<template>
  <section
    id="add-report-view"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
  >
    <div class="reports-title-header">
      <h4 class="report-title">
        Transplant Immunogenetics - Patient Report Data
      </h4>
      <div class="display-end">
        <el-button @click="goToReports" class="back-btn"
          ><el-icon class="el-icon-back"><Back /></el-icon>Back</el-button
        >
      </div>
    </div>

    <div class="inner-navbar">
      <el-row :gutter="10">
        <el-col :span="8">
          <div>
            <el-autocomplete
              v-model="searchVisitId"
              :fetch-suggestions="getAutoCompletePatientsData"
              placeholder="Search VisitID, Patient Name,UHID"
              :clearable="true"
              :trigger-on-focus="false"
              class="el-input"
              @select="handleSelect"
              @clear="clearNameSearch"
            ></el-autocomplete>
          </div>
        </el-col>

        <el-col :span="8">
          <el-select
            v-model="reportType"
            placeholder="Select Report Type *"
            :clearable="true"
            filterable
            @clear="clearFields"
            @change="onChangeReportType()"
          >
            <el-option
              v-for="(report, index) in reportTypes"
              :key="index"
              :label="report.label"
              :value="report.value"
            ></el-option>
          </el-select>
          <FormError errorName="report_type"></FormError>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="testId"
            placeholder="Select Test Category "
            :clearable="true"
            filterable
            @clear="askRemoveConfirmation"
            @change="fillSampleType"
          >
            <el-option
              v-for="report in categoryReportTypes"
              :key="report"
              :label="report.ItemName + '   --  ' + report.TestId"
              :value="report.TestId"
            ></el-option>
          </el-select>

          <FormError errorName="result_test_id"></FormError>
        </el-col>
      </el-row>
      <hr />
      <el-row :gutter="10">
        <el-col :span="8">
          <el-select
            v-model="patientInfo.department"
            allow-create
            placeholder="Select Department"
            :clearable="true"
            filterable
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>

          <FormError errorName="department"></FormError>
        </el-col>

        <el-col :span="8">
          <el-select
            v-model="patientInfo.report_template_id"
            placeholder="Select Report Template"
            :clearable="true"
            filterable
            @clear="askRemoveConfirmation"
            @change="updateTemplateData"
          >
            <el-option
              v-for="(template, index) in templates"
              :key="index"
              :label="template.template_title"
              :value="template._id"
            ></el-option>
          </el-select>

          <FormError errorName="report_template_id"></FormError>
        </el-col>
      </el-row>
    </div>
    <div class="inner">
      <span class="report-header">{{ patientInfo.department }}</span>
      <div class="patient-information">
        <el-form :model="patientInfo">
          <el-row class="edit-box mb-10">
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Patient Name"
                class="mb-05 form-mandatory-field"
              >
                <el-input
                  v-model="patientInfo.patient_name"
                  placeholder="Enter Patient Name"
                ></el-input>
              </el-form-item>
              <FormError errorName="patient_name"></FormError>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Visit Id">
                <el-input
                  v-model="patientInfo.visit_id"
                  placeholder="Enter Visit Id"
                  disabled
                ></el-input>
                <FormError errorName="visit_id"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Barcode" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.barcode_no"
                  placeholder="Enter Barcode"
                ></el-input>
                <FormError errorName="barcode_no"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Age" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.age"
                  placeholder="Enter Age"
                ></el-input>
                <FormError errorName="age"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="UHID/MR No" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.uhid"
                  placeholder="Enter UHID No"
                ></el-input>
                <FormError errorName="uhid"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Collection Date"
                class="mb-05 mandatory-field"
              >
                <el-date-picker
                  v-model="patientInfo.collected_date"
                  type="datetime"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"
                  :disabled-date="disabledDate"
                ></el-date-picker>
                <FormError errorName="collected_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Ref. Doctor" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.ref_doctor"
                  placeholder="Enter Reference Doctor"
                ></el-input>
                <FormError errorName="ref_doctor"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Name" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.client_name"
                  placeholder="Enter Client Name"
                ></el-input>
                <FormError errorName="client_name"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Registration Date"
                class="mb-05 mandatory-field"
              >
                <el-date-picker
                  v-model="patientInfo.registration_date"
                  type="datetime"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"
                  :disabled-date="disabledDate"
                ></el-date-picker>
                <FormError errorName="registration_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Hospital Name" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.hospital_name"
                  placeholder="Enter Hospital Name"
                ></el-input>
                <FormError errorName="hospital_name"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Code" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.client_code"
                  placeholder="Enter Client Code"
                ></el-input>
                <FormError errorName="client_code"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Received Date" class="mb-05 mandatory-field">
                <el-date-picker
                  v-model="patientInfo.received_date"
                  type="datetime"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"
                  :disabled-date="disabledDate"
                ></el-date-picker>
                <FormError errorName="received_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Sample Type" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.sample_type"
                  placeholder="Enter Sample Type"
                ></el-input>
                <FormError errorName="sample_type"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Client Address"
                class="mb-05 mandatory-field"
              >
                <el-input
                  v-model="patientInfo.client_add"
                  placeholder="Enter Client Address"
                ></el-input>
                <FormError errorName="client_add"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Reported Date" class="mb-05 mandatory-field">
                <el-date-picker
                  v-model="patientInfo.reported_date"
                  type="datetime"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"
                  :disabled-date="disabledDate"
                ></el-date-picker>
                <FormError errorName="reported_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Gender" class="mb-05 mandatory-field">
                <div class="gender-select">
                  <el-select
                    v-model="patientInfo.gender"
                    placeholder="Select Gender"
                  >
                    <el-option
                      v-for="(gender, index) in genderTypes"
                      :key="index"
                      :label="gender"
                      :value="gender"
                    ></el-option>
                  </el-select>
                  <FormError errorName="gender"></FormError>
                </div>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Mobile" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.mobile"
                  placeholder="Enter Mobile Number"
                ></el-input>
                <FormError errorName="mobile"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Phone" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.alternate_mobile"
                  placeholder="Enter Phone Number"
                ></el-input>
                <FormError errorName="alternate_mobile"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Email" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.email"
                  placeholder="Enter Email"
                ></el-input>
                <FormError errorName="email"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Locality" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.locality"
                  placeholder="Enter Locality"
                ></el-input>
                <FormError errorName="locality"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Test Name" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.test_name"
                  placeholder="Enter Test Name"
                ></el-input>
                <FormError errorName="test_name"></FormError>
              </el-form-item>
            </el-col>
            <el-col
              :xl="8"
              :lg="8"
              :md="8"
              :sm="8"
              :xs="24"
              v-if="havingTemplateType"
            >
              <el-form-item
                label="Gestational age"
                class="mb-05 mandatory-field"
              >
                <el-input
                  v-model="patientInfo.gestational_age"
                  placeholder="Enter Gestational Age"
                ></el-input>
                <FormError errorName="gestational_age"></FormError>
              </el-form-item>
            </el-col>
            <el-col
              :xl="8"
              :lg="8"
              :md="8"
              :sm="8"
              :xs="24"
              v-if="havingTemplateType"
            >
              <el-form-item label="Fetus Number" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.fetus_number"
                  placeholder="Enter Fetus Number"
                ></el-input>
                <FormError errorName="fetus_number"></FormError>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="havingReportType">
            <el-col :xs="24">
              <div class="uploadInput">
                <el-upload
                  action
                  :on-change="handleUpload"
                  :show-file-list="false"
                  :auto-upload="false"
                  accept="image/png, image/jpeg, image/jpg"
                >
                  <div class="preview-image">
                    <img
                      v-if="previewImage"
                      :src="previewImage"
                      class="avatar-preview"
                    />
                    <div v-else>
                      <img src="../../assets/images/dummy-person.png" alt="" />
                    </div>
                  </div>

                  <el-button
                    class="btn values-button type-success"
                    type="success"
                    v-if="patient_image"
                  >
                    Change Image</el-button
                  >
                  <el-button
                    class="btn values-button type-success"
                    type="success"
                    v-else
                  >
                    Upload Image</el-button
                  >
                </el-upload>
                <el-button
                  type="danger"
                  class="clear-signature"
                  v-if="previewImage"
                  @click="removeImage"
                  >Remove</el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div v-if="havingReportType">
        <hr />
        <div
          style="margin-bottom: 10px; margin-left: 5px"
          v-if="reportType && (reportType == 'HLA_TYPING_CLASS_1_2_CLASS_TYPE' || reportType == 'HLA TYPING CLASS 1 & 2 CLASS TYPE')"
        >
          <el-row>
            <el-col :span="9">
              <div>
                <el-autocomplete
                  v-model="searchSecondVisitId"
                  :fetch-suggestions="getAutoCompletePatientsData"
                  placeholder="Search VisitID, Patient Name,UHID"
                  :clearable="true"
                  :trigger-on-focus="false"
                  class="el-input"
                  @select="handlePatientSelect"
                  @clear="clearPatientNameSearch"
                ></el-autocomplete>
              </div>
              <div v-if="this.issearchSecondVisitId == true">
                <span class="text"
                  >Donor and patient VisitID should not be same.</span
                >
              </div>
            </el-col>
          </el-row>
        </div>
        <div>
          <div
            class="patient-information"
            v-if="
              reportType &&
              (reportType == 'HLA_TYPING_A_B_DRB_1' ||
                reportType == 'HLA TYPING A B DRB1')
            "
          >
            <el-form :model="donorsInfo">
              <div v-for="(donor, index) in donorsInfo" :key="index">
                <div>
                  <el-autocomplete
                    v-model="searchMultiDonorVisitID[index]"
                    :fetch-suggestions="getAutoCompleteMultiPatientsData"
                    placeholder="Search VisitID, Patient Name, UHID"
                    clearable
                    trigger-on-focus="false"
                    class="el-input"
                    @select="(data) => handleMultiPatientSelect(data, index)"
                    @clear="clearMultiPatientNameSearch(index)"
                  ></el-autocomplete>
                </div>
                <div v-if="this.issearchMultiDonorVisitID[index] == true">
                  <span class="text"
                    >Donor and patient VisitID should not be same.</span
                  >
                </div>
                <el-row>
                  <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                    <el-form-item
                      label="Patient Name"
                      class="mb-05 form-mandatory-field"
                    >
                      <el-input
                        v-model="donor.patient_name"
                        placeholder="Enter Patient Name"
                      ></el-input>
                    </el-form-item>
                    <FormError
                      :errorName="`donors_details.${index}.patient_name`"
                    ></FormError>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                    <el-form-item label="Visit Id">
                      <el-input
                        v-model="donor.visit_id"
                        placeholder="Enter Visit Id"
                        disabled
                      ></el-input>
                      <FormError errorName="visit_id"></FormError>
                    </el-form-item>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                    <el-form-item label="Barcode" class="mb-05 mandatory-field">
                      <el-input
                        v-model="donor.barcode_no"
                        placeholder="Enter Barcode"
                      ></el-input>
                      <FormError errorName="barcode_no"></FormError>
                    </el-form-item>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                    <el-form-item label="Age" class="mb-05 mandatory-field">
                      <el-input
                        v-model="donor.age"
                        placeholder="Enter Age"
                      ></el-input>
                      <FormError errorName="age"></FormError>
                    </el-form-item>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                    <el-form-item
                      label="UHID/MR No"
                      class="mb-05 mandatory-field"
                    >
                      <el-input
                        v-model="donor.uhid"
                        placeholder="Enter UHID No"
                      ></el-input>
                      <FormError errorName="uhid"></FormError>
                    </el-form-item>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                    <el-form-item
                      label="Ref. Doctor"
                      class="mb-05 mandatory-field"
                    >
                      <el-input
                        v-model="donor.ref_doctor"
                        placeholder="Enter Reference Doctor"
                      ></el-input>
                      <FormError errorName="ref_doctor"></FormError>
                    </el-form-item>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                    <el-form-item label="Gender" class="mb-05 mandatory-field">
                      <div class="gender-select">
                        <el-select
                          v-model="donor.gender"
                          placeholder="Select Gender"
                        >
                          <el-option
                            v-for="(gender, index) in genderTypes"
                            :key="index"
                            :label="gender"
                            :value="gender"
                          ></el-option>
                        </el-select>
                        <FormError errorName="gender"></FormError>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                    <el-form-item
                      label="Hospital Name"
                      class="mb-05 mandatory-field"
                    >
                      <el-input
                        v-model="donor.hospital_name"
                        placeholder="Enter Hospital Name"
                      ></el-input>
                      <FormError errorName="hospital_name"></FormError>
                    </el-form-item>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                    <el-form-item
                      label="Sample Type"
                      class="mb-05 mandatory-field"
                    >
                      <el-input
                        v-model="donor.sample_type"
                        placeholder="Enter Sample Type"
                      ></el-input>
                      <FormError errorName="sample_type"></FormError>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row class="edit-box mb-10">
                  <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                    <div class="uploadInput">
                      <el-upload
                        action
                        @change="
                          ($event) => handleMultiDonorUpload($event, index)
                        "
                        :show-file-list="false"
                        :auto-upload="false"
                        accept="image/png, image/jpeg, image/jpg"
                      >
                        <div class="preview-image">
                          <img
                            v-if="previewMultiDonorImage[index]"
                            :src="previewMultiDonorImage[index]"
                            class="avatar-preview"
                          />
                          <div v-else>
                            <img
                              src="../../assets/images/dummy-person.png"
                              alt=""
                            />
                          </div>
                        </div>

                        <el-button
                          class="btn values-button type-success"
                          type="success"
                          v-if="previewMultiDonorImage[index]"
                        >
                          Change Image</el-button
                        >
                        <el-button
                          class="btn values-button type-success"
                          type="success"
                          v-else
                        >
                          Upload Image</el-button
                        >
                      </el-upload>
                      <el-button
                        type="danger"
                        class="clear-signature"
                        v-if="previewMultiDonorImage[index]"
                        @click="removeMultiDonorImage(index)"
                        >Remove</el-button
                      >
                    </div>
                  </el-col>
                </el-row>
                <div class="addDonorBlock">
                  <el-button
                    class="defaultButton cancelBtn"
                    v-if="donorsInfo && donorsInfo.length > 1"
                    @click="removeDonor(index)"
                    >Remove</el-button
                  >
                </div>
              </div>
              <div class="addDonorBlock">
                <el-button class="defaultButton" @click="addDonor"
                  >Add Donor</el-button
                >
              </div>
            </el-form>
          </div>
          <div class="patient-information" v-else>
            <el-form :model="donorInfo">
              <el-row>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item
                    label="Patient Name"
                    class="mb-05 form-mandatory-field"
                  >
                    <el-input
                      v-model="donorInfo.patient_name"
                      placeholder="Enter Patient Name"
                    ></el-input>
                  </el-form-item>
                  <FormError errorName="donor_details.patient_name"></FormError>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Visit Id">
                    <el-input
                      v-model="donorInfo.visit_id"
                      placeholder="Enter Visit Id"
                      disabled
                    ></el-input>
                    <FormError errorName="visit_id"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Barcode" class="mb-05 mandatory-field">
                    <el-input
                      v-model="donorInfo.barcode_no"
                      placeholder="Enter Barcode"
                    ></el-input>
                    <FormError errorName="barcode_no"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Age" class="mb-05 mandatory-field">
                    <el-input
                      v-model="donorInfo.age"
                      placeholder="Enter Age"
                    ></el-input>
                    <FormError errorName="age"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item
                    label="UHID/MR No"
                    class="mb-05 mandatory-field"
                  >
                    <el-input
                      v-model="donorInfo.uhid"
                      placeholder="Enter UHID No"
                    ></el-input>
                    <FormError errorName="uhid"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item
                    label="Ref. Doctor"
                    class="mb-05 mandatory-field"
                  >
                    <el-input
                      v-model="donorInfo.ref_doctor"
                      placeholder="Enter Reference Doctor"
                    ></el-input>
                    <FormError errorName="ref_doctor"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Gender" class="mb-05 mandatory-field">
                    <div class="gender-select">
                      <el-select
                        v-model="donorInfo.gender"
                        placeholder="Select Gender"
                      >
                        <el-option
                          v-for="(gender, index) in genderTypes"
                          :key="index"
                          :label="gender"
                          :value="gender"
                        ></el-option>
                      </el-select>
                      <FormError errorName="gender"></FormError>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item
                    label="Hospital Name"
                    class="mb-05 mandatory-field"
                  >
                    <el-input
                      v-model="donorInfo.hospital_name"
                      placeholder="Enter Hospital Name"
                    ></el-input>
                    <FormError errorName="hospital_name"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item
                    label="Sample Type"
                    class="mb-05 mandatory-field"
                  >
                    <el-input
                      v-model="donorInfo.sample_type"
                      placeholder="Enter Sample Type"
                    ></el-input>
                    <FormError errorName="sample_type"></FormError>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="edit-box mb-10">
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <div class="uploadInput">
                    <el-upload
                      action
                      :on-change="handleDonorUpload"
                      :show-file-list="false"
                      :auto-upload="false"
                      accept="image/png, image/jpeg, image/jpg"
                    >
                      <div class="preview-image">
                        <img
                          v-if="previewDonorImage"
                          :src="previewDonorImage"
                          class="avatar-preview"
                        />
                        <div v-else>
                          <img
                            src="../../assets/images/dummy-person.png"
                            alt=""
                          />
                        </div>
                      </div>

                      <el-button
                        class="btn values-button type-success"
                        type="success"
                        v-if="donorInfo.donor_image"
                      >
                        Change Image</el-button
                      >
                      <el-button
                        class="btn values-button type-success"
                        type="success"
                        v-else
                      >
                        Upload Image</el-button
                      >
                    </el-upload>
                    <el-button
                      type="danger"
                      class="clear-signature"
                      v-if="previewDonorImage"
                      @click="removeDonorImage"
                      >Remove</el-button
                    >
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
      <div
        class="report-sub-title"
        v-if="showReportType && patientInfo.report_type"
      >
        {{ patientInfo.report_type }}
        <span @click="onEditReportType" class="cursor-pointer">
          <i class="el-icon-edit"></i>
        </span>
      </div>
      <div class="report-type-edit-info" v-else-if="editReportType">
        <el-input v-model="reportTypeNew"></el-input>
        <el-button
          type="success"
          @click="updateReportType"
          :disabled="isReportTypeUpdateDisable"
          icon="el-icon-check"
        >
        </el-button>
        <el-button
          type="danger"
          icon="el-icon-close"
          @click="cancelUpdateReportType"
        >
        </el-button>
      </div>
      <div
        class="editor"
        v-if="show && checkCKEditorContent()"
        v-loading="loadingEditor"
      >
        <ckeditor
          :editor="editor"
          tag-name="div"
          ref="myEditor"
          v-model="editorData"
          :config="editorConfig"
        ></ckeditor>
      </div>
      <div
        v-if="
          (reportType === 'HLA SAB CLASS I II TYPING' ||
            reportType === 'HLA SAB CLASS I II TYPING') &&
          this.patientInfo.report_template_id &&
          show
        "
      >
        <HLACLASSIIISABTYPING
          :patientInfo="this.patientInfo"
          :HLA_SAB_Array="this.patientInfo.HLA_SAB"
          ref="childComponentRef"
          @childMethod="SaveHLASABData"
        ></HLACLASSIIISABTYPING>
      </div>
      <div
        v-if="
          reportType === 'PRA CLASS I& CLASS II QUANTITATIVE' ||
          reportType === 'PRA_CLASS_I_CLASS_II_QUANTITATIVE' ||
          reportType === 'HLA TYPING CLASS 1 & 2 CLASS TYPE' ||
          reportType === 'HLA_TYPING_CLASS_1_2_CLASS_TYPE' ||
          reportType === 'HLA_TYPING_A_B_DRB_1' ||
          reportType === 'HLA TYPING A B DRB1' ||
          reportType === 'HLA SAB CLASS I II TYPING' ||
          reportType === 'HLA SAB CLASS I II TYPING'
        "
        class="p-10"
      >
        <el-row>
          <el-col :span="6">
            <el-checkbox
              label="Additional approval required"
              v-model="patientInfo.is_required_signature"
              @change="handleCheckedChange"
              :disabled="
                this.patientInfo.report_status === 'APPROVED' &&
                this.patientInfo.fulfilled_signatures === 2
              "
            />
          </el-col>
        </el-row>
      </div>
      <FormError errorName="critical_error"></FormError>

      <ul class="action-buttons">
        <li>
          <el-button @click="printReport" class="preview-button print-button"
            >Print</el-button
          >
        </li>
        <li>
          <el-button @click="previewReport" class="preview-button"
            >Preview</el-button
          >
        </li>
        <li v-if="!isEditForm">
          <el-button @click="savePatientInfo('DRAFT')" class="submit-button"
            >Save as Draft</el-button
          >
        </li>
        <li v-if="!isEditForm">
          <el-button @click="savePatientInfo('PENDING')" class="submit-button"
            >Submit</el-button
          >
        </li>
        <li v-if="isEditForm && isDraft">
          <el-button @click="savePatientInfo('DRAFT')" class="submit-button"
            >Update Draft</el-button
          >
        </li>
        <li v-if="isEditForm && isDraft">
          <el-button @click="savePatientInfo('PENDING')" class="submit-button"
            >Make Complete</el-button
          >
        </li>
        <li v-if="isEditForm && !isDraft">
          <el-button
            @click="savePatientInfo(this.patientInfo.report_status)"
            class="submit-button"
            >Update</el-button
          >
        </li>

        <li>
          <el-button @click="saveAsTemplate" class="submit-button"
            >Save As Template</el-button
          >
        </li>
      </ul>
    </div>

    <div v-if="templateTitleDialogVisible" class="template-title-dialog">
      <el-dialog
        width="30%"
        v-model="templateTitleDialogVisible"
        title="New Template"
        :before-close="handleClose"
        class="template-title-dialog"
      >
        <el-input
          placeholder="Please add a title for Template "
          v-model="templateTitle"
        ></el-input>

        <template #footer>
          <span class="dialog-footer">
            <el-button
              size="medium"
              class="el-white"
              type="info"
              plain
              @click="handleClose"
              >Cancel</el-button
            >
            <el-button
              size="medium"
              type="primary"
              @click="addNewTemplate"
              :loading="loading"
              >Submit</el-button
            >
          </span>
        </template>

        <FormError errorName="template_title"></FormError>
      </el-dialog>
    </div>
    <ResonForChange
      v-if="takeReasonDialog"
      v-on:close="takeReasonDialog = false"
      v-on:reasonSubmit="updateReport"
    >
    </ResonForChange>
  </section>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import {
  genderTypes,
  transplantImmunoGeneticsType,
  transplantImmunoGeneticsMap,
} from "@/constants/formConstants";
import { ElMessageBox, ElMessage } from "element-plus";
import { mapGetters } from "vuex";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import FormError from "@/components/FormError";
import HLACLASSIIISABTYPING from "../transplanantImmunoGenetics/HLACLASSIIISABTYPING";

import EditorConfig from "@/config/editorConfig";
import { errorNotification } from "@/helpers/notifications";
import moment from "moment";
import ResonForChange from "@/components/ReasonForChange";
export default {
  components: {
    ckeditor: CKEditor.component,
    FormError,
    ResonForChange,
    HLACLASSIIISABTYPING,
  },

  data() {
    return {
      askSaveForm: false,
      templateTitleDialogVisible: false,
      templateTitle: "",

      value: "",
      reportTypes: transplantImmunoGeneticsType,
      genderTypes: genderTypes,
      options: ["DEPARTMENT OF TRANSPLANT IMMUNOGENETICS"],
      templates: [],
      patientInfo: {
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        uhid: "",
        mr_no: "",
        gender: "",
        age: "",
        hospital_name: "",
        registration_date: "",
        collected_date: "",
        reported_date: "",
        received_date: "",
        client_name: "",
        client_add: "",
        client_code: "",
        department: "DEPARTMENT OF TRANSPLANT IMMUNOGENETICS",
        report_type: "",
        email: "",
        alternate_mobile: "",
        mobile: "",
        locality: "",
        category: "TRANSPLANT_IMMUNOGENETICS",
        report_status: "DRAFT",
        sample_type: "",
        test_name: "",
        gestational_age: "",
        fetus_number: "",
        is_required_signature: false,
        typedby: "",
        is_amendment: false,
        amendment_history: [],
      },
      issearchSecondVisitId: false,
      issearchMultiDonorVisitID: [],
      unSavedChanges: false,
      loading: false,
      loadingText: "",
      editor: ClassicEditor,
      show: false,
      editorData: "",
      searchVisitId: "",
      searchSecondVisitId: "",
      searchMultiDonorVisitID: [],
      reportType: "",
      showReportType: true,
      editReportType: false,
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      editorConfig: EditorConfig,
      takeReasonDialog: false,
      reasonStatus: "",
      showAddTemplate: false,
      reportTypeNew: "",
      molecularGenomicsReportType: transplantImmunoGeneticsMap,
      loadingEditor: false,
      categoryReportTypes: [],
      listOfDepartments: [],
      testId: "",
      reportSubCategory: "",
      donorInfo: {
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        barcode_no: "",
        gender: "",
        age: "",
        hospital_name: "",
        uhid: "",
        sample_type: "",
        donor_image: "",
      },
      patient_image: "",
      previewImage: "",
      previewDonorImage: "",
      previewMultiDonorImage: [],
      s3Url: process.env.VUE_APP_S3_BUCKET_URL,
      donorsInfo: [
        {
          visit_id: "",
          patient_name: "",
          ref_doctor: "",
          barcode_no: "",
          gender: "",
          age: "",
          hospital_name: "",
          uhid: "",
          sample_type: "",
          donor_image: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("patientReports", [
      "getPatientAddStatus",
      "getSinglePatientReports",
      "getPatientUpdateStatus",
      "getPatientDetails",
      "getDepartments",
    ]),
    ...mapGetters("resultFiles", ["getImageUploadData"]),
    ...mapGetters("reportTemplates", [
      "getAllReportTemplates",
      "getReportTemplateAddStatus",
      "getSingleReportTemplate",
    ]),
    ...mapGetters("auth", ["getProfileDetails"]),
    isReportTypeUpdateDisable() {
      return this.reportTypeNew &&
        this.reportTypeNew.trim() &&
        this.reportTypeNew.trim().length
        ? false
        : true;
    },

    isEditForm() {
      return this.$route.params && this.$route.params.patient_id ? true : false;
    },
    isDraft() {
      if (
        this.isEditForm &&
        this.patientInfo.report_status &&
        this.patientInfo.report_status != "DRAFT"
      ) {
        return false;
      }
      return true;
    },

    noContent() {
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0].innerHTML;
      return data.length ? false : true;
    },
    havingTemplateType() {
      if (
        this.reportType == "NIPT" ||
        this.reportType == "NIPT_with_Micro_Deletions"
      ) {
        return true;
      }
      return false;
    },
    havingReportType() {
      if (
        this.reportSubCategory == "HLA_TYPING_CLASS_1_2_CLASS_TYPE" ||
        this.reportSubCategory === "HLA_TYPING_A_B_DRB_1"
      ) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    if (this.$route.params.patient_id) {
      await this.fetchSinglePatientInfo();
      await this.getVisitData();
      await this.getSecondVisitData();
      await this.fetchReportTemplates();
    }

    await this.$store.dispatch("errors/clear", {}, { root: true });

    setTimeout(() => {
      this.show = true;
    });
  },

  methods: {
    removeDonor(index) {
      this.donorsInfo.splice(index, 1);
    },
    addDonor() {
      this.donorsInfo.push({
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        barcode_no: "",
        gender: "",
        age: "",
        hospital_name: "",
        uhid: "",
        sample_type: "",
        donor_image: "",
      });
      this.searchMultiDonorVisitID.push("");
      this.issearchMultiDonorVisitID.push(false);
    },
    removeImage() {
      this.patient_image = "";
      this.previewImage = "";
    },
    removeDonorImage() {
      this.previewDonorImage = "";
      this.donorInfo.donor_image = "";
    },
    removeMultiDonorImage(index) {
      this.donorsInfo[index].donor_image = "";
    },
    async handleUpload(file) {
      const maxSizeInBytes = 120 * 1024; //120KB
      const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];

      if (!allowedFormats.includes(file.raw.type)) {
        errorNotification("Please upload only JPG, JPEG, or PNG images.");
        return;
      }
      if (file.raw.size > maxSizeInBytes) {
        errorNotification("File size exceeds the limit of 120KB");
        return;
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        this.previewImage = reader.result;
      };
      reader.readAsDataURL(file.raw);
      let params = {
        file: file.name,
        file_type: file.raw.type,
      };
      await this.$store.dispatch("resultFiles/uploadImage", { params, file });

      if (this.getImageUploadData) {
        let imagePath = this.getImageUploadData.path;
        this.patient_image = imagePath;
      } else {
        errorNotification(this.getError || "Error while regenerating graph");
      }
    },
    async handleDonorUpload(file) {
      const maxSizeInBytes = 120 * 1024; //120KB
      const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];

      if (!allowedFormats.includes(file.raw.type)) {
        errorNotification("Please upload only JPG, JPEG, or PNG images.");
        return;
      }
      if (file.raw.size > maxSizeInBytes) {
        errorNotification("File size exceeds the limit of 120KB");
        return;
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        this.previewDonorImage = reader.result;
      };
      reader.readAsDataURL(file.raw);
      let params = {
        file: file.name,
        file_type: file.raw.type,
      };

      await this.$store.dispatch("resultFiles/uploadImage", { params, file });

      if (this.getImageUploadData) {
        let imagePath = this.getImageUploadData.path;
        this.donorInfo.donor_image = imagePath;
      } else {
        errorNotification(this.getError || "Error while regenerating graph");
      }
    },
    async handleMultiDonorUpload(file, index) {
      const maxSizeInBytes = 120 * 1024; //120KB
      const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];

      if (!allowedFormats.includes(file.raw.type)) {
        errorNotification("Please upload only JPG, JPEG, or PNG images.");
        return;
      }
      if (file.raw.size > maxSizeInBytes) {
        errorNotification("File size exceeds the limit of 120KB");
        return;
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        this.previewMultiDonorImage[index] = reader.result;
      };
      reader.readAsDataURL(file.raw);
      let params = {
        file: file.name,
        file_type: file.raw.type,
      };

      await this.$store.dispatch("resultFiles/uploadImage", { params, file });

      if (this.getImageUploadData) {
        let imagePath = this.getImageUploadData.path;
        this.donorsInfo[index].donor_image = imagePath;
      } else {
        errorNotification(this.getError || "Error while regenerating graph");
      }
    },
    onEditReportType() {
      this.reportTypeNew = this.patientInfo.report_type;
      this.editReportType = true;
      this.showReportType = false;
    },
    updateReportType() {
      this.patientInfo.report_type = this.reportTypeNew;
      this.editReportType = false;
      this.showReportType = true;
    },
    cancelUpdateReportType() {
      this.reportTypeNew = this.patientInfo.report_type;
      this.editReportType = false;
      this.showReportType = true;
    },
    clearFields() {
      this.templates = [];
    },
    onChangeReportType() {
      // this.donorsInfo = [
      //   {
      //     visit_id: "",
      //     patient_name: "",
      //     ref_doctor: "",
      //     barcode_no: "",
      //     gender: "",
      //     age: "",
      //     hospital_name: "",
      //     uhid: "",
      //     sample_type: "",
      //     donor_image: "",
      //   }
      // ]
      this.reportSubCategory = this.reportType;
      this.patientInfo.report_type = this.reportType;
      this.patientInfo.report_template_id = "";

      this.patientInfo.test_name =
        this.molecularGenomicsReportType[this.reportType];

      this.fetchReportTemplates();
    },
    askReason() {
      this.takeReasonDialog = true;
    },
    async handleCheckedChange(event) {
      this.patientInfo.is_required_signature = event;
    },
    async updateReport(reason) {
      this.updatePatientInfo(reason);
    },
    fillSampleType(data) {
      const JavaScriptApplicants = this.categoryReportTypes.filter(
        (e) => e.TestId == data
      );

      this.patientInfo.sample_type = JavaScriptApplicants[0].SampleTypeName;
      if (JavaScriptApplicants[0].BarcodeNo) {
        this.patientInfo.barcode_no = JavaScriptApplicants[0].BarcodeNo;
      }
    },
    askSaveConfirmation() {
      this.askSaveForm = true;
    },
    async getAutoCompletePatientsData(queryString, cb) {
      if (queryString && queryString.length > 2) {
        await this.$store.dispatch("patientReports/fetchAllPatients", {
          search_string: queryString,
          get_all: true,
        });

        const patientsData = [];

        (this.getPatientDetails.data || []).forEach((el) => {
          patientsData.push({
            value: el.VisitiID + " - " + el.PatientName,
            ...el,
          });
        });

        cb(patientsData || []);
      } else {
        cb([]);
      }
    },
    async getAutoCompleteMultiPatientsData(queryString, cb) {
      if (queryString && queryString.length > 2) {
        await this.$store.dispatch("patientReports/fetchAllPatients", {
          search_string: queryString,
          get_all: true,
        });

        const patientsData = [];

        (this.getPatientDetails.data || []).forEach((el) => {
          patientsData.push({
            value: el.VisitiID + " - " + el.PatientName,
            ...el,
          });
        });

        cb(patientsData || []);
      } else {
        cb([]);
      }
    },
    async getVisitData() {
      try {
        this.searchVisitId =
          this.patientInfo.visit_id + "-" + this.patientInfo.patient_name;
        // await this.getAutoCompletePatientsData(this.patientInfo.visit_id);
      } catch (error) {
        console.log(error);
      }
    },
    async getSecondVisitData() {
      try {
        this.searchSecondVisitId =
          this.donorInfo.visit_id + "-" + this.donorInfo.patient_name;
        // await this.getAutoCompletePatientsData(this.patientInfo.visit_id);
      } catch (error) {
        console.log(error);
      }
    },
    async handlePatientSelect(data) {
      if (
        this.searchVisitId.replace(/\s/g, "") !==
        this.searchSecondVisitId.replace(/\s/g, "")
      ) {
        this.issearchSecondVisitId = false;
        this.donorInfo.visit_id = data.VisitiID;
        const age = data.Age.split(" ");
        const years = parseInt(age[0]);
        const months = parseInt(age[2]);
        const days = parseInt(age[4]);
        if (
          (years > 0 && months > 0 && days > 0) ||
          (years > 0 && months > 0)
        ) {
          this.donorInfo.age = `${years} Y ${months} M`;
        } else if (years === 0 && months > 0 && days > 0) {
          this.donorInfo.age = `${months} M ${days} D`;
        } else if (years === 0 && months === 0 && days > 0) {
          this.donorInfo.age = `${days} D`;
        } else if (years > 0) {
          this.donorInfo.age = `${years} Y`;
        }
        this.donorInfo.gender = data.Gender.toUpperCase();
        this.donorInfo.barcode_no = data.BarcodeNo;
        this.donorInfo.patient_name = data.PatientName;
        this.donorInfo.hospital_name = data.HospitalName;
        this.donorInfo.ref_doctor = data.RefDoctor;
        this.donorInfo.uhid = data["UHID/MR NO"];
        this.donorInfo.sample_type = data.SampleTypeName;
      } else {
        this.issearchSecondVisitId = true;
      }
    },
    async handleMultiPatientSelect(data, index) {
      if (
        this.searchVisitId.replace(/\s/g, "") !==
        this.searchMultiDonorVisitID[index].replace(/\s/g, "")
      ) {
        this.issearchMultiDonorVisitID[index] = false;
        this.donorsInfo[index].visit_id = data.VisitiID;

        const age = data.Age.split(" ");
        const years = parseInt(age[0]);
        const months = parseInt(age[2]);
        const days = parseInt(age[4]);

        if (
          (years > 0 && months > 0 && days > 0) ||
          (years > 0 && months > 0)
        ) {
          this.donorsInfo[index].age = `${years} Y ${months} M`;
        } else if (years === 0 && months > 0 && days > 0) {
          this.donorsInfo[index].age = `${months} M ${days} D`;
        } else if (years === 0 && months === 0 && days > 0) {
          this.donorsInfo[index].age = `${days} D`;
        } else if (years > 0) {
          this.donorsInfo[index].age = `${years} Y`;
        }

        this.donorsInfo[index].gender = data.Gender.toUpperCase();
        this.donorsInfo[index].barcode_no = data.BarcodeNo;
        this.donorsInfo[index].patient_name = data.PatientName;
        this.donorsInfo[index].hospital_name = data.HospitalName;
        this.donorsInfo[index].ref_doctor = data.RefDoctor;
        this.donorsInfo[index].uhid = data["UHID/MR NO"];
        this.donorsInfo[index].sample_type = data.SampleTypeName;
        this.searchMultiDonorVisitID = [];
      } else {
        this.issearchMultiDonorVisitID[index] = true;
      }
    },
    clearPatientNameSearch() {
      let patientInfo = {
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        uhid: "",
        gender: "",
        age: "",
        hospital_name: "",
        barcode_no: "",
        sample_type: "",
        donor_image: "",
      };
      this.donorInfo = { ...this.donorInfo, ...patientInfo };
      this.issearchSecondVisitId = false;
    },
    clearMultiPatientNameSearch(index) {
      let patientInfo = {
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        uhid: "",
        gender: "",
        age: "",
        hospital_name: "",
        barcode_no: "",
        sample_type: "",
        donor_image: "",
      };
      this.donorsInfo = { ...this.donorsInfo, ...patientInfo };
      this.issearchMultiDonorVisitID[index] = false;
    },
    async handleSelect(data) {
      this.patientInfo.visit_id = data.VisitiID;
      const age = data.Age.split(" ");
      const years = parseInt(age[0]);
      const months = parseInt(age[2]);
      const days = parseInt(age[4]);
      if ((years > 0 && months > 0 && days > 0) || (years > 0 && months > 0)) {
        this.patientInfo.age = `${years} Y ${months} M`;
      } else if (years === 0 && months > 0 && days > 0) {
        this.patientInfo.age = `${months} M ${days} D`;
      } else if (years === 0 && months === 0 && days > 0) {
        this.patientInfo.age = `${days} D`;
      } else if (months > 0) {
        this.patientInfo.age = `${months} M`;
      } else if (years > 0) {
        this.patientInfo.age = `${years} Y`;
      }
      this.patientInfo.gender = data.Gender.toUpperCase();
      this.patientInfo.barcode_no = data.BarcodeNo;
      this.patientInfo.client_add = data.ClientAdd;
      this.patientInfo.client_code = data.ClientCode;
      this.patientInfo.client_name = data.ClientName;
      this.patientInfo.hospital_name = data.HospitalName;
      this.patientInfo.patient_name = data.PatientName;
      this.patientInfo.ref_doctor =
        data.RefDoctor && !data.RefDoctor.startsWith("Dr.")
          ? `Dr. ${data.RefDoctor}`
          : data.RefDoctor || "";
      this.patientInfo.uhid = data["UHID/MR NO"];

      this.patientInfo.registration_date = data.RegisterationDate
        ? moment(data.RegisterationDate).utc().format()
        : null;
      this.patientInfo.collected_date = data.CollectionDate
        ? moment(data.CollectionDate).utc().format()
        : null;
      this.patientInfo.reported_date = data.ReportedDate
        ? moment(data.ReportedDate).utc().format()
        : null;
      this.patientInfo.received_date = data.ReceivedDate
        ? moment(data.ReceivedDate).utc().format()
        : null;

      this.patientInfo.email = data.Email;
      this.patientInfo.mobile = data.Mobile;
      this.patientInfo.locality = data.Locality;
      this.patientInfo.alternate_mobile = data.Phone;
      this.patientInfo.sample_type = data.SampleTypeName;
      if (data.TestName) {
        this.patientInfo.test_name = data.TestName;
      }
      this.patientInfo.gestational_age = data.GestationalAge;
      this.patientInfo.fetus_number = data.FetusNumber;
      await this.fetchPatientDepartments();
    },
    async fetchPatientDepartments() {
      this.loading = true;
      let params = {};
      if (this.patientInfo.visit_id) {
        params.visit_id = this.patientInfo.visit_id;
        params.department = "transplant-immunogenetics";
      }
      await this.$store.dispatch("patientReports/fetchAllDepartments", params);
      if (
        this.getDepartments &&
        this.getDepartments.data &&
        this.getDepartments.data.length
      ) {
        this.listOfDepartments = this.getDepartments.data;

        this.listOfDepartments.forEach(
          (x) => (this.categoryReportTypes = x.test_categories)
        );
      } else {
        this.listOfDepartments = [];
      }
      this.loading = false;
    },
    clearNameSearch() {
      let patientInfo = {
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        client_add: "",
        client_code: "",
        uhid: "",
        mr_no: "",
        gender: "",
        age: "",
        client_name: "",
        hospital_name: "",
        barcode_no: "",
        registration_date: "",
        collected_date: "",
        reported_date: "",
        received_date: "",
        email: "",
        alternate_mobile: "",
        mobile: "",
        locality: "",
        fetus_number: "",
        gestational_age: "",
      };
      this.patientInfo = { ...this.patientInfo, ...patientInfo };
    },
    async fetchSinglePatientInfo() {
      this.loading = true;
      this.loadingText = "Fetching data...";
      try {
        await this.$store.dispatch(
          "patientReports/fetchSinglePatientReports",
          this.$route.params.patient_id
        );
        this.setPatientData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    SaveHLASABData(result) {
      this.patientInfo.HLA_SAB = result;
    },
    async setPatientData() {
      this.patientInfo = { ...this.getSinglePatientReports };
      if (
        this.getSinglePatientReports &&
        this.getSinglePatientReports.patient_image
      ) {
        let data = `${this.s3Url}/report-assets/${this.getSinglePatientReports.patient_image}`;
        this.previewImage = data;
        this.patient_image = this.getSinglePatientReports.patient_image;
      }
      if (
        this.getSinglePatientReports &&
        this.getSinglePatientReports.donor_details
      ) {
        this.donorInfo = {
          ...this.getSinglePatientReports.donor_details,
        };
      }
      if (
        this.getSinglePatientReports &&
        this.getSinglePatientReports.donors_details &&
        this.getSinglePatientReports.donors_details.length > 0
      ) {
        this.donorsInfo = [...this.getSinglePatientReports.donors_details];
      }
      if (
        this.getSinglePatientReports &&
        this.getSinglePatientReports.donor_details &&
        this.getSinglePatientReports.donor_details.donor_image
      ) {
        let data = `${this.s3Url}/report-assets/${this.getSinglePatientReports.donor_details.donor_image}`;
        this.previewDonorImage = data;
      }
      if (
        this.getSinglePatientReports &&
        this.getSinglePatientReports.donors_details &&
        this.getSinglePatientReports.donors_details.length > 0
      ) {
        // Assuming each donor detail object has a property named 'donor_image'
        this.previewMultiDonorImage =
          this.getSinglePatientReports.donors_details.map((donor) => {
            return `${this.s3Url}/report-assets/${donor.donor_image}`;
          });
      }
      this.reportType = this.patientInfo.sub_category;
      this.reportSubCategory = this.patientInfo.sub_category;
      this.editorData = this.getSinglePatientReports.test_details;
      await this.fetchPatientDepartments();
      if (this.getSinglePatientReports.result_test_id) {
        this.testId = this.getSinglePatientReports.result_test_id;
      }
    },
    async handleClose() {
      this.templateTitle = "";
      this.templateTitleDialogVisible = false;
      await this.$store.dispatch("errors/clear", {}, { root: true });
    },

    async addNewTemplate() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;
        if (
          this.reportSubCategory == "HLA_TYPING_CLASS_1_2_CLASS_TYPE" ||
          this.reportSubCategory == "HLA_TYPING_A_B_DRB_1"
        ) {
          params.donor_details = { ...this.donorInfo };
        }
        if (this.reportSubCategory == "HLA SAB CLASS I II TYPING") {
          this.$refs.childComponentRef.childMethod();
        }
        let params = { ...this.patientInfo };
        params.template_title = this.templateTitle;
        params.test_details = data;

        if (!data.length) {
          this.$message("No Content to Save Template");
          return;
        }
        params.template_body = data;

        this.loading = true;
        params.sub_category = this.reportSubCategory;

        if (params.age) {
          params.age = params.age.toString();
        }
        if (params.client_code) {
          params.client_code = params.client_code.toString();
        }
        await this.$store.dispatch("reportTemplates/addReportTemplate", params);

        if (this.getReportTemplateAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Template Added Successfully",
          });

          this.templateTitleDialogVisible = false;
          this.templateTitle = "";

          await this.fetchReportTemplates();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error at Creating new Template",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    sortTemplatesTypes() {
      this.templates.sort(function (a, b) {
        if (a.sub_category < b.sub_category) {
          return -1;
        }
        if (a.sub_category > b.sub_category) {
          return 1;
        }
        return 0;
      });
    },
    saveAsTemplate() {
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0].innerHTML;
      let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
      stringContent = stringContent.trim();
      if (!stringContent) {
        ElMessage.warning("Report content is not given");
        return;
      }
      this.templateTitleDialogVisible = true;
    },
    async previewReport() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;
        if (this.checkCKEditorContent()) {
          let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
          stringContent = stringContent.trim();
          if (!stringContent) {
            ElMessage.warning("Report content is not given");
            return;
          }
        }
        this.loading = true;
        this.loadingText = "Loading..";

        let params = { ...this.patientInfo };
        if (this.reportSubCategory == "HLA_TYPING_CLASS_1_2_CLASS_TYPE") {
          if (this.donorInfo.donor_image == "") {
            delete this.donorInfo.donor_image;
          }
          params.donor_details = {
            ...this.donorInfo,
          };
        }
        if (
          (this.reportType == "HLA_TYPING_A_B_DRB_1" ||
            this.reportType == "HLA TYPING A B DRB1") &&
          this.donorsInfo &&
          this.donorsInfo.length > 0
        ) {
          this.donorsInfo.forEach((donor) => {
            if (donor.donor_image === "") {
              delete donor.donor_image;
            }
          });
          params.donors_details = this.donorsInfo;
        }
        if (
          this.patient_image &&
          this.reportSubCategory == "HLA_TYPING_A_B_DRB_1"
        ) {
          params.patient_image = this.patient_image;
        }
        if (this.reportSubCategory == "HLA SAB CLASS I II TYPING") {
          this.$refs.childComponentRef.childMethod();
        }
        if (this.reportSubCategory === "HLA SAB CLASS I II TYPING") {
          params.HLA_SAB = this.patientInfo.HLA_SAB;
        } else {
          params.HLA_SAB = [];
        }
        params.sub_category = this.reportSubCategory;
        params.test_details = data;
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreview",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to preview report",
            "Alert",
            {
              confirmButtonText: "OK",
            }
          );
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async printReport() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;
        if (this.checkCKEditorContent()) {
          let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
          stringContent = stringContent.trim();
          if (!stringContent) {
            ElMessage.warning("Report content is not given");
            return;
          }
        }
        this.loading = true;
        this.loadingText = "Loading..";

        let params = { ...this.patientInfo };
        if (this.reportSubCategory == "HLA_TYPING_CLASS_1_2_CLASS_TYPE") {
          if (this.donorInfo.donor_image == "") {
            delete this.donorInfo.donor_image;
          }
          params.donor_details = {
            ...this.donorInfo,
          };
        }
        if (
          (this.reportType == "HLA_TYPING_A_B_DRB_1" ||
            this.reportType == "HLA TYPING A B DRB1") &&
          this.donorsInfo &&
          this.donorsInfo.length > 0
        ) {
          this.donorsInfo.forEach((donor) => {
            if (donor.donor_image === "") {
              delete donor.donor_image;
            }
          });
          params.donors_details = this.donorsInfo;
        }
        if (this.reportSubCategory == "HLA SAB CLASS I II TYPING") {
          this.$refs.childComponentRef.childMethod();
        }
        if (this.reportSubCategory === "HLA SAB CLASS I II TYPING") {
          params.HLA_SAB = this.patientInfo.HLA_SAB;
        } else {
          params.HLA_SAB = [];
        }
        params.test_details = data;
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreviewPrint",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to print report",
            "Alert",
            {
              confirmButtonText: "OK",
            }
          );
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async goToReports() {
      if (this.$route.params.patient_id) {
        this.$router.push({
          name: "PatientReportView",
          query: this.$route.query,
          params: { status: this.$route.params.status },
        });
      } else {
        this.$router.push({ name: "PatientReports", query: this.$route.query });
      }
    },

    async fetchReportTemplates() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data..";
        let params = {
          get_all: true,
          category: "TRANSPLANT_IMMUNOGENETICS",
        };
        if (this.reportType) {
          params.report_type =
            this.molecularGenomicsReportType[this.reportType];
        }
        await this.$store.dispatch(
          "reportTemplates/fetchAllReportTemplates",
          params
        );
        this.loading = false;
        if (
          this.getAllReportTemplates &&
          this.getAllReportTemplates.data &&
          this.getAllReportTemplates.data.length
        ) {
          this.templates = this.getAllReportTemplates.data.sort((a, b) =>
            a.sub_category.localeCompare(b.sub_category)
          );
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    savePatientInfo(status) {
      this.reasonStatus = status;
      if (this.$route.params.patient_id && status === "APPROVED") {
        this.askReason();
      } else if (this.$route.params.patient_id) {
        this.updatePatientInfo();
      } else {
        this.addPatientInfo();
      }
    },
    async fetchSingleReportTemplate(templateId) {
      try {
        await this.$store.dispatch(
          "reportTemplates/fetchSingleReportTemplate",
          { template_id: templateId }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async updateTemplateData(templateId) {
      await this.fetchSingleReportTemplate(templateId);
      let templateData = this.templates.find((x) => x._id == templateId);
      if (
        this.getSingleReportTemplate &&
        this.getSingleReportTemplate.template_body
      ) {
        templateData.template_body = this.getSingleReportTemplate.template_body;
      }
      if (
        this.getSingleReportTemplate &&
        this.getSingleReportTemplate.test_details
      ) {
        templateData.test_details = this.getSingleReportTemplate.test_details;
      }
      if (templateData) {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML || "";
        let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent.trim();
        if (stringContent) {
          ElMessageBox.confirm(
            "Are you want to update template data. Continue?",
            "Warning",
            {
              confirmButtonText: "OK",
              cancelButtonText: "No",
              type: "warning",
            }
          )
            .then(async () => {
              this.updateEditorData(templateData);
            })
            .catch(() => {});
        } else {
          this.updateEditorData(templateData);
        }
      }
    },
    updateEditorData(templateData) {
      this.show = false;
      this.loadingEditor = true;
      setTimeout(() => {
        this.loadingEditor = false;

        this.show = true;
        this.editorData = templateData.template_body || "";
        this.patientInfo.report_type = templateData.report_type || "";
        this.patientInfo.HLA_SAB = templateData.HLA_SAB || [];
        if (templateData.report_type == "HLA TYPING CLASS 1 & 2 CLASS TYPE") {
          this.reportSubCategory = "HLA_TYPING_CLASS_1_2_CLASS_TYPE";
        } else if (templateData.report_type == "HLA TYPING A B DRB1") {
          this.reportType = "HLA_TYPING_A_B_DRB_1";
        } else if (templateData.report_type == "HLA SAB CLASS I II TYPING") {
          this.reportSubCategory = "HLA SAB CLASS I II TYPING";
        } else {
          // this.reportSubCategory = "";
          this.clearPatientNameSearch();
          this.removeImage();
          this.removeDonorImage();
          this.searchSecondVisitId = "";
        }
        this.reportType = templateData.sub_category || "";
      }, 100);
    },
    async addPatientInfo() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;
        if (this.checkCKEditorContent()) {
          let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
          stringContent = stringContent.trim();
          if (!stringContent) {
            ElMessage.warning("Report content is not given");
            return;
          }
        }
        if (this.reportSubCategory == "HLA SAB CLASS I II TYPING") {
          this.$refs.childComponentRef.childMethod();
        }
        this.loading = true;
        this.loadingText = "Loading...";
        this.patientInfo.typedby = this.getProfileDetails?.first_name;
        let params = { ...this.patientInfo };
        if (this.reportSubCategory == "HLA_TYPING_CLASS_1_2_CLASS_TYPE") {
          if (this.donorInfo.donor_image == "") {
            delete this.donorInfo.donor_image;
          }
          params.donor_details = {
            ...this.donorInfo,
          };
        }

        if (
          this.patient_image &&
          this.reportSubCategory == "HLA_TYPING_CLASS_1_2_CLASS_TYPE"
        ) {
          params.patient_image = this.patient_image;
        }
        if (
          (this.reportType == "HLA_TYPING_A_B_DRB_1" ||
            this.reportType == "HLA TYPING A B DRB1") &&
          this.donorsInfo &&
          this.donorsInfo.length > 0
        ) {
          this.donorsInfo.forEach((donor) => {
            if (donor.donor_image === "") {
              delete donor.donor_image;
            }
          });
          params.donors_details = this.donorsInfo;
        }
        if (
          this.patient_image &&
          this.reportSubCategory == "HLA_TYPING_A_B_DRB_1"
        ) {
          params.patient_image = this.patient_image;
        }
        if (params.client_code && params.client_code != "") {
          const code = params.client_code.toString();
          params.client_code = code;
        }
        params.test_details = data;
        if (this.reasonStatus) {
          params.report_status = this.reasonStatus;
        }

        params.sub_category = this.reportSubCategory;
        if (!params.report_template_id) {
          params.report_template_id = null;
        }
        if (this.testId) {
          params.result_test_id = this.testId;
        }
        if (this.reportSubCategory === "HLA SAB CLASS I II TYPING") {
          params.HLA_SAB = this.patientInfo.HLA_SAB;
        } else {
          params.HLA_SAB = [];
        }
        await this.$store.dispatch("patientReports/addPatientReport", params);
        if (this.getPatientAddStatus) {
          this.$notify.success({
            title: "Success",
            message:
              this.reasonStatus == "DRAFT"
                ? "Patient Report Drafted Successfully"
                : "Patient Report Added Successfully",
          });
          await this.goToReports();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Adding Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async updatePatientInfo(reason) {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;
        if (this.checkCKEditorContent()) {
          let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
          stringContent = stringContent.trim();
          if (!stringContent) {
            ElMessage.warning("Report content is not given");
            return;
          }
        }
        if (this.reportSubCategory == "HLA SAB CLASS I II TYPING") {
          this.$refs.childComponentRef.childMethod();
        }
        this.loading = true;
        this.loadingText = "Loading...";
        let params = { ...this.patientInfo };
        if (this.reportSubCategory == "HLA_TYPING_CLASS_1_2_CLASS_TYPE") {
          if (this.donorInfo.donor_image == "") {
            delete this.donorInfo.donor_image;
          }
          params.donor_details = {
            ...this.donorInfo,
          };
        }
        if (
          this.patient_image &&
          this.reportSubCategory == "HLA_TYPING_CLASS_1_2_CLASS_TYPE"
        ) {
          params.patient_image = this.patient_image;
        }
        if (
          (this.reportType == "HLA_TYPING_A_B_DRB_1" ||
            this.reportType == "HLA TYPING A B DRB1") &&
          this.donorsInfo &&
          this.donorsInfo.length > 0
        ) {
          this.donorsInfo.forEach((donor) => {
            if (donor.donor_image === "") {
              delete donor.donor_image;
            }
          });
          params.donors_details = this.donorsInfo;
        }
        if (
          this.patient_image &&
          this.reportSubCategory == "HLA_TYPING_A_B_DRB_1"
        ) {
          params.patient_image = this.patient_image;
        }
        if (params.client_code && params.client_code != "") {
          const code = params.client_code.toString();
          params.client_code = code;
        }
        if (params.age == null) {
          params.age = "";
        }
        if (params.reported_date == null) {
          params.reported_date = "";
        }
        if (params.received_date == null) {
          params.received_date = "";
        }
        if (this.reasonStatus) {
          params.report_status = this.reasonStatus;
        }
        params.test_details = data;

        params.sub_category = this.reportSubCategory;

        let patientId = this.$route.params.patient_id;
        if (!params.report_template_id) {
          params.report_template_id = null;
        }
        if (this.testId) {
          params.result_test_id = this.testId;
        }
        if (reason && reason.reason) {
          const amendmentReason = reason.reason;
          const currentDate = new Date();
          const formattedDate = currentDate.toISOString();
          params.reported_date = "";
          this.patientInfo.amendment_history.push({
            amendment_reason: amendmentReason,
            edited_by: this.getProfileDetails?.first_name,
            edited_date: formattedDate,
          });
          params.amendment_reason = reason.reason;
        }
        params.amendment_history = this.patientInfo.amendment_history;
        if (reason && reason.is_amendment) {
          params.is_amendment = reason.is_amendment;
        }
        if (this.reportSubCategory == "HLA SAB CLASS I II TYPING") {
          params.HLA_SAB = this.patientInfo.HLA_SAB;
        } else {
          params.HLA_SAB = [];
        }
        await this.$store.dispatch("patientReports/updatePatientReport", {
          params,
          patientId,
        });
        if (this.getPatientUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Updated Successfully",
          });
          await this.goToReports();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async askRemoveConfirmation() {
      await ElMessageBox.confirm(
        "Are you want to clear template data. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          await this.updateEditorData({});
        })
        .catch(() => {});
    },
    scrollIntoFirstError() {
      var el = this.$el.getElementsByClassName("error")[0];
      if (el) {
        el.scrollIntoView({ block: "center" });
      }
    },
    checkCKEditorContent() {
      const excludedSubcategories = [
        "HLA SAB CLASS I II TYPING",
        "HLA SAB CLASS I II TYPING",
      ];
      return !excludedSubcategories.includes(this.reportType);
    },
  },
};
</script>

<style lang="scss">
#add-report-view {
  max-width: 900px;
  margin: 0 auto;
  .reports-title-header {
    display: grid;
    grid-template-columns: 88% auto;
    margin-bottom: 10px;
    .display-end {
      text-align: end;
    }
    .report-title {
      text-align: center;
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }

    .back-btn {
      padding: 9px 15px;
      min-height: auto;
      line-height: 1;
      color: #333;
      border: 1px solid #47525d42;
      background-color: white;

      border-radius: 5px;

      font-weight: 500;
      .el-icon-back {
        margin-right: 4px;
      }
      &:active,
      &:hover,
      &:focus {
        border: 1px solid #47525d42;
      }
    }
  }

  .inner-navbar {
    margin-bottom: 10px;
    .filter-navigation {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }

  .inner {
    margin-bottom: 50px;
    .report-header {
      margin: 0;
      color: #fff;
      font-weight: 600;
      font-size: 20px;
      background: #2eaf9f;
      text-align: center;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    border: 1px solid rgba(7, 162, 149, 0.6);
    border-radius: 10px;
    padding: 0;
    background: #fff;
    overflow: hidden;
    .patient-information {
      .el-form-item {
        margin-bottom: 0;
      }
      .form-mandatory-field {
        .el-form-item__label:after {
          content: "*";
          color: red !important;
          font-size: 15px;
          line-height: 1;
          padding-left: 3px;
        }
      }

      .el-form-item__label {
        min-width: 40%;
        text-align: left;
        background: #e0f3f1;
        padding-left: 10px;
        border-bottom: 1px solid #e0f3f1;
        font-weight: 600;
        font-size: 11px;
        color: #000;
      }

      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }
      .el-input__inner {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-left: none;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        border-radius: 0;
        color: #000;
        font-weight: 500;
        // text-transform: uppercase;
        &::placeholder {
          color: #5a5a5a;
          font-size: 13px;
          text-transform: capitalize;
        }
      }
    }
    .report-sub-title {
      color: #43454b;
      font-size: 18px;
      text-align: center;
      margin-top: -1px;
      padding-top: 1rem;
      border-top: 1.2px solid rgba(0, 0, 0, 0.2);
      background: #28b4a6;
      font-weight: 600;
    }
    .report-type-edit-info {
      display: flex;
      margin-top: 0.5em;
      padding: 0 10px;
      gap: 5px;
    }
    .editor {
      margin-top: 0.5em;
      padding: 10px;
      .ck-editor__editable {
        min-height: 320px;
      }
    }
    .image__caption_highlighted {
      min-height: auto !important;
    }
    .ck-dropdown__panel {
      max-height: 230px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .ck-placeholder {
      min-height: auto !important;
    }

    .ck-dropdown__panel::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2eaf9f;
    }
    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      margin: 0;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      li {
        &:not(:last-child) {
          margin-right: 20px;
        }
        .el-button {
          padding: 9px 15px;
          min-height: auto;
          line-height: 1;
          &.preview-button {
            background: #28b4a6;
            color: #fff;
            font-weight: 600;
          }
          &.print-button {
            background: #f56c6c !important;
          }
          &.submit-button {
            background: #0061ec;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  figure {
    &.table {
      br {
        display: none;
      }
      br[data-cke-filler="true"] {
        display: block !important;
      }
    }
  }
  .ck {
    ul {
      list-style: disc;
      padding-left: 30px;
      li {
        padding-left: 5px;
        list-style-type: disc !important;
      }
    }
  }
  // .input-error {
  //   .el-input__inner {
  //     border: 1.5px solid red !important;
  //   }
  //   ::placeholder {
  //     color: red !important;
  //     font-weight: 600;
  //   }
  // }
}
.cursor-pointer {
  cursor: pointer;
}
.template-title-dialog {
  .el-dialog__header {
    background-color: #a09b9b6b;
    .el-dialog__close {
      font-size: 20px;
      color: red;
    }
  }
}
.el-upload {
  gap: 10px;
}

.uploadInput {
  display: flex;
  align-items: center;
  gap: 10px;
}
.edit-box {
  border: 1px solid #2eaf9f;

  .title {
    color: #ffffff;
    background: #2eaf9f;
    text-align: left;
    border: 1px solid #2eaf9f;
    font-weight: 600;
    display: block;
    padding: 2px 10px;
    font-size: 14px;
  }
}
</style>