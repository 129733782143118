<template>
  <section
    id="add-report-view"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
  >
    <div class="reports-title-header">
      <h4 class="report-title" v-if="getPatientResults">
        Patient Results Edit({{ getPatientResults.barcode }})
      </h4>

      <div class="display-end">
        <el-button @click="goToReports" class="back-btn">
          <el-icon class="el-icon-back"><Back /></el-icon>
          Back</el-button
        >
      </div>
    </div>

    <div class="inner">
      <span class="report-header">DEPARTMENT OF BIOCHEMICAL GENETICS</span>
      <div class="patient-information">
        <el-form :model="patientInfo">
          <el-row>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Visit Id">
                <el-input
                  v-model="patientInfo.visit_id"
                  placeholder="Enter Visit Id"
                  disabled
                ></el-input>
                <FormError errorName="visit_id"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Patient Name"
                class="mb-05 form-mandatory-field"
              >
                <el-input
                  v-model="patientInfo.patient_name"
                  placeholder="Enter Patient Name"
                ></el-input>
              </el-form-item>
              <FormError
                errorName="patient_demographics.patient_name"
              ></FormError>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Hospital Name" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.hospital_name"
                  placeholder="Enter Hospital Name"
                ></el-input>
                <FormError errorName="ref_doctor"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Ref. Doctor" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.ref_doctor"
                  placeholder="Enter Reference Doctor"
                ></el-input>
                <FormError errorName="ref_doctor"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Client Address"
                class="mb-05 mandatory-field"
              >
                <el-input
                  v-model="patientInfo.client_add"
                  placeholder="Enter Client Address"
                ></el-input>
                <FormError errorName="client_add"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Code" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.client_code"
                  placeholder="Enter Client Code"
                ></el-input>
                <FormError errorName="client_code"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="UHID/MR No" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.uhid"
                  placeholder="Enter UHID No"
                ></el-input>
                <FormError errorName="uhid"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Gender" class="mb-05 mandatory-field">
                <div class="gender-select">
                  <el-select
                    v-model="patientInfo.gender"
                    placeholder="Select Gender"
                  >
                    <el-option
                      v-for="(gender, index) in genderTypes"
                      :key="index"
                      :label="gender"
                      :value="gender"
                    ></el-option>
                  </el-select>
                  <FormError errorName="gender"></FormError>
                </div>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Age" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.age"
                  placeholder="Enter Age"
                ></el-input>
                <FormError errorName="age"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Mobile" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.mobile"
                  placeholder="Enter Mobile Number"
                ></el-input>
                <FormError errorName="mobile"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Phone" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.alternate_mobile"
                  placeholder="Enter Phone Number"
                ></el-input>
                <FormError errorName="alternate_mobile"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Email" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.email"
                  placeholder="Enter Email"
                ></el-input>
                <FormError errorName="email"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Locality" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.locality"
                  placeholder="Enter Locality"
                ></el-input>
                <FormError errorName="locality"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Name" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.client_name"
                  placeholder="Enter Client Name"
                ></el-input>
                <FormError errorName="client_name"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Sample Type" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.sample_type"
                  placeholder="Enter Sample Type"
                ></el-input>
                <FormError errorName="sample_type"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Barcode No" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.barcode_no"
                  placeholder="Enter Barcode No"
                ></el-input>
                <FormError errorName="barcode_no"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Registration Date"
                class="mb-05 mandatory-field"
              >
                <el-date-picker
                  v-model="patientInfo.registration_date"
                  type="date"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY"
                  value-format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                >
                </el-date-picker>
                <FormError errorName="registration_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Collected Date"
                class="mb-05 mandatory-field"
              >
                <el-date-picker
                  v-model="patientInfo.collected_date"
                  type="date"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY"
                  value-format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                >
                </el-date-picker>
                <FormError errorName="collected_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Reported Date" class="mb-05 mandatory-field">
                <el-date-picker
                  v-model="patientInfo.reported_date"
                  type="date"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY"
                  value-format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                >
                </el-date-picker>
                <FormError errorName="reported_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Received Date" class="mb-05 mandatory-field">
                <el-date-picker
                  v-model="patientInfo.received_date"
                  type="date"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY"
                  value-format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                >
                </el-date-picker>
                <FormError errorName="received_date"></FormError>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tms-report-divider"></div>
      <div
        class="double-marker"
        v-if="
          getPatientResults &&
          getPatientResults.test_category == 'DOUBLE_MARKERS'
        "
      >
        <div class="edit-box mb-20">
          <span class="title">Comments:</span>
          <el-input type="textarea" v-model="comments" rows="3"></el-input>
        </div>
        <div class="edit-box mb-20">
          <span class="title">Recommendations:</span>
          <el-input
            type="textarea"
            v-model="recommendations"
            rows="3"
          ></el-input>
        </div>
        <div class="edit-box mb-20 edit-box-table">
          <span class="title">Biochemical Results</span>
          <div class="inside-table">
            <el-row>
              <el-col :span="6">
                <span class="table-header">Test Name</span>
              </el-col>
              <el-col :span="5">
                <span class="table-header">Result</span>
              </el-col>
              <el-col :span="4">
                <span class="table-header">Units</span>
              </el-col>
              <el-col :span="5">
                <span class="table-header">CORR. MOM</span>
              </el-col>
              <el-col :span="4">
                <span class="table-header">Method</span>
              </el-col>
            </el-row>
            <el-form :model="testResultsSummary">
              <el-row>
                <el-col :span="6">
                  <el-form-item>
                    <el-input placeholder="PAPP-A" disabled> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary['ansValuePlain PAPP-A']"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input placeholder="mU/L" disabled> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input
                      v-model="
                        testResultsSummary['ansValueRelatedNormalized_PAPP-A']
                      "
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input v-model="methodValue" disabled> </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item>
                    <el-input placeholder="Free β-HCG" disabled> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary['ansValuePlain HCGb']"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input placeholder="ng/mL" disabled> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input
                      v-model="
                        testResultsSummary['ansValueRelatedNormalized_HCGb']
                      "
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input v-model="methodValue" disabled> </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item>
                    <el-input placeholder="NT1" disabled> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input v-model="testResultsSummary.ansValuePlain_NT">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input placeholder="mm" disabled> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.ansValueRelatedNormalized_NT"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input v-model="methodValue" disabled> </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="6">
                  <el-form-item>
                    <el-input placeholder="NT2" disabled> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input v-model="testResultsSummary.ansValuePlain_NT2">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input placeholder="mm" disabled> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.ansValueRelatedNormalized_NT2"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input v-model="methodValue" disabled> </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div class="edit-box mb-20 edit-box-table">
          <span class="title">Risk Assessment: FETUS 1</span>
          <div class="inside-table">
            <el-row>
              <el-col :span="5">
                <span class="table-header">Condition</span>
              </el-col>
              <el-col :span="5">
                <span class="table-header">Age Risk</span>
              </el-col>
              <el-col :span="4">
                <span class="table-header">Risk</span>
              </el-col>
              <el-col :span="4">
                <span class="table-header">Cut-Off</span>
              </el-col>
              <el-col :span="6">
                <span class="table-header">Risk Category</span>
              </el-col>
            </el-row>
            <el-form :model="testResultsSummary">
              <el-row>
                <el-col :span="5">
                  <el-form-item>
                    <el-input placeholder="DOWN SYNDROME (T21)" disabled>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input v-model="rskAge_T21"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input v-model="rskValue_T21"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.rskCutOff_T21"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.rskEnumValue_T21"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="5">
                  <el-form-item>
                    <el-input placeholder="EDWARD'S SYNDROME (T18)" disabled>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input v-model="rskAge_T18"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input v-model="rskValue_T18"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.rskCutOff_T18"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.rskEnumValue_T18"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="5">
                  <el-form-item>
                    <el-input placeholder="PATAU'S SYNDROME (T13)" disabled>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input v-model="rskAge_T13"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input v-model="rskValue_T13"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.rskCutOff_T13"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.rskEnumValue_T13"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="5">
                  <el-form-item>
                    <el-input
                      placeholder="TURNER' SYNDROME NON-HYDROPS"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input v-model="rskAge_TS"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input v-model="rskValue_TS"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary['rskCutOff_TS non-hydrops']"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-input
                      v-model="
                        testResultsSummary['rskEnumValue_TS non-hydrops']
                      "
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div class="edit-box mb-20 edit-box-table">
          <span class="title">Risk Assessment: FETUS 2</span>
          <div class="inside-table">
            <el-row>
              <el-col :span="5">
                <span class="table-header">Condition</span>
              </el-col>
              <el-col :span="5">
                <span class="table-header">Age Risk</span>
              </el-col>
              <el-col :span="4">
                <span class="table-header">Risk</span>
              </el-col>
              <el-col :span="4">
                <span class="table-header">Cut-Off</span>
              </el-col>
              <el-col :span="6">
                <span class="table-header">Risk Category</span>
              </el-col>
            </el-row>
            <el-form :model="testResultsSummary">
              <el-row>
                <el-col :span="5">
                  <el-form-item>
                    <el-input placeholder="DOWN SYNDROME (T21)" disabled>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input v-model="rskAge_T21"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input v-model="rskValueTwin_T21"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.rskCutOff_T21"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.rskEnumValueTwin_T21"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="5">
                  <el-form-item>
                    <el-input placeholder="EDWARD'S SYNDROME (T18)" disabled>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input v-model="rskAge_T18"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input v-model="rskValueTwin_T18"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.rskCutOff_T18"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.rskEnumValueTwin_T18"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="5">
                  <el-form-item>
                    <el-input placeholder="PATAU'S SYNDROME (T13)" disabled>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input v-model="rskAge_T13"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input v-model="rskValueTwin_T13"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.rskCutOff_T13"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary.rskEnumValueTwin_13"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="5">
                  <el-form-item>
                    <el-input
                      placeholder="TURNER' SYNDROME NON-HYDROPS"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-input v-model="rskAge_TS"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input v-model="rskValueTwin_TS"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-input
                      v-model="testResultsSummary['rskCutOff_TS non-hydrops']"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-input
                      v-model="
                        testResultsSummary['rskEnumValueTwin TS non-hydrops']
                      "
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div class="edit-box mb-20 edit-box-table-list">
          <span class="title">Patient Details</span>
          <el-form :model="testResultsSummary">
            <el-row :gutter="20">
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Date of Birth">
                  <!-- <el-input
                    v-model="testResultsSummary.ptnDateofBirth"
                  ></el-input> -->
                  <el-date-picker
                    v-model="testResultsSummary.ptnDateofBirth"
                    type="date"
                    format="DD/MM/YYYY"
                    value-format="DD/MM/YYYY"
                    :disabled-date="disabledDate"
                    :clearable="false"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Ethnicity">
                  <el-input v-model="testResultsSummary.ptnEthnic"></el-input>
                  <!-- <el-select v-model="testResultsSummary.ptnEthnic">
                    <el-option
                      v-for="(status, index) in statusOptions"
                      :key="index"
                      :label="status"
                      :value="status"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Smoking Status">
                  <el-input v-model="testResultsSummary.cd1SmoKing"> </el-input>
                  <!-- <el-select v-model="testResultsSummary.cd1SmoKing">
                    <el-option
                      v-for="(smokingStatus, index) in statusOptions"
                      :key="index"
                      :label="smokingStatus"
                      :value="smokingStatus"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Weight [kg]">
                  <el-input v-model="testResultsSummary.sd1Weight"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Height [cms]">
                  <el-input v-model="testResultsSummary.cd1Height"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Body Mass Index">
                  <el-input
                    v-model="testResultsSummary.ptnBodyMassIndex"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Bleeding / Spotting">
                  <el-input
                    v-model="testResultsSummary.ptnBleedingSpotting"
                  ></el-input>
                  <!-- <el-select v-model="testResultsSummary.ptnBleedingSpotting">
                    <el-option
                      v-for="(spotting, index) in statusOptions"
                      :key="index"
                      :label="spotting"
                      :value="spotting"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Patient on HCG Injection Taken">
                  <el-input
                    v-model="testResultsSummary.ptnPatientOnHCGInjectionTaken"
                  ></el-input>
                  <!-- <el-select
                    v-model="testResultsSummary.ptnPatientOnHCGInjectionTaken"
                  >
                    <el-option
                      v-for="(injection, index) in statusOptions"
                      :key="index"
                      :label="injection"
                      :value="injection"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Date of HCG Injection Taken">
                  <!-- <el-input
                    v-model="testResultsSummary.ptnDateOfHCGInjection"
                  ></el-input> -->
                  <el-date-picker
                    v-model="testResultsSummary.ptnDateOfHCGInjectionTaken"
                    type="date"
                    format="DD/MM/YYYY"
                    value-format="DD/MM/YYYY"
                    :disabled-date="disabledDate"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="edit-box mb-20 edit-box-table-list">
          <span class="title">Pregnancy Details</span>
          <el-form :model="testResultsSummary">
            <el-row :gutter="20">
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Conception Method">
                  <el-input
                    v-model="testResultsSummary.cd1AssistanceMethod"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="MAEDD">
                  <el-input
                    v-model="testResultsSummary.cd1AgeAtDeliveryDate"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Calculated EDD">
                  <!-- <el-input
                    v-model="testResultsSummary.cd1DelweryDate"
                  ></el-input> -->
                  <el-date-picker
                    v-model="testResultsSummary.cd1DelweryDate"
                    type="date"
                    format="DD/MM/YYYY"
                    value-format="DD/MM/YYYY"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="LMP Date">
                  <!-- <el-input v-model="testResultsSummary.cd1LMPDate"></el-input> -->
                  <el-date-picker
                    v-model="testResultsSummary.cd1LMPDate"
                    type="date"
                    format="DD/MM/YYYY"
                    value-format="DD/MM/YYYY"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Selected Gest. Method">
                  <el-input
                    v-model="testResultsSummary.sdlCalcBasedonScan"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="BP">
                  <el-input v-model="testResultsSummary.cd1Bp"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="No. of Fetuses">
                  <el-input
                    v-model="testResultsSummary['cd1 NumberOfFetuses']"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Chorionicity">
                  <el-input
                    v-model="testResultsSummary.cd1Chorionicity"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Previous Patau Syndrome">
                  <el-input v-model="testResultsSummary.cd1PastT13"></el-input>
                  <!-- <el-select v-model="testResultsSummary.cd1PastT13">
                    <el-option
                      v-for="(patauSyndrome, index) in statusOptions"
                      :key="index"
                      :label="patauSyndrome"
                      :value="patauSyndrome"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Previous Down Syndrome">
                  <el-input
                    v-model="testResultsSummary.cd1PastDowns"
                  ></el-input>
                  <!-- <el-select v-model="testResultsSummary.cd1PastDowns">
                    <el-option
                      v-for="(downSyndrome, index) in statusOptions"
                      :key="index"
                      :label="downSyndrome"
                      :value="downSyndrome"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Previous Edward Syndrome">
                  <el-input
                    v-model="testResultsSummary.cdlPastEdwards"
                  ></el-input>
                  <!-- <el-select v-model="testResultsSummary.cdlPastEdwards">
                    <el-option
                      v-for="(edwardSyndrome, index) in statusOptions"
                      :key="index"
                      :label="edwardSyndrome"
                      :value="edwardSyndrome"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Insulin Dependent Diabetes Mellitus">
                  <el-input
                    v-model="testResultsSummary.cdlInsulinDep"
                  ></el-input>
                  <!-- <el-select v-model="testResultsSummary.cdlInsulinDep">
                    <el-option
                      v-for="(diabetes, index) in statusOptions"
                      :key="index"
                      :label="diabetes"
                      :value="diabetes"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Previous ONTD">
                  <el-input
                    v-model="testResultsSummary.cd1PreviousONTD"
                  ></el-input>
                  <!-- <el-select v-model="testResultsSummary.cd1PreviousONTD">
                    <el-option
                      v-for="(previous, index) in statusOptions"
                      :key="index"
                      :label="previous"
                      :value="previous"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
              <!-- <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Gestational Diabetes">
                  <el-input
                    v-model="testResultsSummary['sdlCalcBasedonScan']"
                  ></el-input>
                  
                </el-form-item>
              </el-col> -->
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Transfer Date">
                  <!-- <el-input
                    v-model="testResultsSummary.ptnDateOfHCGInjection"
                  ></el-input> -->
                  <el-date-picker
                    v-model="testResultsSummary.cdlAssistanceDate"
                    type="date"
                    format="DD/MM/YYYY"
                    value-format="DD/MM/YYYY"
                    :disabled-date="disabledDate"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Egg Extraction Date">
                  <!-- <el-input
                    v-model="testResultsSummary.ptnDateOfHCGInjection"
                  ></el-input> -->
                  <el-date-picker
                    v-model="testResultsSummary['date cdlEggExtractionDate']"
                    type="date"
                    format="DD/MM/YYYY"
                    value-format="DD/MM/YYYY"
                    :disabled-date="disabledDate"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Egg Donor (DOB)">
                  <!-- <el-input
                    v-model="testResultsSummary.ptnDateOfHCGInjection"
                  ></el-input> -->
                  <el-date-picker
                    v-model="testResultsSummary.cd1EggDonorDOB"
                    type="date"
                    format="DD/MM/YYYY"
                    value-format="DD/MM/YYYY"
                    :disabled-date="disabledDate"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Age at Extraction">
                  <!-- <el-input
                    v-model="testResultsSummary.ptnDateOfHCGInjection"
                  ></el-input> -->
                  <el-input v-model="testResultsSummary.cd1AgeAtExtraction">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Monozygous">
                  <!-- <el-input
                    v-model="testResultsSummary.ptnDateOfHCGInjection"
                  ></el-input> -->
                  <el-input v-model="testResultsSummary['cd1 MonozygousTwins']">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="edit-box mb-20 edit-box-table-list">
          <span class="title">Ultrasound Details</span>
          <el-form :model="patientInfo">
            <el-row :gutter="20">
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Scan Date">
                  <!-- <el-input
                    v-model="testResultsSummary.us_sd1USDate"
                  ></el-input> -->
                  <el-date-picker
                    v-model="testResultsSummary.us_sd1USDate"
                    type="date"
                    format="DD/MM/YYYY"
                    value-format="DD/MM/YYYY"
                    :disabled-date="disabledDate"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Gest. at Scan Date [W+D]">
                  <el-input
                    v-model="testResultsSummary['us sd1GestatAtSampDate']"
                    type="number"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Sample Collection Date">
                  <!-- <el-input
                    v-model="testResultsSummary.spcTimeCollected"
                  ></el-input> -->
                  <el-date-picker
                    v-model="testResultsSummary.spcTimeCollected"
                    type="date"
                    format="DD/MM/YYYY"
                    value-format="DD/MM/YYYY"
                    :disabled-date="disabledDate"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Gest. at Sample Date [W+D]">
                  <el-input
                    v-model="testResultsSummary.sd1GestatAtSampDate"
                    type="number"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="CRL 1 [mm]">
                  <el-input
                    v-model="testResultsSummary['us_sd1CRL']"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="CRL 2 [mm]">
                  <el-input
                    v-model="testResultsSummary['us_sd1CRL2']"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="BPD 1 [mm]">
                  <el-input
                    v-model="testResultsSummary['us_sd1BPD']"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="BPD 2 [mm]">
                  <el-input
                    v-model="testResultsSummary['us_sd1BPD2']"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="NT 1 [mm]">
                  <el-input
                    v-model="testResultsSummary.ansValuePlain_NT"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="NT 2 [mm]">
                  <el-input
                    v-model="testResultsSummary.ansValuePlain_NT2"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Nasal Bone 1 (NB)">
                  <el-input
                    v-model="testResultsSummary.ansValuePlain_NB"
                  ></el-input>
                  <!-- <el-select v-model="testResultsSummary.ansValuePlain_NB">
                    <el-option
                      v-for="(nb, index) in nbOptions"
                      :key="index"
                      :label="nb"
                      :value="nb"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Nasal Bone 2 (NB)">
                  <el-input
                    v-model="testResultsSummary.ansValuePlain_NB2"
                  ></el-input>
                  <!-- <el-select v-model="testResultsSummary.ansValuePlain_NB2">
                    <el-option
                      v-for="(nb, index) in nbOptions"
                      :key="index"
                      :label="nb"
                      :value="nb"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>

      <ul class="action-buttons">
        <li>
          <el-button @click="savePatientInfo" class="submit-button"
            >Submit</el-button
          >
        </li>
      </ul>
      <FormError errorName="critical_error"></FormError>
    </div>
  </section>
</template>

<script>
import {
  genderTypes,
  // nosileBoneOptions,
  // statusOptions,
} from "@/constants/formConstants";
import FormError from "@/components/FormError";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    FormError,
  },

  data() {
    return {
      templateTitle: "",
      value: "",
      methodValue: "TRFIA",
      genderTypes: genderTypes,
      // nbOptions: nosileBoneOptions,
      // statusOptions: statusOptions,
      patientInfo: {
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        client_add: "",
        client_code: "",
        uhid: "",
        mr_no: "",
        gender: "",
        age: "",
        client_name: "",
        barcode_no: "",
        registration_date: "",
        collected_date: "",
        reported_date: "",
        received_date: "",
        sample_type: "",
      },
      collected_date: "",
      reported_date: "",
      received_date: "",
      report_template_id: "",
      test_results: "",
      loading: false,
      loadingText: "",
      show: false,
      editorData: "",
      comments: "",
      recommendations: "",
      testResults: {},

      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      interpretationData: "",
      patientLoading: false,
      testResultsSummary: [],
      templateTitleDialogVisible: false,
      testCategory: "",

      rskAge_T21: "",
      rskValue_T21: "",
      rskAge_T18: "",
      rskValue_T18: "",
      rskValueTwin_T21: "",
      rskvalueTwin21: "",
      rskValueTwin_T18: "",
      rskvalueTwin18: "",
      rskValueTwin_T13: "",
      rskvalueTwin13: "",
      rskValueTwin_TS: "",
      rskValueTwinTs: "",
      rskAge_T13: "",
      rskValue_T13: "",
      rskAge_TS: "",
      rskValue_TS: "",
      riskage21: "",
      riskValue21: "",
      riskage18: "",
      riskValue18: "",
      riskage13: "",
      riskValue13: "",
      riskageTS: "",
      riskValueTS: "",
    };
  },
  computed: {
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("resultFiles", [
      "getPatientResults",
      "getPatientResultUpdateStatus",
    ]),
  },
  async mounted() {
    await this.loadInitData();
  },

  methods: {
    async loadInitData() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data...";
        await this.$store.dispatch("errors/clear", {}, { root: true });
        await this.fetchSinglePatientInfo();

        // await this.department();

        setTimeout(() => {
          this.show = true;
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    async fetchSinglePatientInfo() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data...";
        let params = {
          result_file_id: this.$route.params.result_file_id,
          patient_id: this.$route.params.patient_id,
        };
        await this.$store.dispatch(
          "resultFiles/fetchSinglePatientResults",
          params
        );
        await this.setPatientData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    setPatientRiskValues() {
      if (this.testResultsSummary.rskAge_T21) {
        const riskage21 = this.testResultsSummary.rskAge_T21.split(":");
        this.riskage21 = riskage21[0];
        this.rskAge_T21 = riskage21[1];
      }
      if (this.testResultsSummary.rskValue_T21) {
        const riskValue21 = this.testResultsSummary.rskValue_T21.split(":");
        this.riskValue21 = riskValue21[0];
        this.rskValue_T21 = riskValue21[1];
      }
      if (this.testResultsSummary.rskValueTwin_T21) {
        const riskTwinValue21 =
          this.testResultsSummary.rskValueTwin_T21.split(":");
        this.rskValueTwin_T21 = riskTwinValue21[1];
        this.rskvalueTwin21 = riskTwinValue21[0];
      }
      if (this.testResultsSummary.rskValueTwin_T18) {
        const riskTwinValue18 =
          this.testResultsSummary.rskValueTwin_T18.split(":");
        this.rskValueTwin_T18 = riskTwinValue18[1];
        this.rskvalueTwin18 = riskTwinValue18[0];
        console.log(riskTwinValue18);
      }
      if (this.testResultsSummary.rskValueTwin_T13) {
        const riskTwinValue13 =
          this.testResultsSummary.rskValueTwin_T13.split(":");
        this.rskValueTwin_T13 = riskTwinValue13[1];
        this.rskvalueTwin13 = riskTwinValue13[0];
      }
      if (this.testResultsSummary["rskValueTwin_TS non-hydrops"]) {
        const rskValueTwinTS =
          this.testResultsSummary["rskValueTwin_TS non-hydrops"].split(":");
        this.rskValueTwin_TS = rskValueTwinTS[1];
        this.rskValueTwinTs = rskValueTwinTS[0];
      }
      if (this.testResultsSummary.rskAge_T18) {
        const riskage18 = this.testResultsSummary.rskAge_T18.split(":");
        this.riskage18 = riskage18[0];
        this.rskAge_T18 = riskage18[1];
      }
      if (this.testResultsSummary.rskValue_T18) {
        const riskValue18 = this.testResultsSummary.rskValue_T18.split(":");
        this.riskValue18 = riskValue18[0];
        this.rskValue_T18 = riskValue18[1];
      }
      if (this.testResultsSummary.rskAge_T13) {
        const riskage13 = this.testResultsSummary.rskAge_T13.split(":");
        this.riskage13 = riskage13[0];
        this.rskAge_T13 = riskage13[1];
      }
      if (this.testResultsSummary.rskValue_T13) {
        const riskValue13 = this.testResultsSummary.rskValue_T13.split(":");
        this.riskValue13 = riskValue13[0];
        this.rskValue_T13 = riskValue13[1];
      }
      if (this.testResultsSummary.rskAge_TS) {
        const riskageTS = this.testResultsSummary.rskAge_TS.split(":");
        this.riskageTS = riskageTS[0];
        this.rskAge_TS = riskageTS[1];
      }
      if (this.testResultsSummary.rskValue_TS) {
        const riskValueTS = this.testResultsSummary.rskValue_TS.split(":");
        this.riskValueTS = riskValueTS[0];
        this.rskValue_TS = riskValueTS[1];
      }
    },
    async setPatientData() {
      if (
        this.getPatientResults &&
        this.getPatientResults.test_results_summary &&
        this.getPatientResults.test_results_summary.length
      ) {
        this.testResultsSummary =
          this.getPatientResults.test_results_summary[0];
      }
      this.setPatientRiskValues();
      if (
        this.getPatientResults &&
        this.getPatientResults.patient_demographics
      ) {
        let collectedDate = moment(this.getPatientResults.patient_demographics.collected_date).utcOffset('+05:30').format();
        let receivedDate = moment(this.getPatientResults.patient_demographics.received_date).utcOffset('+05:30').format();
        let reportedDate = moment(this.getPatientResults.patient_demographics.reported_date).utcOffset('+05:30').format();
        let registrationDate = moment(this.getPatientResults.patient_demographics.registration_date).utcOffset('+05:30').format();
        this.patientInfo = {
          ...this.patientInfo,
          ...this.getPatientResults.patient_demographics,
          collected_date:collectedDate,
          received_date:receivedDate,
          reported_date:reportedDate,
          registration_date:registrationDate
        };
      }
      if (this.getPatientResults && this.getPatientResults.sample_type) {
        this.patientInfo.sample_type = this.getPatientResults.sample_type;
      }
      if (this.getPatientResults && this.getPatientResults.comments) {
        this.comments = this.getPatientResults.comments;
      }
      if (this.getPatientResults && this.getPatientResults.recommendations) {
        this.recommendations = this.getPatientResults.recommendations;
      }
      this.testCategory = this.getPatientResults.test_category;
    },

    goToReports() {
      if (this.$route.query && this.$route.query.fromRoute) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: "PatientResultFiles",
          query: this.$route.query,
        });
      }
    },

    savePatientInfo() {
      this.addPatientInfo();
    },

    async addPatientInfo() {
      try {
        this.loading = true;
        this.loadingText = "Loading...";
        let patient_demographics = { ...this.patientInfo };

        if (patient_demographics.client_code != "") {
          const code = patient_demographics.client_code.toString();
          patient_demographics.client_code = code;
        }

        let params = { patient_demographics };

        if (this.report_template_id) {
          params.report_template_id = this.report_template_id || "";
        }

        if (this.riskage21 && this.rskAge_T21) {
          this.testResultsSummary.rskAge_T21 =
            this.riskage21 + ":" + this.rskAge_T21;
        } else if (this.rskAge_T21) {
          this.testResultsSummary.rskAge_T21 = 1 + ":" + this.rskAge_T21;
        } else {
          this.testResultsSummary.rskAge_T21 = "";
        }
        if (this.riskValue21 && this.rskValue_T21) {
          this.testResultsSummary.rskValue_T21 =
            this.riskValue21 + ":" + this.rskValue_T21;
        } else if (this.rskValue_T21) {
          this.testResultsSummary.rskValue_T21 = 1 + ":" + this.rskValue_T21;
        } else {
          this.testResultsSummary.rskValue_T21 = "";
        }

        if (this.rskvalueTwin21 && this.rskValueTwin_T21) {
          this.testResultsSummary.rskValueTwin_T21 =
            this.rskvalueTwin21 + ":" + this.rskValueTwin_T21;
        } else if (this.rskValueTwin_T21) {
          this.testResultsSummary.rskValueTwin_T21 =
            1 + ":" + this.rskValueTwin_T21;
        } else {
          this.testResultsSummary.rskValueTwin_T21 = "";
        }
        if (this.riskage18 && this.rskAge_T18) {
          this.testResultsSummary.rskAge_T18 =
            this.riskage18 + ":" + this.rskAge_T18;
        } else if (this.rskAge_T18) {
          this.testResultsSummary.rskAge_T18 = 1 + ":" + this.rskAge_T18;
        } else {
          this.testResultsSummary.rskAge_T18 = "";
        }
        if (this.riskValue18 && this.rskValue_T18) {
          this.testResultsSummary.rskValue_T18 =
            this.riskValue18 + ":" + this.rskValue_T18;
        } else if (this.rskValue_T18) {
          this.testResultsSummary.rskValue_T18 = 1 + ":" + this.rskValue_T18;
        } else {
          this.testResultsSummary.rskValue_T18 = "";
        }

        if (this.rskvalueTwin18 && this.rskValueTwin_T18) {
          this.testResultsSummary.rskValueTwin_T18 =
            this.rskvalueTwin18 + ":" + this.rskValueTwin_T18;
        } else if (this.rskValueTwin_T18) {
          this.testResultsSummary.rskValueTwin_T18 =
            1 + ":" + this.rskValueTwin_T18;
        } else {
          this.testResultsSummary.rskValueTwin_T18 = "";
        }

        if (this.riskage13 && this.rskAge_T13) {
          this.testResultsSummary.rskAge_T13 =
            this.riskage13 + ":" + this.rskAge_T13;
        } else if (this.rskAge_T13) {
          this.testResultsSummary.rskAge_T13 = 1 + ":" + this.rskAge_T13;
        } else {
          this.testResultsSummary.rskAge_T13 = "";
        }
        if (this.riskValue13 && this.rskValue_T13) {
          this.testResultsSummary.rskValue_T13 =
            this.riskValue13 + ":" + this.rskValue_T13;
        } else if (this.rskValue_T13) {
          this.testResultsSummary.rskValue_T13 = 1 + ":" + this.rskValue_T13;
        } else {
          this.testResultsSummary.rskValue_T13 = "";
        }
        if (this.rskvalueTwin13 && this.rskValueTwin_T13) {
          this.testResultsSummary.rskValueTwin_T13 =
            this.rskvalueTwin13 + ":" + this.rskValueTwin_T13;
        } else if (this.rskValueTwin_T13) {
          this.testResultsSummary.rskValueTwin_T13 =
            1 + ":" + this.rskValueTwin_T13;
        } else {
          this.testResultsSummary.rskValueTwin_T13 = "";
        }
        if (this.rskValueTwinTs && this.rskValueTwin_TS) {
          this.testResultsSummary["rskValueTwin_TS non-hydrops"] =
            this.rskValueTwinTs + ":" + this.rskValueTwin_TS;
        } else if (this.rskValueTwin_TS) {
          this.testResultsSummary["rskValueTwin_TS non-hydrops"] =
            1 + ":" + this.this.rskValueTwin_TS;
        } else {
          this.testResultsSummary["rskValueTwin_TS non-hydrops"] = "";
        }
        if (this.riskageTS && this.rskAge_TS) {
          this.testResultsSummary.rskAge_TS =
            this.riskageTS + ":" + this.rskAge_TS;
        } else if (this.rskAge_TS) {
          this.testResultsSummary.rskAge_TS = 1 + ":" + this.rskAge_TS;
        } else {
          this.testResultsSummary.rskAge_TS = "";
        }
        if (this.riskValueTS && this.rskValue_TS) {
          this.testResultsSummary.rskValue_TS =
            this.riskValueTS + ":" + this.rskValue_TS;
        } else if (this.rskValue_TS) {
          this.testResultsSummary.rskValue_TS = 1 + ":" + this.rskValue_TS;
        } else {
          this.testResultsSummary.rskValue_TS = "";
        }
        params.test_results_summary = [this.testResultsSummary];
        params.recommendations = this.recommendations;
        params.comments = this.comments;
        let patientId = this.$route.params.patient_id;
        let resultFileId = this.$route.params.result_file_id;
        if (!params.patient_demographics.reported_date || params.patient_demographics.reported_date === "Invalid date") {
              delete params.patient_demographics.reported_date;
          }
        await this.$store.dispatch("resultFiles/updatePatientResults", {
          params,
          patientId,
          resultFileId,
        });
        if (this.getPatientResultUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Updated Successfully",
          });
          await this.goToReports();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while updating Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lower_abnormal {
  font-weight: bold;
}

.upper_abnormal {
  font-weight: bold;
  background-color: #b7d2ec;
}
</style>
<style lang="scss">
#add-report-view {
  max-width: 900px;
  margin: 0 auto;
  .reports-title-header {
    display: grid;
    grid-template-columns: 88% auto;
    margin-bottom: 15px;
    .display-end {
      display: flex;
      text-align: end;
    }
    .report-title {
      text-align: center;
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }

    .back-btn {
      padding: 9px 15px;
      min-height: auto;
      line-height: 1;
      color: #333;
      border: 1px solid #47525d42;
      background-color: white;

      border-radius: 5px;

      font-weight: 500;
      .el-icon-back {
        margin-right: 4px;
      }
      &:active,
      &:hover,
      &:focus {
        border: 1px solid #47525d42;
      }
    }
  }

  .inner-navbar {
    margin-bottom: 10px;
    .mt-10 {
      margin-top: 10px;
    }
    .filter-navigation {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }
  .tms-report-divider {
    width: 100%;
    height: 3px;
    background: #b7d2ec;
    margin: 15px 0;
  }
  @media print {
    .tms-report-divider {
      display: none;
    }
  }

  .inner {
    margin-bottom: 50px;
    .report-header {
      margin: 0;
      color: #fff;
      font-weight: 600;
      font-size: 20px;
      background: #2eaf9f;
      text-align: center;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    border: 1px solid rgba(7, 162, 149, 0.6);
    border-radius: 10px;
    padding: 0;
    background: #fff;
    overflow: hidden;
    .patient-information {
      .el-form-item {
        margin-bottom: 0;
      }
      .form-mandatory-field {
        .el-form-item__label:after {
          content: "*";
          color: red !important;
          font-size: 15px;
          line-height: 1;
          padding-left: 3px;
        }
      }

      .el-form-item__label {
        min-width: 40%;
        text-align: left;
        background: #e0f3f1;
        padding-left: 10px;
        border-bottom: 1px solid #e0f3f1;
        font-weight: 600;
        font-size: 11px;
        color: #000;
      }

      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }
      .el-input__inner {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-left: none;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        border-radius: 0;
        color: #000;
        font-weight: 500;
        // text-transform: uppercase;
        &::placeholder {
          color: #5a5a5a;
          font-size: 13px;
          text-transform: capitalize;
        }
      }
    }
    .report-sub-title {
      color: #43454b;
      font-size: 18px;
      text-align: center;
      margin-top: -1px;
      padding-top: 1rem;
      border-top: 1.2px solid rgba(0, 0, 0, 0.2);

      font-weight: 600;
    }
    .report-type-edit-info {
      display: flex;
      margin-top: 0.5em;
      padding: 0 10px;
      gap: 5px;
    }
    .editor,
    .comments-editor {
      margin-top: 0.5em;
      padding: 10px;
      .ck-editor__editable {
        min-height: 320px;
      }
    }
    .image__caption_highlighted {
      min-height: auto !important;
    }
    .ck-dropdown__panel {
      max-height: 230px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .ck-placeholder {
      min-height: auto !important;
    }

    .ck-dropdown__panel::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2eaf9f;
    }
    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      margin: 0;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      li {
        &:not(:last-child) {
          margin-right: 20px;
        }
        .el-button {
          padding: 9px 15px;
          min-height: auto;
          line-height: 1;
          &.preview-button {
            background: #28b4a6;
            color: #fff;
            font-weight: 600;
          }
          &.print-button {
            background: #f56c6c !important;
          }
          &.submit-button {
            background: #0061ec;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  figure {
    &.table {
      br {
        display: none;
      }
      br[data-cke-filler="true"] {
        display: block !important;
      }
    }
  }
  .ck {
    ul {
      list-style: disc;
      padding-left: 30px;
      li {
        padding-left: 5px;
        list-style-type: disc !important;
      }
    }
  }
  .table-data {
    padding-top: 1em;
  }
  .el-table {
    .el-table thead {
      background-color: #28b4a6;
      font-weight: 500;
    }
  }
  // .input-error {
  //   .el-input__inner {
  //     border: 1.5px solid red !important;
  //   }
  //   ::placeholder {
  //     color: red !important;
  //     font-weight: 600;
  //   }
  // }
  .result-table {
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
  }
}
.double-marker {
  padding: 10px 0 20px;

  .text-area-sub-header {
    padding: 5px 20px;
  }

  .el-textarea__inner {
    width: 100%;
    margin: auto;
  }
}
</style>

<style lang="scss">
.bio-chemistry-report-title {
  text-align: center;
  background: #e0f3f1;
  border-radius: 5px;
  margin-top: 10px !important;
  padding: 6px 0;
  font-weight: 600;
  font-size: 15px;
  color: #333;
}
.bio-chemistry-data-table {
  border: 1px solid rgba(7, 162, 149, 0.6);
  margin: 20px 10px 10px 10px;
  padding: 0 !important;
  table {
    th {
      background: #2eaf9f;
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      padding: 4px 10px;
      letter-spacing: 0;
      position: relative;
      text-align: left;
      &:not(:last-child) {
        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 2px;
          bottom: 2px;
          background: rgba(255, 255, 255, 0.6);
          width: 1px;
        }
      }
      .cell {
        padding: 0;
      }
    }

    td {
      padding: 5px 10px;
      font-size: 14px;
      border-bottom: 1px solid #2eaf9f;
      font-weight: 500;
      color: #000;
      &:not(:last-child) {
        border-right: 1px solid #2eaf9f;
      }
      .cell {
        padding: 0;
      }
    }
  }
}

.tms-report-editor-table {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  .data-table {
    margin-bottom: 20px;
    table {
      border-collapse: collapse;
      width: 100%;

      th,
      td {
        padding: 5px 10px !important;
        font-size: 15px;
      }
      th {
        background: #f5f5f5;
        font-weight: 600;
      }
      .table-head {
        background: #28b4a6;
        color: #fff;
        text-align: center !important;
        font-size: 15px !important;
        .cell {
          line-height: 20px !important;
        }
      }
    }
  }
}
.mb-20 {
  margin-bottom: 20px;
}
</style>
