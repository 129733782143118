export const genderTypes = ["MALE", "FEMALE", "OTHERS"]
export const rejectedReasons = [
  { label: "Clotted", value: "CLOTTED" },
  { label: "Early collection", value: "EARLY_COLLECTION" },
  { label: "Hemolysis", value: "HEMOLYSIS" },
  { label: "Improper test selection", value: "IMPROPER_TEST_SELECTION" },
  { label: "Improper gestations age", value: "IMPROPER_GESTATIONS_AGE" },
  {
    label: "Improper time of collection",
    value: "IMPROPER_TIME_OF_COLLECTION",
  },
  { label: "Incomplete TRF", value: "INCOMPLETE_TRF" },
  { label: "Insufficient volume", value: "INSUFFICIENT_VOLUME" },
  { label: "On Medication", value: "ON_MEDICATION" },
  { label: "No Clinical history", value: "NO_CLINICAL_HISTORY" },
  // { label: "REPORT Ready", value: "REPORT_READY" },
  {
    label: "Sample collected from IV line",
    value: "SAMPLE_COLLECTED_FROM_IV_LIVE",
  },
  {
    label: "Specimen site not mentioned (Biopsy)",
    value: "SPECIMEN_SITE_NOT_MENTIONED_BIOPSY",
  },
  { label: "Sample contamination", value: "SAMPLE_CONTAMINATION" },
  { label: "Transit delay", value: "TRANSIT_DELAY" },
  { label: "Wrong container", value: "WRONG_CONTAINER" },
  { label: "Others", value: "OTHERS" },
]
export const departmentEmails = [
  {
    department_name: "BIOCHEMICAL GENETICS",
    to_reply: "biochemicalgenetics@yodalifeline.in",
  },
  {
    department_name: "BIOCHEMISTRY",
    to_reply: "biochemistry@yodalifeline.in",
  },
  {
    department_name: "CARDIOLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "CLINICAL PATHOLOGY",
    to_reply: "",
  },
  {
    department_name: "CYTOGENETICS",
    to_reply: "cytogenetics@yodalifeline.in",
  },
  {
    department_name: "CYTOPATHOLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "DENTAL",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "FLOW CYTOMETRY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "HAEMATOLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "HISTOPATHOLOGY",
    to_reply: "histopathology@yodalifeline.in",
  },
  {
    department_name: "HORMONE ASSAYS",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "IHC-HISTOPATHOLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "MAMMOGRAPHY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "MICROARRAY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "MICROBIOLOGY",
    to_reply: "microbiology@yodalifeline.in",
  },
  {
    department_name: "MISCELLANEOUS , AMB, ANAESTHESIA CHARGES",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "MOLECULAR DIAGNOSTICS",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "MOLECULAR GENETICS",
    to_reply: "moleculargenetics@yodalifeline.in",
  },
  {
    department_name: "MOLECULAR PATHOLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "NEUROLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "PFT",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "PHARMACOGENOMICS",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "RADIOLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "SEROLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "SEROLOGY / IMMUNOLOGY",
    to_reply: "info@yodalifeline.in",
  },
]
export const rejectedReasonsMap = {
  CLOTTED: "Clotted",
  EARLY_COLLECTION: "Early collection",
  HEMOLYSIS: "Hemolysis",
  IMPROPER_TEST_SELECTION: "Improper test selection",
  IMPROPER_GESTATIONS_AGE: "Improper gestations age",
  MPROPER_TIME_OF_COLLECTION: "Improper time of collection",
  IMPROPER_TIME_OF_COLLECTION: "Improper time of collection",

  INCOMPLETE_TRF: "Incomplete TRF",
  INSUFFICIENT_VOLUME: "Insufficient volume",
  OTHERS: "Others",
  ON_MEDICATION: "On Medication",
  NO_CLINICAL_HISTORY: "No Clinical history",
  REPORT_READY: "REPORT Ready",
  SAMPLE_COLLECTED_FROM_IV_LIVE: "Sample collected from IV line",

  SPECIMEN_SITE_NOT_MENTIONED_BIOPSY: "Specimen site not mentioned (Biopsy)",
  SAMPLE_CONTAMINATION: "Sample contamination",
  value: "SAMPLE_CONTAMINATION",
  TRANSIT_DELAY: "Transit delay",
  WRONG_CONTAINER: "Wrong container",
}

export const centers = [
  {
    name: "ALL",
    _id: "ALL",
    status: "true"
  },
]

export const users = [
  {
    name: "Data Operator",
    value: "DATA_OPERATOR",
  },
  {
    name: "Doctor",
    value: "DOCTOR",
  },
  {
    name: "Front Desk",
    value: "FRONT_DESK",
  },
  {
    name: "Help Desk",
    value: "HELP_DESK",
  },
  {
    name: "Radiologist",
    value: "RADIOLOGIST",
  },
  {
    name: "Sample Process Manager",
    value: "SAMPLE_PROCESS_MANAGER",
  },
]
export const genderTypesMap = {
  MALE: "Male",
  FEMALE: "Female",
}

export const userTypes = {
  ADMIN: "Admin",
  LAB_DIRECTOR: "Lab Director",
  LAB_PHYSICIAN: "Lab Physician",
  LAB_ASSISTANT: "Lab Assistant",
  RADIOLOGIST: "Radiologist",
  DOCTOR: "Doctor",
  DATA_OPERATOR: "Data Operator",
  FRONT_DESK: "Front Desk",
  HELP_DESK: "Help Desk",
  SAMPLE_PROCESS_MANAGER: "Sample Process Manager",
}

export const userRolesByPermission = [
  {
    role: "ADMIN",
    permissions: [
      "ADD_PATIENT_REPORT",
      "EDIT_NON_APPROVE_PATIENT_REPORT",
      "EDIT_APPROVE_PATIENT_REPORT",
      "FINALIZE_REPORT",
      "EDIT_TEMPLATE",
      "DELETE_TEMPLATE",
    ],
  },
  {
    role: "RADIOLOGIST",
    permissions: [
      "ADD_PATIENT_REPORT",
      "EDIT_NON_APPROVE_PATIENT_REPORT",
      // "EDIT_APPROVE_PATIENT_REPORT",
      "FINALIZE_REPORT",
      // "EDIT_TEMPLATE",
      // "DELETE_TEMPLATE",
    ],
  },
  {
    role: "DOCTOR",
    permissions: [
      "ADD_PATIENT_REPORT",
      "EDIT_NON_APPROVE_PATIENT_REPORT",
      // "EDIT_APPROVE_PATIENT_REPORT",
      "FINALIZE_REPORT",
      "EDIT_TEMPLATE",
      // "DELETE_TEMPLATE",
    ],
  },
  {
    role: "DATA_OPERATOR",
    permissions: [
      "ADD_PATIENT_REPORT",
      "EDIT_NON_APPROVE_PATIENT_REPORT",
      // "EDIT_TEMPLATE",
      // "DELETE_TEMPLATE",
    ],
  },
  {
    role: "FRONT_DESK",
    permissions: [],
  },
  {
    role: "HELP_DESK",
    permissions: [],
  },
  {
    role: "SAMPLE_PROCESS_MANAGER",
    permissions: [],
  },
  {
    role: "SUPER_ADMIN",
    permissions: ["MOVE_TO_LIVE"],
  },
  {
    role: "LAB_MANAGER",
    permissions: [
      "ADD_PATIENT_REPORT",
      "EDIT_NON_APPROVE_PATIENT_REPORT",
      "EDIT_APPROVE_PATIENT_REPORT",
      "FINALIZE_REPORT",
      "EDIT_TEMPLATE",
      "DELETE_TEMPLATE",
    ],
  },
]

export const radiologyReportTypes = [
  "Xray",
  "Ultrasound",
  "Mammogram",
  "CT Scan",
  "MRI",
  "Miscellaneous",
]
export const genomicsReportTypes = [
  'ATN1 GENE TRIPLET REPEAT ANALYSIS FOR DRPLA',
  "Beta Thalassemia Screening",
  "BCR-ABL KD MUTATION ANALYSIS (IRMA)",
  "BCR-ABL QUALITATIVE TEST",
  "BCR-ABL IS -QUANTITATIVE TEST",
  "BK Virus Quantitative PCR",
  "BK Virus Qualitative -PCR",
  "Chlamydia Trachomatis Qualitative Test",
  "CLOPIDOGREL/PRASUGREL/TICAGRELOR",
  "Congenital adrenal hyperplasia CYP21A2",
  "Cystic Fibrosis",
  "Chromosomal Micro array",
  "DMD Analysis by MLPA",
  "DPYD",
  "Fragile-X-PCR",
  "Factor- II Prothrombin Mutation",
  "Factor- V Leiden Mutation Screening",
  "FRIEDREICH ATAXIA",
  "GILBERT SYNDROME",
  // "HEPATITIS -C VIRUS (HCV) GENOTYPE",
  "HSV-1/2 Quantitative -PCR",
  "HLA B51 TYPING",
  "HUNTINGTON - CAG REPEAT ANALYSIS",
  "JAK2 E-14 MUTATION ANALYSIS",
  "JAK2 (EXONS 12-15) MUTATION ANALYSIS",
  "JAK2 REFLEX PANEL",
  "Maternal Cell Contamination Testing Report (MCC)",
  "MTHFR Screening",
  "MTHFR, Factor V and II screening",
  "MPN Panel",
  "MYOTONIC DYSTROPHY 1&2",
  "NIPT",
  "NIPT with Micro Deletions",
  "Parental Carrier Testing",
  "Prothrombotic Profile",
  "PML-RARA QUALITATIVE TEST",
  "PML-RARA QUANTITATIVE TEST",
  "QF PCR",
  "Rubella Pcr",
  "SCA Panel Testing",
  "SMA analysis by RFLP",
  // "SPERM DNA FRAGMENTATION TEST",
  "Spinal Muscular Atrophy Deletion & Duplication Analysis (SMN_MLPA)",
  // "SMA Analysis - Negative",
  // "DMD Analysis - Negative",
  "Tacrolimus",
  "Targeted Testing",
  "Targeted Sanger Sequencing",
  "TPMT",
  // "Urine Organic Acid (GC-MS)",
  "Whole Exome Sequencing",
  "Y Chromosome Microdeletion",
  "Factor- II Prothrombin Mutation",
  "Factor- V Leiden Mutation Screening",
]
export const immunoHematologyReportTypes = [
  "BLOOD GROUP",
  "DIRECT ANTIGLOBULIN TEST",
  "INDIRECT ANTIGLOBULIN TEST",
  "RED CELL ANTIBODY SCREENING"
]
export const molecularGeneticsReportTypes = [
  "ADENOVIRUS QUALITATIVE REAL TIME PCR",
  "ANTI-MICROBIAL RESISTANCE GENE DETECTION - BY MULTIPLEX PCR",
  "ASPERGILLUS QUALITATIVE PCR",
  "BCR-ABL QUALITATIVE",
  "BCR-ABL QUANTITATIVE",
  "BK POLYOMA QUANTITATIVE PCR",
  "BK VIRUS - BLOOD",
  "CAREBAPENEM PCR",
  "CANDIDA AURIS QUALITATIVE PCR",
  "CBNAAT MTB/XDR",
  "CHIKUNGUNYA RNA DETECTION BY PCR",
  "CHLAMYDIA TRACHOMATIS DNA QUALITATIVE TEST",
  "CLOSTRIDIUM DIFFICILE DETECTION PCR",
  "COMPREHENSIVE RESPIRATORY PANEL 1 BACTERIA/VIRUS",
  "CBNAAT GENEXPERT MTB WITH RIFAMPICIN QUALITATIVE",
  "COMPREHENSIVE RESPIRATORY PANEL 2 BACTERIA/VIRUS/FUNGI",
  "COMPREHENSIVE RESPIRATORY PANEL 3 BACTERIA/VIRUS/FUNGI",
  "COVID-19 VIRUS QUALITATIVE PCR",
  "CRYPTOCOCCUS QUALITATIVE PCR",
  "CYTOMEGALO VIRUS (CMV) QUANTITATIVE- PCR",
  "CYTOMEGALOVIRUS (CMV) QUALITATIVE - PCR",
  "EGFR",
  "EPSTEIN BAR VIRUS QUALITATIVE - BY REAL TIME PCR",
  "EXTENDED SPECTRUM BETA-LACTAMS (ESBL) RESISTANCE GENE DETECTION BY MULTIPLEX PCR",
  "FLU PANEL - BY REAL TIME PCR",
  "FUNGAL MULTIPLEX PCR PANEL",
  "HBV DNA QUALITATIVE PCR",
  "HBV DNA QUANTITATIVE REAL TIME PCR",
  "HCV RNA QUALITATIVE REAL TIME PCR",
  "HCV RNA QUANTITATIVE REAL TIME PCR",
  "HEPATITIS B VIRUS (HBV) GENOTYPE",
  "HEPATITIS -C VIRUS (HCV) GENOTYPE",
  "HERPES SIMPLEX VIRUS 1 & 2 PCR- QUANTITATIVE",
  "HERPES SIMPLEX VIRUS 1 & 2 PCR- QUALITATIVE",
  "HIV 1 DRUG RESISTANCE - GENOTYPING",
  "HIV 1 RNA QUALITATIVE REAL TIME PCR",
  "HIV-1 VIRAL LOAD QUANTITATIVE -PCR",
  "HIV-1 DNA, Qualitative (Proviral DNA)",
  "HIV-2 RNA QUANTITATIVE REAL TIME PCR",
  "HLA B27 BY RT PCR (QUALITATIVE)",
  "H1N1/SWINE FLU BY REAL TIME PCR",
  "HUMAN PAPILLOMA VIRUS (HPV) QUALITATIVE",
  "INFLUENZA H3N2 by REAL TIME PCR",
  "JAK2 GENE (Exon 14) V617F MUTATION DETECTION",
  "JAPANESE ENCEPHALITIS VIRUS (JEV) QUALITATIVE PCR",
  "JC VIRUS DNA DETECTION",
  "LEPTOSPIRA DETECTION PCR",
  "KRAS",
  "MDR MTB WITH RIFAMPICIN QUALITATIVE",
  "MEASLES PCR",
  "MRSA SCREENING BY QUALITATIVE PCR",
  "MSI BY SANGER SEQUENCING",
  "MUCORALES QUALITATIVE PCR",
  "MUMPS VIRUS QUALITATIVE",
  "MYCOBACTERIUM TUBERCULOSIS PCR (TB-PCR)",
  "MYCOBACTERIUM TUBERCULOSIS (MTB) &amp; NON-TUBERCULOUS MYCOBACTERIUM (NTM) DIFFERENTIATION BY QUALITATIVE PCR",
  "NEISSERIA GONORRHOEA DNA QUALITATIVE",
  "NON-TUBERCULOUS MYCOBACTERIUM (NTM) QUALITATIVE PCR",
  "PAN FUNGAL DNA PCR",
  "PML-RARA QUANTITATIVE",
  "PML-RARA QUALITATIVE",
  "RESPIRATORY PANEL - BY REAL TIME PCR",
  "RICKETSSIA PCR",
  "RUBELLA PCR QUALITATIVE",
  "SCRUB TYPHUS PCR",
  "SPERM DNA FRAGMENTATION",
  "SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR",
  "SEPSIS PANELWITH AMR GENES - BY MULTIPLEX PCR V2",
  "SYNOVIAL FLUID/JOINT INFECTION - MULTIPLEX PCR",
  "TORCH PANEL REALTIME PCR",
  "TOXOPLASMA QUALITATIVE PCR",
  "TROPICAL FEVER PANEL",
  "VIRAL MENINGITIS PANEL - BY MULTIPLEX REAL TIME PCR",
  "VRE DETECTION BY QUALITATIVE PCR",
  "VRSA DETECTION QUALITATIVE BY REAL TIME PCR",
  "WEST NILE VIRUS PCR",
]
export const cytometryReportTypes = [
  "CD3,CD4,CD8 COUNT-FLOWCYTOMETRY",
  "CD4,CD8 COUNT - FLOWCYTOMETRY",
  "CD-4 COUNT",
  "HLA B27 - FLOWCYTOMETRY",
  "SPERM DNA FRAGMENTATION",
]
export const transplantImmunoGenetics = [
  "CDC CROSS MATCH",
  "Donor Specific Antibody(DSA-IgG) Test",
  "HLA B * 51 / B * 52",
  "HLA - CLASS II DQ TYPING",
  "HLA TYPING CLASS 1 & 2 CLASS TYPE",
  "HLA FLOW CYTOMETRY T & B CELL CROSSMATCH",
  "PRA CLASS I& CLASS II QUANTITATIVE",
  "HLA TYPING A B DRB1",
  "HLA SAB CLASS I II TYPING",
]

export const histopathologyTypes = ["HISTOPATHOLOGY(LARGE SPECIMEN)","HISTOPATHOLOGY(BIOPSY)"]
export const tmsTypes = [
  "CARNITINE",
  "CSF AMINO ACIDS",
  "CSF GLYCINE",
  "HEMOGLOBINOPATHIES",
  "INTEGRATED QUADRUPLE MARKER",
  "METHYLMALONIC ACID QUANTITATIVE - SERUM",
  "METHYLMALONIC ACID QUANTITATIVE - URINE",
  "METHYLMALONIC ACID QUALITATIVE - URINE",
  "NEWBORN SCREENING (BIO-1)",
  "NEWBORN SCREENING (BIO-2)",
  "NEWBORN SCREENING (BIO-3)",
  "NEWBORN SCREENING (BIO-4)",
  "NEWBORN SCREENING (BIO-5)",
  "NEWBORN SCREENING (BIO-6)",
  "NEWBORN SCREENING (BIO-7)",
  "OEMGA 3 AND OMEGA 6",
  "PLASMA AMINO ACIDS",
  "PLASMA GLYCINE",
  "PLGF",
  "TMS",
  "URINE AMINO ACIDS",
  "URINARY GLYCOSAMINOGLYCANS (GAG) QUANTIFICATION",
  "VERY LONG CHAIN FATTY ACID",
  "VITAMIN - A  ALL TRANSRETINOL",
  "VITAMIN - B1",
  "VITAMIN - B2",
  "VITAMIN B2; RIBOFLAVIN",
  "VITAMIN - B3",
  "VITAMIN - B5",
  "VITAMIN - B6",
  "VITAMIN - B7",
  "VITAMIN - B9",
  "VITAMIN - C TOTAL ASCORBIC",
  "VITAMIN - E ALPHATOCOPHEROL",
  "VITAMIN - K1",
  "Fabry_Leukocytes",
  "MPS II_Leukocytes",
  "Gaucher_Leukocytes",
  "Mucolipidosis II and III_Plasma",
  "Glycoasparginase_Leukocytes",
  "GM1_Leukocytes",
  "Krabbe_Leukocytes",
  "Leukocytes_NCL2",
  "MLD_Leukocytes",
  "MPS I_Leukocytes",
  "MPS IIIA_Leukocytes",
  "MPS IIIB_Leukocytes",
  "MPS IIIC_Leukocytes",
  "MPS IIID_Leukocytes",
  "MPS IVA_Leukocytes",
  "MPS VI_Leukocytes",
  "MPS VII_Leukocytes",
  "NCL1_Leukocytes",
  "Acid Lipase_Leukocytes",
  "Pompe_Leukocytes",
  "Schindler_Leukocytes",
  "Taysach,Sandhoff_Leukocytes",
  "Niemann Pick AB_Leukocytes",
  "Neuraminidase_Fibroblasts",
  "Alpha-Fucosidosis_Leukocytes",
  "Alpha-Mannosidosis_Leukocytes",
  "Beta-Mannosidosis_Leukocytes",
  "Biotinidase_Plasma",
  "Chitotriosidase_Plasma"
] //BIOCHEMICAL_GENETICS
export const doubleMarkersReportTypeMap = {
  DOUBLE_MARKERS: "DOUBLE MARKERS",
  'MPS II_Leukocytes': "MPS II_Leukocytes",
  'MPS IIIA_Leukocytes': "MPS IIIA_Leukocytes",
  'MPS IIIB_Leukocytes': "MPS IIIB_Leukocytes",
  'MPS IIIC_Leukocytes': "MPS IIIC_Leukocytes",
  'MPS IIID_Leukocytes': "MPS IIID_Leukocytes",
  'MPS IVA_Leukocytes':"MPS IVA_Leukocytes",
  'MPS VI_Leukocytes':"MPS VI_Leukocytes",
  'MPS VII_Leukocytes':"MPS VII_Leukocytes",
  URINE_ORGANIC_ACID: "URINE ORGANIC ACID",
  TMS: "TMS",
  TRIPLE_MARKERS: "TRIPLE MARKERS",
  QUADRUPLE_MARKERS: "QUADRUPLE MARKERS",
  Plasma_Glycine: "PLASMA GLYCINE",
  PLASMA_AMINO_ACIDS: "PLASMA AMINO ACIDS",
  OEMGA_3_AND_OMEGA_6: "OEMGA 3 AND OMEGA 6",
  INTEGRATED_QUADRUPLE_MARKERS: "INTEGRATED QUADRUPLE MARKERS",
  EARLY_QUADRUPLE_MARKERS: "EARLY QUADRUPLE MARKERS",
  PLASMA_CREATINE_GUANIDINO_ACETIC_ACID_LEVELS:
    "PLASMA CREATINE & GUANIDINO ACETIC ACID LEVELS",
  CSF_AMINO_ACIDS: "CSF AMINO ACIDS",
  CSF_GLYCENE: "CSF GLYCINE",
  Carnitine: "CARNITINE",
  Hemoglobinopathies: "HEMOGLOBINOPATHIES",
  PLGF: "PLGF",
  URINE_AMINO_ACIDS: "URINE AMINO ACIDS",
  Urinary_Glycosaminoglycans_GAG_Quantification:
    "URINARY GLYCOSAMINOGLYCANS (GAG) QUANTIFICATION",
  VERY_LONG_CHAIN_FATTY_ACID: "VERY LONG CHAIN FATTY ACID",
  METHYLMALONIC_ACID_QUANTITATIVE_SERUM:"METHYLMALONIC ACID QUANTITATIVE - SERUM",
  METHYLMALONIC_ACID_QUALITATIVE_URINE:"METHYLMALONIC ACID QUALITATIVE - URINE",
  METHYLMALONIC_ACID_QUANTITATIVE_URINE:"METHYLMALONIC ACID QUANTITATIVE - URINE",
  NEWBORN_SCREENING_BIO_1: "NEWBORN SCREENING (BIO-1)",
  NEWBORN_SCREENING_BIO_2: "NEWBORN SCREENING (BIO-2)",
  NEWBORN_SCREENING_BIO_3: "NEWBORN SCREENING (BIO-3)",
  NEWBORN_SCREENING_BIO_4: "NEWBORN SCREENING (BIO-4)",
  NEWBORN_SCREENING_BIO_5: "NEWBORN SCREENING (BIO-5)",
  NEWBORN_SCREENING_BIO_6: "NEWBORN SCREENING (BIO-6)",
  NEWBORN_SCREENING_BIO_7: "NEWBORN SCREENING (BIO-7)",
  VITAMIN_A_ALL_TRANSRETINOL: "VITAMIN - A  ALL TRANSRETINOL",
  VITAMIN_B1: "VITAMIN - B1",
  VITAMIN_B2: "VITAMIN - B2",
  VITAMIN_B2_RIBOFLAVIN:"VITAMIN B2; RIBOFLAVIN",
  VITAMIN_B3: "VITAMIN - B3",
  VITAMIN_B5:"VITAMIN - B5",
  VITAMIN_B6: "VITAMIN - B6",
  VITAMIN_B7: "VITAMIN - B7",
  VITAMIN_B9: "VITAMIN - B9",
  VITAMIN_C_TOTAL_ASCORBIC: "VITAMIN - C TOTAL ASCORBIC",
  VITAMIN_E_ALPHATOCOPHEROL: "VITAMIN - E ALPHATOCOPHEROL",
  VITAMIN_K1: "VITAMIN - K1",
  URINE_GUANIDINO_ACETIC_ACID_LEVELS:
    "URINE CREATINE & GUANIDINO ACETIC ACID LEVELS",
  Alpha_Fucosidosis_Leukocytes: "Alpha-Fucosidosis_Leukocytes",
  Alpha_Mannosidosis_Leukocytes: "Alpha-Mannosidosis_Leukocytes",
  Beta_Mannosidosis_Leukocytes: "Beta-Mannosidosis_Leukocytes",
  Biotinidase_Plasma: "Biotinidase_Plasma",
  Chitotriosidase_Plasma: "Chitotriosidase_Plasma",
  Gaucher_Leukocytes: "Gaucher_Leukocytes",
  Fabry_Leukocytes: "Fabry_Leukocytes",
  Leukocytes_NCL2: "Leukocytes_NCL2",
  'MPS I_Leukocytes':"MPS I_Leukocytes",
  MLD_Leukocytes:"MLD_Leukocytes",
  GM1_Leukocytes:"GM1_Leukocytes",
  Glycoasparginase_Leukocytes:"Glycoasparginase_Leukocytes",
  'Mucolipidosis II and III_Plasma': "Mucolipidosis II and III_Plasma",
  'Taysach,Sandhoff_Leukocytes': "Taysach,Sandhoff_Leukocytes",
  'Acid Lipase_Leukocytes': "Acid Lipase_Leukocytes",
  Schindler_Leukocytes: "Schindler_Leukocytes",
  'Niemann Pick AB_Leukocytes': "Niemann Pick AB_Leukocytes",
  NCL1_Leukocytes: "NCL1_Leukocytes",
  Neuraminidase_Fibroblasts: "Neuraminidase_Fibroblasts",
  Pompe_Leukocytes: "Pompe_Leukocytes",
  Yoda_Shishu_Samraksha_pannel:"YODA SHISHU SAMRAKSHA PANEL",
  Krabbe_Leukocytes:"Krabbe_Leukocytes"
};
export const biochemicalGeneticsTypeMap = {
  TMS: "TMS",
  Carnitine: "CARNITINE",
  Hemoglobinopathies: "HEMOGLOBINOPATHIES",
  CSF_GLYCENE: "CSF GLYCINE",
  CSF_AMINO_ACIDS: "CSF AMINO ACIDS",
  INTEGRATED_QUADRUPLE_MARKER: "INTEGRATED QUADRUPLE MARKER",
  OEMGA_3_AND_OMEGA_6: "OEMGA 3 AND OMEGA 6", //BIOCHEMICAL_GENETICS
  PLASMA_AMINO_ACIDS: "PLASMA AMINO ACIDS",
  Plasma_Glycine: "PLASMA GLYCINE",
  PLGF: "PLGF",
  URINE_AMINO_ACIDS: "URINE AMINO ACIDS",
  Urinary_Glycosaminoglycans_GAG_Quantification:
    "URINARY GLYCOSAMINOGLYCANS (GAG) QUANTIFICATION",
  VERY_LONG_CHAIN_FATTY_ACID: "VERY LONG CHAIN FATTY ACID",
  METHYLMALONIC_ACID_QUANTITATIVE_SERUM:"METHYLMALONIC ACID QUANTITATIVE - SERUM",
  METHYLMALONIC_ACID_QUALITATIVE_URINE:"METHYLMALONIC ACID QUALITATIVE - URINE",
  METHYLMALONIC_ACID_QUANTITATIVE_URINE:"METHYLMALONIC ACID QUANTITATIVE - URINE",
  NEWBORN_SCREENING_BIO_1: "NEWBORN SCREENING (BIO-1)",
  NEWBORN_SCREENING_BIO_2: "NEWBORN SCREENING (BIO-2)",
  NEWBORN_SCREENING_BIO_3: "NEWBORN SCREENING (BIO-3)",
  NEWBORN_SCREENING_BIO_4: "NEWBORN SCREENING (BIO-4)",
  NEWBORN_SCREENING_BIO_5: "NEWBORN SCREENING (BIO-5)",
  NEWBORN_SCREENING_BIO_6: "NEWBORN SCREENING (BIO-6)",
  NEWBORN_SCREENING_BIO_7: "NEWBORN SCREENING (BIO-7)",
  VITAMIN_A_ALL_TRANSRETINOL: "VITAMIN - A  ALL TRANSRETINOL",
  VITAMIN_B1: "VITAMIN - B1",
  VITAMIN_B2: "VITAMIN - B2",
  VITAMIN_B2_RIBOFLAVIN:"VITAMIN B2; RIBOFLAVIN",
  VITAMIN_B3: "VITAMIN - B3",
  VITAMIN_B5:"VITAMIN - B5",
  VITAMIN_B6: "VITAMIN - B6",
  VITAMIN_B7: "VITAMIN - B7",
  VITAMIN_B9: "VITAMIN - B9",
  VITAMIN_C_TOTAL_ASCORBIC: "VITAMIN - C TOTAL ASCORBIC",
  VITAMIN_E_ALPHATOCOPHEROL: "VITAMIN - E ALPHATOCOPHEROL",
  VITAMIN_K1: "VITAMIN - K1",
  LYSOSOMAL_STORAGE_DISORDERS:"LYSOSOMAL STORAGE DISORDERS",
  Alpha_Fucosidosis_Leukocytes:  "Alpha_Fucosidosis_Leukocytes",
  Alpha_Mannosidosis_Leukocytes:  "Alpha_Mannosidosis_Leukocytes",
  Beta_Mannosidosis_Leukocytes :  "Beta_Mannosidosis_Leukocytes",
  Biotinidase_Plasma :  "Biotinidase_Plasma",
  Chitotriosidase_Plasma:  "Chitotriosidase_Plasma",
  Gaucher_Leukocytes : "Gaucher_Leukocytes",
  Fabry_Leukocytes: "Fabry_Leukocytes",
  Leukocytes_NCL2:"Leukocytes_NCL2",
  Krabbe_Leukocytes:"Krabbe_Leukocytes",
  'MPS I_Leukocytes':"MPS I_Leukocytes",
  'MPS IIIB_Leukocytes':"MPS IIIB_Leukocytes",
  'MPS IIIC_Leukocytes':'MPS IIIC_Leukocytes',
  'MPS IIID_Leukocytes':'MPS IIID_Leukocytes',
  'MPS IVA_Leukocytes':'MPS IVA_Leukocytes',
  'MPS VI_Leukocytes':'MPS VI_Leukocytes',
  'MPS VII_Leukocytes':'MPS VII_Leukocytes',
  'MPS IIIA_Leukocytes':'MPS IIIA_Leukocytes',
  'MPS II_Leukocytes':'MPS II_Leukocytes',
  MLD_Leukocytes:"MLD_Leukocytes",
  GM1_Leukocytes:"GM1_Leukocytes",
  Glycoasparginase_Leukocytes:"Glycoasparginase_Leukocytes",
  'Acid Lipase_Leukocytes':"Acid Lipase_Leukocytes",
  Schindler_Leukocytes:"Schindler_Leukocytes",
  'Taysach,Sandhoff_Leukocytes':"Taysach,Sandhoff_Leukocytes",
  NCL1_Leukocytes:'NCL1_Leukocytes',
  'Mucolipidosis II and III_Plasma':'Mucolipidosis II and III_Plasma',
  Neuraminidase_Fibroblasts:'Neuraminidase_Fibroblasts',
  Pompe_Leukocytes:'Pompe_Leukocytes',
  'Niemann Pick AB_Leukocytes':'Niemann Pick AB_Leukocytes'
}
export const cytometryType = [
  {
    label: "CD3,CD4,CD8 COUNT-FLOWCYTOMETRY",
    value: "CD3_CD4_CD8_COUNT_FLOWCYTOMETRY",
  },
  {
    label: "CD4,CD8 COUNT - FLOWCYTOMETRY",
    value: "CD4_CD8_COUNT_FLOWCYTOMETRY",
  },
  {
    label: "CD-4 COUNT",
    value: "CD4_COUNT",
  },
  {
    label: "HLA B27 - FLOWCYTOMETRY",
    value: "HLA_B27_FLOWCYTOMETRY",
  },
  {
    label: "SPERM DNA FRAGMENTATION",
    value: 'SPERM_DNA_FRAGMENTATION'
  }
]
export const transplantImmunoGeneticsType = [
  {
    label: "CDC CROSS MATCH",
    value: "CDC_CROSS_MATCH",
  },
  {
    label: "Donor Specific Antibody(DSA-IgG) Test",
    value: "Donor_Specific_Antibody_DSA_IgG_Test",
  },
  {
    label: "HLA B * 51 / B * 52",
    value: "HLA_B_51_B_52",
  },
  {
    label: "HLA - CLASS II DQ TYPING",
    value: "HLA_CLASS_II_DQ_TYPING",
  },
  {
    label: "HLA TYPING CLASS 1 & 2 CLASS TYPE",
    value: "HLA_TYPING_CLASS_1_2_CLASS_TYPE",
  },
  {
    label: "HLA FLOW CYTOMETRY T & B CELL CROSSMATCH",
    value: "HLA_FLOW_CYTOMETRY_T_B_CELL_CROSSMATCH",
  },
  {
    label: "PRA CLASS I& CLASS II QUANTITATIVE",
    value: "PRA_CLASS_I_CLASS_II_QUANTITATIVE"
  },
  {
    label: "HLA TYPING A B DRB1",
    value: "HLA_TYPING_A_B_DRB_1"
  },
  {
    label: "HLA SAB CLASS I II TYPING",
    value: "HLA SAB CLASS I II TYPING"
  },
]
export const immunoHematologyTypes = [
  {
    label:"BLOOD GROUP",
    value:"BLOOD_GROUP",
  },
  {
    label:"DIRECT ANTIGLOBULIN TEST",
    value:"DIRECT_ANTIGLOBULIN_TEST"
  },
  {
    label:"INDIRECT ANTIGLOBULIN TEST",
    value:"INDIRECT_ANTIGLOBULIN_TEST"
  },
  {
    label:"RED CELL ANTIBODY SCREENING",
    value:"RED_CELL_ANTIBODY_SCREENING",
  },
]
export const molecularGeneticsType = [
  {
    label: "ADENOVIRUS QUALITATIVE REAL TIME PCR",
    value: "ADENOVIRUS_QUALITATIVE_REAL_TIME_PCR",
  },
  {
    label:"ANTI-MICROBIAL RESISTANCE GENE DETECTION - BY MULTIPLEX PCR",
    value:"ANTI_MICROBIAL_RESISTANCE_GENE_DETECTION_BY_MULTIPLEX_PCR"
  },
  {
    label:"ASPERGILLUS QUALITATIVE PCR",
    value:"ASPERGILLUS_QUALITATIVE_PCR"
  },
  {
    label: "BCR-ABL QUALITATIVE",
    value: "BCR_ABL_QUALITATIVE",
  },
  {
    label: "BCR-ABL QUANTITATIVE",
    value: "BCR_ABL_QUANTITATIVE",
  },
  {
    label: "BK POLYOMA QUANTITATIVE PCR",
    value: "BK_POLYOMA_QUANTITATIVE_PCR",
  },
  {
    label: "CHLAMYDIA TRACHOMATIS DNA QUALITATIVE TEST",
    value: "CHLAMYDIA_TRACHOMATIS_DNA_QUALITATIVE_TEST",
  },
  {
    label: "BK VIRUS - BLOOD",
    value: "BK_VIRUS_BLOOD",
  },
  {
    label:"CAREBAPENEM PCR",
    value:"CAREBAPENEM_PCR"
  },
  {
    label:"CANDIDA AURIS QUALITATIVE PCR",
    value:"CANDIDA_AURIS_QUALITATIVE_PCR"
  },
  {
    label:"CBNAAT MTB/XDR",
    value:"CBNAAT_GENEXPERT_MTB_XDR"
  },
  {
    label:"CBNAAT GENEXPERT MTB WITH RIFAMPICIN QUALITATIVE",
    value:"CBNAAT_GENEXPERT_MTB_WITH_RIFAMPICIN_QUALITATIVE"
  },
  {
    label:"CHIKUNGUNYA RNA DETECTION BY PCR",
    value:"CHIKUNGUNYA_RNA_DETECTION_BY_PCR"
  },
  {
    label:"CLOSTRIDIUM DIFFICILE DETECTION PCR",
    value:"CLOSTRIDIUM_DIFFICILE_DETECTION_PCR"
  },
  {
    label:"COMPREHENSIVE RESPIRATORY PANEL 1 BACTERIA/VIRUS",
    value:"COMPREHENSIVE_RESPIRATORY_PANEL_1_BACTERIA_VIRUSES"
  },
  {
    label:"COMPREHENSIVE RESPIRATORY PANEL 2 BACTERIA/VIRUS/FUNGI",
    value:"COMPREHENSIVE_RESPIRATORY_PANEL_2_BACTERIA_VIRUSES_FUNGI"
  },
  {
    label:"COMPREHENSIVE RESPIRATORY PANEL 3 BACTERIA/VIRUS/FUNGI",
    value:"COMPREHENSIVE_RESPIRATORY_PANEL_3_BACTERIA_VIRUSES_FUNGI"
  },
  {
    label: "COVID-19 VIRUS QUALITATIVE PCR",
    value: "COVID_19_VIRUS_QUALITATIVE_PCR",
  },
  {
    label:"CRYPTOCOCCUS QUALITATIVE PCR",
    value:"CRYPTOCOCCUS_QUALITATIVE_PCR"
  },
  {
    label: "CYTOMEGALO VIRUS (CMV) QUANTITATIVE- PCR",
    value: "CYTOMEGALO_VIRUS_CMV_QUANTITATIVE_PCR",
  },
  {
    label: "CYTOMEGALOVIRUS (CMV) QUALITATIVE - PCR",
    value: "CYTOMEGALOVIRUS_CMV_QUALITATIVE_PCR",
  },
  {
    label:"EGFR",
    value:"EGFR"
  },
  {
    label: "EPSTEIN BAR VIRUS QUALITATIVE - BY REAL TIME PCR",
    value: "EPSTEIN_BAR_VIRUS_QUALITATIVE_BY_REAL_TIME_PCR",
  },
  {
    label:"EXTENDED SPECTRUM BETA-LACTAMS (ESBL) RESISTANCE GENE DETECTION BY MULTIPLEX PCR",
    value:"EXTENDED_SPECTRUM_BETA_LACTAMS_ESBL_RESISTANCE_GENE_DETECTION_BY_MULTIPLEX_PCR"
  },
  {
    label:"FLU PANEL - BY REAL TIME PCR",
    value:"FLU_PANEL_BY_REAL_TIME_PCR"
  },
  {
    label:"FUNGAL MULTIPLEX PCR PANEL",
    value:"FUNGAL_MULTIPLEX_PCR_PANEL"
  },
  {
    label: "HBV DNA QUALITATIVE PCR",
    value: "HBV_DNA_QUALITATIVE_PCR",
  },
  {
    label: "HBV DNA QUANTITATIVE REAL TIME PCR",
    value: "HBV_DNA_QUANTITATIVE_REAL_TIME_PCR",
  },
  {
    label: "HCV RNA QUALITATIVE REAL TIME PCR",
    value: "HCV_RNA_QUALITATIVE_REAL_TIME_PCR",
  },
  {
    label: "HCV RNA QUANTITATIVE REAL TIME PCR",
    value: "HCV_RNA_QUANTITATIVE_REAL_TIME_PCR",
  },
  {
    label:"HEPATITIS B VIRUS (HBV) GENOTYPE",
    value:"HEPATITIS_B_VIRUS_HBV_GENOTYPE"
  },
  {
    label: "HEPATITIS C VIRUS (HCV) GENOTYPE",
    value: "HEPATITIS_C_VIRUS_HCV_GENOTYPE",
  },
  {
    label: "HERPES SIMPLEX VIRUS 1 & 2 PCR- QUANTITATIVE",
    value: "HERPES_SIMPLEX_VIRUS_1_2_PCR_QUANTITATIVE",
  },
  {
    label: "HERPES SIMPLEX VIRUS 1 & 2 PCR- QUALITATIVE",
    value: "HERPES_SIMPLEX_VIRUS_1_2_PCR_QUALITATIVE",
  },
  {
    label:"HIV 1 DRUG RESISTANCE - GENOTYPING",
    value:"HIV_1_DRUG_RESISTANCE_GENOTYPING"
  },
  {
    label: "HIV 1 RNA QUALITATIVE REAL TIME PCR",
    value: "HIV_1_RNA_QUALITATIVE_REAL_TIME_PCR",
  },
  {
    label: "HIV-1 VIRAL LOAD QUANTITATIVE -PCR",
    value: "HIV_1_VIRAL_LOAD_QUANTITATIVE_PCR",
  },
  {
    label: "HIV-1 DNA, Qualitative (Proviral DNA)",
    value: "HIV_1_DNA_Qualitative_Proviral_DNA",
  },
  {
    label: "HIV-2 RNA QUANTITATIVE REAL TIME PCR",
    value: "HIV_2_RNA_QUANTITATIVE_REAL_TIME_PCR",
  },
  {
    label: "HLA B27 BY RT PCR (QUALITATIVE)",
    value: "HLA_B27_BY_RT_PCR_QUALITATIVE",
  },
  {
    label: "H1N1/SWINE FLU BY REAL TIME PCR",
    value: "H1N1_SWINE_FLU_BY_REAL_TIME_PCR",
  },
  {
    label: "HUMAN PAPILLOMA VIRUS (HPV) QUALITATIVE",
    value: "HUMAN_PAPILLOMA_VIRUS_HPV_QUALITATIVE",
  },
  {
    label: "INFLUENZA H3N2 by REAL TIME PCR",
    value: "INFLUENZA_H3N2_by_REAL_TIME_PCR",
  },
  {
    label: "JAK2 GENE (Exon 14) V617F MUTATION DETECTION",
    value: "JAK2_Gene_Exon_14_V617F_Mutation_Detection",
  },
  {
    label: "JAPANESE ENCEPHALITIS VIRUS (JEV) QUALITATIVE PCR",
    value: "Japanese_Encephalitis_Virus_JEV_Qualitative_PCR",
  },
  {
    label: "JC VIRUS DNA DETECTION",
    value: "JC_VIRUS_DNA_DETECTION",
  },
  {
    label:"LEPTOSPIRA DETECTION PCR",
    value:"LEPTOSPIRA_DETECTION_PCR"
  },
  {
    label:"KRAS",
    value:"KRAS"
  },
  {
    label:"MDR MTB WITH RIFAMPICIN QUALITATIVE",
    value:"MDR_MTB_WITH_RIFAMPICIN_QUALITATIVE"
  },
  {
    label:"MEASLES PCR",
    value:"MEASLES_PCR",
  },
  {
    label:"MRSA SCREENING BY QUALITATIVE PCR",
    value:"MRSA_SCREENING_BY_QUALITATIVE_PCR"
  },
  {
    label:"MSI BY SANGER SEQUENCING",
    value:"MSI_BY_SANGER_SEQUENCING"
  },
  {
    label:"MUCORALES QUALITATIVE PCR",
    value:"MUCORALES_QUALITATIVE_PCR"
  },
  {
    label: "MUMPS VIRUS QUALITATIVE",
    value: "MUMPS_VIRUS_QUALITATIVE",
  },
  {
    label: "MYCOBACTERIUM TUBERCULOSIS PCR (TB-PCR)",
    value: "MYCOBACTERIUM_TUBERCULOSIS_PCR_TB_PCR",
  },
  {
    label:"MYCOBACTERIUM TUBERCULOSIS (MTB) &amp; NON-TUBERCULOUS MYCOBACTERIUM (NTM) DIFFERENTIATION BY QUALITATIVE PCR",
    value:"MYCOBACTERIUM_TUBERCULOSIS_MTB_AMP_NON_TUBERCULOUS_MYCOBACTERIUM_NTM_DIFFERENTIATION_BY_QUALITATIVE_PCR"
  },
  {
    label: "NEISSERIA GONORRHOEA DNA QUALITATIVE",
    value: "NEISSERIA_GONORRHOEA_DNA_QUALITATIVE",
  },
  {
    label:"NON-TUBERCULOUS MYCOBACTERIUM (NTM) QUALITATIVE PCR",
    value:"NON_TUBERCULOUS_MYCOBACTERIUM_NTM_QUALITATIVE_PCR"
  },
  {
    label:"PAN FUNGAL DNA PCR",
    value:"PAN_FUNGAL_DNA_PCR"
  },
  {
    label: "PML-RARA QUANTITATIVE",
    value: "PML_RARA_QUANTITATIVE",
  },
  {
    label: "PML-RARA QUALITATIVE",
    value: "PML_RARA_QUALITATIVE",
  },
  {
    label: "RESPIRATORY PANEL - BY REAL TIME PCR",
    value: "RESPIRATORY_PANEL_BY_REAL_TIME_PCR",
  },
  {
    label: "RUBELLA PCR QUALITATIVE",
    value: "RUBELLA_PCR_QUALITATIVE",
  },
  {
    label:"SCRUB TYPHUS PCR",
    value:"SCRUB_TYPHUS_PCR"
  },
  {
    label: "SPERM DNA FRAGMENTATION",
    value: "SPERM_DNA_FRAGMENTATION",
  },
  {
    label:"TOXOPLASMA QUALITATIVE PCR",
    value:"TOXOPLASMA_QUALITATIVE_PCR"
  },
  {
    label: 'TROPICAL FEVER PANEL',
    value: 'TROPICAL_FEVER_PANEL'
  },
  {
    label: "RICKETSSIA PCR",
    value: "RICKETSSIA_PCR"
  },
  {
    label: "VIRAL MENINGITIS PANEL - BY MULTIPLEX REAL TIME PCR",
    value: "VIRAL_MENINGITIS_PANEL_BY_MULTIPLEX_REAL_TIME_PCR",
  },
  {
    label:"VRSA DETECTION QUALITATIVE BY REAL TIME PCR",
    value:"VRSA_DETECTION_QUALITATIVE_BY_REAL_TIME_PCR"
  },
  {
    label: "WEST NILE VIRUS PCR",
    value: "WEST_NILE_VIRUS_PCR",
  },
  {
    label: "SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR",
    value: "SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR",
  },
  {
    label:"SEPSIS PANELWITH AMR GENES - BY MULTIPLEX PCR V2",
    value:"SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR_V2"
  },
  {
    label:"SYNOVIAL FLUID/JOINT INFECTION - MULTIPLEX PCR",
    value:"SYNOVIAL_FLUID_JOINT_INFECTION_MULTIPLEX_PCR"
  },
  {
    label:"TORCH PANEL REALTIME PCR",
    value:"TORCH_PANEL_REALTIME_PCR"
  },
  {
    label:"VRE DETECTION BY QUALITATIVE PCR",
    value:"VRE_DETECTION_BY_QUALITATIVE_PCR"
  },
  {
    label: "Alpha-Fucosidosis_Leukocytes",
    value: "Alpha_Fucosidosis_Leukocytes",
  },
  {
    label: "Alpha-Mannosidosis_Leukocytes",
    value: "Alpha_Mannosidosis_Leukocytes",
  },
  {
    label: "Beta-Mannosidosis_Leukocytes",
    value: "Beta_Mannosidosis_Leukocytes",
  },
  {
    label: "Biotinidase_Plasma",
    value: "Biotinidase_Plasma",
  },
  {
    label: "Chitotriosidase_Plasma",
    value: "Chitotriosidase_Plasma",
  },
]

export const CytopathalogyTypes = [
  {
    label:"CELL BLOCK",
    value:"CELL_BLOCK"
  },
  {
    label: "Cytology Slide Reporting",
    value: "Cytology_Slide_Reporting",
  },
  {
    label: "FNAC (Sample In Clinic)",
    value: "FNAC",
  },
  {
    label: "Fluid For Cytology",
    value: "Fluid_For_Cytology",
  },
  {
    label: "Liquid Based Cytology - Pap Smear",
    value: "Liquid_Based_Cytology",
  },
  {
    label: "Fnac Slides Reporting",
    value: "Fnac_Slides_Reporting",
  },
  {
    label: "Pus For Cytology",
    value: "Pus_For_Cytology",
  },
  {
    label: "PAP SMEAR ABOVE 40",
    value: "PAP SMEAR ABOVE 40",
  },
  {
    label: "Smear For Malignant Cells",
    value: "Smear For Malignant Cells",
  },
  {
    label: "Urine For Cytology",
    value: "Urine_For_Cytology",
  },
  {
    label: "PAP SMEAR - CONVENTIONAL",
    value: "PAP SMEAR - CONVENTIONAL",
  },
  {
    label: "Sputum For Cytology",
    value: "Sputum_For_Cytology",
  },
]
export const molecularGenomicsType = [
  {
    label: 'ATN1 GENE TRIPLET REPEAT ANALYSIS FOR DRPLA',
    value: 'ATN1_GENE_TRIPLET_REPEAT_ANALYSIS_FOR_DRPLA'
  },
  {
    label: "Beta Thalassemia Screening",
    value: "Beta_Thalassemia_Screening",
  },
  {
    label: "BCR-ABL KD MUTATION ANALYSIS (IRMA)",
    value: "BCR_ABL_KD_MUTATION_ANALYSIS_IRMA",
  },
  {
    label: "BCR-ABL QUALITATIVE TEST",
    value: "BCR_ABL_Qualitative_Test",
  },
  {
    label: "BCR-ABL IS -QUANTITATIVE TEST",
    value: "BCR_ABL_IS_Quantitative_Test",
  },
  {
    label: "BK Virus Quantitative PCR",
    value: "BK_Virus_Quantitative_PCR",
  },
  {
    label: "BK Virus Qualitative -PCR",
    value: "BK_Virus_Qualitative_PCR",
  },
  {
    label: "CD-4 COUNT",
    value: "CD4_COUNT",
  },
  {
    label: "CLOPIDOGREL/PRASUGREL/TICAGRELOR",
    value: "CLOPIDOGREL_PRASUGREL_TICAGRELOR",
  },
  {
    label: "Congenital adrenal hyperplasia CYP21A2",
    value: "Congenital_adrenal_hyperplasia_CYP21A2",
  },
  {
    label: "Cystic Fibrosis",
    value: "Cystic_Fibrosis",
  },

  {
    label: "Chlamydia Trachomatis Qualitative Test",
    value: "CHLAMYDIA_TRACHOMATIS_QUALITATIVE_TEST",
  },
  {
    label: "Chromosomal Micro array",
    value: "Chromosomal_Micro_array",
  },
  {
    label: "DMD Analysis by MLPA",
    value: "DMD_Analysis_Mlpa",
  },
  {
    label: "DPYD",
    value: "DPYD",
  },
  {
    label: "Factor- II Prothrombin Mutation",
    value: "Factor_II_Prothrombin_Mutation",
  },
  {
    label: "Factor- V Leiden Mutation Screening",
    value: "Factor_V_Leiden_Mutation_Screening",
  },
  {
    label: "Fragile-X-PCR",
    value: "FRAGILE_X_PCR",
  },
  {
    label: "FRIEDREICH ATAXIA",
    value: "FRIEDREICH_ATAXIA",
  },
  {
    label: "GILBERT SYNDROME",
    value: "GILBERT_SYNDROME",
  },
  {
    label: "HSV-1/2 Quantitative -PCR",
    value: "HSV_1_2_Quantitative_PCR",
  },
  // {
  //   label: "HEPATITIS C VIRUS (HCV) GENOTYPE",
  //   value: "HEPATITIS_C_VIRUS_HCV_GENOTYPE",
  // },
  {
    label: "HLA B51 TYPING",
    value: "HLA_B51_TYPING",
  },
  {
    label: "HUNTINGTON - CAG REPEAT ANALYSIS",
    value: "HUNTINGTON_CAG_REPEAT_ANALYSIS",
  },
  {
    label: "JAK2 E-14 MUTATION ANALYSIS",
    value: "JAK2_E_14_MUTATION_ANALYSIS",
  },
  {
    label: "JAK2 (EXONS 12-15) MUTATION ANALYSIS",
    value: "JAK2_EXONS_12_15_MUTATION_ANALYSIS",
  },
  {
    label: "JAK2 REFLEX PANEL",
    value: "JAK2_REFLEX_PANEL",
  },
  {
    label: "Maternal Cell Contamination Testing Report (MCC)",
    value: "Maternal_Cell_Contamination_Testing_Report_MCC",
  },
  {
    label: "MTHFR Screening",
    value: "MTHFR_Screening",
  },
  {
    label: "MTHFR, Factor V and II screening",
    value: "MTHFR_Factor_V_and_II_screening",
  },
  {
    label: "MPN Panel",
    value: "MPN_Panel",
  },
  {
    label: "MYOTONIC DYSTROPHY 1&2",
    value: "Myotonic_Dystrophy_1_2",
  },
  {
    label: "NIPT",
    value: "NIPT",
  },
  {
    label: "NIPT with Micro Deletions",
    value: "NIPT_with_Micro_Deletions",
  },
  {
    label: "Parental Carrier Testing",
    value: "Parental_Carrier_Testing",
  },
  {
    label: "Prothrombotic Profile",
    value: "Prothrombotic_Profile",
  },
  {
    label: "PML-RARA QUALITATIVE TEST",
    value: "PML_RARA_QUALITATIVE_TEST",
  },
  {
    label: "PML-RARA QUANTITATIVE TEST",
    value: "PML_RARA_QUANTITATIVE_TEST",
  },
  {
    label: "QF PCR",
    value: "QF_PCR",
  },
  {
    label: "Rubella Pcr",
    value: "RUBELLA_PCR",
  },

  {
    label: "SCA Panel Testing",
    value: "SCA_Panel_Testing",
  },
  {
    label: "SMA analysis by RFLP",
    value: "SMA_ANALYSIS_BY_RFLP",
  },
  // {
  //   label: "SPERM DNA FRAGMENTATION TEST",
  //   value: "SPERM_DNA_FRAGMENTATION_TEST"
  // },
  // {
  //   label: "SPERM DNA FRAGMENTATION",
  //   value: 'SPERM_DNA_FRAGMENTATION'
  // },
  {
    label: "Spinal Muscular Atrophy Deletion & Duplication Analysis (SMN_MLPA)",
    value: "Spinal_Muscular_Atrophy_Deletion_Duplication_Analysis_SMN_MLPA",
  },

  {
    label: "Tacrolimus",
    value: "Tacrolimus",
  },
  {
    label: "Targeted Testing",
    value: "Targeted_Testing",
  },
  {
    label: "Targeted Sanger Sequencing",
    value: "Targeted_Sanger_Sequencing",
  },
  {
    label: "TPMT",
    value: "TPMT",
  },

  // {
  //   label: "Urine Organic Acid (GC-MS)",
  //   value: "Urine_Organic_Acid_GC_MS",
  // },

  {
    label: "Whole Exome Sequencing",
    value: "WHOLE_EXOME_SEQUESNCING",
  },

  {
    label: "Y Chromosome Microdeletion",
    value: "Y_Chromosome_Microdeletion",
  },

  // {
  //   label: "SMA Analysis - Negative",
  //   value: "SMA_Analysis",
  // },

  // {
  //   label: "DMD Analysis - Negative",
  //   value: "DMD_Analysis_Negative",
  // },
]
export const doubleMarkersTypes = [
  {
    label: "CARNITINE",
    value: "Carnitine",
  },
  {
    label: "CSF AMINO ACIDS",
    value: "CSF_AMINO_ACIDS",
  },
  {
    label: "CSF GLYCINE",
    value: "CSF_GLYCENE",
  },
  {
    label: "DOUBLE MARKERS",
    value: "DOUBLE_MARKERS",
  },
  {
    value: "EARLY_QUADRUPLE_MARKERS",
    label: "EARLY QUADRUPLE MARKERS",
  },
  {
    label: "HEMOGLOBINOPATHIES",
    value: "Hemoglobinopathies",
  },
  {
    value: "INTEGRATED_QUADRUPLE_MARKERS",
    label: "INTEGRATED QUADRUPLE MARKERS",
  },
  {
    label:"METHYLMALONIC ACID QUANTITATIVE - SERUM",
    value:"METHYLMALONIC_ACID_QUANTITATIVE_SERUM"
  },
  { 
    value:"METHYLMALONIC_ACID_QUALITATIVE_URINE",
    label:"METHYLMALONIC ACID QUALITATIVE - URINE",
  },
  {
    value:"METHYLMALONIC_ACID_QUANTITATIVE_URINE",
    label:"METHYLMALONIC ACID QUANTITATIVE - URINE"
  },
  {
    label: "NEWBORN SCREENING (BIO-1)",
    value: "NEWBORN_SCREENING_BIO_1",
  },
  {
    label: "NEWBORN SCREENING (BIO-2)",
    value: "NEWBORN_SCREENING_BIO_2",
  },
  {
    label: "NEWBORN SCREENING (BIO-3)",
    value: "NEWBORN_SCREENING_BIO_3",
  },
  {
    label: "NEWBORN SCREENING (BIO-4)",
    value: "NEWBORN_SCREENING_BIO_4",
  },
  {
    label: "NEWBORN SCREENING (BIO-5)",
    value: "NEWBORN_SCREENING_BIO_5",
  },
  {
    label: "NEWBORN SCREENING (BIO-6)",
    value: "NEWBORN_SCREENING_BIO_6",
  },
  {
    label: "NEWBORN SCREENING (BIO-7)",
    value: "NEWBORN_SCREENING_BIO_7",
  },
  {
    label: "OEMGA 3 AND OMEGA 6",
    value: "OEMGA_3_AND_OMEGA_6",
  },
  {
    label: "PLASMA AMINO ACIDS",
    value: "PLASMA_AMINO_ACIDS",
  },
  {
    label: "PLASMA CREATINE & GUANIDINO ACETIC ACID LEVELS",
    value: "PLASMA_CREATINE_GUANIDINO_ACETIC_ACID_LEVELS",
  },
  {
    label: "PLASMA GLYCINE",
    value: "Plasma_Glycine",
  },
  {
    label: "PLGF",
    value: "PLGF",
  },
  {
    label: "QUADRUPLE MARKERS",
    value: "QUADRUPLE_MARKERS",
  },
  {
    label: "TMS",
    value: "TMS",
  },
  {
    label: "TRIPLE MARKERS",
    value: "TRIPLE_MARKERS",
  },
  {
    label: "URINE ORGANIC ACID",
    value: "URINE_ORGANIC_ACID",
  },
  {
    label: "URINE AMINO ACIDS",
    value: "URINE_AMINO_ACIDS",
  },
  {
    label: "URINARY GLYCOSAMINOGLYCANS (GAG) QUANTIFICATION",
    value: "Urinary_Glycosaminoglycans_GAG_Quantification",
  },
  {
    value: "URINE_GUANIDINO_ACETIC_ACID_LEVELS",
    label: "URINE CREATINE & GUANIDINO ACETIC ACID LEVELS",
  },
  {
    label: "VERY LONG CHAIN FATTY ACID",
    value: "VERY_LONG_CHAIN_FATTY_ACID",
  },
  {
    label: "VITAMIN - A  ALL TRANSRETINOL",
    value: "VITAMIN_A_ALL_TRANSRETINOL",
  },
  {
    label: "VITAMIN - B1",
    value: "VITAMIN_B1",
  },
  {
    label: "VITAMIN - B2",
    value: "VITAMIN_B2",
  },
  {
    label: "VITAMIN - B3",
    value: "VITAMIN_B3",
  },
  {
    label: "VITAMIN - B5",
    value: "VITAMIN_B5",
  },
  {
    label: "VITAMIN - B6",
    value: "VITAMIN_B6",
  },
  {
    label: "VITAMIN - B7",
    value: "VITAMIN_B7",
  },
  {
    label: "VITAMIN - B9",
    value: "VITAMIN_B9",
  },
  {
    label: "VITAMIN - C TOTAL ASCORBIC",
    value: "VITAMIN_C_TOTAL_ASCORBIC",
  },
  {
    label: "VITAMIN - E ALPHATOCOPHEROL",
    value: "VITAMIN_E_ALPHATOCOPHEROL",
  },
  {
    label: "VITAMIN - K1",
    value: "VITAMIN_K1",
  },
  {
    label: "Alpha-Fucosidosis_Leukocytes",
    value: "Alpha_Fucosidosis_Leukocytes",
  } ,
  {
    label: "Alpha-Mannosidosis_Leukocytes",
    value: "Alpha_Mannosidosis_Leukocytes",
  } ,
  {
    label: "Beta-Mannosidosis_Leukocytes",
    value: "Beta_Mannosidosis_Leukocytes",
  } ,
  {
    label: "Biotinidase_Plasma",
    value: "Biotinidase_Plasma",
  } ,
  {
    label: "Chitotriosidase_Plasma",
    value: "Chitotriosidase_Plasma",
  } ,
  {
    label: "Fabry_Leukocytes",
    value: "Fabry_Leukocytes",
  } ,
  {
    label: "Gaucher_Leukocytes",
    value: "Gaucher_Leukocytes",
  } ,
  {
    label: "Leukocytes_NCL2",
    value: "Leukocytes_NCL2",
  } ,
  {
    label: "Krabbe_Leukocytes",
    value: "Krabbe_Leukocytes",
  } ,
  {
    label: "Glycoasparginase_Leukocytes",
    value: "Glycoasparginase_Leukocytes",
  } ,
  {
    label: "MPS I_Leukocytes",
    value: "MPS I_Leukocytes",
  } ,
  {
    label: "GM1_Leukocytes",
    value: "GM1_Leukocytes",
  } ,
  {
    label: "MLD_Leukocytes",
    value: "MLD_Leukocytes",
  } ,
 {
  label: "MPS II_Leukocytes",
  value: "MPS II_Leukocytes",
} ,
{
  label: "MPS IIIA_Leukocytes",
  value: "MPS IIIA_Leukocytes",
} ,
{
  label: "MPS IIIB_Leukocytes",
  value: "MPS IIIB_Leukocytes",
} ,
{
  label: "MPS IIIC_Leukocytes",
  value: "MPS IIIC_Leukocytes",
} ,  
{
  label: "MPS IIID_Leukocytes",
  value: "MPS IIID_Leukocytes",
} ,
{
  label: "MPS IVA_Leukocytes",
  value: "MPS IVA_Leukocytes",
} ,
{
  label: "MPS VI_Leukocytes",
  value: "MPS VI_Leukocytes",
} ,
{
  label: "MPS VII_Leukocytes",
  value: "MPS VII_Leukocytes ",
} ,
{
  label: "Acid Lipase_Leukocytes",
  value: "Acid Lipase_Leukocytes",
},
{
  label: "Schindler_Leukocytes",
  value: "Schindler_Leukocytes",
},
{
  label: "NCL1_Leukocytes",
  value: "NCL1_Leukocytes",
},
{
  label: "Mucolipidosis II and III_Plasma",
  value: "Mucolipidosis II and III_Plasma",
},
{
  label: "Taysach,Sandhoff_Leukocytes",
  value: "Taysach,Sandhoff_Leukocytes",
},
{
  label: "Neuraminidase_Fibroblasts",
  value: "Neuraminidase_Fibroblasts",
},
{
  label: "Pompe_Leukocytes",
  value: "Pompe_Leukocytes",
},
{
  label: "Niemann Pick AB_Leukocytes",
  value: "Niemann Pick AB_Leukocytes",
},
]
export const cytogeneticsCategorys = [
  {
    label: "KARYOTYPING",
    value: "KARYOTYPING",
  },
  {
    label: "FISH",
    value: "FISH",
  },
]
export const cytogeneticsTypes = [ 
  "KARYOTYPING","SOILDFISH","FISH"]
  export const corporateTypes = [ 
    "Honda Hyundai Motors","Bharathi Cement","Reliance Industries","Bajaj Finserv Ltd","Indian Oil Corporation Ltd"
]
  
  export const cytogeneticsTypesMap = [ 
    {label: " KARYOTYPING" ,value : "KARYOTYPING"},
    {label: " SOILDFISH" ,value : "SOILDFISH"},
    {label: " FISH" ,value : "FISH"},
    ]
    export const CytopathalogyMap = {
      CELL_BLOCK:"CELL BLOCK",
      Cytology_Slide_Reporting:'Cytology Slide Reporting',
      FNAC:"FNAC (Sample In Clinic)",
      Fluid_For_Cytology:"Fluid For Cytology",
      Liquid_Based_Cytology:"Liquid Based Cytology - Pap Smear",
      Fnac_Slides_Reporting:"Fnac Slides Reporting",
      Pus_For_Cytology:"Pus For Cytology",
      'PAP SMEAR ABOVE 40':"PAP SMEAR ABOVE 40",
      'Smear For Malignant Cells':"Smear For Malignant Cells",
      Urine_For_Cytology:"Urine For Cytology",
      'PAP SMEAR - CONVENTIONAL':"PAP SMEAR - CONVENTIONAL",
      Sputum_For_Cytology:"Sputum For Cytology"
    }
export const histopathologyTypesMap = [   
  "HISTOPATHOLOGY(LARGE SPECIMEN)",
  "HISTOPATHOLOGY(BIOPSY)",
];

export const histopathologyIHCTypesMap = [
  "IHC - SINGLE MARKER",
  "IHC - 10 MARKERS PANEL",
  "IHC - 2 MARKERS",
  "IHC - 3 MARKERS",
  "IHC - 4 MARKER",
  "IHC - 6 - 8 ANTIGENS",
  "IHC - ER PR HER2 NEU & KI67",
  "IHC - ER, PR , HER2NEU",
  "IHC - ER/PR",
  "IHC 4 - 6 MARKERS",
  "IHC 8 - 10 MARKERS",
  "IHC > 10 MARKERS",
  "IHC COMPREHENSIVE DIAGNOSTIC PANEL",
  "IHC GLIOMA PANEL",
  "IHC HER 2 NEU",
  "IHC LIVER MARKERS",
  "IHC LYMPHOMA PANEL",
  "IHC SOFT TISSUE TUMOR MARKERS",
  "IHC INI 1",
  "IHC MASPIN",
  "IHC MUC 4",
  "IHC PIT 1",
  "IHC PLA2R",
  "IHC ALK",
  "IHC AMACR",
  "IHC ANDROGEN RECEPTOR",
  "IHC BCI 6",
  "IHC BCL 2",
  "IHC CALCITONIN",
  "IHC CALDESMON",
  "IHC CALPONIN",
  "IHC CALRETININ",
  "IHC CD 3",
  "IHC CD 10",
  "IHC CD 117",
  "IHC CD 138",
  "IHC CD 15",
  "IHC CD 19",
  "IHC CD 20",
  "IHC CD 23",
  "IHC CD 30",
  "IHC CD 31",
  "IHC CD 34",
  "IHC CD 4",
  "IHC CD 45 LCA",
  "IHC CD 56",
  "IHC CD 79 A B CELL",
  "IHC CD 99",
  "IHC CDX2",
  "IHC CHROMOGRANIN",
  "IHC CK 19",
  "IHC CYCLIN D1",
  "IHC CYTOKERATIN 20",
  "IHC CYTOKERATIN 5",
  "IHC CYTOKERATIN 7OV TL 12 30",
  "IHC DESMIN",
  "IHC DOG1",
  "IHC E CADHERIN",
  "IHC EBV",
  "IHC EMA",
  "IHC GATA 3",
  "IHC GFAP",
  "IHC GLIOMA PANEL",
  "IHC GLYPICAN 3",
  "IHC HMB 45",
  "IHC IDH1",
  "IHC INSM1",
  "IHC KAPPA AND LAMBDA LIGHT CHAIN",
  "IHC KAPPA LIGHT CHAINS",
  "IHC KI 67",
  "IHC LAMBDA LIGHT CHAINS",
  "IHC MART 1",
  "IHC MELAN A",
  "IHC MESOTHELIN",
  "IHC MLH 1",
  "IHC MPO",
  "IHC MSH 2",
  "IHC MSH 6",
  "IHC MUM1 PROTEIN",
  "IHC MYOGENIN",
  "IHC NAPSIN A",
  "IHC NEUROENDOCRINE PANEL",
  "IHC NKX 3 1",
  "IHC NUT NUTM1",
  "IHC OCT3 4",
  "IHC OLIG2",
  "IHC P 16",
  "IHC P 53",
  "IHC P 63",
  "IHC PANCYTOKERATIN",
  "IHC PAX 8",
  "IHC PAX 5",
  "IHC PCK",
  "IHC PMS 2",
  "IHC PSMA",
  "IHC RCC",
  "IHC S100",
  "IHC SALL 4",
  "IHC SATB 2",
  "IHC SMA",
  "IHC SOMATOSTATIN RECEPTOR 2 SSTR2",
  "IHC SOX 10",
  "IHC SOX 11",
  "IHC STAT 6",
  "IHC SYNAPTOPHYSIN",
  "IHC TDT",
  "IHC TTF 1",
  "IHC VIMENTIN",
  "IHC WT 1",
  "IMMUNOHISTOCHEMISTRY D L1 CLONE 22 C3",
  "IMMUNOHISTOCHEMISTRY P L1 CLONE SP 142",
  "IMMUNOHISTOCHEMISTRY PD L1 CLONE SP 263",
  "MSI MMR BY IHC",
  "SARCOMA PANEL 5 IHC MARKERS",
  "SARCOMA PANEL REFLEX TO FISH",
];
export const histopathologyTypesMaps = [ 
  {
    label: "HISTOPATHOLOGY(LARGE SPECIMEN)",
    value: "HISTOPATHOLOGY(LARGE SPECIMEN)",
  },
  {
    label: "HISTOPATHOLOGY(BIOPSY)",
    value: "HISTOPATHOLOGY(BIOPSY)",
  },  
];
export const histopathologyTypesIHCMaps = [
  {
    label: "IHC - SINGLE MARKER",
    value: "IHC - SINGLE MARKER",
  },
  {
    label: "IHC - 10 MARKERS PANEL",
    value: "IHC - 10 MARKERS PANEL",
  },
  {
    label: "IHC - 2 MARKERS",
    value: "IHC - 2 MARKERS",
  },
  {
    label: "IHC - 3 MARKERS",
    value: "IHC - 3 MARKERS",
  },
  {
    label: "IHC - 4 MARKER",
    value: "IHC - 4 MARKER",
  },
  {
    label: "IHC - 6 - 8 ANTIGENS",
    value: "IHC - 6 - 8 ANTIGENS",
  },
  {
    label: "IHC - ER PR HER2 NEU & KI67",
    value: "IHC - ER PR HER2 NEU & KI67",
  },
  {
    label: "IHC - ER, PR , HER2NEU",
    value: "IHC - ER, PR , HER2NEU",
  },
  {
    label: "IHC - ER/PR",
    value: "IHC - ER/PR",
  },
  {
    label: "IHC 4 - 6 MARKERS",
    value: "IHC 4 - 6 MARKERS",
  },
  {
    label: "IHC 8 - 10 MARKERS",
    value: "IHC 8 - 10 MARKERS",
  },
  {
    label: "IHC > 10 MARKERS",
    value: "IHC > 10 MARKERS",
  },
  {
    label: "IHC COMPREHENSIVE DIAGNOSTIC PANEL",
    value: "IHC COMPREHENSIVE DIAGNOSTIC PANEL",
  },
  {
    label: "IHC GLIOMA PANEL",
    value: "IHC GLIOMA PANEL",
  },
  {
    label: "IHC HER 2 NEU",
    value: "IHC HER 2 NEU",
  },
  {
    label: "IHC LIVER MARKERS",
    value: "IHC LIVER MARKERS",
  },
  {
    label: "IHC LYMPHOMA PANEL",
    value: "IHC LYMPHOMA PANEL",
  },
  {
    label: "IHC SOFT TISSUE TUMOR MARKERS",
    value: "IHC SOFT TISSUE TUMOR MARKERS",
  },
  {
    label: "HISTOPATHOLOGY(IHC - 4 MARKERS)",
    value: "HISTOPATHOLOGY(IHC - 4 MARKERS)",
  },
  {
    label: "IHC INI 1",
    value: "IHC INI 1",
  },
  {
      label: "IHC MASPIN",
      value: "IHC MASPIN",
  },
  {
      label: "IHC MUC 4",
      value: "IHC MUC 4",
  },
  {
      label: "IHC PIT 1",
      value: "IHC PIT 1",
  },
  {
      label: "IHC PLA2R",
      value: "IHC PLA2R",
  },
  {
      label: "IHC ALK",
      value: "IHC ALK",
  },
  {
      label: "IHC AMACR",
      value: "IHC AMACR",
  },
  {
      label: "IHC ANDROGEN RECEPTOR",
      value: "IHC ANDROGEN RECEPTOR",
  },
  {
      label: "IHC BCI 6",
      value: "IHC BCI 6",
  },
  {
      label: "IHC BCL 2",
      value: "IHC BCL 2",
  },
  {
      label: "IHC CALCITONIN",
      value: "IHC CALCITONIN",
  },
  {
      label: "IHC CALDESMON",
      value: "IHC CALDESMON",
  },
  {
      label: "IHC CALPONIN",
      value: "IHC CALPONIN",
  },
  {
      label: "IHC CALRETININ",
      value: "IHC CALRETININ",
  },
  {
      label: "IHC CD 3",
      value: "IHC CD 3",
  },
  {
      label: "IHC CD 10",
      value: "IHC CD 10",
  },
  {
      label: "IHC CD 117",
      value: "IHC CD 117",
  },
  {
      label: "IHC CD 138",
      value: "IHC CD 138",
  },
  {
      label: "IHC CD 15",
      value: "IHC CD 15",
  },
  {
      label: "IHC CD 19",
      value: "IHC CD 19",
  },
  {
      label: "IHC CD 20",
      value: "IHC CD 20",
  },
  {
      label: "IHC CD 23",
      value: "IHC CD 23",
  },
  {
      label: "IHC CD 30",
      value: "IHC CD 30",
  },
  {
      label: "IHC CD 31",
      value: "IHC CD 31",
  },
  {
      label: "IHC CD 34",
      value: "IHC CD 34",
  },
  {
      label: "IHC CD 4",
      value: "IHC CD 4",
  },
  {
      label: "IHC CD 45 LCA",
      value: "IHC CD 45 LCA",
  },
  {
      label: "IHC CD 56",
      value: "IHC CD 56",
  },
  {
      label: "IHC CD 79 A B CELL",
      value: "IHC CD 79 A B CELL",
  },
  {
      label: "IHC CD 99",
      value: "IHC CD 99",
  },
  {
      label: "IHC CDX2",
      value: "IHC CDX2",
  },
  {
      label: "IHC CHROMOGRANIN",
      value: "IHC CHROMOGRANIN",
  },
  {
      label: "IHC CK 19",
      value: "IHC CK 19",
  },
  {
      label: "IHC CYCLIN D1",
      value: "IHC CYCLIN D1",
  },
  {
      label: "IHC CYTOKERATIN 20",
      value: "IHC CYTOKERATIN 20",
  },
  {
      label: "IHC CYTOKERATIN 5",
      value: "IHC CYTOKERATIN 5",
  },
  {
      label: "IHC CYTOKERATIN 7OV TL 12 30",
      value: "IHC CYTOKERATIN 7OV TL 12 30",
  },
  {
      label: "IHC DESMIN",
      value: "IHC DESMIN",
  },
  {
      label: "IHC DOG1",
      value: "IHC DOG1",
  },
  {
      label: "IHC E CADHERIN",
      value: "IHC E CADHERIN",
  },
  {
      label: "IHC EBV",
      value: "IHC EBV",
  },
  {
      label: "IHC EMA",
      value: "IHC EMA",
  },
  {
      label: "IHC GATA 3",
      value: "IHC GATA 3",
  },
  {
      label: "IHC GFAP",
      value: "IHC GFAP",
  },
  {
      label: "IHC GLIOMA PANEL",
      value: "IHC GLIOMA PANEL",
  },
  {
      label: "IHC GLYPICAN 3",
      value: "IHC GLYPICAN 3",
  },
  {
      label: "IHC HMB 45",
      value: "IHC HMB 45",
  },
  {
      label: "IHC IDH1",
      value: "IHC IDH1",
  },
  {
      label: "IHC INSM1",
      value: "IHC INSM1",
  },
  {
      label: "IHC KAPPA AND LAMBDA LIGHT CHAIN",
      value: "IHC KAPPA AND LAMBDA LIGHT CHAIN",
  },
  {
      label: "IHC KAPPA LIGHT CHAINS",
      value: "IHC KAPPA LIGHT CHAINS",
  },
  {
      label: "IHC KI 67",
      value: "IHC KI 67",
  },
  {
      label: "IHC LAMBDA LIGHT CHAINS",
      value: "IHC LAMBDA LIGHT CHAINS",
  },
  {
      label: "IHC MART 1",
      value: "IHC MART 1",
  },
  {
      label: "IHC MELAN A",
      value: "IHC MELAN A",
  },
  {
      label: "IHC MESOTHELIN",
      value: "IHC MESOTHELIN",
  },
  {
      label: "IHC MLH 1",
      value: "IHC MLH 1",
  },
  {
      label: "IHC MPO",
      value: "IHC MPO",
  },
  {
      label: "IHC MSH 2",
      value: "IHC MSH 2",
  },
  {
      label: "IHC MSH 6",
      value: "IHC MSH 6",
  },
  {
      label: "IHC MUM1 PROTEIN",
      value: "IHC MUM1 PROTEIN",
  },
  {
      label: "IHC MYOGENIN",
      value: "IHC MYOGENIN",
  },
  {
      label: "IHC NAPSIN A",
      value: "IHC NAPSIN A",
  },
  {
      label: "IHC NEUROENDOCRINE PANEL",
      value: "IHC NEUROENDOCRINE PANEL",
  },
  {
      label: "IHC NKX 3 1",
      value: "IHC NKX 3 1",
  },
  {
      label: "IHC NUT NUTM1",
      value: "IHC NUT NUTM1",
  },
  {
      label: "IHC OCT3 4",
      value: "IHC OCT3 4",
  },
  {
      label: "IHC OLIG2",
      value: "IHC OLIG2",
  },
  {
      label: "IHC P 16",
      value: "IHC P 16",
  },
  {
      label: "IHC P 53",
      value: "IHC P 53",
  },
  {
      label: "IHC P 63",
      value: "IHC P 63",
  },
  {
      label: "IHC PANCYTOKERATIN",
      value: "IHC PANCYTOKERATIN",
  },
  {
      label: "IHC PAX 8",
      value: "IHC PAX 8",
  },
  {
      label: "IHC PAX 5",
      value: "IHC PAX 5",
  },
  {
      label: "IHC PCK",
      value: "IHC PCK",
  },
  {
      label: "IHC PMS 2",
      value: "IHC PMS 2",
  },
  {
      label: "IHC PSMA",
      value: "IHC PSMA",
  },
  {
      label: "IHC RCC",
      value: "IHC RCC",
  },
  {
      label: "IHC S100",
      value: "IHC S100",
  },
  {
      label: "IHC SALL 4",
      value: "IHC SALL 4",
  },
  {
      label: "IHC SATB 2",
      value: "IHC SATB 2",
  },
  {
      label: "IHC SMA",
      value: "IHC SMA",
  },
  {
      label: "IHC SOMATOSTATIN RECEPTOR 2 SSTR2",
      value: "IHC SOMATOSTATIN RECEPTOR 2 SSTR2",
  },
  {
      label: "IHC SOX 10",
      value: "IHC SOX 10",
  },
  {
      label: "IHC SOX 11",
      value: "IHC SOX 11",
  },
  {
      label: "IHC STAT 6",
      value: "IHC STAT 6",
  },
  {
      label: "IHC SYNAPTOPHYSIN",
      value: "IHC SYNAPTOPHYSIN",
  },
  {
      label: "IHC TDT",
      value: "IHC TDT",
  },
  {
      label: "IHC TTF 1",
      value: "IHC TTF 1",
  },
  {
      label: "IHC VIMENTIN",
      value: "IHC VIMENTIN",
  },
  {
      label: "IHC WT 1",
      value: "IHC WT 1",
  },
  {
      label: "IMMUNOHISTOCHEMISTRY D L1 CLONE 22 C3",
      value: "IMMUNOHISTOCHEMISTRY D L1 CLONE 22 C3",
  },
  {
      label: "IMMUNOHISTOCHEMISTRY P L1 CLONE SP 142",
      value: "IMMUNOHISTOCHEMISTRY P L1 CLONE SP 142",
  },
  {
      label: "IMMUNOHISTOCHEMISTRY PD L1 CLONE SP 263",
      value: "IMMUNOHISTOCHEMISTRY PD L1 CLONE SP 263",
  },
  {
    label: "MSI MMR BY IHC",
    value: "MSI MMR BY IHC",
  },
  {
    label: "SARCOMA PANEL 5 IHC MARKERS",
    value: "SARCOMA PANEL 5 IHC MARKERS",
  },
  {
    label: "SARCOMA PANEL REFLEX TO FISH",
    value: "SARCOMA PANEL REFLEX TO FISH",
  }
];

export const corporateTypesMaps = [ 
  {
    label:  "Honda Hyundai Motors",
    value:  "Honda Hyundai Motors",
  },
  {
    label: "Bharathi Cement",
    value: "Bharathi Cement",
  },
  {
    label: "Reliance Industries",
    value: "Reliance Industries",
  }, {
    label: "Bajaj Finserv Ltd",
    value:  "Bajaj Finserv Ltd",
  }, {
    label: "Indian Oil Corporation Ltd",
    value:  "Indian Oil Corporation Ltd",
  },
]

export const molecularGenomicsReportTypeMap = {
  ATN1_GENE_TRIPLET_REPEAT_ANALYSIS_FOR_DRPLA: 'ATN1 GENE TRIPLET REPEAT ANALYSIS FOR DRPLA',
  WHOLE_EXOME_SEQUESNCING: "Whole Exome Sequencing",
  RUBELLA_PCR: "Rubella Pcr",
  CHLAMYDIA_TRACHOMATIS_QUALITATIVE_TEST:
    "Chlamydia Trachomatis Qualitative Test",
  Chromosomal_Micro_array: "Chromosomal Micro array",
  FRAGILE_X_PCR: "Fragile-X-PCR",
  Y_Chromosome_Microdeletion: "Y Chromosome Microdeletion",
  // Urine_Organic_Acid_GC_MS: "Urine Organic Acid (GC-MS)",
  Spinal_Muscular_Atrophy_Deletion_Duplication_Analysis_SMN_MLPA:
    "Spinal Muscular Atrophy Deletion & Duplication Analysis (SMN_MLPA)",
  BK_Virus_Quantitative_PCR: "BK Virus Quantitative PCR",
  BK_Virus_Qualitative_PCR: "BK Virus Qualitative -PCR",
  // SMA_Analysis_Positive: "SMA Analysis",
  DMD_Analysis_Mlpa: "DMD Analysis by MLPA",
  // DMD_Analysis_Negative: "DMD Analysis",
  QF_PCR: "QF PCR",
  MTHFR_Screening: "MTHFR Screening",
  MTHFR_Factor_V_and_II_screening: "MTHFR, Factor V and II screening",
  Prothrombotic_Profile: "Prothrombotic Profile",
  SCA_Panel_Testing: "SCA Panel Testing",
  // SPERM_DNA_FRAGMENTATION_TEST: "SPERM DNA FRAGMENTATION TEST",
  Tacrolimus: "Tacrolimus",
  DPYD: "DPYD",
  HSV_1_2_Quantitative_PCR: "HSV-1/2 Quantitative -PCR",
  TPMT: "TPMT",
  GILBERT_SYNDROME: "GILBERT SYNDROME",
  Targeted_Testing: "Targeted Testing",
  Beta_Thalassemia_Screening: "Beta Thalassemia Screening",
  Cystic_Fibrosis: "Cystic Fibrosis",
  NIPT: "NIPT",
  NIPT_with_Micro_Deletions: "NIPT with Micro Deletions",
  PML_RARA_QUANTITATIVE_TEST: "PML-RARA QUANTITATIVE TEST",
  PML_RARA_QUALITATIVE_TEST: "PML-RARA QUALITATIVE TEST",
  JAK2_E_14_MUTATION_ANALYSIS: "JAK2 E-14 MUTATION ANALYSIS",
  HLA_B51_TYPING: "HLA B51 TYPING",
  // HEPATITIS_C_VIRUS_GENOTYPING: "HEPATITIS -C VIRUS GENOTYPING",
  BCR_ABL_IS_Quantitative_Test: "BCR-ABL IS -QUANTITATIVE TEST",
  BCR_ABL_Qualitative_Test: "BCR-ABL QUALITATIVE TEST",
  Factor_II_Prothrombin_Mutation: "Factor- II Prothrombin Mutation",
  Factor_V_Leiden_Mutation_Screening: "Factor- V Leiden Mutation Screening",
  Maternal_Cell_Contamination_Testing_Report_MCC:
    "Maternal Cell Contamination Testing Report (MCC)",
  Targeted_Sanger_Sequencing: "Targeted Sanger Sequencing",
  Parental_Carrier_Testing: "Parental Carrier Testing",
  FRIEDREICH_ATAXIA: "FRIEDREICH ATAXIA",
  MPN_Panel: "MPN Panel",
  JAK2_EXONS_12_15_MUTATION_ANALYSIS: "JAK2 (EXONS 12-15) MUTATION ANALYSIS",
  CLOPIDOGREL_PRASUGREL_TICAGRELOR: "CLOPIDOGREL/PRASUGREL/TICAGRELOR",
  Congenital_adrenal_hyperplasia_CYP21A2:
    "Congenital adrenal hyperplasia CYP21A2",
  Myotonic_Dystrophy_1_2: "MYOTONIC DYSTROPHY 1&2",
  JAK2_REFLEX_PANEL: "JAK2 REFLEX PANEL",
  SMA_ANALYSIS_BY_RFLP: "SMA analysis by RFLP",
  BCR_ABL_KD_MUTATION_ANALYSIS_IRMA: "BCR-ABL KD MUTATION ANALYSIS (IRMA)",
  HUNTINGTON_CAG_REPEAT_ANALYSIS: 'HUNTINGTON - CAG REPEAT ANALYSIS'
}

export const biochemicalGeneticsTypes = [
  {
    label: "CARNITINE",
    value: "Carnitine",
  },
  {
    label: "CSF GLYCINE",
    value: "CSF_GLYCENE",
  },
  {
    label: "CSF AMINO ACIDS",
    value: "CSF_AMINO_ACIDS",
  },
  {
    label: "HEMOGLOBINOPATHIES",
    value: "Hemoglobinopathies",
  },
  {
    label: "INTEGRATED QUADRUPLE MARKER",
    value: "INTEGRATED_QUADRUPLE_MARKER",
  },
  {
    label:"METHYLMALONIC ACID QUANTITATIVE - SERUM",
    value:"METHYLMALONIC_ACID_QUANTITATIVE_SERUM"
  },
  { 
    value:"METHYLMALONIC_ACID_QUALITATIVE_URINE",
    label:"METHYLMALONIC ACID QUALITATIVE - URINE",
  },
  {
    value:"METHYLMALONIC_ACID_QUANTITATIVE_URINE",
    label:"METHYLMALONIC ACID QUANTITATIVE - URINE"
  },
  {
    label: "NEWBORN SCREENING (BIO-1)",
    value: "NEWBORN_SCREENING_BIO_1",
  },
  {
    label: "NEWBORN SCREENING (BIO-2)",
    value: "NEWBORN_SCREENING_BIO_2",
  },
  {
    label: "NEWBORN SCREENING (BIO-3)",
    value: "NEWBORN_SCREENING_BIO_3",
  },
  {
    label: "NEWBORN SCREENING (BIO-4)",
    value: "NEWBORN_SCREENING_BIO_4",
  },
  {
    label: "NEWBORN SCREENING (BIO-5)",
    value: "NEWBORN_SCREENING_BIO_5",
  },
  {
    label: "NEWBORN SCREENING (BIO-6)",
    value: "NEWBORN_SCREENING_BIO_6",
  },
  {
    label: "NEWBORN SCREENING (BIO-7)",
    value: "NEWBORN_SCREENING_BIO_7",
  },
  {
    label: "OEMGA 3 AND OMEGA 6",
    value: "OEMGA_3_AND_OMEGA_6",
  },
  {
    label: "PLASMA AMINO ACIDS",
    value: "PLASMA_AMINO_ACIDS",
  },
  {
    label: "PLASMA GLYCINE",
    value: "Plasma_Glycine",
  },
  {
    label: "PLGF",
    value: "PLGF",
  },
  {
    label: "URINE AMINO ACIDS",
    value: "URINE_AMINO_ACIDS",
  },
  {
    label: "URINARY GLYCOSAMINOGLYCANS (GAG) QUANTIFICATION",
    value: "Urinary_Glycosaminoglycans_GAG_Quantification",
  },
  {
    label: "VERY LONG CHAIN FATTY ACID",
    value: "VERY_LONG_CHAIN_FATTY_ACID",
  },
  {
    label: "VITAMIN - A  ALL TRANSRETINOL",
    value: "VITAMIN_A_ALL_TRANSRETINOL",
  },
  {
    label: "VITAMIN - B1",
    value: "VITAMIN_B1",
  },
  {
    label: "VITAMIN - B2",
    value: "VITAMIN_B2",
  },
  {
    label:"VITAMIN B2; RIBOFLAVIN",
    value:"VITAMIN_B2_RIBOFLAVIN"
  },
  {
    label: "VITAMIN - B3",
    value: "VITAMIN_B3",
  },
  {
    label: "VITAMIN - B5",
    value: "VITAMIN_B5",
  },
  {
    label: "VITAMIN - B6",
    value: "VITAMIN_B6",
  },
  {
    label: "VITAMIN - B7",
    value: "VITAMIN_B7",
  },
  {
    label: "VITAMIN - B9",
    value: "VITAMIN_B9",
  },
  {
    label: "VITAMIN - C TOTAL ASCORBIC",
    value: "VITAMIN_C_TOTAL_ASCORBIC",
  },
  {
    label: "VITAMIN - E ALPHATOCOPHEROL",
    value: "VITAMIN_E_ALPHATOCOPHEROL",
  },
  {
    label: "VITAMIN - K1",
    value: "VITAMIN_K1",
  },
  {
    label: "Fabry_Leukocytes",
    value: "Fabry_Leukocytes",
  },
  {
    label: "MPS II_Leukocytes",
    value: "MPS II_Leukocytes",
  },
  {
    label: "Gaucher_Leukocytes",
    value: "Gaucher_Leukocytes",
  },
  {
    label: "Mucolipidosis II and III_Plasma",
    value: "Mucolipidosis II and III_Plasma",
  },
  {
    label: "Glycoasparginase_Leukocytes",
    value: "Glycoasparginase_Leukocytes",
  },
  {
    label: "GM1_Leukocytes",
    value: "GM1_Leukocytes",
  },
  {
    label: "Krabbe_Leukocytes",
    value: "Krabbe_Leukocytes",
  },
  {
    label: "Leukocytes_NCL2",
    value: "Leukocytes_NCL2",
  },
  {
    label: "MLD_Leukocytes",
    value: "MLD_Leukocytes",
  },
  {
    label: "MPS I_Leukocytes",
    value: "MPS I_Leukocytes",
  },
  {
    label: "MPS IIIA_Leukocytes",
    value: "MPS IIIA_Leukocytes",
  },
  {
    label: "MPS IIIB_Leukocytes",
    value: "MPS IIIB_Leukocytes",
  },
  {
    label: "MPS IIIC_Leukocytes",
    value: "MPS IIIC_Leukocytes",
  },
  {
    label: "MPS IIID_Leukocytes",
    value: "MPS IIID_Leukocytes",
  },
  {
    label: "MPS IVA_Leukocytes",
    value: "MPS IVA_Leukocytes",
  },
  {
    label: "MPS VI_Leukocytes",
    value: "MPS VI_Leukocytes",
  },
  {
    label: "MPS VII_Leukocytes",
    value: "MPS VII_Leukocytes",
  },
  {
    label: "NCL1_Leukocytes",
    value: "NCL1_Leukocytes",
  },
  {
    label: "Neuraminidase_Fibroblasts",
    value: "Neuraminidase_Fibroblasts",
  },
  {
    label: "Acid Lipase_Leukocytes",
    value: "Acid Lipase_Leukocytes",
  },
  {
    label: "Niemann Pick AB_Leukocytes",
    value: "Niemann Pick AB_Leukocytes",
  },
  {
    label: "Pompe_Leukocytes",
    value: "Pompe_Leukocytes",
  },
  {
    label: "Schindler_Leukocytes",
    value: "Schindler_Leukocytes",
  },
  {
    label: "Taysach,Sandhoff_Leukocytes",
    value: "Taysach,Sandhoff_Leukocytes",
  },
  {
    label: "LYSOSOMAL STORAGE DISORDERS",
    value: "LYSOSOMAL_STORAGE_DISORDERS",
  },    
  {
    label: "Alpha-Fucosidosis_Leukocytes",
    value: "Alpha_Fucosidosis_Leukocytes",
  } ,
  {
    label: "Alpha-Mannosidosis_Leukocytes",
    value: "Alpha_Mannosidosis_Leukocytes",
  } ,
  {
    label: "Beta-Mannosidosis_Leukocytes",
    value: "Beta_Mannosidosis_Leukocytes",
  } ,
  {
    label: "Biotinidase_Plasma",
    value: "Biotinidase_Plasma",
  } ,
  {
    label: "Chitotriosidase_Plasma",
    value: "Chitotriosidase_Plasma",
  } ,
  {
    label: "YODA SHISHU SAMRAKSHA PANEL",
    value: "Yoda_Shishu_Samraksha_pannel",
  } ,

]
export const serologyReportTypes = [
  {
    label: "ALLERGY DRUGS COMPREHENSIVE PANEL",
    value: "ALLERGY_DRUGS_COMPREHENSIVE_PANEL",
  },
  {
    label: "ALLERGY ANTIBIOTICS PANEL",
    value: "ALLERGY_ANTIBIOTICS_PANEL",
  },
  {
    label: "ALLERGY ATROPINE",
    value: "ALLERGY_ATROPINE",
  },
  {
    label: "ALLERGY ANALEGESIC & ANTIPYRETICS",
    value: "ALLERGY_ANALEGESIC_ANTIPYRETICS",
  },
  {
    label: "ALLERGY HORMONAL DRUGS",
    value: "ALLERGY_HORMONAL_DRUGS",
  },
  {
    label: "ALLERGY LIDOCAINE",
    value: "ALLERGY_LIDOCAINE",
  },
  {
    label: "ALLERGY COMPREHENSIVE PANEL",
    value: "ALLERGY_COMPREHENSIVE_PANEL",
  },
  {
    label: "ALLERGY PET & AVIAN",
    value: "ALLERGY_PET_AVIAN",
  },
  {
    label: "ALLERGY DUST MITE",
    value: "ALLERGY_DUST_MITE",
  },
  {
    label: "ALLERGY COMMON HOUSEHOLD INSECTS",
    value: "ALLERGY_COMMON_HOUSEHOLD_INSECTS",
  },
  {
    label: "ALLERGY GRASS PROFILE",
    value: "ALLERGY_GRASS_PROFILE",
  },
  {
    label: "ALLERGY MOULDS & YEAST",
    value: "ALLERGY_MOULDS_YEAST",
  },
  {
    label: "ALLERGY WEEDS & TREE ALLENGER",
    value: "ALLERGY_WEEDS_TREE_ALLENGER",
  },
  {
    label: "ALLERGY FOOD NON-VEG",
    value: "ALLERGY_FOOD_NON_VEG",
  },
  {
    label: "ALLERGY FOOD VEG & NON VEG PANEL",
    value: "ALLERGY_FOOD_VEG_NON_VEG_PANEL",
  },
  {
    label: "ALLERGY FOOD VEGETARIAN PANEL",
    value: "ALLERGY_FOOD_VEGETARIAN_PANEL",
  },
  {
    label: "ALLERGY FRUITS PANEL",
    value: "ALLERGY_FRUITS_PANEL",
  },
  {
    label: "ALLERGY MILK & DAIRY PRODUCTS",
    value: "ALLERGY_MILK_DAIRY_PRODUCTS",
  },
  {
    label: "ALLERGY SEED / NUTS / LEGUMES",
    value: "ALLERGY_SEED_NUTS_LEGUMES",
  },
  {
    label: "ALLERGY SPICES",
    value: "ALLERGY_SPICES",
  },
  {
    label: "ALLERGY OCCUPATIONAL",
    value: "ALLERGY_OCCUPATIONAL",
  },
  {
    label: "ALLERGY PARASITES",
    value: "ALLERGY_PARASITES",
  },
  {
    label: "INHALATIONAL SPECIFIC PANEL BY PHADIA TOP",
    value: "INHALATIONAL_SPECIFIC_PANEL_BY_PHADIA_TOP"
  },
  {
    label: "PHADIA TOP ALLERGY ECZEMA PANEL",
    value: "PHADIA_TOP_ALLERGY_ECZEMA_PANEL"
  },
  {
      label: "PHADIA TOP ALLERGY FOOD PANEL VEG AND NONVEG",
      value: "PHADIA_TOP_ALLERGY_FOOD_PANEL_VEG_AND_NONVEG"
  },
  {
      label: "PHADIA TOP ALLERGY INHALATION PANEL 20 PARAMETERS",
      value: "PHADIA_TOP_ALLERGY_INHALATION_PANEL_20_PARAMETERS"
  },
  {
      label: "PHADIA TOP ALLERGY NON VEG",
      value: "PHADIA_TOP_ALLERGY_NON_VEG"
  },
  {
      label: "PHADIA TOP ALLERGY PANEL VEGETARIAN",
      value: "PHADIA_TOP_ALLERGY_PANEL_VEGETARIAN"
  },
  {
      label: "PHADIA TOP ALLERGY PEANUT",
      value: "PHADIA_TOP_ALLERGY_PEANUT"
  },
  {
      label: "PHADIA TOP ALLERGY RHINITIS PANEL",
      value: "PHADIA_TOP_ALLERGY_RHINITIS_PANEL"
  },
  {
      label: "PHADIA TOP ALLERGY SCREENING DUST PANEL",
      value: "PHADIA_TOP_ALLERGY_SCREENING_DUST_PANEL"
  },
  {
      label: "PHADIATOP ALLERGY SCREENING",
      value: "PHADIATOP_ALLERGY_SCREENING"
  },
  {
      label: "PHADIA TOP ALLERGY MILK PRODUCTS PANEL",
      value: "PHADIA_TOP_ALLERGY_MILK_PRODUCTS_PANEL"
  },
  {
      label: "PHADIA TOP ALLERGY ASTHMA PANEL",
      value: "PHADIA_TOP_ALLERGY_ASTHMA_PANEL"
  },
  {
    label: "SENSISURE AERO ALLERGENS",
    value: "SENSISURE_AEROALLERGENS",
  },
  {
    label: "SENSISURE ALLERGY SCREENING",
    value: "SENSISURE_ALLERGY_SCREENING",
  },
  {
    label: "SENSISURE SINGLE ALLERGEN",
    value: "SENSISURE_SINGLE_ALLERGEN",
  },
  {
    label: "SENSISURE ANY 10 ALLERGENS",
    value: "SENSISURE_ANY_10_ALLERGENS"
  },
  {
      label: "SENSISURE ANY 20 ALLERGENS",
      value: "SENSISURE_ANY_20_ALLERGENS"
  },
  {
      label: "SENSISURE ANY 30 ALLERGENS",
      value: "SENSISURE_ANY_30_ALLERGENS"
  },
  {
      label: "SENSISURE ANY 5 ALLERGENS",
      value: "SENSISURE_ANY_5_ALLERGENS"
  },
  {
      label: "SENSISURE COMPREHENSIVE PANEL 48 PARAMETERS",
      value: "SENSISURE_COMPREHENSIVE_PANEL_48_PARAMETERS"
  },
  {
      label: "SENSISURE ECZEMA MAXI 27 PARAMETERS",
      value: "SENSISURE_ECZEMA_MAXI_27_PARAMETERS"
  },
  {
      label: "SENSISURE ECZEMA MINI 20 PARAMETERS",
      value: "SENSISURE_ECZEMA_MINI_20_PARAMETERS"
  },
  {
      label: "SENSISURE FRUIT 6 PARAMETERS",
      value: "SENSISURE_FRUIT_6_PARAMETERS"
  },
  {
      label: "SENSISURE NONVEG 8 PARAMETERS",
      value: "SENSISURE_NONVEG_8_PARAMETERS"
  },
  {
      label: "SENSISURE RHINITIS MAXI 22 PARAMETERS",
      value: "SENSISURE_RHINITIS_MAXI_22_PARAMETERS"
  },
  {
      label: "SENSISURE RHINITIS MINI 11 PARAMETERS",
      value: "SENSISURE_RHINITIS_MINI_11_PARAMETERS"
  },
  {
      label: "SENSISURE VEG 22 PARAMETERS",
      value: "SENSISURE_VEG_22_PARAMETERS"
  },
  {
      label: "SENSISURE VEG AND NONVEG 26 PARAMETERS",
      value: "SENSISURE_VEG_AND_NONVEG_26_PARAMETERS"
  }
]
export const pharmacoGenomicsTypes = [
  "DIABETES PGX",
  "RIVAROXABAN PGx",
  "WARFARIN/ACITROM BASIC",
]
export const pharmacoGenomicsType = [
  {
    label: "DIABETES PGX",
    value: "DIABETES_PGX",
  },
  {
    label: "RIVAROXABAN PGx",
    value: "RIVAROXABAN_PGx",
  },
  {
    label: "WARFARIN/ACITROM BASIC",
    value: "WARFARIN_ACITROM_BASIC",
  },
]
export const pharmacoGenomicsReportTypeMap = {
  DIABETES_PGX: "DIABETES PGX",
  RIVAROXABAN_PGx: "RIVAROXABAN PGx",
  WARFARIN_ACITROM_BASIC: "WARFARIN/ACITROM BASIC",
}
export const serologyImmunologyTypes = [
  "ALLERGY DRUGS COMPREHENSIVE PANEL",
  "ALLERGY ANTIBIOTICS PANEL",
  "ALLERGY ATROPINE",
  "ALLERGY ANALEGESIC & ANTIPYRETICS",
  "ALLERGY HORMONAL DRUGS",
  "ALLERGY LIDOCAINE",
  "ALLERGY COMPREHENSIVE PANEL",
  "ALLERGY PET & AVIAN",
  "ALLERGY DUST MITE",
  "ALLERGY COMMON HOUSEHOLD INSECTS",
  "ALLERGY GRASS PROFILE",
  "ALLERGY MOULDS & YEAST",
  "ALLERGY WEEDS & TREE ALLENGER",
  "ALLERGY FOOD NON - VEG",
  "ALLERGY FOOD VEG & NON VEG PANEL",
  "ALLERGY FOOD VEGETARIAN PANEL",
  "ALLERGY FRUITS PANEL",
  "ALLERGY MILK & DAIRY PRODUCTS",
  "ALLERGY SEED / NUTS / LEGUMES",
  "ALLERGY SPICES",
  "ALLERGY OCCUPATIONAL",
  "ALLERGY PARASITES",
  "INHALATIONAL SPECIFIC PANEL BY PHADIA TOP",  
  "PHADIA TOP ALLERGY ECZEMA PANEL",
  "PHADIA TOP ALLERGY FOOD PANEL VEG AND NONVEG",
  "PHADIA TOP ALLERGY INHALATION PANEL 20 PARAMETERS",
  "PHADIA TOP ALLERGY NON VEG",
  "PHADIA TOP ALLERGY PANEL VEGETARIAN",
  "PHADIA TOP ALLERGY PEANUT",
  "PHADIA TOP ALLERGY RHINITIS PANEL",
  "PHADIA TOP ALLERGY SCREENING DUST PANEL",
  "PHADIATOP ALLERGY SCREENING",
  "PHADIA TOP ALLERGY MILK PRODUCTS PANEL",
  "PHADIA TOP ALLERGY ASTHMA PANEL",
  "SENSISURE AERO ALLERGENS",
  "SENSISURE ALLERGY SCREENING",
  "SENSISURE SINGLE ALLERGEN",
  "SENSISURE ANY 10 ALLERGENS",
  "SENSISURE ANY 20 ALLERGENS",
  "SENSISURE ANY 30 ALLERGENS",
  "SENSISURE ANY 5 ALLERGENS",
  "SENSISURE COMPREHENSIVE PANEL 48 PARAMETERS",
  "SENSISURE ECZEMA MAXI 27 PARAMETERS",
  "SENSISURE ECZEMA MINI 20 PARAMETERS",
  "SENSISURE FRUIT 6 PARAMETERS",
  "SENSISURE NONVEG 8 PARAMETERS",
  "SENSISURE RHINITIS MAXI 22 PARAMETERS",
  "SENSISURE RHINITIS MINI 11 PARAMETERS",
  "SENSISURE VEG 22 PARAMETERS",
  "SENSISURE VEG AND NONVEG 26 PARAMETERS",
]
export const serologyReportMap = {
  ALLERGY_DRUGS_COMPREHENSIVE_PANEL: "ALLERGY DRUGS COMPREHENSIVE PANEL",
  ALLERGY_ANTIBIOTICS_PANEL: "ALLERGY ANTIBIOTICS PANEL",
  ALLERGY_ANALEGESIC_ANTIPYRETICS: "ALLERGY ANALEGESIC & ANTIPYRETICS",
  ALLERGY_PARASITES: "ALLERGY PARASITES",
  ALLERGY_OCCUPATIONAL: "ALLERGY OCCUPATIONAL",
  ALLERGY_SPICES: "ALLERGY SPICES",
  ALLERGY_SEED_NUTS_LEGUMES: "ALLERGY SEED / NUTS / LEGUMES",
  ALLERGY_MILK_DAIRY_PRODUCTS: "ALLERGY MILK & DAIRY PRODUCTS",
  ALLERGY_FOOD_VEGETARIAN_PANEL: "ALLERGY FOOD VEGETARIAN PANEL",
  ALLERGY_FRUITS_PANEL: "ALLERGY FRUITS PANEL",
  ALLERGY_FOOD_NON_VEG: "ALLERGY FOOD NON - VEG",
  ALLERGY_FOOD_VEG_NON_VEG_PANEL: "ALLERGY FOOD VEG & NON VEG PANEL",
  ALLERGY_WEEDS_TREE_ALLENGER: "ALLERGY WEEDS & TREE ALLENGER",
  ALLERGY_MOULDS_YEAST: "ALLERGY MOULDS & YEAST",
  ALLERGY_GRASS_PROFILE: "ALLERGY GRASS PROFILE",
  ALLERGY_COMMON_HOUSEHOLD_INSECTS: "ALLERGY COMMON HOUSEHOLD INSECTS",
  ALLERGY_DUST_MITE: "ALLERGY DUST MITE",
  ALLERGY_PET_AVIAN: "ALLERGY PET & AVIAN",
  ALLERGY_COMPREHENSIVE_PANEL: "ALLERGY COMPREHENSIVE PANEL",
  ALLERGY_LIDOCAINE: "ALLERGY LIDOCAINE",
  ALLERGY_HORMONAL_DRUGS: "ALLERGY HORMONAL DRUGS",
  ALLERGY_ATROPINE: "ALLERGY ATROPINE",
  INHALATIONAL_SPECIFIC_PANEL_BY_PHADIA_TOP:"INHALATIONAL SPECIFIC PANEL BY PHADIA TOP",
  PHADIA_TOP_ALLERGY_ECZEMA_PANEL:"PHADIA TOP ALLERGY ECZEMA PANEL",
  PHADIA_TOP_ALLERGY_FOOD_PANEL_VEG_AND_NONVEG:"PHADIA TOP ALLERGY FOOD PANEL VEG AND NONVEG",
  PHADIA_TOP_ALLERGY_INHALATION_PANEL_20_PARAMETERS:"PHADIA TOP ALLERGY INHALATION PANEL 20 PARAMETERS",
  PHADIA_TOP_ALLERGY_NON_VEG:"PHADIA TOP ALLERGY NON VEG",
  PHADIA_TOP_ALLERGY_PANEL_VEGETARIAN:"PHADIA TOP ALLERGY PANEL VEGETARIAN",
  PHADIA_TOP_ALLERGY_PEANUT:"PHADIA TOP ALLERGY PEANUT",
  PHADIA_TOP_ALLERGY_RHINITIS_PANEL:"PHADIA TOP ALLERGY RHINITIS PANEL",
  PHADIA_TOP_ALLERGY_SCREENING_DUST_PANEL:"PHADIA TOP ALLERGY SCREENING DUST PANEL",
  PHADIATOP_ALLERGY_SCREENING:"PHADIATOP ALLERGY SCREENING",
  PHADIA_TOP_ALLERGY_MILK_PRODUCTS_PANEL:"PHADIA TOP ALLERGY MILK PRODUCTS PANEL",
  PHADIA_TOP_ALLERGY_ASTHMA_PANEL:"PHADIA TOP ALLERGY ASTHMA PANEL",
  SENSISURE_AEROALLERGENS:"SENSISURE AERO ALLERGENS",
  SENSISURE_ALLERGY_SCREENING:"SENSISURE ALLERGY SCREENING",
  SENSISURE_SINGLE_ALLERGEN:"SENSISURE SINGLE ALLERGEN",
  SENSISURE_ANY_10_ALLERGENS:"SENSISURE ANY 10 ALLERGENS",
  SENSISURE_ANY_20_ALLERGENS:"SENSISURE ANY 20 ALLERGENS",
  SENSISURE_ANY_30_ALLERGENS:"SENSISURE ANY 30 ALLERGENS",
  SENSISURE_ANY_5_ALLERGENS:"SENSISURE ANY 5 ALLERGENS",
  SENSISURE_COMPREHENSIVE_PANEL_48_PARAMETERS:"SENSISURE COMPREHENSIVE PANEL 48 PARAMETERS",
  SENSISURE_ECZEMA_MAXI_27_PARAMETERS:"SENSISURE ECZEMA MAXI 27 PARAMETERS",
  SENSISURE_ECZEMA_MINI_20_PARAMETERS:"SENSISURE ECZEMA MINI 20 PARAMETERS",
  SENSISURE_FRUIT_6_PARAMETERS:"SENSISURE FRUIT 6 PARAMETERS",
  SENSISURE_NONVEG_8_PARAMETERS:"SENSISURE NONVEG 8 PARAMETERS",
  SENSISURE_RHINITIS_MAXI_22_PARAMETERS:"SENSISURE RHINITIS MAXI 22 PARAMETERS",
  SENSISURE_RHINITIS_MINI_11_PARAMETERS:"SENSISURE RHINITIS MINI 11 PARAMETERS",
  SENSISURE_VEG_22_PARAMETERS:"SENSISURE VEG 22 PARAMETERS",
  SENSISURE_VEG_AND_NONVEG_26_PARAMETERS:"SENSISURE VEG AND NONVEG 26 PARAMETERS",
}
export const flowCytometryReportTypeMap = {
  CD3_CD4_CD8_COUNT_FLOWCYTOMETRY: "CD3,CD4,CD8 COUNT-FLOWCYTOMETRY",
  CD4_CD8_COUNT_FLOWCYTOMETRY: "CD4,CD8 COUNT - FLOWCYTOMETRY",
  HLA_B27_FLOWCYTOMETRY: "HLA B27 - FLOWCYTOMETRY",
  CD4_COUNT: "CD-4 COUNT",
  SPERM_DNA_FRAGMENTATION: "SPERM DNA FRAGMENTATION",
}
export const transplantImmunoGeneticsMap = {
  CDC_CROSS_MATCH: "CDC CROSS MATCH",
  Donor_Specific_Antibody_DSA_IgG_Test: "Donor Specific Antibody(DSA-IgG) Test",
  HLA_B_51_B_52: "HLA B * 51 / B * 52",
  HLA_CLASS_II_DQ_TYPING: "HLA - CLASS II DQ TYPING",
  HLA_TYPING_CLASS_1_2_CLASS_TYPE: "HLA TYPING CLASS 1 & 2 CLASS TYPE",
  HLA_FLOW_CYTOMETRY_T_B_CELL_CROSSMATCH:
    "HLA FLOW CYTOMETRY T & B CELL CROSSMATCH",
    PRA_CLASS_I_CLASS_II_QUANTITATIVE:"PRA CLASS I& CLASS II QUANTITATIVE",
    HLA_TYPING_A_B_DRB_1:"HLA TYPING A B DRB1",
    'HLA SAB CLASS I II TYPING': "HLA SAB CLASS I II TYPING",
}
export const immunoHematologyReportTypeMap = {
  BLOOD_GROUP:"BLOOD GROUP",
  DIRECT_ANTIGLOBULIN_TEST:"DIRECT ANTIGLOBULIN TEST",
  INDIRECT_ANTIGLOBULIN_TEST:"INDIRECT ANTIGLOBULIN TEST",
  RED_CELL_ANTIBODY_SCREENING:"RED CELL ANTIBODY SCREENING",
}
export const molecularGeneticsReportTypeMap = {
  ADENOVIRUS_QUALITATIVE_REAL_TIME_PCR: "ADENOVIRUS QUALITATIVE REAL TIME PCR",
  BK_POLYOMA_QUANTITATIVE_PCR: "BK POLYOMA QUANTITATIVE PCR",
  BK_VIRUS_BLOOD: "BK VIRUS - BLOOD",
  BCR_ABL_QUALITATIVE: "BCR-ABL QUALITATIVE",
  BCR_ABL_QUANTITATIVE: "BCR-ABL QUANTITATIVE",
  PML_RARA_QUANTITATIVE: "PML-RARA QUANTITATIVE",
  NEISSERIA_GONORRHOEA_DNA_QUALITATIVE: "NEISSERIA GONORRHOEA DNA QUALITATIVE",
  CHLAMYDIA_TRACHOMATIS_DNA_QUALITATIVE_TEST:
    "CHLAMYDIA TRACHOMATIS DNA QUALITATIVE TEST",
  PML_RARA_QUALITATIVE: "PML-RARA QUALITATIVE",
  COVID_19_VIRUS_QUALITATIVE_PCR: "COVID-19 VIRUS QUALITATIVE PCR",
  CYTOMEGALO_VIRUS_CMV_QUANTITATIVE_PCR:
    "CYTOMEGALO VIRUS (CMV) QUANTITATIVE- PCR",
  CYTOMEGALOVIRUS_CMV_QUALITATIVE_PCR:
    "CYTOMEGALOVIRUS (CMV) QUALITATIVE - PCR",
  EPSTEIN_BAR_VIRUS_QUALITATIVE_BY_REAL_TIME_PCR:
    "EPSTEIN BAR VIRUS QUALITATIVE - BY REAL TIME PCR",
  H1N1_SWINE_FLU_BY_REAL_TIME_PCR: "H1N1/SWINE FLU BY REAL TIME PCR",
  INFLUENZA_H3N2_by_REAL_TIME_PCR: "INFLUENZA H3N2 by REAL TIME PCR",
  HCV_RNA_QUALITATIVE_REAL_TIME_PCR: "HCV RNA QUALITATIVE REAL TIME PCR",
  HCV_RNA_QUANTITATIVE_REAL_TIME_PCR: "HCV RNA QUANTITATIVE REAL TIME PCR",
  HBV_DNA_QUALITATIVE_PCR: "HBV DNA QUALITATIVE PCR",
  HBV_DNA_QUANTITATIVE_REAL_TIME_PCR: "HBV DNA QUANTITATIVE REAL TIME PCR",
  HEPATITIS_C_VIRUS_HCV_GENOTYPE: "HEPATITIS C VIRUS (HCV) GENOTYPE",
  HERPES_SIMPLEX_VIRUS_1_2_PCR_QUANTITATIVE:
    "HERPES SIMPLEX VIRUS 1 & 2 PCR- QUANTITATIVE",
  HERPES_SIMPLEX_VIRUS_1_2_PCR_QUALITATIVE:
    "HERPES SIMPLEX VIRUS 1 & 2 PCR- QUALITATIVE",
  HIV_1_RNA_QUALITATIVE_REAL_TIME_PCR: "HIV 1 RNA QUALITATIVE REAL TIME PCR",
  HIV_1_VIRAL_LOAD_QUANTITATIVE_PCR: "HIV-1 VIRAL LOAD QUANTITATIVE -PCR",
  HIV_2_RNA_QUANTITATIVE_REAL_TIME_PCR: "HIV-2 RNA QUANTITATIVE REAL TIME PCR",
  HLA_B27_BY_RT_PCR_QUALITATIVE: "HLA B27 BY RT PCR (QUALITATIVE)",
  MYCOBACTERIUM_TUBERCULOSIS_PCR_TB_PCR:
    "MYCOBACTERIUM TUBERCULOSIS PCR (TB-PCR)",
  RESPIRATORY_PANEL_BY_REAL_TIME_PCR: "RESPIRATORY PANEL - BY REAL TIME PCR",
  RUBELLA_PCR_QUALITATIVE: "RUBELLA PCR QUALITATIVE",
  SPERM_DNA_FRAGMENTATION: "SPERM DNA FRAGMENTATION",
  VIRAL_MENINGITIS_PANEL_BY_MULTIPLEX_REAL_TIME_PCR:
    "VIRAL MENINGITIS PANEL - BY MULTIPLEX REAL TIME PCR",
  HUMAN_PAPILLOMA_VIRUS_HPV_QUALITATIVE:
    "HUMAN PAPILLOMA VIRUS (HPV) QUALITATIVE",
  JC_VIRUS_DNA_DETECTION: "JC VIRUS DNA DETECTION",
  MUMPS_VIRUS_QUALITATIVE: "MUMPS VIRUS QUALITATIVE",
  HIV_1_DNA_Qualitative_Proviral_DNA: "HIV-1 DNA, Qualitative (Proviral DNA)",
  WEST_NILE_VIRUS_PCR: "WEST NILE VIRUS PCR",
  JAK2_Gene_Exon_14_V617F_Mutation_Detection:
    "JAK2 GENE (Exon 14) V617F MUTATION DETECTION",
  Japanese_Encephalitis_Virus_JEV_Qualitative_PCR:
    "JAPANESE ENCEPHALITIS VIRUS (JEV) QUALITATIVE PCR",
  TROPICAL_FEVER_PANEL: 'TROPICAL FEVER PANEL',
  SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR: 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR' || 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR',
  COMPREHENSIVE_RESPIRATORY_PANEL_1_BACTERIA_VIRUSES:"COMPREHENSIVE RESPIRATORY PANEL 1 BACTERIA/VIRUS",
  COMPREHENSIVE_RESPIRATORY_PANEL_2_BACTERIA_VIRUSES_FUNGI:"COMPREHENSIVE RESPIRATORY PANEL 2 BACTERIA/VIRUS/FUNGI",
  COMPREHENSIVE_RESPIRATORY_PANEL_3_BACTERIA_VIRUSES_FUNGI:"COMPREHENSIVE RESPIRATORY PANEL 3 BACTERIA/VIRUS/FUNGI",
  CLOSTRIDIUM_DIFFICILE_DETECTION_PCR:"CLOSTRIDIUM DIFFICILE DETECTION PCR",
  VRSA_DETECTION_QUALITATIVE_BY_REAL_TIME_PCR:"VRSA DETECTION QUALITATIVE BY REAL TIME PCR",
  CBNAAT_GENEXPERT_MTB_XDR:"CBNAAT MTB/XDR",
  CBNAAT_GENEXPERT_MTB_WITH_RIFAMPICIN_QUALITATIVE:"CBNAAT GENEXPERT MTB WITH RIFAMPICIN QUALITATIVE",
  FLU_PANEL_BY_REAL_TIME_PCR:"FLU PANEL - BY REAL TIME PCR",
  HEPATITIS_B_VIRUS_HBV_GENOTYPE:"HEPATITIS B VIRUS (HBV) GENOTYPE",
  LEPTOSPIRA_DETECTION_PCR:"LEPTOSPIRA DETECTION PCR",
  MEASLES_PCR:"MEASLES PCR",
  MDR_MTB_WITH_RIFAMPICIN_QUALITATIVE:"MDR MTB WITH RIFAMPICIN QUALITATIVE",
  HIV_1_DRUG_RESISTANCE_GENOTYPING:"HIV 1 DRUG RESISTANCE - GENOTYPING",
  MRSA_SCREENING_BY_QUALITATIVE_PCR:"MRSA SCREENING BY QUALITATIVE PCR",
  MYCOBACTERIUM_TUBERCULOSIS_MTB_AMP_NON_TUBERCULOUS_MYCOBACTERIUM_NTM_DIFFERENTIATION_BY_QUALITATIVE_PCR:"MYCOBACTERIUM TUBERCULOSIS (MTB) &amp; NON-TUBERCULOUS MYCOBACTERIUM (NTM) DIFFERENTIATION BY QUALITATIVE PCR",
  NON_TUBERCULOUS_MYCOBACTERIUM_NTM_QUALITATIVE_PCR:"NON-TUBERCULOUS MYCOBACTERIUM (NTM) QUALITATIVE PCR",
  SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR_V2:"SEPSIS PANELWITH AMR GENES - BY MULTIPLEX PCR V2",
  SYNOVIAL_FLUID_JOINT_INFECTION_MULTIPLEX_PCR:"SYNOVIAL FLUID/JOINT INFECTION - MULTIPLEX PCR",
  TORCH_PANEL_REALTIME_PCR:"TORCH PANEL REALTIME PCR",
  VRE_DETECTION_BY_QUALITATIVE_PCR:"VRE DETECTION BY QUALITATIVE PCR",
  CRYPTOCOCCUS_QUALITATIVE_PCR:"CRYPTOCOCCUS QUALITATIVE PCR",
  CHIKUNGUNYA_RNA_DETECTION_BY_PCR:"CHIKUNGUNYA RNA DETECTION BY PCR",
  CANDIDA_AURIS_QUALITATIVE_PCR:"CANDIDA AURIS QUALITATIVE PCR",
  ANTI_MICROBIAL_RESISTANCE_GENE_DETECTION_BY_MULTIPLEX_PCR:"ANTI-MICROBIAL RESISTANCE GENE DETECTION - BY MULTIPLEX PCR",
  EXTENDED_SPECTRUM_BETA_LACTAMS_ESBL_RESISTANCE_GENE_DETECTION_BY_MULTIPLEX_PCR:"EXTENDED SPECTRUM BETA-LACTAMS (ESBL) RESISTANCE GENE DETECTION BY MULTIPLEX PCR",
  TOXOPLASMA_QUALITATIVE_PCR:"TOXOPLASMA QUALITATIVE PCR",
  ASPERGILLUS_QUALITATIVE_PCR:"ASPERGILLUS QUALITATIVE PCR",
  MUCORALES_QUALITATIVE_PCR:"MUCORALES QUALITATIVE PCR",
  FUNGAL_MULTIPLEX_PCR_PANEL:"FUNGAL MULTIPLEX PCR PANEL",
  PAN_FUNGAL_DNA_PCR:"PAN FUNGAL DNA PCR",
  CAREBAPENEM_PCR:"CAREBAPENEM PCR",
  SCRUB_TYPHUS_PCR:"SCRUB TYPHUS PCR",
  RICKETSSIA_PCR:"RICKETSSIA PCR",
  EGFR:"EGFR",
  KRAS:"KRAS",
  MSI_BY_SANGER_SEQUENCING:"MSI BY SANGER SEQUENCING"
}
export const biochemistryReportTypeMap = {
  SERUM_PROTEIN_ELECTROPHORESIS_SPE: 'SERUM PROTEIN ELECTROPHORESIS - SPE',
  IMMUNO_FIXATION_ELECTROPHORESIS_IFE_SERUM: 'IMMUNO FIXATION ELECTROPHORESIS - IFE (SERUM)'
}
export const biochemistryReportType = [
  {
    label: 'IMMUNO FIXATION ELECTROPHORESIS - IFE (SERUM)',
    value: 'IMMUNO_FIXATION_ELECTROPHORESIS_IFE_SERUM'
  },
  {
    label: 'SERUM PROTEIN ELECTROPHORESIS - SPE',
    value: 'SERUM_PROTEIN_ELECTROPHORESIS_SPE'
  }
]
export const biochemistryTypes = [
  "IMMUNO FIXATION ELECTROPHORESIS - IFE (SERUM)",
  "SERUM PROTEIN ELECTROPHORESIS - SPE"
]
export const resultFileCategoryMap = {
  DOUBLE_MARKERS: "DOUBLE MARKERS",
  URINE_ORGANIC_ACID: "URINE ORGANIC ACID",
  TRIPLE_MARKERS: "TRIPLE MARKERS",
  QUADRUPLE_MARKERS: "QUADRUPLE MARKERS",
  BIO: "BIO",
  PENTA_MARKERS: "PENTA MARKERS",
  TMS: "TMS",
  EARLY_QUADRUPLE_MARKERS: "EARLY QUADRUPLE MARKERS",
  INTEGRATED_QUADRUPLE_MARKERS: "INTEGRATED QUADRUPLE MARKERS",
  GUANIDINO_ACETATE: "PLASMA CREATINE AND GUINIDINO ACETIC ACID",
  URINE_GUANIDINO_ACETATE: "URINE CREATINE & GUANIDINO ACETIC ACID LEVELS",
}

export const reportTypesOld = [
  "ULTRASOUND WHOLE ABDOMEN & PELVIS",
  "MRI LUMBAR SPINE WITH SCREENING OF WHOLE SPINE",
  "MRI BRAIN ANGIOGRAM",
  "X-RAY CHEST PA VIEW",
]
export const reportCategory = [
  {
    label: "Radiology",
    value: "RADIOLOGY",
  },
]
export const statusTypes = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "Archived",
    value: "ARCHIVED",
  },
]
export const reportStatus = [
  // {
  //   label: "All",
  //   value: "",
  // },
  {
    label: "Approved",
    value: "APPROVED",
  },
  // {
  //   label: "Completed",
  //   value: "COMPLETED",
  // },
  {
    label: "Draft",
    value: "DRAFT",
  },
  // {
  //   label: "Initiate",
  //   value: "INITIATE",
  // },
  {
    label: "Pending",
    value: "PENDING",
  },
  {
    label: "FA Pending",
    value: "FA PENDING",
  },
  // {
  //   label: "Verified",
  //   value: "VERIFIED",
  // },
]
export const departments = [
  {
    label: "All",
    value: "ALLDEPARTMENTS",
  },
  {
    label: "Biochemistry",
    value: "BIO_CHEMISTRY",
  },
  {
    label: "Biochemical Genetics",
    value: "BIOCHEMICAL_GENETICS",
  },
  {
    label : "CORPORATE",
    value : "CORPORATE",
  },
  {
    label : "CYTOGENETICS",
    value : "CYTOGENETICS",
  }, 
  {
    label : "Cytopathology",
    value : "CYTOPATHOLOGY",
  },
  {
    label: "Flow Cytometry",
    value: "FLOW_CYTOMETRY",
  },
  {
    label: "Genomics",
    value: "GENOMICS",
  },
  { label: "Histopathology", value: "HISTO_PATHOLOGY" },
  {
    label : "IHC - HISTOPATHOLOGY",
    value : "IHC_HISTOPATHOLOGY",
  },
  {
    label:"IMMUNOHEMATOLOGY",
    value:"IMMUNOHEMATOLOGY"
  },
  {
    label: "Molecular Genetics",
    value: "MOLECULAR_GENETICS",
  },
  {
    label: "Pharmacogenomics",
    value: "PHARMACO_GENOMICS",
  },
  {
    label: "Radiology",
    value: "RADIOLOGY",
  },
  {
    label: "Serology / Immunology",
    value: "SEROLOGY_IMMUNOLOGY",
  },
  {
    label: "Transplant Immunogenetics",
    value: "TRANSPLANT_IMMUNOGENETICS",
  },
]

export const reportCategories = [
  { label: "BIOCHEMISTRY", value: "BIOCHEMISTRY" },
  { label: "HAEMATOLOGY", value: "HAEMATOLOGY" },
  { label: "SEROLOGY", value: "SEROLOGY" },
]
export const bioChemistryDepartments = [
  "BIOCHEMISTRY",
  "HAEMATOLOGY",
  "SEROLOGY",
]

export const IHCHistopathology = [
  {ANTIBODIESNAME: "CD10", Clone: "56C6"},
    {ANTIBODIESNAME: "CD15", Clone: "MMA"},
    {ANTIBODIESNAME: "CD20", Clone: "L26"},
    {ANTIBODIESNAME: "CD23", Clone: "1B12"},
    {ANTIBODIESNAME: "CD30", Clone: "Ber-H2"},
    {ANTIBODIESNAME: "CD31", Clone: "JC/70A"},
    {ANTIBODIESNAME: "CD117", Clone: "Poly"},
    {ANTIBODIESNAME: "CD45 LCA", Clone: "2B11+PD/26"},
    {ANTIBODIESNAME: "CD56", Clone: "123C3.D5"},
    {ANTIBODIESNAME: "CD79a B-Cell", Clone: "HM47/A9"},
    {ANTIBODIESNAME: "CD99", Clone: "Ho36-1.1"},
    {ANTIBODIESNAME: "CDX2", Clone: "EP25"},
    {ANTIBODIESNAME: "Chromogranin", Clone: "LK2H10"},
    {ANTIBODIESNAME: "Cyclin D1", Clone: "EP12"},
    {ANTIBODIESNAME: "Bcl6", Clone: "LN22"},
    {ANTIBODIESNAME: "Cytokeratin 20", Clone: "Ks20.8"},
    {ANTIBODIESNAME: "Cytokeratin 5", Clone: "XM26"},
    {ANTIBODIESNAME: "Cytokeratin 7,OV-TL 12/30", Clone: "OV-TL 12/30"},
    {ANTIBODIESNAME: "PCk", Clone: "PCk"},
    {ANTIBODIESNAME: "EMA", Clone: "E29"},
    {ANTIBODIESNAME: "Napsin A", Clone: "KCG1.1"},
    {ANTIBODIESNAME: "AMACR", Clone: "13H4"},
    {ANTIBODIESNAME: "P53", Clone: "Do-7"},
    {ANTIBODIESNAME: "P63", Clone: "DBR16.1"},
    {ANTIBODIESNAME: "Synaptophysin", Clone: "SyPo2"},
    {ANTIBODIESNAME: "TTF1", Clone: "orm"},
    {ANTIBODIESNAME: "Vimentin", Clone: "V9"},
    {ANTIBODIESNAME: "WT1", Clone: "6F-H2"},
    {ANTIBODIESNAME: "SMA", Clone: "1A4"},
    {ANTIBODIESNAME: "Alk", Clone: "4A4"},
    {ANTIBODIESNAME: "Androgen Receptor", Clone: "PK"},
    {ANTIBODIESNAME: "IDH1", Clone: "R132H"},
    {ANTIBODIESNAME: "Calcitonin", Clone: "SP17"},
    {ANTIBODIESNAME: "Caldesmon", Clone: "hHCD"},
    {ANTIBODIESNAME: "Calponin", Clone: "CALP"},
    {ANTIBODIESNAME: "Calretinin", Clone: "H-5"},
    {ANTIBODIESNAME: "CD138", Clone: "EP201"},
    {ANTIBODIESNAME: "CD34", Clone: "Qbend/10"},
    {ANTIBODIESNAME: "CD4", Clone: "EP204"},
    {ANTIBODIESNAME: "CK19", Clone: "A53-B/A2.26"},
    {ANTIBODIESNAME: "Desmi", Clone: "D33"},
    {ANTIBODIESNAME: "DOG1", Clone: "DOG1.1"},
    {ANTIBODIESNAME: "EBV", Clone: "CS1,CS2,CS3.CS4"},
    {ANTIBODIESNAME: "E-Cadherin", Clone: "SPM471"},
    {ANTIBODIESNAME: "GATA-3", Clone: "L50-823"},
    {ANTIBODIESNAME: "GFAP", Clone: "GA5"},
    {ANTIBODIESNAME: "Glypican-3", Clone: "GYP7"},
    {ANTIBODIESNAME: "INSM1", Clone: "A-8"},
    {ANTIBODIESNAME: "MART-1", Clone: "A103"},
    {ANTIBODIESNAME: "HMB45", Clone: "HMB45"},
    {ANTIBODIESNAME: "Mesothelin", Clone: "YP158"},
    {ANTIBODIESNAME: "MUM1 Protein", Clone: "MUM1P"},
    {ANTIBODIESNAME: "MPO", Clone: "59A5"},
    {ANTIBODIESNAME: "Myogenin", Clone: "F5D"},
    {ANTIBODIESNAME: "NKX3.1", Clone: "361"},
    {ANTIBODIESNAME: "OCT3/4", Clone: "OCT4/6847R"},
    {ANTIBODIESNAME: "Pax-8", Clone: "4HB3"},
    {ANTIBODIESNAME: "PSMA", Clone: "3E6."},
    {ANTIBODIESNAME: "RCC", Clone: "PN-15"},
    {ANTIBODIESNAME: "S100", Clone: "4C4.9"},
    {ANTIBODIESNAME: "Sall4", Clone: "6.00E+03"},
    {ANTIBODIESNAME: "SOX-10", Clone: "EP268"},
    {ANTIBODIESNAME: "TDT", Clone: "SEN28"},
    {ANTIBODIESNAME: "STAT6", Clone: "D-1"},
    {ANTIBODIESNAME: "CD31", Clone: "D-1"},
    {ANTIBODIESNAME: "Z2682RP BCt-2", Clone: "R130"},
    {ANTIBODIESNAME: "Z248LMP CD19", Clone: "ZM 179"},
    {ANTIBODIESNAME: "Z2655RP Ml-H1", Clone: "2R347"},
    {ANTIBODIESNAME: "Z2574RP MSH2", Clone: "2R250"},
    {ANTIBODIESNAME: "Z2540RP MSH6", Clone: "2R342"},
    {ANTIBODIESNAME: "Z27 63RP P16", Clone: "2R407"},
    {ANTIBODIESNAME: "Z262LRP PMS2", Clone: "ZR317"},
    {ANTIBODIESNAME: "Z2544MP Synaptophysin", Clone: "(2M208"},
    { ANTIBODIESNAME: "PLA2R", Clone: "BSB-129" },
    { ANTIBODIESNAME: "Alk", Clone: "EP302" },
    { ANTIBODIESNAME: "BCl-2", Clone: "EP36" },
    { ANTIBODIESNAME: "CD 3", Clone: "RBT-CD3" },
    { ANTIBODIESNAME: "CD19", Clone: "EP169" },
    { ANTIBODIESNAME: "Desmin", Clone: "D33" },
    { ANTIBODIESNAME: "HER 2 NEU", Clone: "EP3" },
    { ANTIBODIESNAME: "INI-1", Clone: "25" },
    { ANTIBODIESNAME: "Kappa and Lambda light chain", Clone: "BSB-58 (Kap-56)" },
    { ANTIBODIESNAME: "Kappa light chains", Clone: "BSB-58 (Kap-56)" },
    { ANTIBODIESNAME: "Ki67", Clone: "EP5" },
    { ANTIBODIESNAME: "Lambda light chains", Clone: "BSB-16 (Lamb14)" },
    { ANTIBODIESNAME: "Maspin", Clone: "BSB-92" },
    { ANTIBODIESNAME: "Melan A", Clone: "A103" },
    { ANTIBODIESNAME: "MLH1", Clone: "G168-728" },
    { ANTIBODIESNAME: "MUC4", Clone: "EP256" },
    { ANTIBODIESNAME: "OLIG2", Clone: "EP112" },
    { ANTIBODIESNAME: "PAX5", Clone: "RBT-PAX5" },
    { ANTIBODIESNAME: "Somatostatin Receptor 2 /SSTR2", Clone: "EP130" },
    { ANTIBODIESNAME: "SOX11", Clone: "CL0142" }
]
export const categories = ["BIOCHEMISTRY", "HAEMATOLOGY", "SEROLOGY"]
export const departmentCategory = [
  { department: "BIOCHEMISTRY", category: "BIOCHEMISTRY" },
  { department: "HAEMATOLOGY", category: "HAEMATOLOGY" },
  { department: "SEROLOGY", category: "SEROLOGY" },
]

export const subCategories = [
  {
    category: "HAEMATOLOGY",
    subcategory: [
      "CBP(COMPLETE BLOOD PICTURE)",
      "ESR (ERYTHROCYTE SEDIMENTATION RATE)",
    ],
  },
  {
    category: "BIOCHEMISTRY",
    subcategory: [
      "URIC ACID",
      "THYROID PROFILE (T3,T4,TSH)",
      "SERUM CREATININE",
      "LIVER FUNCTION TEST(LFT)",
      "LIPID PROFILE",
      "IRON PROFILE -I",
      "HBA1C",
      "ELECTROLYTES SERUM",
      "CALCIUM - TOTAL",
      "25 HYDROXY VITAMIN D",
    ],
  },
  {
    category: "SEROLOGY",
    subcategory: ["RHEUMATOID FACTOR ( RA)", "C-REACTIVE PROTEIN (CRP)"],
  },
]
export const acknowledgmentTypeMap = {
  ACKNOWLEDGED: "Acknowledged",
  COMPLETED: "Completed",
  CRITICAL_ALERT: "Critial Alert",
  DELAY_IN_REPORT: "Delay In Report",
  HOLD: "Hold",
  OUT_SOURCE:"Out Source",
  PROVISIONAL_REPORT: "Provisional Report",
  REJECTED: "Rejected",
  REPEAT: "Repeat",
}
export const karyotypeAndInterpretetion=[
  {
    Karyotype:'46,X,inv(Y)(p11q13)',
    Interpretation:'Presence of a pericentric inversion in chromosome Y, involving regions p11 & q13. Considered a polymorphic normal variant. Origin and clinical significance may be confirmed by parental karyotyping.'
 },
 {
    Karyotype:'46,X?',
    Interpretation:'Chromosome analysis of cultured amniocytes revealed normal chromosome complement.'
 },
 {
    Karyotype:'47,XY,+21',
    Interpretation:'Presence of an additional copy of chromosome 21, suggestive of trisomy 21. Indicates male child with Down syndrome. Correlate cytogenetic findings with clinical features.'
 },
 {
    Karyotype:'46,XY,inv(9)(p11q13)',
    Interpretation:'Presence of a pericentric inversion in chromosome 9. Generally considered polymorphic, but considering the prenatal diagnosis of monosomy 9p24.3q31.3, it may increase the risk of unbalanced progenies. Associated with congenital heart defects.'
 },
 {
    Karyotype:'46,XX,22pstk+',
    Interpretation:'Increase in length of the stalk on the short arm of one of the chromosomes 22. Considered a polymorphic variant. Role in sub-fertility debatable, but some findings suggest a probable role in pregnancy outcome.'
 },
 {
    Karyotype:'46,XX',
    Interpretation:'No evidence of structural or numerical abnormality. Suggestive of a normal female chromosome complement. Possibility of submicroscopic or cryptic rearrangements cannot be ruled out.'
 },
 {
    Karyotype:'46,XY',
    Interpretation:'No evidence of structural or numerical abnormality. Suggestive of a normal male chromosome complement. Possibility of submicroscopic or cryptic rearrangements cannot be ruled out.'
 },
 {
    Karyotype:'47,+21',
    Interpretation:'Presence of an additional copy of chromosome 21, indicative of Down syndrome. Correlate with USG findings.'
 },
 {
    Karyotype:'46,XY,DSD',
    Interpretation:'Discordance between chromosomal, gonadal, and phenotypic sex. Case of Disorder of Sexual Development (DSD). No evidence of structural or numerical abnormality.'
 },
 {
    Karyotype:'46,Yqh-',
    Interpretation:'Presence of a structural variation on one of the sex chromosomes. Considered a polymorphic variant. Parental karyotyping recommended to establish origin and clinical significance. Impact on fertility debatable.'
 },
 {
    Karyotype:'46,9qh+',
    Interpretation:'Increase in length of the heterochromatic region of one of the chromosomes 9. Considered a polymorphic variant. Origin and clinical significance may be confirmed by parental karyotyping.'
 },
 {
    Karyotype:'46,X,i(X)(q10)',
    Interpretation:'Presence of an isochromosome of the long arm of one of the chromosome X. Unbalanced structural abnormality with simultaneous duplication and deletion. Manifestation includes streak gonads, ovarian failure, short stature, Turner syndrome stigmata.	'
 },
 {
    Karyotype:'46,XY,1qh+',
    Interpretation:'Increase in length of the heterochromatic region of one of the chromosomes 1. Considered a polymorphic variant. Role in sub-fertility debatable, but some findings suggest a probable role in pregnancy outcome.'
 },
 {
    Karyotype:'45,X',
    Interpretation:'	Presence of a single copy of chromosome X, suggestive of monosomy X. Turner syndrome. Phenotype-genotype association may be attempted by correlating cytogenetic findings with USG findings.'
 },
]
export const nosileBoneOptions = ["Absent", "Present"]

export const statusOptions = ["Yes", "No"]