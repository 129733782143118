<template>
  <section id="all-patient-reports">
    <div v-loading="loading" :element-loading-text="loadingText">
      <div class="page-navigation">
        <el-row>
          <el-col :lg="19">
            <ul class="filter-navigation">
              <li>
                <div class="search-filter">
                  <el-input
                    placeholder="Search User Name"
                    v-model="searchString"
                    @input="onSearch"
                    clearable
                  >
                  </el-input>
                </div>
              </li>
              <li>
                <div class="search-filter">
                  <el-select
                    placeholder="Search Centre"
                    v-model="selectedCentre"
                    @change="onSearch"
                    clearable
                  >
                  <el-option v-for="(lab,index) in this.labOptions" :key="index"
                  :label="lab.name"
                  :value="lab._id">                  
                  </el-option>
                  </el-select>
                </div>
              </li>
            </ul>
          </el-col>
          <el-col :lg="5">
            <ul class="action-navigation">
              <li>
                <el-button
                  class="default-button add-button"
                  @click="createUser"
                >
                  Add User
                  <el-icon><Plus /></el-icon>
                </el-button>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <div class="vue-table">
        <el-table
          :data="reportsData"
          style="width: 100%"
          height="calc(100vh - 250px)"
          class="table-list display-web"
        >
          <el-table-column label="User" width="230" fixed>
            <template #default="scope">
              {{
                scope.row.first_name
                  ? scope.row.first_name + " " + scope.row.last_name
                  : scope.row.first_name
              }}
            </template>
          </el-table-column>
          <el-table-column label="Role" width="180">
            <template #default="scope">
              {{
                userTypeMap[scope.row.user_type ? scope.row.user_type : "--"]
              }}
            </template>
          </el-table-column>
          <el-table-column label="Employee ID" width="150">
            <template #default="scope">
              {{
                scope.row.emp_id ? scope.row.emp_id : "--"
              }}
            </template>
          </el-table-column>
          <el-table-column label="User Name" width="180">
            <template #default="scope">
              <span class="doctor-name">
                {{ scope.row.username ? scope.row.username : "--" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Department" width="230">
            <template #default="scope">
              <div
                v-if="scope.row.departments && scope.row.departments.length > 1"
              >
                <el-dropdown>
                  <span class="department-type">
                    {{ getDepartments(scope.row.departments)[0] }}
                    <img
                      src="@/assets/images/icons/arrow.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </span>

                  <template #dropdown>
                    <el-dropdown-menu class="status-dropdown-menu">
                      <el-dropdown-item
                        v-for="(depart, index) of getDepartments(
                          scope.row.departments
                        )"
                        :key="index"
                      >
                        {{ depart }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
              <div
                v-else-if="
                  scope.row.departments && scope.row.departments.length == 1
                "
              >
                <span>
                  {{ getDepartments(scope.row.departments)[0] }}
                </span>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="Status" width="150">
            <template #default="scope">
              <div class="column-status" v-if="scope.row.status">
                <el-dropdown
                  @command="(command) => updateStatus(scope.row, command)"
                >
                  <span>
                    <el-tag :type="getStatusType(scope.row.status)">{{
                      scope.row.status
                    }}</el-tag>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu class="status-dropdown-menu">
                      <el-dropdown-item
                        command="ACTIVE"
                        :disabled="scope.row.status === 'ACTIVE'"
                        >Active</el-dropdown-item
                      >
                      <el-dropdown-item
                        command="INACTIVE"
                        :disabled="scope.row.status === 'INACTIVE'"
                        >Inactive</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Centres" width="230">
            <template #default="scope">
              <span v-if="scope.row.allowed_labs &&scope.row.allowed_labs.length">
                <span v-for="(centre,index) in scope.row.allowed_labs" :key="index">
                {{ index === scope.row.allowed_labs.length-1 ? centre.name : centre.name + ', ' || "--" }}</span>
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="Designation" width="300">
            <template #default="scope">
              {{ scope.row.designation ? scope.row.designation : "--" }}
            </template>
          </el-table-column>          
          <el-table-column label="Actions" width="130" fixed="right">
            <template #default="{ row }">
              <ul class="table-action-buttons">
                <li>
                  <el-button
                    class="view-icon"
                    @click="onView(row)"
                    title="View"
                  >
                    <img
                      src="@/assets/images/icons/eye.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li>
                  <el-button
                    class="view-icon"
                    @click="onResetPassword(row)"
                    title="Reset Password"
                  >
                    <img
                      src="@/assets/images/icons/reset-password.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li>
                  <el-button
                    class="view-icon"
                    @click="deleteUser(row)"
                    title="Archive"
                  >
                    <img
                      src="@/assets/images/icons/archive-icon.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="vue-pagination" v-if="reportsData && reportsData.length">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="loadData"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>
    <div v-if="resetPasswordDialog" class="password-dialog">
      <el-dialog
        v-model="resetPasswordDialog"
        width="30%"
        :before-close="handleClose"
      >
        <el-input placeholder="Enter Password" v-model="password"></el-input>
        <el-button
          type="submit"
          :disabled="!this.password"
          @click="resetPassword"
          >Submit</el-button
        >
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import userRolesPermissionsMixin from "@/mixins/userRolesPermissionsMixin";
import { userTypes } from "@/constants/formConstants";
export default {
  middleware: "auth",
  mixins: [userRolesPermissionsMixin],
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getAllUsers",
      "getProfileDetails",
      "getUsersList",
      "getUpdateUserStatus",
      "getResetUserPasswordStatus",
      "getAllowedLabs"
    ]),
    ...mapGetters("resultFiles", ["getResultFileReportDownloadStatus"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    getDepartments() {
      return (depart) => {
        return (depart || []).map((e) => this.departments[e]);
      };
    },
    getStatusType() {
      return (statusVal) => {
        const status = statusVal;
        if (status == "ACTIVE") {
          return "default";
        } else if (status == "INACTIVE") {
          return "danger";
        } else {
          return "";
        }
      };
    },
  },
  data() {
    return {
      reportsData: [],
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      loadingText: "",
      userTypeMap: userTypes,
      departments: {
        RADIOLOGY: "Radiology",
        HISTO_PATHOLOGY: "Histopathology",
        BIO_CHEMISTRY: "Biochemistry",
        BIOCHEMICAL_GENETICS: "Biochemical Genetics",
        GENOMICS: "Genomics",
        MOLECULAR_GENETICS: "Molecular Genetics",
        FLOW_CYTOMETRY: "Flow Cytometry",
        TRANSPLANT_IMMUNOGENETICS: "Transplant Immunogenetics",
        SEROLOGY_IMMUNOLOGY: "Serology / Immunology",
        CYTOGENETICS : "CYTOGENETICS",
        IMMUNOHEMATOLOGY:"IMMUNOHEMATOLOGY"
      },
      searchString: "",
      resetPasswordDialog: false,
      passwordData: null,
      password: "",
      labOptions:[],
      selectedCentre:""
    };
  },
  async mounted() {
    await this.fetchLabs()
    this.$store.dispatch("errors/clear");
    await this.setQueryToData();
    await this.fetchAllUsers();
  },
  methods: {
    async fetchLabs(){
      await this.$store.dispatch("auth/fetchAllAllowedLabs",{
          get_all:true
        })   
        if(this.getAllowedLabs && this.getAllowedLabs.data){
          this.labOptions = this.getAllowedLabs.data
        }    
    },
    async handleClose() {
      this.password = "";
      this.resetPasswordDialog = false;
    },
    async onSearch() {
      setTimeout(async () => {
        this.page = 1;
        await this.fetchAllUsers();
      }, 600);
    },
    async updateStatus(user, status) {
      try {
        this.loading = true;
        let params = {
          user_id: user._id,
          status: status,
        };
        await this.$store.dispatch("auth/updateUserStatus", params);
        if (this.getUpdateUserStatus) {
          this.$notify.success({
            title: "Success",
            message: "Status Updated Successfully!",
          });
          await this.fetchAllUsers();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Status",
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = true;
        console.log(error);
      }
    },
    onResetPassword(password) {
      this.$confirm("Are you sure want to update new password?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.resetPasswordDialog = true;
          this.passwordData = password;
        })
        .catch(() => {
          this.resetPassword();
        });
      // this.resetPasswordDialog = true;
      // this.passwordData = password;
    },
    async resetPassword() {
      try {
        this.loading = true;
        let params = {
          user_id: this.passwordData._id,
        };
        if (this.password) {
          params.password = this.password;
        } else {
          params.password = "123456";
        }
        await this.$store.dispatch("auth/resetUserPassword", params);
        if (this.getResetUserPasswordStatus) {
          this.$notify.success({
            title: "Success",
            message: "Password Updated Successfully!",
          });
          this.password = "";
          this.resetPasswordDialog = false;
          await this.fetchAllUsers();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Password",
          });
        }
        this.password = "";
        this.resetPasswordDialog = false;
        this.loading = false;
      } catch (error) {
        this.password = "";
        this.resetPasswordDialog = false;
        this.loading = false;
        console.log(error);
      }
    },
    async deleteUser(user) {
      try {
        this.loading = true;
        let params = {
          user_id: user._id,
          status: "ARCHIVED",
        };
        await this.$store.dispatch("auth/updateUserStatus", params);
        if (this.getUpdateUserStatus) {
          this.$notify.success({
            title: "Success",
            message: "User Archived Successfully!",
          });
          await this.fetchAllUsers();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while archiving user",
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = true;
        console.log(error);
      }
    },
    onView(user) {
      this.$router.push({ name: "UpdateUser", params: { user_id: user._id } });
    },
    createUser() {
      this.$router.push({ name: "AddUser" });
    },
    prepareParams() {
      let params = { page: this.page, limit: this.pageSize };

      if (this.searchString) {
        params.search_string = this.searchString;
      }
      if(this.selectedCentre) {
        params.allowed_labs = [this.selectedCentre]
      }

      return params;
    },
    async fetchAllUsers() {
      try {
        this.loading = true;
        this.loadingText = "Fetching Users...";
        let params = this.prepareParams();
        await this.$store.dispatch("auth/fetchAllUsers", params);
        if (this.getUsersList) {
          console.log("prasad", this.getUsersList);
        }
        this.setTableData();
        this.setQueryData(params);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    setTableData() {
      const tableData = JSON.parse(JSON.stringify(this.getUsersList));
      this.reportsData =
        tableData && tableData.data && tableData.data.length
          ? tableData.data
          : [];
      this.page = tableData.page || 1;
      this.total = tableData.total || 0;
      this.pageSize = tableData.limit || 10;
    },

    setQueryData(data) {
      let params = {
        selectedPage: data.page || 1,
        selectedPageSize: data.limit || 10,
        selectedString: data.search_string || undefined,
        selectedCentre : data.allowed_labs || []
      };

      this.$router
        .replace({
          name: this.$route.name,
          query: { ...params },
        })
        .catch(() => {});
    },
    setQueryToData() {
      if (this.$route.query) {
        this.page = this.$route.query.selectedPage
          ? parseInt(this.$route.query.selectedPage)
          : 1;
        this.pageSize = this.$route.query.selectedPageSize
          ? parseInt(this.$route.query.selectedPageSize)
          : 10;
        this.searchString = this.$route.query.selectedString || "";
        this.selectedCentre = this.$route.query.selectedCentre || ""
      }
    },
    async handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      await this.fetchAllUsers();
    },
    async loadData(page) {
      this.page = page;
      await this.fetchAllUsers();
    },
  },
  beforeUnmount() {
    this.$store.commit("auth/setAllUsers", null);
  },
};
</script>

<style lang="scss">
#all-patient-reports {
  tbody {
    td {
      &:last-child {
        background: #f5f5f5;
      }
    }
  }
}
.filter-navigation {
  li {
    margin-right: 10px;
    .status-filter {
      min-width: 120px;
    }
    .category-filter {
      min-width: 150px;
    }
  }
}
.password-dialog {
  .el-dialog {
    height: 30%;
    .el-input {
      border: 2px solid black;
    }
    .el-button {
      float: right;
      margin: 20px;
      background-color: transparent;
    }
  }
}
// popup styles
.all-patient-reports-popup {
  .el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      font-size: 20px;
      padding: 10px 10px 5px;
      background: #2eaf9f;
      border-radius: 10px 10px 0 0;
      .el-dialog__title {
        color: #ffffff;
        font-weight: 500;
      }
    }
    .el-dialog__headerbtn {
      border: 1px solid #dcdce4;
      border-radius: 3px;
      padding: 0 5px;
      top: 10px;
      right: 10px;
      .el-dialog__close {
        color: #ffffff;
        font-weight: 700;
      }
    }
    .el-dialog__body {
      padding-bottom: 10px;
      padding-top: 10px;

      .input-d-flex {
        display: grid;
        grid-template-columns: 125px auto;
        align-items: center;
        margin: 10px 0;
        .name {
          color: #000000;
          font-size: 16px;
          margin: 0;
          font-weight: 500;
        }
        .value {
          font-weight: 500;
          color: #219388;
        }
        .el-input__inner {
          width: 80%;
        }
      }
    }
    .dialog-footer {
      .submit-btn {
        background: #0061ec;
        color: #fff;
        font-weight: 500;
        border: none;
      }
    }

    .err {
      color: red;
      white-space: nowrap;
    }
  }
}
.department-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  min-width: 140px;
  width: 185px;
  padding: 6px;
  color: #00445e;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  background: #f2f2f2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
