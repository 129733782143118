<template>
    <div class="PapSmearConventional">
        <div class="edit-box mb-20">
            <span class="title">CYTOLOGY TYPE</span>
            <el-input placeholder="Please enter Cytology Type"  v-model="cytology_type"/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">LAB. REF. NO.</span>
            <el-input placeholder="Please enter LAB. REF. NO"  v-model="LabRefNo" type="textarea" autosize/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">CLINICAL DETAILS</span>
            <el-input placeholder="Please enter Clinical Details"  v-model="Clinical_Diagnosis" type="textarea" autosize />
        </div>
        <!-- <div class="edit-box mb-20">
            <span class="title">STAINS</span>
            <el-input placeholder="Please enter Stains"  v-model="stains" type="textarea" autosize/>
        </div> -->
         <div class="edit-box mb-20">
            <span class="title">MICROSCOPY</span>
            <el-input placeholder="Please enter Microscopy"  v-model="cytopathology_report" type="textarea" autosize/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">IMPRESSION</span>
            <el-input placeholder="Please enter Impression"  v-model="impression" type="textarea" autosize/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <el-input placeholder="Please enter Note"  v-model="Note" type="textarea" autosize/>
        </div>
        <el-row :gutter="10" style="margin-top: 15px;">
            <el-col :span="6">
              <p class="dropdown">AIR DRIED</p>
              <el-select placeholder="Please Select Air Dried" :clearable="true" :filterable="true" v-model="air_Drein">
                <el-option  v-for="item in numbersList" :key="item" :value="item"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <p class="dropdown">FIXED</p>
              <el-select placeholder="Please Select Fixed" :clearable="true" :filterable="true" v-model="fixed">
                <el-option  v-for="item in numbersList" :key="item" :value="item"></el-option>
              </el-select>
            </el-col>
          </el-row>
   </div>
</template>

<script>


export default{
     props:{
        PusForPapSmearConventional_data:{
            type:Array,
            required:true
        },
        patientInfo:{
            type: String, 
        }
    },
    data(){
        return{
            editorData: '',
            show: false,
            comments: "",
            recommendations: "",
            resultData: "",
            references: "",
            remarks: "",
            textarea: "",
            impression: this.PusForPapSmearConventional_data?.length==1?this.PusForPapSmearConventional_data[0].impression:'',
            LabRefNo:this.PusForPapSmearConventional_data?.length==1?this.PusForPapSmearConventional_data[0].LabRefNo:'',
            Clinical_Diagnosis:this.PusForPapSmearConventional_data?.length==1?this.PusForPapSmearConventional_data[0].Clinical_Diagnosis:'',
            cytopathology_report:this.PusForPapSmearConventional_data?.length==1?this.PusForPapSmearConventional_data[0].cytopathology_report:'',
            Note : this.PusForPapSmearConventional_data?.length==1?this.PusForPapSmearConventional_data[0].Note :'',
            cytology_type:this.PusForPapSmearConventional_data?.length==1?this.PusForPapSmearConventional_data[0].cytology_type :'',
            air_Drein:this.PusForPapSmearConventional_data?.length==1?this.PusForPapSmearConventional_data[0].air_Drein:'',
            fixed:this.PusForPapSmearConventional_data?.length==1?this.PusForPapSmearConventional_data[0].fixed:'',
            // stains:this.PusForPapSmearConventional_data?.length==1?this.PusForPapSmearConventional_data[0].stains:'',
            numbersList:[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50]
        }
        
    },
    methods:{
        childMethod() {
            const result = [{
                LabRefNo : this.LabRefNo ,
                Clinical_Diagnosis:this.Clinical_Diagnosis,
                // stains:this.stains,
                cytopathology_report:this.cytopathology_report,
                Note:this.Note,
                cytology_type:this.cytology_type,
                air_Drein:this.air_Drein,
                fixed:this.fixed,
                impression:this.impression
            }]
            this.$emit('childMethod', result);
        }
    }
}
</script>


<style lang="scss" scoped>
.PapSmearConventional {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
    .dropdown{
        color: #2eaf9f;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 13.28px;
        margin-top: 5px;
    }
}
</style>