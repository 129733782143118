export const strict = false

import { createStore } from "vuex"
import config from "@/config/app"
import createPersistedState from "vuex-persistedstate"

import auth from "./modules/auth"
import errors from "./modules/errors"
import patientReports from "./modules/patientReports"
import patientReportResult from "./modules/patientReportResult"
import reportTemplates from "./modules/reportTemplates"
import biochemistryTemplates from "./modules/biochemistryTemplates"
import resultFiles from "./modules/resultFiles"
import testConfig from "./modules/testConfig"
import acknowledgements from "./modules/acknowledgements"
// modules
const moduleNames = ["auth", "patientReports", "biochemistryTemplates"]
const store = new createStore({
  modules: {
    auth,
    errors,
    patientReports,
    patientReportResult,
    reportTemplates,
    biochemistryTemplates,
    resultFiles,
    testConfig,
    acknowledgements,
  },
  strict: false,
  plugins: [
    createPersistedState({
      key: config.APP_NAME + "_" + config.APP_VERSION,
      paths: ["auth", "patientReports", "biochemistryTemplates", "resultFiles"],
    }),
  ],
  actions: {
    globalReset({ commit }) {
      moduleNames.forEach((module) => {
        commit(`${module}/reset`)
      })
    },
  },
})

export default store
