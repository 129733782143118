<template>
    <section id="add-report-view" v-loading.fullscreen.lock="loading" :element-loading-text="loadingText">
      <div class="reports-title-header">
        <h4 class="report-title">IHC - Histopathology - Patient Report Data</h4>
        <div class="display-end">
          <el-button @click="goToReports" class="back-btn"><el-icon class="el-icon-back">
              <Back />
            </el-icon>Back</el-button>
        </div>
      </div>
  
      <div class="inner-navbar">
        <el-row :gutter="10">
          <el-col :span="12">
            <div>
              <el-autocomplete v-model="searchVisitId" :fetch-suggestions="getAutoCompletePatientsData"
                placeholder="Search VisitID, Patient Name,UHID" :clearable="true" :trigger-on-focus="false" class="el-input"
                @select="handleSelect" @clear="clearNameSearch"></el-autocomplete>
            </div>
          </el-col>
          <el-col :span="8">
            <el-select v-model="testId" placeholder="Select Test Category " :clearable="true" filterable
               @change="fillSampleType">
              <el-option v-for="report in categoryReportTypes" :key="report"
                :label="report.ItemName + '   --  ' + report.TestId" :value="report.TestId"></el-option>
            </el-select>
            <FormError errorName="result_test_id"></FormError>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="mt-10">
  
          <el-col :span="8">
            <el-select v-model="patientInfo.department" allow-create placeholder="Select Department" :clearable="true"
              filterable :loading="loading">
              <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item"></el-option>
            </el-select>
  
            <FormError errorName="department"></FormError>
          </el-col>
          <el-col :span="8">
            <el-select v-model="reportType" placeholder="Select Report Type *" :clearable="true" filterable
              @clear="clearFields" @change="onChangeReportType()">
              <el-option v-for="(report, index) in reportTypes" :key="index" :label="report.label"
                :value="report.value"></el-option>
            </el-select>
  
            <FormError errorName="report_type"></FormError>
          </el-col>
  
          <el-col :span="8">
            <el-select v-model="patientInfo.report_template_id" placeholder="Select Report Template" :clearable="true"
              filterable @clear="askRemoveConfirmation" @change="updateTemplateData">
              <el-option v-for="(template, index) in templates" :key="index" :label="template.template_title"
                :value="template._id"></el-option>
            </el-select>
            <FormError errorName="report_template_id"></FormError>
          </el-col>
        </el-row>
      </div>
  
      <div class="inner">
        <span class="report-header">DEPARTMENT OF IHC - HISTOPATHOLOGY</span>
        <div class="patient-information">
          <el-row>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Patient Name" class="mb-05 form-mandatory-field">
                <el-input v-model="patientInfo.patient_name" placeholder="Enter Patient Name" readonly></el-input>
              </el-form-item>
              <FormError errorName="patient_demographics.patient_name"></FormError>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Visit Id">
                <el-input v-model="patientInfo.visit_id" placeholder="Enter Visit Id" readonly></el-input>
                <FormError errorName="visit_id"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Barcode No" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.barcode_no" placeholder="Enter Barcode No" readonly></el-input>
                <FormError errorName="barcode_no"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Ref. Doctor" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.ref_doctor" placeholder="Enter Reference Doctor"></el-input>
                <FormError errorName="ref_doctor"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="UHID/MR No" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.uhid" placeholder="Enter UHID No" readonly></el-input>
                <FormError errorName="uhid"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Registration Date" class="mb-05 mandatory-field">
                <el-date-picker v-model="patientInfo.registration_date" type="datetime" placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"  :disabled-date="disabledDate" readonly>
                </el-date-picker>
                <FormError errorName="registration_date"></FormError>
              </el-form-item>
            </el-col>
  
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Name" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.client_name" placeholder="Enter Client Name" readonly></el-input>
                <FormError errorName="client_name"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Gender" class="mb-05 mandatory-field">
                <div class="gender-select">
                  <el-select v-model="patientInfo.gender" placeholder="Select Gender" disabled>
                    <el-option v-for="(gender, index) in genderTypes" :key="index" :label="gender"
                      :value="gender"></el-option>
                  </el-select>
                  <FormError errorName="gender"></FormError>
                </div>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Collected Date" class="mb-05 mandatory-field">
                <el-date-picker v-model="patientInfo.collected_date" type="datetime" placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A" :disabled-date="disabledDate" readonly>
                </el-date-picker>
                <FormError errorName="collected_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Code" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.client_code" placeholder="Enter Client Code" readonly></el-input>
                <FormError errorName="client_code"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Hospital Name" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.hospital_name" placeholder="Enter Hospital Name" readonly></el-input>
                <FormError errorName="ref_doctor"></FormError>
              </el-form-item>
            </el-col>
  
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Received Date" class="mb-05 mandatory-field">
                <el-date-picker v-model="patientInfo.received_date" type="datetime" placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"  :disabled-date="disabledDate" readonly>
                </el-date-picker>
                <FormError errorName="received_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Address" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.client_add" placeholder="Enter Client Address" readonly></el-input>
                <FormError errorName="client_add"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Age" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.age" placeholder="Enter Age" readonly></el-input>
                <FormError errorName="age"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Mobile" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.mobile" placeholder="Enter Mobile Number" readonly></el-input>
                <FormError errorName="mobile"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Phone" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.alternate_mobile" placeholder="Enter Phone Number" readonly></el-input>
                <FormError errorName="alternate_mobile"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Email" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.email" placeholder="Enter Email" readonly></el-input>
                <FormError errorName="email"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Locality" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.locality" placeholder="Enter Locality" readonly></el-input>
                <FormError errorName="locality"></FormError>
              </el-form-item>
            </el-col> 
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Test Name" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.test_name"
                  placeholder="Enter Test Name"
                ></el-input>
                <FormError errorName="test_name"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Sample Type" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.sample_type"  placeholder="Enter Sample Type"></el-input>
                <FormError errorName="sample_type"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Reported Date" class="mb-05 mandatory-field">
                <el-date-picker v-model="patientInfo.reported_date" type="datetime" placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"  :disabled-date="disabledDate" readonly>
                </el-date-picker>
                <FormError errorName="reported_date"></FormError>
              </el-form-item>
            </el-col>
  
          </el-row>
        </div>
  
        <div class="report-sub-title" v-if="showReportType && patientInfo.report_type">
          {{ patientInfo.report_type }}
          <span @click="onEditReportType" class="cursor-pointer">
            <el-icon><Edit/></el-icon>
          </span>
        </div>
        <div class="report-type-edit-info" v-else-if="editReportType">
          <el-input v-model="reportTypeNew"></el-input>
          <el-button type="success" @click="updateReportType" :disabled="isReportTypeUpdateDisable">
            <el-icon>
              <Check />
            </el-icon>
          </el-button>
          <el-button type="danger" @click="cancelUpdateReportType">
            <el-icon>
              <Close />
            </el-icon>
          </el-button>
        </div>        
        <div v-if="histopathologyIHCTypes.includes(sub_category) && show">
          <div>
            <div v-if="isMultiPleTemplateReport">
              <div
                class="addReportEditor"
                v-for="(editorDataObj,index) in editorsData"
                :key="index"
              >
                <div class="editor">
                  <el-input
                    placeholder="Enter section title"
                    v-model="editorsData[index].title"
                  >
                  </el-input>
                  <ckeditor
                    :editor="editor"
                    tag-name="div"
                    ref="myEditor"
                    v-model="editorsData[index].template_body"
                    :config="editorConfig"
                  >
                  </ckeditor>
                </div>
              </div>
            </div>
            <div v-else class="ihcReport">
              <div class="editor">
                <div class="importantNote">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <h5>IHC NO</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <el-col :span="24">
                  <el-form-item>
                    <el-input placeholder="Enter the IHC No" type="textarea" autosize v-model="ihcMarkersList[0].histo_ihc_no"></el-input>
                  </el-form-item>
                </el-col>
                <div class="importantNote">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <h5>Histopathology Diagnosis</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <el-col :span="24">
                  <el-form-item>
                    <el-input placeholder="Enter the histopathology diagnosis" type="textarea" autosize v-model="ihcMarkersList[0].clinical_details"></el-input>
                  </el-form-item>
                </el-col>   
                <div class="importantNote">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <h5>Cold Ischemia time</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <el-col :span="24">
                  <el-form-item>
                    <el-input placeholder="Enter the cold ischemia time" type="textarea" autosize v-model="ihcMarkersList[0].coldschemiatime"></el-input>
                  </el-form-item>
                </el-col>
                <div class="importantNote">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <h5>Fixation time</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> 
                <el-col :span="24">
                  <el-form-item>
                    <el-select v-model="ihcMarkersList[0].fixationtime" placeholder="Select fixation time"  v-if="!showCustomInput && !customFixationTime" :clearable="true" @change="handleFixationTimeChange">
                      <el-option v-for="(detail, index) in clinicalDetailsOptions" :key="index" :label="detail.label" :value="detail.value"></el-option>
                    </el-select>
                    <el-input v-model="customFixationTime" v-if="showCustomInput || customFixationTime" placeholder="Enter custom fixation time" :clearable="true" @change="handleFixationTimeChange"></el-input>
                  </el-form-item>
                </el-col>           
                <div class="importantNote">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <h5>Immunohistochemistry Report</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="edit-box ihcTable mt-10" v-if="sub_category!=='IHC HER 2 NEU'">
                  <el-table border style="width: 100%" :data="ihcDetails">
                    <el-table-column prop="sno" label="S.No" width="70"></el-table-column>
                    <el-table-column  width="300">
                      <template #header>               
                        <el-row>
                          <el-col :span="20">
                            <div>IHC Marker</div>
                          </el-col>
                          <el-col :span="4">
                            <el-buttton style="display: flex; align-items: center; justify-content: end; height: 100%;" @click="dialogFormVisible=true;addIHCDetails()">
                              <el-icon :size="18">
                              <CirclePlus />
                              </el-icon>
                            </el-buttton>
                          </el-col>
                        </el-row>
                      </template>
                      <template #default="scope">
                      <el-form>
                        <el-row :gutter="5">
                          <el-col :span="24">
                            <el-select placeholder="Select IHC Marker" v-model="scope.row.ihc_marker"
                              @change="handleDropdownChange(scope.row)" filterable :clearable="true">
                              <el-option v-for="item in ihcMarkerDetails" :key="item.ihcMarker"
                                :label="item.ihcMarker" :value="item.ihcMarker">
                              </el-option>
                            </el-select>
                        </el-col>                      
                        </el-row>
                      </el-form>
                    </template>
                    </el-table-column>
                    <el-table-column label="Clone" width="300" v-slot="scope">
                      <el-form>
                        <el-row>
                          <el-col :span="22">                          
                              <el-select v-if="!scope.row.ihc_clone" v-model="scope.row.ihc_clone" placeholder="Select clone" :clearable="true">
                                <el-option v-for="(clone, index) in filteredClones(scope.row)" :key="index" :label="clone" :value="clone"></el-option>
                              </el-select>
                              <span style="color: red">{{scope.row.errorMessage}}</span>
                              <el-input  v-if="scope.row.ihc_clone" readonly placeholder="Enter the clone" type="textarea" autosize  v-model="scope.row.ihc_clone">
                            </el-input> 
                            </el-col>                         
                            <el-col :span="2" v-if="scope.row.ihc_marker && scope.row.ihc_clone">
                                  <el-buttton style="display: flex; align-items: center; justify-content: end; height: 100%;"   @click="dialogFormVisible=true;editIHCDetails(scope.row)">
                                    <el-icon :size="18">
                                      <Edit />
                                    </el-icon>
                            </el-buttton>             
                        </el-col>   
                        </el-row>
                      </el-form>
                    </el-table-column>
                    <el-table-column label="Percentage" width="200" v-slot="scope" v-if="sub_category=='IHC - ER, PR , HER2NEU' || sub_category=='IHC - ER PR HER2 NEU & KI67' || sub_category=='IHC - ER/PR' ">
                      <el-form>
                        <el-row>
                          <el-col :span="24">
                            <el-input placeholder="Enter the percentage" type="textarea" autosize  v-model="scope.row.ihc_percentage">
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form>
                    </el-table-column>  
                    <el-table-column label="Result" width="200" v-slot="scope">
                      <el-form>
                        <el-row>
                          <el-col :span="24">
                            <el-input placeholder="Enter the result" type="textarea" autosize  v-model="scope.row.ihc_result">
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form>
                    </el-table-column>
                    <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope">
                      <el-row :gutter="12">
                        <el-col :span="6" v-if="scope.row.sno === ihcDetails?.length && scope.row.ihc_marker && scope.row.ihc_clone">
                          <el-buttton class="c-pointer" @click="onAddItem">
                            <el-icon :size="20">
                              <CirclePlus />
                            </el-icon></el-buttton>
                        </el-col>
                        <el-col :span="6">
                          <el-buttton>
                            <el-icon :size="19" @click="removeRow(scope.row)">
                              <Delete style="color: red;" />
                            </el-icon></el-buttton>
                        </el-col>
                      </el-row>
                    </el-table-column>
                  </el-table>
                  <el-dialog v-model="dialogFormVisible" :title="isEditIHC?'Edit IHC Marker':'Add IHC Marker'" width="500">
                    <el-form :model="form" :label-position="labelPosition">
                      <el-form-item label="IHC Marker" :label-width="formLabelWidth" required>
                        <el-input v-model="ihcMarkerDetailsList.ihcMarker" autocomplete="off"
                          placeholder="Enter IHC Marker"  @input="onChangeIHCMarker(ihcMarkerDetailsList)" :disabled="multpleIhcCloneLength!=-1"/>
                      </el-form-item>
                      <el-form-item label="Clone" :label-width="formLabelWidth" required>
                        <el-input v-model="newIhcClone" autocomplete="off" type="textarea" autosize
                          placeholder="Enter Clone"  @input="onChangeIHCMarker(ihcMarkerDetailsList)"/>
                          <div class="text">
                          <span style="font-size: 14px">{{ 
                            isDuplicateMarker ? `Clone already exists.` : '' }}</span>
                        </div>
                      </el-form-item>
                    </el-form>
                    <template #footer>
                      <div class="dialog-footer">
                        <el-button @click="dialogFormVisible = false;">Cancel</el-button>
                        <el-button type="primary" @click="dialogFormVisible = false;isEditIHC?updateIHCDetails(''):saveIHCDetails()"
                          :disabled="ihcMarkerDetailsList.ihcMarker == '' || newIhcClone === ''|| isDuplicateMarker || IHCchangevalue">
                          {{ isEditIHC ?'Update':'Submit' }}
                        </el-button>
                      </div>
                    </template>
                  </el-dialog>
                </div>
                <div class="edit-box ihcTable mt-10" v-if="sub_category=='IHC HER 2 NEU' || sub_category=='IHC - ER PR HER2 NEU & KI67' || sub_category=='IHC - ER, PR , HER2NEU' ">
                  <el-table border style="width: 100%" :data="ihcHer2NewDetails">
                    <el-table-column prop="sno" label="S.No" width="70"></el-table-column>
                    <el-table-column label="Biomarker" v-slot="scope" width="300">
                      <el-form>
                        <el-row :gutter="5">
                          <el-col :span="24">
                            <el-input placeholder="Enter the Biomarker" v-model="scope.row.ihc_marker" readonly></el-input>                                                    
                          </el-col>                       
                        </el-row>
                      </el-form>
                    </el-table-column>
                    <el-table-column label="Score" width="300" v-slot="scope">
                      <el-form>
                        <el-row>
                          <el-col :span="24">
                            <el-input placeholder="Enter the score" v-model="scope.row.ihc_clone"></el-input>
                        </el-col>
                        </el-row>
                      </el-form>
                    </el-table-column>                                
                    <el-table-column label="Result" width="200" v-slot="scope">
                      <el-form>
                        <el-row>
                          <el-col :span="24">
                            <el-input placeholder="Enter the result" type="textarea" autosize  v-model="scope.row.ihc_result">
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form>
                    </el-table-column>                 
                  </el-table>             
                </div>
                <div class="importantNote">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <h5>Final Diagnosis</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <el-col :span="24">
                  <el-form-item>
                    <el-input placeholder="Enter final diagnosis" type="textarea" autosize v-model="ihcMarkersList[0].ihc_final_diagnosis"></el-input>
                  </el-form-item>
                </el-col>
                <div class="importantNote">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <h5>Comment</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <el-col :span="24">
                  <el-form-item>
                    <el-input placeholder="Enter the comment" type="textarea" autosize v-model="ihcMarkersList[0].ihc_comment"></el-input>
                  </el-form-item>
                </el-col>
                <div class="importantNote">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <h5>Disclaimer</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <el-col :span="24">
                  <el-form-item>
                    <el-input placeholder="Enter the disclaimer" type="textarea" autosize v-model="ihcMarkersList[0].disclaimer"></el-input>
                  </el-form-item>
                </el-col>
                <div v-if="this.patientInfo.sub_category=='IHC - ER, PR , HER2NEU' || this.patientInfo.sub_category=='IHC - ER PR HER2 NEU & KI67'">
                  <div class="importantNote">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <h5>Her 2 neu Scoring</h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <el-col :span="24">
                    <el-form-item>
                      <el-input placeholder="Enter the her 2 neu scoring" type="textarea" autosize v-model="ihcMarkersList[0].her2neuscoring"></el-input>
                    </el-form-item>
                  </el-col>
                </div>           
                <div class="importantNote">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <h5>Note</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <el-col :span="24">
                  <el-form-item>
                    <el-input placeholder="Enter the note" type="textarea" autosize v-model="ihcMarkersList[0].note"></el-input>
                  </el-form-item>
                </el-col>
                <div v-if="this.patientInfo.sub_category=='IHC - ER PR HER2 NEU & KI67'">
                  <div class="importantNote">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <h5>References</h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <el-col :span="24">
                    <el-form-item>
                      <el-input placeholder="Enter the references" type="textarea" autosize v-model="ihcMarkersList[0].references"></el-input>
                    </el-form-item>
                  </el-col>
                </div>          
              </div>
            </div>
          </div>
          <el-row class="mt-20" style="margin-left:10px">
          <el-col  :span="6">
            <el-checkbox label="Additional approval required" v-model="patientInfo.is_required_signature" @change="handleCheckedChange" 
            :disabled="this.patientInfo.report_status ==='APPROVED' && this.patientInfo.fulfilled_signatures === 2"/> 
          </el-col>
        </el-row>
        </div>     
        <ul class="action-buttons">
          <li>
            <el-button @click="printReport" class="preview-button print-button">Print</el-button>
          </li>
          <li>
            <el-button @click="previewReport" class="preview-button">Preview</el-button>
          </li>       
          <li v-if="!isEditForm">
            <el-button @click="savePatientInfo('PENDING')" class="submit-button">Submit</el-button>
          </li>
          <li v-if="!isEditForm">
            <el-button @click="savePatientInfo('DRAFT')" class="submit-button">Save as Draft</el-button>
          </li>
          <li v-if="isEditForm && isDraft">
            <el-button @click="savePatientInfo('DRAFT')" class="submit-button">Update Draft</el-button>
          </li>
          <li v-if="isEditForm && isDraft">
            <el-button @click="savePatientInfo('PENDING')" class="submit-button">Make Complete</el-button>
          </li>
          <li v-if="isEditForm && !isDraft">
            <el-button @click="savePatientInfo(this.patientInfo.report_status)" class="submit-button">Update</el-button>
          </li>
  
          <li>
            <el-button @click="saveAsTemplate" class="submit-button">Save As Template</el-button>
          </li>
        </ul>
      </div>
  
      <div v-if="templateTitleDialogVisible" class="template-title-dialog">
        <el-dialog width="30%" v-model="templateTitleDialogVisible" title="New Template" :before-close="handleClose"
          class="template-title-dialog">
          <el-input placeholder="Please add a title for template" v-model="templateTitle" @keyup="validatenewtemplate" @blur="validatenewtemplateOnBlur"></el-input>
          <div class="text" style="padding-top: 10px;"  v-if="istemplateTitleValid">Template title is required!</div>
          <template #footer>
            <span class="dialog-footer">
              <el-button size="medium" class="el-white" type="info" plain @click="handleClose">Cancel</el-button>
              <el-button size="medium" type="primary" @click="addNewTemplate" :loading="loading" :disabled="!templateTitle">Submit</el-button>
            </span>
          </template>
  
          <FormError errorName="template_title"></FormError>
          <FormError errorName="Microscopy"></FormError>
          <FormError errorName="Impression"></FormError>
        </el-dialog>
      </div>
      <ResonForChange v-if="takeReasonDialog" v-on:close="takeReasonDialog = false" v-on:reasonSubmit="updateReport">
      </ResonForChange>
    </section>
  </template>
  <script>
  import CKEditor from "@ckeditor/ckeditor5-vue";
  import axios from "@/config/axios";
  //radiologyReportTypes
  import { genderTypes, histopathologyTypesIHCMaps, IHCHistopathology,histopathologyIHCTypesMap } from "@/constants/formConstants";
  import { ElMessageBox,ElMessage } from "element-plus";
  import { mapGetters } from "vuex";
  import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
  import FormError from "@/components/FormError";
  import moment from "moment";
  import EditorConfig from "@/config/editorConfig";
  import ResonForChange from "@/components/ReasonForChange"
  export default {
    components: {
      ckeditor: CKEditor.component,
      FormError,
      ResonForChange
    },
  
    data() {
      return {
        dialogFormVisible: false,
        IHCchangevalue:false,
        newIhcClone:'',
        isDuplicateMarker:false,
        IHCMarkerLength:-1,
        labelPosition: 'top',
        isEditIHC:false,
        ihcMarkerDetailsList: {
            ihcMarker: '',
            ihcClone: [],
            created_By: ''
          },
        ihcMarkerDetails: [],
        selectedFixationTime: null,
        customFixationTime: '',
        showCustomInput: false,
        matchingClones: [],
        matchingSynaptophysin: [],
        matchingCD31:[],
        histopathologyIHCTypes:histopathologyIHCTypesMap,
        fieldBlurred: false,
        categoryReportTypes:[],
        params:[],
        ihcDetails: [{  sno: 1, ihc_marker: '', ihc_clone: '', ihc_result: '',ihc_percentage:'' }], // Initial row
        ihcHer2NewDetails: [{ sno: 1, ihc_marker:'HER2(4B5)', ihc_clone: '', ihc_result: '',}],
        matchingIHCArray: [],
        synaptoOptions: [],     
        someCondition: false, // Set your condition here
        isExpanded: false,
        askSaveForm: false,
        templateTitleDialogVisible: false,
        templateTitle: "",
        value: "",
        reportTypes: histopathologyTypesIHCMaps,
        IHCtypes: IHCHistopathology,
        filterIHCtypes: IHCHistopathology,
        genderTypes: genderTypes,
        options: ["IHC - HISTOPATHOLOGY"],
        templates: [],
        testId: "",
        thyroid:[],
        amputation:[],
        Composite_resection:[],
        BreastLarge:[],
        uterus:[],
        BREAST_LUMP_WLE:[],
        Lung:[],
        urinary_bladder:[],
        colorectal:[],
        esophagus_ge_junction:[],
        kidney:[],
        multpleIhcCloneDetails:[],
        multpleIhcCloneLength:-1,
        ovary:[],
        stomach:[],
        sub_category:"",
        ihcNote:'',
        isDualClone:false,
        ihcMarkersList: [
        {
          histo_ihc_no: '',
          ihc_final_diagnosis:'',
          ihc_comment: '',
          clinical_details: '',
          coldschemiatime:'',
          fixationtime:'',
          references:'',
          note:'',
          disclaimer:'',
          her2neuscoring:'',
          IHCResultTable:[], 
        },
      ],
        patientInfo: {
          visit_id: "",
          patient_name: "",
          ref_doctor: "",
          uhid: "",
          mr_no: "",
          gender: "",
          age: "",
          hospital_name: "",
          registration_date: "",
          reported_date: "",
          received_date: "",
          client_name: "",
          client_add: "",
          client_code: "",
          department: "IHC - HISTOPATHOLOGY",
          report_type: "",
          email: "",
          alternate_mobile: "",
          mobile: "",
          locality: "",
          category: "IHC_HISTOPATHOLOGY",
          report_status: "DRAFT",
          // umr_no: "",
          barcode_no: "",
          req_no: "",
          sample_type: "",
          test_name: "",
          Microscopy: "",
          Impression: "",
          reportType: "",
          Malignant: "",
          storageType: "",
          BlockType: "",
          Clinical: "",
          Site: "",
          uploadpdf:[],
          typedby:"",
          is_amendment:false,
          addendum:"",
          isaddendumChecked:false,
          amendment_history:[],
          grossdescriptiontype:'Others',
          is_required_signature:false,
          fulfilled_signatures:0,
          thyroid:[
            {
              Clinical_presentation: '',
              family_history: '',
              tft_auto_antibodies: '',
              radioisotope_study: '',
              FNAC: '',
              Bethesda_category: '',
              specimen_dimensions: '',
              right_lobe: '',
              left_lobe: '',
              isthmus: '',
              Gross: '',
              distance_from_nearest_capsule: '',
              invasion_beyond: '',
              tumor_in_right_lobe: '',
              Left_lobe_measures: '',
              isthmus_measures: '',
              capsular_invasion: '',
              angiolymphatic_invasion: '',
              extrathyroidal_extension: '',
              comment_on_other_lobes: '',
              radiology: '',
              presence_of_metastases: '',
              procedure: '',
              specimen_laterality: '',
              parathyroid_glands: '',
              tumor_focality: '',
              right_lobeoption: '',
              left_lobeoption: '',
              color: '',
              capsule: '',
              degenerative_changes: '',
              right_lobe_measures: '',
              left_lobe_measures: '',
            }
          ],
               
       
       
        clinical_details:'',
          histo_ihc_no:''
        },
        input: '',
        clinicalDetailsOptions: [
            { label: 'small biopsy 6 to 8 hours', value: 'small biopsy 6 to 8 hours' },
            { label: 'medium biopsy 10 to 20 hours', value: 'medium biopsy 10 to 20 hours' },
            { label: 'large biopsy 20 to 40 hours', value: 'large biopsy 20 to 40 hours' },
         ],
        Required_Signatures: [
          {
            value: 'Yes',
            label: 'Yes',
          },
          {
            value: 'No',
            label: 'No',
          }
        ],
  
        specimenLateralityOptions: [
          {
            value: 'Yes',
            label: 'Yes'
          },
          {
            value: 'No',
            label: 'No'
          },
  
        ],
        typeOptions: [
          {
            value: 'Amputation',
            label: 'Amputation'
          },
          {
            value: 'Disarticulation',
            label: 'Disarticulation'
          },
        ],
        conditionOptions: [
          {
            value: 'Smooth',
            label: 'Smooth'
          },
          {
            value: 'Ragged',
            label: 'Ragged'
          },
        ],
        skinOptions: [
          {
            value: 'Normal',
            label: 'Normal'
          },
          {
            value: 'Ulceration',
            label: 'Ulceration'
          },
          {
            value: 'Discolouration',
            label: 'Discolouration'
          },
          {
            value: 'Swelling',
            label: 'Swelling'
          },
          {
            value: 'Trauma',
            label: 'Trauma'
          },
        ],
        bloodVesselsOptions: [
          {
            value: 'Normal',
            label: 'Normal'
          },
          {
            value: 'Abnormal',
            label: 'Abnormal'
          },
          {
            value: 'Occlusion (%)',
            label: 'Occlusion (%)'
          },
          {
            value: 'Atheroma',
            label: 'Atheroma'
          },
          {
            value: 'Thrombosis',
            label: 'Thrombosis'
          },
          {
            value: 'Other',
            label: 'Other'
          },
        ],
        radiologyOptions: [
          {
            value: 'US',
            label: 'US'
          },
          {
            value: 'MRI',
            label: 'MRI'
          },
        ],
        presenceOfMetastasesOptions: [
          {
            value: 'LN',
            label: 'LN'
          },
          {
            value: 'Distant',
            label: 'Distant'
          },
        ],
        procedureOptions: [
          {
            value: 'Lobectomy',
            label: 'Lobectomy'
          },
          {
            value: 'Hemithyroidectomy',
            label: 'Hemithyroidectomy'
          },
          {
            value: 'Subtotal thyroidectomy',
            label: 'Subtotal thyroidectomy'
          },
          {
            value: 'Total thyroidectomy',
            label: 'Total thyroidectomy'
          },
        ],
        specimenLateralityOptions1: [
          {
            value: 'Left',
            label: 'Left'
          },
          {
            value: 'Right',
            label: 'Right'
          },
          {
            value: 'Unorientated',
            label: 'Unorientated'
          },
        ],
        parathyroidGlandsOptions: [
          {
            value: 'Absent (or unidentified)',
            label: 'Absent (or unidentified)'
          },
          {
            value: 'Present',
            label: 'Present'
          },
        ],
        TumorFocalityOptions: [
          {
            value: 'Unifocal',
            label: 'Unifocal'
          },
          {
            value: 'Multifocal',
            label: 'Multifocal'
          },
        ],      
       
       
        unSavedChanges: false,
        loading: false,
        loadingText: "",
        editor: ClassicEditor,
        show: false,
        editorData: "",
        Microscopydata: "",
        Impression: "",
        searchVisitId: "",
        reportType: "",
        Microscopy: "",
        Malignant: "",
        addendum: "",
        isMalignantValid: true,
        storageType: "",
        isstorageTypeValid: true,
        BlockType: "",
        isblocktypeValid: true,
        istemplateTitleValid:false,
        isFocused: false,
        Site: "",
        Clinical: "",
        biospyno:"",
        uploadpdf: [],
        DocumentList: [],
        selectedFile: "",
        showReportType: true,
        editReportType: false,
        isaddendumChecked: false,
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
        editorConfig: EditorConfig,
        showAddTemplate: false,
        reportTypeNew: "",
        listOfDepartments: [],
        takeReasonDialog: false,
        reasonStatus: "",
        is_required_signature: false,
        grossdescriptiontype: 'Others',
        multipleTemplateTitle:"",
        editorsData: [
          {
            title: "",
            template_body: ""
          }
        ]
      };
    },
    computed: {
      ...mapGetters("errors", ["getErrors", "getError"]),
      ...mapGetters("patientReports", [
        "getPatientAddStatus",
        "getSinglePatientReports",
        "getPatientUpdateStatus",
        "getPatientDetails",
        "getDepartments",
        "getReportTypes",
      ]),
  
      ...mapGetters("reportTemplates", [
        "getAllReportTemplates",
        "getReportTemplateAddStatus",
        "getSingleReportTemplate",
        "getuploadTemplate",
        "getAllIhcMarkerDetails",
        "getIhcMarkersDetailsResponce",
        "getUpdatedIHCDetailsResponce"
      ]),
      ...mapGetters("auth", ["getProfileDetails"]),
      isReportTypeUpdateDisable() {
        return this.reportTypeNew &&
          this.reportTypeNew.trim() &&
          this.reportTypeNew.trim().length
          ? false
          : true;
      },
      isDisableDepartment() {
        if (
          this.searchVisitId &&
          this.listOfDepartments &&
          this.listOfDepartments.length
        ) {
          return false;
        }
        return true;
      },
      isDisableReportType() {
        if (
          this.searchVisitId &&
          this.listOfDepartments &&
          this.listOfDepartments.length &&
          this.reportTypes &&
          this.reportTypes.length
        ) {
          return false;
        }
        return true;
      },
  
      isEditForm() {
        return this.$route.params && this.$route.params.patient_id ? true : false;
      },
      extractedSampleType() {
      let tempDiv = document.createElement('div');
      tempDiv.innerHTML = this.patientInfo.sample_type;
      let extractedText = tempDiv.textContent || tempDiv.innerText || '';
      return extractedText;
     },
      isDraft() {
        if (
          this.isEditForm &&
          this.patientInfo.report_status &&
          this.patientInfo.report_status != "DRAFT"
        ) {
          return false;
        }
        return true;
      },
      normalizedUploadpdf() {
        return this.uploadpdf.map(item => ({
          name: item.name || null,
          url: item.url || null
        }));
      },
      noContent() {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;
        return data.length ? false : true;
      },
      flexibleInputClass() {
        return {
          'flexible-input': true,
          'expand': this.isExpanded,
        };
      },
      sortedIHCtypes() {
        const sortedArray = [...this.IHCtypes];
      const uniqueNamesSet = new Set();
      const uniqueSortedArray = sortedArray.filter(item => {
        if (!uniqueNamesSet.has(item.ANTIBODIESNAME)) {
          uniqueNamesSet.add(item.ANTIBODIESNAME);
          return true;
        }
        return false;
      });
      return uniqueSortedArray.sort((a, b) => a.ANTIBODIESNAME?.localeCompare(b.ANTIBODIESNAME));
      },
      isMatchedvalue(){
       if(this.clinicalDetailsOptions.some(option => option.value != this.ihcMarkersList[0].fixationtime)){
          return  false; 
        } else{
          return  true;
        }
      },
      isMultiPleTemplateReport(){
        if(this.sub_category && this.sub_category == 'IHC HER 2 NEU' &&
        (this.multipleTemplateTitle == 'Gastric' || this.multipleTemplateTitle == 'Breast Cancer New Template')){
          return true
        } else {
          return false
        }
      }
    },
    async mounted() {
      await this.$store.dispatch("errors/clear", {}, { root: true });
      if (this.$route.params.patient_id) {
        await this.fetchSinglePatientInfo();
        await this.getVisitData();
        await  this.fetchReportTemplates()
        
      }
      await this.$store.dispatch("reportTemplates/fetchAllIhcDetails");
      this.ihcMarkerDetails = this.getAllIhcMarkerDetails.data;
      this.ihcMarkerDetails = this.ihcMarkerDetails.sort((a, b) => a.ihcMarker.localeCompare(b.ihcMarker));
      setTimeout(() => {
        this.show = true;
      });
    },
  
    methods: {
      handleFixationTimeChange(value) {
      if (value) {
        this.showCustomInput = true;
        this.customFixationTime = value;       
      } else {
        this.ihcMarkersList[0].fixationtime=value
        this.customFixationTime = value;
        this.showCustomInput = false;
      }
    },
    onChangeIHCMarker(Marker){
      const newIhcCloneLower = this.newIhcClone      
      const duplicateIHC = this.ihcMarkerDetails.filter(data => 
        data.ihcMarker === Marker.ihcMarker && 
        data.ihcClone.map(val => val).includes(newIhcCloneLower)
    );
    if (duplicateIHC.length > 0) {
        this.isDuplicateMarker = true;
    } else {
        this.isDuplicateMarker = false;
    }   
    this.IHCchangevalue = false;
    },
    editIHCDetails(row){     
     this.isEditIHC=true 
     this.isDuplicateMarker=false
     this.IHCchangevalue=true
     this.multpleIhcCloneDetails = this.ihcMarkerDetails.filter(data => data.ihcMarker === row.ihc_marker).map( data1 => data1.ihcClone)
     const filterIHCMarkers = this.ihcMarkerDetails.filter( data => data.ihcMarker === row.ihc_marker)
     this.IHCMarkerLength=row.sno  
     this.newIhcClone=row.ihc_clone    
     this.ihcMarkerDetailsList = {
        ihcMarker: row.ihc_marker,
        ihcClone: this.newIhcClone,
        ihc_id:filterIHCMarkers[0]._id
      }    
      let valueToMatch = this.multpleIhcCloneDetails[0];
      this.multpleIhcCloneLength = valueToMatch.findIndex(data => data === this.newIhcClone);
    },
    addIHCDetails(){   
     this.isEditIHC=false
     this.IHCMarkerLength=-1
     this.newIhcClone='' 
     this.isDuplicateMarker=false
     this.multpleIhcCloneLength=-1
     this.ihcMarkerDetailsList = {
        ihcMarker:'',
        ihcClone:[],
      }      
    },
    async saveIHCDetails() {
      const duplicate = this.ihcMarkerDetails.filter( data => data.ihcMarker === this.ihcMarkerDetailsList.ihcMarker)
      if(duplicate.length > 0){
       return this.updateIHCDetails('marker')
      }
      this.ihcMarkerDetailsList.ihcClone.push(this.newIhcClone);
      let params = {
        ...this.ihcMarkerDetailsList,
        created_By: this.getProfileDetails.first_name + +'' + this.getProfileDetails.last_name
      }
      await this.$store.dispatch("reportTemplates/saveIhcDetails", {
        ...params
      });
      if (this.getIhcMarkersDetailsResponce.data) {
        await this.$store.dispatch("reportTemplates/fetchAllIhcDetails", params);
        this.ihcMarkerDetails = this.getAllIhcMarkerDetails.data;
        this.ihcMarkerDetails = this.ihcMarkerDetails.sort((a, b) => a.ihcMarker.localeCompare(b.ihcMarker));        
      } 
      this.ihcDetails =this.ihcDetails.filter(data => data.ihc_marker!='')
      this.ihcDetails.push({
        sno: this.ihcDetails?.length +1,
        ihc_marker: this.ihcMarkerDetailsList.ihcMarker,
        ihc_clone: this.newIhcClone,
        ihc_result: '',
        ihc_percentage:''
      });     
      this.ihcMarkerDetailsList = {
        ihcMarker:'',
        ihcClone:[],
       }
    },

    async updateIHCDetails(marker) {
      this.ihcMarkerDetailsList.ihcClone=[]
      if(this.multpleIhcCloneLength >= 0 && this.multpleIhcCloneLength < this.multpleIhcCloneDetails[0]?.length) {
        const filterData = this.ihcMarkerDetails.filter( data => data.ihcMarker === this.ihcMarkerDetailsList.ihcMarker)
        this.multpleIhcCloneDetails[0][this.multpleIhcCloneLength] = this.newIhcClone;
        this.ihcMarkerDetailsList.ihc_id=filterData[0]._id
        this.ihcMarkerDetailsList.ihcClone = this.multpleIhcCloneDetails[0]
      }
      const duplicate = this.ihcMarkerDetails.filter( data => data.ihcMarker === this.ihcMarkerDetailsList.ihcMarker && this.multpleIhcCloneLength==-1)
      if(duplicate.length > 0 && marker!=''){
        duplicate[0].ihcClone.push(this.newIhcClone)
        this.ihcMarkerDetailsList.ihcClone=duplicate[0].ihcClone
        this.ihcMarkerDetailsList.ihc_id=duplicate[0]._id
      }
      let params = {
        ...this.ihcMarkerDetailsList,
        updated_By: this.getProfileDetails.first_name + +'' + this.getProfileDetails.last_name
      }
      await this.$store.dispatch("reportTemplates/updateIHCDetails", {
        ...params
      }); 
      if (this.getUpdatedIHCDetailsResponce) {
        await this.$store.dispatch("reportTemplates/fetchAllIhcDetails", params);
        this.ihcMarkerDetails = this.getAllIhcMarkerDetails.data;        
      }
      if(this.IHCMarkerLength!=-1){
        this.ihcDetails = this.ihcDetails.map((item) => {
        if (item.sno === this.IHCMarkerLength) {
          return {
            sno: item.sno,
            ihc_marker: this.ihcMarkerDetailsList.ihcMarker,
            ihc_clone: this.newIhcClone,
            ihc_result: item.ihc_result,
            ihc_percentage: item.ihc_percentage
          };
        } else {
          return item; // retain unchanged item
        }
      });
      }      
    },
        fillSampleType(data) {
          let matchedReportTypes = [];
        let Testdeatils =this.categoryReportTypes.filter((testDetails)=>testDetails.TestId == data)
          Testdeatils.forEach(category => {
            this.histopathologyIHCTypes.forEach(reportType => {
              if (category.ItemName.includes(reportType)) {
                matchedReportTypes.push(reportType);
              }
            });
          });
            this.reportType = matchedReportTypes[0]
            this.onChangeReportType()
         const JavaScriptApplicants = this.categoryReportTypes.filter(
          (e) => e.TestId == data
        );
        this.testName = JavaScriptApplicants[0]?.ItemName
        if (JavaScriptApplicants[0]?.SampleTypeName) {
          this.patientInfo.sample_type = JavaScriptApplicants[0]?.SampleTypeName;
        }
        if (JavaScriptApplicants[0]?.BarcodeNo) {
          this.patientInfo.barcode_no = JavaScriptApplicants[0]?.BarcodeNo;
        }
      },
      handleBlur() {
        this.fieldBlurred = true
      },   
  
      handleDropdownChange(rows) {
          const selectedMarker = rows.ihc_marker;
          rows.ihc_clone=''
           const filteredItems = this.ihcMarkerDetails.filter(item => item.ihcMarker === selectedMarker);
        let clones = [];
        // If there are multiple ihcClone values in filteredItems, iterate through them
        if (filteredItems.some(item => Array.isArray(item.ihcClone))) {
          filteredItems.forEach(item => {
            clones.push(...item.ihcClone);
          });
        } else {
          // Otherwise, directly push the single ihcClone
          filteredItems.forEach(item => {
            clones.push(item.ihcClone);
          });
        }
        const existingClones = this.ihcDetails.filter(detail => detail.ihc_marker === selectedMarker).map(detail => detail.ihc_clone);
        clones = clones.filter(clone => !existingClones.includes(clone));
          if(clones.length==1){
            rows.ihc_clone = clones[0];
          }                         
      },
      filteredClones(marker) {
        const filteredItems = this.ihcMarkerDetails.filter(item => item.ihcMarker === marker.ihc_marker);
        let clones = [];
        // If there are multiple ihcClone values in filteredItems, iterate through them
        if (filteredItems.some(item => Array.isArray(item.ihcClone))) {
          filteredItems.forEach(item => {
            clones.push(...item.ihcClone);
          });
        } else {
          // Otherwise, directly push the single ihcClone
          filteredItems.forEach(item => {
            clones.push(item.ihcClone);
          });
        }
        const existingClones = this.ihcDetails.filter(detail => detail.ihc_marker === marker.ihc_marker).map(detail => detail.ihc_clone);
        clones = clones.filter(clone => !existingClones.includes(clone));
          if (clones.length === 0 && filteredItems.length > 0) {
        // Set error message if no clones are available but filteredItems are present
          marker.errorMessage = 'No available clones';
          } else {
              // Clear error message if there are available clones
              marker.errorMessage = "";
          }      
        return clones;
      }, 
     
      removeRow(index) {
        if (this.ihcDetails.length > 1) {
        this.ihcDetails.splice(index.sno - 1, 1)
        this.ihcDetails.forEach((data, index) => {
          data.sno = index + 1
        })
      }
      },
  
      onAddItem() {
        this.ihcDetails.push({
          sno: this.ihcDetails?.length + 1,
          ihc_marker: '',
          ihc_clone: '',
          ihc_result: '',
          ihc_percentage:''
        });
      },
  
      onDeleteItem(index) {
        if(this.ihcDetails.length > 1){
        this.ihcDetails.splice(index.sno - 1, 1)
        this.ihcDetails.forEach((data, index) => {
          data.sno = index + 1
        })
      }
      },
      async handleCheckedChange(event) {
        this.patientInfo.is_required_signature = event;
      },
      validateblocktype() {
        if (this.BlockType.length == 0) {
          this.isblocktypeValid = false;
        } else {
          this.isblocktypeValid = true;
        }
      },
      validatenewtemplate() {
        if (this.templateTitle.length === 0) {
          this.istemplateTitleValid = true;
        } else {
          this.istemplateTitleValid = false;
        }
      },
      validatenewtemplateOnBlur() {
        this.isFocused = false;
        this.validatenewtemplate();
      },
      validatestorageTypetype() {
        if (this.storageType.length == 0) {
          this.isstorageTypeValid = false;
        } else {
          this.isstorageTypeValid = true;
        }
      },
      validateMalignanttype() {
        if (this.Malignant.length == 0) {
          this.isMalignantValid = false;
        } else {
          this.isMalignantValid = true;
        }
      },
      expandInput() {
        this.isExpanded = true;
      },
      collapseInput() {
        this.isExpanded = false;
      },
  
      async updateReport(reason) {
        this.updatePatientInfo(reason);
      },
      askReason() {
        this.takeReasonDialog = true;
      },
      onEditReportType() {
        this.reportTypeNew = this.patientInfo.report_type;
        this.editReportType = true;
        this.showReportType = false;
      },
      updateReportType() {
        this.patientInfo.report_type = this.reportTypeNew;
        this.editReportType = false;
        this.showReportType = true;
      },
      cancelUpdateReportType() {
        this.reportTypeNew = this.patientInfo.report_type;
        this.editReportType = false;
        this.showReportType = true;
      },
      onChangeReportType() {
        this.reportSubCategory = this.reportType;
        this.patientInfo.report_type = this.reportType;
        this.patientInfo.report_template_id = "";
  
        this.patientInfo.test_name =this.reportType
        this.fetchReportTemplates();
      },
      askSaveConfirmation() {
        this.askSaveForm = true;
      },
      async getAutoCompletePatientsData(queryString, cb) {
        if (queryString && queryString.length > 2) {
          await this.$store.dispatch("patientReports/fetchAllPatients", {
            search_string: queryString,
            get_all: true,
          });
  
          const patientsData = [];
  
          (this.getPatientDetails.data || []).forEach((el) => {
            patientsData.push({
              value: el.VisitiID + " - " + el.PatientName,
              ...el,
            });
          });
  
          cb(patientsData || []);
        } else {
          cb([]);
        }
      },
      async getVisitData() {
        try {
          this.searchVisitId =
            this.patientInfo.visit_id + "-" + this.patientInfo.patient_name;
        } catch (error) {
          console.log(error);
        }
      },
      async handleSelect(data) {
        this.patientInfo.visit_id = data.VisitiID;
        const age = data.Age.split(" ");
        const years = parseInt(age[0]);
        const months = parseInt(age[2]);
        const days = parseInt(age[4]);
        if (years > 0 && months > 0 && days > 0 || years > 0 && months > 0) {
          this.patientInfo.age = `${years} Y ${months} M`;
        } else if (years === 0 && months > 0 && days > 0) {
          this.patientInfo.age = `${months} M ${days} D`;
        } else if (years === 0 && months === 0 && days > 0) {
          this.patientInfo.age = `${days} D`;
        }else if ( months > 0) {
          this.patientInfo.age = `${months} M`;
        }
        else if(years>0){
          this.patientInfo.age = `${years} Y`;
        }
        this.patientInfo.gender = data.Gender.toUpperCase();
        this.patientInfo.barcode_no = data.BarcodeNo;
        this.patientInfo.hospital_name = data.HospitalName;
        this.patientInfo.client_add = data.ClientAdd;
        this.patientInfo.client_code = data.ClientCode;
        this.patientInfo.client_name = data.ClientName;
        this.patientInfo.patient_name = data.PatientName;
        this.patientInfo.ref_doctor = data.RefDoctor && !data.RefDoctor.startsWith('Dr.')
      ? `Dr. ${data.RefDoctor}`
      : data.RefDoctor || '';
        this.patientInfo.uhid = data["UHID/MR NO"];
        this.patientInfo.registration_date = data.RegisterationDate
          ? moment(data.RegisterationDate).utc().format()
          : null;
          this.patientInfo.reported_date = null;
        this.patientInfo.received_date = data.ReceivedDate
          ? moment(data.ReceivedDate).utc().format()
          : null;
        this.patientInfo.collected_date = data.CollectionDate
          ? moment(data.CollectionDate).utc().format()
          : null;
  
        this.patientInfo.email = data.Email;
        this.patientInfo.mobile = data.Mobile;
        this.patientInfo.locality = data.Locality;
        this.patientInfo.alternate_mobile = data.Phone;
        this.patientInfo.client_name = data.ClientName;
        this.patientInfo.req_no = data.req_no;
        if (data.test_name) {
          this.patientInfo.test_name = data.TestName;
        }
        this.patientInfo.sample_type = data.SampleTypeName;
  
        await this.fetchPatientDepartments();
      },
  
      getSelectedReportType(departmentType) {
        const JavaScriptApplicants = this.listOfDepartments.filter((e) =>
          e.department.includes(departmentType)
        );
  
        JavaScriptApplicants.forEach(
          (x) => (this.reportTypes = x.test_categories)
        );
      },
      clearNameSearch() {
        let patientInfo = {
          visit_id: "",
          patient_name: "",
          ref_doctor: "",
          client_add: "",
          client_code: "",
          uhid: "",
          mr_no: "",
          gender: "",
          age: "",
          client_name: "",
          hospital_name: "",
          barcode_no: "",
          registration_date: "",
          collected_date: "",
          reported_date: "",
          received_date: "",
          email: "",
          alternate_mobile: "",
          mobile: "",
          locality: "",
          sample_type: "",
        };
        this.patientInfo = { ...this.patientInfo, ...patientInfo };
      },
      async fetchSinglePatientInfo() {
        this.loading = true;
        this.loadingText = "Fetching data...";
        try {
          await this.$store.dispatch(
            "patientReports/fetchSinglePatientReports",
            this.$route.params.patient_id
          );
          await this.setPatientData();
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        }
      },
      async setPatientData() {
        this.editorsData = [];
        this.patientInfo = { ...this.getSinglePatientReports };
        if (this.patientInfo.report_template_id) {
          let templateId = this.patientInfo.report_template_id;
          await this.fetchReportTemplates();
          let templates = [];
          if (this.getAllReportTemplates && this.getAllReportTemplates.data 
              &&this.getAllReportTemplates.data.length) {
            templates = this.getAllReportTemplates.data;
          }
          if (templates && templates.length > 0) {
            let templateData = templates.find((x) => x._id == templateId);
            if (templateData) {
              this.multipleTemplateTitle = templateData.template_title;
            }
          }
        }
        this.ihcDetails = this.patientInfo.ihcMarkersList[0].IHCResultTable.length > 0 ? this.patientInfo.ihcMarkersList[0].IHCResultTable : this.ihcDetails
        this.ihcMarkersList =this.patientInfo.ihcMarkersList.length > 0 ? this.patientInfo.ihcMarkersList : this.ihcMarkersList
        this.ihcHer2NewDetails =this.patientInfo.ihcMarkersList[0].IHCHer2New.length > 0 ? this.patientInfo.ihcMarkersList[0].IHCHer2New : this.ihcHer2NewDetails
        this.customFixationTime=this.patientInfo.ihcMarkersList.length > 0 ? this.patientInfo.ihcMarkersList[0].fixationtime : this.customFixationTime
        this.sub_category= this.patientInfo.sub_category
        await this.fetchPatientDepartments();
        this.editorData = this.getSinglePatientReports.test_details;
        if (
          this.getSinglePatientReports &&
          this.getSinglePatientReports.template_body_sections &&
          this.getSinglePatientReports.template_body_sections.length
        ) {
          this.editorsData = [
            ...this.editorsData,
            ...this.getSinglePatientReports.template_body_sections
          ];
        }
        this.Microscopydata = this.getSinglePatientReports.Microscopy;
        this.Impression = this.getSinglePatientReports.Impression;
        this.storageType = this.getSinglePatientReports.storageType;
        this.BlockType = this.getSinglePatientReports.BlockType;
        this.Malignant = this.getSinglePatientReports.Malignant;
        this.Clinical = this.getSinglePatientReports.Clinical;
        this.uploadpdf = this.getSinglePatientReports.uploadpdf;
        this.Site = this.getSinglePatientReports.Site;
        this.grossdescriptiontype = this.getSinglePatientReports.grossdescriptiontype?this.getSinglePatientReports.grossdescriptiontype:'Others';
        this.addendum = this.getSinglePatientReports.addendum;
        this.isaddendumChecked = this.getSinglePatientReports.isaddendumChecked;
        this.biospyno=this.getSinglePatientReports.biospyno;
        this.patientInfo.reported_date=null;
        this.reportType = this.patientInfo.sub_category;
        this.reportName = this.patientInfo.report_type;
        if (this.getDepartments && this.getDepartments.data) {
          this.departmentsData = this.getDepartments.data;
        }
        const JavaScriptApplicants = this.departmentsData.filter((e) =>
          e.department.includes(this.patientInfo.department)
        );
        JavaScriptApplicants.forEach(
          (x) => (this.reportTypes = x.test_categories)
        );
        this.testId = this.getSinglePatientReports.result_test_id;
      },
      async fetchPatientDepartments() {
        this.loading = true;
        let params = {};
        if (this.patientInfo.visit_id) {
          params.visit_id = this.patientInfo.visit_id;
          params.department = "ihchistopathology";
        }
        await this.$store.dispatch("patientReports/fetchAllDepartments", params);
        if (
          this.getDepartments &&
          this.getDepartments.data &&
          this.getDepartments.data.length
        ) {
          this.listOfDepartments = this.getDepartments.data;
  
          this.listOfDepartments.forEach(
            (x) => (this.categoryReportTypes = x.test_categories)
          );
          if (this.categoryReportTypes.length == 1) {
            this.testId = this.categoryReportTypes[0].TestId
            this.testName = this.categoryReportTypes[0].ItemName
            this.reportType= this.categoryReportTypes[0].ItemName.replace(/\s+/g, ' ');
            this.patientInfo.report_type = this.patientInfo.report_type==''? this.reportType: this.patientInfo.report_type;
            this.patientInfo.test_name=this.reportType
            this.fetchReportTemplates()
          }
          else {
            this.testId = ''
          }
        } else {
          this.listOfDepartments = [];
        }
        
        this.loading = false;
      },
      async clearForm() {
        await ElMessageBox.confirm(
          "Are you sure to clear the form. Continue?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            await this.clearPatientForm();
          })
          .catch(() => { });
      },
      async handleClose() {
        this.istemplateTitleValid=false;
        this.templateTitle = "";
        this.templateTitleDialogVisible = false;
        await this.$store.dispatch("errors/clear", {}, { root: true });
      },
  
      async addNewTemplate() {
        try {        
          let dd = window.document.getElementsByClassName("ck-content");
          if (this.reasonStatus) {
            this.patientInfo.report_status = this.reasonStatus;
          }
           this.patientInfo.typedby = this.getProfileDetails.first_name;
           this.ihcMarkersList[0].IHCResultTable=this.ihcDetails.filter(detail => detail.ihc_marker !== '' && detail.ihc_clone !== '');
           this.ihcMarkersList[0].fixationtime=this.customFixationTime==""?this.ihcMarkersList[0].fixationtime:this.customFixationTime
           if(this.params.sub_category=='IHC - ER PR HER2 NEU & KI67' || this.params.sub_category=='IHC - ER, PR , HER2NEU' || this.params.sub_category=='IHC HER 2 NEU'){
            this.ihcMarkersList[0].IHCHer2New=this.ihcHer2NewDetails
           }
           this.patientInfo.ihcMarkersList= this.ihcMarkersList  
           if (this.patientInfo.age) {
            this.patientInfo.age =this.patientInfo.age.toString();
          }
            this.patientInfo.template_title = this.templateTitle;
            this.patientInfo.sub_category = this.reportType;
            this.params = { ...this.patientInfo };     
          if (
            this.isMultiPleTemplateReport &&
            this.editorsData &&
            this.editorsData.length > 0
          ) {
            let multipleTitleData = []
            let multititle = {
              title:"",
              template_body:""
            }
            Array.from(dd).forEach((ckeditorElement, index) => {
              if (ckeditorElement && this.editorsData[index]) {
                multititle.template_body = ckeditorElement.innerHTML || "";
                multititle.title = this.editorsData[index].title || "";
                multipleTitleData.push({ ...multititle }); // Push a copy to the array
              }
            });
            this.params.template_body_sections = multipleTitleData;
          }
          this.loading = true;
          await this.$store.dispatch("reportTemplates/addReportTemplate", this.params);
          if (this.getReportTemplateAddStatus) {
            this.$notify.success({
              title: "Success",
              message: "Report Template Added Successfully",
            });
            this.templateTitleDialogVisible = false;
            this.templateTitle = "";
            this.loading = false;
            await this.fetchReportTemplates();
          } else if (!this.getErrors) {
            this.$notify.error({
              title: "Error",
              message: "Error at Creating new Template",
            });
          }
          this.loading = false;
        } catch (err) {
          console.log(err);
          this.loading = false;
        }
      },
  
      saveAsTemplate() {
        if(this.patientInfo.report_template_id==undefined){       
          ElMessage.warning("Report content is not given");
          return;        
      }
        this.templateTitleDialogVisible = true;
      },
      clearPatientForm(clearPatientData = true) {
        if (clearPatientData) {
          this.patientInfo = {
            visit_id: "",
            patient_name: "",
            ref_doctor: "",
            uhid: "",
            mr_no: "",
            gender: "",
            age: "",
            hospital_name: "",
            registration_date: "",
            reported_date: "",
            received_date: "",
            barcode_no: "",
            client_name: "",
          };
        }
  
        window.document.getElementsByClassName("ck-content")[0].innerText = "";
        window.document.getElementsByClassName("ck-content").innerHTML = "";
      },
      async previewReport() {
        try {          
          let dd = window.document.getElementsByClassName("ck-content");
          let params = { ...this.patientInfo };
          params.sub_category = this.sub_category;
          this.ihcMarkersList[0].IHCResultTable=this.ihcDetails.filter(detail => detail.ihc_marker !== '' && detail.ihc_clone !== '');
          this.ihcMarkersList[0].fixationtime=this.customFixationTime==""?this.ihcMarkersList[0].fixationtime:this.customFixationTime
          if(this.params.sub_category=='IHC - ER PR HER2 NEU & KI67' || this.params.sub_category=='IHC - ER, PR , HER2NEU' || this.params.sub_category=='IHC HER 2 NEU'){
              this.ihcMarkersList[0].IHCHer2New=this.ihcHer2NewDetails
          }
          params.ihcMarkersList= this.ihcMarkersList 
          if (
            this.isMultiPleTemplateReport &&
            this.editorsData &&
            this.editorsData.length > 0
          ) {
            let multipleTitleData = []
            let multititle = {
              title:"",
              template_body:""
            }
            Array.from(dd).forEach((ckeditorElement, index) => {
              if (ckeditorElement && this.editorsData[index]) {
                multititle.template_body = ckeditorElement.innerHTML || "";
                multititle.title = this.editorsData[index].title || "";
                multipleTitleData.push({ ...multititle }); // Push a copy to the array
              }
            });
            params.template_body_sections = multipleTitleData;
          }
          if (
            this.patientInfo.patient_name &&
            this.patientInfo.department &&
            this.patientInfo.report_type
          ) {
            this.$store.dispatch("patientReports/addPatientData", params);
  
            let routerData = this.$router.resolve({
              name: "PatientReportPreview",
            });
  
            window.open(routerData.href, "_blank");
          } else {
            ElMessageBox.alert(
              "Please Fill mandatory Fields to preview report",
              "Alert",
              {
                confirmButtonText: "OK",
              }
            );
          }
          this.loading = false;

       
        } catch (err) {
          console.log(err);
        }
      },
      async printReport() {
        try {
          let dd = window.document.getElementsByClassName("ck-content");
          let params = { ...this.patientInfo };
          params.sub_category = this.reportType;
          this.ihcMarkersList[0].IHCResultTable=this.ihcDetails.filter(detail => detail.ihc_marker !== '' && detail.ihc_clone !== '');
          this.ihcMarkersList[0].fixationtime=this.customFixationTime==""?this.ihcMarkersList[0].fixationtime:this.customFixationTime
          if(this.params.sub_category=='IHC - ER PR HER2 NEU & KI67' || this.params.sub_category=='IHC - ER, PR , HER2NEU' || this.params.sub_category=='IHC HER 2 NEU'){
              this.ihcMarkersList[0].IHCHer2New=this.ihcHer2NewDetails
          }
          params.ihcMarkersList= this.ihcMarkersList 
          if (
            this.isMultiPleTemplateReport &&
            this.editorsData &&
            this.editorsData.length > 0
          ) {
            let multipleTitleData = []
            let multititle = {
              title:"",
              template_body:""
            }
            Array.from(dd).forEach((ckeditorElement, index) => {
              if (ckeditorElement && this.editorsData[index]) {
                multititle.template_body = ckeditorElement.innerHTML || "";
                multititle.title = this.editorsData[index].title || "";
                multipleTitleData.push({ ...multititle }); // Push a copy to the array
              }
            });
            params.template_body_sections = multipleTitleData;
          }
          if (
            this.patientInfo.patient_name &&
            this.patientInfo.department &&
            this.patientInfo.report_type
          ) {
            this.$store.dispatch("patientReports/addPatientData", params);
  
            let routerData = this.$router.resolve({
              name: "PatientReportPreviewPrint",
            });
  
            window.open(routerData.href, "_blank");
          } else {
            ElMessageBox.alert(
              "Please Fill mandatory Fields to preview report",
              "Alert",
              {
                confirmButtonText: "OK",
              }
            );
          }      
        } catch (err) {
          console.log(err);
        }
      },
      async goToReports() {
        if (this.$route.params.patient_id) {
          this.$router.push({
            name: "PatientReportView",
            query: this.$route.query,
            params: { status: this.$route.params.status },
          });
        } else {
          this.$router.push({ name: "PatientReports", query: this.$route.query });
        }
      },
  
      async fetchReportTemplates() {
        try {
          this.loading = true;
          this.loadingText = "Fetching data..";
          let params = {
            get_all: true,
            category: "IHC_HISTOPATHOLOGY",
          };
  
          if (this.reportType) {
            const selected_category = this.reportTypes.find(type => type.value.toLowerCase() === this.reportType.toLowerCase());
  
            if (selected_category) {
              params.sub_category = selected_category.value;
            }
          }
  
          await this.$store.dispatch("reportTemplates/fetchAllReportTemplates", params);
  
          this.templates = this.getAllReportTemplates.data;
  
  
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.error(err);
        }
      },
      async beforeUpload(file) {
        try {
          console.log(file)
          return false;
        } catch (error) {
          console.error(error);
          return false;
        }
      },
      async handleFileChange(file) {
        try {
          const selectedFile = file.raw;
          const formData = new FormData();
          formData.append('file', selectedFile);
          const response = await axios.post("patient-reports/upload-pdf", formData, file.raw);
          const pdf = response.data.data.imageUrl;
          const name = response.data.data.name;
          this.uploadpdf.push({ name: name, url: pdf });
        } catch (error) {
          console.error(error);
        }
      },
      previewPdf(file) {
        if (file) {
          window.open(file.url);
        } else {
          console.error("No PDF file selected.");
        }
      },
      savePatientInfo(status) {
        this.reasonStatus = status;       
          if (this.$route.params.patient_id && status === 'APPROVED') {
            this.askReason()
          } else if (this.$route.params.patient_id) {
            this.updatePatientInfo();
          } else {
            this.addPatientInfo();
          }      
      },
      async fetchSingleReportTemplate(templateId) {
        try {
          await this.$store.dispatch(
            "reportTemplates/fetchSingleReportTemplate",
            { template_id: templateId }
          );
        } catch (err) {
          console.log(err);
        }
      },
      async updateTemplateData(templateId) {   
        this.editorsData = [];     
        await this.fetchReportTemplates();
        let templateDataTitle = this.templates.find((x) => x._id == templateId);
        if (templateDataTitle) {
          this.multipleTemplateTitle = templateDataTitle.template_title;
        }
        await this.fetchSingleReportTemplate(templateId);
        let templateData = this.templates.find((x) => x._id == templateId);
        if (
          this.getSingleReportTemplate &&
          this.getSingleReportTemplate.template_body
        ) {
          templateData.template_body = this.getSingleReportTemplate.template_body;
        }
        if (
          this.getSingleReportTemplate &&
          this.getSingleReportTemplate.template_body_sections &&
          this.getSingleReportTemplate.template_body_sections.length
        ) {
          this.editorsData = [
            ...this.editorsData,
            ...this.getSingleReportTemplate.template_body_sections
          ];
        }
        if (
          this.getSingleReportTemplate &&
          this.getSingleReportTemplate.test_details
        ) {
          templateData.test_details = this.getSingleReportTemplate.test_details;
          templateData.Microscopy = this.getSingleReportTemplate.Microscopy;
          templateData.Impression = this.getSingleReportTemplate.Impression;
          templateData.Clinical = this.getSingleReportTemplate.Clinical;
          templateData.Site = this.getSingleReportTemplate.Site;
        }
        if (templateData && !this.histopathologyIHCTypes.includes(templateData.sub_category)) {
          let dd = window.document.getElementsByClassName("ck-content");
          const data = dd[0].innerHTML || "";
          let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
          stringContent = stringContent.trim();
          if (stringContent) {
            ElMessageBox.confirm(
              "Are you want to update template data. Continue?",
              "Warning",
              {
                confirmButtonText: "OK",
                cancelButtonText: "No",
                type: "warning",
              }
            )
              .then(async () => {
                await this.updateEditorData(templateData);
              })
              .catch(() => { });
          } else {
            this.updateEditorData(templateData);
          }
        }
        if (templateData) {
          this.updateEditorData(templateData);
        }else{
          this.sub_category=""
        }
      },
      updateEditorData(templateData) {
        this.show = false;
        this.loadingEditor = true;
          setTimeout(() => {  
          this.ihcDetails = []
          this.ihcDetails = [{  sno: 1, ihc_marker: '', ihc_clone: '', ihc_result: '',ihc_percentage:'' }]
          this.show = true;
          this.loadingEditor = false;
          this.sub_category= templateData?.sub_category || "";         
          this.patientInfo.sub_category=templateData.sub_category || "";
          this.customFixationTime=templateData.ihcMarkersList[0].fixationtime || ""
          this.ihcDetails =templateData.ihcMarkersList[0]?.IHCResultTable.length > 0 ? templateData.ihcMarkersList[0].IHCResultTable : this.ihcDetails
          this.ihcNote=templateData.ihcMarkersList.length > 0  ? templateData.ihcMarkersList[0].ihcNote : this.ihcNote
          this.ihcMarkersList = templateData.ihcMarkersList.length > 0 ? templateData.ihcMarkersList : this.ihcMarkersList
          this.ihcHer2NewDetails = 
            templateData.ihcMarkersList?.[0]?.IHCHer2New?.length > 0 
            ? templateData.ihcMarkersList[0].IHCHer2New 
            : this.ihcHer2NewDetails;
            }, 100);
      },
      async addPatientInfo() {
        try {          
          let dd = window.document.getElementsByClassName("ck-content");   
            this.params = { ...this.patientInfo };
            if (this.testId) {
            this.params.result_test_id = this.testId;
          }
          if (this.reasonStatus) {
            this.params.report_status = this.reasonStatus;
          }
          if (! this.params.report_template_id) {
            this.params.report_template_id = null;
          }
          this.params.typedby = this.getProfileDetails.first_name;
          this.ihcMarkersList[0].IHCResultTable=this.ihcDetails.filter(detail => detail.ihc_marker !== '' && detail.ihc_clone !== '');
          this.ihcMarkersList[0].fixationtime=this.customFixationTime==""?this.ihcMarkersList[0].fixationtime:this.customFixationTime
          if(this.params.sub_category=='IHC - ER PR HER2 NEU & KI67' || this.params.sub_category=='IHC - ER, PR , HER2NEU' || this.params.sub_category=='IHC HER 2 NEU'){
            this.ihcMarkersList[0].IHCHer2New=this.ihcHer2NewDetails
         }
          this.params.ihcMarkersList= this.ihcMarkersList  
          this.params.sub_category = this.sub_category;          
          if (
            this.isMultiPleTemplateReport &&
            this.editorsData &&
            this.editorsData.length > 0
          ) {
            let multipleTitleData = []
            let multititle = {
              title:"",
              template_body:""
            }
            Array.from(dd).forEach((ckeditorElement, index) => {
              if (ckeditorElement && this.editorsData[index]) {
                multititle.template_body = ckeditorElement.innerHTML || "";
                multititle.title = this.editorsData[index].title || "";
                multipleTitleData.push({ ...multititle }); // Push a copy to the array
              }
            });
            this.params.template_body_sections = multipleTitleData;
          }
          await this.$store.dispatch("patientReports/addPatientReport",  this.params);
          if (this.getPatientAddStatus) {
            this.$notify.success({
              title: "Success",
              message: this.reasonStatus == "DRAFT" ? "Patient Report Drafted Successfully" : "Patient Report Submitted Successfully",
            });
            await this.goToReports();
          } else if (this.getErrors) {
            this.scrollIntoFirstError();
          } else if (!this.getErrors) {
            this.$notify.error({
              title: "Error",
              message: "Error while Adding Report",
            });
          }
          this.loading = false;
        } catch (err) {
          console.log(err);
        }
      },
      async updatePatientInfo(reason) {
        try {   
          let dd = window.document.getElementsByClassName("ck-content");       
          let params = { ...this.patientInfo };         
           if (this.reasonStatus) {
            params.report_status = this.reasonStatus;
            }
          if (this.testId) {
            params.result_test_id = this.testId;
          }
          if (params.client_code && params.client_code != "") {
            const code = this.patientInfo.client_code.toString();
            params.client_code = code;
          }
          if (this.patientInfo.age == null) {
            params.age = "";
          }
          if (this.patientInfo.reported_date == null) {
            params.reported_date = "";
          }
          if (this.patientInfo.received_date == null) {
            params.received_date = "";
          }
          if (this.reasonStatus) {
            params.report_status = this.reasonStatus;
          }
          if (reason && reason.reason) {
            const amendmentReason = reason.reason;
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString();
            params.amendment_history.push({
              amendment_reason: amendmentReason,
              edited_by: this.getProfileDetails.first_name,
              edited_date: formattedDate
            });
            params.amendment_reason = reason.reason;
          }
          params.IHCResultTable = this.ihcDetails.filter(detail => detail.ihc_marker !== '' && detail.ihc_clone !== '') 
          this.ihcMarkersList[0].IHCResultTable=this.ihcDetails.filter(detail => detail.ihc_marker !== '' && detail.ihc_clone !== '');
          this.ihcMarkersList[0].fixationtime=this.customFixationTime==""?this.ihcMarkersList[0].fixationtime:this.customFixationTime
          if(this.params.sub_category=='IHC - ER PR HER2 NEU & KI67' || this.params.sub_category=='IHC - ER, PR , HER2NEU' || this.params.sub_category=='IHC HER 2 NEU'){
            this.ihcMarkersList[0].IHCHer2New=this.ihcHer2NewDetails
         }
          params.ihcMarkersList= this.ihcMarkersList  
          params.sub_category = this.reportType;
          if (
              this.isMultiPleTemplateReport &&
              this.editorsData &&
              this.editorsData.length > 0
            ) {
              let multipleTitleData = []
              let multititle = {
                title:"",
                template_body:""
              }
              Array.from(dd).forEach((ckeditorElement, index) => {
                if (ckeditorElement && this.editorsData[index]) {
                  multititle.template_body = ckeditorElement.innerHTML || "";
                  multititle.title = this.editorsData[index].title || "";
                  multipleTitleData.push({ ...multititle }); // Push a copy to the array
                }
              });
              params.template_body_sections = multipleTitleData;
            }
            let patientId = this.$route.params.patient_id;
            await this.$store.dispatch("patientReports/updatePatientReport", {
            params,
            patientId,
          });
          if (this.getPatientUpdateStatus) {
            this.$notify.success({
              title: "Success",
              message: "Patient Report Updated Successfully",
            });
            await this.goToReports();
          } else if (this.getErrors) {
            this.scrollIntoFirstError();
          } else if (!this.getErrors) {
            this.$notify.error({
              title: "Error",
              message: "Error while Updating Report",
            });
          }
          this.loading = false;
        } catch (err) {
          console.log(err);
        }
      },
      setPatientinfo(){
        if(this.grossdescriptiontype!='Thyroid'){
            this.patientInfo.thyroid = this.thyroid ;
          }
          if (this.grossdescriptiontype!='Uterus'){
            this.patientInfo.uterus =this.uterus;
          }
          if (this.grossdescriptiontype!='AMPUTATION'){
            this.patientInfo.amputation =this.amputation;
          }
           if (this.grossdescriptiontype!='Breast_Large'){
            this.patientInfo.BreastLarge =this.BreastLarge;
          }
           if (this.grossdescriptiontype!='BREAST_LUMP_WLE'){
            this.patientInfo.BREAST_LUMP_WLE  = this.BREAST_LUMP_WLE;
          }
           if (this.grossdescriptiontype!='colorectal'){
            this.patientInfo.colorectal =this.colorectal;
          }
            if (this.grossdescriptiontype!='Composite resection'){
            this.patientInfo.Composite_resection =this.Composite_resection;
          }
           if (this.grossdescriptiontype!='ESOPHAGUS_GE_JUNCTION'){
            this.patientInfo.esophagus_ge_junction =this.esophagus_ge_junction;
          }
           if (this.grossdescriptiontype!='Kidney'){
            this.patientInfo.kidney = this.kidney;
          }
           if (this.grossdescriptiontype!='Lung'){
            this.patientInfo.Lung =this.Lung;
          }
           if (this.grossdescriptiontype!='OVARY'){
            this.patientInfo.ovary =this.ovary;
          }
           if (this.grossdescriptiontype!='Ovary_FT_cancer'){
            this.patientInfo.Ovary_ft_cancer =this.Ovary_ft_cancer;
          }
           if (this.grossdescriptiontype!='STOMACH'){
            this.patientInfo.stomach =this.stomach;
          }
           if (this.grossdescriptiontype!='URINARY_BLADDER'){
            this.patientInfo.urinary_bladder =this.urinary_bladder;
          }
      },
      async askRemoveConfirmation() {
        await ElMessageBox.confirm(
          "Are you want to clear template data. Continue?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "No",
            type: "warning",
          }
        )
          .then(async () => {
            await this.updateEditorData({});
          })
          .catch(() => { });
      },
      scrollIntoFirstError() {
        var el = this.$el.getElementsByClassName("error")[0];
        if (el) {
          el.scrollIntoView({ block: "center" });
        }
      },
      handleRemove(file) {
        const index = this.uploadpdf.findIndex(item => item.name === file.name && item.url === file.url);
        if (index !== -1) {
          this.uploadpdf.splice(index, 1);
        }
      },
      onChangeCategory(selectedvalue) {
        this.grossdescriptiontype = selectedvalue;
      },
      handleCheckedCitiesChange(value) {
        if (value == true) {
          this.isaddendumChecked = true
        } else {
          this.isaddendumChecked = false
        }
      },
    },
   
    };
  </script>
  
  <style lang="scss">
  #add-report-view {
    max-width: 900px;
    margin: 20px auto 0 !important;
  
    .reports-title-header {
      display: grid;
      grid-template-columns: 88% auto;
      margin-bottom: 10px;
  
      .display-end {
        text-align: end;
      }
  
      .report-title {
        text-align: center;
        color: #000;
        font-size: 20px;
        font-weight: 500;
      }
    }
  
    .inner-navbar {
      margin-bottom: 10px;
  
      .filter-navigation {
        display: flex;
        justify-content: space-between;
  
        li {
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
  
    .inner {
      margin-bottom: 100px !important;
  
      .report-header {
        margin: 0;
        color: #fff;
        font-weight: 500;
        font-size: 20px;
        background: #2eaf9f;
        text-align: center;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
  
      border: 1px solid rgba(7, 162, 149, 0.6);
      border-radius: 10px;
      padding: 0;
      background: #fff;
      overflow: hidden;
  
      .patient-information {
        .el-form-item {
          margin-bottom: 0;
        }
  
        .form-mandatory-field {
          .el-form-item__label:after {
            content: "*";
            color: red !important;
            font-size: 15px;
            line-height: 1;
            padding-left: 3px;
          }
        }
  
        .el-form-item__label {
          min-width: 40%;
          text-align: left;
          background: #e0f3f1;
          padding-left: 10px;
          border-bottom: 1px solid #e0f3f1;
          font-weight: 500;
          font-size: 11px;
          color: #000;
        }
  
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }
  
        .el-input__inner {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          border-left: none;
          border-right: 1px solid rgba(0, 0, 0, 0.2);
          border-top: none;
          border-radius: 0;
          color: #000;
          font-weight: 500;
  
          &::placeholder {
            color: #5a5a5a;
            font-size: 13px;
            text-transform: capitalize;
          }
        }
      }
  
      .report-sub-title {
        color: #43454b;
        font-size: 18px;
        text-align: center;
        margin-top: -1px;
        padding-top: 1rem;
        border-top: 1.2px solid rgba(0, 0, 0, 0.2);
  
        font-weight: 500;
      }
  
      .report-type-edit-info {
        display: flex;
        margin-top: 0.5em;
        padding: 0 10px;
        gap: 5px;
      }
  
      .editor {
        margin-top: 0.5em;
        padding: 10px;
  
        .ck-editor__editable {
          min-height: 320px;
        }
      }
  
      .image__caption_highlighted {
        min-height: auto !important;
      }
  
      .ck-dropdown__panel {
        max-height: 230px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
  
      .ck-placeholder {
        min-height: auto !important;
      }
  
      .ck-dropdown__panel::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }
  
      .ck-dropdown__panel::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
      }
  
      .ck-dropdown__panel::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #2eaf9f;
      }
  
      .action-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        margin: 0;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
  
        li {
          &:not(:last-child) {
            margin-right: 20px;
          }
  
          .el-button {
            padding: 9px 15px;
            min-height: auto;
            line-height: 1;
  
            &.preview-button {
              background: #28b4a6;
              color: #fff;
              font-weight: 500;
            }
  
            &.print-button {
              background: #f56c6c !important;
            }
  
            &.submit-button {
              background: #0061ec;
              color: #fff;
              font-weight: 500;
            }
          }
        }
      }
    }
  
    figure {
      &.table {
        br {
          display: none;
        }
  
        br[data-cke-filler="true"] {
          display: block !important;
        }
      }
    }
  
    .ck {
      ul {
        list-style: disc;
        padding-left: 30px;
  
        li {
          padding-left: 5px;
          list-style-type: disc !important;
        }
      }
    }
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .template-title-dialog {
    .el-dialog__header {
      background-color: #a09b9b6b;
  
      .el-dialog__close {
        font-size: 20px;
        color: red;
      }
    }
  }
  
  .lineheight{
    line-height: 20px;
  }
  .flexible-input {
    &.expand {
      z-index: 3;
    }
  
    &.collapse {
      z-index: 0;
      display: none;
      /* Optionally hide the collapsed version */
    }
  }
  
  .fileUploadArea {
    padding: 10px;
  }
  
  .text {
    color: red;
    font-size: smaller;
  }
  
  .error-message {
    color: red;
    text-align: start;
    margin: 0;
    font-size: 14px;
    padding: 5px;
  }
  
  /** IHC CSS **/
  .ihcReport {
    .reportTitle {
      padding: 3px 5px;
      margin-top: 15px;
      text-align: center;
      font-weight: 600;
      background: #2caf9f;
      color: #fff;
      text-transform: uppercase;
      font-size: 15px;
      line-height: 1.3;
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
  
      th {
        background: #e0f3f1;
        color: #333;
        text-align: center;
        border: 1px solid #2eaf9f;
        font-weight: 600;
        font-size: 14px;
      }
  
      td {
        border: 1px solid #2eaf9f;
        text-align: left;
        line-height: 1.3;
        padding: 3px 10px;
      }
  
      p {
        margin: 0;
        font-size: 14px;
      }
    }
  
    .importantNote {
      margin-top: 20px;
  
      td {
        border: 0;
        padding: 0;
      }
  
      h5 {
        color: #2eaf9f;
        font-weight: 600;
        margin-bottom: 5px;
      }
  
      
    }
  
  }
  .el-upload-list__item .el-icon--close-tip {
      display: none !important;
  }
  
  .template-title-dialog .el-dialog__header{
    background-color: white !important;
  }
  
  /** IHC CSS **/
  </style>
  