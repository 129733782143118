<template>
    <div class="TaysachSandhoffLeukocytes">
        <div class="edit-box mb-20">
            <span class="title">CLINICAL HISTORY</span>
            <ckeditor :editor="editor" v-model="clinical_history" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INDICATION</span>
            <ckeditor :editor="editor" v-model="Indication" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">LEUKOCYTE ENZYME ACTIVITY</span>
            <el-table :data="Leukocyte_Enzyme_Activity" style="width: 100%">
               <el-table-column prop="Test" label="Test (Units)" width="210">
                <template v-slot="scope">
                    <el-input v-model="scope.row.Test" placeholder="Enter Test (Units)" type="textarea"  ></el-input>
                </template>
               </el-table-column>
               <el-table-column label="Disorder" width="180">
                <template v-slot="scope">
                  <el-input v-model="scope.row.Disorder" placeholder="Enter Disorder" type="textarea" ></el-input>
               </template>
               </el-table-column>
               <el-table-column label="Result" width="150">
               <template v-slot="scope">
                <el-input v-model="scope.row.Result" placeholder="Enter Result" type="textarea" ></el-input>

               </template>
               </el-table-column>
               <el-table-column prop="Reference_interval" label="Reference Interval(Mean ± SD)" width="150">
                <template v-slot="scope">
                        <el-input v-model="scope.row.Reference_interval" placeholder="Enter Biological Reference Interval" type="textarea" ></el-input>
                    </template>
               </el-table-column>
                <el-table-column prop="Status" label="Status" width="150">
                    <template v-slot="scope">
                        <el-select placeholder="Status" :clearable="true" :filterable="true" v-model="scope.row.Status">
                            <el-option label="Normal" value="Normal"></el-option>
                            <el-option label="Deficient" value="Deficient"></el-option>
                            <el-option label="Low" value="Low"></el-option>
                            <el-option label="Elevated" value="Elevated"></el-option> 
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope"
                    v-if="this.patientInfo?.from == undefined">
                    <el-row :gutter="12">
                        <el-col :span="6" v-if="scope.row.sno === Leukocyte_Enzyme_Activity?.length">
                            <el-buttton @click="onAddItem" class="c-pointer"><el-icon :size="20">
                                    <CirclePlus />
                                </el-icon></el-buttton>
                        </el-col>
                        <el-col :span="6" v-if="Leukocyte_Enzyme_Activity?.length > 1"><el-buttton
                                @click="onDeleteItem(scope.row)"><el-icon :size="19">
                                    <Delete style="color: red;" />
                                </el-icon></el-buttton>
                        </el-col>
                    </el-row>

                </el-table-column>
            </el-table>

        </div>
        <div class="edit-box mb-20">
            <span class="title">METHOD</span>
            <ckeditor :editor="editor" v-model="Method" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RESULT</span>
            <ckeditor :editor="editor" v-model="Result" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INTERPRETATION</span>
            <ckeditor :editor="editor" v-model="Interpretation" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RECOMMENDATION</span>
            <ckeditor :editor="editor" v-model="recommendations" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">COMMENT</span>
            <ckeditor :editor="editor" v-model="comment" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Note" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
   </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";


export default{
    components:{
        ckeditor: CKEditor.component,
    },
    props: {
    taysachSandhoffLeukocytes: {
      type: Array,
      required: true
    },
    patientInfo:{
        type:Object
    }
   },
    data(){
        return{
            editor: ClassicEditor,
            editorData: '',
            show: false,
            impression: "",
            resultData: "",
            references: "",
            remarks: "",
            editorConfig: EditorConfig,
            textarea: "",
            clinical_history: this.taysachSandhoffLeukocytes?.length ==1?this.taysachSandhoffLeukocytes[0].clinical_history:"",
            comment: this.taysachSandhoffLeukocytes?.length ==1?this.taysachSandhoffLeukocytes[0].comment:"",
            recommendations: this.taysachSandhoffLeukocytes?.length ==1?this.taysachSandhoffLeukocytes[0].recommendations:"",
            Note:this.taysachSandhoffLeukocytes?.length ==1 &&this.taysachSandhoffLeukocytes[0].Note ?this.taysachSandhoffLeukocytes[0].Note:"It is presumed that the specimen used to perform the test belongs to the patient specified above, such verification having been carried out at the collection level of sample. Please correlate with clinical findings and other test findings.",
            Indication: this.taysachSandhoffLeukocytes?.length ==1?this.taysachSandhoffLeukocytes[0].Indication:"",
            Interpretation: this.taysachSandhoffLeukocytes?.length==1  ?this.taysachSandhoffLeukocytes[0].Interpretation:"Normal activities of β-hexosaminidase A and b-hexosaminidase total (A+B) in peripheral blood leukocytes may rule out the possibility of Sandhoff disease (GM2 gangliosidosis).",
            Method: this.taysachSandhoffLeukocytes?.length==1  ?this.taysachSandhoffLeukocytes[0].Method:"Artificial Fluorogenic substrates.",
            Result:this.taysachSandhoffLeukocytes?.length ==1?this.taysachSandhoffLeukocytes[0].Result:"Normal activities of β-hexosaminidase A and b-hexosaminidase total (A+B) are obtained in leukocytes. Normal enzyme activity of reference enzyme β-galactosidase is obtained.",  
            Leukocyte_Enzyme_Activity: [
                {
              sno: 1,
              Test: 'β-Hexosaminidase A(nmol/h/mg)',
              Disorder: 'Tay-Sachs',
              Result: '',
              Reference_interval: '91 – 260 (181 ± 61)',
              Status: 'Normal',
                },
                {
              sno: 2,
              Test: 'β-Hexosaminidase total (A+B)(nmol/h/mg)',
              Disorder: 'Sandhoff',
              Result: '',
              Reference_interval: '1353 – 3834 (2453 ± 608)',
              Status: 'Normal',
                },
                {
              sno: 3,
              Test: '* β-Galactosidase(nmol/h/mg)',
              Disorder: 'GM1-gangliosidosis',
              Result: '',
              Reference_interval: '85 - 300 (141 ± 61)',
              Status: 'Normal',
                }
            ],           
            signature_required:(this.patientInfo && this.patientInfo.is_required_signature) ? this.patientInfo.is_required_signature : false
        }        
    },
    
    
 mounted() {
    this.Leukocyte_Enzyme_Activity=this.taysachSandhoffLeukocytes && this.taysachSandhoffLeukocytes[0].Leukocyte_Enzyme_Activity.length > 0?this.taysachSandhoffLeukocytes[0].Leukocyte_Enzyme_Activity:this.Leukocyte_Enzyme_Activity
  },
    methods: {
        sendTaysachSandhoffLeukocytes() {
        let dd = window.document.getElementsByClassName("ck-content");
        const clinical_history = dd[0]?.innerHTML;
        const Indication = dd[1]?.innerHTML;
        const Method = dd[2]?.innerHTML;
        const Result = dd[3]?.innerHTML;
        const Interpretation = dd[4]?.innerHTML;
        const recommendations = dd[5]?.innerHTML;
        const comment = dd[6]?.innerHTML;
        const Note = dd[7]?.innerHTML;

        this.Leukocyte_Enzyme_Activity = this.Leukocyte_Enzyme_Activity.filter(detail =>detail.Test !== '' || detail.Disorder !== '' || detail.Result !== '' || detail.Reference_interval !== '' || detail.Status !== '');
   const result = [{
           Indication: Indication,
           Leukocyte_Enzyme_Activity: this.Leukocyte_Enzyme_Activity,
           Method:Method,
           Result: Result,
           Interpretation: Interpretation,
           signature_required: this.signature_required,   
           Note:Note,
           clinical_history:clinical_history,
           recommendations:recommendations,
           comment:comment
          }]
        return this.$emit('sendTaysachSandhoffLeukocytes', result);
    },
    onDeleteItem(index) {
            this.Leukocyte_Enzyme_Activity?.splice(index.sno - 1, 1)
            this.Leukocyte_Enzyme_Activity?.forEach((data, index) => {
                data.sno = index + 1
            })
        },
        onAddItem() {
            this.Leukocyte_Enzyme_Activity?.push({
                sno: this.Leukocyte_Enzyme_Activity?.length + 1,
                Test: '',
                Disorder: '',
                Reference_interval: '',
                Result: '',
                Status: ''
            })
        },
  }
}
</script>


<style lang="scss" scoped>
.TaysachSandhoffLeukocytes {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>