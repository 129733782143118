<template>
  <section
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
  >
    <!-- <h3 v-if="getPatientReportData.template_title">
      Template - {{ getPatientReportData.template_title }}
    </h3> -->
    <!-- <div v-html="getPatientReportPreviewData"></div> -->
    <div v-if="showError">
      <h3 style="color: red">{{ getError || "Error in report preview" }}</h3>
    </div>
  </section>
</template>

<script>
// import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      loadingText: "Fetching Report Preview..",
      patientInfo: "",
      showError: false,
    };
  },
  computed: {
    ...mapGetters("resultFiles", [
      "getPatientResultPreviewData",
      "getReportPreview",
    ]),
    ...mapGetters("errors", ["getError"]),
  },
  async mounted() {
    await this.fetchSinglePatientPreview();
  },
  methods: {
    async fetchSinglePatientPreview() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "resultFiles/addReportTemplatePreview",
          this.getPatientResultPreviewData
        );

        if (this.getReportPreview) {
          document.write(this.getReportPreview);
          setTimeout(() => {
            window.print();
          }, 600);
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/scss/components/reports.scss";
.patient-report-preview {
  h3 {
    text-align: center;
    margin-bottom: 20px;
    padding: 5px 0;
    background: #fff;
  }
  @media print {
    h3 {
      display: none;
    }
  }
}
</style>
