<template>
  <section
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
  >
    <div v-html="getReportTemplatePreview"></div>
  </section>
</template>

<script>
// import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      patientInfo: "",
      loadingText: "",
    };
  },
  computed: {
    ...mapGetters("biochemistryTemplates", [
      "getReportTemplatePreview",
      "getTemplateReportData",
    ]),
  },
  async mounted() {
    console.log(this.getTemplateReportData);
    this.loading = true;
    await this.$store.dispatch(
      "biochemistryTemplates/addTemplateReportPreview",
      this.getTemplateReportData
    );
    this.loading = false;
  },
  methods: {
    goToTemplates() {
      this.$router.push({ name: "BiochemistryTemplates" });
    },
    goToEditBioChemicalReports() {
      try {
        this.$router.push({
          name: "UpdateBiochemistryTemplate",
          params: {
            template_id: this.$route.params.template_id,
          },
          query: this.$route.query,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async fetchSingletemplateForm() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data...";
        await this.$store.dispatch(
          "biochemistryTemplates/fetchSingleBiochemistryTemplate",
          this.$route.params.template_id
        );
        this.setPatientData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/scss/components/reports.scss";
</style>
