import axios from "@/config/axios";

// initial state
const initialState = () => {
  return {
    patientDetails: null,
    patientReportURL: null,
  };
};

const state = initialState();

const getters = {
  getPatientDetails: (state) => state.patientDetails,
  getPatientReportURL: (state) => state.patientReportURL,
};

const mutations = {
  setPatientData(state, data) {
    state.patientDetails = data;
  },
  setPatientReportURL(state, data) {
    console.log(data);
    state.patientReportURL = data;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  async fetchPatientReportURL({ commit, dispatch }, reportId) {
    try {
      commit("setPatientReportURL", null);
      const response = await axios.get(
        `/patient-reports-result/${reportId}/secure-url`
      );

      commit("setPatientReportURL", response.data);
    } catch (err) {
      commit("setPatientReportURL", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchPatientReport({ commit, dispatch }, reportId) {
    try {
      commit("setPatientData", null);
      const response = await axios.get(`/patient-reports-result/${reportId}`);

      commit("setPatientData", response.data);
    } catch (err) {
      commit("setPatientData", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  reset: function ({ commit }) {
    commit("reset", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
