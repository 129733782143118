import { mapGetters } from "vuex";
import { userRolesByPermission } from "@/constants/formConstants";
export default {
  computed: {
    isAbleToAccess() {
      return (permission) => {
        if (this.getProfileDetails && this.getProfileDetails.user_type) {
          const permissions = userRolesByPermission.find(
            (userType) => userType.role == this.getProfileDetails.user_type
          );
          if (
            permissions &&
            permissions.permissions &&
            permissions.permissions.length &&
            permissions.permissions.indexOf(permission) != -1
          ) {
            return true;
          }
        }
        return false;
      };
    },
    isAbleToAccessEdit() {
      if (
        this.getProfileDetails &&
        this.getProfileDetails.user_type &&
        ["FRONT_DESK", "HELP_DESK"].indexOf(this.getProfileDetails.user_type) !=
          -1
      ) {
        return false;
      }
      return true;
    },
    ...mapGetters("auth", ["getProfileDetails","getAuthenticatedUser"]),
    isDoctor() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        this.getAuthenticatedUser.user_type == 'DOCTOR'
      ) {
        return true;
      }
      return false;
    },
  },
};
