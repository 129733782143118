<template>
    <div>
        <iframe
        :src="reportData.src"
        width="100%"
        :height="getScreenHeight"
        class="iframe-preview"
        ></iframe>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from "axios";
export default {
    data(){
        return {
            reportData:{
                src:""
            }
        }
    },
    computed:{
    ...mapGetters("patientReports",["getFormVReportPreview"]),
    getScreenHeight() {
        return screen.height - 50;
    },
    },
    async mounted(){
        if(this.$route.params.path)
        await this.formVReportPreview()
    },
    methods:{
    async formVReportPreview() {
        try {
            let params = {
                path:this.$route.params.path
            }
            await this.$store.dispatch("patientReports/downloadFormVReport",params)
            if(this.getFormVReportPreview){
                console.log("getFormVReportPreview",this.getFormVReportPreview)
                let url = this.getFormVReportPreview.download_url
                const fileResponse = await axios({
                    url: url, //your url
                    method: "GET",
                    responseType: "blob", // important
                });
                url = window.URL.createObjectURL(
                    new Blob([fileResponse.data], { type: "application/pdf" })
                );
                this.reportData.src = url
            }
        } catch(err){
            console.log(err)
        }
    }
    }
}
</script>