<template>
  <section
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
  >
    <div v-if="showError">
      <h3 style="color: red">{{ getError || "Error in report preview" }}</h3>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      result_file_id: "",
      patient_id: "",
      loadingText: "Fetching Report Preview..",
      reportPreview: "",
      showError: false,
    };
  },
  computed: {
    ...mapGetters("resultFiles", ["getPatientResultFilePreview"]),
  },
  unmounted() {
    this.toggleBodyClass("removeClass", "white-bg");
  },
  mounted() {
    this.toggleBodyClass("addClass", "white-bg");
    this.result_file_id = this.$route.params.result_file_id;
    this.patient_id = this.$route.params.patient_id;
    this.fetchPatientReportPreview();
  },

  methods: {
    async fetchPatientReportPreview() {
      try {
        this.loading = true;
        this.showError = false;

        let params = {
          result_file_id: this.result_file_id,
          patient_id: this.patient_id,
          params: {
            include_header_footer:
              this.$route.query.selectedStatus == "false" ? false : true,
            is_color_variant:
              this.$route.query.isColorPrint == "false" ? false : true,
          },
        };
        await this.$store.dispatch(
          "resultFiles/fetchPatientResultFilePreview",
          params
        );
        if (this.getPatientResultFilePreview) {
          this.reportPreview = this.getPatientResultFilePreview;
          document.write(this.reportPreview);
          setTimeout(() => {
            window.print();
          }, 1000);
        } else {
          this.showError = true;
        }
        this.loading = false;
      } catch (err) {
        this.showError = true;
        console.log(err);
      }
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    goToReports() {
      this.$router.push({
        name: "PatientResultFiles",
        query: this.$route.query,
        params: {
          result_file_id: this.$route.params.result_file_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reports-title-header {
  display: grid;
  grid-template-columns: 75% auto;
  margin: 15px 0;
  .display-end {
    display: flex;
    text-align: end;
  }
  .report-title {
    text-align: center;
    color: #000;
    font-size: 20px;
    font-weight: 500;
  }

  .back-btn {
    padding: 9px 15px;
    min-height: auto;
    line-height: 1;
    color: #333;
    border: 1px solid #47525d42;
    background-color: white;

    border-radius: 5px;

    font-weight: 500;
    .el-icon-back {
      margin-right: 4px;
    }
    &:active,
    &:hover,
    &:focus {
      border: 1px solid #47525d42;
    }
  }
}
@media print {
  .reports-title-header {
    display: none !important;
  }
}
</style>

<style>
.white-bg {
  background: #fff !important;
}
</style>
