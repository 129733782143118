<template>
    <div class="MPSVILeukocytes">
        <div class="edit-box mb-20">
            <span class="title">CLINICAL HISTORY</span>
            <ckeditor :editor="editor" tag-name="div" v-model="clinical_history" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INDICATION</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Indication" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
             <span class="title">LEUKOCYTE ENZYME ACTIVITY</span>
             <el-table :data="leukocyte_enzyme_activity" style="width: 100%">

                 <el-table-column prop="test" label="Test (Units)" width="270">
                    <template v-slot="scope">
                         <el-input type="textarea" autosize v-model="scope.row.test" placeholder="Test (Units)"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column prop="disorder" label="Disorder" width="180">
                    <template v-slot="scope">
                         <el-input type="textarea" autosize v-model="scope.row.disorder" placeholder="Disorder"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column label="Results" width="100">
                     <template v-slot="scope">
                         <el-input type="textarea" autosize v-model="scope.row.results" placeholder="Results"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column prop="reference" label="Reference Interval(Mean ± SD)" width="230">
                    <template v-slot="scope">
                         <el-input type="textarea" autosize v-model="scope.row.reference" placeholder="Reference Interval(Mean ± SD)"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column label="Status" width="150">
                    <template v-slot="scope">
                        <el-select placeholder="Status" :clearable="true" :filterable="true" v-model="scope.row.status">
                            <el-option label="Normal" value="Normal"></el-option>
                            <el-option label="Deficient" value="Deficient"></el-option>
                            <el-option label="Low" value="Low"></el-option>
                            <el-option label="Elevated" value="Elevated"></el-option> 
                        </el-select>
                    </template>
                 </el-table-column>

                  <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope" v-if="this.patientInfo?.from==undefined">
                                <el-row :gutter="12">
                                    <el-col :span="6" v-if="scope.row.sno === leukocyte_enzyme_activity?.length">
                                        <el-buttton @click="onAddItem" class="c-pointer"><el-icon :size="20">
                                                <CirclePlus />
                                            </el-icon></el-buttton>
                                    </el-col>
                                    <el-col :span="6" v-if="leukocyte_enzyme_activity?.length > 1"><el-buttton
                                            @click="onDeleteItem(scope.row)"><el-icon :size="19">
                                                <Delete style="color: red;" />
                                            </el-icon></el-buttton>
                                    </el-col>
                                </el-row>

                    </el-table-column>
             </el-table>
         </div>
        <div class="edit-box mb-20">
            <span class="title">METHOD</span>
            <ckeditor :editor="editor" v-model="Method" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RESULT</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Result" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INTERPRETATION</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Interpretation" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RECOMMENDATION</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Recommendations" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">COMMENT</span>
            <ckeditor :editor="editor" tag-name="div" v-model="comment" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Note" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
   </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";


export default{
    components:{
        ckeditor: CKEditor.component,
    },
     props: {
    dataArray: {
      type: Array,
      required: true,
    },
     patientInfo:{
        type: String, 
    }
  },
    data(){
        return{
            editor: ClassicEditor,
            editorData: '',
            show: false,
            comment:this.dataArray?.length == 1 ? this.dataArray[0].comment : "",
            Indication:this.dataArray?.length == 1 ? this.dataArray[0].Indication : "",
            recommendations: "",
            impression: "",
            resultData: "",
            references: "",
            remarks: "",
            editorConfig: EditorConfig,
            textarea: "",
            clinical_history:
            this.dataArray?.length == 1 ? this.dataArray[0].clinical_history : "",
            leukocyte_enzyme_activity: this.dataArray?.length == 1 ? this.dataArray[0].Leukocyte_enzyme_activity : [
                {
                    sno:1,
                    test: 'Arylsulphatase B (nmol/h/mg)',
                    disorder: 'Maroteaux-Lamy (MPS VI)',
                    results:'',
                    reference: '60 – 315 (179.4 ± 87.2)',
                    status:'Normal'
                },
                {
                   sno:2,
                   test: '* β-Galactosidase (nmol/h/mg)',
                   disorder: 'MPS IVB',
                   results:'',
                   reference: '85 – 300 (142 ± 59)',
                   status:'Normal'
                }
            ],
        Method: this.dataArray?.length == 1 ? this.dataArray[0].Method : "Artificial chromogenic & fluorogenic substrates.",
        Result: this.dataArray?.length == 1 ? this.dataArray[0].Result : "A normal activity of arylsulphatase B is obtained in peripheral blood leukocytes.",
        Interpretation: this.dataArray?.length == 1 ? this.dataArray[0].Interpretation : "Normal activity of arylsulphatase B in leukocytes may rule out the possibility of Maroteaux-Lamy (MPS VI) disease.",
        Recommendations:this.dataArray?.length == 1 ? this.dataArray[0].Recommendations : "",
        Note:this.dataArray?.length == 1 ? this.dataArray[0].Note : "It is presumed that the specimen used to perform the test belongs to the patient specified above, such verification having been carried out at the collection level of sample. Please correlate with clinical findings and other test findings.",
        }  
    },
      methods: {
        sendmpsLeukocytes() {
        let dd = window.document.getElementsByClassName("ck-content");
        const clinical_history = dd[0]?.innerHTML;
        const Indication = dd[1]?.innerHTML;
        const Method = dd[2]?.innerHTML;
        const Result = dd[3]?.innerHTML;
        const Interpretation = dd[4]?.innerHTML;
        const Recommendations = dd[5]?.innerHTML;
        const comment = dd[6]?.innerHTML;
        const Note = dd[7]?.innerHTML;
   const result = [{
           clinical_history: clinical_history,
            Leukocyte_enzyme_activity: this.leukocyte_enzyme_activity,
            Method:Method,
            Result: Result,
            Interpretation: Interpretation,
            Recommendations: Recommendations,
            Note:Note,
            Indication:Indication,
            comment:comment
          }]
        return this.$emit('sendmpsLeukocytes', result);
    },
    onDeleteItem(index) {
            this.leukocyte_enzyme_activity?.splice(index.sno - 1, 1)
            this.leukocyte_enzyme_activity?.forEach((data, index) => {
                data.sno = index + 1
            })
        },
    onAddItem() {
        this.leukocyte_enzyme_activity?.push({
            sno: this.leukocyte_enzyme_activity?.length + 1,
            test: '',
            disorder: '',
            results: '',
            reference: '',
            status:''
        })
    },
  }
}
</script>


<style lang="scss" scoped>
.MPSVILeukocytes {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>