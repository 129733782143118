<template>
  <section id="all-patient-reports">
    <div v-loading="loading" :element-loading-text="loadingText">
      <div class="page-navigation">
      <el-row>
        <el-col :lg="12">
          <ul class="filter-navigation">
            <li>
              <div class="date-filter">
                <el-date-picker
                  v-model="fromDate"
                  type="date"
                  format="DD-MM-YYYY"
                  value-format="YYYY-MM-DD"
                  placeholder="From Date"
                  :disabled-date="disabledDate"
                  @change="fetchDateFilterData"
                ></el-date-picker>
              </div>
           </li>
           <li>
              <div class="date-filter">
                <el-date-picker
                  v-model="toDate"
                  type="date"
                  format="DD-MM-YYYY"
                  value-format="YYYY-MM-DD"
                  placeholder="To Date"
                  :disabled-date="disabledDate"
                  @change="fetchDateFilterData"
                ></el-date-picker>
              </div>
           </li>
          </ul>
        </el-col>
        <el-col  :lg="12">
          <div class="action-navigation">
            <el-button class="default-button add-button" @click="exportToExcel">Export to Excel
              <i class="el-icon-delete"></i>
             </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
      <div class="vue-table">
        <el-table :data="allpatientclinicaldata" style="width: 100%" height="calc(100vh - 250px)" class="table-list display-web">
          <el-table-column  prop="created_at" label="Date" sortable="custom"> 
            <template #default="scope">
              <span class="date" v-if="scope.row.created_at">
                {{ date(scope.row.created_at) }}
                {{ time(scope.row.created_at) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="visit_id" label="Visit ID" sortable="custom"> 
            <template #default="scope">
              {{ scope.row.visit_id ? scope.row.visit_id : "--" }}
            </template>
          </el-table-column>
          <el-table-column prop="client_code" label="Client Code" sortable="custom"> 
            <template #default="scope">
              {{ scope.row.client_code ? scope.row.client_code : "--" }}
            </template>
          </el-table-column>
          <el-table-column prop="patient_name" label="Patient Name" sortable="custom">
            <template #default="scope">
              <span class="doctor-name">
                {{ scope.row.patient_name ? scope.row.patient_name : "--" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="Clinical" label="Clinical Detail" sortable="custom"> 
            <template #default="scope">
              {{ scope.row.ref_doctor ? scope.row.Clinical : "--" }}</template>
          </el-table-column>
          <el-table-column prop="Impression" label="Impression" sortable="custom"> 
           <template #default="scope">
             {{ scope.row.ref_doctor ? stripHTMLTags(scope.row.Impression) : "--" }}
           </template>
         </el-table-column>
          <el-table-column prop="Malignant" label="Maligant" sortable="custom"> 
            <template #default="scope">
              {{ scope.row.Malignant ?  scope.row.Malignant : "--" }}</template>
          </el-table-column>
          <el-table-column prop="storageType" label="Storage Type" sortable="custom">
            <template #default="scope">
              {{ scope.row.ref_doctor ? scope.row.storageType : "--" }}</template>
          </el-table-column>
          <el-table-column prop="BlockType" label="BlockType" sortable="custom"> 
            <template #default="scope">
              {{ scope.row.ref_doctor ? scope.row.BlockType : "--" }}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="vue-pagination">
        <el-pagination
         @size-change="size => handleSizeChange(size)"
         @current-change="loadData"
         :page-sizes="[10, 25, 50, 100]"
         :page-size="pageSize"
         layout="total, sizes, prev, pager, next, jumper"
         :total="total"
         :current-page="page"
       >
       </el-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import userRolesPermissionsMixin from "@/mixins/userRolesPermissionsMixin";
import moment from "moment";
import * as XLSX from 'xlsx';

export default {
  name: 'ExportToExcel',
  middleware: 'auth',
  mixins: [userRolesPermissionsMixin],
  data() {
    return {
    allpatientclinicaldata:[],
    fromDate:'',
    toDate:'',
    page: 1,
    pageSize: 10,
    total: 0,
   
    }
  },
  computed: {
    ...mapGetters('patientReports', ['getAllallclinicaldetails']),
  },
  async mounted() {
    this.$store.dispatch('errors/clear');
    await this.fetchAllclinicaldetails();
  },
  methods: {

    /**
     * this service method used to get clinical details
     */
    async fetchAllclinicaldetails() {
      this.loading = true;
      this.loadingText = "Fetching data...";
      let params= {
        fromDate: this.fromDate,
        toDate: this.toDate,
        page:this.page,
        pageSize:this.pageSize
      }
      try {
        await this.$store.dispatch("patientReports/fetchAllclinicaldetails",params);
        this.allpatientclinicaldata = this.getAllallclinicaldetails.data.data;
        this.setTableData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    date(value) {
      if (!value) return;
      return moment(value).format("DD-MM-yyyy");
    },
    time(value) {
      if (!value) return;
      return moment(value).format("hh:mm a");
    },
    /**
     * this event used to change the date
     */
    async fetchDateFilterData() {
      if(this.fromDate && this.toDate){
        this.fetchAllclinicaldetails();
      }else if(!this.fromDate && !this.toDate){
        this.fetchAllclinicaldetails();
      }
    },
    /**
     * this event used to export the grid to excel
     */
    exportToExcel() {
      const dataToExport = this.allpatientclinicaldata.map(item => ({
        VisiId: item.visit_id,
        ClintCode: item.client_code,
        PatientName: item.patient_name,
        ClientName	:item.client_name,
        Gender: item.gender,
        Age: item.age,
        SampleType: item.sample_type,
        ClincaDetail: item.Clinical,
        Impression: item.Impression,
        Malignant: item.Malignant,
        StorageType: item.storageType,
        BlockType: item.BlockType,
      }));
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'allpatientclinicaldata');
      const columnSizes = [20, 20, 30, 30, 20, 10, 30, 30, 10, 10, 20]; 
      columnSizes.forEach((size, i) => {
        ws['!cols'] = ws['!cols'] || [];
        ws['!cols'][i] = { wch: size };
      });
      XLSX.writeFile(wb, 'allpatientclinicaldata.xlsx');
    },
    /**
     * this event used to set pagination values
     */
    setTableData() {
      const tableData = JSON.parse(JSON.stringify(this.getAllallclinicaldetails));
      if (tableData && tableData.data) {
      this.page = tableData.data?.page || 1;
      this.total = tableData.data?.total || 0;
      this.pageSize = tableData.data?.pageSize || 10;
      console.log(this.page, this.pageSize, this.total,);
      }
    },
    /**
     * this change event used to change the page size
     */
    async handleSizeChange(size) {
     try {
       this.pageSize = size;
       this.page = 1;
       await this.fetchAllclinicaldetails();
     } catch (error) {
       console.error('Error in handleSizeChange:', error);
     }
   },
  /**
   * this change event used to change the page number
   */
   async loadData(page) {
      this.page = page; 
      await this.fetchAllclinicaldetails(); 
    },
    stripHTMLTags(html) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || '';
    }
  },
};
</script>
