<template>
  <div class="scroll-view">
    <div
      class="edit-profile-card"
      v-loading="loading"
      :element-loading-text="loadingText"
    >
      <div class="headingPart">
        <h3>Edit Profile</h3>
      </div>
      <div class="bodyPart">
        <el-form
          class="add-users"
          label-position="top"
          :model="profileDetails"
          label-width="120px"
        >
          <el-row :gutter="20">
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="First Name" class="mb-05 mandatory-field">
                <el-input
                  v-model="profileDetails.first_name"
                  placeholder="Enter First Name"
                ></el-input>
                <FormError errorName="first_name"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Last Name" class="mb-05 mandatory-field">
                <el-input
                  v-model="profileDetails.last_name"
                  placeholder="Enter Last Name"
                ></el-input>
                <FormError errorName="last_name"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <div class="gender-select">
                <el-form-item
                  prop="gender"
                  label="Gender"
                  class="mandatory-field"
                >
                  <el-select
                    v-model="profileDetails.gender"
                    placeholder="Select Gender"
                  >
                    <el-option
                      v-for="(gender, index) in genderTypes"
                      :key="index"
                      :label="gender"
                      :value="gender"
                    ></el-option>
                  </el-select>
                  <FormError errorName="gender"></FormError>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Email">
                <el-input
                  type="email"
                  v-model="profileDetails.email"
                  placeholder="Enter Email"
                  class="email-lower"
                ></el-input>

                <FormError errorName="email"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Mobile No" class="mb-05">
                <el-input
                  v-model="profileDetails.mobile"
                  placeholder="Enter mobile Number"
                  maxlength="10"
                ></el-input>
                <FormError errorName="mobile"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Address Line1">
                <el-input
                  v-model.trim="profileDetails.address.address_line_1"
                  placeholder="Enter Address Line1"
                  type="textarea"
                ></el-input>
                <div class="error-display">
                  <FormError errorName="address.address_line_1"></FormError>
                </div>
              </el-form-item>
            </el-col>
            <!-- <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Username" class="mb-05 mandatory-field">
              <el-input
                v-model="profileDetails.username"
                placeholder="Enter Username"
              ></el-input>
              <FormError errorName="username"></FormError>
            </el-form-item>
          </el-col> -->
          </el-row>

          <el-row :gutter="20">
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="City" class="mb-05 mandatory-field">
                <el-input
                  v-model.trim="profileDetails.address.city"
                  placeholder="Enter City"
                ></el-input>
                <div class="error-display">
                  <FormError errorName="address.city"></FormError>
                </div>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="State" class="mb-05 mandatory-field">
                <el-select
                  style="width: 100%"
                  v-model="profileDetails.address.state"
                  placeholder="Select State"
                  :filterable="true"
                  :clearable="true"
                >
                  <el-option value="Telangana" label="Telangana"></el-option>
                  <el-option value="Andhra Pradesh" label="Andhra Pradesh">
                  </el-option>
                </el-select>
                <div class="error-display">
                  <FormError errorName="address.state"></FormError>
                </div>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Zip" class="mb-05 mandatory-field">
                <el-input
                  v-model.trim="profileDetails.address.zip"
                  placeholder="Enter Zip"
                ></el-input>
                <div class="error-display">
                  <FormError errorName="address.zip"></FormError>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Signature" class="mb-05">
                <el-input
                  v-model="profileDetails.signature_name"
                  placeholder="Enter Signature"
                ></el-input>
                <FormError errorName="signature_name"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Regd No" class="mb-05">
                <el-input
                  v-model.trim="profileDetails.regd_no"
                  placeholder="Enter Regd No"
                ></el-input>
                <div class="error-display">
                  <FormError errorName="regd_no"></FormError>
                </div>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Designation" class="mb-05">
                <el-input
                  v-model="profileDetails.designation"
                  placeholder="Enter Designation"
                ></el-input>
                <div class="error-display">
                  <FormError errorName="designation"></FormError>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="digitalSignature">
            <el-row>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item
                  class="mb-05 digital-signature"
                  :loading="imageLoading"
                >
                  <div class="inside">
                    <p>Digital Signature</p>
                    <el-button
                      type="danger"
                      class="clear-signature"
                      v-if="profileDetails.digital_signature"
                      @click="profileDetails.digital_signature = ''"
                      >Clear</el-button
                    >
                  </div>

                  <el-upload
                    class="avatar-uploader"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="onChangeImage"
                    accept="image/*"
                  >
                    <img
                      v-if="profileDetails.digital_signature"
                      :src="profileDetails.digital_signature"
                      class="avatar-preview"
                    />
                    <el-icon v-else class="avatar-uploader-icon">
                      <el-icon><Picture /></el-icon>
                    </el-icon>
                  </el-upload>
                  <FormError errorName="digital_signature"></FormError>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <div class="footerPart">
        <ul>
          <li>
            <el-button
              @click="goToProfile"
              :disable="loading"
              class="cancel-btn-action"
              >Cancel</el-button
            >
          </li>
          <li>
            <el-button
              type="primary"
              @click="updateProfile"
              :disable="loading"
              class="btn-action"
              >Update Profile</el-button
            >
          </li>
        </ul>
        <FormError errorName="critical_error"></FormError>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormError from "@/components/FormError";
import { genderTypes } from "@/constants/formConstants";

import { ElMessage } from "element-plus";

export default {
  components: {
    FormError,
  },
  computed: {
    ...mapGetters("errors", ["getError", "getErrors"]),

    ...mapGetters("auth", ["getProfileUpdateStatus", "getProfileDetails"]),
  },
  data() {
    return {
      genderTypes: genderTypes,
      loading: false,
      searchState: "",
      filteredStates: [],
      filterString: "",
      citiesLoading: false,
      imageLoading: false,
      profileData: {},
      profileDetails: {
        first_name: "",
        last_name: "",
        signature_name: "",
        digital_signature: "",
        email: "",
        mobile: "",
        gender: "",
        user_type: "",
        // username: "",
        regd_no: "",
        designation: "",
        address: {
          address_line_1: "",
          state: "",
          city: "",
          zip: "",
        },
      },
      loadingText: "",
    };
  },
  async mounted() {
    await this.$store.dispatch("errors/clear", {}, { root: true });
    await this.getData();
  },
  methods: {
    async updateProfile() {
      try {
        this.loading = true;
        this.loadingText = "Updating Profile...";
        let params = { ...this.profileDetails };
        if (params.email) {
          params.email = params.email.toLowerCase();
        }
        await this.$store.dispatch("auth/updateProfile", params);

        this.loading = false;

        if (this.getProfileUpdateStatus) {
          this.$message({
            showClose: true,
            type: "success",
            message: "Profile Updated Successfully!",
          });
          this.goToProfile();
          return;
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error in Updating Profile",
          });
        }
      } catch (err) {
        this.loading = false;
      }
    },
    goToProfile() {
      this.$router.push({ name: "ViewProfile" });
    },
    async getData() {
      this.loading = true;
      this.loadingText = "Fetching Data...";
      await this.$store.dispatch("auth/getProfile");

      let profileData = JSON.parse(JSON.stringify(this.getProfileDetails));
      if (profileData) {
        this.profileDetails.first_name = profileData.first_name;
        this.profileDetails.last_name = profileData.last_name;
        this.profileDetails.signature_name = profileData.signature_name;
        this.profileDetails.digital_signature = profileData.digital_signature;

        this.profileDetails.email = profileData.email;
        this.profileDetails.mobile = profileData.mobile;
        this.profileDetails.gender = profileData.gender;
        this.profileDetails.designation = profileData.designation;
        this.profileDetails.regd_no = profileData.regd_no;
        // this.profileDetails.username = profileData.username;
        this.profileDetails.user_type = profileData.user_type;
        if (profileData.address) {
          this.profileDetails.address = { ...profileData.address };
        }
      }

      this.loading = false;
    },
    onChangeImage(image) {
      try {
        if (image.size / Math.pow(1024, 2) > 5) {
          ElMessage.error("Digital Signature size can not exceed 5MB!");
          return false;
        }
        this.imageLoading = true;
        const reader = new FileReader();

        reader.onloadend = () => {
          this.profileDetails.digital_signature = reader.result;
        };
        reader.readAsDataURL(image.raw);
        this.imageLoading = false;
      } catch (err) {
        this.imageLoading = false;
        console.log(err);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.qr-code {
  .error {
    line-height: 1;
    margin-top: 4px;
    margin-bottom: 0;
  }
}
</style>
<style lang="scss">
.edit-profile-card {
  background-color: white;
  width: 900px;
  border-radius: 10px;
  margin: auto;
  border: 1px solid #efefef;
  .headingPart {
    padding: 20px 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    h3 {
      font-size: 1.05em;
      font-weight: 500;
      color: rgb(24, 84, 109);
      line-height: 1;
    }
  }
  .bodyPart {
    padding: 20px 30px;
    .el-input__inner {
      height: 40px;
    }
    .digitalSignature {
      margin-top: 10px;
      h4 {
        margin-bottom: 10px !important;
      }
      img {
        border: 1px dashed #333;
        padding: 5px;
        border-radius: 2px;
      }
      .inside {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        p {
          padding: 0;
        }
        button {
          padding: 5px 10px;
          height: 22px;
        }
      }
    }
  }
  .footerPart {
    padding: 20px 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
        button {
          font-weight: 600;
        }
        .cancel-btn-action {
          background: #f94e5f;
          color: #fff;
        }
      }
    }
  }
  .el-form-item__label {
    line-height: 1;
  }
  .el-select {
    width: 100% !important;
  }
  h4 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 3rem;
  }
  .cancel-btn-action {
    border: 1px solid #f94e5fc7;
    min-height: 10px;
    padding: 9px 15px;
    &:hover,
    &:focus,
    &:active {
      border: 1px solid #f94e5f;
      background: none;
      color: black;
    }
  }

  .btn-action {
    background-color: #28b4a6;
    border: none;

    min-height: 10px;
    padding: 10px 15px;

    &:hover,
    &:focus,
    &:active {
      background-color: #28b4a6;
      color: white;
    }
  }
}
.add-users {
  .mandatory-field {
    .el-form-item__label:after {
      content: "*";
      color: red !important;
      font-size: 15px;
      line-height: 1;
      padding-left: 2px;
    }
  }
  .el-form-item__label {
    font-weight: 500;
  }
}

#signature,
.avatar-uploader {
  .el-upload {
    width: 100%;
  }
}

/* .avatar-uploader,
.avatar-uploader-icon {
  min-height: 100px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
} */

.avatar-preview {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
// .scroll-view {
//   background: #f5f7fa;
//   min-height: calc(100vh - 150px);
//   padding: 30px 0 0 0;
//   max-height: 80vh;
// }
.digital-signature {
  position: relative;
  .el-form-item__content {
    // margin-top: 10px !important;
  }
  .clear-signature {
    /* position: absolute;
    right: 0;
    top: -30px;
    padding: 2px 10px;
    min-height: auto; */
  }
  .el-icon-picture-outline {
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #333;
  }
}
</style>
