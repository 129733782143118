<template>
    <div class="FabryLeukocytes">
        <div class="edit-box mb-20">
            <span class="title">
                CLINICAL HISTORY
            </span>
            <ckeditor :editor="editor" v-model="clinical_history" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">
                INDICATION
            </span>
            <ckeditor :editor="editor" v-model="indication" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
             <span class="title">LEUKOCYTE ENZYME ACTIVITY</span>
             <el-table :data="leukocyte_enzyme_activity" style="width: 100%">

                <el-table-column prop="test" label="Test (Units)" width="270">
                    <template v-slot="scope">
                         <el-input type="textarea" autosize v-model="scope.row.test" placeholder="Test (Units)"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column prop="disorder" label="Disorder" width="180">
                    <template v-slot="scope">
                         <el-input type="textarea" autosize v-model="scope.row.disorder" placeholder="Disorder"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column label="Results" width="100">
                     <template v-slot="scope">
                         <el-input type="textarea" autosize v-model="scope.row.results" placeholder="Results"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column prop="reference" label="Reference Interval(Mean ± SD)" width="230">
                    <template v-slot="scope">
                         <el-input type="textarea" autosize v-model="scope.row.reference" placeholder="Reference Interval(Mean ± SD)"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column label="Status" width="150">
                    <template v-slot="scope">
                        <el-select placeholder="Status" :clearable="true" :filterable="true" v-model="scope.row.status">
                            <el-option label="Normal" value="Normal"></el-option>
                            <el-option label="Deficient" value="Deficient"></el-option>
                            <el-option label="Low" value="Low"></el-option>
                            <el-option label="Elevated" value="Elevated"></el-option> 
                        </el-select>
                    </template>
                 </el-table-column>

                  <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope" v-if="this.patientInfo?.from==undefined">
                                <el-row :gutter="12">
                                    <el-col :span="6" v-if="scope.row.sno === leukocyte_enzyme_activity?.length">
                                        <el-buttton @click="onAddItem" class="c-pointer"><el-icon :size="20">
                                                <CirclePlus />
                                            </el-icon></el-buttton>
                                    </el-col>
                                    <el-col :span="6" v-if="leukocyte_enzyme_activity?.length > 1"><el-buttton
                                            @click="onDeleteItem(scope.row)"><el-icon :size="19">
                                                <Delete style="color: red;" />
                                            </el-icon></el-buttton>
                                    </el-col>
                                </el-row>

                    </el-table-column>
             </el-table>
         </div>
        <div class="edit-box mb-20">
            <span class="title">METHOD</span>
            <ckeditor :editor="editor"  v-model="method" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RESULT</span>
            <ckeditor :editor="editor"  v-model="results" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INTERPRETATION</span>
            <ckeditor :editor="editor"  v-model="interpretation" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RECOMMENDATION</span>
            <ckeditor :editor="editor"  v-model="recommendations" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor> 
        </div>
        <div class="edit-box mb-20">
            <span class="title">COMMENT</span>
            <ckeditor :editor="editor" v-model="comment" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
         <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <ckeditor :editor="editor" v-model="note" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
   </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";


export default{
    components:{
        ckeditor: CKEditor.component,
    },
    props: {
    Fabry_Leukocytes_Array: {
      type: Array,
      required: true
    },
    patientInfo:{
        type: String, 
    }
  },
  mounted(){
  },
    data(){
        return{
            editor: ClassicEditor,
            editorData: '',
            show: false,
            recommendations:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].recommendations:'',
            impression: "", 
            resultData: "",
            references: "",
            remarks: "",
            editorConfig: EditorConfig,
            textarea: "",
            clinical_history:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].clinical_history:'',
            comment:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].comment:'',
            indication:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].indication:'',
            method:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].method:'Artificial fluorogenic substrates.', 
            results:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].results:
            this.patientInfo.report_type === 'Fabry_Leukocytes'?'A normal activity of α-galactosidase is obtained in leukocytes.':
            this.patientInfo.report_type === 'Gaucher_Leukocytes'?'A normal enzyme activity of β-glucosidase is obtained in leukocytes.':
            this.patientInfo.report_type === 'Krabbe_Leukocytes'?'A normal enzyme activity of β-galactocerebrosidase is obtained in peripheral blood leukocytes.':
            this.patientInfo.report_type === 'Glycoasparginase_Leukocytes'?'A normal activity of glycosylasparaginase is obtained in peripheral blood leukocytes.':
            'A normal activity of tripeptidyl peptidase 1 is obtained in peripheral blood leukocytes.',
            interpretation:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].interpretation:
            this.patientInfo.report_type === 'Fabry_Leukocytes'?'Normal activity of α-galactosidase in leukocytes may rule out the possibility of Fabry disease.':
            this.patientInfo.report_type === 'Gaucher_Leukocytes'?'Normal activity of β-glucosidase in leukocytes may rule out the possibility of Gaucher disease.':
            this.patientInfo.report_type === 'Krabbe_Leukocytes'?'Normal activity of β-galactocerebrosidase in leukocytes may rule out the possibility of Krabbe Disease (Globoid Cell Leukodystrophy).':
            this.patientInfo.report_type === 'Glycoasparginase_Leukocytes'?'Normal activity of glycosylasparaginase in leukocytes may rule out the possibility of Aspartylglucosaminuria':
            'Normal activity of tripeptidyl peptidase 1 in leukocytes may rule out the possibility of Late infantile Neuronal Ceroid Lipofuscinosis 2 (NCL2).',
            note:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].note:
            'It is presumed that the specimen used to perform the test belongs to the patient specified above, such verification having been carried out at the collection level of sample. Please correlate with clinical findings and other test findings.',
            subcategory:this.patientInfo.report_type,
            leukocyte_enzyme_activity: this.Fabry_Leukocytes_Array?.length == 1 ? this.Fabry_Leukocytes_Array[0].leukocyte_enzyme_activity : [
                {
                    sno: 1,
                    test: this.patientInfo.report_type==='Krabbe_Leukocytes'?'β-Galactocerebrosidase (nmol/17h/mg)':
                     this.patientInfo.report_type==='Glycoasparginase_Leukocytes'?'Glycosylasparaginase (nmol/24h/mg)':
                     this.patientInfo.report_type==='Gaucher_Leukocytes'?'β-Glucosidase (nmol/h/mg)':
                     this.patientInfo.report_type==='Leukocytes_NCL2'?'Tripeptidyl Peptidase 1 (nmol/h/mg)':'α-Galactosidase  (nmol/h/mg)',
                     disorder : 
                    (this.patientInfo.report_type === 'Fabry_Leukocytes' ? 'Fabry' :
                    (this.patientInfo.report_type === 'Gaucher_Leukocytes' ? 'Gaucher' :
                    (this.patientInfo.report_type === 'Krabbe_Leukocytes' ? 'Krabbe' :
                    (this.patientInfo.report_type === 'Glycoasparginase_Leukocytes' ? 'Aspartylglucosaminuria' :'NCL2')))),
                    results:'',
                    reference: (this.patientInfo.report_type === 'Fabry_Leukocytes' ? '22 - 62 (48 ± 17)' : 
                      (this.patientInfo.report_type === 'Gaucher_Leukocytes' ? '4 - 13.2 (8.6 ± 2.0)' :
                      (this.patientInfo.report_type === 'Krabbe_Leukocytes' ? '5.8 - 28 (14 ± 6.4)' :
                      (this.patientInfo.report_type === 'Glycoasparginase_Leukocytes' ? '53 - 122 (89 ± 20)' :
                      '114 - 231 (176.3 ± 34.7)')))),
                    status:'Normal'
                },
                {
                   sno: 2,
                   test:'*β-Galactosidase (nmol/h/mg)',
                   disorder: 'GM1-Gangliosidosis',
                   results:'',
                   reference:'85 - 300 (142 ± 59)',
                   status:'Normal'
                }
            ]
         }
        },
          
        
 
    methods:{
     childMethod() {
        let dd = window.document.getElementsByClassName("ck-content");
        const clinical_history=dd[0]?.innerHTML || '';
        const indication = dd[1]?.innerHTML || '';
        const method = dd[2]?.innerHTML || ''; 
        const results = dd[3]?.innerHTML || ''; 
        const interpretation =dd[4]?.innerHTML || '';       
        const recommendations =dd[5].innerHTML  || '';    
        const comment=dd[6].innerHTML  || '';   
        const note =dd[7].innerHTML  || '';   

        const result = [{
            clinical_history:clinical_history,
            indication:indication,
            leukocyte_enzyme_activity:this.leukocyte_enzyme_activity,
            method:method,
            results: results,
            interpretation: interpretation, 
            recommendations:recommendations,
            comment:comment,
            note:note,
          }] 
       this.$emit('childMethod', result);
    },
    onDeleteItem(index) {
            this.leukocyte_enzyme_activity?.splice(index.sno - 1, 1)
            this.leukocyte_enzyme_activity?.forEach((data, index) => {
                data.sno = index + 1
            })
        },
    onAddItem() {
        this.leukocyte_enzyme_activity?.push({
            sno: this.leukocyte_enzyme_activity?.length + 1,
            test: '',
            disorder: '',
            results: '',
            reference: '',
            status:''
        })
    },
  }
}
</script>


<style lang="scss" scoped>
.FabryLeukocytes {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>


