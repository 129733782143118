<template>
  <section
    id="patient-file-preview"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
  >
    <div class="print-navigation-navbar">
      <ul>
        <li>
          <el-dropdown>
            <el-button class="print-btn">
              <el-icon><Printer /></el-icon>
              Print
            </el-button>
            <template #dropdown>
              <el-dropdown-menu class="print-options-btn">
                <el-dropdown-item command="true">
                  <router-link
                    :to="{
                      name: 'PatientResultFilePrintPreview',
                      params: {
                        patient_id: this.$route.params.patient_id,
                        result_file_id: this.$route.params.result_file_id,
                      },
                      query: { selectedStatus: true, isColorPrint: true },
                    }"
                    class="print-option-link"
                    target="_blank"
                  >
                    With Header And Footer
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item command="false">
                  <router-link
                    :to="{
                      name: 'PatientResultFilePrintPreview',
                      params: {
                        patient_id: this.$route.params.patient_id,
                        result_file_id: this.$route.params.result_file_id,
                      },
                      query: { selectedStatus: false, isColorPrint: true },
                    }"
                    class="print-option-link"
                    target="_blank"
                  >
                    Without Header And Footer
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item command="true">
                  <router-link
                    :to="{
                      name: 'PatientResultFilePrintPreview',
                      params: {
                        patient_id: this.$route.params.patient_id,
                        result_file_id: this.$route.params.result_file_id,
                      },
                      query: { selectedStatus: true, isColorPrint: false },
                    }"
                    class="print-option-link"
                    target="_blank"
                  >
                    Black and white With Header And Footer
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item command="false">
                  <router-link
                    :to="{
                      name: 'PatientResultFilePrintPreview',
                      params: {
                        patient_id: this.$route.params.patient_id,
                        result_file_id: this.$route.params.result_file_id,
                      },
                      query: { selectedStatus: false, isColorPrint: false },
                    }"
                    class="print-option-link"
                    target="_blank"
                  >
                    Black and White Without Header And Footer
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            getPatientResults.test_category == 'TMS'
          "
        >
          <el-button class="edit-btn mr-5" @click="editReport()">
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            getPatientResults.test_sub_category == 'DOUBLE_MARKER' &&
            isAbleToEdit
          "
        >
          <el-button class="edit-btn mr-5" @click="editDoubleMarkerReport()">
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            getPatientResults.test_sub_category == 'TWIN_DOUBLE_MARKER' &&
            isAbleToEdit
          "
        >
          <el-button
            class="edit-btn mr-5"
            @click="editTwinDoubleMarkerReport()"
          >
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            getPatientResults.test_sub_category == 'PLGF_DOUBLE_MARKER' &&
            isAbleToEdit
          "
        >
          <el-button
            class="edit-btn mr-5"
            @click="editPlgfDoubleMarkerReport()"
          >
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            getPatientResults.test_category == 'URINE_ORGANIC_ACID' &&
            isAbleToEdit
          "
        >          
          <el-button
            class="edit-btn mr-5"
            @click="editUrineOrganicAcidReport()"
          >
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            (getPatientResults.test_sub_category == 'TRIPLE_MARKER' ||
              getPatientResults.test_sub_category == 'TRIPLE_MARKERS') && 
              isAbleToEdit
          "
        >
          <el-button class="edit-btn mr-5" @click="editTripleMarkerReport()">
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            getPatientResults.test_sub_category == 'TWIN_TRIPLE_MARKER' &&
            isAbleToEdit
          "
        >
          <el-button
            class="edit-btn mr-5"
            @click="editTwinTripleMarkerReport()"
          >
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            getPatientResults.test_sub_category == 'QUADRUPLE_MARKER' &&
            isAbleToEdit
          "
        >
          <el-button class="edit-btn mr-5" @click="editQuadrableMarkerReport()">
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            getPatientResults.test_sub_category == 'TWIN_QUADRUPLE_MARKER' &&
            isAbleToEdit
          "
        >
          <el-button
            class="edit-btn mr-5"
            @click="editTwinQuadrableMarkerReport()"
          >
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            getPatientResults.test_category == 'BIO' &&
            isAbleToEdit
          "
        >
          <el-button class="edit-btn mr-5" @click="editBioReport()">
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&          
            getPatientResults.test_category == 'PENTA_MARKERS' &&
            isAbleToEdit
          "
        >
          <el-button class="edit-btn mr-5" @click="editPentaMarkersReport()">
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            getPatientResults.test_category == 'KARYOTYPING' &&
            isAbleToEdit
          "
        >
          <el-button class="edit-btn mr-5" @click="editKaryotypingReport()">
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            getPatientResults.test_category == 'SOLIDFISH' &&
            isAbleToEdit
          "
        >
          <el-button class="edit-btn mr-5" @click="editSolidFishReport()">
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>

        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            getPatientResults.test_category == 'GUANIDINO_ACETATE' &&
            isAbleToEdit
          "
        >
          <el-button class="edit-btn mr-5" @click="editPlasmaReport()">
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            (getPatientResults.report_status == 'COMPLETED' ||
              getPatientResults.report_status == 'ACTIVE') &&
            isAbleToAccessEdit &&
            getPatientResults.test_category == 'URINE_GUANIDINO_ACETATE' &&
            isAbleToEdit
          "
        >
          <el-button
            class="edit-btn mr-5"
            @click="editUrineGuanidinoAcetateReport()"
          >
            <el-icon><Edit /></el-icon>
            Edit
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            !getPatientResults.is_finalized &&
            isAbleToFinalize &&
            getPatientResults.report_status == 'COMPLETED' && getPatientResults.test_category != 'URINE_ORGANIC_ACID'
          "
        >
          <el-button class="btn approve-btn" @click="finalizeReport()">
            Finalize
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults &&
            getPatientResults.test_category == 'URINE_ORGANIC_ACID'
            && !getPatientResults.is_finalized &&
            isAbleToFinalize &&
            getPatientResults.report_status == 'COMPLETED' &&
            getPatientResults.reviewed_by
          "
        >
          <el-button class="btn approve-btn" @click="finalizeReport()">
            Finalize
          </el-button>
        </li>
        <li
          v-if="getPatientResults && 
            getPatientResults.test_category == 'URINE_ORGANIC_ACID' &&
             !isAlreadyReviewed(getPatientResults) && !getPatientResults.is_finalized
          "
        >
          <el-button class="btn approve-btn" @click="reviewReport()">
            Review
          </el-button>
        </li>
        <li
          v-if="getPatientResults && getPatientResults.regenerate_graph_status"
        >
          <el-button class="btn approve-btn" @click="regenerateGraph()">
            Regenerate
          </el-button>
        </li>
        <li
          class="d-flex align-center"
          v-if="getPatientResults && getPatientResults.is_finalized == true"
        >
          <el-button class="btn approve-btn" disabled>
            <el-icon><Check /></el-icon>
            Finalized</el-button
          >
        </li>
        <li
          v-if="
            getPatientResults && getPatientResults.report_status == 'ACTIVE'
          "
        >
          <el-button
            class="btn values-button type-success"
            type="success"
            @click="onMakeComplete()"
          >
            Mark as Complete
          </el-button>
        </li>
        <li
          v-if="
            getPatientResults && getPatientResults.report_status == 'COMPLETED'
          "
        >
          <el-dropdown
            @command="(command) => downloadProvisionalReport(command)"
          >
            <el-button class="view-icon">
              <el-icon><Download /></el-icon>
              Download
              <!-- @/assets/images/icons/download_before.svg -->
              <!-- <img
                src="@/assets/images/icons/download.svg"
                alt=""
                class="img-fluid"
              /> -->
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="includeHeader"
                  >With Header And Footer</el-dropdown-item
                >
                <el-dropdown-item command="excludeHeader"
                  >Without Header And Footer</el-dropdown-item
                >
                <el-dropdown-item command="includeHeaderNonColor"
                  >Black and White With Header And Footer</el-dropdown-item
                >
                <el-dropdown-item command="excludeHeaderNonColor"
                  >Black and White Without Header And Footer</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li>
          <el-button @click="goToReports" class="back-btn">
            <el-icon class="el-icon-back"><Back /></el-icon>
            Back
          </el-button>
        </li>
      </ul>
    </div>
    <div v-html="reportPreview"></div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import userRolesPermissionsMixin from "@/mixins/userRolesPermissionsMixin";
export default {
  mixins: [userRolesPermissionsMixin],
  data() {
    return {
      loading: false,
      result_file_id: "",
      patient_id: "",
      loadingText: "Fetching Report Preview..",
      reportPreview: "",
      downloadParams: {
        includeHeader: {
          include_header_footer: true,
          is_color_variant: true,
        },
        excludeHeader: {
          include_header_footer: false,
          is_color_variant: true,
        },
        includeHeaderNonColor: {
          include_header_footer: true,
          is_color_variant: false,
        },
        excludeHeaderNonColor: {
          include_header_footer: false,
          is_color_variant: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters("resultFiles", [
      "getPatientResultFilePreview",
      "getPatientResults",
      "getFinalizeReportStatus",
      "getPatientResultUpdateStatus",
      "getResultFileReportDownloadStatus",
      "getRegenerateGraphStatus",
      "getPatientReportReview"
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    isAbleToFinalize() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        ["ADMIN", "RADIOLOGIST", "DOCTOR"].indexOf(
          this.getAuthenticatedUser.user_type
        ) != -1
      ) {
        return true;
      }
      return false;
    },
    isAlreadyReviewed(){
      return (review) => {
        if(review && review.reviewed_by){
          return true
        }
        return false
      }
    },
    isAbleToEdit() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        ["FRONT_DESK", "HELP_DESK"].indexOf(
          this.getAuthenticatedUser.user_type
        ) != -1
      ) {
        return false;
      } else if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        ["ADMIN", "LAB_DIRECTOR","DOCTOR"].indexOf(
          this.getAuthenticatedUser.user_type
        ) == -1 &&
        this.getPatientResults &&
        this.getPatientResults.report_status &&
        this.getPatientResults.is_finalized == true
      ) {
        return false;
      }
      return true;
    },
  },
  unmounted() {
    this.toggleBodyClass("removeClass", "white-bg");
  },
  mounted() {
    this.toggleBodyClass("addClass", "white-bg");
    this.result_file_id = this.$route.params.result_file_id;
    this.patient_id = this.$route.params.patient_id;
    this.fetchPatientReportPreview();
    this.fetchSinglePatientInfo();
  },

  methods: {
    async fetchPatientReportPreview() {
      try {
        this.loading = true;

        let params = {
          result_file_id: this.result_file_id,
          patient_id: this.patient_id,
        };
        await this.$store.dispatch(
          "resultFiles/fetchPatientResultFilePreview",
          params
        );
        this.reportPreview = this.getPatientResultFilePreview;
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchSinglePatientInfo() {
      try {
        this.loading = true;
        this.loadingText = "Fetching Result values..";
        let params = {
          result_file_id: this.result_file_id,
          patient_id: this.patient_id,
        };
        await this.$store.dispatch(
          "resultFiles/fetchSinglePatientResults",
          params
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    goToReports() {
      if (this.$route.query && this.$route.query.fromRoute) {
        this.$router.push({
          name: this.$route.query.fromRoute,
          query: this.$route.query,
        });
      } else {
        this.$router.push({
          name: "PatientResultFiles",
          query: this.$route.query,
          params: {
            result_file_id: this.$route.params.result_file_id,
          },
        });
      }
    },
    async editReport() {
      try {
        this.$router.push({
          name: "ResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editDoubleMarkerReport() {
      try {
        this.$router.push({
          name: "DoubleMarkersResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editPlgfDoubleMarkerReport() {
      try {
        this.$router.push({
          name: "PlgfDoubleMarkersResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },

    async editTwinDoubleMarkerReport() {
      try {
        this.$router.push({
          name: "TwinDoubleMarkersResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editUrineOrganicAcidReport() {
      try {
        this.$router.push({
          name: "UrineOrganicAcidResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editTripleMarkerReport() {
      try {
        this.$router.push({
          name: "TripleMarkersResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editQuadrableMarkerReport() {
      try {
        this.$router.push({
          name: "QuadrableMarkersResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editBioReport() {
      try {
        this.$router.push({
          name: "BioResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editPentaMarkersReport() {
      try {
        this.$router.push({
          name: "PentaMakersResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editPlasmaReport() {
      try {
        this.$router.push({
          name: "PlasmaResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editUrineGuanidinoAcetateReport() {
      try {
        this.$router.push({
          name: "UrineGuanidinoResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editTwinQuadrableMarkerReport() {
      try {
        this.$router.push({
          name: "TwinQuadrableMarkersResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editTwinTripleMarkerReport() {
      try {
        this.$router.push({
          name: "TwinTripleMarkersResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async regenerateGraph() {
      try {
        this.loading = true;
        let params = {
          patient_id: this.getPatientResults._id,
          result_file_id: this.getPatientResults.test_result_file_id,
        };
        await this.$store.dispatch("resultFiles/regenerateGraph", params);
        if (this.getRegenerateGraphStatus) {
          successNotification(this.getRegenerateGraphStatus.message);
          await this.fetchPatientReportPreview();
          await this.fetchSinglePatientInfo();
        } else {
          errorNotification(this.getError || "Error while regenerating graph");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async finalizeReport() {
      try {
        this.loading = true;
        let params = {
          patient_id: this.getPatientResults._id,
          result_file_id: this.getPatientResults.test_result_file_id,
        };
        await this.$store.dispatch("resultFiles/finalizeReport", params);
        if (this.getFinalizeReportStatus) {
          successNotification(this.getFinalizeReportStatus.message);
          await this.fetchPatientReportPreview();
          await this.fetchSinglePatientInfo();
        } else {
          errorNotification(this.getError || "Error while finalizing");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async reviewReport(){
      try {
        this.loading = true;
        let params = {
          patientId: this.getPatientResults._id,
          resultId: this.getPatientResults.test_result_file_id,
        };
        await this.$store.dispatch("resultFiles/reviewPatientReport", params);
        if (this.getPatientReportReview) {
          successNotification("Report Reviewed Successfully");
          setTimeout(async () => {
            await this.fetchPatientReportPreview();
            await this.fetchSinglePatientInfo();
          }, 1000);

          // window.location.reload();
        } else {
          errorNotification(this.getError || "Error while reviewing");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async onMakeComplete() {
      try {
        let patient = this.getPatientResults;
        this.loading = true;
        this.loadingText = "Updating Patient Result Status...";
        let params = {
          patientId: patient._id,
          resultFileId: patient.test_result_file_id,
          params: { status: "COMPLETED" },
        };
        await this.$store.dispatch(
          "resultFiles/updatePatientReportStatus",
          params
        );
        if (this.getPatientResultUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Status Updated Successfully",
          });
          await this.fetchPatientReportPreview();
          await this.fetchSinglePatientInfo();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while updating Report",
          });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async editKaryotypingReport() {
      try {
        this.$router.push({
          name: "KaryotypingResultFileEdit",
          params: {
            patient_id: this.getPatientResults._id,
            result_file_id: this.getPatientResults.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },

    async downloadProvisionalReport(status) {
      try {
        let patient = this.getPatientResults;
        let params = {
          patientId: patient._id,
          resultFileId: patient.test_result_file_id,
        };

        if (this.downloadParams[status]) {
          params.params = { ...this.downloadParams[status] };
        }
        // params.include_header_footer = status;
        this.loading = true;
        this.loadingText = "Downloading Report...";
        await this.$store.dispatch(
          "resultFiles/downloadPatientResultFileReport",
          params
        );

        if (this.getResultFileReportDownloadStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Downloaded Successfully",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Download Failed",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
  beforeUnmount() {
    this.$store.commit("resultFiles/setPatientResults", null);
  },
};
</script>

<style lang="scss">
.print-navigation-navbar {
  max-width: 900px;
  margin: 20px auto;
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      &:not(:last-child) {
        margin-right: 15px;
      }
      .el-dropdown {
        button {
          background: #08b4b9;
          color: #fff;
          border-color: #08b4b9;
          // font-size: 16px;
          padding: 8px 15px;
          min-height: auto;
        }
      }
      .el-button {
        color: #fff;
        padding: 8px 15px;
        min-height: auto;
        .el-icon {
          margin-right: 5px;
          font-size: 16px;
        }
        &.back-btn {
          background: #2f80ed;
          border-color: #2f80ed;
        }

        &.edit-btn {
          background-color: #f94e5e;
          border-color: #f94e5e;
        }
        &.approve-btn {
          background-color: #368110;
          border-color: #368110;
          &.disabled-status {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
@media print {
  .print-navigation-navbar {
    display: none !important;
  }
}
#patient-file-preview .footer {
  height: 100% !important;
}
</style>

<style>
.white-bg {
  background: #fff !important;
}
</style>
