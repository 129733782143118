<template>
    <section
      v-loading.fullscreen.lock="loading"
      :element-loading-text="loadingText"
      id="previewReport"
    >
      <div id="add-report-view">
        <div class="inner">
          <span class="report-header"> PATIENT DEMOGRAPHICS </span>
          <div class="patient-information">
            <el-form :model="patientInfo">
              <el-row>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item
                    label="Patient Name"
                    class="mb-05 form-mandatory-field"
                  >
                    <el-input
                      v-model="patientInfo.patient_name"
                      placeholder="Enter Patient Name"
                    ></el-input>
                  </el-form-item>
                  <FormError errorName="patient_name"></FormError>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Visit Id">
                    <el-input
                      v-model="patientInfo.visit_id"
                      placeholder="Enter Visit Id"
                      disabled
                    ></el-input>
                    <FormError errorName="visit_id"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Ref. Doctor" class="mb-05 mandatory-field">
                    <el-input
                      v-model="patientInfo.ref_doctor"
                      placeholder="Enter Reference Doctor"
                    ></el-input>
                    <FormError errorName="ref_doctor"></FormError>
                  </el-form-item>
                </el-col>
  
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="UHID/MR No" class="mb-05 mandatory-field">
                    <el-input
                      v-model="patientInfo.uhid"
                      placeholder="Enter UHID No"
                    ></el-input>
                    <FormError errorName="uhid"></FormError>
                  </el-form-item>
                </el-col>
  
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Gender" class="mb-05 mandatory-field">
                    <div class="gender-select">
                      <el-select
                        v-model="patientInfo.gender"
                        placeholder="Select Gender"
                      >
                        <el-option
                          v-for="(gender, index) in genderTypes"
                          :key="index"
                          :label="gender"
                          :value="gender"
                        ></el-option>
                      </el-select>
                      <FormError errorName="gender"></FormError>
                    </div>
                  </el-form-item>
                </el-col>
  
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Age" class="mb-05 mandatory-field">
                    <el-input
                      v-model="patientInfo.age"
                      placeholder="Enter Age"
                    ></el-input>
                    <FormError errorName="age"></FormError>
                  </el-form-item>
                </el-col>
  
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Mobile" class="mb-05 mandatory-field">
                    <el-input
                      v-model="patientInfo.mobile"
                      placeholder="Enter Mobile Number"
                    ></el-input>
                    <FormError errorName="mobile"></FormError>
                  </el-form-item>
                </el-col>
  
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Phone" class="mb-05 mandatory-field">
                    <el-input
                      v-model="patientInfo.alternate_mobile"
                      placeholder="Enter Phone Number"
                    ></el-input>
                    <FormError errorName="alternate_mobile"></FormError>
                  </el-form-item>
                </el-col>
  
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Email" class="mb-05 mandatory-field">
                    <el-input
                      v-model="patientInfo.email"
                      placeholder="Enter Email"
                    ></el-input>
                    <FormError errorName="email"></FormError>
                  </el-form-item>
                </el-col>
  
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Locality" class="mb-05 mandatory-field">
                    <el-input
                      v-model="patientInfo.locality"
                      placeholder="Enter Locality"
                    ></el-input>
                    <FormError errorName="locality"></FormError>
                  </el-form-item>
                </el-col>
  
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item
                    label="Hospital Name"
                    class="mb-05 mandatory-field"
                  >
                    <el-input
                      v-model="patientInfo.hospital_name"
                      placeholder="Enter Hospital Name"
                    ></el-input>
                    <FormError errorName="hospital_name"></FormError>
                  </el-form-item>
                </el-col>
  
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item
                    label="Registration Date"
                    class="mb-05 mandatory-field"
                  >
                    <el-date-picker
                      v-model="patientInfo.registration_date"
                      type="datetime"
                      placeholder="Pick a Date"
                      format="DD-MM-YYYY hh:mm A"
                      :disabled-date="disabledDate"
                    ></el-date-picker>
                    <FormError errorName="registration_date"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item
                    label="Collection Date"
                    class="mb-05 mandatory-field"
                  >
                    <el-date-picker
                      v-model="patientInfo.collected_date"
                      type="datetime"
                      placeholder="Pick a Date"
                      format="DD-MM-YYYY hh:mm A"
                      :disabled-date="disabledDate"
                    ></el-date-picker>
                    <FormError errorName="collected_date"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item
                    label="Received Date"
                    class="mb-05 mandatory-field"
                  >
                    <el-date-picker
                      v-model="patientInfo.received_date"
                      type="datetime"
                      placeholder="Pick a Date"
                      format="DD-MM-YYYY hh:mm A"
                      :disabled-date="disabledDate"
                    ></el-date-picker>
                    <FormError errorName="received_date"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item
                    label="Reported Date"
                    class="mb-05 mandatory-field"
                  >
                    <el-date-picker
                      v-model="patientInfo.reported_date"
                      type="datetime"
                      placeholder="Pick a Date"
                      format="DD-MM-YYYY hh:mm A"
                      :disabled-date="disabledDate"
                    ></el-date-picker>
                    <FormError errorName="reported_date"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Test Name" class="mb-05 mandatory-field">
                    <el-input
                      v-model="patientInfo.test_name"
                      placeholder="Enter Test Name"
                    ></el-input>
                    <FormError errorName="test_name"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Sample Type" class="mb-05 mandatory-field">
                    <el-input
                      v-model="patientInfo.sample_type"
                      placeholder="Enter Sample Type"
                    ></el-input>
                    <FormError errorName="sample_type"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                  <el-form-item label="Client Code" class="mb-05 mandatory-field">
                    <el-input
                      v-model="patientInfo.client_code"
                      placeholder="Enter Client Code"
                    ></el-input>
                    <FormError errorName="client_code"></FormError>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <ul class="action-buttons">
              <li>
                <el-button @click="goToReports" class="preview-button"
                  >CANCEL</el-button
                >
              </li>
              <li>
                <el-button @click="syncPatientDemographics" class="submit-button"
                  >SYNC PATIENT DEMOGRAPHICS</el-button
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import moment from "moment";
  import { genderTypes } from "@/constants/formConstants";
  export default {
    data() {
      return {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
        genderTypes: genderTypes,
        loading: false,
        loadingText: "",
        patientInfo: {
          visit_id: "",
          patient_name: "",
          ref_doctor: "",
          uhid: "",
          mr_no: "",
          gender: "",
          age: "",
          hospital_name: "",
          registration_date: "",
          collected_date: "",
          reported_date: "",
          received_date: "",
          client_code: "",
          email: "",
          alternate_mobile: "",
          mobile: "",
          locality: "",
          sample_type: "",
          test_name: "",
        },
      };
    },
    computed: {
      ...mapGetters("patientReports", ["getSinglePatientReports","getPatientReportsSyncStatus"]),
      ...mapGetters("errors", ["getErrors", "getError"]),
    },
    mounted() {
      this.loadInitData();
    },
    methods: {
      async syncPatientDemographics() {
        try {
          this.loading = true;
          let params = { ...this.patientInfo };
          params.report_id = this.$route.params.patient_id;
          await this.$store.dispatch("patientReports/syncPatientDemographics", params);
          if (this.getPatientReportsSyncStatus) {
            this.$notify.success({
              title: "Success",
              message: "Sync Patient Demographics in Report successfully!",
            });
            this.goToReports();
          } else {
            this.$notify.error({
              title: "Error",
              message: this.getError
                ? this.getError
                : "Error while Sync Patient Demographics!",
            });
          }
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        } finally {
          this.loading = false;
        }
      },
      async loadInitData() {
        try {
          this.loading = true;
          this.loadingText = "Fecthing Patient Report...";
          this.patientId = this.$route.params.patient_id;
          await Promise.all([this.fetchSinglePatientInfo()]);
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        }
      },
      async fetchSinglePatientInfo() {
        try {
          await this.$store.dispatch(
            "patientReports/fetchSinglePatientReports",
            this.patientId
          );
          if (this.getSinglePatientReports) {
            this.patientInfo.patient_name =
              this.getSinglePatientReports.patient_name;
            this.patientInfo.visit_id = this.getSinglePatientReports.visit_id;
            this.patientInfo.barcode_no = this.getSinglePatientReports.barcode_no;
            this.patientInfo.age = this.getSinglePatientReports.age;
            this.patientInfo.uhid = this.getSinglePatientReports.uhid;
            this.patientInfo.registration_date = this.getSinglePatientReports
              .registration_date
              ? moment(this.getSinglePatientReports.registration_date)
                  .utc()
                  .format()
              : null;
            this.patientInfo.collected_date = this.getSinglePatientReports
              .collected_date
              ? moment(this.getSinglePatientReports.collected_date).utc().format()
              : null;
            this.patientInfo.reported_date = this.getSinglePatientReports
              .reported_date
              ? moment(this.getSinglePatientReports.reported_date).utc().format()
              : null;
            this.patientInfo.received_date = this.getSinglePatientReports
              .received_date
              ? moment(this.getSinglePatientReports.received_date).utc().format()
              : null;
            this.patientInfo.ref_doctor = this.getSinglePatientReports.ref_doctor;
            this.patientInfo.hospital_name =
              this.getSinglePatientReports.hospital_name;
            this.patientInfo.client_code =
              this.getSinglePatientReports.client_code;
            this.patientInfo.sample_type =
              this.getSinglePatientReports.sample_type;
            this.patientInfo.gender = this.getSinglePatientReports.gender;
            this.patientInfo.mobile = this.getSinglePatientReports.mobile;
            this.patientInfo.alternate_mobile =
              this.getSinglePatientReports.alternate_mobile;
            this.patientInfo.email = this.getSinglePatientReports.email;
            this.patientInfo.test_name = this.getSinglePatientReports.test_name;
          }
        } catch (err) {
          console.log(err);
        }
      },
      goToReports() {
        this.$router.push({ name: "PatientReports", query: this.$route.query });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .inner-nav-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0rem auto 1rem;
    width: 900px;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 9;
    padding: 10px 0;
    .el-button {
      padding: 9px 15px;
      min-height: auto;
      color: #e0f3f1;
      line-height: 1;
      font-weight: 400;
      &.approve-btn {
        background-color: #368110;
        border-color: #368110;
        &.disabled-status {
          opacity: 0.6;
        }
      }
      &.print-btn {
        background-color: #08b4b9;
        border-radius: #08b4b9;
      }
      &.verify-btn {
        background-color: #e78915;
        border-color: #e78915;
      }
      &.download-btn {
        background-color: #2f80ed;
        border-color: #2f80ed;
      }
      &.edit-btn {
        background-color: #f94e5e;
        border-color: #f94e5e;
      }
      &.back-btn {
        color: #333;
        background-color: white;
        border: 1px solid #47525d42;
  
        background-color: transparent;
        &:active,
        &:hover {
          border: 1px solid #47525d42;
        }
      }
    }
  }
  // @import "/src/assets/scss/components/reports.scss";
  @media print {
    .inner-nav-btn {
      display: none;
    }
  }
  </style>
  <style lang="scss">
  #previewReport {
    .each-block {
      ul {
        padding-left: 20px !important;
        li {
          list-style-type: disc !important;
        }
      }
    }
  }
  .footer-inner-last-page {
    height: 100% !important;
  }
  .verified-by {
    height: 100% !important;
  }
  .print-options-btn {
    .print-option-link {
      display: block;
      color: #000;
    }
    a.print-option-link:hover,
    a.print-option-link:focus,
    a.print-option-link:visited,
    a.print-option-link:focus-within {
      color: #000;
    }
  }
  
  .approve-type-dialog {
    .el-dialog__header {
      background: #bdbfbf;
      .el-dialog__title {
        font-weight: 500;
      }
      .el-dialog__close {
        font-size: 20px;
        color: red;
      }
    }
  }
  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 80px;
    li {
      &:not(:last-child) {
        margin-right: 20px;
      }
      .el-button {
        padding: 9px 15px;
        min-height: auto;
        line-height: 1;
        &.preview-button {
          background: #28b4a6;
          color: #fff;
          font-weight: 600;
        }
        &.print-button {
          background: #f56c6c !important;
        }
        &.submit-button {
          background: #0061ec;
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
  </style>