<template>
  <section
    id="add-report-view"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
  >
    <div class="reports-title-header">
      <h4 class="report-title">Corporate Reports - Patient Report Data</h4>
      <div class="display-end">
        <el-button @click="goToReports" class="back-btn"
          ><el-icon class="el-icon-back">
            <Back />
          </el-icon>
          Back
        </el-button>
      </div>
    </div>
    <div class="inner-navbar">
      <el-row :gutter="10">
        <!-- <el-col :span="8">
          <el-select placeholder="Select Corporate *" :clearable="true" filterable>
            <el-option></el-option>
          </el-select>
          <FormError errorName="report_type"></FormError>
        </el-col> -->
        <el-col :span="12">
          <div>
            <el-autocomplete
              v-model="searchVisitId"
              :fetch-suggestions="getAutoCompletePatientsData"
              placeholder="Search VisitID, Patient Name,UHID"
              :clearable="true"
              :trigger-on-focus="false"
              class="el-input"
              @select="handleSelect"
              @clear="clearNameSearch"
            ></el-autocomplete>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-10">
        <el-col :span="8">
          <el-select
            v-model="patientInfo.department"
            :loading="loading"
            allow-create
            placeholder="Select Department"
            :clearable="true"
            filterable
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
          <FormError errorName="department"></FormError>
        </el-col>
        <el-col :span="8">
          <el-select
            placeholder="Select Report Type *"
            allow-create
            :clearable="true"
            v-model="reportType"
            @change="onChangeReportType()"
            filterable
          >
            <el-option
              v-for="(report, index) in reportTypes"
              :key="index"
              :label="report.label"
              :value="report.value"
            ></el-option>
          </el-select>
          <FormError errorName="report_type"></FormError>
        </el-col>
        <el-col :span="8">
          <el-select
            placeholder="Select Report Template *"
            :clearable="true"
            v-model="patientInfo.report_template_id"
            @clear="askRemoveConfirmation"
            @change="updateTemplateData"
            filterable
          >
            <el-option
              v-for="(template, index) in templates"
              :key="index"
              :label="template.template_title"
              :value="template._id"
            ></el-option>
          </el-select>
          <FormError errorName="report_template_id"></FormError>
        </el-col>
      </el-row>
    </div>
    <div class="inner">
      <span class="report-header">MEDICAL SURVEILLANCE REPORT</span>
      <div class="patient-information">
        <el-form>
          <el-row>
            <el-col :xl="9" :lg="9" :md="9" :sm="9" :xs="24">
              <el-form-item label="Name" class="mb-05 form-mandatory-field">
                <el-input
                  placeholder="Enter Name"
                  v-model="patientInfo.patient_name"
                  readonly
                ></el-input>
              </el-form-item>
              <FormError errorName="patient_name"></FormError>
              <el-form-item label="Age/Gender">
                <el-input
                  placeholder="Enter Age/Gender"
                  v-model="patientInfo.age_gender"
                  readonly
                ></el-input>
                <FormError errorName="age/gender"></FormError>
              </el-form-item>
              <el-form-item label="Collected Date" class="mb-05 mandatory-field">
                <el-input
                  placeholder="Enter Date"
                  v-model="formattedDate"
                  readonly
                ></el-input>
                <FormError errorName="date"></FormError>
              </el-form-item>
              <el-form-item label="Visit ID" class="mb-05 mandatory-field">
                <el-input
                  placeholder="Enter Visit ID"
                  v-model="patientInfo.visit_id"
                  readonly
                ></el-input>
                <FormError errorName="visit_id"></FormError>
              </el-form-item>
              <el-form-item label="Barcode" class="mb-05 mandatory-field">
                <el-input
                  placeholder="Enter Barcode"
                  v-model="patientInfo.barcode_no"
                  readonly
                ></el-input>
                <FormError errorName="s.no"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="9" :lg="9" :md="9" :sm="9" :xs="24">
              <el-form-item label="UHID/MR No" class="mb-05 mandatory-field">
                <el-input
                  placeholder="Enter UHID/MR No"
                  v-model="patientInfo.uhid"
                  readonly
                ></el-input>
                <FormError errorName="uhid"></FormError>
              </el-form-item>
              <el-form-item label="Employee ID" class="mb-05 mandatory-field">
                <el-input
                  placeholder="Enter Employee ID"
                  v-model="patientInfo.employee_id"
                ></el-input>
                <FormError errorName="employee_id"></FormError>
              </el-form-item>
              <el-form-item label="Department	" class="mb-05 mandatory-field">
                <el-input
                  placeholder="Enter Department"
                  v-model="patientInfo.corporate_department"
                ></el-input>
                <FormError errorName="corporate_department"></FormError>
              </el-form-item>
              <el-form-item label="Other" class="mb-05 mandatory-field">
                <el-input
                  placeholder="Enter other"
                  v-model="patientInfo.corporate_others"
                ></el-input>
                <FormError errorName="barcode"></FormError>
              </el-form-item>
            </el-col>
            <el-col
              :xl="6"
              :lg="6"
              :md="6"
              :sm="6"
              :xs="24"
              style="
                border-left: 1px solid rgba(0, 0, 0, 0.2);
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                display: flex;
                align-items: center;
                justify-content: center;
              "
              class=""
            >
              <el-button
                v-if="
                  previewImage?.length == 0 &&
                  !isCameraOpen &&
                  this.patientInfo.patient_name
                "
                @click="toggleCamera()"
              >
                Camera
              </el-button>
              <el-button
                type="info"
                class="el-buttons submit-button"
                v-if="
                  previewImage?.length == 0 &&
                  !isCameraOpen &&
                  this.patientInfo.patient_name
                "
                @click="openFileInput"
              >
                <input
                  ref="fileInput"
                  type="file"
                  class="hidden"
                  accept="image/jpeg, image/png, image/gif"
                  @change="handleFileUpload"
                />
                Upload</el-button
              >
              <div id="app" class="web-camera-container" v-if="isCameraOpen">
                <div
                  class="camera-box"
                  :class="{ flash: isShotPhoto }"
                  v-if="isCameraOpen"
                >
                  <!-- <div class="camera-shutter" :class="{ 'flash': isShotPhoto }"></div> -->

                  <video
                    v-show="!isPhotoTaken"
                    ref="camera"
                    :width="220"
                    :height="200"
                    autoplay
                    style="object-fit: cover"
                  ></video>
                  <el-button
                    v-if="!isPhotoTaken"
                    class="clickBtn"
                    @click="takePhoto()"
                  >
                    <el-icon>
                      <camera />
                    </el-icon>
                  </el-button>
                  <!-- <el-button class="cameraBtn" @click="this.takePhoto()">
                     Camera
                </el-button> -->
                  <canvas
                    v-show="isPhotoTaken"
                    id="photoTaken"
                    ref="canvas"
                    style="object-fit: cover; width: 100%; height: 100%"
                  ></canvas>
                  <el-button
                    v-if="isPhotoTaken"
                    type="danger"
                    @click="deletecaptureImage()"
                    class="remove-icon-btn"
                    circle
                  >
                    <el-icon>
                      <Close />
                    </el-icon>
                  </el-button>
                </div>
                <!-- <p>Note: Please make sure to capture the patient information before taking a picture.</p> -->

                <!-- <div v-if="isCameraOpen" class="camera-shoot">
                        <button type="button" class="button" @click="takePhoto">
                            <i class="ri-camera-line font-size-36"></i> 
                        </button>
                    </div> -->
              </div>
              <div class="uploadProfilePic" v-if="previewImage?.length > 0">
                <div
                  v-for="(item, index) in previewImage"
                  :key="item"
                  class="img-block"
                >
                  <img alt="" :src="item" />
                  <el-button
                    type="danger"
                    @click="deleteImage(index)"
                    class="remove-btn"
                    circle
                  >
                    <el-icon>
                      <Close />
                    </el-icon>
                  </el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div>
        <div>
          <span class="report-header">PHYSICAL EXAMINATION</span>
        </div>
        <el-form>
          <el-row :gutter="10">
            <el-col :span="5" :offset="2" class="mt-10">
              <el-form-item>
                <div class="sub-title">Height (cm)</div>
                <el-input
                  type="number"
                  placeholder="Height"
                  v-model="patientInfo.height"
                  id="height"
                  @change="updateWeightStatus"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" class="mt-10">
              <el-form-item>
                <div class="sub-title">Weight (kg)</div>
                <el-input
                  type="number"
                  placeholder="Weight"
                  v-model="patientInfo.weight"
                  id="weight"
                  @change="updateWeightStatus"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" class="mt-10">
              <el-form-item>
                <div class="sub-title">BP</div>
                <el-input
                  placeholder="BP"
                  v-model="patientInfo.bp"
                  id="bp"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" class="mt-10">
              <el-form-item>
                <div class="sub-title">Pulse</div>
                <el-input
                  placeholder="Pulse"
                  type="number"
                  v-model="patientInfo.pulse"
                  id="pulse"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div>
          <span class="report-header">PERSONAL HISTORY</span>
        </div>
        <el-form>
          <el-row>
            <el-col :span="20" :offset="2" class="mt-10">
              <el-form-item>
                <div class="sub-title">Past Complaints</div>
                <el-input
                  type="textarea"
                  placeholder="Past Complaints"
                  v-model="patientInfo.past_complaints"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20" :offset="2">
              <el-form-item>
                <div class="sub-title">Present Complaints</div>
                <el-input
                  type="textarea"
                  placeholder="Present Complaints"
                  v-model="patientInfo.present_complaints"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20" :offset="2">
              <el-form-item>
                <div class="sub-title">Family History</div>
                <el-input
                  type="textarea"
                  placeholder="Family History"
                  v-model="patientInfo.family_history"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div>
          <span class="report-header">VISUAL ACUITY</span>
        </div>
        <el-row :gutter="20">
          <el-col :span="5">
            <p class="veEyeLabel">Right Eye</p>
          </el-col>
          <el-col :span="17">
            <el-form>
              <el-row class="mt-10">
                <el-form-item>
                  <el-row :gutter="5">
                    <el-col :span="6">
                      <div class="sub-title">Distance</div>
                      <el-input
                        placeholder="Distance"
                        v-model="patientInfo.right_eye_distance"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <div class="sub-title">Near</div>
                      <el-input
                        placeholder="Near"
                        v-model="patientInfo.right_eye_near"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <div class="sub-title">Colour Vision</div>
                      <el-input
                        placeholder="Colour Vision"
                        v-model="patientInfo.right_eye_colour_version"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <div class="sub-title">Corrections</div>
                      <el-input
                        placeholder="Corrections"
                        v-model="patientInfo.right_eye_corrections"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-row>
            </el-form>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="5">
            <p class="veEyeLabel">Left Eye</p>
          </el-col>
          <el-col :span="17">
            <el-form>
              <el-row class="mt-10">
                <el-form-item>
                  <el-row :gutter="5">
                    <el-col :span="6">
                      <div class="sub-title">Distance</div>
                      <el-input
                        placeholder="Distance"
                        v-model="patientInfo.left_eye_distance"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <div class="sub-title">Near</div>
                      <el-input
                        placeholder="Near"
                        v-model="patientInfo.left_eye_near"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <div class="sub-title">Colour Vision</div>
                      <el-input
                        placeholder="Colour Vision"
                        v-model="patientInfo.left_eye_colour_version"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <div class="sub-title">Corrections</div>
                      <el-input
                        placeholder="Corrections"
                        v-model="patientInfo.left_eye_corrections"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
        <div>
          <span class="report-header">SYSTEMIC EXAMINATION</span>
        </div>
        <el-form>
          <el-row class="mt-10">
            <el-form-item>
              <el-col :span="20" :offset="2">
                <div class="systematicIcons">
                  <el-image
                    style="width: 36px; height: 36px"
                    src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/skin.png"
                  ></el-image>
                  <el-input
                    placeholder="Skin"
                    v-model="patientInfo.skin"
                  ></el-input>
                </div>
              </el-col>

              <el-col :span="20" :offset="2">
                <div class="systematicIcons">
                  <el-image
                    style="width: 36px; height: 36px"
                    src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/pft.png"
                  >
                  </el-image>
                  <el-input
                    placeholder="PFT"
                    v-model="patientInfo.pft"
                  ></el-input>
                </div>
              </el-col>

              <el-col :span="20" :offset="2">
                <div class="systematicIcons">
                  <el-image
                    style="width: 36px; height: 36px"
                    src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/ecg-reading.png"
                  >
                  </el-image>
                  <el-input
                    placeholder="ECG"
                    v-model="patientInfo.ecg"
                  ></el-input>
                </div>
              </el-col>

              <el-col :span="20" :offset="2">
                <div class="systematicIcons">
                  <el-image
                    style="width: 36px; height: 36px"
                    src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/audiometry.png"
                  >
                  </el-image>
                  <el-input
                    placeholder="Audiometry"
                    v-model="patientInfo.audiometry"
                  ></el-input>
                </div>
              </el-col>

              <el-col :span="20" :offset="2">
                <div class="systematicIcons">
                  <el-image
                    style="width: 36px; height: 36px"
                    src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/x-ray.png"
                  >
                  </el-image>
                  <el-input
                    placeholder="Chest X-Ray"
                    v-model="patientInfo.chest_xray"
                  ></el-input>
                </div>
              </el-col>
            </el-form-item>
          </el-row>
        </el-form>
        <div>
          <span class="report-header">LAB INVESTIGATIONS</span>
        </div>
        <el-form>
          <el-row :gutter="9">
            <el-col :span="6" :offset="2" class="mt-10">
              <el-form-item>
                <div class="sub-title">HB</div>
                <el-input placeholder="HB" v-model="patientInfo.hb"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" class="mt-10">
              <el-form-item>
                <div class="sub-title">RBS</div>
                <el-input
                  placeholder="RBS"
                  v-model="patientInfo.rbs"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" class="mt-10">
              <el-form-item>
                <div class="sub-title">URINE</div>
                <el-input
                  placeholder="URINE"
                  v-model="patientInfo.urine"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div>
          <span class="report-header">REMARKS</span>
        </div>
        <el-form>
          <el-row>
            <el-col :span="20" :offset="2" class="mt-10">
              <el-form-item>
                <div class="sub-title">Remarks</div>
                <el-input
                  type="textarea"
                  placeholder="Remarks / Advice by Physician"
                  v-model="patientInfo.corporate_remaks"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <ul class="action-buttons">
        <!-- <li>
          <el-button @click="clearForm" class="submit-button"
            >Clear Form</el-button
          >
        </li>-->
        <li>
          <el-button class="preview-button print-button" @click="printReport()"
            >Print</el-button
          >
        </li>
        <li>
          <el-button class="preview-button" @click="previewReport()"
            >Preview</el-button
          >
        </li>       
        <li v-if="!isEditForm">
          <el-button class="submit-button" @click="savePatientInfo('PENDING')"
            >Submit</el-button
          >
        </li>
        <li v-if="!isEditForm">
          <el-button @click="savePatientInfo('DRAFT')" class="submit-button"
            >Save as Draft</el-button
          >
        </li>
        <li v-if="isEditForm && isDraft">
          <el-button @click="savePatientInfo('DRAFT')" class="submit-button"
            >Update Draft</el-button
          >
        </li>
        <li v-if="isEditForm && isDraft">
          <el-button @click="savePatientInfo('PENDING')" class="submit-button"
            >Make Complete</el-button
          >
        </li>
        <li v-if="isEditForm && !isDraft">
          <el-button
            class="submit-button"
            @click="savePatientInfo(this.patientInfo.report_status)"
            >Update</el-button
          >
        </li>
        <!-- <li v-if="!isEditForm">
          <el-button class="submit-button">Save as Draft</el-button>
        </li> -->

        <!-- <li v-if="!isEditForm">
          <el-button class="submit-button">Update Draft</el-button>
        </li> -->
        <!-- <li>
          <el-button class="submit-button">Make Complete</el-button>
        </li> -->
      </ul>
      <FormError errorName="critical_error"></FormError>
    </div>
    <ResonForChange
      v-if="takeReasonDialog"
      v-on:close="takeReasonDialog = false"
      v-on:reasonSubmit="updateReport"
    >
    </ResonForChange>
  </section>
</template>


<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { ElMessageBox } from "element-plus";
import FormError from "@/components/FormError";
import { corporateTypesMaps } from "@/constants/formConstants";
import ResonForChange from "@/components/ReasonForChange";
import axios from "@/config/axios";
export default {
  components: {
    FormError,
    ResonForChange,
  },
  computed: {
    ...mapGetters("patientReports", [
      "getPatientAddStatus",
      "getSinglePatientReports",
      "getPatientUpdateStatus",
      "getPatientDetails",
      "getDepartments",
      "getReportTypes",
    ]),
    ...mapGetters("reportTemplates", ["getAllReportTemplates"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("auth", ["getProfileDetails"]),
    isEditForm() {
      return this.$route.params && this.$route.params.patient_id ? true : false;
    },

    isDraft() {
      if (
        this.isEditForm &&
        this.patientInfo.report_status &&
        this.patientInfo.report_status != "DRAFT"
      ) {
        return false;
      }
      return true;
    },

    formattedDate() {
      if(this.patientInfo.department=="Department of Corporate"){
        const collectedDate = this.patientInfo.collected_date.replace('T', ' ');
      if (collectedDate !== null) {
        const dateParts = collectedDate.split(" ")[0].split("-");
        if (dateParts.length === 3) {
          return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        }
      }
      return ""; // Return empty string if date is null or not in expected format
    }else{
      return "";
    }
  },
    
  },
  async mounted() {
    await this.$store.dispatch("errors/clear", {}, { root: true });
    if (this.$route.params.patient_id) {
      await this.fetchSinglePatientInfo();
      await this.getVisitData();
      await this.fetchReportTemplates();
    }
    //await this.fetchReportTemplates();
    setTimeout(() => {
      this.show = true;
    });
  },
  data() {
    return {
      removedImage: "",
      previewImage: [],
      patientImage: null,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      cameraType: "environment",
      link: "#",
      searchVisitId: "",
      options: ["Department of Corporate"],
      reportTypes: corporateTypesMaps,
      corporateTypesMap: corporateTypesMaps,
      templates: [],
      takeReasonDialog: false,
      patientInfo: {
        patient_name: "",
        age: "",
        gender: "",
        mobile: "",
        email: "",
        age_gender: "",
        reported_date: "",
        received_date: "",
        collected_date: "",
        uhid: "",
        visit_id: "",
        barcode_no: "",
        height: null,
        weight: null,
        bp: null,
        pulse: null,
        past_complaints: "",
        present_complaints: "",
        family_history: "",
        weight_status: "",
        under_weight: "",
        healthy_weight: "",
        over_weight: "",
        obesity: "",
        right_eye_distance: "",
        right_eye_near: "",
        right_eye_colour_version: "",
        right_eye_corrections: "",
        left_eye_distance: "",
        left_eye_near: "",
        left_eye_colour_version: "",
        left_eye_corrections: "",
        skin: "",
        pft: "",
        ecg: "",
        audiometry: "",
        chest_xray: "",
        hb: "",
        rbs: "",
        urine: "",
        corporate_remaks: "",
        report_type: "",
        department: "Department of Corporate",
        sub_category: "",
        category: "CORPORATE",
        report_status: "DRAFT",
        typedby: "",
        employee_id: "",
        corporate_others: "",
        corporate_department: "",
        test_name: "",
        // other properties...
      },
      reportType: "",
      reasonStatus: "",
      loading: false,
      loadingText: "",
    };
  },
  methods: {
    deleteImage(index) {
      this.removedImage = null;
      this.patientImage = null;
      this.isPhotoTaken = false;
      this.previewImage.splice(index, 1);
    },
    deletecaptureImage() {
      this.removedImage = null;
      this.isCameraOpen = false;
    },
    toggleCamera() {
      this.isPhotoTaken = false;
      this.isCameraOpen = true;
      this.createCameraElement();
    },

    createCameraElement() {
      this.isLoading = true;
      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.removedImage = stream;
          this.$refs.camera.srcObject = stream;
          this.message = "Smile Please";
        })
        .catch((err) => {
          this.message = "No camera device detected";
          this.textMessage =
            "It seems like your device does not have an active camera. Please make sure your camera is properly connected or enabled.";
          this.isCameraOpen = err.length;
          this.errMessage = err;
          //alert("May the browser didn't support or there is some errors.");
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    async takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      this.isPhotoTaken = !this.isPhotoTaken;

      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");

      const camera = this.$refs.camera;

      // Get the original dimensions of the image
      const originalWidth = camera.videoWidth;
      const originalHeight = camera.videoHeight;

      // Set the canvas dimensions to match the original image
      canvas.width = originalWidth;
      canvas.height = originalHeight;

  // Draw the image onto the canvas using original dimensions
  context.drawImage(camera, 0, 0, originalWidth, originalHeight);
  const currentDate = new Date();
  const dateString = currentDate.toISOString().slice(0, 10); // Format: YYYY-MM-DD
  const timeString = currentDate.toTimeString().slice(0, 8); // Format: HH:MM:SS
  canvas.toBlob((blob) => {
    const imageBlob = new File([blob], `${this.patientInfo.patient_name}-${dateString}-${timeString}-${this.patientInfo.barcode_no}.jpg`, {
      lastModified: new Date().getTime(),
      lastModifiedDate: new Date(),
      type: "image/jpeg"
    });
    this.patientImage = imageBlob
    this.removedImage = imageBlob
   });// Draw the image onto the canvas using original dimensions
 },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },

    openFileInput() {
      // Trigger click event on file input when the button is clicked
      this.$refs.fileInput.click();
    },

    async handleFileUpload(event) {
      try {
        const file = event.target.files[0]; // Get the uploaded file
        const blob = URL.createObjectURL(file);
        this.patientImage = file;
        this.previewImage.push(blob);
        this.removedImage = blob;
      } catch (error) {
        console.error(error);
      }
    },

    isImage(file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      return allowedTypes.includes(file.type);
    },
    onChangeReportType() {
      this.reportSubCategory = this.reportType;
      this.patientInfo.report_type = this.reportType;
      this.patientInfo.report_template_id = "";
      this.patientInfo.test_name = this.corporateTypesMap[this.reportType];
      this.fetchReportTemplates();
    },

    async fetchSinglePatientInfo() {
      this.loading = true;
      this.loadingText = "Fetching data...";
      try {
        await this.$store.dispatch(
          "patientReports/fetchSinglePatientReports",
          this.$route.params.patient_id
        );
        await this.setPatientData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    async setPatientData() {
      this.patientInfo = { ...this.getSinglePatientReports };
      if (this.patientInfo.patient_imageurl == null) {
        this.previewImage = [];
      } else {
        this.removedImage = this.patientInfo.patient_imageurl;
        this.previewImage.push(this.patientInfo.patient_imageurl);
      }
      this.reportType = this.patientInfo.sub_category;
      const age = this.patientInfo.age.split(" ");
      const years = parseInt(age[0]);
      const months = parseInt(age[2]);
      const days = parseInt(age[4]);
      if ((years > 0 && months > 0 && days > 0) || (years > 0 && months > 0)) {
        this.patientInfo.age = `${years} Y ${months} M`;
      } else if (years === 0 && months > 0 && days > 0) {
        this.patientInfo.age = `${months} M ${days} D`;
      } else if (years === 0 && months === 0 && days > 0) {
        this.patientInfo.age = `${days} D`;
      } else if (years > 0) {
        this.patientInfo.age = `${years} Y`;
      }

      this.patientInfo.age_gender = `${
        this.patientInfo.age
      } / ${this.patientInfo.gender.toUpperCase()}`;
      await this.fetchPatientDepartments();
      const JavaScriptApplicants = this.departmentsData.filter((e) =>
        e.department.includes(this.patientInfo.department)
      );

      // console.log(JavaScriptApplicants);
      JavaScriptApplicants.forEach(
        (x) => (this.reportTypes = x.test_categories)
      );
      this.testId = this.getSinglePatientReports.result_test_id;
    },

    async fetchPatientDepartments() {
      this.loading = true;
      let params = {};
      if (this.patientInfo.visit_id) {
        params.visit_id = this.patientInfo.visit_id;
        params.department = "histopathology";
      }
      await this.$store.dispatch("patientReports/fetchAllDepartments", params);
      if (
        this.getDepartments &&
        this.getDepartments.data &&
        this.getDepartments.data.length
      ) {
        this.listOfDepartments = this.getDepartments.data;
      } else {
        this.listOfDepartments = [];
      }
      this.loading = false;
    },

    async getAutoCompletePatientsData(queryString, cb) {
      // eslint-disable-next-line no-debugger
      if (queryString) {
        await this.$store.dispatch("patientReports/fetchAllPatients", {
          search_string: queryString,
          get_all: true,
        });
        const patientsData = [];
        (this.getPatientDetails.data || []).forEach((el) => {
          patientsData.push({
            value: el.VisitiID + " - " + el.PatientName,
            ...el,
          });
          console.log(patientsData);
        });

        cb(patientsData || []);
        console.log(cb(patientsData || []));
      } else {
        cb([]);
      }
    },

    clearNameSearch() {
      let patientInfo = {
        patient_name: "",
        age: "",
        gender: "",
        mobile: "",
        email: "",
        age_gender: "",
        reported_date: "",
        received_date: "",
        collected_date: "",
        uhid: "",
        visit_id: "",
        barcode_no: "",
      };
      this.patientInfo = { ...this.patientInfo, ...patientInfo };
    },

    async getVisitData() {
      try {
        this.searchVisitId =
          this.patientInfo.visit_id + "-" + this.patientInfo.patient_name;
        // await this.getAutoCompletePatientsData(this.patientInfo.visit_id);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchSingleReportTemplate(templateId) {
      try {
        await this.$store.dispatch(
          "reportTemplates/fetchSingleReportTemplate",
          { template_id: templateId }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async updateTemplateData(templateId) {
      await this.fetchSingleReportTemplate(templateId);
      let templateData = this.templates.find((x) => x._id == templateId);
      if (templateData) {
        this.updateEditorData(templateData);
      }
    },

    updateEditorData(templateData) {
      this.show = false;
      this.loadingEditor = true;
      setTimeout(() => {
        this.loadingEditor = false;
        this.show = true;
        this.patientInfo.height = templateData.height || "";
        this.patientInfo.weight = templateData.weight || "";
        this.patientInfo.bp = templateData.bp || "";
        this.patientInfo.pulse = templateData.pulse || "";
        this.patientInfo.past_complaints = templateData.past_complaints || "";
        this.patientInfo.present_complaints =
          templateData.present_complaints || "";
        this.patientInfo.family_history = templateData.family_history || "";
        this.patientInfo.right_eye_distance =
          templateData.right_eye_distance || "";
        this.patientInfo.right_eye_near = templateData.right_eye_near || "";
        this.patientInfo.right_eye_colour_version =
          templateData.right_eye_colour_version || "";
        this.patientInfo.right_eye_corrections =
          templateData.right_eye_corrections || "";
        this.patientInfo.left_eye_distance =
          templateData.left_eye_distance || "";
        this.patientInfo.left_eye_near = templateData.left_eye_near || "";
        this.patientInfo.left_eye_colour_version =
          templateData.left_eye_colour_version || "";
        this.patientInfo.left_eye_corrections =
          templateData.left_eye_corrections || "";
        this.patientInfo.skin = templateData.skin || "";
        this.patientInfo.pft = templateData.pft || "";
        this.patientInfo.ecg = templateData.ecg || "";
        this.patientInfo.audiometry = templateData.audiometry || "";
        this.patientInfo.chest_xray = templateData.chest_xray || "";
        this.patientInfo.hb = templateData.hb || "";
        this.patientInfo.rbs = templateData.rbs || "";
        this.patientInfo.urine = templateData.urine || "";
        this.patientInfo.corporate_remaks = templateData.corporate_remaks || "";
        this.patientInfo.corporate_logo = templateData.corporate_logo || "";
        this.patientInfo.doctor_signature = templateData.doctor_signature || "";
        //  this.patientInfo.corporate_department = templateData.corporate_department
        // this.patientInfo.corporate_others = templateData.corporate_others
        // this.patientInfo.employee_id = templateData.employee_id
        if (this.patientInfo.height != null  && this.patientInfo.weight) {
          const height = parseFloat(this.patientInfo.height);
          const weight = parseFloat(this.patientInfo.weight);
          const heightInMeters = height / 100;
          const bmi = weight / (heightInMeters * heightInMeters);
          const Calculationbmi = bmi.toFixed(2);
          this.patientInfo.calculationbmi = Calculationbmi;
        }else{
          const value = "NaN";
          this.patientInfo.calculationbmi = parseFloat(value);

        }
      }, 100);
    },

    async askRemoveConfirmation() {
      await ElMessageBox.confirm(
        "Are you want to clear template data. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          await this.updateEditorData({});
        })
        .catch(() => {});
    },

    async fetchReportTemplates() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data..";
        let params = {
          get_all: true,
          category: "CORPORATE",
        };
        if (this.reportType) {
          const selected_category = this.corporateTypesMap.find(
            (type) => type.value === this.reportType
          );
          console.log(selected_category);
          if (selected_category) {
            params.sub_category = selected_category.value;
          }
        }

        await this.$store.dispatch(
          "reportTemplates/fetchAllReportTemplates",
          params
        );
        this.templates = this.getAllReportTemplates.data;
        console.log(this.templates);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },

    async previewReport() {
      try {
        let params = { ...this.patientInfo };

        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreview",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to preview report",
            "Alert",
            {
              confirmButtonText: "OK",
              callback: (action) => {
                // Check if the user clicked on the close button (X)
                if (action === "close" || action === "cancel") {
                  // Handle the close action here
                  console.log("Dialog closed by clicking X");
                  // You can perform additional actions if needed
                }
              },
            }
          );
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async printReport() {
      try {
        let params = { ...this.patientInfo };
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreviewPrint",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to print report",
            "Alert",
            {
              confirmButtonText: "OK",
              callback: (action) => {
                // Check if the user clicked on the close button (X)
                if (action === "close" || action === "cancel") {
                  // Handle the close action here
                  console.log("Dialog closed by clicking X");
                  // You can perform additional actions if needed
                }
              },
            }
          );
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    handleSelect(data) {
      this.patientInfo.visit_id = data.VisitiID;
      const age = data.Age.split(" ");
      const years = parseInt(age[0]);
      const months = parseInt(age[2]);
      const days = parseInt(age[4]);
      if ((years > 0 && months > 0 && days > 0) || (years > 0 && months > 0)) {
        this.patientInfo.age = `${years} Y ${months} M`;
      } else if (years === 0 && months > 0 && days > 0) {
        this.patientInfo.age = `${months} M ${days} D`;
      } else if (years === 0 && months === 0 && days > 0) {
        this.patientInfo.age = `${days} D`;
      } else if (years > 0) {
        this.patientInfo.age = `${years} Y`;
      }
      this.patientInfo.barcode_no = data.BarcodeNo;
      this.patientInfo.gender = data.Gender.toUpperCase();
      this.patientInfo.patient_name = data.PatientName;
      this.patientInfo.email = data.Email;
      this.patientInfo.mobile = data.Mobile;
      this.patientInfo.age_gender = `${
        this.patientInfo.age
      } / ${data.Gender.toUpperCase()}`;
      this.patientInfo.reported_date = data.ReportedDate
        ? moment(data.ReportedDate).utc().format()
        : null;
      this.patientInfo.received_date = data.ReceivedDate
        ? moment(data.ReceivedDate).utc().format()
        : null;
      this.patientInfo.collected_date =
        data.CollectionDate !== "0001-01-01 00:00:00"
          ? data.CollectionDate
          : null;
      // ? moment(data.CollectionDate).utc().format()
      // : null;
      if (data.test_name) {
        this.patientInfo.test_name = data.TestName;
      }
      this.patientInfo.uhid = data["UHID/MR NO"];
      // Handle the selection of a suggestion
      console.log("Selected data:", data, "hey data");
      this.patientInfo.visit_id = data.VisitiID;
      // You can perform any additional actions here
    },

    async updateReport(reason) {
      this.updatePatientInfo(reason);
    },

    askReason() {
      this.takeReasonDialog = true;
    },
    savePatientInfo(status) {
      this.reasonStatus = status;
      if (this.$route.params.patient_id && status === "APPROVED") {
        this.askReason();
        //this.updatePatientInfo();
      } else if (this.$route.params.patient_id) {
        this.updatePatientInfo();
      } else {
        this.addPatientInfo();
      }
    },

    async goToReports() {
      if (this.$route.params.patient_id) {
        this.$router.push({
          name: "PatientReportView",
          query: this.$route.query,
          params: { status: this.$route.params.status },
        });
      } else {
        this.$router.push({ name: "PatientReports", query: this.$route.query });
      }
    },

    scrollIntoFirstError() {
      var el = this.$el.getElementsByClassName("error")[0];
      if (el) {
        el.scrollIntoView({ block: "center" });
      }
    },

    async addPatientInfo() {
      try {
        const params = {
          ...this.patientInfo,
        };
        if (params.height != "" && params.weight != "") {
          const height = parseFloat(params.height);
          const weight = parseFloat(params.weight);
          const heightInMeters = height / 100;
          const bmi = weight / (heightInMeters * heightInMeters);
          const Calculationbmi = bmi.toFixed(2);
          params.calculationbmi = Calculationbmi;
        }else{
          const value = "NaN";
          params.calculationbmi = parseFloat(value);
        }
        if (this.patientImage != null) {
          const formData = new FormData();
          formData.append("file", this.patientImage);
          const response = await axios.post(
            "patient-reports/upload-pdf",
            formData,
            this.patientImage
          );
          if (response.data.success == true) {
            const logopdf = response.data.data.imageUrl;
            params.patient_imageurl = logopdf;
            //this.previewImage.push(logopdf);
          }
          // const pdf = response.data.data.imageUrl;
          // this.previewImage.push( pdf );
        }
        params.typedby = this.getProfileDetails.first_name;
        if (this.reasonStatus) {
          params.report_status = this.reasonStatus;
        }
        if (!params.report_template_id) {
          params.report_template_id = null;
        }
        params.sub_category = this.reportType;
        await this.$store.dispatch("patientReports/addPatientReport", params);
        if (this.getPatientAddStatus) {
          this.$notify.success({
            title: "Success",
            message:
              this.reasonStatus == "DRAFT"
                ? "Patient Report Drafted Successfully"
                : "Patient Report Submitted Successfully",
          });
          await this.goToReports();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Adding Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async updatePatientInfo(reason) {
      try {
        let params = { ...this.patientInfo };
        if ((params.height != null &&  params.height != "" && params.weight != null && params.weight != "")) {
          const height = parseFloat(params.height);
          const weight = parseFloat(params.weight);
          const heightInMeters = height / 100;
          const bmi = weight / (heightInMeters * heightInMeters);
          const Calculationbmi = bmi.toFixed(2);
          params.calculationbmi = Calculationbmi;
        }else{
          const value = "NaN";
          params.calculationbmi = parseFloat(value);
        }
        if (this.patientImage != null) {
          const formData = new FormData();
          formData.append("file", this.patientImage);
          const response = await axios.post(
            "patient-reports/upload-pdf",
            formData,
            this.patientImage
          );
          if (response.data.success == true) {
            const logopdf = response.data.data.imageUrl;
            params.patient_imageurl = logopdf;
          }
        }
        if (this.$route.params.patient_id && this.removedImage == null) {
          params.patient_imageurl = null;
        }
        if (this.testId) {
          params.result_test_id = this.testId;
        }
        // if (params.client_code && params.client_code != "") {
        //   const code = params.client_code.toString();
        //   params.client_code = code;
        // }
        // if (params.age == null) {
        //   params.age = "";
        // }
        // if (params.reported_date == null) {
        //   params.reported_date = "";
        // }
        // if (params.received_date == null) {
        //   params.received_date = "";
        // }
        if (this.reasonStatus) {
          params.report_status = this.reasonStatus;
        }
        // params.test_details = data;
        params.sub_category = this.reportType;
        let patientId = this.$route.params.patient_id;
        if (!params.report_template_id) {
          params.report_template_id = null;
        }
        if (reason && reason.reason) {
          params.amendment_reason = reason.reason;
        }
        if (reason && reason.is_amendment) {
          params.is_amendment = reason.is_amendment;
        }
        await this.$store.dispatch("patientReports/updatePatientReport", {
          params,
          patientId,
        });
        if (this.getPatientUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Updated Successfully",
          });
          await this.goToReports();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>



<style lang="scss">
#add-report-view {
  max-width: 900px;
  margin: 0 auto;

  .reports-title-header {
    display: grid;
    grid-template-columns: 88% auto;
    margin-bottom: 15px;

    .display-end {
      text-align: end;
    }

    .report-title {
      text-align: center;
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .inner-navbar {
    margin-bottom: 10px;

    .mt-10 {
      margin-top: 10px;
    }

    .filter-navigation {
      display: flex;
      justify-content: space-between;

      // align-items: center;
      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }

  .inner {
    margin-bottom: 50px;

    .report-header {
      margin: 0;
      color: #fff;
      font-weight: 500;
      font-size: 20px;
      background: #2eaf9f;
      text-align: center;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    border: 1px solid rgba(7, 162, 149, 0.6);
    border-radius: 10px;
    padding: 0;
    background: #fff;
    overflow: hidden;

    .patient-information {
      .el-form-item {
        margin-bottom: 0;
      }

      .form-mandatory-field {
        .el-form-item__label:after {
          content: "*";
          color: red !important;
          font-size: 15px;
          line-height: 1;
          padding-left: 3px;
        }
      }

      .el-form-item__label {
        min-width: 40%;
        text-align: left;
        background: #e0f3f1;
        padding-left: 10px;
        border-bottom: 1px solid #e0f3f1;
        font-weight: 500;
        font-size: 11px;
        color: #000;
      }

      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }

      .el-input__inner {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-left: none;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        border-radius: 0;
        color: #000;
        font-weight: 500;

        // text-transform: uppercase;
        &::placeholder {
          color: #5a5a5a;
          font-size: 13px;
          text-transform: capitalize;
        }
      }
    }

    .report-sub-title {
      color: #43454b;
      font-size: 18px;
      text-align: center;
      margin-top: -1px;
      padding-top: 1rem;
      border-top: 1.2px solid rgba(0, 0, 0, 0.2);

      font-weight: 500;
    }

    .report-type-edit-info {
      display: flex;
      margin-top: 0.5em;
      padding: 0 10px;
      gap: 5px;
    }

    .editor,
    .comments-editor {
      margin-top: 0.5em;
      padding: 10px;

      .ck-editor__editable {
        min-height: 320px;
      }
    }

    .image__caption_highlighted {
      min-height: auto !important;
    }

    .ck-dropdown__panel {
      max-height: 230px;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .ck-placeholder {
      min-height: auto !important;
    }

    .ck-dropdown__panel::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2eaf9f;
    }

    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      margin: 0;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;

      li {
        &:not(:last-child) {
          margin-right: 20px;
        }

        .el-button {
          padding: 9px 15px;
          min-height: auto;
          line-height: 1;

          &.preview-button {
            background: #28b4a6;
            color: #fff;
            font-weight: 500;
          }

          &.print-button {
            background: #f56c6c !important;
          }

          &.submit-button {
            background: #0061ec;
            color: #fff;
            font-weight: 500;
          }
        }
      }
    }
  }

  figure {
    &.table {
      br {
        display: none;
      }

      br[data-cke-filler="true"] {
        display: block !important;
      }
    }
  }

  .ck {
    ul {
      list-style: disc;
      padding-left: 30px;

      li {
        padding-left: 5px;
        list-style-type: disc !important;
      }
    }
  }

  .table-data {
    padding-top: 1em;
  }

  .el-table {
    .el-table thead {
      background-color: #28b4a6;
      font-weight: 500;
    }
  }

  // .input-error {
  //   .el-input__inner {
  //     border: 1.5px solid red !important;
  //   }
  //   ::placeholder {
  //     color: red !important;
  //     font-weight: 500;
  //   }
  // }
}
</style>

<style lang="scss">
.bio-chemistry-report-title {
  text-align: center;
  background: #e0f3f1;
  border-radius: 5px;
  margin-top: 10px !important;
  padding: 6px 0;
  font-weight: 500;
  font-size: 15px;
  color: #333;
}

.bio-chemistry-data-table {
  border: 1px solid rgba(7, 162, 149, 0.6);
  margin: 20px 10px 10px 10px;
  padding: 0 !important;

  table {
    th {
      background: #2eaf9f;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      padding: 4px 10px;
      letter-spacing: 0;
      position: relative;
      text-align: left;

      &:not(:last-child) {
        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 2px;
          bottom: 2px;
          background: rgba(255, 255, 255, 0.6);
          width: 1px;
        }
      }

      .cell {
        padding: 0;
      }
    }

    td {
      padding: 5px 10px;
      font-size: 14px;
      border-bottom: 1px solid #2eaf9f;
      font-weight: 500;
      color: #000;

      &:not(:last-child) {
        border-right: 1px solid #2eaf9f;
      }

      .cell {
        padding: 0;
      }
    }
  }
}

.systematicIcons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 10px;
}

.back-btn {
  background: #2f80ed;
  color: #fff;
  padding: 8px 15px;
  min-height: auto;
  border-color: #2f80ed;
}

.veEyeLabel {
  text-align: right;
  font-weight: 500;
  margin-top: 45px;
}

.uploadProfilePic {
  display: flex;
  /* gap: 0; */
  /* padding: 0; */
  /* margin: 0; */
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-width: 100%;
    height: 192px;
    object-fit: cover;
  }

  h4 {
    text-align: center;
    border: 2px solid #cccccc;
    border-radius: 4px;
    padding: 10px;
  }

  .upload-btn {
    padding: 10px;
  }

  .img-block {
    position: relative;
    /* background: #f3f3f3; */
    /* padding: 10px; */
    border-radius: 6px;
    width: 220px;

    .remove-btn {
      display: none;
    }

    &:hover {
      .remove-btn {
        display: block;
        cursor: pointer;
      }
    }

    .remove-btn {
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }
}

.camera-box {
  height: 200px;
  position: relative;

  .camera-shutter {
    opacity: 0;
    width: 100%;
    height: auto;
    background-color: #fff;
    position: absolute;

    &.flash {
      opacity: 1;
    }
  }
  .clickBtn {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 42px;
    height: 42px;
    border-radius: 30px;
    border: 0;
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// .camera-shoot {
// margin: 1rem 0;

// button {
//     height: 60px;
//     width: 60px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 100%;

//     img {
//         height: 35px;
//         object-fit: cover;
//     }
// }
.remove-icon-btn {
  position: absolute;
  top: 10px; /* Adjust top position as needed */
  right: 10px; /* Adjust right position as needed */
  z-index: 1; /* Ensure the button stays above the canvas */
}
.cameraBtn {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
}
</style>


