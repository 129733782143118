import axios from "axios";
import router from "@/router";
import store from "@/store";

let apiUrl = "";

if (process.env.NODE_ENV == "production") {
  apiUrl =
    process.env.VUE_APP_PROD_API_URL || process.env.VUE_APP_LOCAL_API_URL;
} else if (process.env.NODE_ENV == "local") {
  apiUrl =
    process.env.VUE_APP_INTERNAL_API_URL || process.env.VUE_APP_LOCAL_API_URL;
} else {
  apiUrl = process.env.VUE_APP_DEV_API_URL || process.env.VUE_APP_INTERNAL_API_URL;
}
// apiUrl = "http://localhost:2222/v1.0";
const $axios = axios.create({
  baseURL: apiUrl,
  timeout: 99000,
});
$axios.interceptors.request.use(
  (config) => {
    store.commit("errors/reset");
    let authDetails = store.getters["auth/getAuthenticationDetails"];
    if (authDetails && authDetails["access_token"]) {
      config.headers.common["Authorization"] = authDetails["access_token"];
    }

    if (authDetails && authDetails["user_id"]) {
      config.headers.common["userid"] = authDetails["user_id"];
    }

    if (authDetails && authDetails["password"]) {
      config.headers.common["password"] = authDetails["password"];
    }

    if (authDetails && authDetails["document_id"]) {
      config.headers.common["documentid"] = authDetails["document_id"];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

$axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let originalRequest = error.config;
    let errorStatusCode = error.response.status;

    let lastEndpoint = error.config.url.split("/").pop();
    let allowedEndpoints = [
      "signin",
      "verify-reset-password",
      "verify-email",
      "verify-password",
    ];
    if (
      errorStatusCode === 401 &&
      !originalRequest._retry &&
      allowedEndpoints.indexOf(lastEndpoint) == -1
    ) {
      originalRequest._retry = true;
      resetStore();
      if (error.config.url !== "signin") {
        router.push("/signin");
      }
    }

    return Promise.reject(error);
  }
);

const resetStore = () => {
  store.dispatch("globalReset");
};

export default $axios;
