<template>
    <section
      id="add-report-view"
      v-loading="loading"
      class="view-annexure-report"
    >
      <div v-if="getAnnexureReportPreview && showReport">
        <ul class="actionButtons">
          <li>
            <el-button @click="goToReports" class="back-button">
              <el-icon class="el-icon-back"><Back /></el-icon>Back
            </el-button>
          </li>
          <li>
            <el-button
              @click="deleteAnnexure()"
              title="REMOVE ANNEXURE"
              class="remove-button"
            >
              REMOVE
            </el-button>
          </li>
          <li>
            <el-button
              @click="updateAnnexure()"
              title="REMOVE ANNEXURE"
              class="update-button"
            >
              UPDATE
            </el-button>
          </li>
        </ul>
        <div v-if="getAnnexureReportPreview" class="annexureDataDisplay">
          <div class="inside">
            <!-- <h3>Annexure</h3> -->
            <div class="insideData">
              <div v-html="getAnnexureReportPreview"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!showReport">
        <el-form :model="annexureFormData">
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="formGroup">
                <label> Visit ID </label>
                <el-input v-model="annexureFormData.visit_id"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="formGroup">
                <label> Barcode No </label>
                <el-input v-model="annexureFormData.barcode"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <label> Patient Name </label>
              <el-input v-model="annexureFormData.patient_name"></el-input>
            </el-col>
          </el-row>
          <br />
          <el-row>
            <el-col :xl="24" :lg="24">
              <div class="ck-editor-edit-view">
                <ckeditor
                  :editor="editor"
                  tag-name="div"
                  ref="myEditor"
                  v-model="editorData"
                  :config="editorConfig"
                >
                </ckeditor>
              </div>
            </el-col>
          </el-row>
  
          <ul class="actionButtons">
            <li>
              <el-button @click="cancelPopup()" class="cancel-button"
                >Cancel</el-button
              >
            </li>
            <li>
              <el-button @click="updateAnnexureData()" class="save-button"
                >Update</el-button
              >
            </li>
          </ul>
        </el-form>
      </div>
    </section>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import CKEditor from "@ckeditor/ckeditor5-vue";
  
  import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
  
  import EditorConfig from "@/config/editorConfig";
  import { ElMessage } from "element-plus";
  export default {
    components: {
      ckeditor: CKEditor.component,
    },
  
    data() {
      return {
        loading: false,
        annexureFormData: {
          visit_id: "",
          barcode: "",
          patient_name: "",
        },
        editorData: "",
        editorConfig: EditorConfig,
        editor: ClassicEditor,
        showReport: true,
      };
    },
    computed: {
      ...mapGetters("patientReports", [
        "getAllPatientReports",
        "getReportDownloadStatus",
        "getReportDownloadUrl",
        "getReportGenerateStatus",
        "getReportApproveStatus",
        "getReportVerifyStatus",
        "getReportDispatchStatus",
        "getWhatsAppReportStatus",
        "getSmsSentStatus",
        "getEmailSentStatus",
        "getDoctorWhatsappSentStatus",
        "getDoctorEmailSentStatus",
        "getAllReports",
        "getResyncLisReport",
        "getResyncResultFileLisReport",
        "getAddAnnexureContentStatus",
        "getAnnexureReportDetails",
        "getUpdateAnnexureDetails",
        "getRemoveAnnexureStatus",
        "getSinglePatientReports",
        "getAnnexureReportPreview",
      ]),
      ...mapGetters("errors", ["getErrors", "getError"]),
    },
    async mounted() {
      await this.fetchAnnexurePreview();
      await this.fetchAnnexureReportDetails();
    },
    methods: {
      goToReports() {
        this.$router.push({ name: "PatientReports", query: this.$route.query });
      },
      updateAnnexure() {
        console.log("getAnnexureReportDetails", this.getAnnexureReportDetails);
        this.annexureFormData.patient_name =
          this.getAnnexureReportDetails.patient_name || "";
        this.annexureFormData.barcode =
          this.getAnnexureReportDetails.barcode || "";
        this.annexureFormData.visit_id =
          this.getAnnexureReportDetails.visit_id || "";
        this.editorData = this.getAnnexureReportDetails.content || "";
        this.showReport = false;
      },
      deleteAnnexure() {
        this.$confirm(
          "Are you sure want to DELETE Annexure. Continue?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(async () => {
          this.deleteAnnexureData();
        });
      },
      async deleteAnnexureData() {
        try {
          this.loading = true;
          let params = {
            annexureId: this.$route.params.annexure_id,
          };
          await this.$store.dispatch(
            "patientReports/deleteAnnexureReport",
            params
          );
          if (this.getRemoveAnnexureStatus) {
            this.$notify.success({
              title: "Success",
              message: "Annexure removed successfully",
            });
            this.cancelPopup();
          } else {
            this.$notify.error({
              title: "Error",
              message: this.getError
                ? this.getError
                : "Error while removing annexure",
            });
          }
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        } finally {
          this.loading = false;
        }
      },
      async fetchAnnexurePreview() {
        let params = {
          annexureId: this.$route.params.annexure_id,
        };
        await this.$store.dispatch(
          "patientReports/annexureReportPreview",
          params
        );
      },
      async fetchAnnexureReportDetails() {
        let params = {
          annexureId: this.$route.params.annexure_id,
        };
        await this.$store.dispatch("patientReports/fetchAnnexureDetails", params);
        if (this.getAnnexureReportDetails) {
          console.log("getAnnexureReportDetails", this.getAnnexureReportDetails);
        }
      },
  
      async updateAnnexureData() {
        try {
          this.loading = true;
          let dd = window.document.getElementsByClassName("ck-content");
          const data = dd[0]?.innerHTML;
  
          let stringContent = data?.replace(new RegExp("<[^>]*>", "g"), "");
          stringContent = stringContent?.trim();
          if (!stringContent) {
            ElMessage.warning("Report content is not given");
            return;
          }
          let params = {};
          params.annexure_id = this.$route.params.annexure_id;
          params.content = data || "";
          await this.$store.dispatch(
            "patientReports/updateAnnexureReport",
            params
          );
          if (this.getUpdateAnnexureDetails) {
            this.$notify.success({
              title: "Success",
              message: "Annexure updated successfully",
            });
            await this.fetchAnnexurePreview();
            this.showReport = true;
          } else {
            this.$notify.error({
              title: "Error",
              message: this.getError
                ? this.getError
                : "Error while updating annexure",
            });
          }
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        } finally {
          this.loading = false;
        }
      },
      cancelPopup() {
        this.$router.push({
          name: "PatientReports",
          query: this.$route.query
        });
      },
    },
  };
  </script>
  
  <style lang="scss">
  .view-annexure-report {
    .formGroup {
      label {
        font-size: 14px;
        margin-bottom: 3px;
        font-weight: 500;
        color: #333333;
      }
    }
    .ck-editor-preview {
      margin-top: 20px;
      background: #fff;
      padding: 15px;
      border-radius: 7px;
    }
    .actionButtons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
        button {
          color: #fff;
          font-weight: 500;
        }
        .remove-button {
          background: #f56c6c;
        }
        .update-button {
          background: #0061ec;
        }
        .back-button {
          background: white;
          color: var(--color);
        }
      }
    }
    .actionButtons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
        button {
          color: #fff;
          font-weight: 500;
        }
        .cancel-button {
          background: #f56c6c;
        }
        .save-button {
          background: #0061ec;
        }
      }
    }
  }
  </style>