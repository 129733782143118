<template>
  <section class="patient-report-values">
    <!-- <div>Patient Result files</div> -->
    <div class="vue-table">
      <el-table
        :data="testResults"
        v-loading="loading"
        :element-loading-text="loadingText"
      >
        <el-table-column prop="test" label="TEST" min-width="150" />
        <el-table-column
          prop="fullname"
          label="Display Value"
          min-width="200"
        />
        <el-table-column prop="value" label="VALUE" width="150">
          <template #default="{ row }">
            {{ row.value && row.value != "-" ? row.value.toFixed(2) : "" }}
          </template>
        </el-table-column>
        <el-table-column prop="lower_limit" label="LOWER LIMIT" width="150" />
        <el-table-column
          width="150"
          prop="upper_limit"
          label="UPPER LIMIT"
        ></el-table-column>
      </el-table>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("resultFiles", ["getPatientResults"]),
  },
  data() {
    return {
      dialogTableVisible: false,
      testResults: [],
      loading: false,
      loadingText: "",
    };
  },
  props: {
    patientReport: {
      required: true,
    },
  },
  async mounted() {
    await this.fetchSinglePatientInfo();
  },
  methods: {
    async fetchSinglePatientInfo() {
      try {
        this.loading = true;
        this.loadingText = "Fetching Result values..";
        let params = {
          result_file_id: this.patientReport.test_result_file_id,
          patient_id: this.patientReport._id,
        };
        await this.$store.dispatch(
          "resultFiles/fetchSinglePatientResults",
          params
        );
        if (
          this.getPatientResults &&
          this.getPatientResults.test_results &&
          this.getPatientResults.test_results.length
        ) {
          this.testResults = this.getPatientResults.test_results_summary;
        } else {
          this.testResults = [];
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
#all-patient-reports {
  tbody {
    td {
      &:last-child {
        background: #f5f5f5;
      }
    }
  }
}
.finalize-btn {
  .el-button {
    color: blue;
  }
}
.filter-navigation {
  li {
    margin-right: 10px;
    .status-filter {
      min-width: 120px;
    }
    .category-filter {
      min-width: 150px;
    }
  }
}
.patient-report-values {
  .vue-table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    th {
      font-size: 14px;
      .cell {
        line-height: 20px;
      }
    }
  }
}
</style>
