<template>
  <section
    id="template-view"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
  >
    <div class="print-navigation-navbar">
      <ul>
        <li>
          <el-button @click="goToReports" class="back-btn"
            ><el-icon class="el-icon-back"><Back /></el-icon> Back
          </el-button>
        </li>
      </ul>
    </div>
    <div v-html="getReportTemplatePreview"></div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      loadingText: "Fetching Report Preview..",
      patientInfo: "",
    };
  },
  computed: {
    ...mapGetters("patientReports", ["getPatientReportData"]),
    ...mapGetters("reportTemplates", ["getReportTemplatePreview"]),
  },
  unmounted() {
    this.toggleBodyClass("removeClass", "white-bg");
  },
  async mounted() {
    this.toggleBodyClass("addClass", "white-bg");
    this.loading = true;
    await this.$store.dispatch(
      "reportTemplates/addReportTemplatePreview",
      this.getPatientReportData
    );
    this.loading = false;
  },
  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    goToReports() {
      this.$router.push({
        name: "ReportTemplates",
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/scss/components/reports.scss";
.print-navigation-navbar {
  max-width: 900px;
  margin: 20px auto;
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      &:not(:last-child) {
        margin-right: 15px;
      }
      .el-dropdown {
        button {
          background: #08b4b9;
          color: #fff;
          border-color: #08b4b9;
          // font-size: 16px;
          padding: 8px 15px;
          min-height: auto;
        }
      }
      .back-btn {
        background: #2f80ed;
        color: #fff;
        padding: 8px 15px;
        min-height: auto;
        border-color: #2f80ed;
      }
    }
  }
}
@media print {
  .print-navigation-navbar {
    display: none !important;
  }
}
.white-bg {
  background: #fff !important;
}
</style>
<style lang="scss">
#template-view {
  margin-bottom: 40px;
  .footer {
    height: 100% !important;
  }
  .ck-content {
    p {
      margin: 5px 0 !important;
    }
    ul {
      margin: revert;
      padding: revert;
    }
    ul li {
      list-style: disc;
    }
  }
}
</style>
