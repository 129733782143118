<template>
  <section id="all-patient-reports">
    <div v-loading="loading" :element-loading-text="loadingText">
      <div class="button-group mb-20">
        <el-button @click="goBack" class="default-button back-btn"
          >Back</el-button
        >
        <el-button
          @click="editAcknowledgement"
          class="default-button edit-button"
          >Edit</el-button
        >
      </div>

      <div v-if="getSingleAcknowledgement">
        <el-row :gutter="20">
          <el-col :xl="12" :lg="12">
            <el-card shadow="never" class="mb-20 type-2">
              <template #header>
                <div class="card-header">
                  <span class="second-font">Sample Information:</span>
                </div>
              </template>
              <el-row :gutter="20">
                <el-col :xl="8" :lg="8" :md="12" :sm="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Barcode No</h4>
                    <span
                      class="value"
                      v-if="getSingleAcknowledgement.barcode_no"
                    >
                      {{
                        getSingleAcknowledgement
                          ? getSingleAcknowledgement.barcode_no
                          : "--"
                      }}
                    </span>
                    <span v-else>--</span>
                  </div>
                </el-col>
                <el-col :xl="8" :lg="8" :md="12" :sm="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Status</h4>
                    <span class="value" v-if="getSingleAcknowledgement.status">
                      {{
                        getSingleAcknowledgement
                          ? this.acknowledgmentSampleTypeMap[
                              getSingleAcknowledgement.status
                            ]
                          : "--"
                      }}
                    </span>
                    <span v-else>--</span>
                  </div>
                </el-col>
                <el-col :xl="8" :lg="8" :md="12" :sm="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Created By</h4>
                    <span
                      class="date"
                      v-if="getSingleAcknowledgement.created_by"
                    >
                      {{
                        getSingleAcknowledgement.created_by
                          ? getSingleAcknowledgement.created_by.first_name +
                            " " +
                            getSingleAcknowledgement.created_by.last_name
                          : "--"
                      }}
                    </span>
                    <span v-else>--</span>
                  </div>
                </el-col>
                <el-col :xl="8" :lg="8" :md="12" :sm="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Created At</h4>
                    <span
                      class="date"
                      v-if="getSingleAcknowledgement.created_at"
                    >
                      {{ dateTime(getSingleAcknowledgement.created_at) }}
                    </span>
                    <span v-else>--</span>
                  </div>
                </el-col>
                <el-col :xl="8" :lg="8" :md="12" :sm="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Reason(s)</h4>
                    <span
                      class="date"
                      v-if="
                        getSingleAcknowledgement.reasons &&
                        getSingleAcknowledgement.reasons.length
                      "
                    >
                      <span
                        v-for="(
                          reason, index
                        ) in getSingleAcknowledgement.reasons"
                        :key="index"
                      >
                        {{
                          index ===
                          this.getSingleAcknowledgement.reasons.length - 1
                            ? reason
                            : reason + ", "
                        }}
                      </span>
                    </span>
                    <span v-else>--</span>
                  </div>
                </el-col>
                <el-col :xl="24" :lg="24" :md="12" :sm="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Notes</h4>
                    <span
                      class="date"
                      v-if="getSingleAcknowledgement.description"
                    >
                      {{
                        getSingleAcknowledgement
                          ? getSingleAcknowledgement.description
                          : "--"
                      }}
                    </span>
                    <span v-else>--</span>
                  </div>
                </el-col>
              </el-row>

              <el-divider class="mt-10 mb-10"></el-divider>
              <el-row>
                <el-col> </el-col>
                <el-col :md="12" :sm="12">
                  <div class="input-value view-cases">
                    <h4 class="name">To Email</h4>
                    <span
                      class="value"
                      v-if="
                        this.getSingleAcknowledgement &&
                        this.getSingleAcknowledgement.email_to &&
                        this.getSingleAcknowledgement.email_to.length
                      "
                    >
                      <span
                        v-for="(email, index) in this.getSingleAcknowledgement
                          .email_to"
                        :key="index"
                      >
                        {{
                          index ===
                          this.getSingleAcknowledgement.email_to.length - 1
                            ? email
                            : email + ", "
                        }}
                      </span>
                    </span>
                    <span v-else>--</span>
                  </div>
                </el-col>

                <el-col :md="12" :sm="12">
                  <div class="input-value view-cases">
                    <h4 class="name">CC Email</h4>
                    <span
                      class="value"
                      v-if="
                        this.getSingleAcknowledgement &&
                        this.getSingleAcknowledgement.email_to_cc &&
                        this.getSingleAcknowledgement.email_to_cc.length
                      "
                    >
                      <span
                        v-for="(email, index) in this.getSingleAcknowledgement
                          .email_to_cc"
                        :key="index"
                      >
                        {{
                          index ===
                          this.getSingleAcknowledgement.email_to_cc.length - 1
                            ? email
                            : email + ", "
                        }}
                      </span>
                    </span>
                    <span v-else>--</span>
                  </div>
                </el-col>
                <el-col :md="12" :sm="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Reply To Email</h4>
                    <span
                      class="value"
                      v-if="
                        this.getSingleAcknowledgement &&
                        this.getSingleAcknowledgement.reply_to
                      "
                    >
                      {{ this.getSingleAcknowledgement.reply_to || "--" }}
                    </span>
                    <span v-else>--</span>
                  </div>
                </el-col>
              </el-row>
              <el-divider class="mt-10 mb-20"></el-divider>
            </el-card>

            <div class="commentsSection">
              <div class="comment-header">
                <h3 class="second-font">Comments</h3>
              </div>
              <div class="post-comment-box">
                <el-input
                  type="textarea"
                  placeholder="Enter Comment"
                  v-model="message"
                  rows="4"
                ></el-input>
                <el-button
                  class="default-button add-button mt-10 mb-20"
                  @click="addComment"
                  :loading="buttonLoading"
                  :disabled="hadReason"
                >
                  POST
                </el-button>
              </div>
              <div v-if="getAllAcknowledgementComments">
                <el-card
                  shadow="never"
                  class="mb-20 type-1"
                  v-for="(comment, index) in allComments"
                  :key="index"
                >
                  <div class="eachRow">
                    <div class="view-cases">
                      <p class="value">
                        {{ comment.message }}
                      </p>
                    </div>
                    <div class="comment-by">
                      <el-avatar>
                        {{ comment.created_by.first_name.charAt(0) }}
                      </el-avatar>
                      <div class="content">
                        <h4 class="commentor-name">
                          {{
                            comment.created_by
                              ? comment.created_by.first_name +
                                " " +
                                comment.created_by.last_name
                              : "--"
                          }}
                        </h4>
                        <p class="created-time">
                          {{ dateTime(comment.created_at) }}
                        </p>
                      </div>
                    </div>
                    <ul class="table-action-buttons">
                      <li>
                        <el-button
                          class="view-icon"
                          @click="updateComments(comment)"
                        >
                          <img
                            src="@/assets/images/icons/pencil.svg"
                            alt=""
                            class="img-fluid"
                          />
                        </el-button>
                      </li>
                      <li>
                        <el-button
                          class="view-icon"
                          @click="onDelete(comment._id)"
                        >
                          <img
                            src="@/assets/images/icons/trash-bin.svg"
                            alt=""
                            class="img-fluid"
                          />
                        </el-button>
                      </li>
                    </ul>
                  </div>
                </el-card>
              </div>
            </div>
          </el-col>
          <el-col :xl="12" :lg="12">
            <div class="position-sticky-top">
              <el-card shadow="never" class="mb-20 type-1">
                <template #header>
                  <div class="card-header">
                    <span class="second-font">Patient Information:</span>
                  </div>
                </template>
                <el-row :gutter="20">
                  <el-col :xl="8" :lg="8" :md="8" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Patient Name</h4>
                      <span
                        class="value"
                        v-if="
                          this.patientDetails &&
                          this.patientDetails.patient_name
                        "
                      >
                        {{
                          this.patientDetails
                            ? this.patientDetails.patient_name
                            : "--"
                        }}
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="12" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Phone Number</h4>
                      <span
                        class="value"
                        v-if="this.patientDetails && this.patientDetails.mobile"
                      >
                        {{
                          this.patientDetails
                            ? this.patientDetails.mobile
                            : "--"
                        }}
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Email</h4>
                      <span
                        class="value"
                        v-if="this.patientDetails && this.patientDetails.email"
                      >
                        {{
                          this.patientDetails ? this.patientDetails.email : "--"
                        }}
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="12" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Gender</h4>
                      <span
                        class="value"
                        v-if="this.patientDetails && this.patientDetails.gender"
                      >
                        {{
                          this.patientDetails
                            ? this.patientDetails.gender
                            : "--"
                        }}
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="12" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Patient Age</h4>
                      <span
                        class="value"
                        v-if="
                          this.patientDetails && this.patientDetails.patient_age
                        "
                      >
                        {{
                          this.patientDetails
                            ? this.patientDetails.patient_age
                            : "--"
                        }}
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="12" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Visit ID</h4>
                      <span
                        class="value"
                        v-if="
                          this.patientDetails && this.patientDetails.visit_id
                        "
                      >
                        {{
                          this.patientDetails
                            ? this.patientDetails.visit_id
                            : "--"
                        }}
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>

              <el-card shadow="never" class="mb-20 type-1">
                <template #header>
                  <div class="card-header">
                    <span class="second-font">Client Information:</span>
                  </div>
                </template>
                <el-row :gutter="20">
                  <el-col :xl="8" :lg="8" :md="12" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Client Name</h4>
                      <span
                        class="value"
                        v-if="
                          this.clientDetails && this.clientDetails.client_name
                        "
                      >
                        {{
                          this.clientDetails
                            ? this.clientDetails.client_name
                            : "--"
                        }}
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="12" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Client Email</h4>
                      <span
                        class="value"
                        v-if="
                          this.clientDetails && this.clientDetails.client_email
                        "
                      >
                        {{
                          this.clientDetails
                            ? this.clientDetails.client_email
                            : "--"
                        }}
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="12" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Client Address</h4>
                      <span
                        class="value"
                        v-if="
                          this.clientDetails &&
                          this.clientDetails.client_address
                        "
                      >
                        {{
                          this.clientDetails
                            ? this.clientDetails.client_address
                            : "--"
                        }}
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="12" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Client Phone</h4>
                      <span
                        class="value"
                        v-if="
                          this.clientDetails && this.clientDetails.client_phone
                        "
                      >
                        {{
                          this.clientDetails
                            ? this.clientDetails.client_phone
                            : "--"
                        }}
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="12" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Client Code</h4>
                      <span
                        class="value"
                        v-if="
                          this.clientDetails && this.clientDetails.client_code
                        "
                      >
                        {{
                          this.clientDetails
                            ? this.clientDetails.client_code
                            : "--"
                        }}
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="12" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">PRO Number</h4>
                      <span
                        class="value"
                        v-if="
                          this.patientDetails && this.patientDetails.pro_mobile
                        "
                      >
                        {{
                          this.patientDetails
                            ? this.patientDetails.pro_mobile
                            : "--"
                        }}
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="12" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Department</h4>
                      <span
                        class="value"
                        v-if="
                          this.getSingleAcknowledgement &&
                          this.getSingleAcknowledgement.department_name
                        "
                      >
                        {{
                          this.getSingleAcknowledgement.department_name || "--"
                        }}
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="12" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Test Name</h4>
                      <span
                        class="value"
                        v-if="
                          this.getSingleAcknowledgement &&
                          this.getSingleAcknowledgement.test_name
                        "
                      >
                        <span
                          v-for="(testName, index) in this
                            .getSingleAcknowledgement.test_name"
                          :key="index"
                        >
                          {{
                            index ===
                            this.getSingleAcknowledgement.test_name.length - 1
                              ? testName
                              : testName + ", "
                          }}
                        </span>
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="12" :sm="12">
                    <div class="input-value view-cases">
                      <h4 class="name">Centre</h4>
                      <span
                        class="value"
                        v-if="
                          this.getSingleAcknowledgement &&
                          this.getSingleAcknowledgement.lab_id
                        "
                      >
                        <span                          
                        >
                          {{                            
                            this.getSingleAcknowledgement.lab_id ? this.getSingleAcknowledgement.lab_id.name : "--" || "--"
                          }}
                        </span>
                      </span>
                      <span v-else>--</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
              <div class="grid-gap-12">
                <div>
                  <el-button
                    @click="allAcknowledgementAuditLogs"
                    class="default-button export-button"
                    >Audit Logs</el-button
                  >
                </div>
                <div>
                  <el-button
                    @click="allAcknowledgementEmailLogs"
                    class="default-button add-button"
                    >Email Logs</el-button
                  >
                </div>
                <div>
                  <el-button              
                    @click="allAcknowledgementAttachments"
                    class="default-button add-button"
                    >Attachments</el-button
                  >
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="all-patient-reports-popup">
      <div id="statusUpdateDialog">
        <el-dialog
          title="Update Comment"
          v-model="updateCommentPopup"
          class="email-notification"
          width="500px"
          align-center
        >
          <div class="dialogBody">
            <div class="email-box">
              <el-input
                type="textarea"
                placeholder="Enter Comment"
                v-model="updateMessage"
                rows="6"
              ></el-input>
            </div>
          </div>
          <div class="dialogFooter">
            <ul>
              <li>
                <el-button
                  class="default-button add-button"
                  @click="updateComment"
                  :loading="updateLoading"
                >
                  Update
                </el-button>
              </li>
            </ul>
          </div>
        </el-dialog>
      </div>
      <div id="statusUpdateDialog">
        <el-drawer
          title="Audit Logs"
          v-model="logsPopup"
          class="email-notification"
        >
          <div
            v-if="
              this.getAllAcknowledgementLogs &&
              this.getAllAcknowledgementLogs.data
            "
          >
            <el-card
              shadow="never"
              class="mb-20 type-1"
              v-for="(auditLog, index) in this.getAllAcknowledgementLogs.data"
              :key="index"
            >
              <div class="view-logs">
                <p class="name">
                  <el-avatar v-if="auditLog.created_by">
                    {{ auditLog.created_by.first_name.charAt(0) }}
                  </el-avatar>
                  <span>
                    {{
                      auditLog.created_by
                        ? auditLog.created_by.first_name +
                          " " +
                          auditLog.created_by.last_name
                        : "--"
                    }}<br />
                    <span class="created-time label mb-0">
                      {{
                        auditLog.createdAt ? dateTime(auditLog.createdAt) : "--"
                      }}
                    </span>
                  </span>
                </p>

                <p class="audit_type" style="margin-bottom: 14px">
                  <span class="label">Audit Type</span>
                  {{ auditLog.audit_type ? auditLog.audit_type : "--" }}
                </p>
                <p
                  class="audit_type mb-10"
                  v-if="
                    auditLog &&
                    auditLog.updated_fields &&
                    ((auditLog.updated_fields.old &&
                      auditLog.updated_fields.old.status) ||
                      (auditLog.updated_fields.new &&
                        auditLog.updated_fields.new.status))
                  "
                >
                  <span class="label">Change Details</span>
                  <span class="change-status">
                    <span>
                      <span class="label">OLD</span>
                      {{
                        auditLog.updated_fields.old.status
                          ? acknowledgmentSampleTypeMap[
                              auditLog.updated_fields.old.status
                            ]
                          : "--"
                      }}
                    </span>
                    <span>
                      <span class="label">NEW</span>
                      {{
                        auditLog.updated_fields.new.status
                          ? acknowledgmentSampleTypeMap[
                              auditLog.updated_fields.new.status
                            ]
                          : "--"
                      }}
                    </span>
                  </span>
                </p>
              </div>
            </el-card>
          </div>
        </el-drawer>
      </div>
      <div id="statusUpdateDialog">
        <el-drawer
          title="Email Logs"
          v-model="emailLogsPopup"
          class="email-notification"
        >
          <div
            v-if="
              this.getAcknowledgementEmailLogs &&
              this.getAcknowledgementEmailLogs.data
            "
          >
            <el-card
              shadow="never"
              class="mb-20 type-1"
              v-for="(emailLog, index) in this.getAcknowledgementEmailLogs.data"
              :key="index"
            >
              <div class="view-logs">
                <p class="created-time label">
                  {{ emailLog.createdAt ? dateTime(emailLog.createdAt) : "--" }}
                </p>
                <p class="audit_type">
                  <span class="label">ACTION</span>
                  {{ emailLog.action ? emailLog.action : "--" }}
                </p>
                <p class="audit_type">
                  <span class="label">EMAIL</span>
                  <span
                    class="value"
                    v-if="
                      emailLog && emailLog.email_to && emailLog.email_to.length
                    "
                  >
                    <span
                      v-for="(email, index) in emailLog.email_to"
                      :key="index"
                    >
                      {{
                        index === emailLog.email_to.length - 1
                          ? email
                          : email + ", "
                      }}
                    </span>
                  </span>
                  <span v-else>--</span>
                </p>
                <p class="audit_type">
                  <span class="label">CC EMAIL</span>
                  <span
                    class="value"
                    v-if="
                      emailLog && emailLog.email_to && emailLog.email_to.length
                    "
                  >
                    <span
                      v-for="(email, index) in emailLog.email_to_cc"
                      :key="index"
                    >
                      {{
                        index === emailLog.email_to_cc.length - 1
                          ? email
                          : email + ", "
                      }}
                    </span>
                  </span>
                  <span v-else>--</span>
                </p>
              </div>
            </el-card>
          </div>
        </el-drawer>
      </div>
      <div v-if="this.attachmentPopup" id="statusUpdateDialog">
          <el-dialog
            v-model="attachmentPopup"
            title="Attachments"
            class="email-notification"
            style="max-width: 500px; width: 100%;"
            align-center
          >
            <div class="dialogBody">
              <el-upload
                action
                :on-change="handleUpload"
                :auto-upload="false"
                :limit="1"
                :on-remove="handleFileRemove"
                >
                <el-button
                  class="el-button default-button add-button button-center"
                >
                  Upload</el-button
                >
              </el-upload>
              <el-divider></el-divider>
              <div>
                <ul class="attachmentsList" v-for="(attach,index) in this.attachmentsData" :key="index" >
                <li class="file-name">
                  {{ attach }}
                </li>

                <li>
                  <el-button class="view-icon" @click="downloadAttachment(attach)" title="Download Acknowledgement">
                    <img
                    src="@/assets/images/icons/download.svg"
                      alt="download"
                      width="18"
                    />
                  </el-button>
                </li>
                <li>
                  <el-button class="view-icon" @click="deleteAttachment(attach)" title="Delete Acknowledgement">
                    <img
                      src="@/assets/images/icons/trash-bin.svg"
                      alt="trash bin"
                      width="18"
                    />
                  </el-button>
                </li>
              </ul>
              </div>
            </div>
          </el-dialog>  
        </div>      
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { acknowledgmentTypeMap } from "@/constants/formConstants";
import axios from "axios";
import { errorNotification } from "@/helpers/notifications";
export default {
  middleware: "auth",
  computed: {
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("acknowledgements", [
      "getSingleAcknowledgement",
      "getAddCommentsStatus",
      "getAllAcknowledgementComments",
      "getDeleteCommentStatus",
      "getUpdateCommentStatus",
      "getAllAcknowledgementLogs",
      "getAcknowledgementEmailLogs",
      "getDeleteAttachmentStatus",
      "getDownloadAttachment",
      "getAttachmentUploadData",
      "getUpdateAttachmentsStatus"
    ]),
    hadReason() {
      if (
        this.message &&
        this.message.trim() &&
        this.message.trim().length &&
        this.message.trim().length >= 3
      ) {
        return false;
      }
      return true;
    },
    ...mapGetters("auth", ["getAllowedLabs"]),       
  },
  data() {
    return {
      reportsData: [],
      loading: false,
      loadingText: "",
      patientDetails: {},
      clientDetails: {},
      acknowledgmentSampleTypeMap: acknowledgmentTypeMap,
      commentPopup: false,
      message: "",
      buttonLoading: false,
      allComments: [],
      updateCommentPopup: false,
      commentData: {},
      updateMessage: "",
      updateLoading: false,
      logsPopup: false,
      emailLogsPopup: false,
      attachmentPopup: false,
      attachmentsData:{}
    };
  },
  async mounted() {
    this.$store.dispatch("errors/clear");
    await this.fetchAcknowledgementById();
    await this.fetchAllComments();
    await this.fetchAcknowledgementLogs();
    await this.fetchAcknowledgementEmailLogs();
  },
  methods: {   
    handleFileRemove() {
      this.uploadedFileCount -= 1;
    },
    async handleUpload(file) {
      try {
      this.uploadedFileCount += 1;
      let params = {
        file: file.name,
        file_type: file.raw.type,
      };
      await this.$store.dispatch("acknowledgements/uploadAttachment", { params, file });

      if (this.getAttachmentUploadData) {
        this.ack_proofs = [this.getAttachmentUploadData.path];
      await this.updateAckAttachment(params,file)
      } else {
        errorNotification(this.getError || "Error while uploading");
      }
      } catch(error){
        console.log(error)
      }
      
    },
    async updateAckAttachment(params,file) {
      try {
        let acknowledgement_id =  this.$route.params.id;
        await this.$store.dispatch("acknowledgements/updateAttachment",{acknowledgement_id,params,file})
        if(this.getUpdateAttachmentsStatus){
          this.$notify.success({
            title: "Success",
            message: "Attachment Uploaded Successfully",
          });
          this.attachmentPopup = false
          this.fetchAcknowledgementById()
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while Uploading Attachment",
          });
        }

      } catch(error){
        console.log(error)
      }
    },
    async downloadAttachment(attachment_path){
      try {
        let params = {
          proof:attachment_path
        }
        await this.$store.dispatch("acknowledgements/downloadAttachmentAcknowledge",params)
        if (this.getDownloadAttachment) {       
          let url = this.getDownloadAttachment.download_url
          axios({
                  url: url, //your url
                  method: 'GET',
                  responseType: 'blob', // important
              }).then((response) => {
                  // create file link in browser's memory
                  const href = URL.createObjectURL(response.data);

                  // create "a" HTML element with href to file & click
                  const link = document.createElement('a');
                  link.href = href;
                  link.setAttribute('download', `${attachment_path}`); //or any other extension
                  document.body.appendChild(link);
                  link.click();

                  // clean up "a" element & remove ObjectURL
                  document.body.removeChild(link);
                  URL.revokeObjectURL(href);
              });
          this.attachmentPopup =false
          this.fetchAcknowledgementById()
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while downloading Attachemt",
          });
        }
      } catch(error){
        console.log(error)
      }
    },
    async deleteAttachment(attachment_path) {
      this.$confirm("Are you sure want to Delete Acknowledgement?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
      }).then(async () => {
        try {
          await this.deleteAckAttachments(attachment_path);
        } catch (error) {
          console.error("Error deleting acknowledgement:", error);
        }
      }).catch((error) => {
        console.error("Confirmation error:", error);
      });
    },
    async deleteAckAttachments(attachment_path){
      try {
        let attachment_id = this.getSingleAcknowledgement._id
        let params = {
          proof:attachment_path
        }
        await this.$store.dispatch("acknowledgements/deleteAttachment",{attachment_id,params})
        if (this.getDeleteAttachmentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Attachemt deleted successfully",
          });
          this.attachmentPopup =false
          this.fetchAcknowledgementById()
        }  else {
          this.$notify.error({
            title: "Error",
            message: "Error while deleting Attachemt",
          });
        }
      } catch(error){
        console.log(error)
      }
    },
    allAcknowledgementAuditLogs() {
      this.logsPopup = true;
    },
    allAcknowledgementEmailLogs() {
      this.emailLogsPopup = true;
    },
    allAcknowledgementAttachments() {
      this.attachmentPopup = true;
    },
    async fetchAcknowledgementLogs() {
      try {
        let acknowledgement_id = this.$route.params.id;
        await this.$store.dispatch(
          "acknowledgements/fetchAllAcknowledgementsLogs",
          acknowledgement_id
        );
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAcknowledgementEmailLogs() {
      try {
        let acknowledgement_id = this.$route.params.id;
        await this.$store.dispatch(
          "acknowledgements/fetchAllAcknowledgementsEmailLogs",
          acknowledgement_id
        );
      } catch (error) {
        console.log(error);
      }
    },
    async onDelete(comment) {
      this.$confirm("Are you sure want to Delete Acknowledgement?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        await this.deleteComment(comment);
      });
    },
    async updateComment() {
      try {
        this.updateLoading = true;
        let params = {
          message: this.updateMessage,
          comment_id: this.commentData._id,
          acknowledgement_id: this.$route.params.id,
        };
        await this.$store.dispatch(
          "acknowledgements/updateAcknowledgementComment",
          params
        );
        if (this.getUpdateCommentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Comment updated successfully",
          });
          this.updateCommentPopup = false;
          this.fetchAcknowledgementById();
          this.fetchAllComments();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while updating comment",
          });
        }
        this.updateLoading = false;
      } catch (error) {
        this.updateLoading = false;
        console.log(error);
      }
    },
    async deleteComment(comment) {
      try {
        this.loading = true;
        let params = {
          comment_id: comment,
          acknowledgement_id: this.$route.params.id,
        };
        await this.$store.dispatch(
          "acknowledgements/deleteAcknowledgementComment",
          params
        );
        if (this.getDeleteCommentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Comment deleted successfully",
          });
          // this.fetchAcknowledgementById();
          // this.fetchAllComments()
          window.location.reload();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while deleting comment",
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async fetchAllComments() {
      try {
        // this.loading = true
        let acknowledgement_id = this.$route.params.id;
        await this.$store.dispatch(
          "acknowledgements/fetchAllAcknowledgementComments",
          acknowledgement_id
        );
        if (this.getAllAcknowledgementComments) {
          this.allComments = this.getAllAcknowledgementComments;
          this.allComments.sort((a, b) => {
            // Assuming 'created_at' is in ISO 8601 format (e.g., "2023-08-03T12:34:56Z")
            // If 'created_at' is in a different format, you may need to adjust the comparison logic.
            return new Date(b.created_at) - new Date(a.created_at);
          });
        }
        // this.loading = false
      } catch (error) {
        // this.loading = false
        console.log(error);
      }
    },
    addComments() {
      this.commentPopup = true;
    },
    updateComments(comment) {
      this.updateMessage = comment.message;
      this.commentData = comment;
      this.updateCommentPopup = true;
    },
    async addComment() {
      try {
        this.buttonLoading = true;
        let params = {
          message: this.message,
        };
        let acknowledgement_id = this.$route.params.id;
        await this.$store.dispatch(
          "acknowledgements/addAcknowledgementComments",
          { params, acknowledgement_id }
        );
        if (this.getAddCommentsStatus) {
          this.$notify.success({
            title: "Success",
            message: "Comments added successfully",
          });
          this.commentPopup = false;
          this.message = "";
          this.fetchAcknowledgementById();
          this.fetchAllComments();
          this.fetchAcknowledgementLogs();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while adding comments",
          });
        }
        this.buttonLoading = false;
      } catch (error) {
        this.buttonLoading = false;
        console.log(error);
      }
    },
    editAcknowledgement() {
      this.$router.push({
        name: "UpdateAcknowledgement",
        params: { id: this.$route.params.id },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    dateTime(value) {
      if (!value) return;
      return moment(value).format("DD-MM-yyyy hh:mm A");
    },
    date(value) {
      if (!value) return;
      return moment(value).format("DD-MM-yyyy");
    },
    async fetchAcknowledgementById() {
      try {
        this.loading = true;
        this.loadingText = "Fetching Acknowledgement...";
        let params = this.$route.params.id;
        await this.$store.dispatch(
          "acknowledgements/fetchAcknowledgementsById",
          params
        );
        if (
          this.getSingleAcknowledgement &&
          this.getSingleAcknowledgement.patient_details
        ) {
          this.patientDetails = this.getSingleAcknowledgement.patient_details;
        }
        if (
          this.getSingleAcknowledgement &&
          this.getSingleAcknowledgement.client_details
        ) {
          this.clientDetails = this.getSingleAcknowledgement.client_details;
        }
        if(this.getSingleAcknowledgement && this.getSingleAcknowledgement.ack_proofs && this.getSingleAcknowledgement.ack_proofs.length){
          this.attachmentsData = this.getSingleAcknowledgement.ack_proofs
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
.position-sticky-top {
  position: sticky;
  top: 20px;
}
#all-patient-reports {
  tbody {
    td {
      &:last-child {
        background: #f5f5f5;
      }
    }
  }
  .grid-gap-12 {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
  .button-group {
    display: flex;
  }
  .default-button {
    padding: 10px 15px;
    min-height: auto;
    height: 100%;
    line-height: 1;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 500;
    min-width: 100px;
    border: none;
    box-shadow: 0 0 5px 0 #efefef;
    &:hover {
      background-color: #ffffff;
      color: #333436;
    }
    &.back-btn {
      color: #fff;
      background-color: #2f80ed;
      border: #2f80ed;
      &:active,
      &:hover,
      &:focus {
        border: 1px solid #2f80ed;
      }
    }
    &.export-button {
      background-color: #28b4a6;
      color: #fff;
      border: none;
    }
    &.edit-button {
      background-color: #f12424;
      color: #ffffff;
    }
    &.add-button {
      background-color: #0061ec;
      color: #fff;
    }
  }
  .input-value {
    margin-bottom: 1rem;
    .name {
      color: #778899;
      font-size: 0.935rem;
      font-weight: 400;
    }
  }
  .sub-title {
    font-weight: 400;
    letter-spacing: 0;
    font-size: 1.125rem;
  }
  .el-card.type-1 {
    .el-card__header {
      background-color: #219388;
      color: #ffffff;
      font-size: 1.1rem;
      font-weight: 500;
      line-height: 1;
      padding-block: 0.65em;
      text-transform: uppercase;
    }
  }

  .el-card.type-2 {
    .el-card__header {
      background-color: #00445e;
      color: #ffffff;
      font-size: 1.1rem;
      font-weight: 500;
      line-height: 1;
      padding-block: 0.65em;
      text-transform: uppercase;
    }
  }
  .el-divider {
    margin-inline: -20px;
    width: calc(100% + 40px);
  }
}
.email-notification {
  .el-drawer__header {
    background-color: #28b4a6;
    margin-bottom: 0;
    color: #ffffff;
    padding-bottom: 15px;
    .el-drawer__title {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 1.1rem;
      line-height: 1;
      text-transform: uppercase;
    }
    .el-drawer__close {
      &:hover {
        color: #000000;
      }
    }
  }
  .el-drawer__body {
    background-color: #f1f5f7;
    .label {
      font-size: 12px;
      text-transform: uppercase;
      color: #5f6b77;
      display: block;
    }
    .created-time {
      margin: 0 0 5px;
    }
    .barcode_no {
      margin: 0;
      span {
        font-weight: 500;
        color: #0061ec;
      }
    }
    .audit_type {
      .label {
        margin: 0;
      }
    }
    .name {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 0;
    }
  }
}
.filter-navigation {
  li {
    margin-right: 10px;
    .status-filter {
      min-width: 120px;
    }
    .category-filter {
      min-width: 150px;
    }
  }
}
.audit-action-buttons {
  display: flex;
  flex-wrap: wrap;
  li {
    margin: 5px;
  }
}
// popup styles
.all-patient-reports-popup {
  .el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      font-size: 20px;
      padding: 10px 10px 5px;
      background: #2eaf9f;
      border-radius: 10px 10px 0 0;
      margin-right: 0;
      .el-dialog__title {
        color: #ffffff;
        font-weight: 500;
      }
    }
    .el-dialog__headerbtn {
      border: 1px solid #dcdce4;
      border-radius: 3px;
      padding: 10px;
      height: auto;
      width: auto;
      top: 5px;
      right: 10px;
      border: none;
      .el-dialog__close {
        color: #ffffff;
        font-weight: 700;
      }
    }
    .el-dialog__body {
      padding: 0;

      .input-d-flex {
        display: grid;
        grid-template-columns: 125px auto;
        align-items: center;
        margin: 10px 0;
        .name {
          color: #000000;
          font-size: 16px;
          margin: 0;
          font-weight: 500;
        }
        .value {
          font-weight: 500;
          color: #219388;
        }
        .el-input__inner {
          width: 80%;
        }
      }
      .dialogBody {
        padding: 25px 20px;
        input {
          min-height: 40px;
        }
      }
      .dialogFooter {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding: 15px 20px;
        text-align: right;
      }
    }
    .dialog-footer {
      .submit-btn {
        background: #0061ec;
        color: #fff;
        font-weight: 500;
        border: none;
      }
    }

    .err {
      color: red;
      white-space: nowrap;
    }
  }
}
.department-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  min-width: 140px;
  width: 185px;
  padding: 6px;
  color: #00445e;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  background: #f2f2f2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
#statusUpdateDialog {
  @media (max-width: 768px) {
    .el-drawer {
      width: 90% !important;
    }
  }
}
.commentsSection {
  margin-top: 1rem;
  p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.3;
  }
  .comment-header {
    display: flex;
    justify-content: space-between;
    margin-block-end: 0.75rem;
    align-items: center;
    h3 {
      font-size: 1.25rem;
      line-height: 1;
      margin: 0;
    }
  }
  .post-comment-box {
    text-align: right;
  }
  .el-card {
    border-radius: 3px 10px 10px 3px;
    border-left: 2px solid #a5a5a5;
  }
  .eachRow {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .comment-by {
      display: grid;
      grid-template-columns: 40px auto;
      gap: 10px;
      margin-top: 0.75rem;
      .commentor-name {
        font-weight: 400;
      }
      .created-time {
        color: #778899;
        font-size: 0.85rem;
      }
    }
    .table-action-buttons {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      bottom: 5px;
      right: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      button {
        width: 25px;
        height: 25px;
        img {
          width: 12px;
        }
      }
    }
    &:hover .table-action-buttons {
      visibility: visible;
      opacity: 1;
    }
  }
}
.change-status {
  display: flex;
  gap: 90px;
}
.attachmentsList {
    display : grid;
    grid-template-columns: 325px auto auto;
    align-items: center;
    margin-bottom: 0.5rem;
    column-gap: 10px;
    .el-button {
      margin-left: 5px;
    }
    .file-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      row-gap: 10px;
    }
  }
  .dialogBody { 
    .button-center {
      display: block;
      margin: 0 auto;
      width: 200px;
    }
  }
</style>