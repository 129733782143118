import { ElMessage as Message } from 'element-plus';

/**
 * success message
 * @param {String} message
 */
export function successMessage(message) {
  Message.success(message)
}

/**
 * error message
 * @param {String} message
 */
export function errorMessage(message) {
  Message.success(message)

}

/**
 * warning message
 * @param {String} message
 */
export function warningMessage(message) {
  Message.success(message)

}

/**
 * info message
 * @param {String} message
 */
export function infoMessage(message) {
  Message.success(message)
}

