<template>
    <div class="AcidLipaseLeukocytes">
        <div class="edit-box mb-20">
            <span class="title">CLINICAL HISTORY</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Clinical_history" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INDICATION</span>
            <ckeditor :editor="editor" tag-name="div" v-model="indication" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">LEUKOCYTE ENZYME ACTIVITY</span>
            <el-table :data="Leukocyte_Enzyme_Activity" style="width: 100%">
                <el-table-column prop="Test" label="Test (Units)" width="270">
                    <template v-slot="scope">
                        <el-input type="textarea"  autosize v-model="scope.row.Test" placeholder="Enter Test (Units)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="Disorder" width="180">
                    <template v-slot="scope">
                        <el-input type="textarea" autosize v-model="scope.row.Disorder" placeholder="Enter Disorder"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="Result" width="140">
                    <template v-slot="scope">
                        <el-input type="textarea" autosize v-model="scope.row.Result" placeholder="Enter Result"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="Reference_interval" label="Reference Interval(Mean ± SD)" width="230">
                    <template v-slot="scope">
                        <el-input type="textarea" autosize v-model="scope.row.Reference_interval"  placeholder="Enter Reference Interval(Mean ± SD)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="Status" label="Status" width="150">
                    <template v-slot="scope">
                        <el-select placeholder="Status" :clearable="true" :filterable="true" v-model="scope.row.Status">
                            <el-option label="Normal" value="Normal"></el-option>
                            <el-option label="Deficient" value="Deficient"></el-option>
                            <el-option label="Low" value="Low"></el-option>
                            <el-option label="Elevated" value="Elevated"></el-option> 
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope"
                    v-if="this.patientInfo?.from == undefined">
                    <el-row :gutter="12">
                        <el-col :span="6" v-if="scope.row.sno === Leukocyte_Enzyme_Activity?.length">
                            <el-buttton @click="onAddItem" class="c-pointer"><el-icon :size="20">
                                    <CirclePlus />
                                </el-icon></el-buttton>
                        </el-col>
                        <el-col :span="6" v-if="Leukocyte_Enzyme_Activity?.length > 1"><el-buttton
                                @click="onDeleteItem(scope.row)"><el-icon :size="19">
                                    <Delete style="color: red;" />
                                </el-icon></el-buttton>
                        </el-col>
                    </el-row>

                </el-table-column>
            </el-table>

        </div>
        <div class="edit-box mb-20">
            <span class="title">METHOD</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Method" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RESULT</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Result" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INTERPRETATION</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Interpretation" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RECOMMENDATION</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Recommendations" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">COMMENT</span>
            <ckeditor :editor="editor" tag-name="div" v-model="comment" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <ckeditor :editor="editor" tag-name="div" v-model='Note' ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";

export default {
    components: {
        ckeditor: CKEditor.component,
    },
    props: {
        acidLipaseLeukocytes: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            editor: ClassicEditor,
            editorData: '',
            show: false,
            impression: "",
            resultData: "",
            references: "",
            remarks: "",
            editorConfig: EditorConfig,
            textarea: "",
            comment:this.acidLipaseLeukocytes?.length == 1 ? this.acidLipaseLeukocytes[0].comment : "",
            recommendations:this.acidLipaseLeukocytes?.length == 1 ? this.acidLipaseLeukocytes[0].recommendations : "",
            indication:this.acidLipaseLeukocytes?.length == 1 ? this.acidLipaseLeukocytes[0].indication : "",
            Note: this.acidLipaseLeukocytes?.length == 1 && this.acidLipaseLeukocytes[0].Note ? this.acidLipaseLeukocytes[0].Note :
            "It is presumed that the specimen used to perform the test belongs to the patient specified above, such verification having been carried out at the collection level of sample. Please correlate with clinical findings and other test findings.",
            Clinical_history: this.acidLipaseLeukocytes?.length == 1 ? this.acidLipaseLeukocytes[0].Clinical_history : "",
            Interpretation: this.acidLipaseLeukocytes?.length == 1 ? this.acidLipaseLeukocytes[0].Interpretation : "Normal activity of acid lipase in leukocytes may rule out the possibility of Wolman disease.",
            Method: this.acidLipaseLeukocytes?.length == 1 ? this.acidLipaseLeukocytes[0].Method : "Artificial Fluorogenic substrates.",
            Leukocyte_Enzyme_Activity: [
                {
                    sno: 1,
                    Test: 'Acid lipase (nmol/30min/mg)',
                    Disorder: 'Wolman / CESD',
                    Result: '',
                    Reference_interval: '125 – 480 (268 ± 15)',
                    Status: 'Normal',
                },
                {
                    sno: 2,
                    Test: '* β-Galactosidase (nmol/h/mg)',
                    Disorder: 'GM1-Gangliosidosis',
                    Result: '',
                    Reference_interval: '85 – 300 (142 ± 59)',
                    Status: 'Normal',
                },
            ],
            Result: this.acidLipaseLeukocytes?.length == 1 ? this.acidLipaseLeukocytes[0].Result : "A normal activity of acid lipase is obtained in peripheral blood leukocytes.",
            Recommendations: this.acidLipaseLeukocytes?.length == 1 ? this.acidLipaseLeukocytes[0].Recommendations : "",
            signature_required: (this.patientInfo && this.patientInfo.is_required_signature) ? this.patientInfo.is_required_signature : false
        }
    },
    computed: {

    },
    mounted() {
        // this.prepareTemplateData();
        this.Leukocyte_Enzyme_Activity=this.acidLipaseLeukocytes && this.acidLipaseLeukocytes[0].Leukocyte_Enzyme_Activity.length > 0?this.acidLipaseLeukocytes[0].Leukocyte_Enzyme_Activity:this.Leukocyte_Enzyme_Activity
    },
    methods: {
        sendAcidLILeucocytes() {
            let dd = window.document.getElementsByClassName("ck-content");
            const Clinical_history = dd[0]?.innerHTML;
            const indication = dd[1]?.innerHTML;
            const Method = dd[2]?.innerHTML;
            const Result = dd[3]?.innerHTML;
            const Interpretation = dd[4]?.innerHTML;
            const Recommendations = dd[5]?.innerHTML;
            const comment = dd[6]?.innerHTML;
            const Note = dd[7]?.innerHTML;
            this.Leukocyte_Enzyme_Activity = this.Leukocyte_Enzyme_Activity.filter(detail =>detail.Test !== '' || detail.Disorder !== '' || detail.Result !== '' || detail.Reference_interval !== '' || detail.Status !== '');  
            console.log(this.Leukocyte_Enzyme_Activity)         
             const result = [{
                Clinical_history: Clinical_history,
                indication: indication,
                Leukocyte_Enzyme_Activity: this.Leukocyte_Enzyme_Activity,
                Method: Method,
                Result: Result,
                Interpretation: Interpretation,
                Recommendations: Recommendations,
                signature_required: this.signature_required,
                Note:Note,
                comment:comment
            }]
            return this.$emit('sendAcidLILeucocytes', result);
        },
        onDeleteItem(index) {
            this.Leukocyte_Enzyme_Activity?.splice(index.sno - 1, 1)
            this.Leukocyte_Enzyme_Activity?.forEach((data, index) => {
                data.sno = index + 1
            })
        },
        onAddItem() {
            this.Leukocyte_Enzyme_Activity?.push({
                sno: this.Leukocyte_Enzyme_Activity?.length + 1,
                Test: '',
                Disorder: '',
                Reference_interval: '',
                Result: '',
                Status: ''
            })
        },
    }
}
</script>


<style lang="scss" scoped>
.AcidLipaseLeukocytes {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>