<template>
  <div class="vue-default-dialog">
    <el-dialog
      width="30%"
      align-center
      v-model="visible"
      @close="close"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div class="dialog-header">
        <span class="header-part">
          {{ displayTitle }}
        </span>
      </div>
      <div class="model-body">
        <el-form ref="ruleForm" :model="formData">
          <el-form-item>
            <el-checkbox v-model="formData.is_amendment" label="Amendment" />
          </el-form-item>
          <el-form-item prop="reason" v-if="this.formData.is_amendment">
            <el-input
              type="textarea"
              v-model="formData.reason"
              rows="3"
              placeholder="Enter reason"
            />
            <p><b>Note :</b> Minimum 3 characters is required</p>
          </el-form-item>
        </el-form>
      </div>
      <div class="model-footer">
        <ul>
          <li>
            <el-button
              class="cancel-button"
              
              @click="cancel()"
              >CANCEL</el-button
            >
          </li>
          <!-- :disabled="formData.is_amendment" -->
          <li>
            <el-button
              class="submit-button"
              :disabled="!formData.is_amendment || checkReason"
              @click="submit('ruleForm')"
              >SUBMIT</el-button
            >
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
    },
    width: {
      default: 30,
    },
  },
  data() {
    return {
      displayTitle: this.title ? this.title : "Reason for amendment",
      visible: true,
      currentWidth: "30%",
      formData: {
        reason: "",
        is_amendment: false,
      },
      rules: {
        reason: [
          {
            required: true,
            message: "Invalid  reason",
            pattern: /^.\S/,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    if (this.width) {
      this.currentWidth = this.width + "%";
    }
  },
  computed: {
    checkReason() {
      if (this.formData.is_amendment) {
        return !(
          this.formData.reason && this.formData.reason.trim().length >= 3
        );
      }
      return false;
    },
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.visible = false;

          this.$emit("reasonSubmit", this.formData);
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.visible = false;
      // this.$emit("reasonSubmit", this.formData);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.vue-default-dialog {
  .el-dialog {
    height: initial !important;
    margin: auto !important;
  }
  .dialog-header,
  .el-dialog__header {
    background: #2eaf9f;
    padding: 10px 20px;
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    margin: 0;
    span {
      color: #ffffff;
      font-weight: 500;
      font-size: 1rem;
    }
    button {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 15px;
      right: 10px;

      svg {
        color: #fff;
        width: 30px;
        height: 30px;
        font-weight: 700;
      }
    }
  }
  .el-dialog__header {
    padding: 0;
    display: block !important;
  }
  .el-dialog__body {
    padding: 0;
  }
  .model-body {
    padding: 10px 20px;
  }
  .model-footer {
    background: #f5f5f5;
    padding: 10px 20px;
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
        .cancel-button {
          background: #2f80ed;
          color: #ffffff;
        }
        .submit-button {
          background: #2eaf9f;
          color: #fff;
        }
      }
    }
  }
}
</style>
