<template>
    <div class="SmearForMalignantCells">
        <div class="edit-box mb-20">
            <span class="title">CYTOLOGY TYPE</span>
            <el-input placeholder="Please enter Cytology Type"  v-model="Fluid_Cytology"/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">LAB. REF. NO.</span>
            <el-input placeholder="Please enter LAB. REF. NO"  v-model="Lab_Refno" type="textarea" autosize/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">CLINICAL DETAILS</span>
            <el-input placeholder="Please enter Clinical Details"  v-model="clinical_details" type="textarea" autosize />
        </div>
        <!-- <div class="edit-box mb-20">
            <span class="title">STAINS</span>
            <el-input placeholder="Please enter Stains"  v-model="stains" type="textarea" autosize/>
        </div> -->
         <div class="edit-box mb-20">
            <span class="title">MICROSCOPY</span>
            <el-input placeholder="Please enter Microscopy"  v-model="cytopathology_report" type="textarea" autosize/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">IMPRESSION</span>
            <el-input placeholder="Please enter Impression"  v-model="impressions" type="textarea" autosize/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <el-input placeholder="Please enter Note"  v-model="Note" type="textarea" autosize/>
        </div>
        <el-row :gutter="10" style="margin-top: 15px;">
            <el-col :span="6">
              <p class="dropdown">AIR DRIED</p>
              <el-select placeholder="Please Select Air Dried" :clearable="true" :filterable="true" v-model="air_Drein">
                <el-option  v-for="item in numbersList" :key="item" :value="item"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <p class="dropdown">FIXED</p>
              <el-select placeholder="Please Select Fixed" :clearable="true" :filterable="true" v-model="fixed">
                <el-option  v-for="item in numbersList" :key="item" :value="item"></el-option>
              </el-select>
            </el-col>
          </el-row>
   </div>
</template>

<script>


export default{
    props: {
      smearForMalignantCells: {
      type: Array,
      required: true
    }
   },
    data(){
        return{
            editorData: '',
            show: false,
            comments: "",
            recommendations: "",
            impression: "",
            resultData: "",
            references: "",
            remarks: "",
            textarea: "",
            Fluid_Cytology:this.smearForMalignantCells?.length ==1?this.smearForMalignantCells[0].Fluid_Cytology:"",
            Lab_Refno:this.smearForMalignantCells?.length==1  ?this.smearForMalignantCells[0].Lab_Refno:"",
            clinical_details:this.smearForMalignantCells?.length==1  ?this.smearForMalignantCells[0].clinical_details:"",
            // stains:this.smearForMalignantCells?.length==1  ?this.smearForMalignantCells[0].stains:"",
            cytopathology_report:this.smearForMalignantCells?.length==1  ?this.smearForMalignantCells[0].cytopathology_report:"",
            Note:this.smearForMalignantCells?.length==1  ?this.smearForMalignantCells[0].Note:"",
            air_Drein:this.smearForMalignantCells?.length==1?this.smearForMalignantCells[0].air_Drein:'',
            fixed:this.smearForMalignantCells?.length==1?this.smearForMalignantCells[0].fixed:'',
            impressions:this.smearForMalignantCells?.length==1?this.smearForMalignantCells[0].impressions:'',
            numbersList:[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
            signature_required:(this.patientInfo && this.patientInfo.is_required_signature) ? this.patientInfo.is_required_signature : false, 
        }        
    },
    methods: {
        handleSmearForMalignantCells() {
   const result = [{
           Fluid_Cytology: this.Fluid_Cytology,           
           Lab_Refno:this.Lab_Refno,
           clinical_details: this.clinical_details,
           //  stains: this.stains,  
           cytopathology_report: this.cytopathology_report,
           signature_required: this.signature_required,
           Note:this.Note,
           air_Drein:this.air_Drein,
           fixed:this.fixed,
           impressions : this.impressions,
          }]
        return this.$emit('sendSmearForMalignantCells', result);
    }
  },
}
</script>


<style lang="scss" scoped>
.SmearForMalignantCells {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
    .dropdown{
        color: #2eaf9f;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 13.28px;
        margin-top: 5px;
    }
}
</style>