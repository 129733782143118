import axios from "@/config/axios"
import * as PrivateAxios from "axios"

// initial state
const initialState = () => {
  return {
    allResultFiles: null,
    fileUploadStatus: null,
    fileProcessStatus: null,
    allPatientResultFiles: null,
    patientResultFilePreview: null,
    patientResults: null,
    finalizeReportStatus: null,
    patientReportUpdateStatus: null,
    patientResultPreviewData: null,
    resultsPreviewData: null,
    fileGenerateStatus: null,
    patientReportDownloadStatus: false,
    reportDownloadData: null,
    resultFileReportDownloadStatus: false,
    regenerateGraphStatus: false,
    readyToApproveReportStatus: false,
    imageUploadDetails: null,
    attachGraphDataStatus: null,
    resultsAttachStatus: null,
    patientReportReview: false,
  }
}

const state = initialState()

const getters = {
  getAllResultFiles: (state) => state.allResultFiles,
  getFileUploadStatus: (state) => state.fileUploadStatus,
  getFileProcessStatus: (state) => state.fileProcessStatus,
  getAllPatientResultFiles: (state) => state.allPatientResultFiles,
  getPatientResultFilePreview: (state) => state.patientResultFilePreview,
  getPatientResults: (state) => state.patientResults,
  getFinalizeReportStatus: (state) => state.finalizeReportStatus,
  getPatientResultUpdateStatus: (state) => state.patientReportUpdateStatus,
  getPatientResultPreviewData: (state) => state.patientResultPreviewData,
  getReportPreview: (state) => state.resultsPreviewData,
  getFileGenerateStatus: (state) => state.fileGenerateStatus,
  getReportDownloadStatus: (state) => state.patientReportDownloadStatus,
  getReportDownloadData: (state) => state.reportDownloadData,
  getResultFileReportDownloadStatus: (state) =>
    state.resultFileReportDownloadStatus,
  getRegenerateGraphStatus: (state) => state.regenerateGraphStatus,
  getReadyToApproveReportStatus: (state) => state.readyToApproveReportStatus,
  getImageUploadData: (state) => state.imageUploadDetails,
  getGraphAttachStatus: (state) => state.attachGraphDataStatus,
  getResultsAttachStatus: (state) => state.resultsAttachStatus,
  getPatientReportReview: (state) => state.patientReportReview,
}

const mutations = {
  setAllResultFiles(state, data) {
    state.allResultFiles = data
  },
  setFileUploadStatus(state, data) {
    state.fileUploadStatus = data
  },
  setFileProcessStatus(state, data) {
    state.fileProcessStatus = data
  },
  setFileGenerateStatus(state, data) {
    state.fileGenerateStatus = data
  },
  setAllPatientResultFiles(state, data) {
    state.allPatientResultFiles = data
  },
  setResultFilePreview(state, data) {
    state.patientResultFilePreview = data
  },
  setPatientResults(state, data) {
    state.patientResults = data
  },
  setFinalizeReportStatus(state, status) {
    state.finalizeReportStatus = status
  },
  setPatientReportUpdateStatus(state, status) {
    state.patientReportUpdateStatus = status
  },
  setPatientResultsPreviewReportData(state, data) {
    state.patientResultPreviewData = data
  },
  setResultsData(state, data) {
    state.resultsPreviewData = data
  },
  setReportDownloadStatus(state, status) {
    state.patientReportDownloadStatus = status
  },
  setReportDownloadData(state, data) {
    state.reportDownloadData = data
  },
  setResultFileReportDownloadStatus(state, status) {
    state.resultFileReportDownloadStatus = status
  },
  setRegenerateGraphStatus(state, status) {
    state.regenerateGraphStatus = status
  },
  setReadyToApproveReportStatus(state, status) {
    state.readyToApproveReportStatus = status
  },
  setImageUploadData(state, data) {
    state.imageUploadDetails = data
  },
  setAttachGraphDataStatus(state, data) {
    state.attachGraphDataStatus = data
  },
  setAttachResultsStatus(state, data) {
    state.resultsAttachStatus = data
  },
  setPatientReportReview(state, data) {
    state.patientReportReview = data
  },
  reset: (state) => {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

const actions = {
  async fetchAllResultFiles({ commit, dispatch }, params) {
    try {
      commit("setAllResultFiles", null)
      const response = await axios.get("/test-result-files", { params })

      commit("setAllResultFiles", response.data)
    } catch (err) {
      commit("setAllResultFiles", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },

  async uploadResultFiles({ commit, dispatch }, params) {
    try {
      commit("setFileUploadStatus", null)
      const response = await axios.post("/test-result-files", params)

      commit("setFileUploadStatus", response.data)
    } catch (err) {
      commit("setFileUploadStatus", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async processReport({ commit, dispatch }, report_id) {
    try {
      commit("setFileProcessStatus", null)
      const response = await axios.post(
        `/test-result-files/${report_id}/process-file`
      )
      commit("setFileProcessStatus", response.data)
    } catch (err) {
      commit("setFileProcessStatus", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async generateReport({ commit, dispatch }, report_id) {
    try {
      commit("setFileGenerateStatus", null)
      const response = await axios.post(
        `/test-result-files/${report_id}/generate-report`
      )
      commit("setFileGenerateStatus", response.data)
    } catch (err) {
      commit("setFileGenerateStatus", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async fetchAllPatientResultFiles({ commit, dispatch }, params) {
    try {
      const id = params.result_file_id
      delete params.result_file_id
      commit("setAllPatientResultFiles", null)
      const response = await axios.get(
        "/test-result-files/" + id + "/patients",
        { params }
      )

      commit("setAllPatientResultFiles", response.data)
    } catch (err) {
      commit("setAllPatientResultFiles", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async fetchPatientResultFilePreview(
    { commit, dispatch },
    { result_file_id, patient_id, params = {} }
  ) {
    try {
      commit("setResultFilePreview", null)
      const response = await axios.get(
        `/test-result-files/${result_file_id}/patients/${patient_id}/report-preview`,
        { params }
      )
      commit("setResultFilePreview", response.data)
    } catch (err) {
      commit("setResultFilePreview", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async fetchSinglePatientResults({ commit, dispatch }, params) {
    try {
      commit("setPatientResults", null)
      const response = await axios.get(
        `/test-result-files/${params.result_file_id}/patients/${params.patient_id}`
      )
      commit("setPatientResults", response.data.data)
    } catch (err) {
      commit("setPatientResults", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async finalizeReport({ commit, dispatch }, params) {
    try {
      commit("setFinalizeReportStatus", null)
      const response = await axios.patch(
        `/test-result-files/${params.result_file_id}/patients/${params.patient_id}/finalize-report`
      )
      commit("setFinalizeReportStatus", response.data)
    } catch (err) {
      commit("setFinalizeReportStatus", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },

  async regenerateGraph({ commit, dispatch }, params) {
    try {
      commit("setRegenerateGraphStatus", null)
      const response = await axios.post(
        `/test-result-files/${params.result_file_id}/patients/${params.patient_id}/regenerate-graphs`
      )
      commit("setRegenerateGraphStatus", response.data)
    } catch (err) {
      commit("setRegenerateGraphStatus", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async downloadReport({ commit, dispatch }, params) {
    try {
      const reportId = params.reportId

      commit("errors/clear", null, { root: true })
      commit("setReportDownloadStatus", false)
      commit("setReportDownloadData", null)
      const response = await axios.get(
        `/test-result-files/${reportId}/download`
      )

      if (
        response &&
        response.data &&
        response.data.success &&
        response.data.data &&
        response.data.data.data &&
        response.data.data.data.length
      ) {
        const arrayBuffer = response.data.data.data
        const pdfBuffer = new Uint8Array(arrayBuffer)

        var blob = new Blob([pdfBuffer], { type: "application/pdf" })
        var blobURL = URL.createObjectURL(blob)

        var tmpLink = document.createElement("a")
        tmpLink.href = blobURL
        tmpLink.setAttribute("download", response.data.filename || "report.pdf")

        // temporarily add link to body and initiate the download
        document.body.appendChild(tmpLink)
        tmpLink.click()
        document.body.removeChild(tmpLink)

        commit("setReportDownloadStatus", true)
        // commit("setReportDownloadData", response.data);
      } else {
        commit("setReportDownloadStatus", false)
      }
    } catch (err) {
      console.log(err)
      commit("setReportDownloadStatus", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async updatePatientResults(
    { commit, dispatch },
    { params, patientId, resultFileId }
  ) {
    try {
      commit("setPatientReportUpdateStatus", null)
      const response = await axios.patch(
        `/test-result-files/${resultFileId}/patients/${patientId}/edit-report`,
        params
      )
      commit("setPatientReportUpdateStatus", response.data)
    } catch (err) {
      commit("setPatientReportUpdateStatus", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async updatePatientReportStatus(
    { commit, dispatch },
    { params, patientId, resultFileId }
  ) {
    try {
      commit("setPatientReportUpdateStatus", null)
      const response = await axios.patch(
        `/test-result-files/${resultFileId}/patients/${patientId}/update-status`,
        params
      )
      commit("setPatientReportUpdateStatus", response.data)
    } catch (err) {
      commit("setPatientReportUpdateStatus", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async addReportTemplatePreview({ commit, dispatch }, params) {
    try {
      commit("setResultsData", null)
      const response = await axios.post(
        "/test-result-files/report-type/draft-preview",
        params
      )
      commit("setResultsData", response.data)
    } catch (err) {
      commit("setResultsData", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async downloadPatientResultFileReport(
    { commit, dispatch },
    { patientId, resultFileId, params }
  ) {
    try {
      commit("errors/clear", null, { root: true })
      commit("setResultFileReportDownloadStatus", false)
      const response = await axios.get(
        `/test-result-files/${resultFileId}/patients/${patientId}/download-report`,
        { params }
      )

      if (
        response &&
        response.data &&
        response.data.success &&
        response.data.data &&
        response.data.data.data &&
        response.data.data.data.length
      ) {
        const arrayBuffer = response.data.data.data
        const pdfBuffer = new Uint8Array(arrayBuffer)

        var blob = new Blob([pdfBuffer], { type: "application/pdf" })
        var blobURL = URL.createObjectURL(blob)

        var tmpLink = document.createElement("a")
        tmpLink.href = blobURL
        tmpLink.setAttribute("download", response.data.filename || "report.pdf")

        // temporarily add link to body and initiate the download
        document.body.appendChild(tmpLink)
        tmpLink.click()
        document.body.removeChild(tmpLink)

        commit("setResultFileReportDownloadStatus", true)
      } else {
        commit("setResultFileReportDownloadStatus", false)
      }
    } catch (err) {
      console.log(err)
      commit("setResultFileReportDownloadStatus", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },

  async readyToApproveReport(
    { commit, dispatch },
    { patient_id, result_file_id, params }
  ) {
    try {
      commit("errors/clear", null, { root: true })
      commit("setReadyToApproveReportStatus", false)
      await axios.patch(
        `/test-result-files/${result_file_id}/patients/${patient_id}/update-ready-to-approve`,
        params
      )

      commit("setReadyToApproveReportStatus", true)
    } catch (err) {
      commit("setReadyToApproveReportStatus", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async uploadImage({ commit, dispatch }, { params, file }) {
    try {
      commit("errors/clear", null, { root: true })

      commit("setImageUploadData", null)
      const { data } = await axios.post("/upload-a-file/report-assets", params)

      if (data.success && data.data && data.data.target_url) {
        let pAxios = PrivateAxios.create({})
        await pAxios.put(data.data.target_url, file.raw)
      } else {
        commit("setImageUploadData", null)
      }
      commit("setImageUploadData", data.data)
    } catch (err) {
      commit("setImageUploadData", null)

      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async attachGraphdata(
    { commit, dispatch },
    { patient_id, result_file_id, params }
  ) {
    try {
      commit("errors/clear", null, { root: true })
      commit("setAttachGraphDataStatus", null)
      const response = await axios.post(
        `/test-result-files/${result_file_id}/patients/${patient_id}/update-graph-files`,
        params
      )
      commit("setAttachGraphDataStatus", response.data)
    } catch (err) {
      commit("setAttachGraphDataStatus", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async uploadDescreateValues({ commit, dispatch }, params) {
    let resultFileId = params.result_file_id
    let patientId = params.patient_id
    delete params.result_file_id
    delete params.patient_id
    try {
      commit("errors/clear", null, { root: true })
      commit("setAttachResultsStatus", null)
      const response = await axios.post(
        `/test-result-files/${resultFileId}/patients/${patientId}/merge-report`,
        params
      )
      commit("setAttachResultsStatus", response.data)
    } catch (err) {
      commit("setAttachResultsStatus", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async reviewPatientReport({ commit, dispatch }, params) {
    try {
      let patientId = params.patientId
      delete params.patientId
      let resultId = params.resultId
      delete params.resultId
      commit("errors/clear", null, { root: true })
      commit("setPatientReportReview", false)
      await axios.patch(
        `/test-result-files/${resultId}/patients/${patientId}/review-report`,
        params
      )

      commit("setPatientReportReview", true)
    } catch (err) {
      commit("setPatientReportReview", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  addResultsData({ commit }, params) {
    commit("setPatientResultsPreviewReportData", params)
  },
  reset: function ({ commit }) {
    commit("reset", null)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
