<template>
  <section id="auth-page" class="forgot-password">
    <div class="auth-card">
      <div class="auth-card-title">
        <div class="icon">
          <img
            src="@/assets/images/icons/lock.svg"
            alt="icon"
            class="img-fluid"
          />
        </div>
        <h3 class="title">OTP Verification</h3>
        <p class="description">Enter the OTP received on your Email</p>
        <p v-if="getForgotPasswordUsername">
          Email: {{ getForgotPasswordUsername }}
        </p>
      </div>
      <div class="auth-card-body">
        <div class="form-items">
          <div class="form-group">
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
              "
            >
              <OtpInput
                ref="otpInput"
                input-classes="otp-input"
                separator=""
                :num-inputs="4"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-change="handleOnChange"
                @on-complete="handleOnComplete"
              />
            </div>
            <div class="invalid-message">
              <FormError errorName="otp"></FormError>
            </div>

            <div class="otp-not-receive">
              <p>
                Didn't received OTP?
                <el-button
                  type="text"
                  class="resend-otp"
                  @click="resendOTP"
                  :disabled="disabled"
                  >Resend OTP</el-button
                >

                <span v-if="disabled" class="timer"> {{ countDown }}</span>
              </p>
            </div>
          </div>
        </div>

        <ul class="submit-action-group mt-40">
          <li>
            <el-button
              class="cancel-button"
              type="danger"
              @click="goToPreviousStep"
              >Cancel
            </el-button>
          </li>
          <li>
            <el-button
              class="submit-button"
              type="primary"
              @click="verifyOtp"
              :loading="loading"
              >Submit
            </el-button>
          </li>
        </ul>

        <div class="invalid-message">
          <FormError errorName="critical_error"></FormError>
        </div>
      </div>
    </div>
    <div class="logo">
      <img src="@/assets/images/yoda-logo.svg" alt="logo" class="img-fluid" />
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import FormError from "@/components/FormError";
import OtpInput from "vue3-otp-input";

import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  layout: "auth",
  middleware: "guest",
  computed: {
    ...mapGetters("auth", [
      "getForgotPasswordUsername",

      "getOtpSentStatus",
      "getOtpVerificationStatus",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),
  },
  data() {
    return {
      isPasswordVisible: false,
      otpForm: {
        otp: "",
        username: "",
      },
      userForm: {
        username: "",
      },
      loading: false,
      disabled: false,
      countDown: 10,
    };
  },
  components: {
    FormError,
    OtpInput,
  },
  async mounted() {
    this.$store.dispatch("errors/clear");
    this.$store.commit("auth/setOtpVerificationStatus", false, { root: true });
    if (this.getForgotPasswordUsername) {
      this.otpForm.username = this.getForgotPasswordUsername;
      this.userForm.username = this.getForgotPasswordUsername;
    } else {
      this.goToPreviousStep();
    }
  },
  methods: {
    handleOnComplete(value) {
      this.otpForm.otp = value;
    },
    handleOnChange(value) {
      this.otpForm.otp = value;
    },

    async verifyOtp() {
      try {
        this.loading = true;
        await this.$store.dispatch("auth/verify", this.otpForm);
        this.loading = false;
        if (this.getOtpVerificationStatus) {
          successNotification("OTP Verified Successfully");
          this.goToResetPassword();
        } else if (!this.getError) {
          errorNotification(this.getError || "Error while verifying OTP");
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async resendOTP() {
      try {
        this.disabled = true;
        this.countDown = 60;
        this.loading = true;
        await this.$store.dispatch(
          "auth/sendForgotPasswordVerification",
          this.userForm
        );
        this.loading = false;
        if (this.getOtpSentStatus && this.getOtpSentStatus.success) {
          successNotification("OTP sent to registered email successfully");
          this.countDownTimer();
        } else if (!this.getErrors) {
          errorNotification(this.getError || "Error while sending OTP");
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.disabled = false;
      }
    },
    goToPreviousStep() {
      this.$router.push("/forgot-password");
    },
    goToResetPassword() {
      this.$router.push("/forgot-password/verify");
    },
  },
};
</script>
<style lang="scss">
@import "/src/assets/scss/components/authentication.scss";
</style>
