<template>
    <div class="HLACLASSIIISABTYPING">
      <div class="edit-box mb-20">
            <span class="title">TEST RESULT</span>
            <el-table 
            :data="test_Result" style="width: 100%">
                <el-table-column label="SAB Class I">
               <el-table-column prop="HLA_Antigen_Specificities_1" label="HLA Antigen Specificities">
                
                <template v-slot="scope">
                  <el-input type="textarea" placeholder="Enter Result" v-model="scope.row.HLA_Antigen_Specificities_1"></el-input>
               </template>
               </el-table-column>
               <el-table-column prop="PRA_Result_1" label="PRA% (Result)"  width="200px">
                <template v-slot="scope">
                  <el-input placeholder="Enter Result"  v-model="scope.row.PRA_Result_1"></el-input>
               </template>
               </el-table-column>
            </el-table-column>
            </el-table>

            <el-table 
            :data="test_Result" style="width: 100%;margin-top: 20px;">
                <el-table-column label="SAB Class II">
               <el-table-column prop="HLA_Antigen_Specificities_2" label="HLA Antigen Specificities">
                
                <template v-slot="scope">
                  <el-input type="textarea" rows="4" placeholder="Enter Result" v-model="scope.row.HLA_Antigen_Specificities_2"></el-input>
               </template>
               </el-table-column>
               <el-table-column prop="PRA_Result_2" label="PRA% (Result)" width="200px">
                <template v-slot="scope">
                  <el-input placeholder="Enter Result"  v-model="scope.row.PRA_Result_2"></el-input>
               </template>
               </el-table-column>
            </el-table-column>
            </el-table>
        </div>
    
         <div class="edit-box mb-20">
            <span class="title">PATIENT HLA TYPE (BASED ON PREVIOUS REPORT)</span>
            <el-table :data="patient_HLA_Type" style="width: 100%">
                <el-table-column prop="HLA_A" label="HLA A">
                    <template v-slot="scope">
                        <el-input v-model="scope.row.HLA_A" placeholder="Enter"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="HLA_B" label="HLA B">
                    <template v-slot="scope">
                        <el-input v-model="scope.row.HLA_B" placeholder="Enter"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="HLA_C" label="HLA C">
                    <template v-slot="scope">
                        <el-input v-model="scope.row.HLA_C" placeholder="Enter"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="HLA_DRB_1" label="HLA DRB1">
                    <template v-slot="scope">
                        <el-input v-model="scope.row.HLA_DRB_1" placeholder="Enter"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="HLA_DQB_1" label="HLA DQB1">
                    <template v-slot="scope">
                        <el-input v-model="scope.row.HLA_DQB_1" placeholder="Enter"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="HLA_DQA_1" label="HLA DQA1">
                    <template v-slot="scope">
                        <el-input v-model="scope.row.HLA_DQA_1" placeholder="Enter"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="HLA_DPA_1" label="HLA DPA1">
                    <template v-slot="scope">
                        <el-input v-model="scope.row.HLA_DPA_1" placeholder="Enter"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="HLA_DPB_1" label="HLA DPB1">
                    <template v-slot="scope">
                        <el-input v-model="scope.row.HLA_DPB_1" placeholder="Enter"></el-input>
                    </template>
                </el-table-column>
            </el-table>

        </div>
        <div class="edit-box mb-20">
            <span class="title">CLINICAL SIGNIFICANCE</span>
            <ckeditor :editor="editor" v-model="Clinical_significance" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">TEST METHOD</span>
            <ckeditor :editor="editor" v-model="Test_Method" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">DETAILED REPORT</span>
            <el-table 
            :data="detailed_report_1" style="width: 100%">
                <el-table-column label="SAB Class I">
               <el-table-column prop="HLA_Antigen_Specificities_1" label="HLA Antigen Specificities">
                <template v-slot="scope">
                  <el-input  placeholder="Enter Result" v-model="scope.row.HLA_Antigen_Specificities_1"></el-input>
               </template>
               </el-table-column>
               <el-table-column  label="Recipient Antibody MFI Value">
                <template v-slot="scope">
                  <el-input  placeholder="Enter Result" v-model="scope.row.Recipient_Antibody_MFI_Value_1"></el-input>
               </template>
               </el-table-column>
               <el-table-column prop="PRA_Result_1" label="PRA% (Result)">
                <template v-slot="scope">
                  <el-input placeholder="Enter Result"  v-model="scope.row.PRA_Result_1"></el-input>
               </template>
               </el-table-column>
               <el-table-column prop="Risk_Category_Remarks_1" label="Risk Category / Remarks">
                <template v-slot="scope">
                  <el-input placeholder="Enter Result"  v-model="scope.row.Risk_Category_Remarks_1"></el-input>
               </template>
               </el-table-column>
            </el-table-column>
            </el-table>

            <el-table 
            :data="detailed_report" :span-method="objectSpanMethod" style="width: 100%;margin-top: 20px;">
                <el-table-column label="SAB Class II">
               <el-table-column prop="HLA_Antigen_Specificities_2" label="HLA Antigen Specificities">
               </el-table-column>
               <el-table-column prop="Recipient_Antibody_MFI_Value_2" label="Recipient Antibody MFI Value">
                <template v-slot="scope">
                  <el-input placeholder="Enter"  v-model="scope.row.Recipient_Antibody_MFI_Value_2"></el-input>
               </template>
               </el-table-column>
               <el-table-column align="center" prop="PRA_Result_2" label="PRA% (Result)">
                <template v-slot="scope">
                  <el-input placeholder="Enter"  v-model="scope.row.PRA_Result_2"></el-input>
               </template>
               </el-table-column>
               <el-table-column prop="Risk_Category_Remarks_2" label="Risk Category/Remarks">
                <template v-slot="scope">
                  <el-input placeholder="Enter"  v-model="scope.row.Risk_Category_Remarks_2"></el-input>
               </template>
               </el-table-column>
            </el-table-column>
            </el-table>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INTERPRETATION</span>
            <el-table :data="Interpretation" style="width: 100%">
               <el-table-column prop="riskLevel" label="Risk Level"  width="100px"></el-table-column>
               <el-table-column prop="mFI" label="MFI"  width="150px"></el-table-column>
               <el-table-column label="Description">
                  <template v-slot="scope">
                     <div>{{ parseDescription(scope.row.description) }}</div>
                  </template>
              </el-table-column>
            </el-table>
        </div>
        <div class="edit-box mb-20">
            <span class="title">DISCLAIMER</span>
            <ckeditor :editor="editor" v-model="Disclaimer" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">TEST ATTRIBUTES/LIMITATIONS</span>
            <ckeditor :editor="editor" v-model="Test_Limitations_Attributes" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">REFERENCE</span>
            <ckeditor :editor="editor" v-model="References" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>   
   </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";


export default{
    components:{
        ckeditor: CKEditor.component,
    },
    props: {
    HLA_SAB_Array: {
      type: Array,
      required: true
    },
    patientInfo:{
        type: String, 
    }
  },
  mounted(){
  },
    data(){
        return{
            editor: ClassicEditor,
            editorData: '',
            show: false,
            comments: "",
            recommendations: "",
            impression: "",
            resultData: "",
            references: "",
            remarks: "",
            editorConfig: EditorConfig,
            textarea: "",
            test_Result:this.HLA_SAB_Array?.length==1?this.HLA_SAB_Array[0].test_Result:[
                {
                    HLA_Antigen_Specificities_1:'',
                    PRA_Result_1:'',
                    HLA_Antigen_Specificities_2:'Positive  DQB1*03:01,DQB1*03:02,DQB1*03:03,DRB1*03:01,DRB1*03:02,DRB1*03:03,DRB1*07:01,DRB1* 08:01,DRB1*08:02,DRB1*09:01,DRB1*11:01,DRB1*11:03,DRB1*11:04,DRB1*12:01,DRB1*12:02,D RB1*13:01,DRB1*13:03,DRB1*13:05,DRB1*14:01,DRB1*14:03,DRB 1*14:04,DRB3*01:01,DRB3*02:02,DRB3*03:01 ',
                    PRA_Result_2:''
                }
            ],
            patient_HLA_Type:this.HLA_SAB_Array?.length==1?this.HLA_SAB_Array[0].patient_HLA_Type : [
                {
                    HLA_A:'',
                    HLA_B:'',
                    HLA_C:'',
                    HLA_DRB_1:'',
                    HLA_DQB_1:'',
                    HLA_DQA_1:'',
                    HLA_DPA_1:'',
                    HLA_DPB_1:''
                },
                {
                    HLA_A:'',
                    HLA_B:'',
                    HLA_C:'',
                    HLA_DRB_1:'',
                    HLA_DQB_1:'',
                    HLA_DQA_1:'',
                    HLA_DPA_1:'',
                    HLA_DPB_1:''
                }
            ],
            Clinical_significance:this.HLA_SAB_Array?.length==1?this.HLA_SAB_Array[0].Clinical_significance:'',
            Test_Method:this.HLA_SAB_Array?.length==1?this.HLA_SAB_Array[0].Test_Method:'',
            detailed_report_1:this.HLA_SAB_Array?.length==1?this.HLA_SAB_Array[0].detailed_report_1:[
            {
                HLA_Antigen_Specificities_1:'',
                Recipient_Antibody_MFI_Value:'',
                PRA_Result_1:'',
                Risk_Category_Remarks_1:''
            }
            ],
            detailed_report:this.HLA_SAB_Array?.length==1?this.HLA_SAB_Array[0].detailed_report : [                       
            {
                HLA_Antigen_Specificities_2: 'DRB3*01:01',
                Recipient_Antibody_MFI_Value_2: '',
                PRA_Result_2: '',
                Risk_Category_Remarks_2: ''
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*09:01',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB3*03:01',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*07:01',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB3*02:02',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*12:01',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*12:02',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*11:04',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*14:01',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*03:03',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*13:03',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*03:01',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*11:01',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*14:04',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*11:03',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*13:01',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*14:03',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*13:05',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*08:02',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*08:01',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DRB1*03:02',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DQB1*03:02',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DQB1*03:02',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DQB1*03:01',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DQB1*03:03',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            },
            {
              HLA_Antigen_Specificities_2: 'DQB1*03:03',
              Recipient_Antibody_MFI_Value_2: '',
              Risk_Category_Remarks_2: '',
            }
            ],
            Interpretation: [
                {
                    riskLevel: 'I',
                    mFI: '<1000',
                    description: 'Standard (negative)',
                },
                {
                   riskLevel: 'II',
                   mFI: '1000 - <2000',
                   description: 'Low level donor HLA specific antibodies, minimum risk of hyperacute rejection but greater than standard risk.',
                },
                {
                riskLevel: 'III',
                mFI: '2000 - 5000',
                description: 'Low risk of hyper-acute rejection but significant risk of early rejection and anti body mediated graft damage. Pretransplant antibody suppression may be considered.',
                },
                {
                riskLevel: 'IV',
                mFI: '>5000',
                description: 'Transplant veto, may be recommended for exceptional cases based on clinician discretion.  <br>  Further risk profiles (CDC, compliment fixation assay) recommended.',
                },
            ],
            Disclaimer:this.HLA_SAB_Array?.length==1?this.HLA_SAB_Array[0].Disclaimer:'',
            Test_Limitations_Attributes:this.HLA_SAB_Array?.length==1?this.HLA_SAB_Array[0].Test_Limitations_Attributes:'',
            References:this.HLA_SAB_Array?.length==1?this.HLA_SAB_Array[0].References:'',                                                              
        } 
        
    },
    methods:{
      parseDescription(description) {
      return description.replace(/<br\s*\/?>/ig, '\n');
    },
     childMethod() {
        let dd = window.document.getElementsByClassName("ck-content");
        const Clinical_significance = dd[0]?.innerHTML;  
        const Test_Method = dd[1]?.innerHTML;  
        const Disclaimer = dd[2]?.innerHTML; 
        const Test_Limitations_Attributes = dd[3]?.innerHTML; 
        const References = dd[4]?.innerHTML;      
        const result = [{
            test_Result:this.test_Result,
            patient_HLA_Type:this.patient_HLA_Type,
            Clinical_significance:Clinical_significance,
            Test_Method:Test_Method,
            detailed_report_1:this.detailed_report_1,
            detailed_report:this.detailed_report,
            Interpretation: this.Interpretation, 
            Disclaimer:Disclaimer, 
            Test_Limitations_Attributes:Test_Limitations_Attributes,           
            References:References,
          }] 
       this.$emit('childMethod', result);
    },
    
    objectSpanMethod({ rowIndex, columnIndex }) {
        if (columnIndex === 2) {
          if (rowIndex % 26 === 0) {
            return {
              rowspan: 26,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      }
  }
}
</script>


<style lang="scss" scoped>

.HLACLASSIIISABTYPING {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
    .el-table td.el-table__cell div {
      box-sizing: border-box;
       word-break: break-word !important;
    }
}

</style>