<template>
  <section
    id="add-report-view"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
  >
    <div class="reports-title-header">
      <h4 class="report-title">Molecular Genetics - Patient Report Data</h4>
      <div class="display-end">
        <el-button @click="goToReports" class="back-btn"
          ><el-icon class="el-icon-back"><Back /></el-icon>Back</el-button
        >
      </div>
    </div>

    <div class="inner-navbar">
      <el-row :gutter="10">
        <el-col :span="8">
          <div>
            <el-autocomplete
              v-model="searchVisitId"
              :fetch-suggestions="getAutoCompletePatientsData"
              placeholder="Search VisitID, Patient Name,UHID"
              :clearable="true"
              :trigger-on-focus="false"
              class="el-input"
              @select="handleSelect"
              @clear="clearNameSearch"
            ></el-autocomplete>
          </div>
        </el-col>

        <el-col :span="8">
          <el-select
            v-model="reportType"
            placeholder="Select Report Type *"
            :clearable="true"
            filterable
            @clear="clearFields"
            @change="onChangeReportType()"
          >
            <el-option
              v-for="(report, index) in reportTypes"
              :key="index"
              :label="report.label"
              :value="report.value"
            ></el-option>
          </el-select>
          <FormError errorName="report_type"></FormError>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="testId"
            placeholder="Select Test Category "
            :clearable="true"
            filterable
            @clear="askRemoveConfirmation"
            @change="fillSampleType"
          >
            <el-option
              v-for="report in categoryReportTypes"
              :key="report"
              :label="report.ItemName + '   --  ' + report.TestId"
              :value="report.TestId"
            ></el-option>
          </el-select>

          <FormError errorName="result_test_id"></FormError>
        </el-col>
      </el-row>
      <hr />
      <el-row :gutter="10">
        <el-col :span="8">
          <el-select
            v-model="patientInfo.department"
            allow-create
            placeholder="Select Department"
            :clearable="true"
            filterable
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>

          <FormError errorName="department"></FormError>
        </el-col>

        <el-col :span="8">
          <el-select
            v-model="patientInfo.report_template_id"
            placeholder="Select Report Template"
            :clearable="true"
            filterable
            @clear="askRemoveConfirmation"
            @change="updateTemplateData"
          >
            <el-option
              v-for="(template, index) in templates"
              :key="index"
              :label="template.template_title"
              :value="template._id"
            ></el-option>
          </el-select>

          <FormError errorName="report_template_id"></FormError>
        </el-col>
      </el-row>
    </div>
    <div class="inner">
      <span class="report-header">{{ patientInfo.department }}</span>
      <div class="patient-information">
        <el-form :model="patientInfo">
          <el-row>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Patient Name"
                class="mb-05 form-mandatory-field"
              >
                <el-input
                  v-model="patientInfo.patient_name"
                  placeholder="Enter Patient Name"
                ></el-input>
              </el-form-item>
              <FormError errorName="patient_name"></FormError>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Visit Id">
                <el-input
                  v-model="patientInfo.visit_id"
                  placeholder="Enter Visit Id"
                  disabled
                ></el-input>
                <FormError errorName="visit_id"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Ref. Doctor" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.ref_doctor"
                  placeholder="Enter Reference Doctor"
                ></el-input>
                <FormError errorName="ref_doctor"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="UHID/MR No" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.uhid"
                  placeholder="Enter UHID No"
                ></el-input>
                <FormError errorName="uhid"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Gender" class="mb-05 mandatory-field">
                <div class="gender-select">
                  <el-select
                    v-model="patientInfo.gender"
                    placeholder="Select Gender"
                  >
                    <el-option
                      v-for="(gender, index) in genderTypes"
                      :key="index"
                      :label="gender"
                      :value="gender"
                    ></el-option>
                  </el-select>
                  <FormError errorName="gender"></FormError>
                </div>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Age" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.age"
                  placeholder="Enter Age"
                ></el-input>
                <FormError errorName="age"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Mobile" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.mobile"
                  placeholder="Enter Mobile Number"
                ></el-input>
                <FormError errorName="mobile"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Phone" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.alternate_mobile"
                  placeholder="Enter Phone Number"
                ></el-input>
                <FormError errorName="alternate_mobile"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Email" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.email"
                  placeholder="Enter Email"
                ></el-input>
                <FormError errorName="email"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Locality" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.locality"
                  placeholder="Enter Locality"
                ></el-input>
                <FormError errorName="locality"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Hospital Name" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.hospital_name"
                  placeholder="Enter Hospital Name"
                ></el-input>
                <FormError errorName="hospital_name"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Registration Date"
                class="mb-05 mandatory-field"
              >
                <el-date-picker
                  v-model="patientInfo.registration_date"
                  type="datetime"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"
                  :disabled-date="disabledDate"
                ></el-date-picker>
                <FormError errorName="registration_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Collection Date"
                class="mb-05 mandatory-field"
              >
                <el-date-picker
                  v-model="patientInfo.collected_date"
                  type="datetime"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"
                  :disabled-date="disabledDate"
                ></el-date-picker>
                <FormError errorName="collected_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Received Date" class="mb-05 mandatory-field">
                <el-date-picker
                  v-model="patientInfo.received_date"
                  type="datetime"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"
                  :disabled-date="disabledDate"
                ></el-date-picker>
                <FormError errorName="received_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Reported Date" class="mb-05 mandatory-field">
                <el-date-picker
                  v-model="patientInfo.reported_date"
                  type="datetime"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"
                  :disabled-date="disabledDate"
                ></el-date-picker>
                <FormError errorName="reported_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Test Name" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.test_name"
                  placeholder="Enter Test Name"
                ></el-input>
                <FormError errorName="test_name"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Sample Type" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.sample_type"
                  placeholder="Enter Sample Type"
                ></el-input>
                <FormError errorName="sample_type"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Code" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.client_code"
                  placeholder="Enter Client Code"
                ></el-input>
                <FormError errorName="client_code"></FormError>
              </el-form-item>
            </el-col>
            <el-col
              :xl="8"
              :lg="8"
              :md="8"
              :sm="8"
              :xs="24"
              v-if="reportSubCategory == 'COVID_19_VIRUS_QUALITATIVE_PCR'"
            >
              <el-form-item label="Passport Number">
                <el-input
                  v-model="patientInfo.passport_number"
                  placeholder="Enter Passport Number"
                ></el-input>
                <FormError errorName="passport_number"></FormError>
              </el-form-item>
            </el-col>
            <el-col
              :xl="8"
              :lg="8"
              :md="8"
              :sm="8"
              :xs="24"
              v-if="havingTemplateType"
            >
              <el-form-item
                label="Gestational age"
                class="mb-05 mandatory-field"
              >
                <el-input
                  v-model="patientInfo.gestational_age"
                  placeholder="Enter Gestational Age"
                ></el-input>
                <FormError errorName="gestational_age"></FormError>
              </el-form-item>
            </el-col>
            <el-col
              :xl="8"
              :lg="8"
              :md="8"
              :sm="8"
              :xs="24"
              v-if="havingTemplateType"
            >
              <el-form-item label="Fetus Number" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.fetus_number"
                  placeholder="Enter Fetus Number"
                ></el-input>
                <FormError errorName="fetus_number"></FormError>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!-- <div
          class="report-sub-title"
          v-if="showReportType && patientInfo.report_type"
        >
          {{ patientInfo.report_type
          }}<span @click="onEditReportType" class="cursor-pointer"
            ><i class="el-icon-edit"></i
          ></span>
        </div>
        <div class="report-type-edit-info" v-else-if="editReportType">
          <el-input v-model="reportTypeNew"></el-input>
          <el-button
            type="success"
            @click="updateReportType"
            :disabled="isReportTypeUpdateDisable"
            icon="el-icon-check"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-close"
            @click="cancelUpdateReportType"
          ></el-button>
        </div>-->
      <div>
        <div v-if="showReportEdit">
          <div class="addReportEditor" v-for="(editorDataObj,index) in editorsData" :key="index">
            <div
              class="editor"
            >
                <el-input placeholder="Enter section title"
                v-model="editorsData[index].title">
                </el-input>
                <ckeditor
                  :editor="editor"
                  tag-name="div"
                  ref="myEditor"
                  v-model="editorsData[index].template_body"    
                  :config="editorConfig"
                >
                </ckeditor>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="editor" v-if="show  && (reportType !== 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR' && reportType !== 'SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR')" v-loading="loadingEditor">
            <ckeditor
              :editor="editor"
              tag-name="div"
              ref="myEditor"
              v-model="editorData"
              :config="editorConfig"
            ></ckeditor>
          </div>
        </div>  
      </div>  
      <div class="p-10">
        <el-row v-if="patientInfo.report_template_id && showReportEdit">
                  <el-col :span="6">
                    <el-checkbox label="Additional approval required" v-model="patientInfo.is_required_signature"
                      @change="handleCheckedChange"
                      :disabled="this.patientInfo.report_status === 'APPROVED' && this.patientInfo.fulfilled_signatures === 2" />
                  </el-col>
            </el-row>
      </div> 
      <div v-if="patientInfo.report_template_id &&(reportType=='SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR' || reportType=='SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR')">
        <!-- <sepsis ref="SepsisTestList"></sepsis> -->
        <div>
        <div class="double-marker">
            <!-- Test List Table -->
            <div class="edit-box mb-20 edit-box-table">
                <div class="inside-table">
                    <el-form>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Test Name" readonly style="font-weight: bold;">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Result" readonly style="font-weight: bold;">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item>
                                    <el-input placeholder="ORGANISM" readonly style="font-weight: bold;">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item>
                                    <el-input placeholder="GRAM POSITIVE ORGANISMS" readonly tabindex="-1" style="font-weight: bold;">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Staphylococcus Aureus" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramPostiveOrganisms[0].staphylococcus_Aureus"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramPostiveOrganisms[0].staphylococcus_Aureus === 'Detected' }"
                                    >
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                        
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Staphylococcus Epidermidis" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramPostiveOrganisms[0].staphylococcus_Epidermidis"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramPostiveOrganisms[0].staphylococcus_Epidermidis === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Streptococcus Pneumoniae" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramPostiveOrganisms[0].streptococcus_Pneumoniae"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramPostiveOrganisms[0].streptococcus_Pneumoniae === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Streptococcus Pyogenes" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramPostiveOrganisms[0].streptococcus_Pyogenes"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramPostiveOrganisms[0].streptococcus_Pyogenes === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Streptococcus Agalactiae" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramPostiveOrganisms[0].streptococcus_Agalactiae"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramPostiveOrganisms[0].streptococcus_Agalactiae === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Enterococcus Fecalis" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramPostiveOrganisms[0].enterococcus_Fecalis"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramPostiveOrganisms[0].enterococcus_Fecalis === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Enterococcus Faecium" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramPostiveOrganisms[0].enterococcus_Faecium"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramPostiveOrganisms[0].enterococcus_Faecium === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Listeria Monocytogenes" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramPostiveOrganisms[0].listeria_Monocytogenes"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramPostiveOrganisms[0].listeria_Monocytogenes === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item align="center">
                                    <el-input placeholder="GRAM NEGATIVE ORGANISMS" readonly style="font-weight:bold;" tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Escherichia Coli" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramNegativeOrganisms[0].Escherichia_Coli"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramNegativeOrganisms[0].Escherichia_Coli === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Klebsiella Pneumoniae" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramNegativeOrganisms[0].Klebsiella_pneumoniae"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramNegativeOrganisms[0].Klebsiella_pneumoniae === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Klebsiella Oxytoca" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramNegativeOrganisms[0].Klebsiella_Oxytoca"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramNegativeOrganisms[0].Klebsiella_Oxytoca === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Enterobacter Species" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramNegativeOrganisms[0].Enterobacter_species"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramNegativeOrganisms[0].Enterobacter_species === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Salmonella Spp" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramNegativeOrganisms[0].Salmonella_spp"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramNegativeOrganisms[0].Salmonella_spp === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Acinetobacter Baumannii" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramNegativeOrganisms[0].Acinetobacter_baumannii"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramNegativeOrganisms[0].Acinetobacter_baumannii === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Pseudomonas Beruginosa" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramNegativeOrganisms[0].Pseudomonas_aeruginosa"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramNegativeOrganisms[0].Pseudomonas_aeruginosa === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Haemophilus Influenzae (A-F)" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramNegativeOrganisms[0].Haemophilus_influenzae"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramNegativeOrganisms[0].Haemophilus_influenzae === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Neisseria Meningitidis" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="GramNegativeOrganisms[0].Neisseria_meningitidis"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': GramNegativeOrganisms[0].Neisseria_meningitidis === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item>
                                    <el-input placeholder="YEAST" readonly style="font-weight: bold;" tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Candida Auris" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Yeast[0].Candida_auris"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Yeast[0].Candida_auris === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Candida Albicans" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Yeast[0].Candida_albicans"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Yeast[0].Candida_albicans === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Candida Lusitaniae" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Yeast[0].Candida_lusitaniae"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Yeast[0].Candida_lusitaniae === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item>
                                    <el-input placeholder="ANTI-MICROBIAL RESISITANT GENE" readonly tabindex="-1"
                                        style="font-weight: bold;">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="sul2" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Anti_microbial_resistance_gene[0].sul2"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].sul2 === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="dfrA1" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Anti_microbial_resistance_gene[0].dfrA1"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].dfrA1 === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="dfrA5" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Anti_microbial_resistance_gene[0].dfrA5"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].dfrA5 === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="bla CTX-M gr1" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Anti_microbial_resistance_gene[0].bla_CTX_M_gr1"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].bla_CTX_M_gr1 === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="bla TEM" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Anti_microbial_resistance_gene[0].bla_TEM"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].bla_TEM === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="bla SHV" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Anti_microbial_resistance_gene[0].bla_SHV"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].bla_SHV === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="bla KPC" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Anti_microbial_resistance_gene[0].bla_KPC"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].bla_KPC === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="bla NDM" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Anti_microbial_resistance_gene[0].bla_NDM"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].bla_NDM === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="bla VIM" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Anti_microbial_resistance_gene[0].bla_VIM"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].bla_VIM === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="bla OXA-48" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Anti_microbial_resistance_gene[0].bla_OXA_48"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].bla_OXA_48 === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="bla IMP" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="  Anti_microbial_resistance_gene[0].bla_IMP"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].bla_IMP === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="mecA" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Anti_microbial_resistance_gene[0].mecA"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].mecA === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="vanA" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Anti_microbial_resistance_gene[0].vanA"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].vanA === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="vanB" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-select v-model="Anti_microbial_resistance_gene[0].vanB"  placeholder="Select" :clearable="true"
                                    :class="{ 'detected-color': Anti_microbial_resistance_gene[0].vanB === 'Detected' }">
                                        <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <!-- Test List Table -->

            <!-- DRUGS INTERPRETATION Table -->
            <div class="edit-box mb-20 edit-box-table">
                <div class="inside-table">
                    <el-form>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="DRUGS INTERPRETATION" readonly style="font-weight: bold;" tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="SENSITIVE / RESISTANT" readonly style="font-weight: bold;" tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="COTRIMOXAZOLE (Trimethoprim & Sulfamethoxazole)" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Cotrimoxazole">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Pencillin" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Pencillin">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Ampicillin" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Ampicillin">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Amoxicillin/ClavulanicAcid" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Amoxicillin_clavulanic_acid">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Piperacillin/Tazobactam" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Piperacillin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Cefazolin" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefazolin">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Cefoxitin" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefoxitin">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Cefuroxime" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefuroxime">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Cefotaxime" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefotaxime">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Ceftriaxone" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Ceftriaxone"> 
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Ceftazidime" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefatazidime">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Cefepime" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefepime">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Cefoperazone/Sulbactam" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefoperazone">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Ceftazidime/Avibactam" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Ceftazidime_avibactam">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Gentamicin" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Gentamicin">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Amikacin" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Amikacin">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Tigecycline" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Tigecycline">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Polymyxin" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Polymyxin">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Imipenem" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Imipenem">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Meropenem" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Meropenem">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Ertapenem" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Ertapenem">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Aztreonam" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Aztreonam">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Methicillin" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Methicillin">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Vancomycin" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Vancomycin"> 
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="Teicoplanin" readonly tabindex="-1">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item>
                                    <el-input placeholder="" v-model="Drugs_Interpretation[0].Teicoplanin">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <!-- DRUGS INTERPRETATION Table -->
            <!-- <div class="edit-box mb-20 editor">
              <ckeditor :editor="editor" tag-name="div" ref="myEditor" v-model="editorData"
                :config="editorConfig">
              </ckeditor>
            </div> -->
                          <div class="mb-20">
                    <div class="edit-box">
                        <span class="title">Intended Use</span>
                        <el-input type="textarea" rows="3" v-model="patientInfo.Intended_Use" ref="intendedUseField" autosize></el-input>
                    </div>
                    <div class="text" v-if="!patientInfo.Intended_Use && !validations() && isSubmited">Intended Use is required.</div>
                </div>

                <div class="mb-20">
            <div class="edit-box">
                <span class="title">Test Method</span>
                <el-input type="textarea"  v-model="patientInfo.Test_Method" row="2" autosize></el-input>
              </div>
                <div class="text" v-if="!patientInfo.Test_Method && !validations() && isSubmited">Test Method is required.</div>
           
          </div>
          <div class="mb-20">
            <div class="edit-box">
                <span class="title">Test Limitations/ Attributes</span>
                <el-input type="textarea"  v-model="patientInfo.Test_Limitations_Attributes" row="2" autosize></el-input>
              </div>
                <div class="text" v-if="!patientInfo.Test_Limitations_Attributes && !validations() && isSubmited">Test Limitations/ Attributes is required.</div>
           
          </div>
          <div class="mb-20">
            <div class="edit-box">
                <span class="title">Disclaimer</span>
                <el-input type="textarea"  v-model="patientInfo.Disclaimer" row="2" autosize></el-input>
            </div>
                <div class="text" v-if="!patientInfo.Disclaimer && !validations() && isSubmited">Disclaimer is required.</div>
           
          </div>
          <div>
            <div class="edit-box">
                <span class="title">References</span>
                <el-input type="textarea"  v-model="patientInfo.References" row="2" autosize></el-input>
            </div>
                <div class="text" v-if="!patientInfo.References && !validations() && isSubmited">References is required.</div>
           
          </div>
        </div>
      </div >
    </div>
    <div v-if="this.onCheckSubCategorys()"
        class="p-10">
        <el-row>
            <el-col :span="6">
             <el-checkbox label="Additional approval required" v-model="patientInfo.is_required_signature" @change="handleCheckedChange" 
             :disabled="this.patientInfo.report_status ==='APPROVED' && this.patientInfo.fulfilled_signatures === 2"/>
            </el-col>
        </el-row>
        </div>
      <!-- <div v-if="$route.params.patient_id">
          <qrcode-vue :value="value"></qrcode-vue>
        </div>-->
      <FormError errorName="critical_error"></FormError>

      <ul class="action-buttons">
        <!-- <li>
            <el-button @click="clearForm" class="submit-button"
              >Clear Form</el-button
            >
          </li>-->
        <li>
          <el-button @click="printReport" class="preview-button print-button"
            >Print</el-button
          >
        </li>
        <li>
          <el-button @click="previewReport" class="preview-button"
            >Preview</el-button
          >
        </li>
        <li v-if="!isEditForm">
          <el-button @click="savePatientInfo('DRAFT')" class="submit-button"
            >Save as Draft</el-button
          >
        </li>
        <li v-if="!isEditForm">
          <el-button @click="savePatientInfo('PENDING');isSubmited=true" class="submit-button"
            >Submit</el-button
          >
        </li>
        <li v-if="isEditForm && isDraft">
          <el-button @click="savePatientInfo('DRAFT')" class="submit-button"
            >Update Draft</el-button
          >
        </li>
        <li v-if="isEditForm && isDraft">
          <el-button @click="savePatientInfo('PENDING')" class="submit-button"
            >Make Complete</el-button
          >
        </li>
        <li v-if="isEditForm && !isDraft">
          <el-button
            @click="savePatientInfo(this.patientInfo.report_status);isSubmited=true"
            class="submit-button"
            >Update</el-button
          >
        </li>

        <li>
          <el-button @click="saveAsTemplate" class="submit-button"
            >Save As Template</el-button
          >
        </li>
      </ul>
    </div>

    <div v-if="templateTitleDialogVisible" class="template-title-dialog">
      <el-dialog
        width="30%"
        v-model="templateTitleDialogVisible"
        title="New Template"
        :before-close="handleClose"
        class="template-title-dialog"
      >
        <el-input
          placeholder="Please add a titile for Template "
          v-model="templateTitle"
        ></el-input>

        <template #footer>
          <span class="dialog-footer">
            <el-button
              size="medium"
              class="el-white"
              type="info"
              plain
              @click="handleClose"
              >Cancel</el-button
            >
            <el-button
              size="medium"
              type="primary"
              @click="addNewTemplate"
              :loading="loading"
              >Submit</el-button
            >
          </span>
        </template>

        <FormError errorName="template_title"></FormError>
      </el-dialog>
      
    </div>
    <ResonForChange v-if="takeReasonDialog" v-on:close="takeReasonDialog = false" v-on:reasonSubmit="updateReport">
    </ResonForChange>
  </section>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ResonForChange from "@/components/ReasonForChange";
import {
  genderTypes,
  molecularGeneticsType,
  molecularGeneticsReportTypeMap,
} from "@/constants/formConstants";
import { ElMessageBox, ElMessage } from "element-plus";
import { mapGetters } from "vuex";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import FormError from "@/components/FormError";
import moment from "moment";
import EditorConfig from "@/config/editorConfig";
export default {
  components: {
    ckeditor: CKEditor.component,
    FormError,
    ResonForChange
  },

  data() {
    return {
      takeReasonDialog: false,
      reasonStatus:'',
      isSubmited:false,
      askSaveForm: false,
      templateTitleDialogVisible: false,
      templateTitle: "",

      value: "",
      reportTypes: molecularGeneticsType,
      genderTypes: genderTypes,
      options: ["DEPARTMENT OF MOLECULAR GENETICS"],
      templates: [],
      patientInfo: {
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        uhid: "",
        mr_no: "",
        gender: "",
        age: "",
        hospital_name: "",
        registration_date: "",
        collected_date: "",
        reported_date: "",
        received_date: "",
        client_name: "",
        client_add: "",
        client_code: "",
        department: "DEPARTMENT OF MOLECULAR GENETICS",
        report_type: "",
        email: "",
        alternate_mobile: "",
        mobile: "",
        locality: "",
        category: "MOLECULAR_GENETICS",
        report_status: "DRAFT",
        sample_type: "",
        test_name: "",
        gestational_age: "",
        fetus_number: "",
        passport_number: "",
        is_required_signature:false,
        typedby:"",
        Intended_Use:'',
         Test_Method:'',
         Test_Limitations_Attributes:'',
         Disclaimer:'',
         References:''
      },
      unSavedChanges: false,
      loading: false,
      loadingText: "",
      editor: ClassicEditor,
      show: false,
      editorData: "",
      searchVisitId: "",
      reportType: "",
      showReportType: true,
      editReportType: false,
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      editorConfig: EditorConfig,
      showAddTemplate: false,
      reportTypeNew: "",
      molecularGenomicsReportType: molecularGeneticsReportTypeMap,
      loadingEditor: false,
      categoryReportTypes: [],
      listOfDepartments: [],
      testId: "",
      reportSubCategory: "",
      Required_Signatures: [
        {
          value: 'Yes',
          label: 'Yes',
        },
        {
          value: 'No',
          label: 'No',
        }
      ],
      dropdownOptions: [
        {
          label: "Detected",
          value: "Detected",
          color: "#5cb85c" // green
        },
        {
          label: "Not Detected",
          value: "Not Detected",
          color: "#5cb85c"
        },
      ],
      GramPostiveOrganisms: [{
          staphylococcus_Aureus: '',
          staphylococcus_Epidermidis: '',
          streptococcus_Pneumoniae: '',
          streptococcus_Pyogenes: '',
          streptococcus_Agalactiae: '',
          enterococcus_Fecalis: '',
          enterococcus_Faecium: '',
          listeria_Monocytogenes: ''
      }],
      GramNegativeOrganisms: [{
          Escherichia_Coli: '',
          Klebsiella_pneumoniae: '',
          Klebsiella_Oxytoca: '',
          Enterobacter_species: '',
          Salmonella_spp: '',
          Acinetobacter_baumannii: '',
          Pseudomonas_aeruginosa: '',
          Haemophilus_influenzae: '',
          Neisseria_meningitidis: ''

      }],
      Yeast: [{
          Candida_auris: '',
          Candida_albicans: '',
          Candida_lusitaniae: '',
      }],
      Anti_microbial_resistance_gene : [{
          sul2:'',	  
          dfrA1:'',  
          dfrA5:'',	  
          bla_CTX_M_gr1:'',	  
          bla_TEM	:'',  
          bla_SHV	:'',  
          bla_KPC	:'',  
          bla_NDM	:'',  
          bla_VIM:'',	  
          bla_OXA_48	:'',  
          bla_IMP:'',	  
          mecA:'',	  
          vanA:'',	  
          vanB:''
      }],
      Drugs_Interpretation: [{
          Cotrimoxazole:'',
          Cefoperazone:'',
          Pencillin: '',
          Ampicillin: '',
          Amoxicillin_clavulanic_acid: '',
          Piperacillin:'',
          Cefazolin: '',
          Cefoxitin: '',
          Cefuroxime: '',
          Cefotaxime: '',
          Ceftriaxone: '',
          Ceftazidime: '',
          Cefatazidime: '',
          Cefepime:'',
          Cefoperazone_sulbactam:'',
          Ceftazidime_avibactam:'',
          Cefatazidime_Avibactam: '',
          Gentamicin: '',
          Amikacin: '',
          Tigecycline: '',
          Polymyxin: '',
          Imipenem: '',
          Meropenem: '',
          Ertapenem: '',
          Aztreonam: '',
          Methicillin: '',
          Vancomycin: '',
          Teicoplanin: ''
      }],
      editorsData:[
        {
          title:"",
          template_body:""
        }
      ]  
    };
  },
  computed: {
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("patientReports", [
      "getPatientAddStatus",
      "getSinglePatientReports",
      "getPatientUpdateStatus",
      "getPatientDetails",
      "getDepartments",
    ]),

    ...mapGetters("reportTemplates", [
      "getAllReportTemplates",
      "getReportTemplateAddStatus",
      "getSingleReportTemplate",
    ]),
    ...mapGetters("auth", ["getProfileDetails"]),
    isReportTypeUpdateDisable() {
      return this.reportTypeNew &&
        this.reportTypeNew.trim() &&
        this.reportTypeNew.trim().length
        ? false
        : true;
    },

    isEditForm() {
      return this.$route.params && this.$route.params.patient_id ? true : false;
    },
    isDraft() {
      if (
        this.isEditForm &&
        this.patientInfo.report_status &&
        this.patientInfo.report_status != "DRAFT"
      ) {
        return false;
      }
      return true;
    },

    noContent() {
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0]?.innerHTML;
      return data.length ? false : true;
    },
    havingTemplateType() {
      if (
        this.reportType == "NIPT" ||
        this.reportType == "NIPT_with_Micro_Deletions"
      ) {
        return true;
      }
      return false;
    },
    showReportEdit() {
      return this.reportType &&
        (this.reportType === 'COMPREHENSIVE_RESPIRATORY_PANEL_1_BACTERIA_VIRUSES' ||
        this.reportType === 'COMPREHENSIVE_RESPIRATORY_PANEL_2_BACTERIA_VIRUSES_FUNGI' ||
        this.reportType === 'COMPREHENSIVE_RESPIRATORY_PANEL_3_BACTERIA_VIRUSES_FUNGI' ||
        this.reportType === 'CLOSTRIDIUM_DIFFICILE_DETECTION_PCR' ||
        this.reportType === 'VRSA_DETECTION_QUALITATIVE_BY_REAL_TIME_PCR' ||
        this.reportType === 'CBNAAT_GENEXPERT_MTB_XDR' ||
        this.reportType === 'CBNAAT_GENEXPERT_MTB_WITH_RIFAMPICIN_QUALITATIVE' ||
        this.reportType === 'FLU_PANEL_BY_REAL_TIME_PCR' ||
        this.reportType === 'HEPATITIS_B_VIRUS_HBV_GENOTYPE' ||
        this.reportType === 'LEPTOSPIRA_DETECTION_PCR' ||
        this.reportType === 'MEASLES_PCR' ||
        this.reportType === 'MDR_MTB_WITH_RIFAMPICIN_QUALITATIVE' ||
        this.reportType === 'HIV_1_DRUG_RESISTANCE_GENOTYPING' ||
        this.reportType === 'MRSA_SCREENING_BY_QUALITATIVE_PCR' ||
        this.reportType === 'MYCOBACTERIUM_TUBERCULOSIS_MTB_AMP_NON_TUBERCULOUS_MYCOBACTERIUM_NTM_DIFFERENTIATION_BY_QUALITATIVE_PCR' ||
        this.reportType === 'NON_TUBERCULOUS_MYCOBACTERIUM_NTM_QUALITATIVE_PCR' ||
        this.reportType === 'SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR_V2' ||
        this.reportType === 'SYNOVIAL_FLUID_JOINT_INFECTION_MULTIPLEX_PCR' ||
        this.reportType === 'TORCH_PANEL_REALTIME_PCR' ||
        this.reportType === 'VRE_DETECTION_BY_QUALITATIVE_PCR' ||
        this.reportType === 'CRYPTOCOCCUS_QUALITATIVE_PCR' ||
        this.reportType === 'CHIKUNGUNYA_RNA_DETECTION_BY_PCR' ||
        this.reportType === 'CANDIDA_AURIS_QUALITATIVE_PCR' ||
        this.reportType === 'ANTI_MICROBIAL_RESISTANCE_GENE_DETECTION_BY_MULTIPLEX_PCR' ||
        this.reportType === 'EXTENDED_SPECTRUM_BETA_LACTAMS_ESBL_RESISTANCE_GENE_DETECTION_BY_MULTIPLEX_PCR' ||
        this.reportType === 'TOXOPLASMA_QUALITATIVE_PCR' ||
        this.reportType === 'ASPERGILLUS_QUALITATIVE_PCR' ||
        this.reportType === 'MUCORALES_QUALITATIVE_PCR' ||
        this.reportType === 'FUNGAL_MULTIPLEX_PCR_PANEL' ||
        this.reportType === 'PAN_FUNGAL_DNA_PCR' ||
        this.reportType === 'CAREBAPENEM_PCR' ||
        this.reportType === 'SCRUB_TYPHUS_PCR' ||
        this.reportType === 'RICKETSSIA_PCR' ||
        this.reportType === 'EGFR' ||
        this.reportType === 'KRAS' ||
        this.reportType === 'MSI_BY_SANGER_SEQUENCING');
    }
  },
  async mounted() {
    if (this.$route.params.patient_id) {
      await this.fetchSinglePatientInfo();
      await this.getVisitData();
      await this.fetchReportTemplates();
    }

    await this.$store.dispatch("errors/clear", {}, { root: true });

    setTimeout(() => {
      this.show = true;
    });
  },

  methods: {
    async updateReport(reason) {
      this.updatePatientInfo(reason)
    },
    onEditReportType() {
      this.reportTypeNew = this.patientInfo.report_type;
      this.editReportType = true;
      this.showReportType = false;
    },
    updateReportType() {
      this.patientInfo.report_type = this.reportTypeNew;
      this.editReportType = false;
      this.showReportType = true;
    },
    cancelUpdateReportType() {
      this.reportTypeNew = this.patientInfo.report_type;
      this.editReportType = false;
      this.showReportType = true;
    },
    clearFields() {
      this.templates = [];
    },
    onChangeReportType() {
      this.reportSubCategory = this.reportType;
      this.patientInfo.report_type = this.reportType;
      this.patientInfo.report_template_id = "";

      this.patientInfo.test_name =
        this.molecularGenomicsReportType[this.reportType];

      this.fetchReportTemplates();
    },
    askSaveConfirmation() {
      this.askSaveForm = true;
    },
    async getAutoCompletePatientsData(queryString, cb) {
      if (queryString && queryString.length > 2) {
        await this.$store.dispatch("patientReports/fetchAllPatients", {
          search_string: queryString,
          get_all: true,
        });

        const patientsData = [];

        (this.getPatientDetails.data || []).forEach((el) => {
          patientsData.push({
            value: el.VisitiID + " - " + el.PatientName,
            ...el,
          });
        });

        cb(patientsData || []);
      } else {
        cb([]);
      }
    },
    async getVisitData() {
      try {
        this.searchVisitId =
          this.patientInfo.visit_id + "-" + this.patientInfo.patient_name;
        // await this.getAutoCompletePatientsData(this.patientInfo.visit_id);
      } catch (error) {
        console.log(error);
      }
    },
    async handleSelect(data) {
      this.patientInfo.visit_id = data.VisitiID;
      const age = data.Age.split(" ");
      const years = parseInt(age[0]);
      const months = parseInt(age[2]);
      const days = parseInt(age[4]);
      if (years > 0 && months > 0 && days > 0 || years > 0 && months > 0) {
        this.patientInfo.age = `${years} Y ${months} M`;
      } else if (years === 0 && months > 0 && days > 0) {
        this.patientInfo.age = `${months} M ${days} D`;
      } else if (years === 0 && months === 0 && days > 0) {
        this.patientInfo.age = `${days} D`;
      }else if ( months > 0) {
        this.patientInfo.age = `${months} M`;
      }
      else if(years>0){
        this.patientInfo.age = `${years} Y`;
      }
      this.patientInfo.gender = data.Gender.toUpperCase();
      this.patientInfo.barcode_no = data.BarcodeNo;
      this.patientInfo.client_add = data.ClientAdd;
      this.patientInfo.client_code = data.ClientCode;
      this.patientInfo.client_name = data.ClientName;
      this.patientInfo.hospital_name = data.HospitalName;
      this.patientInfo.patient_name = data.PatientName;
      this.patientInfo.ref_doctor = data.RefDoctor && !data.RefDoctor.startsWith('Dr.')
    ? `Dr. ${data.RefDoctor}`
    : data.RefDoctor || '';
      this.patientInfo.uhid = data["UHID/MR NO"];

      this.patientInfo.registration_date = data.RegisterationDate
        ? moment(data.RegisterationDate).utc().format()
        : null;
      this.patientInfo.collected_date = data.CollectionDate
        ? moment(data.CollectionDate).utc().format()
        : null;
        this.patientInfo.reported_date = this.$route.params.patient_id? (data.ReportedDate
        ? moment(data.ReportedDate).utc().format()
        : null):null;
      this.patientInfo.received_date = data.ReceivedDate
        ? moment(data.ReceivedDate).utc().format()
        : null;

      this.patientInfo.email = data.Email;
      this.patientInfo.mobile = data.Mobile;
      this.patientInfo.locality = data.Locality;
      this.patientInfo.alternate_mobile = data.Phone;
      this.patientInfo.sample_type = data.SampleTypeName;
      if (data.TestName) {
        this.patientInfo.test_name = data.TestName;
      }
      this.patientInfo.gestational_age = data.GestationalAge;
      this.patientInfo.fetus_number = data.FetusNumber;
      if (data.PassPortNo) {
        this.patientInfo.passport_number = data.PassPortNo;
      }
      await this.fetchPatientDepartments();
    },
    async fetchPatientDepartments() {
      this.loading = true;
      let params = {};
      if (this.patientInfo.visit_id) {
        params.visit_id = this.patientInfo.visit_id;
        params.department = "molecular-genetics";
      }
      await this.$store.dispatch("patientReports/fetchAllDepartments", params);
      if (
        this.getDepartments &&
        this.getDepartments.data &&
        this.getDepartments.data.length
      ) {
        this.listOfDepartments = this.getDepartments.data;

        this.listOfDepartments.forEach(
          (x) => (this.categoryReportTypes = x.test_categories)
        );
      } else {
        this.listOfDepartments = [];
      }
      this.loading = false;
    },
    clearNameSearch() {
      let patientInfo = {
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        client_add: "",
        client_code: "",
        uhid: "",
        mr_no: "",
        gender: "",
        age: "",
        client_name: "",
        hospital_name: "",
        barcode_no: "",
        registration_date: "",
        collected_date: "",
        reported_date: "",
        received_date: "",
        email: "",
        alternate_mobile: "",
        mobile: "",
        locality: "",
        fetus_number: "",
        gestational_age: "",
      };
      this.patientInfo = { ...this.patientInfo, ...patientInfo };
    },
    async fetchSinglePatientInfo() {
      this.loading = true;
      this.loadingText = "Fetching data...";
      try {
        await this.$store.dispatch(
          "patientReports/fetchSinglePatientReports",
          this.$route.params.patient_id
        );
        this.setPatientData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async setPatientData() {
      this.editorsData = []
      this.patientInfo = { ...this.getSinglePatientReports };
      this.reportType = this.patientInfo.sub_category;
      this.reportSubCategory = this.patientInfo.sub_category;
      this.editorData = this.getSinglePatientReports.test_details;
      if (
        this.getSinglePatientReports &&
        this.getSinglePatientReports.template_body_sections &&
        this.getSinglePatientReports.template_body_sections.length
      ) {
        this.editorsData = [ ...this.editorsData, ...this.getSinglePatientReports.template_body_sections];
      }
      await this.fetchPatientDepartments();
      if (this.getSinglePatientReports.result_test_id) {
        this.testId = this.getSinglePatientReports.result_test_id;
      }
      if(this.patientInfo.sub_category==='SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR'){
       this.GramPostiveOrganisms=this.getSinglePatientReports.GramPostiveOrganisms,
       this.GramNegativeOrganisms = this.getSinglePatientReports.GramNegativeOrganisms
       this.Yeast = this.getSinglePatientReports.Yeast
       this.Anti_microbial_resistance_gene =this.getSinglePatientReports.Anti_microbial_resistance_gene
       this.Drugs_Interpretation=this.getSinglePatientReports.Drugs_Interpretation
      }
    },
    async handleClose() {
      this.templateTitle = "";
      this.templateTitleDialogVisible = false;
      await this.$store.dispatch("errors/clear", {}, { root: true });
    },

    async addNewTemplate() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        let params = { ...this.patientInfo };
        params.template_title = this.templateTitle;
        params.test_details = data;

        if (!data.length) {
          this.$message("No Content to Save Template");
          return;
        }
        params.template_body = data;

        if (
          this.showReportEdit &&
          this.editorsData &&
          this.editorsData.length > 0
        ) {
          let multipleTitleData = []
          let multititle = {
            title:"",
            template_body:""
          }
          Array.from(dd).forEach((ckeditorElement, index) => {
            if (ckeditorElement && this.editorsData[index]) {
              multititle.template_body = ckeditorElement.innerHTML || "";
              multititle.title = this.editorsData[index].title || "";
              multipleTitleData.push({ ...multititle }); // Push a copy to the array
            }
          });
          params.template_body_sections = multipleTitleData;
        }
        this.loading = true;
        params.sub_category = this.reportSubCategory;

        if (params.age) {
          params.age = params.age.toString();
        }
        if (params.client_code) {
          params.client_code = params.client_code.toString();
        }
        await this.$store.dispatch("reportTemplates/addReportTemplate", params);

        if (this.getReportTemplateAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Template Added Successfully",
          });

          this.templateTitleDialogVisible = false;
          this.templateTitle = "";

          await this.fetchReportTemplates();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error at Creating new Template",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    sortTemplatesTypes() {
      this.templates.sort(function (a, b) {
        if (a.sub_category < b.sub_category) {
          return -1;
        }
        if (a.sub_category > b.sub_category) {
          return 1;
        }
        return 0;
      });
    },
    saveAsTemplate() {
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0]?.innerHTML;
      let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
      stringContent = stringContent.trim();
      if (!stringContent) {
        ElMessage.warning("Report content is not given");
        return;
      }
      this.templateTitleDialogVisible = true;
    },
    async previewReport() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        if(this.reportType !=='SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR' && this.reportType!=='SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'){
        let stringContent = data?.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent?.trim();
        if (!stringContent) {
          ElMessage.warning("Report content is not given");
          return;
        }
      }

        this.loading = true;
        this.loadingText = "Loading..";

        let params = { ...this.patientInfo };
        params.sub_category = this.reportSubCategory;
        params.test_details = data;

        if (
          this.showReportEdit &&
          this.editorsData &&
          this.editorsData.length > 0
        ) {
          let multipleTitleData = []
          let multititle = {
            title:"",
            template_body:""
          }
          Array.from(dd).forEach((ckeditorElement, index) => {
            if (ckeditorElement && this.editorsData[index]) {
              multititle.template_body = ckeditorElement.innerHTML || "";
              multititle.title = this.editorsData[index].title || "";
              multipleTitleData.push({ ...multititle }); // Push a copy to the array
            }
          });
          params.template_body_sections = multipleTitleData;
        }
        if(this.reportType == "SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR" || this.reportType=='SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'){
          params.GramPostiveOrganisms= this.GramPostiveOrganisms,
          params.GramNegativeOrganisms=this.GramNegativeOrganisms,
          params.Yeast= this.Yeast,
          params.Anti_microbial_resistance_gene= this.Anti_microbial_resistance_gene,
          params.Drugs_Interpretation= this.Drugs_Interpretation
        }
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreview",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to preview report",
            "Alert",
            {
              confirmButtonText: "OK",
            }
          );
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async printReport() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        if(this.reportType !=='SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR' && this.reportType!=='SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'){
        let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent.trim();
        if (!stringContent) {
          ElMessage.warning("Report content is not given");
          return;
        }
      }

        this.loading = true;
        this.loadingText = "Loading..";

        let params = { ...this.patientInfo };
        params.sub_category = this.reportSubCategory;

        params.test_details = data;

        if (
          this.showReportEdit &&
          this.editorsData &&
          this.editorsData.length > 0
        ) {
          let multipleTitleData = []
          let multititle = {
            title:"",
            template_body:""
          }
          Array.from(dd).forEach((ckeditorElement, index) => {
            if (ckeditorElement && this.editorsData[index]) {
              multititle.template_body = ckeditorElement.innerHTML || "";
              multititle.title = this.editorsData[index].title || "";
              multipleTitleData.push({ ...multititle }); // Push a copy to the array
            }
          });
          params.template_body_sections = multipleTitleData;
        }
        if(this.reportType == "SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR" || this.reportType=='SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'){
        params.GramPostiveOrganisms= this.GramPostiveOrganisms,
          params.GramNegativeOrganisms=this.GramNegativeOrganisms,
          params.Yeast= this.Yeast,
          params.Anti_microbial_resistance_gene= this.Anti_microbial_resistance_gene,
          params.Drugs_Interpretation= this.Drugs_Interpretation
        }
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreviewPrint",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to print report",
            "Alert",
            {
              confirmButtonText: "OK",
            }
          );
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async goToReports() {
      if (this.$route.params.patient_id) {
        this.$router.push({
          name: "PatientReportView",
          query: this.$route.query,
          params: { status: this.$route.params.status },
        });
      } else {
        this.$router.push({ name: "PatientReports", query: this.$route.query });
      }
    },

    async fetchReportTemplates() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data..";
        let params = {
          get_all: true,
          category: "MOLECULAR_GENETICS",
        };
        if (this.reportType) {
          params.report_type =
            this.molecularGenomicsReportType[this.reportType];
        }
        await this.$store.dispatch(
          "reportTemplates/fetchAllReportTemplates",
          params
        );
        this.loading = false;
        if (
          this.getAllReportTemplates &&
          this.getAllReportTemplates.data &&
          this.getAllReportTemplates.data.length
        ) {
          this.templates = this.getAllReportTemplates.data.sort((a, b) =>
            a.sub_category.localeCompare(b.sub_category)
          );
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    askReason() {
      this.takeReasonDialog = true
    },
    savePatientInfo(status) {
      this.reasonStatus = status;
      if ((this.reportType === 'SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR' || this.reportType === 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR') && !this.validations()) {
        this.$refs.intendedUseField.$el.scrollIntoView({ behavior: 'smooth' });
      }
    if ((this.reportType === 'SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR' || this.reportType === 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR') && this.validations()) {
      if (this.$route.params.patient_id && status === 'APPROVED') {
        this.askReason()
      } else if (this.$route.params.patient_id) {
        this.updatePatientInfo();
      } else {
        this.addPatientInfo(status);
      }
    } else if (this.reportType !== 'SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR' && this.reportType !== 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR') {
      if (this.$route.params.patient_id && status === 'APPROVED') {
        this.askReason()
      } else if (this.$route.params.patient_id) {
        this.updatePatientInfo();
      } else {
        this.addPatientInfo(status);
      }
    }
},
    validations() {
      return (
        this.patientInfo.Intended_Use !== '' &&
        this.patientInfo.Test_Method !== '' &&
        this.patientInfo.Test_Limitations_Attributes !== '' &&
        this.patientInfo.Disclaimer !== '' &&
        this.patientInfo.References !== ''
      );
    }, 
    async fetchSingleReportTemplate(templateId) {
      try {
        await this.$store.dispatch(
          "reportTemplates/fetchSingleReportTemplate",
          { template_id: templateId }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async updateTemplateData(templateId) {
      this.editorsData = []
      await this.fetchSingleReportTemplate(templateId);

      let templateData = this.templates.find((x) => x._id == templateId);
      if (
        this.getSingleReportTemplate &&
        this.getSingleReportTemplate.template_body
      ) {
        templateData.template_body = this.getSingleReportTemplate.template_body;
      }
      if (
        this.getSingleReportTemplate &&
        this.getSingleReportTemplate.template_body_sections &&
        this.getSingleReportTemplate.template_body_sections.length
      ) {
        this.editorsData = [ ...this.editorsData, ...this.getSingleReportTemplate.template_body_sections];
      }
      if (
        this.getSingleReportTemplate &&
        this.getSingleReportTemplate.test_details
      ) {
        templateData.test_details = this.getSingleReportTemplate.test_details;
      }
      if (templateData) {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML || "";
        let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent.trim();
        if (stringContent) {
          ElMessageBox.confirm(
            "Are you want to update template data. Continue?",
            "Warning",
            {
              confirmButtonText: "OK",
              cancelButtonText: "No",
              type: "warning",
            }
          )
            .then(async () => {
              await this.updateEditorData(templateData);
            })
            .catch(() => {});
        } else {
          this.updateEditorData(templateData);
        }
      }
    },
    updateEditorData(templateData) {
      this.show = false;
      this.loadingEditor = true;
      setTimeout(() => {
        this.loadingEditor = false;

        this.show = true;
        this.editorData = templateData.template_body || "";
        this.patientInfo.report_type = templateData.report_type || "";

        this.reportType = templateData.sub_category || "";
        if(this.reportType === 'SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR' || this.reportType === 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'){
            this.Anti_microbial_resistance_gene=templateData.Anti_microbial_resistance_gene;
            this.Drugs_Interpretation=templateData.Drugs_Interpretation;
            this.GramNegativeOrganisms=templateData.GramNegativeOrganisms;
            this.GramPostiveOrganisms=templateData.GramPostiveOrganisms;
            this.Yeast=templateData.Yeast;
            this.patientInfo.Intended_Use = templateData.Intended_Use;
            this.patientInfo.Test_Method = templateData.Test_Method;
            this.patientInfo.Test_Limitations_Attributes = templateData.Test_Limitations_Attributes
            this.patientInfo.Disclaimer = templateData.Disclaimer
            this.patientInfo.References = templateData.References
        }
      }, 100);
    },
    async addPatientInfo(status) {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        if(this.reportType !=='SEPSIS_PANEL WITH_AMR_GENES_BY_MULTIPLEX_PCR' && this.reportType!=='SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'){
        let stringContent = data?.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent?.trim();
        if (!stringContent) {
          ElMessage.warning("Report content is not given");
          return;
        }
        }
        
        this.loading = true;
        this.loadingText = "Loading...";
        this.patientInfo.typedby = this.getProfileDetails?.first_name
        let params = { ...this.patientInfo };
        if (params.client_code && params.client_code != "") {
          const code = params.client_code.toString();
          params.client_code = code;
        }
        params.test_details = data;
        params.report_status = status;

        params.sub_category = this.reportSubCategory;

        // params.sub_category = this.reportType;
        if (!params.report_template_id) {
          params.report_template_id = null;
        }
        if (this.testId) {
          params.result_test_id = this.testId;
        }
        if(this.reportType == "SEPSIS_PANEL WITH_AMR_GENES_BY_MULTIPLEX_PCR" || this.reportType=='SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'){
          params.GramPostiveOrganisms=[]
        params.GramNegativeOrganisms=[]
        params.Yeast=[]
        params.Anti_microbial_resistance_gene=[]
        params.Drugs_Interpretation=[]
        params.GramPostiveOrganisms= this.GramPostiveOrganisms,
          params.GramNegativeOrganisms=this.GramNegativeOrganisms,
          params.Yeast= this.Yeast,
          params.Anti_microbial_resistance_gene= this.Anti_microbial_resistance_gene,
          params.Drugs_Interpretation= this.Drugs_Interpretation
        }

        if (
          this.showReportEdit &&
          this.editorsData &&
          this.editorsData.length > 0
        ) {
          let multipleTitleData = []
          let multititle = {
            title:"",
            template_body:""
          }
          Array.from(dd).forEach((ckeditorElement, index) => {
            if (ckeditorElement && this.editorsData[index]) {
              multititle.template_body = ckeditorElement.innerHTML || "";
              multititle.title = this.editorsData[index].title || "";
              multipleTitleData.push({ ...multititle }); // Push a copy to the array
            }
          });
          params.template_body_sections = multipleTitleData;
        }
        await this.$store.dispatch("patientReports/addPatientReport", params);
        if (this.getPatientAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Added Successfully",
          });
          await this.goToReports();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Adding Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async handleCheckedChange(event) {
      this.patientInfo.is_required_signature = event;
    },
    async updatePatientInfo(status) {
      try {

        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
      if(this.reportType !=='SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR' && this.reportType!=='SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'){
        let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent.trim();
        if (!stringContent) {
          ElMessage.warning("Report content is not given");
          return;
        }
      }
        this.loading = true;
        this.loadingText = "Loading...";
        let params = { ...this.patientInfo };
        if (params.client_code && params.client_code != "") {
          const code = params.client_code.toString();
          params.client_code = code;
        }
        if (params.age == null) {
          params.age = "";
        }
        if (params.reported_date == null) {
          params.reported_date = "";
        }
        if (params.received_date == null) {
          params.received_date = "";
        }
        params.report_status = this.reasonStatus;

        params.test_details = data;

        params.sub_category = this.reportSubCategory;

        let patientId = this.$route.params.patient_id;
        if (!params.report_template_id) {
          params.report_template_id = null;
        }
        if (this.testId) {
          params.result_test_id = this.testId;
        }
        if (status && status.reason) {
          params.amendment_reason = status.reason
        }
        if (status && status.is_amendment) {
          params.is_amendment = status.is_amendment
        }
        if(this.reportType == "SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR" || this.reportType=='SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'){
          params.GramPostiveOrganisms=[]
        params.GramNegativeOrganisms=[]
        params.Yeast=[]
        params.Anti_microbial_resistance_gene=[]
        params.Drugs_Interpretation=[]
        params.GramPostiveOrganisms= this.GramPostiveOrganisms,
          params.GramNegativeOrganisms=this.GramNegativeOrganisms,
          params.Yeast= this.Yeast,
          params.Anti_microbial_resistance_gene= this.Anti_microbial_resistance_gene,
          params.Drugs_Interpretation= this.Drugs_Interpretation
        }

        if (
          this.showReportEdit &&
          this.editorsData &&
          this.editorsData.length > 0
        ) {
          let multipleTitleData = []
          let multititle = {
            title:"",
            template_body:""
          }
          Array.from(dd).forEach((ckeditorElement, index) => {
            if (ckeditorElement && this.editorsData[index]) {
              multititle.template_body = ckeditorElement.innerHTML || "";
              multititle.title = this.editorsData[index].title || "";
              multipleTitleData.push({ ...multititle }); // Push a copy to the array
            }
          });
          params.template_body_sections = multipleTitleData;
        }
        await this.$store.dispatch("patientReports/updatePatientReport", {
          params,
          patientId,
        });
        if (this.getPatientUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Updated Successfully",
          });
          await this.goToReports();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async askRemoveConfirmation() {
      await ElMessageBox.confirm(
        "Are you want to clear template data. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          await this.updateEditorData({});
        })
        .catch(() => {});
    },
    scrollIntoFirstError() {
      var el = this.$el.getElementsByClassName("error")[0];
      if (el) {
        el.scrollIntoView({ block: "center" });
      }
    },
    fillSampleType(data) {
      const JavaScriptApplicants = this.categoryReportTypes.filter(
        (e) => e.TestId == data
      );

      this.patientInfo.sample_type = JavaScriptApplicants[0].SampleTypeName;
      if (JavaScriptApplicants[0].BarcodeNo) {
        this.patientInfo.barcode_no = JavaScriptApplicants[0].BarcodeNo;
      }
    },
    onCheckSubCategorys() {
      const subCategorys = [
      'PML-RARA QUANTITATIVE' ,'MPN Panel' , 'PML-RARA QUALITATIVE' ,
      'PML_RARA_QUANTITATIVE' , 'MPN_Panel' , 'PML_RARA_QUALITATIVE',
      'JAK2 GENE (Exon 14) V617F MUTATION DETECTION','JAK2_Gene_Exon_14_V617F_Mutation_Detection',
      'BCR-ABL QUANTITATIVE','BCR_ABL_QUANTITATIVE',
      'BCR-ABL QUALITATIVE','BCR_ABL_QUALITATIVE',
      'SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR','SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'
      ];
      return subCategorys.includes(this.reportType);
   },
  },
};
</script>

<style lang="scss">
#add-report-view {
  max-width: 900px;
  margin: 0 auto;
  .reports-title-header {
    display: grid;
    grid-template-columns: 88% auto;
    margin-bottom: 10px;
    .display-end {
      text-align: end;
    }
    .report-title {
      text-align: center;
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }

    .back-btn {
      padding: 9px 15px;
      min-height: auto;
      line-height: 1;
      color: #333;
      border: 1px solid #47525d42;
      background-color: white;

      border-radius: 5px;

      font-weight: 500;
      .el-icon-back {
        margin-right: 4px;
      }
      &:active,
      &:hover,
      &:focus {
        border: 1px solid #47525d42;
      }
    }
  }

  .inner-navbar {
    margin-bottom: 10px;
    .filter-navigation {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }

  .inner {
    margin-bottom: 50px;
    .report-header {
      margin: 0;
      color: #fff;
      font-weight: 600;
      font-size: 20px;
      background: #2eaf9f;
      text-align: center;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    border: 1px solid rgba(7, 162, 149, 0.6);
    border-radius: 10px;
    padding: 0;
    background: #fff;
    overflow: hidden;
    .patient-information {
      .el-form-item {
        margin-bottom: 0;
      }
      .form-mandatory-field {
        .el-form-item__label:after {
          content: "*";
          color: red !important;
          font-size: 15px;
          line-height: 1;
          padding-left: 3px;
        }
      }

      .el-form-item__label {
        min-width: 40%;
        text-align: left;
        background: #e0f3f1;
        padding-left: 10px;
        border-bottom: 1px solid #e0f3f1;
        font-weight: 600;
        font-size: 11px;
        color: #000;
      }

      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }
      .el-input__inner {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-left: none;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        border-radius: 0;
        color: #000;
        font-weight: 500;
        // text-transform: uppercase;
        &::placeholder {
          color: #5a5a5a;
          font-size: 13px;
          text-transform: capitalize;
        }
      }
    }
    .report-sub-title {
      color: #43454b;
      font-size: 18px;
      text-align: center;
      margin-top: -1px;
      padding-top: 1rem;
      border-top: 1.2px solid rgba(0, 0, 0, 0.2);

      font-weight: 600;
    }
    .report-type-edit-info {
      display: flex;
      margin-top: 0.5em;
      padding: 0 10px;
      gap: 5px;
    }
    .editor {
      margin-top: 0.5em;
      padding: 10px;
      .ck-editor__editable {
        min-height: 320px;
      }
    }
    .image__caption_highlighted {
      min-height: auto !important;
    }
    .ck-dropdown__panel {
      max-height: 230px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .ck-placeholder {
      min-height: auto !important;
    }

    .ck-dropdown__panel::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2eaf9f;
    }
    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      margin: 0;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      li {
        &:not(:last-child) {
          margin-right: 20px;
        }
        .el-button {
          padding: 9px 15px;
          min-height: auto;
          line-height: 1;
          &.preview-button {
            background: #28b4a6;
            color: #fff;
            font-weight: 600;
          }
          &.print-button {
            background: #f56c6c !important;
          }
          &.submit-button {
            background: #0061ec;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  figure {
    &.table {
      br {
        display: none;
      }
      br[data-cke-filler="true"] {
        display: block !important;
      }
    }
  }
  .ck {
    ul {
      list-style: disc;
      padding-left: 30px;
      li {
        padding-left: 5px;
        list-style-type: disc !important;
      }
    }
  }
  // .input-error {
  //   .el-input__inner {
  //     border: 1.5px solid red !important;
  //   }
  //   ::placeholder {
  //     color: red !important;
  //     font-weight: 600;
  //   }
  // }
}
.cursor-pointer {
  cursor: pointer;
}
.template-title-dialog {
  .el-dialog__header {
    background-color: #a09b9b6b;
    .el-dialog__close {
      font-size: 20px;
      color: red;
    }
  }
}
.detected-color {
  .el-input__inner {
    color: #dc3545 !important;/* Change this to whatever color you want */
  }
}
.fileUploadArea {
  padding: 10px;
}
</style>