<template>
    <section id="add-report-view" v-loading="loading">
      <div v-if="getSinglePatientReports" class="add-annexure-report">
        <el-form :model="annexureFormData">
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="formGroup">
                <label> Visit ID </label>
                <el-input v-model="annexureFormData.visit_id"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="formGroup">
                <label> Barcode No </label>
                <el-input v-model="annexureFormData.barcode"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="formGroup">
                <label> Patient Name </label>
                <el-input v-model="annexureFormData.patient_name"></el-input>
              </div>
            </el-col>
            <el-col :span="24"> </el-col>
          </el-row>
          <div class="custom-editor">
            <ckeditor
              :editor="editor"
              tag-name="div"
              ref="myEditor"
              v-model="editorData"
              :config="editorConfig"
            >
            </ckeditor>
          </div>
          <ul class="actionButtons">
            <li>
              <el-button @click="cancelPopup()" class="cancel-button"
                >Cancel</el-button
              >
            </li>
            <li>
              <el-button @click="saveAnnexureData()" class="save-button"
                >Save</el-button
              >
            </li>
          </ul>
        </el-form>
      </div>
    </section>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import CKEditor from "@ckeditor/ckeditor5-vue";
  
  import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
  
  import EditorConfig from "@/config/editorConfig";
  import { ElMessage } from "element-plus";
  export default {
    components: {
      ckeditor: CKEditor.component,
    },
  
    data() {
      return {
        loading: false,
        annexureFormData: {
          visit_id: "",
          barcode: "",
          patient_name: "",
        },
        editorData: "",
        editorConfig: EditorConfig,
        editor: ClassicEditor,
      };
    },
    computed: {
      ...mapGetters("patientReports", [
        "getAllPatientReports",
        "getReportDownloadStatus",
        "getReportDownloadUrl",
        "getReportGenerateStatus",
        "getReportApproveStatus",
        "getReportVerifyStatus",
        "getReportDispatchStatus",
        "getWhatsAppReportStatus",
        "getSmsSentStatus",
        "getEmailSentStatus",
        "getDoctorWhatsappSentStatus",
        "getDoctorEmailSentStatus",
        "getAllReports",
        "getResyncLisReport",
        "getResyncResultFileLisReport",
        "getAddAnnexureContentStatus",
        "getAnnexureReportDetails",
        "getUpdateAnnexureDetails",
        "getRemoveAnnexureStatus",
        "getSinglePatientReports",
      ]),
      ...mapGetters("errors", ["getErrors", "getError"]),
    },
    async mounted() {
      if (this.$route.params.patient_id) {
        this.patientId = this.$route.params.patient_id;
        this.fetchSinglePatientInfo();
      }
    },
    methods: {
      async saveAnnexureData() {
        try {
          this.loading = true;
          let dd = window.document.getElementsByClassName("ck-content");
          const data = dd[0]?.innerHTML;
  
          let stringContent = data?.replace(new RegExp("<[^>]*>", "g"), "");
          stringContent = stringContent?.trim();
          if (!stringContent) {
            ElMessage.warning("Report content is not given");
            return;
          }
          let params = this.annexureFormData;
          params.report_id = this.getSinglePatientReports._id;
          params.content = data || "";
          await this.$store.dispatch("patientReports/addAnnexureReport", params);
          if (this.getAddAnnexureContentStatus) {
            this.$notify.success({
              title: "Success",
              message: "Annexure added successfully",
            });
            this.cancelPopup();
          } else {
            this.$notify.error({
              title: "Error",
              message: this.getError
                ? this.getError
                : "Error while adding annexure",
            });
          }
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        } finally {
          this.loading = false;
        }
      },
      cancelPopup() {
        this.$router.push({
          name: "PatientReports",
          query: this.$route.query
        });
      },
      async fetchSinglePatientInfo() {
        this.loading = true;
        try {
          await this.$store.dispatch(
            "patientReports/fetchSinglePatientReports",
            this.$route.params.patient_id
          );
          console.log("prads", this.getSinglePatientReports);
          if (this.getSinglePatientReports) {
            this.annexureFormData.visit_id =
              this.getSinglePatientReports.visit_id || "";
            this.annexureFormData.barcode =
              this.getSinglePatientReports.barcode_no || "";
            this.annexureFormData.patient_name =
              this.getSinglePatientReports.patient_name || "";
          }
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        } finally {
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style lang="scss">
  .add-annexure-report {
    .formGroup {
      label {
        font-size: 14px;
        margin-bottom: 3px;
        font-weight: 500;
        color: #333333;
      }
    }
    .custom-editor {
      margin-top: 20px;
    }
    .actionButtons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
        button {
          color: #fff;
          font-weight: 500;
        }
        .cancel-button {
          background: #f56c6c;
        }
        .save-button {
          background: #0061ec;
        }
      }
    }
  }
  </style>