<template>
  <p class="error" v-if="getErrorData">{{ getErrorData == 'Invalid Report Template'?'Report Template is Required!':getErrorData }}</p>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["errorName"],
  computed: {
    getErrorData() {
      return this.getErrors && this.errorName && this.getErrors[this.errorName]
        ? this.getErrors[this.errorName]
        : "";
    },
    ...mapGetters("errors", ["getErrors"]),
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: red;
  text-align: start;
  margin: 0;
  font-size: 14px;
  padding: 5px;
}
.el-form-item__content {
  .error {
    color: red;
    margin: 0;
    font-size: 12px;
    padding: 5px;
  }
}
</style>
