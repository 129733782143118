import axios from "@/config/axios";

// initial state
const initialState = () => {
  return {
    allBiochemistryTemplates: null,
    singleReportTemplate: null,
    reportTemplateAddStatus: false,
    reportTemplateAddError: null,
    reportTemplateUpdateStatus: null,
    reportTemplateUpdateError: null,
    reportPreview: null,
    reportTemplatePreview: null,
    templateReportData: null,
    biochemistryTemplatesBySubCategory: null,
    allDepartments: null,
    allSubCategories: null,
    allcategories: null,
    templateDeleteStatus: null,
  };
};

const state = initialState();

const getters = {
  getAllBiochemistryTemplates: (state) => state.allBiochemistryTemplates,
  getSingleBiochemistryTemplate: (state) => state.singleReportTemplate,
  getBiochemistryTemplateAddStatus: (state) => state.reportTemplateAddStatus,
  getBiochemistryTemplateUpdateStatus: (state) =>
    state.reportTemplateUpdateStatus,
  getReportPreview: (state) => state.reportPreview,
  getReportTemplatePreview: (state) => state.reportTemplatePreview,
  getTemplateReportData: (state) => state.templateReportData,
  getBiochemistryTemplatesBySubCategory: (state) =>
    state.biochemistryTemplatesBySubCategory,
  getAllDepartments: (state) => state.allDepartments,
  getAllCategories: (state) => state.allCategories,
  getAllSubCategories: (state) => state.allSubCategories,
  getTemplateDeleteStatus: (state) => state.templateDeleteStatus,
};

const mutations = {
  setAllBiochemistryTemplates(state, data) {
    state.allBiochemistryTemplates = data;
  },
  setSingleReportTemplate(state, data) {
    state.singleReportTemplate = data;
  },
  setBiochemistryTemplateAddStatus(state, data) {
    state.reportTemplateAddStatus = data;
  },

  seReportTemplateUpdateStatus(state, data) {
    state.reportTemplateUpdateStatus = data;
  },
  setReportTemplatePreview(state, data) {
    state.reportTemplatePreview = data;
  },
  setReportPreview(state, data) {
    state.reportPreview = data;
  },
  setTemplatePreviewReportData(state, data) {
    state.templateReportData = data;
  },
  setBiochemistryTemplatesBySubCategory(state, data) {
    state.biochemistryTemplatesBySubCategory = data;
  },
  setDepartments(state, data) {
    state.allDepartments = data;
  },
  setCategories(state, data) {
    state.allCategories = data;
  },
  setSubCategories(state, data) {
    state.allSubCategories = data;
  },
  setTemplateDeleteStatus(state, status) {
    state.templateDeleteStatus = status;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  async fetchAllBiochemistryTemplates({ commit, dispatch }, params) {
    try {
      commit("setAllBiochemistryTemplates", null);
      const response = await axios.get("/template-data", { params });

      commit("setAllBiochemistryTemplates", response.data);
    } catch (err) {
      commit("setAllBiochemistryTemplates", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchSingleBiochemistryTemplate({ commit, dispatch }, params) {
    try {
      commit("setSingleReportTemplate", null);
      const response = await axios.get("/template-data/" + params);

      commit("setSingleReportTemplate", response.data.data);
    } catch (err) {
      commit("setSingleReportTemplate", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async updateBiochemistryTemplate(
    { commit, dispatch },
    { params, templateId }
  ) {
    try {
      commit("seReportTemplateUpdateStatus", false);
      await axios.patch("/template-data/" + templateId, params);
      commit("seReportTemplateUpdateStatus", true);
    } catch (err) {
      commit("seReportTemplateUpdateStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async addBiochemistryTemplate({ commit, dispatch }, params) {
    try {
      commit("setBiochemistryTemplateAddStatus", false);
      await axios.post("/template-data", params);
      commit("setBiochemistryTemplateAddStatus", true);
    } catch (err) {
      commit("setBiochemistryTemplateAddStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchTemplatePreview({ commit, dispatch }, params) {
    try {
      commit("setReportPreview", null);
      const response = await axios.get(
        "/template-data/" + params + "/preview-report"
      );
      commit("setReportPreview", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async addTemplateReportPreview({ commit, dispatch }, params) {
    try {
      commit("setReportTemplatePreview", null);
      const response = await axios.post(
        "/template-data/preview-report",
        params
      );
      commit("setReportTemplatePreview", response.data);
    } catch (err) {
      commit("setReportTemplatePreview", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchBiochemistryTemplateBySubCategory({ commit, dispatch }, params) {
    try {
      commit("setBiochemistryTemplatesBySubCategory", null);
      const response = await axios.get("/template-data/details/sub-category", {
        params,
      });

      commit("setBiochemistryTemplatesBySubCategory", response.data);
    } catch (err) {
      commit("setBiochemistryTemplatesBySubCategory", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchAllDepartments({ commit, dispatch }) {
    try {
      commit("setDepartments", null);
      const response = await axios.get("/departments");

      commit("setDepartments", response.data);
    } catch (err) {
      commit("setDepartments", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchAllCategories({ commit, dispatch }) {
    try {
      commit("setCategories", null);
      const response = await axios.get("/categories");

      commit("setCategories", response.data);
    } catch (err) {
      commit("setCategories", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchAllSubCategories({ commit, dispatch }) {
    try {
      commit("setSubCategories", null);
      const response = await axios.get("/sub-categories");

      commit("setSubCategories", response.data);
    } catch (err) {
      commit("setSubCategories", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  async archiveTemplateData({ commit, dispatch }, templateId) {
    try {
      commit("setTemplateDeleteStatus", null);
      const response = await axios.delete(
        `/template-data/${templateId}/archive`
      );
      commit("setTemplateDeleteStatus", response.data);
    } catch (err) {
      commit("setTemplateDeleteStatus", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  addTemplateData({ commit }, params) {
    commit("setTemplatePreviewReportData", params);
  },
  reset: function ({ commit }) {
    commit("reset", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
