<template>
  <section id="all-patient-reports">
    <div class="page-navigation">
      <el-row>
        <el-col :lg="19">
          <ul class="filter-navigation">
            <li>
              <div class="search-filter">
                <el-input placeholder="Search Patient, UHID, Visit ID, Hospital, Doctor, Barcode" v-model="searchString"
                  @input="onSearch" clearable>
                  <!-- <i class="el-input__icon el-icon-search"></i> -->
                </el-input>
              </div>
            </li>
            <li v-if="isAbleToDispatch">
              <div class="status-filter">
                <el-select placeholder="Select Status" v-model="searchStatus" @change="fetchData" clearable filterable>
                  <el-option v-for="status in reportStatus" :key="status" :label="status.label" :value="status.value">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li>
              <div class="category-filter">
                <el-select placeholder="Select Department" v-model="searchDepartment" @change="loadPatientsData"
                  clearable filterable>
                  <el-option v-for="department in getDepartmetns" :key="department" :label="department.label"
                    :value="department.value">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li v-if="searchDepartment == 'RADIOLOGY'">
              <div class="category-filter">
                <el-select placeholder="Select Category" v-model="searchCategory" @change="fetchData" filterable
                  clearable>
                  <el-option v-for="category in reportTypes" :key="category" :label="category" :value="category">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li v-if="searchDepartment == 'GENOMICS'">
              <div class="category-filter">
                <el-select placeholder="Select Category" v-model="searchCategory" @change="fetchData" filterable
                  clearable>
                  <el-option v-for="(report, index) in genomicsReportTypes" :key="index" :label="report.label"
                    :value="report.value">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li v-if="searchDepartment == 'MOLECULAR_GENETICS'">
              <div class="category-filter">
                <el-select placeholder="Select Category" v-model="searchCategory" @change="fetchData" filterable
                  clearable>
                  <el-option v-for="(report, index) in molecularGeneticReportTypes" :key="index" :label="report.label"
                    :value="report.value">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li v-if="searchDepartment == 'FLOW_CYTOMETRY'">
              <div class="category-filter">
                <el-select placeholder="Select Category" v-model="searchCategory" @change="fetchData" filterable
                  clearable>
                  <el-option v-for="(report, index) in cytometryReportTypes" :key="index" :label="report.label"
                    :value="report.value">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li v-if="searchDepartment == 'TRANSPLANT_IMMUNOGENETICS'">
              <div class="category-filter">
                <el-select placeholder="Select Category" v-model="searchCategory" @change="fetchData" filterable
                  clearable>
                  <el-option v-for="(
                      report, index
                    ) in transplantImmunoGeneticsReportTypes" :key="index" :label="report.label" :value="report.value">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li v-if="searchDepartment == 'SEROLOGY_IMMUNOLOGY'">
              <div class="category-filter">
                <el-select placeholder="Select Category" v-model="searchCategory" @change="fetchData" filterable
                  clearable>
                  <el-option v-for="(report, index) in serologyImmunologyReportTypes" :key="index" :label="report.label"
                    :value="report.value">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li v-if="searchDepartment == 'BIOCHEMICAL_GENETICS'">
              <div class="category-filter">
                <el-select placeholder="Select Category" v-model="searchCategory" @change="fetchData" filterable
                  clearable>
                  <el-option v-for="(report, index) in doubleMarkersTypes" :key="index" :label="report.label"
                    :value="report.value">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li v-if="searchDepartment == 'PHARMACO_GENOMICS'">
              <div class="category-filter">
                <el-select placeholder="Select Category" v-model="searchCategory" @change="fetchData" filterable
                  clearable>
                  <el-option v-for="(report, index) in pharmacoGenomicsReports" :key="index" :label="report.label"
                    :value="report.value">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li v-if="searchDepartment == 'CYTOGENETICS'">
              <div class="category-filter">
                <el-select placeholder="Select Category" v-model="searchCategory" @change="fetchData" filterable
                  clearable>
                  <el-option v-for="(report, index) in cytogeneticsCategorys" :key="index" :label="report.label"
                    :value="report.value">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li v-if="searchDepartment == 'CYTOPATHOLOGY'">
              <div class="category-filter">
                <el-select placeholder="Select Category" v-model="searchCategory" @change="fetchData" filterable
                  clearable>
                  <el-option v-for="category in CytopathologyReportTypes" :key="category" :label="category.label"
                    :value="category.value">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li v-if="searchDepartment == 'IMMUNOHEMATOLOGY'">
              <div class="category-filter">
                <el-select
                  placeholder="Select Category"
                  v-model="searchCategory"
                  @change="fetchData"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="category in immunoHematologyReportType"
                    :key="category"
                    :label="category.label"
                    :value="category.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </li>
            <li>
              <div class="category-filter" v-loading="usersloading">
                <el-select placeholder="Approved By" v-model="approved_by" filterable clearable @change="fetchData"
                  :loading="usersloading">
                  <el-option v-for="user in allUsers" :key="user._id" :value="user._id"
                    :label="user.first_name + ' ' + user.last_name"></el-option>
                </el-select>
              </div>
            </li>
            <li>
              <div class="date-filter">
                <el-date-picker v-model="fromDate" type="date" format="DD-MM-YYYY" value-format="YYYY-MM-DD"
                  placeholder="From Date" :disabled-date="disabledDate" @change="fetchDateFilterData"></el-date-picker>
              </div>
            </li>
            <li>
              <div class="date-filter">
                <el-date-picker v-model="toDate" type="date" format="DD-MM-YYYY" value-format="YYYY-MM-DD"
                  placeholder="To Date" :disabled-date="disabledDate" @change="fetchDateFilterData"></el-date-picker>
              </div>
            </li>
          </ul>
        </el-col>
        <el-col :lg="5">
          <ul class="action-navigation">
            <li v-if="isAbleToAccess('ADD_PATIENT_REPORT')">
              <el-dropdown>
                <el-button class="default-button add-button">
                  Add Patient Report
                  <el-icon>
                    <Plus />
                  </el-icon>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <router-link :to="{
          path: '/patient-reports/bio-chemistry/add',
          query: $route.query,
        }" v-if="isAbleToAddBiochemistry">
                      <el-dropdown-item>Bio-Chemistry</el-dropdown-item>
                    </router-link>
                    <router-link :to="{
          path: '/patient-reports/biochemical-genetics/add',
          query: $route.query,
        }" v-if="isAbleToAddBiochemicalGenetics">
                      <el-dropdown-item>Biochemical Genetics</el-dropdown-item>
                    </router-link>
                    <router-link :to="{
          path: '/corporate-reports',
          query: $route.query,
        }" v-if="isAbleToAddHisto">
                      <el-dropdown-item>Corporate</el-dropdown-item>
                    </router-link>
                    <router-link :to="{
          path: '/patient-reports/Cytogenetics/add',
          query: $route.query,
        }" v-if="isAbleToAddCytogenetics">
                      <el-dropdown-item>Cytogenetics</el-dropdown-item>
                    </router-link>

                    <router-link :to="{
          path: '/patient-reports/Cytopathology/add',
          query: $route.query,
        }" v-if="isAbleToCytopathology">
                      <el-dropdown-item>Cytopathology</el-dropdown-item>
                    </router-link>

                    
                    <router-link :to="{
          name: 'CytometryReport',
          query: $route.query,
        }" v-if="isAbleToAddCytometry">
                      <el-dropdown-item>Flow Cytometry</el-dropdown-item>
                    </router-link>
                    <router-link :to="{
          name: 'GenomicsReport',
          query: $route.query,
        }" v-if="isAbleToAddGenomics">
                      <el-dropdown-item>Genomics</el-dropdown-item>
                    </router-link>
                    <router-link :to="{
          path: '/patient-reports/histo-pathology/add',
          query: $route.query,
        }" v-if="isAbleToAddHisto">
                      <el-dropdown-item>Histopathology</el-dropdown-item>
                    </router-link>
                    <router-link :to="{
                      path: '/patient-reports/ihc-histo-pathology/add',
                      query: $route.query,
                    }" v-if="isAbleToAddIHCHisto">
                      <el-dropdown-item>IHC - HISTOPATHOLOGY</el-dropdown-item>
                    </router-link>
                    <router-link :to="{
                      path: '/patient-reports/immuno-hematology/add',
                      query: $route.query,
                    }" v-if="isAbleToAddImmunoHematology">
                      <el-dropdown-item>ImmunoHematology</el-dropdown-item>
                    </router-link>                    
                    <router-link :to="{
          path: '/patient-reports/MolecularDiagnostics/add',
          query: $route.query,
        }" v-if="isAbleToCytopathology">
                      <el-dropdown-item>Molecular Diagnostics</el-dropdown-item>
                    </router-link>
                    <router-link :to="{
          name: 'MolecularGeneticsReport',
          query: $route.query,
        }" v-if="isAbleToAddMolecularGenetics">
                      <el-dropdown-item>Molecular Genetics</el-dropdown-item>
                    </router-link>
                    <router-link :to="{
          name: 'PharmacoGenomicsReport',
          query: $route.query,
        }" v-if="isAbleToAddPharmacoGenomics">
                      <el-dropdown-item>Pharmacogenomics</el-dropdown-item>
                    </router-link>
                    <router-link :to="{
          path: '/patient-reports/add',
          query: $route.query,
        }" v-if="isAbleToAdd">
                      <el-dropdown-item>Radiology</el-dropdown-item>
                    </router-link>

                    <router-link :to="{
          name: 'SerologyReport',
          query: $route.query,
        }" v-if="isAbleToAddSerology">
                      <el-dropdown-item>Serology / Immunology</el-dropdown-item>
                    </router-link>
                    <router-link :to="{
          name: 'ImmunogeneticsReport',
          query: $route.query,
        }" v-if="isAbleToAddImmunogenetics">
                      <el-dropdown-item>Transplant Immunogenetics</el-dropdown-item>
                    </router-link>
                    

                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <div v-loading="loading" :element-loading-text="loadingText">
      <div class="vue-table">
        <el-table :data="reportsData" style="width: 100%" height="calc(100vh - 250px)" class="table-list display-web"
          @sort-change="changeSort">
          <el-table-column prop="created_at" label="Date" width="180" sortable="custom" fixed>
            <template #default="scope">
              <span class="date" v-if="scope.row.created_at">
                {{ date(scope.row.created_at) }}
                {{ time(scope.row.created_at) }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="uhid" label="UHID" width="150" sortable="custom" fixed>
            <template #default="scope">
              {{ scope.row.uhid ? scope.row.uhid : "--" }}
            </template>
          </el-table-column>
          <el-table-column prop="visit_id" label="Visit ID" width="150" sortable="custom">
            <template #default="scope">
              {{ scope.row.visit_id ? scope.row.visit_id : "--" }}
            </template>
          </el-table-column>
          <el-table-column prop="barcode_no" label="Barcode" width="150" sortable="custom">
            <template #default="scope">
              {{ scope.row.barcode_no ? scope.row.barcode_no : "--" }}
            </template>
          </el-table-column>
          <el-table-column prop="patient_name" label="Patient" min-width="200" sortable="custom">
            <template #default="scope">
              <span class="doctor-name">
                {{ scope.row.patient_name ? scope.row.patient_name : "--" }}
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="gender" label="Age" width="120">
            <template #default="scope">
              <span v-if="scope.row.age">
                <span v-if="scope.row.category == 'BIOCHEMICAL_GENETICS'">
                  {{ formatAge(scope.row.age) }}
                </span>
                <span v-else>
                  {{ formatAge(scope.row.age) }}
                </span>
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="department" label="Department" width="300" sortable="custom">
            <template #default="scope">
              {{
          scope.row.department ? scope.row.department : "--"
        }}</template>
          </el-table-column>
          <el-table-column prop="sub_category" label="Category" min-width="300" sortable="custom">
            <template #default="scope">
              <span v-if="scope.row.category == 'GENOMICS'">
                {{
          `${scope.row.sub_category
            ? molecularGenomicsReportType[scope.row.sub_category]
            : scope.row.sub_category || "--"
          }`
        }}
              </span>
              <span v-else-if="scope.row.category == 'FLOW_CYTOMETRY'">
                {{
          `${scope.row.sub_category
            ? flowCytometryReportType[scope.row.sub_category]
            : scope.row.sub_category || "--"
          }`
        }}
              </span>
              <span v-else-if="scope.row.category == 'MOLECULAR_GENETICS'">
                {{
          `${scope.row.sub_category
            ? molecularGeneticsReportType[scope.row.sub_category]
            : scope.row.sub_category || "--"
          }`
        }}
              </span>
              <span v-else-if="scope.row.category == 'TRANSPLANT_IMMUNOGENETICS'">
                {{
          `${scope.row.sub_category
            ? transplantImmunoGeneticsReportTypeMap[
            scope.row.sub_category
            ]
            : scope.row.sub_category || "--"
          }`
        }}
              </span>
              <span v-else-if="scope.row.category == 'SEROLOGY_IMMUNOLOGY'">
                {{
          `${scope.row.sub_category
            ? serologyImmunologyReportTypeMap[scope.row.sub_category]
            : scope.row.sub_category || "--"
          }`
        }}
              </span>
              <span v-else-if="scope.row.category == 'BIOCHEMICAL_GENETICS'">
                {{
          `${scope.row.sub_category
            ? doubleMarkersReportType[scope.row.sub_category]
            : scope.row.sub_category || "--"
          }`
        }}
              </span>
              <span v-else-if="scope.row.category == 'PHARMACO_GENOMICS'">
                {{
          `${scope.row.sub_category
            ? pharmacoGenomicsReportType[scope.row.sub_category]
            : scope.row.sub_category || "--"
          }`
        }}
              </span>
              <span v-else-if="scope.row.category == 'CYTOPATHOLOGY'">
                {{
          `${scope.row.sub_category
            ? CytopathalogyMap[scope.row.sub_category]
            : scope.row.sub_category || "--"
          }`
        }}
              </span>
              <!-- <span v-else-if="scope.row.category == 'BIO_CHEMISTRY'">
                {{
                  `${
                    scope.row.sub_category
                      ? bioChemistryReportType[scope.row.sub_category]
                      : scope.row.sub_category || "--"
                  }`
                }}
              </span> -->
              <span v-else-if="scope.row.category == 'IMMUNOHEMATOLOGY'">
                {{
                  `${
                    scope.row.sub_category
                      ? immunoHematologyReportTypes[scope.row.sub_category]
                      : scope.row.sub_category || "--"
                  }`
                }}
              </span>
              <span v-else>
                {{ `${scope.row.sub_category || "--"}` }}
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="approved_by.first_name" label="Approved by" width="230" sortable="custom">
            <template #default="scope">
              {{
          scope.row.approved_by ? scope.row.approved_by.first_name + ' ' + scope.row.approved_by.last_name : "--"
        }}</template>
          </el-table-column>
          <el-table-column prop="hospital_name" label="Hospital" width="230" sortable="custom">
            <template #default="scope">
              {{
          scope.row.hospital_name ? scope.row.hospital_name : "--"
        }}</template>
          </el-table-column>
          <el-table-column prop="ref_doctor" label="Doctor" width="230" sortable="custom">
            <template #default="scope">
              {{ scope.row.ref_doctor ? scope.row.ref_doctor : "--" }}</template>
          </el-table-column>
          <el-table-column label="Dispatched" width="130">
            <template #default="{ row }">
              <div class="column-status not-approved" v-if="!row.report_status ||
          row.report_status != 'APPROVED' ||
          !isAbleToDispatch
          ">
                <el-tag :type="row.is_dispatched ? 'success' : 'warning'">{{
          row.is_dispatched ? "YES" : "NO"
        }}</el-tag>
              </div>
              <el-dropdown @command="(command) => dispatchReport(row, command)" v-else>
                <div class="column-status">
                  <el-tag :type="row.is_dispatched ? 'success' : 'warning'">{{
          row.is_dispatched ? "Yes" : "No"
        }}</el-tag>
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :command="true" :disabled="row.is_dispatched">
                      Yes
                    </el-dropdown-item>
                    <el-dropdown-item :command="false" :disabled="!row.is_dispatched">
                      No
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>

          <el-table-column prop="report_status" label="Status" width="125" fixed="right" sortable="custom">
            <template #default="scope">
              <div class="column-status">
                <span v-if="scope.row.report_status">
                  <el-tag :type="getStatusTagType(scope.row.report_status)">{{
          scope.row.report_status
        }}</el-tag>
                </span>
                <span v-else>--</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Actions" fixed="right" :width="getActionsWidth">
            <template #default="{ row }">
              <ul class="table-action-buttons">
                <li>
                  <el-button class="view-icon" @click="onView(row)">
                    <img src="@/assets/images/icons/eye.svg" alt="" class="img-fluid" />
                  </el-button>
                </li>

                <!-- <li v-if="isAbleToVerify && row.report_status == 'PENDING'">
                  <el-button class="warning-btn" @click="verifyReport(row)">
                    Verify
                  </el-button>
                </li> -->
                <!-- <li v-if="isAbleToApprove && row.report_status == 'PENDING'">
                  <el-button class="view-icon" @click="approveReport(row)">
                    <img
                      src="@/assets/images/icons/approve.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li> -->
                <!-- <li v-if="isAbleToVerify && generate(row)">
                  <el-dropdown
                    @command="(command) => regenerateReport(row, command)"
                  >
                    <el-button class="view-icon">
                      <img
                        src="@/assets/images/icons/Re-Generate.svg"
                        alt=""
                        class="img-fluid"
                      />
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          command="all"
                          :disabled="disableAllGenerateOption(row)"
                        >
                          All
                        </el-dropdown-item>
                        <el-dropdown-item
                          command="includeHeader"
                          :disabled="disableHeaderReportGenerateOption(row)"
                          >With Header And Footer</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="excludeHeader"
                          :disabled="
                            disableWithoutHeaderReportGenerateOption(row)
                          "
                          >Without Header And Footer</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="includeHeaderNonColor"
                          :disabled="
                            disableBlackWhiteHeaderReportGenerateOption(row)
                          "
                          >Black and White With Header And
                          Footer</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="excludeHeaderNonColor"
                          :disabled="
                            disableBlackWhiteWithoutHeaderReportGenerateOption(
                              row
                            )
                          "
                          >Black and White Without Header And
                          Footer</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
        </el-dropdown>
        </li> -->
                <!-- <li v-if="checkIsAbleToDownload(row)">
                  <el-dropdown
                    @command="(command) => downloadReports(row, command)"
                  >
                    <el-button class="view-icon"
                      ><img
                        src="@/assets/images/icons/download.svg"
                        alt=""
                        class="img-fluid"
                      />
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          command="includeHeader"
                          :disabled="disableHeaderReportDownloadOption(row)"
                          >With Header And Footer</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="excludeHeader"
                          :disabled="
                            disableWithoutHeaderReportDownloadOption(row)
                          "
                        >
                          Without Header And Footer
                        </el-dropdown-item>
                        <el-dropdown-item
                          command="includeHeaderNonColor"
                          :disabled="
                            disableBlackWhiteHeaderReportDownloadOption(row)
                          "
                        >
                          Black and White With Header And Footer
                        </el-dropdown-item>
                        <el-dropdown-item
                          command="excludeHeaderNonColor"
                          :disabled="
                            disableBlackWhiteWithoutHeaderReportDownloadOption(
                              row
                            )
                          "
                        >
                          Black and White Without Header And Footer
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </li>
                <li
                  v-else-if="
                    row.report_generate_status === 'PROCESSING' &&
                    row.exclude_header_report_generate_status ===
                      'PROCESSING' &&
                    row.non_color_report_generate_status === 'PROCESSING' &&
                    row.non_color_exclude_header_report_generate_status ===
                      'PROCESSING'
                  "
                >
                  <el-tooltip content="Processing">
                    <el-button class="view-icon">
                      <img
                        src="@/assets/images/icons/download.svg"
                        alt="Download"
                        class="img-fluid"
                      />
                    </el-button>
                  </el-tooltip>
                </li> -->
                <!-- v-else -->
                <li>
                  <el-dropdown
                    @command="(command) => row.sub_category !== 'Yoda_Shishu_Samraksha_pannel' ? checkDownloadReport(row, command) : checkShishuSamrakshadata(row, command)">
                    <el-button class="view-icon">
                      <!-- @/assets/images/icons/download_before.svg -->
                      <img src="@/assets/images/icons/download.svg" alt="" class="img-fluid" />
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="includeHeader">With Header And Footer</el-dropdown-item>
                        <el-dropdown-item command="excludeHeader">Without Header And Footer</el-dropdown-item>
                        <el-dropdown-item command="includeHeaderNonColor">Black and White With Header And
                          Footer</el-dropdown-item>
                        <el-dropdown-item command="excludeHeaderNonColor">Black and White Without Header And
                          Footer</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </li>
                <li v-if="checkWhatsAppSchedule(row)">
                  <el-tooltip :content="row.whatsapp_notification_sent_on
          ? dateTime(row.whatsapp_notification_sent_on)
          : ''
          " placement="top">
                    <el-button class="view-icon">
                      <img src="@/assets/images/icons/whatsapp-schedule.svg" alt="" class="img-fluid" />
                    </el-button>
                  </el-tooltip>
                </li>
                <li v-if="checkWhatsAppSentStatus(row)">
                  <el-tooltip :content="row.whatsapp_failed_reason
          ? row.whatsapp_failed_reason
          : ''
          " placement="top">
                    <el-button class="view-icon" @click="sendWhatsAppReports(row)">
                      <img src="@/assets/images/icons/whatsappUnsent.svg" alt="" class="img-fluid" />
                    </el-button>
                  </el-tooltip>
                </li>
                <li v-if="checkReportCanSendToWhatsApp(row)">
                  <el-button class="view-icon" @click="sendWhatsAppReports(row)">
                    <img src="@/assets/images/icons/whatsappSent.svg" alt="" class="img-fluid" />
                  </el-button>
                </li>
                <li v-if="checkEmailSentStatus(row)">
                  <el-tooltip :content="row.email_failed_reason ? row.email_failed_reason : ''
          " placement="top">
                    <el-button class="view-icon" @click="sendReportsToEmail(row)">
                      <img src="@/assets/images/icons/email-unsent.png" alt="" class="img-fluid" />
                    </el-button>
                  </el-tooltip>
                </li>
                <li v-if="checkReportCanSendToEmail(row)">
                  <el-button class="view-icon" @click="sendReportsToEmail(row)">
                    <img src="@/assets/images/icons/email-sent.png" alt="" class="img-fluid" />
                  </el-button>
                </li>
                <li v-if="checkSmsSentStatus(row)">
                  <el-tooltip :content="row.sms_failed_reason ? row.sms_failed_reason : ''
          " placement="top">
                    <el-button class="view-icon" @click="sendReportsToSms(row)">
                      <img src="@/assets/images/icons/msg-unsent.png" alt="" class="img-fluid" />
                    </el-button>
                  </el-tooltip>
                </li>
                <li v-if="checkReportCanSendToSms(row)">
                  <el-button class="view-icon" @click="sendReportsToSms(row)">
                    <img src="@/assets/images/icons/msg-sent.png" alt="" class="img-fluid" />
                  </el-button>
                </li>
                <li v-if="checkDoctorWhatsApp(row) &&
          !isApollo(row) &&
          !isSelfReferrence(row)
          ">
                  <el-button @click="openDoctorWhatsappDialog(row)" title="Doctor Whatsapp">
                    <img src="@/assets/images/icons/doctorWhatsappUnsent.svg" alt="" class="img-fluid" />
                  </el-button>
                </li>
                <li v-if="checkDoctorWhatsAppSentStatus(row) &&
          !isApollo(row) &&
          !isSelfReferrence(row)
          ">
                  <el-button @click="openDoctorWhatsappDialog(row)" title="Doctor Whatsapp">
                    <img src="@/assets/images/icons/doctorWhatappSent.svg" alt="" class="img-fluid" />
                  </el-button>
                </li>
                <li v-if="checkDoctorEmail(row) &&
          !isApollo(row) &&
          !isSelfReferrence(row)
          ">
                  <el-button @click="openDoctorEmailDialog(row)" title="Doctor Email">
                    <img src="@/assets/images/icons/doctorMailUnsent.svg" alt="" class="img-fluid" />
                  </el-button>
                </li>
                <li v-if="checkDoctorEmailSentStatus(row) &&
          !isApollo(row) &&
          !isSelfReferrence(row)
          ">
                  <el-button @click="openDoctorEmailDialog(row)" title="Doctor Email">
                    <img src="@/assets/images/icons/doctorMailSent.svg" alt="" class="img-fluid" />
                  </el-button>
                </li>
                <!-- <li v-if="checkSyncLisReport(row)">
                  <el-button class="view-icon" @click="syncLisReports(row)">
                    <img
                      src="@/assets/images/icons/syncing-right.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li> -->
                <li v-if="checkResyncLisReport(row)">
                  <el-tooltip :content="row.lis_sync_message ? row.lis_sync_message : ''" placement="top">
                    <el-button class="view-icon" @click="syncLisReports(row)">
                      <img src="@/assets/images/icons/syncing-wrong.svg" alt="" class="img-fluid" />
                    </el-button>
                  </el-tooltip>
                </li>
                <!-- <li v-if="checkResultFileSyncLisReport(row)">
                  <el-button
                    class="view-icon"
                    @click="syncResultFilesToLisReports(row)"
                  >
                    <img
                      src="@/assets/images/icons/syncing-right.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li> -->
                <li v-if="checkResultFileResyncLisReport(row)">
                  <el-tooltip :content="row.lis_sync_message ? row.lis_sync_message : ''" placement="top">
                    <el-button class="view-icon" @click="syncResultFilesToLisReports(row)">
                      <img src="@/assets/images/icons/syncing-wrong.svg" alt="" class="img-fluid" />
                    </el-button>
                  </el-tooltip>
                </li>
                <li v-if="checkWholeExomeExist(row)">
                  <el-button
                    v-if="row && row.annexure_report_id"
                    class="view-icon"
                    @click="viewAnnexure(row)"
                    title="VIEW ANNEXURE"
                  >
                    <el-icon><View /></el-icon>
                  </el-button>
                  <el-button
                    v-else
                    class="view-icon"
                    @click="addAnnexure(row)"
                    title="ADD ANNEXURE"
                  >
                    <el-icon><Plus /></el-icon>
                  </el-button>
                </li>
                <li v-if="checkHLATypingExist(row)">
                  <el-button
                    v-if="row && row.form_v_path"
                    class="view-icon"
                    @click="previewFormV(row.form_v_path)"
                    title="PREVIEW FORM V"
                  >
                    <el-icon><View /></el-icon>
                  </el-button>
                  <el-button
                    v-else
                    class="view-icon"
                    @click="uploadFormVReport(row)"
                    title="ADD FORM V REPORT"
                  >
                    <el-icon><Plus /></el-icon>
                  </el-button>
                </li>
                <li v-if="checkHLATypingExist(row)">
                  <el-button
                    v-if="row && row.form_v_path"
                    class="view-icon"
                    @click="removeFileFormVReport(row)"
                    title="REMOVE FORM V"
                  >
                    <el-icon><Delete /></el-icon>
                  </el-button>
                </li>
                <li v-if="checkHLATypingExist(row)">
                  <el-button
                    v-if="row && row.form_v_path"
                    class="view-icon"
                    @click="uploadFormVReport(row)"
                    title="UPDATE FORM V REPORT"
                  >
                    <el-icon><Edit /></el-icon>
                  </el-button>
                </li>
                <li v-if="isAbleToSyncPatientDemographics">
                  <el-button
                    class="view-icon"
                    @click="syncPatientDemograpics(row._id)"
                    title="SYNC PATIENT DEMOGRAPHICS"
                  >
                  <img src="@/assets/images/regenerate.svg" alt="logo" class="img-fluid" />
                  </el-button>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="vue-pagination" v-if="reportsData && reportsData.length">
        <el-pagination @size-change="handleSizeChange" @current-change="loadData" :page-sizes="[10, 25, 50, 100]"
          :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" :current-page="page">
        </el-pagination>
      </div>
    </div>
    <div v-if="selectedReport" class="all-patient-reports-popup">
      <el-dialog width="30%" v-model="openWhatsappDialogVisible" :title="`Send ${selectedReport.visit_id || ''
          } Report  to Doctor Whatsapp`" :before-close="handleClose" class="">
        <div class="input-d-flex">
          <p class="name">Doctor Name :</p>
          <span class="value">
            {{ selectedReport.ref_doctor ? selectedReport.ref_doctor : "" }}
          </span>
        </div>
        <div class="input-d-flex">
          <p class="name">Phone No</p>
          <el-input placeholder="Please enter phone number" v-model="doctorPhone" maxLength="10"></el-input>
          <p class="err" v-if="!isPhonePatternMatch">
            Enter Valid Phone Number
          </p>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="medium" class="el-white" type="info" plain @click="handleClose">Cancel</el-button>
            <el-button size="medium" type="primary" class="submit-btn" @click="sendReportToDoctorWhatsapp"
              :loading="buttonLoading" :disabled="checkPhoneNumber">Submit</el-button>
          </span>
        </template>

        <FormError errorName="critical_error"></FormError>
      </el-dialog>
      <el-dialog width="30%" v-model="openEmailDialog"
        :title="`Send ${selectedReport.visit_id || ''} Report to Doctor Email`" :before-close="handleOnClose" class="">
        <div class="input-d-flex">
          <p class="name">Doctor Name :</p>
          <span class="value">
            {{ selectedReport ? selectedReport.ref_doctor : "" }}
          </span>
        </div>
        <div class="input-d-flex">
          <p class="name">Email</p>

          <el-input placeholder="Please enter email" v-model="doctorEmail"></el-input>
          <p class="err" v-if="!isPatternMatch">Invalid Email</p>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="medium" class="el-white" type="info" plain @click="handleOnClose">Cancel</el-button>
            <el-button size="medium" type="primary" @click="sendReportToDoctorEmail" :loading="buttonLoading"
              :disabled="checkEmail">Submit</el-button>
          </span>
        </template>

        <FormError errorName="critical_error"></FormError>
      </el-dialog>
    </div>
    <div v-if="formVploadPopup" class="all-patient-reports-popup">
      <el-dialog
        width="35%"
        v-model="formVploadPopup"
        :title="
          this.formVData && this.formVData.form_v_path
            ? 'UPDATE FORM V REPORT'
            : 'ADD FORM V REPORT'
        "
        :before-close="handleFormVClose"
      >
        <div class="popup-date">
          <div class="upload-files-alignment-block">
            <div class="upload-files-alignment">
              <el-upload
                class="upload-demo"
                drag
                action
                :on-change="handleUpload"
                :show-file-list="false"
                :auto-upload="false"
                accept=".pdf"
                :limit="1"
                v-model:file-list="fileList"
                :multiple="false"                
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload-text-align">
                  <div class="el-upload__text-img">
                    <img
                      src="@/assets/images/icons/attachments-icon.svg"
                      alt=""
                    />
                  </div>
                  <div class="el-upload__text">
                    <h3>Drag & drop file here</h3>
                    <p>your file here, or browse</p>
                  </div>
                </div>
              </el-upload>
            </div>
          </div>
          <div class="sample-attachment-upload-file-block">
            <div v-if="formVFilePath">
              <div class="revenue-attachment" v-for="(file, index) in fileList" :key="index">
                <img src="@/assets/images/icons/attachments_1.svg" alt="" />
                <p>
                  {{ file.name }}
                </p>
                <el-button type="text" @click="removeFile(index)" class="btn-close">
                  <img
                    class="hover"
                    src="@/assets/images/icons/attachment-success.svg"
                    alt=""
                  />
                  <img
                    src="@/assets/images/icons/remove-button.svg"
                    alt=""
                    class="without-hover"
                  />
                </el-button>
              </div>
            </div>
          </div>
          <div class="dialog-footer" v-if="this.formVFilePath">
            <el-button
              class="defaultButton cancelBtn"
              type="info"
              plain
              @click="handleFormVClose"
              >Cancel</el-button
            >
            <el-button
              class="defaultButton"
              @click="uploadFileFormVReport"
              :loading="formButtonLoading"
              >Upload</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import {
  reportStatus,
  reportCategory,
  departments,
} from "@/constants/formConstants";
import moment from "moment";
import { Search } from "@element-plus/icons-vue";
import {
  radiologyReportTypes,
  molecularGenomicsType,
  molecularGeneticsType,
  cytometryType,
  doubleMarkersTypes,
  transplantImmunoGeneticsMap,
  transplantImmunoGeneticsType,
  molecularGenomicsReportTypeMap,
  molecularGeneticsReportTypeMap,
  flowCytometryReportTypeMap,
  doubleMarkersReportTypeMap,
  serologyReportMap,
  serologyReportTypes,
  pharmacoGenomicsReportTypeMap,
  pharmacoGenomicsType,
  biochemistryReportTypeMap,
  CytopathalogyMap,
  CytopathalogyTypes,
  cytogeneticsCategorys,
  immunoHematologyReportTypeMap,
  immunoHematologyTypes
} from "@/constants/formConstants";
import userRolesPermissionsMixin from "@/mixins/userRolesPermissionsMixin";
import { errorNotification } from "@/helpers/notifications";
export default {
  middleware: "auth",
  mixins: [userRolesPermissionsMixin],
  computed: {
    ...mapGetters("patientReports", [
      "getAllPatientReports",
      "getReportDownloadStatus",
      "getReportDownloadUrl",
      "getReportGenerateStatus",
      "getReportApproveStatus",
      "getReportVerifyStatus",
      "getReportDispatchStatus",
      "getWhatsAppReportStatus",
      "getSmsSentStatus",
      "getEmailSentStatus",
      "getDoctorWhatsappSentStatus",
      "getDoctorEmailSentStatus",
      "getAllReports",
      "getResyncLisReport",
      "getResyncResultFileLisReport",
      "getAddFormVReportStatus",
      "getFormVReportPreview",
      "getRemoveFormVReportStatus",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getAllUsers",
      "getProfileDetails",
    ]),
    ...mapGetters("resultFiles", ["getResultFileReportDownloadStatus","getImageUploadData"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    isPatternMatch() {
      if (!this.doctorEmail) return true;
      const pattern = new RegExp(
        /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
      );
      return pattern.test(this.doctorEmail);
    },
    isPhonePatternMatch() {
      if (!this.doctorPhone) return true;

      const pattern = new RegExp(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
      );
      return pattern.test(this.doctorPhone);
    },

    isApollo() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          (report.client_code == "115" ||
            (report.hospital_name &&
              report.hospital_name.toUpperCase().indexOf("APOLLO") > -1))
        ) {
          return true;
        }
        return false;
      };
    },
    isSelfReferrence() {
      return (report) => {
        if (report.report_status == "APPROVED" && report.ref_doctor == "SELF") {
          return true;
        }
        return false;
      };
    },
    getApprovedByNames() {
      // eslint-disable-next-line no-debugger
      debugger
      return this.reportsData.map((data) => {
        if (data.approved_by) {
          return data.approved_by;
        }
      });
    },
    checkReportCanSendToWhatsApp() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.whatsapp_sent_status == true &&
          report.whatsapp_notification_status == "SUCCESS" &&
          (report.mobile || report.alternate_mobile)
        ) {
          return true;
        }
        return false;
      };
    },
    checkWhatsAppSentStatus() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          (report.whatsapp_sent_status == false ||
            report.whatsapp_notification_status == "FAILED")
        ) {
          return true;
        }
        return false;
      };
    },
    checkWhatsAppSchedule() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.whatsapp_sent_status == true &&
          report.whatsapp_notification_status == "SCHEDULED"
        ) {
          return true;
        }
        return false;
      };
    },
    checkDoctorWhatsApp() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.report_sent_to_doctor_whatsapp == false
        ) {
          return true;
        }
        return false;
      };
    },
    checkDoctorWhatsAppSentStatus() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.report_sent_to_doctor_whatsapp == true
        ) {
          return true;
        }
        return false;
      };
    },
    checkDoctorEmail() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.report_sent_to_doctor_email == false
        ) {
          return true;
        }
        return false;
      };
    },
    checkDoctorEmailSentStatus() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.report_sent_to_doctor_email == true
        ) {
          return true;
        }
        return false;
      };
    },
    checkEmail() {
      if (this.doctorEmail && this.isPatternMatch) {
        return false;
      }
      return true;
    },
    checkPhoneNumber() {
      if (
        this.doctorPhone &&
        this.doctorPhone.length == 10 &&
        this.isPhonePatternMatch
      ) {
        return false;
      }
      return true;
    },
    checkReportCanSendToEmail() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.email_sent_status == true &&
          report.email
        ) {
          return true;
        }
        return false;
      };
    },
    checkEmailSentStatus() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.email_sent_status == false
        ) {
          return true;
        }
        return false;
      };
    },
    checkReportCanSendToSms() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.sms_sent_status == true &&
          (report.mobile || report.alternate_mobile)
        ) {
          return true;
        }
        return false;
      };
    },
    checkSmsSentStatus() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.sms_sent_status == false
        ) {
          return true;
        }
        return false;
      };
    },
    checkSyncLisReport() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.lis_sync_status == true &&
          report.category != "BIOCHEMICAL_GENETICS"
        ) {
          return true;
        }
        return false;
      };
    },
    checkResultFileSyncLisReport() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.lis_sync_status == true &&
          report.category == "BIOCHEMICAL_GENETICS"
        ) {
          return true;
        }
        return false;
      };
    },
    checkResyncLisReport() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.lis_sync_status == false &&
          report.category != "BIOCHEMICAL_GENETICS" &&
          report.report_source_from && report.report_source_from != 'RESULT_FILE'
        ) {
          return true;
        }
        return false;
      };
    },
    checkResultFileResyncLisReport() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          report.lis_sync_status == false &&
          report.category == "BIOCHEMICAL_GENETICS" &&
          report.report_source_from && report.report_source_from == 'RESULT_FILE'
        ) {
          return true;
        }
        return false;
      };
    },
    isAbleToDispatch() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        ["FRONT_DESK", "HELP_DESK"].indexOf(
          this.getAuthenticatedUser.user_type
        ) != -1
      ) {
        return false;
      }
      return true;
    },
    isAbleToVerify() {
      if (
        this.getProfileDetails &&
        this.getProfileDetails.user_type &&
        [
          "ADMIN",
          "LAB_DIRECTOR",
          "LAB_PHYSICIAN",
          "RADIOLOGIST",
          "DOCTOR",
        ].indexOf(this.getProfileDetails.user_type) != -1 &&
        this.getProfileDetails.allow_to_access.indexOf("VERIFY") != -1
      ) {
        return true;
      }
      return false;
    },
    isAbleToApprove() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        ["ADMIN", "LAB_DIRECTOR", "RADIOLOGIST", "DOCTOR"].indexOf(
          this.getAuthenticatedUser.user_type
        ) != -1
      ) {
        return true;
      }
      return false;
    },
    isAbleToAdd() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes("RADIOLOGY")
      ) {
        return true;
      }
      return false;
    },
    isAbleToAddBiochemistry() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes("BIO_CHEMISTRY")
      ) {
        return true;
      }
      return false;
    },

    isAbleToAddBiochemicalGenetics() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes("BIOCHEMICAL_GENETICS")
      ) {
        return true;
      }
      return false;
    },
    isAbleToAddHisto() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes("HISTO_PATHOLOGY")
      ) {
        return true;
      }
      return false;
    },
    isAbleToAddIHCHisto() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes("IHC_HISTOPATHOLOGY")
      ) {
        return true;
      }
      return false;
    },
    isAbleToCytopathology() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes("CYTOPATHOLOGY")
      ) {
        return true;
      }
      return false;
    },
    isAbleToAddGenomics() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes("GENOMICS")
      ) {
        return true;
      }
      return false;
    },
    isAbleToAddCytogenetics() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes("CYTOGENETICS")
      ) {
        console.log(this.getAuthenticatedUser.departments);
        return true;
      }
      return false;
    },

    allUsers() {
      const allUsersList = this.getAllUsers;
      return allUsersList?.filter(item => {
        return item.user_type === 'DOCTOR' || item.user_type === 'RADIOLOGIST';
      });
    },


    isAbleToAddMolecularGenetics() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes("MOLECULAR_GENETICS")
      ) {
        return true;
      }
      return false;
    },
    isAbleToAddCytometry() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes("FLOW_CYTOMETRY")
      ) {
        return true;
      }
      return false;
    },
    isAbleToAddImmunogenetics() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes(
          "TRANSPLANT_IMMUNOGENETICS"
        )
      ) {
        return true;
      }
      return false;
    },
    isAbleToAddSerology() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes("SEROLOGY_IMMUNOLOGY")
      ) {
        return true;
      }
      return false;
    },
    isAbleToAddPharmacoGenomics() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes("PHARMACO_GENOMICS")
      ) {
        return true;
      }
      return false;
    },
    getDepartmetns() {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.departments) {
        const userDepartments = this.getAuthenticatedUser.departments;
        return this.departmentTypes.filter((obj) =>
          userDepartments.includes(obj.value)
        );
      }
      return this.departmentTypes;
    },
    getActionsWidth() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        this.widthByRole[this.getAuthenticatedUser.user_type]
      ) {
        return this.widthByRole[this.getAuthenticatedUser.user_type];
      }
      return 200;
    },
    getStatusTagType() {
      return (status) => {
        if (status && this.tagByStatus && this.tagByStatus[status]) {
          return this.tagByStatus[status];
        }
        return;
      };
    },

    disableWithoutHeaderReportGenerateOption() {
      return (report) => {
        if (
          (report.exclude_header_report_generate_status === "COMPLETED" &&
            report.exclude_header_report_path) ||
          report.exclude_header_report_generate_status === "PROCESSING"
        ) {
          return true;
        }

        return false;
      };
    },
    disableHeaderReportGenerateOption() {
      return (report) => {
        if (
          (report.report_generate_status === "COMPLETED" &&
            report.report_path) ||
          report.report_generate_status === "PROCESSING"
        ) {
          return true;
        }

        return false;
      };
    },
    disableBlackWhiteHeaderReportGenerateOption() {
      return (report) => {
        if (
          (report.non_color_report_generate_status === "COMPLETED" &&
            report.non_color_report_path) ||
          report.non_color_report_generate_status === "PROCESSING"
        ) {
          return true;
        }

        return false;
      };
    },
    disableBlackWhiteWithoutHeaderReportGenerateOption() {
      return (report) => {
        if (
          (report.non_color_exclude_header_report_generate_status ===
            "COMPLETED" &&
            report.non_color_exclude_header_report_path) ||
          report.non_color_exclude_header_report_generate_status ===
          "PROCESSING"
        ) {
          return true;
        }

        return false;
      };
    },
    disableAllGenerateOption() {
      return (report) => {
        if (
          (report.report_generate_status == "" ||
            report.report_generate_status == "FAILED" ||
            (!report.report_path &&
              report.report_generate_status != "PROCESSING")) &&
          (report.exclude_header_report_generate_status == "FAILED" ||
            report.exclude_header_report_generate_status == "" ||
            (!report.exclude_header_report_path &&
              report.exclude_header_report_generate_status != "PROCESSING")) &&
          (report.non_color_report_generate_status == "FAILED" ||
            report.non_color_report_generate_status == "" ||
            (!report.non_color_report_path &&
              report.non_color_report_generate_status != "PROCESSING")) &&
          (report.non_color_exclude_header_report_generate_status == "FAILED" ||
            report.non_color_exclude_header_report_generate_status == "" ||
            (!report.non_color_exclude_header_report_path &&
              report.non_color_exclude_header_report_generate_status !=
              "PROCESSING"))
        ) {
          return false;
        }
        return true;
      };
    },
    generate() {
      return (report) => {
        if (
          report.report_status == "APPROVED" &&
          (report.report_generate_status == "" ||
            report.report_generate_status == "FAILED" ||
            report.exclude_header_report_generate_status == "FAILED" ||
            report.exclude_header_report_generate_status == "" ||
            (!report.report_path &&
              report.report_generate_status != "PROCESSING") ||
            (!report.exclude_header_report_path &&
              report.exclude_header_report_generate_status != "PROCESSING"))
        ) {
          return true;
        }
        return false;
      };
    },
    checkIsAbleToDownload() {
      return (report) => {
        if (report.report_path || report.exclude_header_report_path) {
          return true;
        }
        return false;
      };
    },
    disableHeaderReportDownloadOption() {
      return (report) => {
        if (
          !report.report_path &&
          report.report_generate_status != "PROCESSING"
        )
          return true;
        return false;
      };
    },
    disableWithoutHeaderReportDownloadOption() {
      return (report) => {
        if (
          !report.exclude_header_report_path &&
          report.exclude_header_report_generate_status != "PROCESSING"
        )
          return true;
        return false;
      };
    },

    disableBlackWhiteHeaderReportDownloadOption() {
      return (report) => {
        if (
          !report.non_color_report_path &&
          report.non_color_report_generate_status != "PROCESSING"
        )
          return true;
        return false;
      };
    },
    disableBlackWhiteWithoutHeaderReportDownloadOption() {
      return (report) => {
        if (
          !report.non_color_exclude_header_report_path &&
          report.non_color_exclude_header_report_generate_status != "PROCESSING"
        )
          return true;
        return false;
      };
    },
    checkWholeExomeExist() {
      return (report) => {
        if (
          report &&
          report.report_status == "PENDING" &&
          report.category == "GENOMICS" &&
          report.sub_category == "WHOLE_EXOME_SEQUESNCING"
        ) {
          return true;
        }
        return false;
      };
    },
    checkHLATypingExist() {
      return (report) => {
        if (
          report &&
          report.report_status == "PENDING" &&
          report.category == "TRANSPLANT_IMMUNOGENETICS" &&
          report.sub_category == "HLA_TYPING_A_B_DRB_1"
        ) {
          return true;
        }
        return false;
      };
    },  
    isAbleToAddImmunoHematology() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.departments &&
        this.getAuthenticatedUser.departments.includes("IMMUNOHEMATOLOGY")
      ) {
        return true;
      }
      return false;
    },
    isAbleToSyncPatientDemographics() {
      if (
        this.getProfileDetails &&
        this.getProfileDetails.user_type &&
        [
          "SUPER_ADMIN",
        ].indexOf(this.getProfileDetails.user_type) != -1 
      ) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      reportTypes: radiologyReportTypes,
      genomicsReportTypes: molecularGenomicsType,
      molecularGeneticReportTypes: molecularGeneticsType,
      cytometryReportTypes: cytometryType,
      transplantImmunoGeneticsReportTypes: transplantImmunoGeneticsType,
      transplantImmunoGeneticsReportTypeMap: transplantImmunoGeneticsMap,
      molecularGenomicsReportType: molecularGenomicsReportTypeMap,
      molecularGeneticsReportType: molecularGeneticsReportTypeMap,
      flowCytometryReportType: flowCytometryReportTypeMap,
      doubleMarkersReportType: doubleMarkersReportTypeMap,
      doubleMarkersTypes: doubleMarkersTypes,
      serologyImmunologyReportTypeMap: serologyReportMap,
      serologyImmunologyReportTypes: serologyReportTypes,
      reportStatus: reportStatus,
      reportCategory: reportCategory,
      departmentTypes: departments,
      pharmacoGenomicsReportType: pharmacoGenomicsReportTypeMap,
      pharmacoGenomicsReports: pharmacoGenomicsType,
      bioChemistryReportType: biochemistryReportTypeMap,
      CytopathalogyMap: CytopathalogyMap,
      CytopathologyReportTypes: CytopathalogyTypes,
      cytogeneticsCategorys:cytogeneticsCategorys,
      dialogVisible: false,
      headerFooter: "",
      userRoleDept: [],
      reportsData: [],
      page: 1,
      pageSize: 10,
      total: 0,
      orderBy: "",
      orderType: "",
      searchIcon: Search,
      sortCodeMap: {
        ascending: "asc",
        descending: "desc",
      },
      searchString: "",
      searchStatus: "",
      searchCategory: "",
      searchDepartment: "",
      approved_by: "",
      collectionDate: "",
      fromDate: "",
      toDate: "",
      widthByRole: {
        LAB_PHYSICIAN: 250,
        LAB_ASSISTANT: 210,
        DATA_OPERATOR: 290,
        LAB_DIRECTOR: 290,
        RADIOLOGIST: 290,
        DOCTOR: 290,
        ADMIN: 290,
        SUPER_ADMIN:290,
        LAB_MANAGER:290
      },
      tagByStatus: {
        APPROVED: "success",
        VERIFIED: "warning",
        INITIATE: "primary",
        DRAFT: "info",
        PENDING: "warning",
      },
      generateParams: {
        includeHeader: false,
        excludeHeader: false,
        includeHeaderNonColor: false,
        excludeHeaderNonColor: false,
      },
      downloadParams: {
        includeHeader: {
          include_header_footer: true,
          is_color_variant: true,
        },
        excludeHeader: {
          include_header_footer: false,
          is_color_variant: true,
        },
        includeHeaderNonColor: {
          include_header_footer: true,
          is_color_variant: false,
        },
        excludeHeaderNonColor: {
          include_header_footer: false,
          is_color_variant: false,
        },
      },
      loading: false,
      usersloading: false,
      loadingText: "",
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      openWhatsappDialogVisible: false,
      selectedReport: null,
      doctorPhone: "",
      doctorEmail: "",
      buttonLoading: false,
      openEmailDialog: false,
      usersData: [],
      formVData: {},
      formVploadPopup: false,
      s3Url: process.env.VUE_APP_S3_BUCKET_URL,
      formVFilePath: "",
      formButtonLoading: false,
      fileList: [],
      immunoHematologyReportTypes:immunoHematologyReportTypeMap,
      immunoHematologyReportType:immunoHematologyTypes
    };
  },
  async mounted() {
    this.$store.dispatch("errors/clear");
    await this.setQueryToData();
    await this.fetchAllReports();
    await this.fetchAllUsers();
  },
  methods: {
    syncPatientDemograpics(patient){
      this.$router.push({
        name:"SyncPatientDemographics",
        params: {patient_id:patient},
        query: this.$route.query,
      })
    },
    removeFile(index) {
      this.fileList.splice(index, 1);
    },
    async removeFileFormVReport(formVData) {
      try {
        this.loading = true;
        let params = {
          patientId: formVData._id,
          form_v_path: formVData.form_v_path,
        };
        await this.$store.dispatch("patientReports/deleteFormVReport", params);
        if (this.getRemoveFormVReportStatus) {
          this.$notify.success({
            title: "Success",
            message: "Form V deleted successfully",
          });
          await this.fetchAllReports();
        } else {
          this.$notify.error({
            title: "Success",
            message: "Error while deleting form V",
          });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async previewFormV(formpath) {
      let routerData = this.$router.resolve({
        name: "FormVReportPreview",
        params: { path: formpath },
      });
      window.open(routerData.href, "_blank");
    },
    async uploadFileFormVReport() {
      try {
        this.formButtonLoading = true;
        let params = {
          patientId: this.formVData._id,
          form_v_path: this.formVFilePath,
        };
        await this.$store.dispatch(
          "patientReports/updateFormVReportPath",
          params
        );
        if (this.getAddFormVReportStatus) {
          this.$notify.success({
            title: "Success",
            message: "Form V added successfully",
          });
          this.handleFormVClose();
          await this.fetchAllReports();
        } else {
          this.$notify.error({
            title: "Success",
            message: "Error while uploading form V",
          });
        }
        this.formButtonLoading = false;
      } catch (err) {
        this.formButtonLoading = false;
        console.log(err);
      } finally {
        this.formButtonLoading = false;
      }
    },
    handleFormVClose() {
      this.formVFilePath = "";
      this.formVData = null;
      this.formVploadPopup = false;
      this.fileList = []
    },

    async handleUpload(file) {
      this.fileList[0] = file;
      const reader = new FileReader();
      reader.readAsDataURL(file.raw);
      let params = {
        file: file.name,
        file_type: file.raw.type,
      };
      await this.$store.dispatch("resultFiles/uploadImage", { params, file });

      if (this.getImageUploadData) {
        this.formVFilePath = this.getImageUploadData.path;
      } else {
        errorNotification(this.getError || "Error while regenerating graph");
      }
    },
    uploadFormVReport(patientData) {
      this.formVData = patientData;
      this.formVploadPopup = true;
    },
    async viewAnnexure(annexure) {
      this.$router.push({
        name: "ViewAnnexureReport",
        params: { annexure_id: annexure.annexure_report_id },
        query: this.$route.query,
      });
    },
    addAnnexure(annexure) {
      this.$router.push({
        name: "AddAnnexureReport",
        params: { patient_id: annexure._id },
        query: this.$route.query,
      });
    },
    async fetchAllUsers() {
      try {
        this.usersloading = true;
        let params = {
          get_all: true,
          order_by: "first_name",
          order_type: "asc",
          status: "ACTIVE",
        };
        await this.$store.dispatch("auth/fetchAllUsers", params);
        if (this.getAllUsers) {
          this.usersData = this.getAllUsers.filter(
            (user) => user.user_type === "DOCTOR"
          );
        }
        this.usersloading = false;
      } catch (err) {
        this.usersloading = false;
        console.log(err);
      }
    },


    formatAge(ageString) {
      const agePattern = /^\d+\sY\s\d+\sM\s\d+\sD$/;
      const simpleAgePattern = /^\d+\s(Y|M|D)$/;
      if (!agePattern.test(ageString) && !simpleAgePattern.test(ageString)) {
      const ageComponents = ageString.split(" ");
      const years = parseInt(ageComponents[0]);
      const months = parseInt(ageComponents[2]);
      const days = parseInt(ageComponents[4]);

      if (years > 0 && months > 0 && days > 0 || years > 0 && months > 0) {
        return `${years} Y ${months} M`; // Display year and month
      } else if (months > 0 && days == 0 || months > 0 && days > 0) {
        return `${months} M`; // Display month and day
      } else if (years > 0 && months === 0 && days === 0) {
        return `${years} Y`; // Display only year
      }
      else if (years == 0 && months == 0 && days > 0) {
        return `${days} D`
      }

      else if (years > 0) {
        return `${years} Y`
      }
      return ageString; // Display as is
    }else{
      return ageString
    }
    },

    date(value) {
      if (!value) return;
      return moment(value).format("DD-MM-yyyy");
    },
    time(value) {
      if (!value) return;
      return moment(value).format("hh:mm a");
    },
    dateTime(value) {
      if (!value) return;
      return moment(value).format("DD-MM-yyyy hh:mm a");
    },
    goToAddForm() {
      this.$router.push({ name: "Report" });
    },
    async fetchDateFilterData() {
      if (this.fromDate && this.toDate) {
        await this.fetchData();
      } else if (!this.fromDate && !this.toDate) {
        this.fetchData();
      }
    },
    converToUpperCase(str) {
      if (!str) return;
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    },
    goToTemplates() {
      this.$router.pus("/all-report-temaples");
    },
    async fetchAllReports() {
      try {
        this.loading = true;
        this.loadingText = "Fetching Patient Reports...";
        let params = this.prepareParams();
        if (
          (this.getAuthenticatedUser &&
            this.getAuthenticatedUser.user_type &&
            this.getAuthenticatedUser.user_type == "FRONT_DESK") ||
          this.getAuthenticatedUser.user_type == "HELP_DESK"
        ) {
          params.report_status = "APPROVED";
        }
        await this.$store.dispatch(
          "patientReports/fetchAllPatientReports",
          params
        );
        this.setTableData();
        this.setQueryData(params);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async onSearch() {
      setTimeout(async () => {
        this.page = 1;
        await this.fetchAllReports();
      }, 600);
    },

    setTableData() {
      const tableData = JSON.parse(JSON.stringify(this.getAllPatientReports));
      this.reportsData =
        tableData && tableData.data && tableData.data.length
          ? tableData.data
          : [];
      this.page = tableData.page || 1;
      this.total = tableData.total || 0;
      this.pageSize = tableData.limit || 10;
    },
    prepareParams() {
      let params = { page: this.page, limit: this.pageSize };

      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }

      if (this.searchString) {
        params.search_string = this.searchString;
      }
      if (this.searchStatus) {
        params.report_status = this.searchStatus;
      }
      if (this.searchCategory) {
        params.sub_category = this.searchCategory;
      }
      if (this.searchDepartment) {
        params.category = this.searchDepartment;
      }
      if (this.approved_by) {
        params.approved_by = this.approved_by;
      }
      if (this.fromDate && this.toDate) {
        params.from_date = this.fromDate + " " + "00:00:00";

        params.to_date = this.toDate + " " + "23:59:59";

        params.from_date = moment(params.from_date)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
        params.to_date = moment(params.to_date)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
      }
      return params;
    },

    setQueryData(data) {
      let params = {
        selectedPage: data.page || 1,
        selectedPageSize: data.limit || 10,
        selectedString: data.search_string || undefined,
        selectedStatus: data.report_status || undefined,
        selectedCategory: data.sub_category || undefined,
        selectedFromDate: this.fromDate || undefined,
        selectedToDate: this.toDate || undefined,
        selectedUser: data.approved_by || undefined,
        selectedDepartment: data.category || undefined,
      };

      this.$router
        .replace({
          name: this.$route.name,
          query: { ...params },
        })
        .catch(() => { });
    },
    setQueryToData() {
      if (this.$route.query) {
        this.page = this.$route.query.selectedPage
          ? parseInt(this.$route.query.selectedPage)
          : 1;
        this.pageSize = this.$route.query.selectedPageSize
          ? parseInt(this.$route.query.selectedPageSize)
          : 10;
        this.searchString = this.$route.query.selectedString || "";
        this.searchStatus = this.$route.query.selectedStatus || "";

        this.searchCategory = this.$route.query.selectedCategory || "";
        this.searchDepartment = this.$route.query.selectedDepartment || "";
        this.fromDate = this.$route.query.selectedFromDate || "";
        this.toDate = this.$route.query.selectedToDate || "";
        this.approved_by = this.$route.query.selectedUser || "";
      }
    },
    changeSort({ prop, order }) {
      this.orderBy = prop;
      this.orderType = this.sortCodeMap[order];
      this.page = 1;
      this.fetchAllReports();
    },
    async handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      await this.fetchAllReports();
    },
    async loadData(page) {
      this.page = page;
      await this.fetchAllReports();
    },
    fetchData() {
      // this.searchCategory = "";
      this.page = 1;
      this.fetchAllReports();
    },
    async loadPatientsData() {
      this.searchCategory = "";
      this.page = 1;
      await this.fetchAllReports();
    },
    onView(patient) {
      // this.dialogVisible = true;
      if (
        patient.category == "BIOCHEMICAL_GENETICS" &&
        (patient.sub_category == "TMS" ||
          patient.sub_category == "URINE_ORGANIC_ACID" ||
          patient.sub_category == "DOUBLE_MARKERS" ||
          patient.sub_category == "TRIPLE_MARKERS" ||
          patient.sub_category == "QUADRUPLE_MARKERS" ||
          patient.report_source_from == "RESULT_FILE")
      ) {
        this.$router.push({
          name: "PatientResultFilePreview",
          params: {
            patient_id: patient.result_file_id,
            result_file_id: patient.test_result_file_id,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } else {
        this.$router.push({
          name: "PatientReportView",
          params: {
            patient_id: patient._id,
          },
          query: this.$route.query,
        });
      }
    },
    goToPreview() {
      console.log(this.headerFooter);
    },
    async sendWhatsAppReports(patient) {
      try {
        this.loading = true;
        this.loadingText = "Sending Report To Patient...";

        await this.$store.dispatch(
          "patientReports/sendWhatsAppPatientReports",
          {
            reportId: patient._id,
          }
        );
        this.loading = false;
        if (this.getWhatsAppReportStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Send Successfully to Patient Whatsapp",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError ? this.getError : "Error in Sending Report",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async sendReportsToEmail(report) {
      try {
        this.loading = true;
        this.loadingText = "Sending Report To Patient...";
        await this.$store.dispatch("patientReports/sendPatientReportsbyEmail", {
          reportId: report._id,
        });
        this.loading = false;
        if (this.getEmailSentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Send to Patient Email Successfully",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError ? this.getError : "Error in Sending Report",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async sendReportsToSms(report) {
      try {
        this.loading = true;
        this.loadingText = "Sending Report To Patient...";
        await this.$store.dispatch("patientReports/sendPatientReportsbySms", {
          reportId: report._id,
        });
        this.loading = false;

        if (this.getSmsSentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Send Successfully to Patient by SMS",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError ? this.getError : "Error in Sending Report",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async syncLisReports(report) {
      try {
        this.loading = true;
        this.loadingText = "Re-Syncing Report To Patient...";
        let params = {
          reportId: report._id,
        };
        await this.$store.dispatch("patientReports/syncLisReport", params);
        this.loading = false;

        if (
          this.getResyncLisReport &&
          this.getResyncLisReport.success &&
          this.getResyncLisReport.success == true
        ) {
          this.$notify.success({
            title: "Success",
            message: "Patient report lis status updated successfully",
          });
          setTimeout(() => {
            window.location.reload();
          }, 600);
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getResyncLisReport
              ? this.getResyncLisReport.message
              : "Patient report lis status updated failed",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async syncResultFilesToLisReports(report) {
      try {
        this.loading = true;
        this.loadingText = "Re-Syncing Report To Patient...";
        let params = {
          result_file_id: report.test_result_file_id,
          patient_id: report.result_file_id,
        };
        await this.$store.dispatch(
          "patientReports/syncResultFileLisReport",
          params
        );
        this.loading = false;

        if (
          this.getResyncResultFileLisReport &&
          this.getResyncResultFileLisReport.success &&
          this.getResyncResultFileLisReport.success == true
        ) {
          this.$notify.success({
            title: "Success",
            message: "Patient report lis status updated successfully",
          });
          setTimeout(() => {
            window.location.reload();
          }, 600);
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getResyncResultFileLisReport
              ? this.getResyncResultFileLisReport.message
              : "Patient report lis status updated failed",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    openDoctorWhatsappDialog(report) {
      this.selectedReport = report;
      this.openWhatsappDialogVisible = true;
    },
    openDoctorEmailDialog(report) {
      this.selectedReport = report;
      this.openEmailDialog = true;
    },

    async sendReportToDoctorEmail() {
      try {
        this.buttonLoading = true;
        let params = {
          name: this.selectedReport.ref_doctor,
          email: this.doctorEmail || "",
        };
        await this.$store.dispatch("patientReports/sendReportToDoctorEmail", {
          params,
          reportId: this.selectedReport._id,
        });
        this.buttonLoading = false;
        if (this.getDoctorEmailSentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report send to doctor email successfully",
          });

          this.fetchAllReports();
          this.handleOnClose();
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError ? this.getError : "Error in Sending Report",
          });
        }
      } catch (err) {
        this.buttonLoading = false;
        console.log(err);
      }
    },
    async sendReportToDoctorWhatsapp() {
      try {
        this.buttonLoading = true;
        let params = {
          name: this.selectedReport.ref_doctor,
          phone: this.doctorPhone || "",
        };
        await this.$store.dispatch(
          "patientReports/sendReportToDoctorWhatsapp",
          {
            params,
            reportId: this.selectedReport._id,
          }
        );
        this.buttonLoading = false;
        if (this.getDoctorWhatsappSentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Send Successfully to Doctor Whatsapp",
          });
          this.fetchAllReports();

          this.handleClose();
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError ? this.getError : "Error in Sending Report",
          });
        }
      } catch (err) {
        this.buttonLoading = false;
        console.log(err);
      }
    },
    handleClose() {
      this.openWhatsappDialogVisible = false;
      this.doctorPhone = "";
    },
    handleOnClose() {
      this.doctorEmail = "";
      this.openEmailDialog = false;
    },
    async downloadReports(patient, status) {
      try {
        let params = {
          patientId: patient._id,
        };

        if (this.downloadParams[status]) {
          params = { ...params, ...this.downloadParams[status] };
        }

        // params.include_header_footer = status;
        this.loading = true;
        this.loadingText = "Downloading Report...";
        await this.$store.dispatch(
          "patientReports/downloadPatientReports",
          params
        );

        if (
          this.getReportDownloadUrl &&
          this.getReportDownloadUrl.success &&
          this.getReportDownloadUrl.data
        ) {
          var tmpLink = document.createElement("a");
          tmpLink.href = this.getReportDownloadUrl.data;

          // temporarily add link to body and initiate the download
          document.body.appendChild(tmpLink);
          tmpLink.click();
          document.body.removeChild(tmpLink);

          this.$notify.success({
            title: "Success",
            message: "Patient Report Downloaded Successfully",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Download Failed",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    checkDownloadReport(patient, status) {
      if (
        patient.category == "BIOCHEMICAL_GENETICS" &&
        patient.test_result_file_id
      ) {
        this.downloadBioChemicalProvisionalReport(patient, status);
      } else {
        this.downloadProvisionalReport(patient, status);
      }
    },
    async downloadProvisionalReport(patient, status) {
      try {
        let params = {
          patientId: patient._id,
        };

        if (this.downloadParams[status]) {
          params = { ...params, ...this.downloadParams[status] };
        }
        // params.include_header_footer = status;
        this.loading = true;
        this.loadingText = "Downloading Report...";
        await this.$store.dispatch(
          "patientReports/downloadProvosionalPatientReport",
          params
        );

        if (this.getReportDownloadStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Downloaded Successfully",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Download Failed",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async downloadBioChemicalProvisionalReport(patient, status) {
      try {
        let params = {
          patientId: patient.result_file_id,
          resultFileId: patient.test_result_file_id,
        };

        if (this.downloadParams[status]) {
          params.params = { ...this.downloadParams[status] };
        }
        // params.include_header_footer = status;
        this.loading = true;
        this.loadingText = "Downloading Report...";
        await this.$store.dispatch(
          "resultFiles/downloadPatientResultFileReport",
          params
        );

        if (this.getResultFileReportDownloadStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Downloaded Successfully",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Download Failed",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async regenerateReport(patient, command) {
      try {
        this.loading = true;
        this.loadingText = "Regenerating Report...";
        let params = { patientId: patient._id, ...this.generateParams };
        // if (command && command == "all") {
        //   params.includeHeader = true;
        //   params.excludeHeader = true;
        // } else if (command && command == "true") {
        //   params.includeHeader = true;
        //   params.excludeHeader = false;
        // } else {
        //   params.includeHeader = false;
        //   params.excludeHeader = true;
        // }
        Object.keys(this.generateParams).forEach((keyVal) => {
          if (
            Object.keys(params).indexOf(keyVal) != -1 &&
            (keyVal == command || command == "all")
          ) {
            params[keyVal] = true;
          }
        });

        await this.$store.dispatch(
          "patientReports/generatePatientReports",
          params
        );
        if (this.getReportGenerateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Regenerated Successfully",
          });
          await this.fetchAllReports();
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Error While Regenerating",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
      // await this.renameAndDownloadFile(url, report.title);
    },

    async approveReport(patient) {
      try {
        this.loading = true;
        this.loadingText = "Report Approving...";
        let params = { patientId: patient._id };
        params.includeHeader = true;

        await this.$store.dispatch(
          "patientReports/approvePatientReport",
          params
        );
        if (this.getReportApproveStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Approved Successfully",
          });
          await this.fetchAllReports();
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Error While Approving",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async dispatchReport(patientReport, status) {
      try {
        this.loading = true;
        this.loadingText = `Report ${status ? "dispatching" : "undispatching"
          }...`;
        let status1 = null;
        if (status == "false") {
          status1 = false;
        }
        if (status == "true") {
          status1 = true;
        }
        let params = {
          patientId: patientReport._id,
          params: { is_dispatched: status1 },
        };
        await this.$store.dispatch(
          "patientReports/dispatchPatientReport",
          params
        );
        if (this.getReportDispatchStatus) {
          this.$notify.success({
            title: "Success",
            message: `Report ${status ? "Dispatched" : "Undispatched"
              } Successfully`,
          });
          await this.fetchAllReports();
        } else {
          this.$notify.error({
            title: "Error",
            message:
              this.getError ||
              `Error While ${status ? "Dispatching" : "Undispatching"}`,
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async verifyReport(patient) {
      try {
        this.loading = true;
        this.loadingText = "Verifying Report...";
        await this.$store.dispatch(
          "patientReports/verifyPatientReport",
          patient._id
        );
        if (this.getReportVerifyStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Verified Successfully",
          });
          await this.fetchAllReports();
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Error While Verifiying",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
  },
  beforeUnmount() {
    this.$store.commit("patientReports/setAllPatientReports", null);
  },
};
</script>

<style lang="scss">
#all-patient-reports {
  tbody {
    td {
      &:last-child {
        background: #f5f5f5;
      }
    }
  }
}

.filter-navigation {
  li {
    margin-right: 10px;

    .status-filter {
      min-width: 120px;
    }

    .category-filter {
      min-width: 150px;
    }
  }
}

// popup styles
.all-patient-reports-popup {
  .el-dialog {
    border-radius: 10px;

    .el-dialog__header {
      font-size: 20px;
      padding: 10px 10px 5px;
      background: #2eaf9f;
      border-radius: 10px 10px 0 0;

      .el-dialog__title {
        color: #ffffff;
        font-weight: 500;
      }
    }

    .el-dialog__headerbtn {
      border: 1px solid #dcdce4;
      border-radius: 3px;
      padding: 0 5px;
      top: 10px;
      right: 10px;

      .el-dialog__close {
        color: #ffffff;
        font-weight: 700;
      }
    }

    .el-dialog__body {
      padding-bottom: 10px;
      padding-top: 10px;

      .input-d-flex {
        display: grid;
        grid-template-columns: 125px auto;
        align-items: center;
        margin: 10px 0;

        .name {
          color: #000000;
          font-size: 16px;
          margin: 0;
          font-weight: 600;
        }

        .value {
          font-weight: 600;
          color: #219388;
        }

        .el-input__inner {
          width: 80%;
        }
      }
    }

    .dialog-footer {
      .submit-btn {
        background: #0061ec;
        color: #fff;
        font-weight: 600;
        border: none;
      }
    }

    .err {
      color: red;
      white-space: nowrap;
    }
  }
}
</style>
