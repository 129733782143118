<template>
    <div class="niemannPickABLeukocytes">
        <div class="edit-box mb-20">
            <span class="title">CLINICAL HISTORY</span>
            <ckeditor :editor="editor" v-model="Clinical_history" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INDICATION</span>
            <ckeditor :editor="editor" v-model="indication" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">LEUKOCYTE ENZYME ACTIVITY</span>
            <el-table :data="Leukocyte_Enzyme_Activity" style="width: 100%">
               <el-table-column prop="Test" label="Test (Units)" width="180" >
                <template v-slot="scope">
                    <el-input v-model="scope.row.Test" type="textarea" autosize placeholder="Enter Test (units)"></el-input>
                </template>
               </el-table-column>
               <el-table-column label="Disorder" width="180">
                <template v-slot="scope">
                  <el-input v-model="scope.row.Disorder" placeholder="Enter Disorder"  type="textarea" autosize></el-input>
               </template>
               </el-table-column>
               <el-table-column label="Results" width="130">
               <template v-slot="scope">
                  <el-input v-model="scope.row.Result" placeholder="Enter Result"  type="textarea" autosize></el-input>
               </template>
               </el-table-column>
               <el-table-column prop="Reference_interval" label="Reference Interval(Mean ± SD)" width="230">
                <template v-slot="scope">
                        <el-input v-model="scope.row.Reference_interval"  type="textarea" autosize placeholder="Enter Reference Interval(Mean ± SD)"></el-input>
                    </template>
               </el-table-column>
               <el-table-column prop="Status" label="Status" width="150">
                    <template v-slot="scope">
                        <el-select placeholder="Status" :clearable="true" :filterable="true" v-model="scope.row.Status">
                            <el-option label="Normal" value="Normal"></el-option>
                            <el-option label="Deficient" value="Deficient"></el-option>
                            <el-option label="Low" value="Low"></el-option>
                            <el-option label="Elevated" value="Elevated"></el-option> 
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope"
                    v-if="this.patientInfo?.from == undefined">
                    <el-row :gutter="12">
                        <el-col :span="6" v-if="scope.row.sno === Leukocyte_Enzyme_Activity?.length">
                            <el-buttton @click="onAddItem" class="c-pointer"><el-icon :size="20">
                                    <CirclePlus />
                                </el-icon></el-buttton>
                        </el-col>
                        <el-col :span="6" v-if="Leukocyte_Enzyme_Activity?.length > 1"><el-buttton
                                @click="onDeleteItem(scope.row)"><el-icon :size="19">
                                    <Delete style="color: red;" />
                                </el-icon></el-buttton>
                        </el-col>
                    </el-row>

                </el-table-column>
            </el-table>
        </div>
        <div class="edit-box mb-20">
            <span class="title">METHOD</span>
            <ckeditor :editor="editor" v-model="Method" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RESULT</span>
            <ckeditor :editor="editor" v-model="Result" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INTERPRETATION</span>
            <ckeditor :editor="editor" v-model="Interpretation" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RECOMMENDATION</span>
            <ckeditor :editor="editor" v-model="recommendations" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">COMMENT</span>
            <ckeditor :editor="editor" v-model="comment" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig" v-model="Note">
            </ckeditor>
        </div>
   </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";


export default{
    components:{
        ckeditor: CKEditor.component,
    },
    props: {
    niemannPickABLeukocytes: {
      type: Array,
      required: true
    },
    patientInfo:{
        type:Object
    }
   },
    data(){
        return{
            editor: ClassicEditor,
            editorData: '',
            show: false,
            impression: "",
            resultData: "",
            references: "",
            remarks: "",
            editorConfig: EditorConfig,
            textarea: "",
            indication:this.niemannPickABLeukocytes?.length ==1?this.niemannPickABLeukocytes[0].indication:"",
            comment:this.niemannPickABLeukocytes?.length ==1?this.niemannPickABLeukocytes[0].comment:"",
            recommendations:this.niemannPickABLeukocytes?.length ==1?this.niemannPickABLeukocytes[0].recommendations:"",
            Clinical_history:this.niemannPickABLeukocytes?.length ==1?this.niemannPickABLeukocytes[0].Clinical_history:"",
            Interpretation:this.niemannPickABLeukocytes?.length==1  ?this.niemannPickABLeukocytes[0].Interpretation:"Normal enzyme activity of sphingomyelinase in leukocytes may rule out the possibility of Niemann Pick Disease A/B.",
            Method:this.niemannPickABLeukocytes?.length==1  ?this.niemannPickABLeukocytes[0].Method:"Artificial Fluorogenic substrates.",
            Note:this.niemannPickABLeukocytes?.length==1  ?this.niemannPickABLeukocytes[0].Note:"It is presumed that the specimen used to perform the test belongs to the patient specified above, such verification having been carried out at the collection level of sample. Please correlate with clinical findings and other test findings.",
            Result:this.niemannPickABLeukocytes?.length ==1?this.niemannPickABLeukocytes[0].Result:"A normal activity of sphingomyelinase is obtained in peripheral blood leukocytes.", 
            Leukocyte_Enzyme_Activity:this.niemannPickABLeukocytes?.length ==1?this.niemannPickABLeukocytes[0].Leukocyte_Enzyme_Activity:[
                {
              sno: 1,
              Test: 'Sphingomyelinase (nmol/17h/mg)',
              Disorder: 'Niemann Pick A/B',
              Result: '',
              Reference_interval: '4.1 - 14 (7.1 ± 2.8)',
              Status: 'Normal',
                },
                {
              sno: 2,
              Test: '* β-Galactosidase (nmol/h/mg)',
              Disorder: 'GM1-gangliosidosis',
              Result: '',
              Reference_interval: '85 - 300 (142 ± 59)',
              Status: 'Normal',
                },              
            ],         
            signature_required:(this.patientInfo && this.patientInfo.is_required_signature) ? this.patientInfo.is_required_signature : false,   
        }
        
    },   
    methods: {
        sendNiemannPickABLeukocytes() {
        let dd = window.document.getElementsByClassName("ck-content");
        const Clinical_history = dd[0]?.innerHTML;
        const indication = dd[1]?.innerHTML;
        const Method = dd[2]?.innerHTML;
        const Result = dd[3]?.innerHTML;
        const Interpretation = dd[4]?.innerHTML;
        const recommendations = dd[5]?.innerHTML;
        const comment = dd[6]?.innerHTML;
        const Note = dd[7]?.innerHTML;       
        const result = [{
           Clinical_history: Clinical_history,
           Leukocyte_Enzyme_Activity: this.Leukocyte_Enzyme_Activity,
           Method:Method,
           Result: Result,
           Interpretation: Interpretation,
           Note:Note,       
           indication:indication,
           recommendations:recommendations,
           comment:comment,   
           signature_required: this.signature_required
          }]
        return this.$emit('sendNiemannPickABLeukocytes', result);
    },
    onDeleteItem(index) {
        this.Leukocyte_Enzyme_Activity?.splice(index.sno - 1, 1)
        this.Leukocyte_Enzyme_Activity?.forEach((data, index) => {
            data.sno = index + 1
        })
    },
    onAddItem() {
        this.Leukocyte_Enzyme_Activity?.push({
            sno: this.Leukocyte_Enzyme_Activity?.length + 1,
            Test: '',
            Disorder: '',
            Reference_interval: '',
            Result: '',
            Status: ''
        })
    },
  },    
}
</script>


<style lang="scss" scoped>
.niemannPickABLeukocytes {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>