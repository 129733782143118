<template>
    <div class="CytologySlideReporting">
        <div class="edit-box mb-20">
            <span class="title">CYTOLOGY TYPE</span>
            <el-input placeholder="Please enter Cytology Type"  v-model="cytology_type"/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">LAB. REF. NO.</span>
            <el-input placeholder="Please enter LAB. REF. NO"  v-model="lab_ref_no" type="textarea" autosize/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">CLINICAL DETAILS</span>
            <el-input placeholder="Please enter Clinical Details"  v-model="clinical_details" type="textarea" autosize />
        </div>
        <!-- <div class="edit-box mb-20">
            <span class="title">STAINS</span>
            <el-input placeholder="Please enter Stains"  v-model="stains" type="textarea" autosize/>
        </div> -->
         <div class="edit-box mb-20">
            <span class="title">MICROSCOPY</span>
            <el-input placeholder="Please enter Microscopy"  v-model="specimen" type="textarea" autosize/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">IMPRESSION</span>
            <el-input placeholder="Please enter Impression"  v-model="impression" type="textarea" autosize/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <el-input placeholder="Please enter Note"  v-model="note" type="textarea" autosize/>
        </div>
        <el-row :gutter="10" style="margin-top: 15px;">
            <el-col :span="6">
              <p class="dropdown">AIR DRIED</p>
              <el-select placeholder="Please Select Air Dried" :clearable="true" :filterable="true" v-model="air_Drein">
                <el-option  v-for="item in numbersList" :key="item" :value="item"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <p class="dropdown">FIXED</p>
              <el-select placeholder="Please Select Fixed" :clearable="true" :filterable="true" v-model="fixed">
                <el-option  v-for="item in numbersList" :key="item" :value="item"></el-option>
              </el-select>
            </el-col>
          </el-row>
    </div>
</template>

<script>


export default {
    props: {
        patientInfo: {
            type: Array,
            default: null
        },
        subCategory: {
            type: String
        }
    },
    data() {
        return {
            editorData: '',
            show: false,
            comments: "",
            recommendations: "",
            resultData: "",
            references: "",
            remarks: "",
            textarea: "",
            impression: this.patientInfo[0]?.impression ? this.patientInfo[0].impression : '',
            lab_ref_no: this.patientInfo[0]?.lab_ref_no ? this.patientInfo[0].lab_ref_no : '',
            clinical_details: this.patientInfo[0]?.clinical_details ? this.patientInfo[0].clinical_details : '',
            specimen: this.patientInfo[0]?.specimen ? this.patientInfo[0].specimen : '',
            microscopy: this.patientInfo[0]?.microscopy ? this.patientInfo[0].microscopy : '',
            note: this.patientInfo[0]?.note ? this.patientInfo[0].note : '',
            cytology_type:this.patientInfo[0]?.cytology_type ? this.patientInfo[0].cytology_type : '',
            // stains:this.patientInfo[0]?.stains?this.patientInfo[0].stains:'',
            air_Drein:this.patientInfo[0]?.air_Drein?this.patientInfo[0].air_Drein:'',
            fixed:this.patientInfo[0]?.fixed?this.patientInfo[0].fixed:'',
            numbersList:[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50]
        }

    },
    methods: {
        childMethod() {
            const result = [{
                lab_ref_no: this.lab_ref_no,
                clinical_details: this.clinical_details,
                specimen: this.specimen,
                // stains:this.stains,
                note: this.note,
                cytology_type:this.cytology_type,
                air_Drein:this.air_Drein,
                fixed:this.fixed,
                impression:this.impression
            }]
            this.$emit('childMethod', result);
        },
        onCheckFeilds() {
            const subCategorys = [
                "Fluid For Cytology", "Fluid_For_Cytology",
                "Urine For Cytology", "Urine_For_Cytology",
                "Sputum For Cytology", "Sputum_For_Cytology"
            ];
            return !subCategorys.includes(this.subCategory)
        }
    },

}
</script>


<style lang="scss" scoped>
.CytologySlideReporting {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
    .dropdown{
        color: #2eaf9f;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 13.28px;
        margin-top: 5px;
    }
}
</style>