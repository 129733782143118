<template>
    <section id="all-patient-reports">
      <div v-loading="loading" :element-loading-text="loadingText">
        <div class="module-filter-navigation">
          <ul class="action-navigation">
            <li>
              <div class="search-filter">
                <el-input
                  placeholder="Search Visit Id"
                  @input="onSearch"
                  v-model="searchString"
                  size="large"
                  clearable
                ></el-input>
              </div>
            </li>
            <li>
              <div class="search-filter">
                <el-select
                  placeholder="Select Type"
                  @change="onSearch"
                  v-model="status"
                  size="large"
                  clearable
                >
                  <el-option
                    v-for="status in statusOptions"
                    :key="status"
                    :label="status.label"
                    :value="status.value"
                  ></el-option>
                </el-select>
              </div>
            </li>
          </ul>
        </div>
  
        <div class="web_version_data hidden-sm-and-down">
          <div class="vue-table">
            <el-table
              :data="reportsData"
              height="calc(100vh - 250px)"
              class="table-list display-web"
            >
            <el-table-column
                label="VISIT ID"
                prop="visit_id"
                min-width="130"
              >
                <template #default="scope">
                  <span class="date" v-if="scope.row.visit_id">
                    {{ scope.row.visit_id }}
                  </span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column
                label="MESSAGE"
                prop="message"
                min-width="130"
              >
                <template #default="scope">
                  <span class="date" v-if="scope.row.message">
                    {{ scope.row.message }}
                  </span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="DATE & TIME" prop="created_at" min-width="160">
              <template #default="scope">
                <span class="date" v-if="scope.row.created_at">
                  {{ date(scope.row.created_at) }}
                  {{ time(scope.row.created_at) }}
                </span>
                <span v-else>--</span>
              </template>
              </el-table-column>
              <el-table-column
                label="USER"
                prop="user_id"
                min-width="130"
              >
                <template #default="scope">
                  <span class="date" v-if="scope.row.user_id">
                    {{ scope.row.user_id ? scope.row.user_id.first_name + " " + scope.row.user_id.last_name: "--" }}
                  </span>
                  <span v-else>--</span>
                </template>
              </el-table-column>          
          </el-table>
        </div>
        <div
          class="vue-pagination web_version_pagination hidden-sm-and-down"
          v-if="reportsData && reportsData.length"
        >
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="loadData"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div>
        </div>
      </div>
    </section>
  </template>
  <script>
  import { mapGetters } from "vuex";
  import moment from "moment";
  export default {
    middleware: "auth",
    components: {
    },
    computed: {
      ...mapGetters("errors", ["getErrors", "getError"]),
      ...mapGetters("patientReports", [
        "getAllAuditLogs",
      ]),
      getStatusType() {
        return (statusVal) => {
          const status = statusVal ? statusVal.trim() : statusVal;
          if (status == "COMPLETED") {
            return "success";
          } else if (status == "REJECTED") {
            return "danger";
          } else if (status == "HOLD") {
            return "warning";
          } else if (status == "ACKNOWLEDGED") {
            return "info";
          } else if (status == "OUT_SOURCE") {
            return "warning";
          } else {
            return "default";
          }
        };
      },
      ...mapGetters("auth", [
        "getAuthenticatedUser",
        "getProfileDetails",
        "getAuthenticationDetails",
      ]),
    },
    data() {
      return {
        reportsData: [],
        page: 1,
        pageSize: 20,
        total: 0,
        loading: false,
        loadingText: "",
        searchString: "",
        status: "",
        statusOptions: [
        {
          label: "Report",
          value: "report_id",
        },
        {
          label: "Result",
          value: "result_file_id",
        },
      ],
      orderBy: "created_at",
      orderType: "desc",
      sortCodeMap: {
        ascending: "asc",
        descending: "desc",
      },
      };
    },
    async mounted() {
      this.setQueryToData();
      this.$store.dispatch("errors/clear");
      await this.fetchAuditLogs();
    },
    methods: {
      setQueryToData() {
        if (this.$route.query) {
          this.page = this.$route.query.selectedPage
            ? parseInt(this.$route.query.selectedPage)
            : 1;
          this.pageSize = this.$route.query.selectedPageSize
            ? parseInt(this.$route.query.selectedPageSize)
            : 20;
          this.searchString = this.$route.query.selectedString || "";
          this.status = this.$route.query.selectedStatus || "";
        }
      },
      setQueryData(data) {
        let params = {
          selectedPage: data.page || 1,
          selectedPageSize: data.limit || 20,
          selectedString: data.search_string || undefined,
          selectedStatus: this.status,
          result_file_id: data.result_file_id || undefined, // Include result_file_id in query params if present
          report_id: data.report_id || undefined
        };

        this.$router
          .replace({
            name: this.$route.name,
            query: { ...params },
          })
          .catch(() => {});
      },
      date(value) {
        if (!value) return;
        return moment(value).format("DD-MM-yyyy");
      },
      time(value) {
        if (!value) return;
        return moment(value).format("hh:mm A");
      },
      async onSearch() {
        setTimeout(async () => {
          this.page = 1;
          await this.fetchAuditLogs();
        }, 600);
      },
      async fetchAuditLogs() {
        try {
          this.loading = true;
          this.loadingText = "Fetching Audit Logs...";
          let params = {
            page:this.page,
            limit:this.pageSize,
          };
          if (this.searchString) {
            params.search_string = this.searchString;
          }
          if(this.status == 'result_file_id'){
            params.result_file_id = true
          }
          if(this.status == 'report_id'){
            params.report_id = true
          }
          if (this.orderBy && this.orderType) {
            params.order_by = this.orderBy;
            params.order_type = this.orderType;
          }
          // if(!this.hasAdmin){
          //   params.user_id = this.getAuthenticatedUser._id
          // }
          await this.$store.dispatch(
            "patientReports/fetchAllAuditLogs",params
          );
          this.setTableData();
          this.setQueryData(params);

          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        }
      },
  
      setTableData() {
        const tableData = JSON.parse(JSON.stringify(this.getAllAuditLogs));
        this.reportsData =
          tableData && tableData.data && tableData.data.length
            ? tableData.data
            : [];
        this.page = tableData.page || 1;
        this.total = tableData.total || 0;
        this.pageSize = tableData.limit || 20;
      },
      async handleSizeChange(size) {
        this.pageSize = size;
        this.page = 1;
  
        await this.fetchAuditLogs();
      },
      async loadData(page) {
        this.page = page;
        await this.fetchAuditLogs();
      },
    },
    beforeUnmount() {
      this.$store.commit("patientReports/setAllAuditLogs", []);
    },
  };
  </script>
  
  <style lang="scss" scoped>
  
  @media (max-width: 576px) {
    .action-navigation {
      display: grid;
      justify-content: stretch;
      align-items: normal;
      li:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 1;
      }
      li:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 2;
      }
      li:nth-child(3) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 3;
      }
      li:nth-child(4){
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 3;
        grid-row-end: 3;
      }
      .el-button {
        width: 100%;
        margin-top: 8px;
      }
    }
  }
  
  #all-patient-reports {
    tbody {
      td {
        &:last-child {
          background-image: linear-gradient(90deg, #f1f8ff, #ffffff) !important;
          background-color: #f1f8ff !important;
        }
        .status-dropdown .el-dropdown-link:focus {
          outline: none;
        }
      }
    }
    .attachmentsList {
      display : grid;
      grid-template-columns: 325px auto auto;
      align-items: center;
      margin-bottom: 0.5rem;
      column-gap: 10px;
      .el-button {
        margin-left: 5px;
      }
      .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
      }
    }
    .dialogBody { 
      .button-center {
        display: block;
        margin: 0 auto;
        width: 200px;
      }
    }
    
    
  
    .default-table-action-items {
      li {
        .send-mail-btn {
          width: 70px;
          height: 32px;
          span {
            padding-left: 2px;
          }
        }
      }
    }
    
    .email-notification {
      .case-info {
        margin: 0 auto 20px;
      }
       .each-cell {
        display: grid;
        grid-template-columns: 100px 5px auto;
        align-items: center;
        column-gap: 8px;
      }
      .value {
        font-weight: 500;
        margin: 8px 0;
      }
  
      .label {
        color: #18546d;
        margin: 8px 0;
      }
  
      .button-group {
        text-align: center;
      }
      
      .el-dialog__header {
        background-color: #28b4a6;
        margin: 0;
        padding: 10px 20px;
        .el-dialog__close {
          font-size: 1.25rem;
          color: #ffffff;
        }
        .el-dialog__title {
          color: #ffffff;
        }
      }
    }
  }
  
  .filter-navigation {
    li {
      margin-right: 10px;
  
      .status-filter {
        min-width: 120px;
      }
  
      .category-filter {
        min-width: 150px;
      }
    }
  }
  
  // popup styles
  .all-patient-reports-popup {
    .el-dialog {
      border-radius: 10px;
      .notes-text {
        font-weight: 500;
        font-size: 1rem;
        color: #005475;
        margin: 0 0 10px;
      }
      .el-dialog__header {
        font-size: 20px;
        padding: 10px 10px 5px;
        background: #2eaf9f;
        border-radius: 10px 10px 0 0;
  
        .el-dialog__title {
          color: #ffffff;
          font-weight: 500;
        }
      }
  
      .el-dialog__headerbtn {
        border: 1px solid #dcdce4;
        border-radius: 3px;
        padding: 0 5px;
        top: 0px !important;
        right: 10px;
  
        .el-dialog__close {
          color: #ffffff;
          font-weight: 700;
        }
      }
  
      .el-dialog__body {
        padding: 0;
        padding-bottom: 10px;
        padding-top: 10px;
  
        .input-d-flex {
          display: grid;
          grid-template-columns: 125px auto;
          align-items: center;
          margin: 10px 0;
  
          .name {
            color: #000000;
            font-size: 16px;
            margin: 0;
            font-weight: 500;
          }
  
          .value {
            font-weight: 500;
            color: #219388;
          }
  
          .el-input__inner {
            width: 80%;
          }
        }
      }
  
      .dialog-footer {
        .submit-btn {
          background: #0061ec;
          color: #fff;
          font-weight: 500;
          border: none;
        }
      }
  
      .err {
        color: red;
        white-space: nowrap;
      }
    }
  }
  
  .department-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    min-width: 140px;
    width: 185px;
    padding: 6px;
    color: #00445e;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    background: #f2f2f2;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  #statusUpdateDialog {
    .el-drawer {
      width: 90% !important;
    }
    .el-dialog__body {
      padding: 0;
    }
    .dialogBody {
      padding: 25px 20px;
      input {
        min-height: 40px;
      }
    }
    .dialogFooter {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding: 15px 20px;
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        li {
          &:not(:last-child) {
            margin-right: 15px;
          }
          button {
            padding: 7px 15px;
            min-width: 90px;
            color: #ffffff;
            &.cancel-button {
              background: #eb5757;
            }
          }
        }
      }
    }
  }
  </style>
  