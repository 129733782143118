<template>
  <section
    id="add-report-view"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
  >
    <div class="reports-title-header">
      <h4 class="report-title">Biochemistry Template</h4>
      <div class="display-end">
        <el-button @click="goToTemplates" class="back-btn"
          ><el-icon class="el-icon-back"><Back /></el-icon>Back</el-button
        >
      </div>
    </div>
    <div class="inner-navbar">
      <el-row :gutter="10">
        <!-- <ul class="filter-navigation"> -->
        <el-col :span="6">
          <el-select
            v-model="templateForm.department"
            placeholder="Select Department"
            :clearable="true"
            @clear="clearAllFields"
            filterable
            :loading="loading"
            @change="fetchAllCategories"
          >
            <el-option
              v-for="(item, index) in listOfDepartments"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <!-- <li> -->

          <el-select
            v-model="templateForm.category"
            placeholder="Select Category"
            :clearable="true"
            filterable
            :loading="loading"
            @clear="clearSubCategory"
            @change="fetchAllSubCategories"
          >
            <el-option
              v-for="category in listOfCategories"
              :key="category"
              :label="category"
              :value="category"
            >
            </el-option>
          </el-select>

          <FormError errorName="category"></FormError>
          <!-- </li> -->
        </el-col>
        <el-col :span="10">
          <!-- <li> -->
          <el-select
            v-model="templateForm.sub_category"
            placeholder="Select Sub Category"
            :clearable="true"
            filterable
          >
            <el-option
              v-for="subcategory in listOfSubCategories"
              :key="subcategory"
              :label="subcategory"
              :value="subcategory"
            ></el-option>
          </el-select>
          <FormError errorName="sub_category"></FormError>
          <!-- </li> -->
        </el-col>
        <!-- </ul> -->
        <!-- </el-col> -->
      </el-row>
    </div>
    <div class="inner">
      <div class="editor">
        <ckeditor
          v-if="show"
          :editor="editor"
          tag-name="div"
          ref="myCommentEditor"
          v-model="templateData"
          :config="editorConfig"
        >
        </ckeditor>
      </div>
      <FormError errorName="subcategory"></FormError>
      <ul class="action-buttons">
        <!-- <li>
          <el-button @click="clearForm" class="submit-button"
            >Clear Form</el-button
          >
        </li> -->
        <!-- <li>
          <el-button @click="previewReport" class="preview-button"
            >Preview</el-button
          >
        </li> -->
        <li v-if="!isEditForm">
          <el-button @click="saveBiochemistryTemplate" class="submit-button"
            >Submit</el-button
          >
        </li>

        <li v-if="isEditForm">
          <el-button @click="updateTemplateForm" class="submit-button"
            >Update</el-button
          >
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import FormError from "@/components/FormError";
import { mapGetters } from "vuex";
import { ElMessage } from "element-plus";

import EditorConfig from "@/config/editorConfig";
export default {
  components: {
    ckeditor: CKEditor.component,
    FormError,
  },

  data() {
    return {
      reportTypes: [],
      templateForm: {
        department: "BIOCHEMISTRY",
        category: "",
        sub_category: "",
      },
      templateData: "",
      loading: false,
      loadingText: "",
      editor: ClassicEditor,
      show: false,
      editorData: "",

      editorConfig: EditorConfig,
      listOfDepartments: [],
      listOfCategories: [],
      listOfSubCategories: [],
      categoryType: [],
      subCategoryTypes: [],
    };
  },
  computed: {
    ...mapGetters("errors", ["getErrors", "getError"]),

    ...mapGetters("biochemistryTemplates", [
      "getBiochemistryTemplateAddStatus",
      "getBiochemistryTemplateUpdateStatus",
      "getSingleBiochemistryTemplate",
      "getAllDepartments",
      "getAllCategories",
      "getAllSubCategories",
    ]),

    isEditForm() {
      return this.$route.params && this.$route.params.template_id
        ? true
        : false;
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetchAllDepartments();
    await this.fetchAllCategories();
    this.loadInitData();
    this.loading = false;
  },

  methods: {
    goToTemplates() {
      this.$router.push({
        name: "BiochemistryTemplates",
        query: this.$route.query,
      });
    },
    clearAllFields() {
      this.templateForm.category = "";
      this.categoryType = [];
      this.subCategoryTypes = [];
      this.templateForm.sub_category = "";
    },
    clearSubCategory() {
      this.subCategoryTypes = [];
      this.templateForm.sub_category = "";
    },
    async loadInitData() {
      try {
        this.loading = true;
        await this.$store.dispatch("errors/clear", {}, { root: true });

        if (this.$route.params.template_id) {
          await this.fetchSingletemplateForm();
        }

        setTimeout(() => {
          this.show = true;
        });

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async fetchAllDepartments() {
      await this.$store.dispatch("biochemistryTemplates/fetchAllDepartments");
      this.listOfDepartments = this.getAllDepartments.data;
    },
    async fetchAllCategories() {
      await this.$store.dispatch("biochemistryTemplates/fetchAllCategories");
      this.listOfCategories = this.getAllCategories.data;
    },
    async fetchAllSubCategories() {
      await this.$store.dispatch("biochemistryTemplates/fetchAllSubCategories");
      this.listOfSubCategories = this.getAllSubCategories.data;
    },
    getCategories(department) {
      this.categoryType = [];

      this.templateForm.category = "";
      // this.subCategoryTypes = [];
      this.templateForm.sub_category = "";

      // console.log(this.listOfCategories, department);
      const category = this.listOfCategories.filter((e) =>
        e.department.includes(department)
      );
      this.categoryType = category;
    },
    getSubCategory(category) {
      const subcategories = this.listOfSubCategories.filter((e) =>
        e.category.includes(category)
      );
      subcategories.forEach((x) => (this.subCategoryTypes = x.subcategory));
    },
    async previewReport() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;
        let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent.trim();
        if (!stringContent) {
          ElMessage.warning("Report content is not given");
          return;
        }

        this.loading = true;
        this.loadingText = "Loading..";

        let interpretation_template = data || "";

        let params = {
          interpretation_template: interpretation_template,
        };
        if (data) {
          this.$store.dispatch("biochemistryTemplates/addTemplateData", params);

          let routerData = this.$router.resolve({
            name: "PreviewTemplate",
          });

          window.open(routerData.href, "_blank");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchSingletemplateForm() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data...";
        await this.$store.dispatch(
          "biochemistryTemplates/fetchSingleBiochemistryTemplate",
          this.$route.params.template_id
        );
        await this.setPatientData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    setPatientData() {
      this.templateForm.department =
        this.getSingleBiochemistryTemplate.department;
      this.templateForm.category = this.getSingleBiochemistryTemplate.category;
      this.templateForm.sub_category =
        this.getSingleBiochemistryTemplate.sub_category;

      // const JavaScriptApplicants = subCategories.filter((e) =>
      //   e.subcategory.includes(this.templateForm.sub_category)
      // );
      // JavaScriptApplicants.forEach(
      //   (x) => (this.subCategoryTypes = x.subcategory)
      // );

      this.templateData =
        this.getSingleBiochemistryTemplate.interpretation_template;
      this.fetchAllCategories();
      this.fetchAllSubCategories();
      // this.commentData = this.getSinglePatientReports.comments;
    },

    async goToReports() {
      if (this.$route.params.patient_id) {
        this.$router.push({
          name: "PatientReportView",
          query: this.$route.query,
          params: { status: this.$route.params.status },
        });
      } else {
        this.$router.push({ name: "PatientReports", query: this.$route.query });
      }
      // await this.goToReportsView();
    },

    async saveBiochemistryTemplate() {
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0].innerHTML;
      let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
      stringContent = stringContent.trim();
      if (!stringContent) {
        ElMessage.warning("Template content is not given");
        return;
      }
      try {
        this.loading = true;
        this.loadingText = "Loading...";
        let params = { ...this.templateForm };
        let templatesData = window.document.querySelector(
          ".editor .ck-content"
        );
        params.interpretation_template = templatesData.innerHTML || "";
        await this.$store.dispatch(
          "biochemistryTemplates/addBiochemistryTemplate",
          params
        );
        if (this.getBiochemistryTemplateAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Template Addedd Successfully",
          });
          await this.goToTemplates();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Adding Template",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async updateTemplateForm() {
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0].innerHTML;
      let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
      stringContent = stringContent.trim();
      if (!stringContent) {
        ElMessage.warning("Template content is not given");
        return;
      }
      this.loading = true;
      this.loadingText = "Loading...";
      try {
        let params = { ...this.templateForm };

        let updateEditorData = window.document.querySelector(
          ".editor .ck-content"
        );
        params.interpretation_template = updateEditorData.innerHTML || "";

        let templateId = this.$route.params.template_id;
        await this.$store.dispatch(
          "biochemistryTemplates/updateBiochemistryTemplate",
          {
            params,
            templateId,
          }
        );
        if (this.getBiochemistryTemplateUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Template Updated Successfully",
          });
          await this.goToTemplates();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Template",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    scrollIntoFirstError() {
      var el = this.$el.getElementsByClassName("error")[0];
      if (el) {
        el.scrollIntoView({ block: "center" });
      }
    },
  },
};
</script>

<style lang="scss">
#add-report-view {
  max-width: 900px;
  margin: 0 auto;
  .reports-title-header {
    display: grid;
    grid-template-columns: 88% auto;
    margin-bottom: 10px;
    .display-end {
      text-align: end;
    }
    .report-title {
      text-align: center;
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }

    .back-btn {
      padding: 9px 15px;
      min-height: auto;
      line-height: 1;
      color: #333;
      border: 1px solid #47525d42;
      background-color: white;

      border-radius: 5px;

      font-weight: 500;
      .el-icon-back {
        margin-right: 4px;
      }
      &:active,
      &:hover,
      &:focus {
        border: 1px solid #47525d42;
      }
    }
  }

  .inner-navbar {
    margin-bottom: 10px;
    .filter-navigation {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }

  .inner {
    margin-bottom: 50px;
    .report-header {
      margin: 0;
      color: #fff;
      font-weight: 600;
      font-size: 20px;
      background: #2eaf9f;
      text-align: center;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    border: 1px solid rgba(7, 162, 149, 0.6);
    border-radius: 10px;
    padding: 0;
    background: #fff;
    overflow: hidden;
    .patient-information {
      .el-form-item {
        margin-bottom: 0;
      }
      .form-mandatory-field {
        .el-form-item__label:after {
          content: "*";
          color: red !important;
          font-size: 15px;
          line-height: 1;
          padding-left: 3px;
        }
      }

      .el-form-item__label {
        min-width: 40%;
        text-align: left;
        background: #e0f3f1;
        padding-left: 10px;
        border-bottom: 1px solid #e0f3f1;
        font-weight: 600;
        font-size: 11px;
        color: #000;
      }

      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }
      .el-input__inner {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-left: none;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        border-radius: 0;
        color: #000;
        font-weight: 500;
        // text-transform: uppercase;
        &::placeholder {
          color: #5a5a5a;
          font-size: 13px;
          text-transform: capitalize;
        }
      }
    }
    .report-sub-title {
      color: #43454b;
      font-size: 18px;
      text-align: center;
      margin-top: -1px;
      padding-top: 1rem;
      border-top: 1.2px solid rgba(0, 0, 0, 0.2);

      font-weight: 600;
    }
    .editor,
    .comments-editor {
      margin-top: 0.5em;
      padding: 10px;
      .ck-editor__editable {
        min-height: 320px;
      }
    }
    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      margin: 0;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      z-index: 999;
      li {
        &:not(:last-child) {
          margin-right: 20px;
        }
        .el-button {
          padding: 9px 15px;
          min-height: auto;
          line-height: 1;
          &.preview-button {
            background: #28b4a6;
            color: #fff;
            font-weight: 600;
          }
          &.print-button {
            background: #f56c6c !important;
          }
          &.submit-button {
            background: #0061ec;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  .ck {
    ul {
      list-style: disc;
      padding-left: 30px;
      li {
        padding-left: 5px;
        list-style-type: disc !important;
      }
    }
  }
  // .input-error {
  //   .el-input__inner {
  //     border: 1.5px solid red !important;
  //   }
  //   ::placeholder {
  //     color: red !important;
  //     font-weight: 600;
  //   }
  // }
}
</style>
