<template>
  <section class="profile-view">
    <div
      class="profile-data-view"
      v-loading="profileDataloading"
      :element-loading-text="loadingText"
    >
      <div v-if="profileDetails">
        <div class="insideNavbar">
          <ul>
            <li>
              <router-link :to="{ name: 'PatientReports' }" class="back-arrow">
                <el-icon class="el-icon-back"><Back /></el-icon>
                Back
              </router-link>
            </li>
            <li>
              <router-link to="/edit-profile" class="edit-icon">
                <el-icon><Edit /></el-icon>
                Edit
              </router-link>
            </li>
          </ul>
        </div>
        <div class="profile-card">
          <div class="headerPart">
            <h3>Profile Details</h3>
          </div>
          <div class="bodyPart">
            <div class="data">
              <el-row :gutter="20">
                <el-col :xl="8" :lg="8" :md="12" :sm="12" :xs="24">
                  <div class="each-view">
                    <h4>First Name</h4>
                    <p v-if="profileDetails.first_name">
                      {{ profileDetails.first_name }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="8" :lg="8" :md="12" :sm="12" :xs="24">
                  <div class="each-view">
                    <h4>Last Name</h4>
                    <p v-if="profileDetails.last_name">
                      {{ profileDetails.last_name }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="8" :lg="8" :md="12" :sm="12" :xs="24">
                  <div class="each-view">
                    <h4>Signature</h4>
                    <p v-if="profileDetails.signature_name">
                      {{ profileDetails.signature_name }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="8" :lg="8" :md="12" :sm="12" :xs="24">
                  <div class="each-view">
                    <h4>Gender</h4>
                    <p>
                      {{
                        profileDetails.gender
                          ? genderTypesMap[profileDetails.gender] ||
                            profileDetails.gender
                          : "--"
                      }}
                    </p>
                  </div>
                </el-col>

                <el-col :xl="8" :lg="8" :md="12" :sm="12" :xs="24">
                  <div class="each-view">
                    <h4>Username</h4>
                    <p v-if="profileDetails.username">
                      {{ profileDetails.username }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="8" :lg="8" :md="12" :sm="12" :xs="24">
                  <div class="each-view">
                    <h4>Email Address</h4>
                    <p v-if="profileDetails.email" class="email-address">
                      {{ profileDetails.email }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="8" :lg="8" :md="12" :sm="12" :xs="24">
                  <div class="each-view">
                    <h4>Phone Number</h4>
                    <p v-if="profileDetails.mobile">
                      {{ profileDetails.mobile }}
                    </p>
                  </div>
                </el-col>
                <el-col
                  :xl="8"
                  :lg="8"
                  :md="12"
                  :sm="12"
                  :xs="24"
                  v-if="profileDetails.address"
                >
                  <div class="each-view">
                    <h4>City</h4>
                    <p
                      v-if="
                        profileDetails.address && profileDetails.address.city
                      "
                    >
                      {{ profileDetails.address.city }}
                    </p>
                  </div>
                </el-col>
                <el-col
                  :xl="8"
                  :lg="8"
                  :md="12"
                  :sm="12"
                  :xs="24"
                  v-if="profileDetails.address"
                >
                  <div class="each-view">
                    <h4>State</h4>
                    <p
                      v-if="
                        profileDetails.address && profileDetails.address.state
                      "
                    >
                      {{ profileDetails.address.state }}
                    </p>
                  </div>
                </el-col>
                <el-col
                  :xl="8"
                  :lg="8"
                  :md="12"
                  :sm="12"
                  :xs="24"
                  v-if="profileDetails.address"
                >
                  <div class="each-view">
                    <h4>Zip</h4>
                    <p
                      v-if="
                        profileDetails.address && profileDetails.address.zip
                      "
                    >
                      {{ profileDetails.address.zip }}
                    </p>
                  </div>
                </el-col>

                <el-col :xl="8" :lg="8" :md="12" :sm="12" :xs="24">
                  <div class="each-view">
                    <h4>Designation</h4>
                    <p v-if="profileDetails.designation">
                      {{ profileDetails.designation }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="8" :lg="8" :md="12" :sm="12" :xs="24">
                  <div class="each-view">
                    <h4>Regd No</h4>
                    <p v-if="profileDetails.regd_no">
                      {{ profileDetails.regd_no }}
                    </p>
                  </div>
                </el-col>
              </el-row>
              <div class="digitalSignature">
                <el-row :gutter="20">
                  <el-col
                    :xl="8"
                    :lg="12"
                    :md="12"
                    :sm="12"
                    :xs="24"
                    v-if="profileDetails.digital_signature"
                  >
                    <div class="each-view">
                      <h4>Digital Signature</h4>
                      <img
                        :src="profileDetails.digital_signature"
                        class="digital-signature-img-view"
                      />
                      <!-- <p
                    v-if="
                      profileDetails.digital_signature &&
                      profileDetails.digital_signature
                    "
                  >
                    {{ profileDetails.digital_signature }}
                  </p> -->
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { genderTypesMap } from "@/constants/formConstants";

export default {
  computed: {
    ...mapGetters("auth", ["getProfileDetails"]),
  },
  data() {
    return {
      profileDataloading: false,
      genderTypesMap: genderTypesMap,

      profileDetails: {},
      laoding: false,
      userData: {},
      loadingText: "",
    };
  },
  async mounted() {
    this.fetchProfile();
    await this.$store.dispatch("errors/clear", {}, { root: true });
  },
  methods: {
    async fetchProfile() {
      try {
        this.profileDataloading = true;
        this.loadingText = "Fetching Data...";
        await this.$store.dispatch("auth/getProfile");
        if (this.getProfileDetails) {
          this.profileDetails = {
            ...this.profileDetails,
            ...this.getProfileDetails,
          };
        }

        this.profileDataloading = false;
      } catch (error) {
        console.log(error);
      }
    },
    editForm() {},
  },
};
</script>

<style lang="scss" scoped>
.profile-view {
  .profile-data-view {
    max-width: 800px;
    margin: 0 auto;
    .insideNavbar {
      margin-bottom: 1.25rem;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
          .back-arrow {
            display: flex;
            align-items: center;
            color: #000;
            font-weight: 500;
            font-size: 1.05em;
            text-transform: uppercase;
            i {
              font-size: 16px;
              margin-right: 5px;
            }
          }
          .edit-icon {
            background: #f94e5e;
            padding: 3px 15px;
            color: #fff;
            border-radius: 4px;
            text-decoration: none;
            font-size: 0.9em;
            display: flex;
            align-items: center;
            i {
              font-size: 16px;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .profile-card {
      border-radius: 10px;
      margin: auto;
      border: 1px solid #efefef;
      background-color: white;
      .headerPart {
        padding: 20px 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        h3 {
          font-size: 1.05em;
          font-weight: 500;
          color: rgb(24, 84, 109);
          line-height: 1;
        }
      }
      .bodyPart {
        padding: 20px 30px;
        .digitalSignature {
          h4 {
            margin-bottom: 10px !important;
          }
          img {
            border: 1px dashed #333;
            padding: 5px;
            border-radius: 2px;
          }
        }
      }

      .profile-image {
        margin-bottom: 30px;
        img {
          max-width: 100px;
          display: table;
          margin: 0 auto;
        }
      }
      .data {
        .each-view {
          margin-bottom: 25px;

          p {
            margin: 0;
            font-weight: normal;
            font-family: sans-serif;
          }
          h4 {
            color: #828282;
            font-size: 1em;
            font-family: sans-serif;
            margin-bottom: 2px;
            font-weight: 500;
          }
          img.digital-signature-img-view {
            width: 200px;
          }
        }
      }
    }
  }
}
</style>
