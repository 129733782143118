<template>
    <div class="MucolipidosisPlasma">
        <div class="edit-box mb-20">
            <span class="title">CLINICAL HISTORY</span>
            <ckeditor :editor="editor" v-model="Clinical_history" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INDICATION</span>
            <ckeditor :editor="editor" v-model="Indication" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">PLASMA ENZYME ACTIVITY</span>
            <el-table :data="Plasma_Enzyme_Activity" style="width: 100%">

                <el-table-column prop="Test" label="Test (Units)" width="270">
                    <template v-slot="scope">
                        <el-input type="textarea" autosize v-model="scope.row.Test" placeholder="Test (Units)"></el-input>
                    </template>
                </el-table-column>

                <el-table-column prop="Enzyme_Activity" label="Enzyme Activity" width="180">
                    <template v-slot="scope">
                        <el-input type="textarea" autosize v-model="scope.row.Enzyme_Activity" placeholder="Enzyme Activity"></el-input>
                    </template>
                </el-table-column>

                <el-table-column label="Fold Elevation" width="120">
                    <template v-slot="scope">
                        <el-input type="textarea" autosize v-model="scope.row.Fold_Elevation" placeholder="Fold Elevation"></el-input>
                    </template>
                </el-table-column>

                <el-table-column prop="Biological_References" label="Biological Reference Interval (Mean ± SD)"
                    width="160">
                    <template v-slot="scope">
                        <el-input type="textarea" autosize v-model="scope.row.Biological_References" placeholder="Biological Reference Interval (Mean ± SD)"></el-input>
                    </template>
                </el-table-column>

                <el-table-column prop="Status" label="Status" width="150">
                    <template v-slot="scope">
                        <el-select placeholder="Status" :clearable="true" :filterable="true" v-model="scope.row.Status">
                            <el-option label="Normal" value="Normal"></el-option>
                            <el-option label="Deficient" value="Deficient"></el-option>
                            <el-option label="Low" value="Low"></el-option>
                            <el-option label="Elevated" value="Elevated"></el-option> 
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope"
                    v-if="this.patientInfo?.from == undefined">
                    <el-row :gutter="12">
                        <el-col :span="6" v-if="scope.row.sno === Plasma_Enzyme_Activity?.length">
                            <el-buttton @click="onAddItem" class="c-pointer"><el-icon :size="20">
                                    <CirclePlus />
                                </el-icon></el-buttton>
                        </el-col>
                        <el-col :span="6" v-if="Plasma_Enzyme_Activity?.length > 1"><el-buttton
                                @click="onDeleteItem(scope.row)"><el-icon :size="19">
                                    <Delete style="color: red;" />
                                </el-icon></el-buttton>
                        </el-col>
                    </el-row>

                </el-table-column>
            </el-table>
        </div>
        <div class="edit-box mb-20">
            <span class="title">METHOD</span>
            <ckeditor :editor="editor" v-model="Method" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RESULT</span>
            <ckeditor :editor="editor" v-model="Result" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INTERPRETATION</span>
            <ckeditor :editor="editor" v-model="Interpretation" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RECOMMENDATION</span>
            <ckeditor :editor="editor" v-model="Recommendations" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">COMMENT</span>
            <ckeditor :editor="editor" v-model="comment" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <ckeditor :editor="editor" v-model="Note" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <el-row v-if="this.patientInfo" class="mt-20">
            <el-col :span="6">
                <el-checkbox label="Additional approval required" v-model="signature_required"
                    @change="handleCheckedChange"
                    :disabled="this.patientInfo?.report_status === 'APPROVED' && this.patientInfo.fulfilled_signatures === 2" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";


export default {
    components: {
        ckeditor: CKEditor.component,
    },
    props: {
        mucolipidosisPlasmaArray: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            editor: ClassicEditor,
            editorData: '',
            show: false,
            comment:this.mucolipidosisPlasmaArray?.length == 1 ? this.mucolipidosisPlasmaArray[0].comment : "",
            Indication:this.mucolipidosisPlasmaArray?.length == 1 ? this.mucolipidosisPlasmaArray[0].Indication : "",
            Recommendations:this.mucolipidosisPlasmaArray?.length == 1 ? this.mucolipidosisPlasmaArray[0].Recommendations : "",
            impression: "",
            resultData: "",
            references: "",
            remarks: "",
            editorConfig: EditorConfig,
            textarea: "",
            Clinical_history: this.mucolipidosisPlasmaArray?.length == 1 ? this.mucolipidosisPlasmaArray[0].Clinical_history : "",
            Interpretation: this.mucolipidosisPlasmaArray?.length == 1 ? this.mucolipidosisPlasmaArray[0].Interpretation : "Normal activities of α-mannosidase, α-fucosidase, and β-hexosaminidase total in plasma may rule out the possibility of Mucolipidosis II/III.",
            Method: this.mucolipidosisPlasmaArray?.length == 1 ? this.mucolipidosisPlasmaArray[0].Method : "Artificial Fluorogenic substrates.",
            Note:this.mucolipidosisPlasmaArray?.length == 1 ? this.mucolipidosisPlasmaArray[0].Note : "It is presumed that the specimen used to perform the test belongs to the patient specified above, such verification having been carried out at the collection level of sample. Please correlate with clinical findings and other test findings.",
            Plasma_Enzyme_Activity: this.mucolipidosisPlasmaArray?.length == 1 ? this.mucolipidosisPlasmaArray[0].Plasma_Enzyme_Activity : [
                {
                    sno: 1,
                    Test: 'α-mannosidase (nmol/h/ml)',
                    Enzyme_Activity: '',
                    Fold_Elevation: '',
                    Biological_References: '25 – 110 (63 ± 26)',
                    Status: 'Normal',
                },
                {
                    sno: 2,
                    Test: 'α-fucosidase(nm/h/ml)',
                    Enzyme_Activity: '',
                    Fold_Elevation: '',
                    Biological_References: '150 – 566 (348 ± 132)',
                    Status: 'Normal',
                },
                {
                    sno: 3,
                    Test: '*β-hexosaminidase total(A+B) (nmol/h/ml)',
                    Enzyme_Activity: '',
                    Fold_Elevation: '',
                    Biological_References: '635 – 3600 (2184 ± 435)',
                    Status: 'Normal',
                }
            ],
            Result: this.mucolipidosisPlasmaArray?.length == 1 ? this.mucolipidosisPlasmaArray[0].Result : "Normal activities of α-mannosidase, α-fucosidase, and β-hexosaminidase total (A+B) are obtained in plasma (For fold elevation see results table).",
            signature_required: (this.patientInfo && this.patientInfo.is_required_signature) ? this.patientInfo.is_required_signature : false
        }
    },
    computed: {

    },
    mounted() {
        // this.prepareTemplateData();
    },
    methods: {
        sendBGMLPlama() {
            let dd = window.document.getElementsByClassName("ck-content");
            const Clinical_history = dd[0]?.innerHTML;
            const Indication = dd[1]?.innerHTML;
            const Method = dd[2]?.innerHTML;
            const Result = dd[3]?.innerHTML;
            const Interpretation = dd[4]?.innerHTML;
            const Recommendations = dd[5]?.innerHTML;
            const comment = dd[6]?.innerHTML;
            const Note = dd[7]?.innerHTML;
            const result = [{
                Clinical_history: Clinical_history,
                Plasma_Enzyme_Activity: this.Plasma_Enzyme_Activity,
                Method: Method,
                Result: Result,
                Interpretation: Interpretation,
                Note:Note,
                Indication: Indication,
                comment:comment,
                Recommendations:Recommendations,
                signature_required: this.signature_required
            }]
            return this.$emit('sendBGMLPlama', result);
        },
        // async handleCheckedChange(event) {
        //   this.signature_required = event;
        // },
        onDeleteItem(index) {
            this.Plasma_Enzyme_Activity?.splice(index.sno - 1, 1)
            this.Plasma_Enzyme_Activity?.forEach((data, index) => {
                data.sno = index + 1
            })
        },
        onAddItem() {
            this.Plasma_Enzyme_Activity?.push({
                sno: this.Plasma_Enzyme_Activity?.length + 1,
                Test: '',
                Enzyme_Activity: '',
                Fold_Elevation: '',
                Biological_References: '',
                Status: ''
            })
        },
    }
}
</script>


<style lang="scss" scoped>
.MucolipidosisPlasma {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>