<template>
  <section id="dashboard-view">
    <h1>Dashboard</h1>
  </section>
</template>

<script>
export default {};
</script>


<style lang="scss"  scoped>
#dashboard-view {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  h1 {
    font-size: 2em;
    color: #0061ec;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: uppercase;
  }
}
</style>