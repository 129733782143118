<template>
  <section id="all-patient-reports">
    <div v-loading="loading" :element-loading-text="loadingText">
      <div class="page-navigation">
        <el-row>
          <el-col :lg="19">
            <ul class="filter-navigation"></ul>
          </el-col>
          <el-col :lg="5">
            <ul class="action-navigation">
              <li>
                <div class="search-filter">
                  <el-input
                    placeholder="Search Visit Id"
                    @input="onSearch"
                    v-model="searchString"
                  ></el-input>
                  <!-- <el-select
                    placeholder="Select"
                    v-model="searchString"
                    clearable
                    filterable
                    remote
                    :remote-method="queryReportLogs"
                    default-first-option
                    :loading="searchReportsLoading"
                    @change="getReportLogs"
                    @clear="clearReportsLogsSearch"
                  >
                    <el-option
                      v-for="(log, index) of reportLogs"
                      :key="index"
                      :label="log.visit_id"
                      :value="log.visit_id"
                    ></el-option>
                  </el-select> -->
                </div>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <div class="vue-table">
        <el-table
          :data="reportsData"
          style="width: 100%"
          height="calc(100vh - 250px)"
          class="table-list display-web"
        >
          <el-table-column label="Visit Id" prop="visit_id">
            <template #default="scope">
              <span class="date" v-if="scope.row.visit_id">
                {{ scope.row.visit_id }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="Description" prop="action_description">
            <template #default="scope">
              <span class="date" v-if="scope.row.action_description">
                {{ scope.row.action_description }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="Created At" prop="created_at">
            <template #default="scope">
              <span class="date" v-if="scope.row.created_at">
                {{ date(scope.row.created_at) }}
                {{ time(scope.row.created_at) }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="User">
            <template #default="scope">
              <span class="date" v-if="scope.row.user_id">
                {{
                  scope.row.user_id
                    ? scope.row.user_id.first_name +
                      " " +
                      scope.row.user_id.last_name
                    : scope.row.user_id.first_name +
                      " " +
                      scope.row.user_id.last_name
                }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="vue-pagination" v-if="reportsData && reportsData.length">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="loadData"
          :page-sizes="[20, 25, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  middleware: "auth",
  computed: {
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("patientReports", ["getReportsLogs"]),
  },
  data() {
    return {
      reportsData: [],
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      loadingText: "",
      searchString: "",
      searchReportsLoading: false,
      reportLogs: [],
    };
  },
  async mounted() {
    this.$store.dispatch("errors/clear");
    await this.setQueryToData();
    await this.fetchReportsLogs();
  },
  methods: {
    getReportLogs() {
      setTimeout(async () => {
        this.page = 1;
        await this.fetchReportsLogs();
      }, 600);
    },
    clearReportsLogsSearch() {
      this.searchString = "";
      this.fetchReportsLogs(1);
    },
    async queryReportLogs(query) {
      if (query.length >= 6) {
        this.searchReportsLoading = true;
        let params = {
          search_string: query,
        };
        await this.$store.dispatch("patientReports/fetchReportsLogs", params);
        this.reportLogs = this.getReportsLogs.data;
        this.searchReportsLoading = false;
      }
    },
    date(value) {
      if (!value) return;
      return moment(value).format("DD-MM-yyyy");
    },
    time(value) {
      if (!value) return;
      return moment(value).format("hh:mm a");
    },
    async onSearch() {
      setTimeout(async () => {
        this.page = 1;
        await this.fetchReportsLogs();
      }, 600);
    },
    prepareParams() {
      let params = { page: this.page, limit: this.pageSize };

      if (this.searchString) {
        params.search_string = this.searchString;
      }

      return params;
    },
    async fetchReportsLogs() {
      try {
        this.loading = true;
        this.loadingText = "Fetching Reports Logs...";
        let params = this.prepareParams();
        await this.$store.dispatch("patientReports/fetchReportsLogs", params);
        if (this.getReportsLogs) {
          console.log("prasad", this.getReportsLogs);
        }
        this.setTableData();
        this.setQueryData(params);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    setTableData() {
      const tableData = JSON.parse(JSON.stringify(this.getReportsLogs));
      this.reportsData =
        tableData && tableData.data && tableData.data.length
          ? tableData.data
          : [];
      this.page = tableData.page || 1;
      this.total = tableData.count || 0;
      this.pageSize = tableData.limit || 20;
    },

    setQueryData(data) {
      let params = {
        selectedPage: data.page || 1,
        selectedPageSize: data.limit || 20,
        selectedString: data.search_string || undefined,
      };

      this.$router
        .replace({
          name: this.$route.name,
          query: { ...params },
        })
        .catch(() => {});
    },
    setQueryToData() {
      if (this.$route.query) {
        this.page = this.$route.query.selectedPage
          ? parseInt(this.$route.query.selectedPage)
          : 1;
        this.pageSize = this.$route.query.selectedPageSize
          ? parseInt(this.$route.query.selectedPageSize)
          : 20;
        this.searchString = this.$route.query.selectedString || "";
      }
    },
    async handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      await this.fetchReportsLogs();
    },
    async loadData(page) {
      this.page = page;
      await this.fetchReportsLogs();
    },
  },
  beforeUnmount() {
    this.$store.commit("patientReports/setReportsLogs", null);
  },
};
</script>

<style lang="scss">
#all-patient-reports {
  tbody {
    td {
      &:last-child {
        background: #f5f5f5;
      }
    }
  }
}
.filter-navigation {
  li {
    margin-right: 10px;
    .status-filter {
      min-width: 120px;
    }
    .category-filter {
      min-width: 150px;
    }
  }
}
// popup styles
.all-patient-reports-popup {
  .el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      font-size: 20px;
      padding: 10px 10px 5px;
      background: #2eaf9f;
      border-radius: 10px 10px 0 0;
      .el-dialog__title {
        color: #ffffff;
        font-weight: 500;
      }
    }
    .el-dialog__headerbtn {
      border: 1px solid #dcdce4;
      border-radius: 3px;
      padding: 0 5px;
      top: 10px;
      right: 10px;
      .el-dialog__close {
        color: #ffffff;
        font-weight: 700;
      }
    }
    .el-dialog__body {
      padding-bottom: 10px;
      padding-top: 10px;

      .input-d-flex {
        display: grid;
        grid-template-columns: 125px auto;
        align-items: center;
        margin: 10px 0;
        .name {
          color: #000000;
          font-size: 16px;
          margin: 0;
          font-weight: 600;
        }
        .value {
          font-weight: 600;
          color: #219388;
        }
        .el-input__inner {
          width: 80%;
        }
      }
    }
    .dialog-footer {
      .submit-btn {
        background: #0061ec;
        color: #fff;
        font-weight: 600;
        border: none;
      }
    }

    .err {
      color: red;
      white-space: nowrap;
    }
  }
}
.department-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  min-width: 140px;
  width: 185px;
  padding: 6px;
  color: #00445e;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  background: #f2f2f2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
