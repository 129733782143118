<template>
    <div class="GM1Leukocytes">
        <div class="edit-box mb-20">
            <span class="title">CLINICAL HISTORY</span>
            <ckeditor :editor="editor" v-model="clinical_history" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INDICATION</span>
            <ckeditor :editor="editor" v-model="indication" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
             <span class="title">LEUKOCYTE ENZYME ACTIVITY</span>
             <el-table :data="leukocyte_enzyme_activity" style="width: 100%">
                 <el-table-column prop="test" label="Test (Units)" width="250">
                    <template v-slot="scope">
                         <el-input type="textarea" autosize v-model="scope.row.test" placeholder="Test (Units)"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column prop="disorder" label="Disorder" width="180">
                    <template v-slot="scope">
                         <el-input type="textarea" autosize v-model="scope.row.disorder" placeholder="Disorder"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column label="Results" width="100">
                     <template v-slot="scope">
                         <el-input type="textarea" autosize v-model="scope.row.results" placeholder="Results"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column prop="reference" label="Reference Interval(Mean ± SD)" width="230">
                    <template v-slot="scope">
                         <el-input type="textarea" autosize v-model="scope.row.reference" placeholder="Reference Interval(Mean ± SD)"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column label="Status" width="150">
                    <template v-slot="scope">
                        <el-select placeholder="Status" :clearable="true" :filterable="true" v-model="scope.row.status">
                            <el-option label="Normal" value="Normal"></el-option>
                            <el-option label="Deficient" value="Deficient"></el-option>
                            <el-option label="Low" value="Low"></el-option>
                            <el-option label="Elevated" value="Elevated"></el-option> 
                        </el-select>
                    </template>
                 </el-table-column>
                    
                 <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope" v-if="this.patientInfo?.from==undefined">
                                <el-row :gutter="12">
                                    <el-col :span="6" v-if="scope.row.sno === leukocyte_enzyme_activity?.length">
                                        <el-buttton @click="onAddItem" class="c-pointer"><el-icon :size="20">
                                                <CirclePlus />
                                            </el-icon></el-buttton>
                                    </el-col>
                                    <el-col :span="6" v-if="leukocyte_enzyme_activity?.length > 1"><el-buttton
                                            @click="onDeleteItem(scope.row)"><el-icon :size="19">
                                                <Delete style="color: red;" />
                                            </el-icon></el-buttton>
                                    </el-col>
                                </el-row>
                    </el-table-column>

             </el-table>
         </div>
        <div class="edit-box mb-20">
            <span class="title">METHOD</span>
            <ckeditor :editor="editor" v-model="method" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RESULT</span>
            <ckeditor :editor="editor" v-model="results" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">REMARK</span>
            <ckeditor :editor="editor" v-model="remark" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">BACKGROUND</span>
            <ckeditor :editor="editor"  v-model="background" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INTERPRETATION</span>
            <ckeditor :editor="editor" v-model="interpretation" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RECOMMENDATIONS</span>
            <ckeditor :editor="editor" v-model="recommendations" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">COMMENT</span>
            <ckeditor :editor="editor"  v-model="comment" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>

        <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <ckeditor :editor="editor"  v-model="note" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">REFERENCE</span>
            <ckeditor :editor="editor"  v-model="reference" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
   </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";


export default{
    components:{
        ckeditor: CKEditor.component,
    },
    props: {
      GM1_Leukocytes_Array: {
      type: Array,
    },
    patientInfo:{
        type: String, 
    }
  },
  mounted(){
  },
    data(){
        return{
            editor: ClassicEditor,
            editorData: '',
            clinical_history:this.GM1_Leukocytes_Array?.length==1?this.GM1_Leukocytes_Array[0].clinical_history:'',
            show: false,
            comment: this.GM1_Leukocytes_Array?.length==1?this.GM1_Leukocytes_Array[0].comment:'',
            recommendations: this.GM1_Leukocytes_Array?.length==1?this.GM1_Leukocytes_Array[0].recommendations:'',
            indication: this.GM1_Leukocytes_Array?.length==1?this.GM1_Leukocytes_Array[0].indication:'',
            method: this.GM1_Leukocytes_Array?.length==1?this.GM1_Leukocytes_Array[0].method:'Artificial fluorogenic substrates',
            results: this.GM1_Leukocytes_Array?.length==1?this.GM1_Leukocytes_Array[0].results:'A normal enzyme activity of β-Galactosidase is obtained in leukocytes.',
            interpretation:this.GM1_Leukocytes_Array?.length==1?this.GM1_Leukocytes_Array[0].interpretation:'Normal activity of β-Galactosidase in leukocytes may rule out the possibility of GM1-Gangliosidosis.',
            remark:this.GM1_Leukocytes_Array?.length==1?this.GM1_Leukocytes_Array[0].remark:'',
            background:this.GM1_Leukocytes_Array?.length==1?this.GM1_Leukocytes_Array[0].background:'',
            note:this.GM1_Leukocytes_Array?.length==1?this.GM1_Leukocytes_Array[0].note:
            'It is presumed that the specimen used to perform the test belongs to the patient specified above, such verification having been carried out at the collection level of sample. Please correlate with clinical findings and other test findings.',
            reference:this.GM1_Leukocytes_Array?.length==1?this.GM1_Leukocytes_Array[0].reference:'',
            resultData: "",
            editorConfig: EditorConfig,
            textarea: "",
            leukocyte_enzyme_activity:this.GM1_Leukocytes_Array?.length == 1 ? this.GM1_Leukocytes_Array[0].leukocyte_enzyme_activity :[
            {
               sno: 1,
               test:'β-Galactosidase(nmol/h/mg)	',
               disorder: 'GM1-gangliosidosis	',
               results:'',
               reference:'85 - 300(142 ± 59)',
               status:'Normal'
            },
            {
               sno: 2,
               test:'*β-Hexosaminidase A(nmol/h/mg)	',
               disorder: 'Tay-Sach',
               results:'',
               reference:'91 – 260(181 ± 61)	',
               status:'Normal'
            }
        ]
    }
    },
    methods:{
     childMethod() {
        let dd = window.document.getElementsByClassName("ck-content");
        const clinical_history = dd[0]?.innerHTML;
        const indication = dd[1]?.innerHTML;
        const method = dd[2]?.innerHTML;  
        const results = dd[3]?.innerHTML;  
        const remark = dd[4]?.innerHTML;  
        const background=dd[5]?.innerHTML;
        const interpretation = dd[6]?.innerHTML;  
        const recommendations = dd[7]?.innerHTML;
        const comment=dd[8]?.innerHTML;
        const note =dd[9]?.innerHTML;
        const reference=dd[10]?.innerHTML;
        const result = [{
            indication:indication,
            method:method,
            results: results,
            interpretation: interpretation, 
            remark:remark,
            recommendations:recommendations,
            note:note,
            reference:reference,
            background:background,
            leukocyte_enzyme_activity:this.leukocyte_enzyme_activity,
            comment:comment,
            clinical_history:clinical_history
        }]
       this.$emit('childMethod', result);
    },
    onDeleteItem(index) {
            this.leukocyte_enzyme_activity?.splice(index.sno - 1, 1)
            this.leukocyte_enzyme_activity?.forEach((data, index) => {
                data.sno = index + 1
            })
        },
    onAddItem() {
        this.leukocyte_enzyme_activity?.push({
            sno: this.leukocyte_enzyme_activity?.length + 1,
            test: '',
            disorder: '',
            results: '',
            reference: '',
            status:''
        })
    },
  }
}
</script>


<style lang="scss" scoped>
.GM1Leukocytes {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>