<template>
  <section class="add-patient-report-data" v-loading.fullscreen.lock="loading" :element-loading-text="loadingText">
    <div class="AddReportTitle">
      <h3>
        {{ `${$route.name == "AddTemplate" ? "Add" : "Update"} Template` }}
      </h3>

      <div class="backNavigation">
        <!-- <el-button @click="goToReportTemplates" class="back-button">
          <el-icon class="el-icon-back"><Back /></el-icon>
          Back</el-button
        > -->
        <el-button @click="goToReportTemplates" class="back-btn">
          <img src="@/assets/images/icons/back-arrow.svg" alt="" class="img-fluid" />
          <span> Back </span>
        </el-button>
      </div>
    </div>
    <div class="addReportFilters">
      <el-row :gutter="10">
        <el-col :span="8">
          <div>
            <el-input v-model="patientInfo.template_title" placeholder="Template Title"></el-input>
            <FormError errorName="template_title"></FormError>
          </div>
        </el-col>
        <el-col :span="8">
          <el-select v-model="patientInfo.department" placeholder="Select Department" :clearable="true"
            @change="patientInfo.sub_category = ''" filterable>
            <el-option v-for="(item, index) in getDepartmetns" :key="index" :label="item.label" :value="item.label">
            </el-option>
          </el-select>
          <FormError errorName="department"></FormError>
        </el-col>
        <el-col :span="8">
          <el-select v-model="patientInfo.sub_category" placeholder="Select Category" :clearable="true"
            @change="onChangeCategory" :disabled="!patientInfo.department" filterable>
            <el-option v-for="(report, index) in getSubCategories" :key="index" :label="report"
              :value="report"></el-option>
          </el-select>
          <FormError errorName="report_type"></FormError>
          <!-- </li> -->
        </el-col>
        <div class="fileUploadBlock mt-20" v-if="this.patientInfo.category == 'CORPORATE'">
          <div class="fileUploadArea" v-if="this.patientInfo.department == 'Department of CORPORATE'">
            <div class="fileUpload section-1">
              <cropper v-if="image.src" ref="cropper" class="cropper" @change="cropImage" :src="image.src" />
              <!-- <img v-if="this.patientInfo.corporate_logo" :src="this.patientInfo.corporate_logo" class="cropper" /> -->
              <div class="mt-20 centeredButton">
                <el-button type="primary" @click="file.click()">
                  <input type="file" ref="file" class="hidden" @change="uploadImage($event)"
                    accept="image/jpeg, image/png," />
                  Upload Company Logo
                </el-button>

              </div>
              <!-- <el-button type="info"  @click="cropImage()">Crop </el-button> -->
            </div>
            <div v-if="croppedimage" class="croppedBlock mt-20">
              <img v-if="croppedimage" :src="croppedimage" class="croppedImage" alt="Cropped Image" />
              <div>
              </div>
            </div>
          </div>
          <p v-if="imageFormateError" class="text">Please upload an image with JPEG, PNG, or JPG format.</p>

          <!-- <div class="fileUploadArea" v-if="this.patientInfo.department == 'Department of CORPORATE'">
      <div class="section-1">
        <cropper v-if="image1.src" ref="cropper1" class="cropper" @change="cropDoctorSignatureImage"  :src="image1.src" />
        <div class="button-wrapper">
            <el-button type="primary"  @click="file1.click()">
            <input
              type="file"
              ref="file1"
              class="hidden"
              @change="uploadDoctorSignatureImage($event)"
              accept="image/jpeg, image/png,"
            />
            Upload Doctor Signature
          </el-button>
          <el-button type="info"  @click="cropDoctorSignatureImage()">Crop </el-button>
        </div>
      </div>
      <div v-if="croppedimage1" class="croppedBlock">
        <img  v-if="croppedimage1" :src="croppedimage1"  class="croppedImage" alt="Cropped Image" />
        <div class="button-wrapper">
          <el-button type="danger" @click="removeDoctorSignatureImage()" v-if="image1.src">Remove</el-button>
        </div>
      </div>
    </div> -->
        </div>

      </el-row>
    </div>
    <div class="addReportPatientDemographics">
      <div class="inner">
        <div class="report-sub-title" v-if="showReportType && patientInfo.report_type">
          <h3>{{ patientInfo.report_type }}</h3>

          <span @click="onEditReportType" class="cursor-pointer">
            <el-icon>
              <Edit />
            </el-icon>
          </span>
        </div>
        <div class="report-type-edit-info" v-else-if="editReportType">
          <el-input v-model="reportTypeNew"></el-input>
          <el-button type="success" @click="updateReportType" :disabled="isReportTypeUpdateDisable">
            <el-icon>
              <Check />
            </el-icon>
          </el-button>
          <el-button type="danger" @click="cancelUpdateReportType">
            <el-icon>
              <Close />
            </el-icon>
          </el-button>
        </div>

        <div>
          <div v-if="isMolecularGenetics" id="molecular-genetics-report-editor">
            <el-button @click="addNewEditor" class="add-editor-button">
              Add <span>+</span>
            </el-button>
            <div
              class="addReportEditor"
              v-for="(editorData, index) in editorsData"
              :key="index"
            >
              <div class="editor">
                <div class="input-title">
                  <el-input
                    placeholder="Enter section title"
                    v-model="editorsData[index].title"
                  >
                  </el-input>
                  <el-button class="removeBtn" v-if="editorsData && editorsData.length > 1" @click="removeEditor(index)">
                   <span><el-icon><Minus /></el-icon></span> 
                  </el-button>
                </div>
             
                <ckeditor
                  :editor="editor"
                  tag-name="div"
                  ref="myEditor"
                  v-model="editorsData[index].template_body"
                  :config="editorConfig"
                >
                </ckeditor>
              </div>
            </div>
          </div>
          <div class="addReportEditor" v-else>
            <div class="editor" v-if="show && checkCKEditorContent()">
              <ckeditor
                :editor="editor"
                tag-name="div"
                ref="myEditor"
                v-model="editorData"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
        </div>
        <div v-if="patientInfo.sub_category == 'KARYOTYPING'">

          <!-- Karyotyping Report Static Code Start -->
          <div class="double-marker">
            <div class="edit-box mb-20 edit-box-table">
              <div class="inside-table">
                <el-form :model="testResultsSummary">
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Sample Type" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeSample_Type"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Quality of Sample" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeQualityOfSample"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Yoda Cytogenetics Number" readonly>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.CytogeneticsNumber"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Clinical Indication" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeClinicalIndication"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row> -->
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Test Requested" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeTestRequested"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Test Methodology" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeTestMethodology"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="No of cells counted" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.NoOfCellsCounted"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="No of cells Karyotyped" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.NoOfCellsKaryotyped"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Estimated band resolution" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.EstimatedBandResolution"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Banding method" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.BandingMethod"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="ISCN" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.ISCN"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <div class="edit-box mb-20 editor">
              <ckeditor :editor="editor" tag-name="div" ref="myEditor" v-model="editorData" :config="editorConfig">
              </ckeditor>
            </div>
            <!-- <div class="edit-box mb-20">
              <span class="title">Karyotype:</span>
              <el-input type="textarea" v-model="patientInfo.karyotype" rows="2"></el-input>
            </div>
            <div class="edit-box mb-20">
              <span class="title">Interpretation:</span>
              <el-input type="textarea" v-model="patientInfo.interpretation" rows="3"></el-input>
            </div> -->
            <div class="edit-box mb-20">
              <span class="title">Recommendations:</span>
              <el-input type="textarea" autosize v-model="patientInfo.recommendations" rows="3"></el-input>
            </div>

            <div class="edit-box mb-20">
              <span class="title">References:</span>
              <el-input type="textarea" autosize v-model="patientInfo.References" rows="3"
                style="font-size:10px;"></el-input>
            </div>
            <div class="edit-box mb-20">
              <span class="title">Disclaimer:</span>
              <el-input type="textarea" autosize v-model="patientInfo.Disclaimer" rows="3"
                style="font-size:8px;"></el-input>
            </div>
          </div>
        </div>

        <!-- SEPSIS Report Static Code Start -->
        <div
          v-if="patientInfo.sub_category && patientInfo.sub_category == 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'">
          <!-- <sepsis ref="SepsisTestList"></sepsis> -->
          <div>
            <div class="double-marker">
              <!-- Test List Table -->
              <div class="edit-box mb-20 edit-box-table">
                <div class="inside-table">
                  <el-form>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Test Name" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Result" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item>
                          <el-input placeholder="ORGANISM" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item>
                          <el-input placeholder="GRAM POSITIVE ORGANISMS" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Staphylococcus Aureus" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].staphylococcus_Aureus" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Staphylococcus Epidermidis" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].staphylococcus_Epidermidis" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Streptococcus Pneumoniae" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].streptococcus_Pneumoniae" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Streptococcus Pyogenes" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].streptococcus_Pyogenes" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Streptococcus Agalactiae" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].streptococcus_Agalactiae" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Enterococcus Fecalis" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].enterococcus_Fecalis" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Enterococcus Faecium" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].enterococcus_Faecium" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Listeria Monocytogenes" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].listeria_Monocytogenes" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item align="center">
                          <el-input placeholder="GRAM NEGATIVE ORGANISMS" readonly style="font-weight:bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Escherichia Coli" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Escherichia_Coli" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Klebsiella Pneumoniae" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Klebsiella_pneumoniae" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Klebsiella Oxytoca" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Klebsiella_Oxytoca" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Enterobacter Species" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Enterobacter_species" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Salmonella Spp" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Salmonella_spp" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Acinetobacter Baumannii" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Acinetobacter_baumannii" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Pseudomonas Beruginosa" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Pseudomonas_aeruginosa" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Haemophilus Influenzae (A-F)" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Haemophilus_influenzae" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Neisseria Meningitidis" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Neisseria_meningitidis" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item>
                          <el-input placeholder="YEAST" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Candida Auris" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Yeast[0].Candida_auris" placeholder="Select" :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Candida Albicans" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Yeast[0].Candida_albicans" placeholder="Select" :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Candida Lusitaniae" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Yeast[0].Candida_lusitaniae" placeholder="Select" :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item>
                          <el-input placeholder="ANTI-MICROBIAL RESISITANT GENE" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="sul2" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].sul2" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="dfrA1" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].dfrA1" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="dfrA5" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].dfrA5" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla CTX-M gr1" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_CTX_M_gr1" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla TEM" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_TEM" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla SHV" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_SHV" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla KPC" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_KPC" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla NDM" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_NDM" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla VIM" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_VIM" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla OXA-48" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_OXA_48" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla IMP" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_IMP" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="mecA" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].mecA" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="vanA" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].vanA" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="vanB" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].vanB" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </div>
              <!-- Test List Table -->

              <!-- DRUGS INTERPRETATION Table -->
              <div class="edit-box mb-20 edit-box-table">
                <div class="inside-table">
                  <el-form>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="DRUGS INTERPRETATION" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="SENSITIVE / RESISTANT" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="COTRIMOXAZOLE (Trimethoprim & Sulfamethoxazole)" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cotrimoxazole">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Pencillin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Pencillin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Ampicillin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Ampicillin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Amoxicillin/ClavulanicAcid" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Amoxicillin_clavulanic_acid">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Piperacillin/Tazobactam" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Piperacillin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Cefazolin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefazolin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Cefoxitin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefoxitin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Cefuroxime" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefuroxime">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Cefotaxime" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefotaxime">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Ceftriaxone" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Ceftriaxone">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Ceftazidime" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefatazidime">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Cefepime" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefepime">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Cefoperazone/Sulbactam" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefoperazone">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Ceftazidime/Avibactam" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Ceftazidime_avibactam">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Gentamicin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Gentamicin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Amikacin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Amikacin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Tigecycline" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Tigecycline">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Polymyxin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Polymyxin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Imipenem" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Imipenem">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Meropenem" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Meropenem">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Ertapenem" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Ertapenem">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Aztreonam" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Aztreonam">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Methicillin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Methicillin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Vancomycin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Vancomycin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Teicoplanin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Teicoplanin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </div>
              <!-- DRUGS INTERPRETATION Table -->
              <!-- <div class="edit-box mb-20 editor">
              <ckeditor :editor="editor" tag-name="div" ref="myEditor" v-model="editorData"
                :config="editorConfig">
              </ckeditor>
            </div> -->
              <div class="edit-box mb-20">
                <span class="title">Intended Use</span>
                <el-input type="textarea" rows="2" v-model="patientInfo.Intended_Use" autosize></el-input>
              </div>
              <div class="edit-box mb-20">
                <span class="title">Test Method</span>
                <el-input type="textarea" v-model="patientInfo.Test_Method" autosize></el-input>
              </div>
              <div class="edit-box mb-20">
                <span class="title">Test Limitations/ Attributes</span>
                <el-input type="textarea" v-model="patientInfo.Test_Limitations_Attributes" autosize></el-input>
              </div>
              <div class="edit-box mb-20">
                <span class="title">Disclaimer</span>
                <el-input type="textarea" v-model="patientInfo.Disclaimer" autosize></el-input>
              </div>
              <div class="edit-box mb-20">
                <span class="title">References</span>
                <!-- <ol>
                    <li>Tsalik EL, Jones D, Nicholson B, Waring L, Liesenfeld O, Park LP, Glickman SW, Caram LB, Langley RJ,
                        van Velkinburgh JC, Cairns CB, Rivers EP, Otero RM, Kingsmore SF, Lalani T, Fowler VG, Woods CW.
                        Multiplex PCR to diagnose bloodstream infections in patients admitted from the emergency department
                        with sepsis. J Clin Microbiol. 2010 Jan;48(1):26-33.</li>
                    <li>
                        Lucignano B, Ranno S, Liesenfeld O, Pizzorno B, Putignani L, Bernaschi P, Menichella D. Multiplex
                        PCR allows rapid and accurate diagnosis of bloodstream infections in newborns and children with
                        suspected sepsis. J Clin Microbiol. 2011 Jun;49(6):2252-8.
                    </li>
                    <li>
                        Westh, H. et al.Multiplex real-time PCR and blood culture for identification of bloodstream
                        pathogens in patients with suspected sepsis.Clinical Microbiology and Infection,2009 , Volume 15,
                        Issue 6, 544 – 551.
                    </li>
                </ol> -->
                <el-input type="textarea" v-model="patientInfo.References" autosize></el-input>
              </div>
            </div>
          </div>
        </div>
        <!-- SEPSIS Report Static Code Start -->

        <div v-if="shouldRenderElement">
          <div>
            <div>
              <span class="report-header">PHYSICAL EXAMINATION</span>
            </div>
            <el-form>
              <el-row :gutter="10">
                <el-col :span="5" :offset="2" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">Height (cm)</div>
                    <el-input type="number" placeholder="Height" v-model="patientInfo.height" id="height"
                      @change="updateWeightStatus"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">Weight (kg)</div>
                    <el-input type="number" placeholder="Weight" v-model="patientInfo.weight" id="weight"
                      @change="updateWeightStatus"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">BP</div>
                    <el-input placeholder="BP" v-model="patientInfo.bp" id="bp"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">Pulse</div>
                    <el-input placeholder="Pulse" type="number" v-model="patientInfo.pulse" id="pulse"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div>
            </div>
            <div>
              <span class="report-header">PERSONAL HISTORY</span>
            </div>
            <el-form>
              <el-row>
                <el-col :span="20" :offset="2" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">Past Complaints</div>
                    <el-input type="textarea" placeholder="Past Complaints"
                      v-model="patientInfo.past_complaints"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="20" :offset="2">
                  <el-form-item>
                    <div class="sub-title">Present Complaints</div>
                    <el-input type="textarea" placeholder="Present Complaints"
                      v-model="patientInfo.present_complaints"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="20" :offset="2">
                  <el-form-item>
                    <div class="sub-title">Family History</div>
                    <el-input type="textarea" placeholder="Family History"
                      v-model="patientInfo.family_history"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <!-- <div>
        <span class="report-header">BMI CALCULATOR</span>
      </div> -->
            <!-- <el-form>
        <el-row :gutter="10">
          <el-col :span="4" :offset="2" class="mt-10">
            <el-form-item>
              <el-input placeholder="Weight Status" v-model="patientInfo.weight_status"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mt-10">
            <el-form-item>
              <el-input placeholder="Under Weight" v-model="patientInfo.under_weight"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mt-10">
            <el-form-item>
              <el-input placeholder="Healthy Weight" v-model="patientInfo.healthy_weight"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mt-10">
            <el-form-item>
              <el-input placeholder="Over Weight" v-model="patientInfo.over_weight"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mt-10">
            <el-form-item>
              <el-input placeholder="Obesity" v-model="patientInfo.obesity"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form> -->
            <div>
              <span class="report-header">VISUAL ACUITY</span>
            </div>
            <el-row :gutter="20">
              <el-col :span="5">
                <p class="veEyeLabel">Right Eye</p>
              </el-col>
              <el-col :span="17">
                <el-form>
                  <el-row class="mt-10">
                    <el-form-item>
                      <el-row :gutter="5">
                        <el-col :span="6">
                          <div class="sub-title">Distance</div>
                          <el-input placeholder="Distance" v-model="patientInfo.right_eye_distance"></el-input>
                        </el-col>
                        <el-col :span="6">
                          <div class="sub-title">Near</div>
                          <el-input placeholder="Near" v-model="patientInfo.right_eye_near"></el-input>
                        </el-col>
                        <el-col :span="6">
                          <div class="sub-title">Colour Vision</div>
                          <el-input placeholder="Colour Vision"
                            v-model="patientInfo.right_eye_colour_version"></el-input>
                        </el-col>
                        <el-col :span="6">
                          <div class="sub-title">Corrections</div>
                          <el-input placeholder="Corrections" v-model="patientInfo.right_eye_corrections"></el-input>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-row>
                </el-form>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="5">
                <p class="veEyeLabel">Left Eye</p>
              </el-col>
              <el-col :span="17">
                <el-form>
                  <el-row class="mt-10">
                    <el-form-item>
                      <el-row :gutter="5">
                        <el-col :span="6">
                          <div class="sub-title">Distance</div>
                          <el-input placeholder="Distance" v-model="patientInfo.left_eye_distance"></el-input>
                        </el-col>
                        <el-col :span="6">
                          <div class="sub-title">Near</div>
                          <el-input placeholder="Near" v-model="patientInfo.left_eye_near"></el-input>
                        </el-col>
                        <el-col :span="6">
                          <div class="sub-title">Colour Vision</div>
                          <el-input placeholder="Colour Vision"
                            v-model="patientInfo.left_eye_colour_version"></el-input>
                        </el-col>
                        <el-col :span="6">
                          <div class="sub-title">Corrections</div>
                          <el-input placeholder="Corrections" v-model="patientInfo.left_eye_corrections"></el-input>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-row>
                </el-form>
              </el-col>
            </el-row>
            <div>
              <span class="report-header">SYSTEMIC EXAMINATION</span>
            </div>
            <el-form>
              <el-row class="mt-10">
                <el-form-item>

                  <el-col :span="20" :offset="2">
                    <div class="systematicIcons">
                      <el-image style="width: 36px; height: 36px"
                        src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/skin.png"></el-image>
                      <el-input placeholder="Skin" v-model="patientInfo.skin"></el-input>
                    </div>
                  </el-col>

                  <el-col :span="20" :offset="2">
                    <div class="systematicIcons">
                      <el-image style="width: 36px; height: 36px"
                        src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/pft.png">
                      </el-image>
                      <el-input placeholder="PFT" v-model="patientInfo.pft"></el-input>
                    </div>
                  </el-col>

                  <el-col :span="20" :offset="2">
                    <div class="systematicIcons">
                      <el-image style="width: 36px; height: 36px"
                        src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/ecg-reading.png">
                      </el-image>
                      <el-input placeholder="ECG" v-model="patientInfo.ecg"></el-input>
                    </div>
                  </el-col>

                  <el-col :span="20" :offset="2">
                    <div class="systematicIcons">
                      <el-image style="width: 36px; height: 36px"
                        src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/audiometry.png">
                      </el-image>
                      <el-input placeholder="Audiometry" v-model="patientInfo.audiometry"></el-input>
                    </div>
                  </el-col>

                  <el-col :span="20" :offset="2">
                    <div class="systematicIcons">
                      <el-image style="width: 36px; height: 36px"
                        src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/x-ray.png">
                      </el-image>
                      <el-input placeholder="Chest X-Ray" v-model="patientInfo.chest_xray"></el-input>
                    </div>
                  </el-col>
                </el-form-item>
              </el-row>
            </el-form>
            <div>
              <span class="report-header">LAB INVESTIGATIONS</span>
            </div>
            <el-form>
              <el-row :gutter="9">
                <el-col :span="6" :offset="2" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">HB</div>
                    <el-input placeholder="HB" v-model="patientInfo.hb"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">RBS</div>
                    <el-input placeholder="RBS" v-model="patientInfo.rbs"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">URINE</div>
                    <el-input placeholder="URINE" v-model="patientInfo.urine"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div>
              <span class="report-header">REMARKS</span>
            </div>
            <el-form>
              <el-row>
                <el-col :span="20" :offset="2" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">Remarks</div>
                    <el-input type="textarea" placeholder="Remarks / Advice by Physician"
                      v-model="patientInfo.corporate_remaks"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>

        <div v-if="histopathologyIHCTypes.includes(this.patientInfo.sub_category) && show">
          <div class="ihcReport">
            <!-- <h4 class="reportTitle">{{this.patientInfo.sub_category}}</h4> -->
            <div class="editor">
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>IHC NO</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24">
                <el-form-item>
                  <el-input placeholder="Enter the IHC No" type="textarea" autosize v-model="ihcMarkersList[0].histo_ihc_no"></el-input>
                </el-form-item>
              </el-col>
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Histopathology Diagnosis</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24">
                <el-form-item>
                  <el-input placeholder="Enter the histopathology diagnosis" type="textarea" autosize v-model="ihcMarkersList[0].clinical_details"></el-input>
                </el-form-item>
              </el-col>   
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Cold Ischemia time</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24">
                <el-form-item>
                  <el-input placeholder="Enter the cold ischemia time" type="textarea" autosize v-model="ihcMarkersList[0].coldschemiatime"></el-input>
                </el-form-item>
              </el-col>
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Fixation time</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> 
              <el-col :span="24">
              <el-form-item>
                <el-select v-model="ihcMarkersList[0].fixationtime" placeholder="Select fixation time"  v-if="!showCustomInput && !customFixationTime" :clearable="true" @change="handleFixationTimeChange">
                  <el-option v-for="(detail, index) in clinicalDetailsOptions" :key="index" :label="detail.label" :value="detail.value"></el-option>
                </el-select>
                <el-input v-model="customFixationTime" v-if="showCustomInput || customFixationTime" placeholder="Enter custom fixation time" :clearable="true" @change="handleFixationTimeChange"></el-input>
              </el-form-item>
            </el-col>           
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Immunohistochemistry Report</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
               <div class="edit-box ihcTable mt-10" v-if="this.patientInfo.sub_category!=='IHC HER 2 NEU'">
                <el-table border style="width: 100%" :data="ihcDetails">
                  <el-table-column prop="sno" label="S.No" width="70"></el-table-column>
                  <el-table-column width="300">
                    <template #header>               
                      <el-row>
                        <el-col :span="20">
                          <div>IHC Marker</div>
                        </el-col>
                        <el-col :span="4">
                          <el-buttton style="display: flex; align-items: center; justify-content: end; height: 100%;" @click="dialogFormVisible=true;addIHCDetails()">
                            <el-icon :size="18">
                             <CirclePlus />
                            </el-icon>
                          </el-buttton>
                        </el-col>
                      </el-row>
                    </template>
                    <template #default="scope">
                      <el-form>
                        <el-row :gutter="5">
                          <el-col :span="24">
                            <el-select placeholder="Select IHC Marker" v-model="scope.row.ihc_marker"
                              @change="handleDropdownChange(scope.row)" filterable :clearable="true">
                              <el-option v-for="item in ihcMarkerDetails" :key="item.ihcMarker"
                                :label="item.ihcMarker" :value="item.ihcMarker">
                              </el-option>
                            </el-select>                      
                          </el-col>
                        </el-row>
                      </el-form>
                    </template>
                  </el-table-column>
                  <el-table-column label="Clone" width="300" v-slot="scope">
                    <el-form>
                      <el-row>
                        <el-col :span="22">                          
                            <el-select v-if="!scope.row.ihc_clone" v-model="scope.row.ihc_clone" placeholder="Select clone" :clearable="true">
                              <el-option v-for="(clone, index) in filteredClones(scope.row)" :key="index" :label="clone" :value="clone"></el-option>
                            </el-select>
                            <span style="color: red">{{scope.row.errorMessage}}</span>
                            <el-input  v-if="scope.row.ihc_clone" readonly placeholder="Enter the clone" type="textarea" autosize  v-model="scope.row.ihc_clone">
                          </el-input> 
                          </el-col>                         
                          <el-col :span="2" v-if="scope.row.ihc_marker && scope.row.ihc_clone">
                                <el-buttton style="display: flex; align-items: center; justify-content: end; height: 100%;"   @click="dialogFormVisible=true;editIHCDetails(scope.row)">
                                  <el-icon :size="18">
                                    <Edit />
                                  </el-icon>
                          </el-buttton>             
                      </el-col>                 
                      </el-row>
                    </el-form>
                  </el-table-column> 
                   <el-table-column label="Percentage" width="200" v-slot="scope" v-if="this.patientInfo.sub_category=='IHC - ER, PR , HER2NEU' || this.patientInfo.sub_category=='IHC - ER PR HER2 NEU & KI67' || this.patientInfo.sub_category=='IHC - ER/PR' ">
                    <el-form>
                      <el-row>
                        <el-col :span="24">
                          <el-input placeholder="Enter the percentage" type="textarea" autosize  v-model="scope.row.ihc_percentage">
                          </el-input>
                        </el-col>
                      </el-row>
                    </el-form>
                  </el-table-column>                
                  <el-table-column label="Result" width="200" v-slot="scope">
                    <el-form>
                      <el-row>
                        <el-col :span="24">
                          <el-input placeholder="Enter the result" type="textarea" autosize  v-model="scope.row.ihc_result">
                          </el-input>
                        </el-col>
                      </el-row>
                    </el-form>
                  </el-table-column>
                  <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope">
                    <el-row :gutter="12">
                      <el-col :span="6" v-if="scope.row.sno === ihcDetails?.length && scope.row.ihc_marker && scope.row.ihc_clone">
                        <el-buttton class="c-pointer" @click="onAddIHC">
                          <el-icon :size="20">
                            <CirclePlus />
                          </el-icon></el-buttton>
                      </el-col>
                      <el-col :span="6">
                        <el-buttton>
                          <el-icon :size="19" @click="onDeleteIHC(scope.row)">
                            <Delete style="color: red;" />
                          </el-icon></el-buttton>
                      </el-col>
                    </el-row>
                  </el-table-column>
                </el-table>
                <el-dialog v-model="dialogFormVisible" :title="isEditIHC?'Edit IHC Marker':'Add IHC Marker'" width="500">
                  <el-form :model="form" :label-position="labelPosition">
                    <el-form-item label="IHC Marker" :label-width="formLabelWidth" required>
                      <el-input v-model="ihcMarkerDetailsList.ihcMarker" autocomplete="off"
                        placeholder="Enter IHC Marker" @input="onChangeIHCMarker(ihcMarkerDetailsList)" :disabled="multpleIhcCloneLength!=-1"/>
                      
                    </el-form-item>
                    <el-form-item label="Clone" :label-width="formLabelWidth" required>
                      <el-input v-model="newIhcClone" autocomplete="off" type="textarea" autosize
                        placeholder="Enter Clone"  @input="onChangeIHCMarker(ihcMarkerDetailsList)"/>
                        <div class="text">
                        <span style="font-size: 14px">{{ 
                          isDuplicateMarker ? `Clone already exists.` : '' }}</span>
                      </div>
                    </el-form-item>
                  </el-form>
                  <template #footer>
                    <div class="dialog-footer">
                      <el-button @click="dialogFormVisible = false;">Cancel</el-button>
                      <el-button type="primary" @click="dialogFormVisible = false;isEditIHC?updateIHCDetails(''):saveIHCDetails()"
                        :disabled="ihcMarkerDetailsList.ihcMarker == '' || newIhcClone === '' || isDuplicateMarker || this.IHCchangevalue">
                        {{ isEditIHC ?'Update':'Submit' }}
                      </el-button>
                    </div>
                  </template>
                </el-dialog>
               </div>
               <div class="edit-box ihcTable mt-10" v-if="this.patientInfo.sub_category=='IHC HER 2 NEU' ||this.patientInfo.sub_category=='IHC - ER PR HER2 NEU & KI67'|| this.patientInfo.sub_category=='IHC - ER, PR , HER2NEU'">
                <el-table border style="width: 100%" :data="ihcHer2NewDetails">
                  <el-table-column prop="sno" label="S.No" width="70"></el-table-column>
                  <el-table-column label="Biomarker" v-slot="scope" width="300">
                    <el-form>
                      <el-row :gutter="5">
                        <el-col :span="20">
                          <el-input placeholder="Enter the Biomarker" v-model="scope.row.ihc_marker" readonly></el-input>                                                    
                        </el-col>                       
                      </el-row>
                    </el-form>
                  </el-table-column>
                  <el-table-column label="Score" width="300" v-slot="scope">
                    <el-form>
                      <el-row>
                        <el-col :span="24">
                          <el-input placeholder="Enter the score" v-model="scope.row.ihc_clone"></el-input>
                      </el-col>
                      </el-row>
                    </el-form>
                  </el-table-column>                                
                  <el-table-column label="Result" width="200" v-slot="scope">
                    <el-form>
                      <el-row>
                        <el-col :span="24">
                          <el-input placeholder="Enter the result" type="textarea" autosize  v-model="scope.row.ihc_result">
                          </el-input>
                        </el-col>
                      </el-row>
                    </el-form>
                  </el-table-column>                 
                </el-table>             
               </div>

              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Final Diagnosis</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24">
                <el-form-item>
                  <el-input placeholder="Enter final diagnosis" type="textarea" autosize v-model="ihcMarkersList[0].ihc_final_diagnosis"></el-input>
                </el-form-item>
              </el-col>
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Comment</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24">
                <el-form-item>
                  <el-input placeholder="Enter the comment" type="textarea" autosize v-model="ihcMarkersList[0].ihc_comment"></el-input>
                </el-form-item>
              </el-col>
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Disclaimer</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24">
                <el-form-item>
                  <el-input placeholder="Enter the disclaimer" type="textarea" autosize v-model="ihcMarkersList[0].disclaimer"></el-input>
                </el-form-item>
              </el-col>
              <div v-if="this.patientInfo.sub_category=='IHC - ER, PR , HER2NEU' || this.patientInfo.sub_category=='IHC - ER PR HER2 NEU & KI67'">
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Her 2 neu Scoring</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24">
                <el-form-item>
                  <el-input placeholder="Enter the her 2 neu scoring" type="textarea" autosize v-model="ihcMarkersList[0].her2neuscoring"></el-input>
                </el-form-item>
              </el-col>
            </div>
            
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Note</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24" v-if="patientInfo.sub_category === 'IHC - ER PR HER2 NEU & KI67'">
                <el-form-item>
                  <el-input placeholder="Enter the note" type="textarea" autosize v-model="ihcMarkersList[0].note"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-if="patientInfo.sub_category != 'IHC - ER PR HER2 NEU & KI67'">
                <el-form-item>
                  <el-input placeholder="Enter the note" type="textarea" autosize v-model="ihcNote"></el-input>
                </el-form-item>
              </el-col>  
              <div v-if="this.patientInfo.sub_category=='IHC - ER PR HER2 NEU & KI67'">
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>References</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24">
                <el-form-item>
                  <el-input placeholder="Enter the references" type="textarea" autosize v-model="ihcMarkersList[0].references"></el-input>
                </el-form-item>
              </el-col>
            </div>            
            </div>
          </div>
        </div>

        <div class="editor" v-if="patientInfo.report_type == 'HISTOPATHOLOGY(BIOPSY)'">
          <el-row :gutter="10" style="margin-bottom: 15px;">
            <el-col :span="8" class="mb-6">
              <div style="font-size: 12px; font-weight: 500;">Clinical details provided</div>
              <el-input placeholder="Enter Clinical details" v-model="patientInfo.Clinical"></el-input>
            </el-col>
            <el-col :span="8" class="mb-6">
              <div style="font-size: 12px; font-weight: 500;">Site of Biopsy</div>
              <el-input placeholder="Enter site of Biopsy" v-model="patientInfo.Site"></el-input>
            </el-col>
          </el-row>

          <div style="font-weight: 500">Gross Description</div>
          <div>
            <ckeditor :editor="editor" tag-name="div" ref="DescriptionEditor" v-model="Description"
              :config="editorConfig">
            </ckeditor>
          </div>

          <div style="margin-top: 15px;font-weight: 500;">Microscopy</div>
          <ckeditor :editor="editor" tag-name="div" ref="MicroscopyEditor" v-model="Microscopy" :config="editorConfig">
          </ckeditor>

          <div style="margin-top: 15px;font-weight: 500;">Impression</div>
          <ckeditor :editor="editor" tag-name="div" ref="ImpressionEditor" v-model="Impression" :config="editorConfig">
          </ckeditor>

        </div>
        <!-- Fish template Report code start -->
        <div v-if="patientInfo.sub_category == 'FISH'">

          <!-- SOLIDFISH UI Start -->

          <div class="double-marker">

            <div class="edit-box mb-20">
              <span class="title">FLUORESCENCE IN SITU HYBRIDIZATION (FISH)[ISCN 2020]</span>
              <el-table border style="width: 100%" :data="fluorTableData">
                <el-table-column prop="sampletype" label="Sample Type" width="200" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="Sample Type" v-model="scope.row.sampletype">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="Clinical Indication" width="300" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="Clinical Indication" v-model="scope.row.clinicalindication">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="testrequested	" label="Test Requested	" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="Test Requested" v-model="scope.row.testrequested">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="method" label="Method" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="Method" v-model="scope.row.method">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
              </el-table>
              <el-table border style="width: 100%" :data="fluorTableData">
                <el-table-column label="Sample Quality" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="Sample Quality" v-model="scope.row.SampleQuality">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="Prob Details" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="Prob Details" v-model="scope.row.ProbDetails">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="No Of cells" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="No Of cells" v-model="scope.row.NoOfcells">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
              </el-table>
            </div>
            <div class="edit-box mb-20">
              <span class="title">Result Table</span>
              <el-table border style="width: 100%" :data="resultTableData">
                <el-table-column prop="sno" label="S.No" width="70"></el-table-column>
                <el-table-column prop="result" label="Result (ISCN 2020)" width="300" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="Result (ISCN 2020)" v-model="scope.row.result">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="chromosomeloci" label="Chromosome Loci/Color" width="200" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="Chromosome Loci/Color"
                            v-model="scope.row.chromosomeloci">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="noofcells" label="No Of Cells" width="160" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="No Of Cells" v-model="scope.row.noofcells">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="finalresult" label="Result" width="140" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-select v-model="scope.row.finalresult" placeholder="Select">
                          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope">
                  <el-row :gutter="12">
                    <el-col :span="6" v-if="scope.row.sno === resultTableData?.length">
                      <el-buttton @click="onAddItem" class="c-pointer"><el-icon :size="20">
                          <CirclePlus />
                        </el-icon></el-buttton>
                    </el-col>
                    <el-col :span="6" v-if="resultTableData?.length > 1"><el-buttton
                        @click="onDeleteItem(scope.row)"><el-icon :size="19">
                          <Delete style="color: red;" />
                        </el-icon></el-buttton>
                    </el-col>
                  </el-row>

                </el-table-column>
              </el-table>

            </div>
            <div class="edit-box mb-20 p-5">
              <span class="title">Image</span>

              <div class="upload-btn p-10">
                <el-upload class="upload-demo report-title" action :on-change="onChangeImage" accept="image/*"
                  :auto-upload="false" multiple :show-file-list="false">
                  <el-button type="primary">Click to upload</el-button>
                </el-upload>

                <div class="el-upload__tip">
                  jpg/png files with a size less than 500KB.
                </div>
              </div>

              <div class="image-flex" v-if="previewImage?.length > 0">
                <div v-for="(item, index) in previewImage" :key="item" class="img-block">
                  <img alt="" :src="item.imageUrl">
                  <el-input v-model="item.imageContent"></el-input>
                  <el-button type="danger" @click="deleteImage(index)" class="remove-btn" circle>
                    <el-icon>
                      <Close />
                    </el-icon>
                  </el-button>
                </div>
              </div>
            </div>


            <div class="edit-box mb-20">
              <span class="title">Interpretation Guide</span>
              <el-input type="textarea" rows="2" v-model="patientInfo.Interpretation_Guide"></el-input>
            </div>
            <div class="edit-box mb-20">
              <span class="title">References</span>
              <el-input type="textarea" rows="2" v-model="patientInfo.References"></el-input>
            </div>


          </div>
        </div>
        <!-- Fih report code end -->
        <div class="addReportActionButtons">
          <ul class="action-buttons">
            <li v-if="!isEditForm">
              <el-button @click="addTemplate" class="submit-button">Submit</el-button>
            </li>

            <li v-if="isEditForm">
              <el-button @click="saveAsTemplate" class="submit-button">Update</el-button>
            </li>
          </ul>
        </div>

        <!-- GaucherLeukocytes not using this component-->
        <div v-if="patientInfo.sub_category == 'testGaucherLeukocytes' && show">
          <GaucherLeukocytes></GaucherLeukocytes>
        </div>
        <!-- GaucherLeukocytes -->

        <!-- FabryLeukocytes -->
        <div
          v-if="(patientInfo.sub_category == 'Fabry_Leukocytes' || patientInfo.sub_category == 'Gaucher_Leukocytes' || patientInfo.sub_category == 'Leukocytes_NCL2' || patientInfo.sub_category == 'Krabbe_Leukocytes' || patientInfo.sub_category == 'Glycoasparginase_Leukocytes') && show">
          <FabryLeukocytes :Fabry_Leukocytes_Array="patientInfo.Fabry_Leukocytes" :patientInfo="patientInfo"
            ref="childComponentRef" @childMethod="SavefabricData"></FabryLeukocytes>
        </div>
        <!-- FabryLeukocytes -->

        <!-- MPSILeukocytes -->
        <div
          v-if="(patientInfo.sub_category == 'MPS I_Leukocytes' || patientInfo.sub_category == 'MLD_Leukocytes') && show">
          <MPSILeukocytes :Fabry_Leukocytes_Array="patientInfo.Fabry_Leukocytes" :patientInfo="patientInfo"
            ref="childComponentRef" @childMethod="SavefabricData"></MPSILeukocytes>
        </div>
        <!-- MPSILeukocytes -->

        <!-- MLDLeukocytes component was not using-->
        <div v-if="patientInfo.sub_category == 'testMLDLeukocytes' && show">
          <MLDLeukocytes></MLDLeukocytes>
        </div>
        <!-- MLDLeukocytes -->

        <!-- KrabbeLeukocytes component was not using-->
        <div v-if="patientInfo.sub_category == 'testKrabbeLeukocytes' && show">
          <KrabbeLeukocytes></KrabbeLeukocytes>
        </div>
        <!-- KrabbeLeukocytes -->

        <!-- LeukocytesNCL2 not using this component-->
        <div v-if="patientInfo.sub_category == 'testLeukocytesNCL2' && show">
          <LeukocytesNCL2></LeukocytesNCL2>
        </div>
        <!-- LeukocytesNCL2 -->

        <!-- GlycoasparginaseLeukocytes  not using this component-->
        <div v-if="patientInfo.sub_category == 'test GlycoasparginaseLeukocytes' && show">
          <GlycoasparginaseLeukocytes></GlycoasparginaseLeukocytes>
        </div>
        <!-- GlycoasparginaseLeukocytes -->
        <!-- MucolipidosisPlasma -->
        <div v-if="patientInfo.sub_category == 'Mucolipidosis II and III_Plasma' && show">
          <MucolipidosisPlasma :mucolipidosisPlasmaArray="this.patientInfo.Mucolipidosis_Plasma" ref="childBGMLPlamaRef"
            @sendBGMLPlama="handleBGMLPlama"></MucolipidosisPlasma>
        </div>
        <!-- MucolipidosisPlasma -->

        <!-- GM1Leukocytes -->
        <div v-if="patientInfo.sub_category == 'GM1_Leukocytes' && show">
          <GM1Leukocytes :GM1_Leukocytes_Array="patientInfo.GM1_Leukocytes" :patientInfo="patientInfo"
            ref="childComponentRef" @childMethod="SaveGM1Data"></GM1Leukocytes>
        </div>
        <!-- GM1Leukocytes -->

        <!-- MPSIILeukocytes -->
        <div v-if="patientInfo.sub_category == 'MPS II_Leukocytes' && show">
          <MPSIILeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" ref="childmpsLeukocytesRef"
            @sendmpsLeukocytes="handlempsLeukocytes"></MPSIILeukocytes>
        </div>
        <!-- MPSIILeukocytes -->

        <!-- MPSIIIALeukocytes -->
        <div v-if="patientInfo.sub_category == 'MPS IIIA_Leukocytes' && show">
          <MPSIIIALeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" ref="childmpsLeukocytesRef"
            @sendmpsLeukocytes="handlempsLeukocytes"></MPSIIIALeukocytes>
        </div>
        <!-- MPSIIIALeukocytes -->

        <!-- MPSIIIBLeukocytes -->
        <div v-if="patientInfo.sub_category == 'MPS IIIB_Leukocytes' && show">
          <MPSIIIBLeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" ref="childmpsLeukocytesRef"
            @sendmpsLeukocytes="handlempsLeukocytes"></MPSIIIBLeukocytes>
        </div>
        <!-- MPSIIIBLeukocytes -->

        <!-- MPSIIICLeukocytes -->
        <div v-if="patientInfo.sub_category == 'MPS IIIC_Leukocytes' && show">
          <MPSIIICLeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" ref="childmpsLeukocytesRef"
            @sendmpsLeukocytes="handlempsLeukocytes"></MPSIIICLeukocytes>
        </div>
        <!-- MPSIIICLeukocytes -->

        <!-- MPSIIIDLeukocytes -->
        <div v-if="patientInfo.sub_category == 'MPS IIID_Leukocytes' && show">
          <MPSIIIDLeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" ref="childmpsLeukocytesRef"
            @sendmpsLeukocytes="handlempsLeukocytes"></MPSIIIDLeukocytes>
        </div>
        <!-- MPSIIIDLeukocytes -->

        <!-- MPSIVALeukocytes -->
        <div v-if="patientInfo.sub_category == 'MPS IVA_Leukocytes' && show">
          <MPSIVALeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" ref="childmpsLeukocytesRef"
            @sendmpsLeukocytes="handlempsLeukocytes"></MPSIVALeukocytes>
        </div>
        <!-- MPSIVALeukocytes -->

        <!-- MPSVILeukocytes -->
        <div v-if="patientInfo.sub_category == 'MPS VI_Leukocytes' && show">
          <MPSVILeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" ref="childmpsLeukocytesRef"
            @sendmpsLeukocytes="handlempsLeukocytes"></MPSVILeukocytes>
        </div>
        <!-- MPSVILeukocytes -->

        <!-- MPSVIILeukocytes -->
        <div v-if="patientInfo.sub_category == 'MPS VII_Leukocytes' && show">
          <MPSVIILeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" ref="childmpsLeukocytesRef"
            @sendmpsLeukocytes="handlempsLeukocytes"></MPSVIILeukocytes>
        </div>
        <!-- MPSVIILeukocytes -->

        <!-- AlphaFucosidosisLeukocytes -->
        <div v-if="patientInfo.sub_category == 'AlphaFucosidosisLeukocytes' && show">
          <AlphaFucosidosisLeukocytes></AlphaFucosidosisLeukocytes>
        </div>
        <!-- AlphaFucosidosisLeukocytes -->

        <!-- HLACLASSIIISABTYPING -->
        <div
          v-if="(this.patientInfo.sub_category == 'HLA SAB CLASS I II TYPING' || this.patientInfo.sub_category == 'HLA_SAB_CLASS_I_II_TYPING') && show">
          <HLACLASSIIISABTYPING :HLA_SAB_Array="this.patientInfo.HLA_SAB" :patientInfo="patientInfo"
            ref="childComponentRef" @childMethod="SaveHLASABData"></HLACLASSIIISABTYPING>
        </div>
        <!-- HLACLASSIIISABTYPING -->

        <!-- NCL1Leukocytes -->
        <div v-if="patientInfo.sub_category == 'NCL1_Leukocytes' && show">
          <NCL1Leukocytes :NCL1_Leukocytes="this.patientInfo.NCL1_Leukocytes" ref="childNCL1Leukocytes"
            @sendNCL1Leukocytes="handleNCL1Leukocytes"></NCL1Leukocytes>
        </div>
        <!-- NCL1Leukocytes -->

        <!-- NeuraminidaseFibroblasts -->
        <div v-if="patientInfo.sub_category == 'Neuraminidase_Fibroblasts' && show">
          <NeuraminidaseFibroblasts :neuraminidaseFibroblasts="this.patientInfo.Neuraminidase_Fibroblasts"
            ref="childNeuraminidaseFibroblasts" @sendNeuraminidaseFibroblasts="handleNeuraminidaseFibroblasts">
          </NeuraminidaseFibroblasts>
        </div>
        <!-- NeuraminidaseFibroblasts -->

        <!-- AcidLipaseLeukocytes -->
        <div
          v-if="patientInfo.sub_category == 'AcidLipaseLeukocytes' || patientInfo.sub_category == 'Acid Lipase_Leukocytes' && show">
          <AcidLipaseLeukocytes :acidLipaseLeukocytes="this.patientInfo.Acid_Lipase_Leukocytes"
            ref="childAcidLILeucocytesRef" @sendAcidLILeucocytes="handleAcidLILeucocytes"></AcidLipaseLeukocytes>
        </div>
        <!-- AcidLipaseLeukocytes -->

        <!-- NiemannPickABLeukocytes -->
        <div v-if="patientInfo.sub_category == 'Niemann Pick AB_Leukocytes' && show">
          <NiemannPickABLeukocytes :niemannPickABLeukocytes="this.patientInfo.Niemann_Pick_AB_Leukocytes"
            ref="childNiemannPickABLeukocytesRef" @sendNiemannPickABLeukocytes="handleNiemannPickABLeukocytes">
          </NiemannPickABLeukocytes>
        </div>
        <!-- NiemannPickABLeukocytes -->

        <!-- PompeLeukocytes -->
        <div v-if="patientInfo.sub_category == 'Pompe_Leukocytes' && show">
          <PompeLeukocytes :pompeLeukocytes="this.patientInfo.Pompe_Leukocytes" ref="childPompeLeukocytesRef"
            @sendPompeLeukocytes="handlePompeLeukocytes"></PompeLeukocytes>
        </div>
        <!-- PompeLeukocytes -->

        <!-- SchindlerLeukocytes -->
        <div v-if="patientInfo.sub_category == 'Schindler_Leukocytes' && show">
          <SchindlerLeukocytes :schindlerLeukocytes="this.patientInfo.Schindler_Leukocytes"
            ref="childSchindlerLeukocytesRef" @sendSchindlerLeukocytes="handlesendSchindlerLeukocytes">
          </SchindlerLeukocytes>
        </div>
        <!-- SchindlerLeukocytes -->

        <!-- TaysachSandhoffLeukocytes -->
        <div
          v-if="patientInfo.sub_category == 'TaysachSandhoffLeukocytes' || patientInfo.sub_category == 'Taysach,Sandhoff_Leukocytes' && show">
          <TaysachSandhoffLeukocytes :taysachSandhoffLeukocytes="this.patientInfo.Taysach_Sandhoff_Leukocytes"
            ref="childTaysachSandhoffLeukocytesRef"
            @sendTaysachSandhoffLeukocytes="handlesendTaysachSandhoffLeukocytes"></TaysachSandhoffLeukocytes>
        </div>
        <!-- TaysachSandhoffLeukocytes -->

        <!-- AlphaMannosidosisLeukocytes -->
        <div v-if="patientInfo.sub_category == 'AlphaMannosidosisLeukocytes' && show">
          <AlphaMannosidosisLeukocytes></AlphaMannosidosisLeukocytes>
        </div>
        <!-- AlphaMannosidosisLeukocytes -->

        <!-- BetaMannosidosisLeukocytes -->
        <div v-if="patientInfo.sub_category == 'BetaMannosidosisLeukocytes' && show">
          <BetaMannosidosisLeukocytes></BetaMannosidosisLeukocytes>
        </div>
        <!-- BetaMannosidosisLeukocytes -->

        <!-- BiotinidasePlasma -->
        <div v-if="patientInfo.sub_category == 'BiotinidasePlasma' && show">
          <BiotinidasePlasma></BiotinidasePlasma>
        </div>
        <!-- BiotinidasePlasma -->

        <!-- ChitotriosidasePlasma -->
        <div v-if="patientInfo.sub_category == 'ChitotriosidasePlasma' && show">
          <ChitotriosidasePlasma></ChitotriosidasePlasma>
        </div>
        <!-- ChitotriosidasePlasma -->

        <!-- CytologySlideReporting -->
        <div v-if="onCheckCytologySubCategory() && show">
          <CytologySlideReporting :patientInfo="patientInfo.cytopathology_details"
            :subCategory="patientInfo.sub_category" ref="childComponentRef" @childMethod="SaveCytopathologyReports">
          </CytologySlideReporting>
        </div>
        <!-- CytologySlideReporting -->

        <!-- LiquidBasedCytology -->
        <div v-if="patientInfo.sub_category == '_LiquidBasedCytology' && show">
          <LiquidBasedCytology></LiquidBasedCytology>
        </div>
        <!-- LiquidBasedCytology -->

        <!-- FnacSlidesReporting -->
        <div v-if="patientInfo.sub_category == '_FnacSlidesReporting' && show">
          <FnacSlidesReporting></FnacSlidesReporting>
        </div>
        <!-- FnacSlidesReporting -->

        <!-- PusForCytology -->
        <div v-if="this.onCheckCytopathologySubCategory() && show">
          <PusForCytology :PusForCytology_Array="this.patientInfo.PusFor_Cytology" :patientInfo="patientInfo"
            ref="childComponentRef" @childMethod="PusForCytologyData"></PusForCytology>
        </div>
        <!-- PusForCytology -->

        <!-- PapSmearAbove -->
        <div v-if="patientInfo.sub_category == 'PAP SMEAR ABOVE 40' && show">
          <PapSmearAbove :PusForPapSmearAbove_data="this.patientInfo.Cytopathology_SubCategoery"
            :patientInfo="patientInfo" ref="childComponentRef" @childMethod="CytopathologySubCategoery"></PapSmearAbove>
        </div>
        <!-- PapSmearAbove -->

        <!-- SmearForMalignantCells -->
        <div v-if="(patientInfo.sub_category == 'Smear For Malignant Cells') && show">
          <SmearForMalignantCells :smearForMalignantCells="this.patientInfo.SmearFor_MalignantCells"
            ref="childSmearForMalignantCells" @sendSmearForMalignantCells="handleSmearForMalignantCells">
          </SmearForMalignantCells>
        </div>
        <!-- SmearForMalignantCells -->

        <!-- UrineForCytology -->
        <div v-if="patientInfo.sub_category == 'UrineForCytology' && show">
          <UrineForCytology></UrineForCytology>
        </div>
        <!-- UrineForCytology -->

        <!-- PapSmearConventional -->
        <div v-if="patientInfo.sub_category == 'PAP SMEAR - CONVENTIONAL' && show">
          <PapSmearConventional :PusForPapSmearConventional_data="this.patientInfo.Cytopathology_SubCategoery"
            :patientInfo="patientInfo" ref="childComponentRef" @childMethod="CytopathologySubCategoery">
          </PapSmearConventional>
        </div>
        <!-- PapSmearConventional -->

        <!-- SputumForCytology -->
        <div v-if="patientInfo.sub_category == 'SputumForCytology' && show">
          <SputumForCytology></SputumForCytology>
        </div>
        <!-- SputumForCytology -->
        <div v-if="onCheckSubCategory() && show">
          <BioChemicalGeneticsGlobal :reportDetails="this.patientInfo" ref="childComponentRef"
            @childMethod="this.$route.params.template_id ? updateTemplate : saveTemplate"></BioChemicalGeneticsGlobal>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import axios from "@/config/axios";
// import { ElMessage } from "element-plus";
import CKEditor from "@ckeditor/ckeditor5-vue";
import HLACLASSIIISABTYPING from "../transplanantImmunoGenetics/HLACLASSIIISABTYPING"
import FabryLeukocytes from "../bioChemicalGenetics/FabryLeukocytes.vue";
import MPSIILeukocytes from "../bioChemicalGenetics/MPSIILeukocytes.vue";
import CytologySlideReporting from "../cytopathology/CytologySlideReporting.vue";
import LiquidBasedCytology from "../cytopathology/LiquidBasedCytology.vue";
import FnacSlidesReporting from "../cytopathology/FnacSlidesReporting.vue";
import PusForCytology from "../cytopathology/PusForCytology.vue";
import PapSmearAbove from "../cytopathology/PapSmearAbove.vue";
import SmearForMalignantCells from "../cytopathology/SmearForMalignantCells.vue";
import UrineForCytology from "../cytopathology/UrineForCytology.vue";
import PapSmearConventional from "../cytopathology/PapSmearConventional.vue";
import SputumForCytology from "../cytopathology/SputumForCytology.vue";
import GaucherLeukocytes from "../bioChemicalGenetics/GaucherLeukocytes.vue";
import MucolipidosisPlasma from "../bioChemicalGenetics/MucolipidosisPlasma.vue";
import GlycoasparginaseLeukocytes from "../bioChemicalGenetics/GlycoasparginaseLeukocytes.vue";
import GM1Leukocytes from "../bioChemicalGenetics/GM1Leukocytes.vue";
import KrabbeLeukocytes from "../bioChemicalGenetics/KrabbeLeukocytes.vue";
import LeukocytesNCL2 from "../bioChemicalGenetics/LeukocytesNCL2.vue";
import MLDLeukocytes from "../bioChemicalGenetics/MLDLeukocytes.vue";
import MPSILeukocytes from "../bioChemicalGenetics/MPSILeukocytes.vue";
import MPSIIIALeukocytes from "../bioChemicalGenetics/MPSIIIALeukocytes.vue";
import MPSIIIBLeukocytes from "../bioChemicalGenetics/MPSIIIBLeukocytes.vue";
import MPSIIICLeukocytes from "../bioChemicalGenetics/MPSIIICLeukocytes.vue";
import MPSIVALeukocytes from "../bioChemicalGenetics/MPSIVALeukocytes.vue";
import MPSVILeukocytes from "../bioChemicalGenetics/MPSVILeukocytes.vue";
import MPSVIILeukocytes from "../bioChemicalGenetics/MPSVIILeukocytes.vue";
import MPSIIIDLeukocytes from "../bioChemicalGenetics/MPSIIIDLeukocytes.vue";
import AlphaFucosidosisLeukocytes from "../bioChemicalGenetics/AlphaFucosidosisLeukocytes.vue";
import NCL1Leukocytes from "../bioChemicalGenetics/NCL1Leukocytes.vue";
import NeuraminidaseFibroblasts from "../bioChemicalGenetics/NeuraminidaseFibroblasts.vue";
import AcidLipaseLeukocytes from "../bioChemicalGenetics/AcidLipaseLeukocytes.vue";
import PompeLeukocytes from "../bioChemicalGenetics/PompeLeukocytes.vue";
import SchindlerLeukocytes from "../bioChemicalGenetics/SchindlerLeukocytes.vue";
import TaysachSandhoffLeukocytes from "../bioChemicalGenetics/TaysachSandhoffLeukocytes.vue";
import NiemannPickABLeukocytes from "../bioChemicalGenetics/NiemannPickABLeukocytes.vue";
import AlphaMannosidosisLeukocytes from "../bioChemicalGenetics/AlphaMannosidosisLeukocytes.vue";
import BetaMannosidosisLeukocytes from "../bioChemicalGenetics/BetaMannosidosisLeukocytes.vue";
import BiotinidasePlasma from "../bioChemicalGenetics/BiotinidasePlasma.vue";
import ChitotriosidasePlasma from "../bioChemicalGenetics/ChitotriosidasePlasma.vue";
import {
  genderTypes,
  radiologyReportTypes,
  histopathologyTypesMap,
  CytopathalogyMap,
  tmsTypes,
  departments,
  genomicsReportTypes,
  molecularGeneticsReportTypes,
  transplantImmunoGenetics,
  cytometryReportTypes,
  serologyImmunologyTypes,
  pharmacoGenomicsTypes,
  biochemistryTypes,
  cytogeneticsTypes,
  cytogeneticsTypesMap,
  corporateTypes,
  IHCHistopathology,
  histopathologyIHCTypesMap,
  immunoHematologyReportTypes
} from "@/constants/formConstants";
import { ElMessageBox } from "element-plus";
import { mapGetters } from "vuex";

import FormError from "@/components/FormError";
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { ref, onUnmounted, getCurrentInstance } from 'vue';
import EditorConfig from "@/config/editorConfig";
import BioChemicalGeneticsGlobal from "../BioChemicalGeneticsGlobal.vue";
// import corporateTemplate from "@/components/reportsTemplates/corporateTemplate"

export default {
  components: {
    ckeditor: CKEditor.component,
    FormError,
    Cropper,
    HLACLASSIIISABTYPING,
    FabryLeukocytes,
    GaucherLeukocytes,
    MPSIILeukocytes,
    CytologySlideReporting,
    LiquidBasedCytology,
    FnacSlidesReporting,
    PusForCytology,
    PapSmearAbove,
    SmearForMalignantCells,
    UrineForCytology,
    PapSmearConventional,
    SputumForCytology,
    MucolipidosisPlasma,
    GlycoasparginaseLeukocytes,
    GM1Leukocytes,
    KrabbeLeukocytes,
    LeukocytesNCL2,
    MLDLeukocytes,
    MPSILeukocytes,
    MPSIIIALeukocytes,
    MPSIIIBLeukocytes,
    MPSIIICLeukocytes,
    MPSIIIDLeukocytes,
    MPSIVALeukocytes,
    MPSVILeukocytes,
    MPSVIILeukocytes,
    AlphaFucosidosisLeukocytes,
    NCL1Leukocytes,
    NeuraminidaseFibroblasts,
    AcidLipaseLeukocytes,
    PompeLeukocytes,
    SchindlerLeukocytes,
    TaysachSandhoffLeukocytes,
    AlphaMannosidosisLeukocytes,
    BetaMannosidosisLeukocytes,
    BiotinidasePlasma,
    ChitotriosidasePlasma,
    NiemannPickABLeukocytes,
    BioChemicalGeneticsGlobal
  },

  data() {
    return {
     isDuplicateMarker:false,
     dialogFormVisible: false,
     IHCchangevalue:false,
     IHCMarkerLength:-1,
     labelPosition: 'top',
     multpleIhcCloneDetails:[],
     multpleIhcCloneLength:-1,
     isEditIHC:false,
     newIhcClone:'',
     ihcMarkerDetailsList: {
        ihcMarker: '',
        ihcClone: [],
        created_By: ''
      },
      ihcMarkerDetails: [],
      multipleClones: [],
      matchingSynaptophysin: [],
      matchingCD31:[],
      selectedFixationTime: null,
      customFixationTime: '',
      showCustomInput: false,
      histopathologyIHCTypes: histopathologyIHCTypesMap,
      immunoHematologyTypes:immunoHematologyReportTypes,
      histopathologyTypesMaps: histopathologyTypesMap,
      IHCtypes: IHCHistopathology,
      filterIHCtypes: IHCHistopathology,
      ihcDetails: [{ sno: 1, ihc_marker:'', ihc_clone: '', ihc_result: '', ihc_percentage:''}],
      ihcHer2NewDetails: [{ sno: 1, ihc_marker:'HER2(4B5)', ihc_clone: '', ihc_result: '',}],
      croppedImageData: '',
      croppedimage: '',
      croppedImageData1: '',
      croppedimage1: '',
      askSaveForm: false,
      value: "",
      genderTypes: genderTypes,
      cytogeneticsTypesMap: cytogeneticsTypesMap,
      showKaryotypingUI: false,
      previewImage: [],
      clinicalDetailsOptions: [
      { label: 'small biopsy 6 to 8 hours', value: 'small biopsy 6 to 8 hours' },
      { label: 'medium biopsy 10 to 20 hours', value: 'medium biopsy 10 to 20 hours' },
      { label: 'large biopsy 20 to 40 hours', value: 'large biopsy 20 to 40 hours' },
      // Add more options as needed
     ],
      departmentOptions: [
        "Department of Radiology",
        "Histopathology",
        "Biochemical Genetics"

      ],
      fluorTableData: [
        {
          sampletype: '',
          clinicalindication: '',
          testrequested: '',
          method: '',
          SampleQuality: '',
          ProbDetails: '',
          NoOfcells: ''
        },
      ],
      resultTableData: [{
        sno: 1,
        result: '',
        chromosomeloci: '',
        noofcells: '',
        finalresult: '',
      }],
      options: [{
        value: 'Positive',
        label: 'Positive'
      },
      {
        value: 'Negative',
        label: 'Negative'
      },
      {
        value: 'Mosaic',
        label: 'Mosaic'
      }
      ],
      departmentTypes: [
        {
          label: "Department of Bio-Chemistry",
          value: "BIO_CHEMISTRY",
        },
        {
          label: "Department of Radiology",
          value: "RADIOLOGY",
        },
        {
          label: "Department of Genomics and Molecular Diagnostics",
          value: "GENOMICS",
        },
        {
          label: "Department of Molecular Genetics",
          value: "MOLECULAR_GENETICS",
        },
        {
          label: "Department of Flow Cytometry",
          value: "FLOW_CYTOMETRY",
        },
        {
          label: "Department of Transplant Immunogenetics",
          value: "TRANSPLANT_IMMUNOGENETICS",
        },
        { label: "Histopathology", value: "HISTO_PATHOLOGY" },
        {
          label: "Biochemical Genetics",
          value: "BIOCHEMICAL_GENETICS",
        },
        {
          label: "Department of Serology / Immunology",
          value: "SEROLOGY_IMMUNOLOGY",
        },
        {
          label: "Department of Pharmacogenomics",
          value: "PHARMACO_GENOMICS",
        },
        {
          label: "Department of CYTOGENETICS",
          value: "CYTOGENETICS",
        },
        {
          label: "Department of CORPORATE",
          value: "CORPORATE",
        },
        {
          label: "Department of CYTOPATHOLOGY",
          value: "CYTOPATHOLOGY",
        },
        {
          label: "Department of MolecularDiagnostics",
          value: "MOLECULARDIAGNISTICS",
        },
        {
          label: "IHC - HISTOPATHOLOGY",
          value: "IHC_HISTOPATHOLOGY",
        },
        {
          label:"Department of ImmunoHematology",
          value:"IMMUNOHEMATOLOGY"
        }
      ],
      dropdownOptions: [
        {
          label: "Detected",
          value: "Detected"
        },
        {
          label: "Not Detected",
          value: "Not Detected"
        },
      ],
      templates: [],
      patientInfo: {
        indication: '',
        leukocyte_enzyme_activity: '',
        method: '',
        result: '',
        department: "",
        report_type: "",
        template_title: "",
        Microscopy: "",
        Impression: "",
        category: "",
        sub_category: "",
        Clinical: "",
        Site: "",
        BlockType: "",
        storageType: "",
        Malignant: "",
        karyotype: "",
        recommendations: "",
        interpretation: "",
        karyotypeSample_Type: "",
        karyotypeQualityOfSample: "",
        karyotypeClinicalIndication: "",
        karyotypeTestRequested: "",
        karyotypeTestMethodology: "",
        NoOfCellsCounted: "",
        NoOfCellsKaryotyped: "",
        EstimatedBandResolution: "",
        BandingMethod: "",
        height: null,
        weight: null,
        bp: null,
        pulse: null,
        past_complaints: "",
        present_complaints: "",
        family_history: "",
        weight_status: "",
        under_weight: "",
        healthy_weight: "",
        over_weight: "",
        obesity: "",
        right_eye_distance: "",
        right_eye_near: "",
        right_eye_colour_version: "",
        right_eye_correction: "",
        left_eye_distance: "",
        left_eye_near: "",
        left_eye_colour_version: "",
        left_eye_corrections: "",
        skin: "",
        pft: "",
        ecg: "",
        audiometry: "",
        chest_xray: "",
        hb: "",
        rbs: "",
        urine: "",
        corporate_remaks: "",
        ISCN: "",
        clinical_details: '',
        histo_ihc_no: '',
        ihc_final_diagnosis: '',
        ihc_comment: '',
        Intended_Use: '',
        Test_Method: '',
        Test_Limitations_Attributes: '',
        Disclaimer: '',
        CytogeneticsNumber: '',
        test_Result: "",
        patient_HLA_Type: "",
        Clinical_significance: "",
        detailed_report: "",
        Interpretation: "",
        References: "",
        cytopathology_details: [],       
      },
      ihcNote:'',
      ihcMarkersList: [
        {
          histo_ihc_no: '',
          ihc_final_diagnosis:'',
          ihc_comment: '',
          clinical_details: '',
          coldschemiatime:'',
          fixationtime:'',
          references:'a)HER2 Testing in Breast Cancer - 2023 Guidline Source:www.cap.org/protocols-and-\n guidlines\n b)Antonio C. Wolff;Mark R.Somerfield; Mitchell Dosett;M. Elizabeth H.Hammond;Daniel F.\n Hayes;Lisa M.McShane;Thomas J. Saphner; Patrica A.Speakers;Kimberly H. Allison;Journal of\n Clinical Oncology 2023 413867-3872.\n c)Wolff AC,Hammod Meh,Allison KH, et al:Human epidermal growth factor receptor 2\n testing in breast cancer:American Society of Clinical Oncology/College of American\n Pathologists clinical practice guideline focused update.J Clin Oncol 36:2105-2122,2018',
          note:'1. Patient with breast cancers that are HER2 IHC 3+ or IHC 2+/ISH amplified may be eligible\n for several therapies that disrupt HER2 signalling pathways.Invasive breast cancers that test\n "HER2-negative"(IHC 0,1+ or 2+/ISH not-amplified) are more specifically considered "HER2 \n -negative for protein overexpression/gene amplification" since non-overexpressed levels of the \n HER2 protein may be present in these cases.Patients with breast cancers that are HER2 IHC \n 1+ or IHC 2+/ISH not-amplified may be eligible for a treatment that targets non \n -amplified/non-overexpressed levels of HER2 expression for cytotoxic drug delivery (IHC 0 \n results do not results in eligibility currently)\n 2.IHC 1+ or 0 results are still both interpreted as HER2-negative (HER2 is not overexpressed)\n using the previously recommended scoring criteria. Importantly, the semiquantitative IHC\n score must always be reported as well to ensure patients who meet eligibility criteria for\n transtuzumab deruxtecan be identified.',
          disclaimer:'Preanalytical issues for blocks received  from out side are not addressed by this lab',
          her2neuscoring:'Her 2 neu Scoring (ASCO guideline - 2018):\n\0: Negative\n 1+: Faint / barely perceptible membrane staining in >10% of invasive tumor cells; cell only stained in part of membrane.\n 2+ (Equivocal): Weak-to-moderate complete membrane staining in > 10% of invasive tumor cells. Needs confirmation by FISH\n 3+ Positive (Strong complete membrane staining in >10% of invasive tumor cells.',
          IHCResultTable:[],
          IHCHer2New:[] 
        },
      ],
      isaddendumChecked: false,
      grossdescriptiontype: 'Others',
      unSavedChanges: false,
      loading: false,
      loadingText: "",
      editor: ClassicEditor,
      show: false,
      editorData: "",
      searchVisitId: "",
      reportType: "",
      Impression: "",
      Microscopy: "",
      Description: "",
      showReportType: true,
      editReportType: false,
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      editorConfig: EditorConfig,
      showAddTemplate: false,
      reportTypeNew: "",
      GramPostiveOrganisms: [{
        staphylococcus_Aureus: '',
        staphylococcus_Epidermidis: '',
        streptococcus_Pneumoniae: '',
        streptococcus_Pyogenes: '',
        streptococcus_Agalactiae: '',
        enterococcus_Fecalis: '',
        enterococcus_Faecium: '',
        listeria_Monocytogenes: ''
      }],
      GramNegativeOrganisms: [{
        Escherichia_Coli: '',
        Klebsiella_pneumoniae: '',
        Klebsiella_Oxytoca: '',
        Enterobacter_species: '',
        Salmonella_spp: '',
        Acinetobacter_baumannii: '',
        Pseudomonas_aeruginosa: '',
        Haemophilus_influenzae: '',
        Neisseria_meningitidis: ''

      }],
      Yeast: [{
        Candida_auris: '',
        Candida_albicans: '',
        Candida_lusitaniae: '',
      }],
      Anti_microbial_resistance_gene: [{
        sul2: '',
        dfrA1: '',
        dfrA5: '',
        bla_CTX_M_gr1: '',
        bla_TEM: '',
        bla_SHV: '',
        bla_KPC: '',
        bla_NDM: '',
        bla_VIM: '',
        bla_OXA_48: '',
        bla_IMP: '',
        mecA: '',
        vanA: '',
        vanB: ''
      }],
      Drugs_Interpretation: [{
        Cotrimoxazole: '',
        Cefoperazone: '',
        Pencillin: '',
        Ampicillin: '',
        Amoxicillin_clavulanic_acid: '',
        Piperacillin: '',
        Cefazolin: '',
        Cefoxitin: '',
        Cefuroxime: '',
        Cefotaxime: '',
        Ceftriaxone: '',
        Ceftazidime: '',
        Cefatazidime: '',
        Cefepime: '',
        Cefoperazone_sulbactam: '',
        Ceftazidime_avibactam: '',
        Cefatazidime_Avibactam: '',
        Gentamicin: '',
        Amikacin: '',
        Tigecycline: '',
        Polymyxin: '',
        Imipenem: '',
        Meropenem: '',
        Ertapenem: '',
        Aztreonam: '',
        Methicillin: '',
        Vancomycin: '',
        Teicoplanin: ''

      }],
      editorsData: [
        {
          title: "",
          template_body: "",
        },
      ],
    };
  },
  computed: {   
    sortedIHCtypes() {
      const sortedArray = [...this.IHCtypes];
      const uniqueNamesSet = new Set();
      const uniqueSortedArray = sortedArray.filter(item => {
        if (!uniqueNamesSet.has(item.ANTIBODIESNAME)) {
          uniqueNamesSet.add(item.ANTIBODIESNAME);
          return true;
        }
        return false;
      });
      return uniqueSortedArray.sort((a, b) => a.ANTIBODIESNAME?.localeCompare(b.ANTIBODIESNAME));

    },

    shouldRenderElement() {
      // Check if patientInfo.report_type is not empty and then check if it includes sub_category
      if (this.patientInfo.department == "Department of CORPORATE" && this.patientInfo.category == "CORPORATE") {
        return this.patientInfo.report_type && this.patientInfo.report_type.includes(this.patientInfo.sub_category);
      } else {
        return false;
      }
    },

    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("patientReports", [
      "getPatientAddStatus",
      "getSinglePatientReports",
      "getPatientUpdateStatus",
      "getPatientDetails",
    ]),
    ...mapGetters("auth", ["getProfileDetails"]),
    ...mapGetters("reportTemplates", [
      "getSingleReportTemplate",
      "getReportTemplateUpdateStatus",
      "getReportTemplateAddStatus",
      "getAllIhcMarkerDetails",
      "getIhcMarkersDetailsResponce",
      "getUpdatedIHCDetailsResponce"
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),

    getSubCategories() {
      if (!this.patientInfo.department) {
        return [];
      } else if (this.patientInfo.department == "Histopathology") {
        return histopathologyTypesMap;
      }
      else if (this.patientInfo.department == "Department of CYTOPATHOLOGY") {
        return CytopathalogyMap;
      }
      else if (this.patientInfo.department == "Department of CYTOGENETICS") {
        return cytogeneticsTypes;
      }
      else if (this.patientInfo.department == "Department of CORPORATE") {
        return corporateTypes;
      }
      else if (this.patientInfo.department == "Department of Radiology") {
        return radiologyReportTypes;
      } else if (this.patientInfo.department == "Biochemical Genetics") {
        return tmsTypes;
      } else if (
        this.patientInfo.department ==
        "Department of Genomics and Molecular Diagnostics"
      ) {
        return genomicsReportTypes;
      } else if (
        this.patientInfo.department == "Department of Molecular Genetics"
      ) {
        return molecularGeneticsReportTypes;
      } else if (
        this.patientInfo.department == "Department of Flow Cytometry"
      ) {
        return cytometryReportTypes;
      } else if (
        this.patientInfo.department == "Department of Transplant Immunogenetics"
      ) {
        return transplantImmunoGenetics;
      } else if (
        this.patientInfo.department == "Department of Serology / Immunology"
      ) {
        return serologyImmunologyTypes;
      } else if (
        this.patientInfo.department == "Department of Pharmacogenomics"
      ) {
        return pharmacoGenomicsTypes;
      } else if (this.patientInfo.department == "Department of Bio-Chemistry") {
        return biochemistryTypes;
      } else if (this.patientInfo.department == "IHC - HISTOPATHOLOGY") {
        return this.histopathologyIHCTypes;
      } else if (this.patientInfo.department == "Department of ImmunoHematology") {
        return this.immunoHematologyTypes;
      } else {
        return [];
      }
    },
    getDepartmetns() {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.departments) {
        let userDepartments = this.getAuthenticatedUser.departments;
        return this.departmentTypes.filter((obj) =>
          userDepartments.includes(obj.value)
        );
      }
      return this.departmentTypes;
    },
    isReportTypeUpdateDisable() {
      return this.reportTypeNew &&
        this.reportTypeNew.trim() &&
        this.reportTypeNew.trim().length
        ? false
        : true;
    },
    isEditForm() {
      return this.$route.params && this.$route.params.template_id
        ? true
        : false;
    },
    isDraft() {
      if (
        this.isEditForm &&
        this.patientInfo.report_status &&
        this.patientInfo.report_status != "DRAFT"
      ) {
        return false;
      }
      return true;
    },

    noContent() {
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0].innerHTML;
      return data.length ? false : true;
    },
    isMolecularGenetics() {
      return (
        this.patientInfo &&
        (this.patientInfo.department === "Department of Molecular Genetics" ||
         this.patientInfo.department === "Biochemical Genetics" || 
         this.patientInfo.department === "Department of CYTOPATHOLOGY" ||
         this.patientInfo.department === "Department of Serology / Immunology" ||
         this.patientInfo.department === "Department of Immuno Hematology" ||
         this.patientInfo.department === "IHC - HISTOPATHOLOGY") &&
        (this.patientInfo.sub_category ===
        "COMPREHENSIVE RESPIRATORY PANEL 1 BACTERIA/VIRUS" ||
        this.patientInfo.sub_category ===
          "COMPREHENSIVE RESPIRATORY PANEL 2 BACTERIA/VIRUS/FUNGI" ||
        this.patientInfo.sub_category ===
          "COMPREHENSIVE RESPIRATORY PANEL 3 BACTERIA/VIRUS/FUNGI" ||
        this.patientInfo.sub_category ===
          "CLOSTRIDIUM DIFFICILE DETECTION PCR" ||
        this.patientInfo.sub_category ===
          "VRSA DETECTION QUALITATIVE BY REAL TIME PCR" ||
        this.patientInfo.sub_category === 
          "CBNAAT MTB/XDR" || 
        this.patientInfo.sub_category === 
        "CBNAAT GENEXPERT MTB WITH RIFAMPICIN QUALITATIVE" ||
        this.patientInfo.sub_category === "VITAMIN - A  ALL TRANSRETINOL"||
        this.patientInfo.sub_category === "VITAMIN - C TOTAL ASCORBIC"||
        this.patientInfo.sub_category === "VITAMIN - E ALPHATOCOPHEROL" ||
        this.patientInfo.sub_category === "FLU PANEL - BY REAL TIME PCR" ||
        this.patientInfo.sub_category === "HEPATITIS B VIRUS (HBV) GENOTYPE" ||
        this.patientInfo.sub_category === "LEPTOSPIRA DETECTION PCR" ||
        this.patientInfo.sub_category === "MEASLES PCR" ||
        this.patientInfo.sub_category === "MDR MTB WITH RIFAMPICIN QUALITATIVE" ||
        this.patientInfo.sub_category === "HIV 1 DRUG RESISTANCE - GENOTYPING" ||
        this.patientInfo.sub_category === "MRSA SCREENING BY QUALITATIVE PCR" ||
        this.patientInfo.sub_category === "MYCOBACTERIUM TUBERCULOSIS (MTB) &amp; NON-TUBERCULOUS MYCOBACTERIUM (NTM) DIFFERENTIATION BY QUALITATIVE PCR" ||
        this.patientInfo.sub_category === "NON-TUBERCULOUS MYCOBACTERIUM (NTM) QUALITATIVE PCR" ||
        this.patientInfo.sub_category === "SEPSIS PANELWITH AMR GENES - BY MULTIPLEX PCR V2" ||
        this.patientInfo.sub_category === "VITAMIN - B1" ||
        this.patientInfo.sub_category === "VITAMIN - K1" ||
        this.patientInfo.sub_category === "VITAMIN B2; RIBOFLAVIN" ||
        this.patientInfo.sub_category === "VITAMIN - B3" ||
        this.patientInfo.sub_category === "VITAMIN - B5" ||
        this.patientInfo.sub_category === "VITAMIN - B6" ||
        this.patientInfo.sub_category === "VITAMIN - B7" ||
        this.patientInfo.sub_category === "VITAMIN - B9" ||
        this.patientInfo.sub_category === "METHYLMALONIC ACID QUANTITATIVE - SERUM" ||
        this.patientInfo.sub_category === "CELL BLOCK" ||
        this.patientInfo.sub_category === "SYNOVIAL FLUID/JOINT INFECTION - MULTIPLEX PCR" ||
        this.patientInfo.sub_category === "TORCH PANEL REALTIME PCR" ||
        this.patientInfo.sub_category === "VRE DETECTION BY QUALITATIVE PCR" ||
        this.patientInfo.sub_category === "CRYPTOCOCCUS QUALITATIVE PCR" ||
        this.patientInfo.sub_category === "CHIKUNGUNYA RNA DETECTION BY PCR" ||
        this.patientInfo.sub_category === "CANDIDA AURIS QUALITATIVE PCR" ||
        this.patientInfo.sub_category === "ANTI-MICROBIAL RESISTANCE GENE DETECTION - BY MULTIPLEX PCR" ||
        this.patientInfo.sub_category === "SENSISURE SINGLE ALLERGEN" ||
        this.patientInfo.sub_category === "SENSISURE AERO ALLERGENS" ||
        this.patientInfo.sub_category === "SENSISURE ALLERGY SCREENING" ||
        this.patientInfo.sub_category === "RED CELL ANTIBODY SCREENING"||
        this.patientInfo.sub_category === "BLOOD GROUP" ||
        this.patientInfo.sub_category === "DIRECT ANTIGLOBULIN TEST"||
        this.patientInfo.sub_category === "INDIRECT ANTIGLOBULIN TEST" ||
        this.patientInfo.sub_category === "METHYLMALONIC ACID QUALITATIVE - URINE" ||
        this.patientInfo.sub_category === "METHYLMALONIC ACID QUANTITATIVE - URINE" ||
        this.patientInfo.sub_category === "EXTENDED SPECTRUM BETA-LACTAMS (ESBL) RESISTANCE GENE DETECTION BY MULTIPLEX PCR" ||
        this.patientInfo.sub_category === "TOXOPLASMA QUALITATIVE PCR" ||
        this.patientInfo.sub_category === "ASPERGILLUS QUALITATIVE PCR" ||
        this.patientInfo.sub_category === "MUCORALES QUALITATIVE PCR" ||
        this.patientInfo.sub_category === "FUNGAL MULTIPLEX PCR PANEL" ||
        this.patientInfo.sub_category === "PAN FUNGAL DNA PCR" ||
        this.patientInfo.sub_category === "CAREBAPENEM PCR" ||
        this.patientInfo.sub_category === "SCRUB TYPHUS PCR" ||
        this.patientInfo.sub_category === "RICKETSSIA PCR" ||
        this.patientInfo.sub_category === "EGFR" ||
        this.patientInfo.sub_category === "KRAS" ||
        this.patientInfo.sub_category === "SENSISURE ANY 10 ALLERGENS" ||
        this.patientInfo.sub_category === "SENSISURE ANY 20 ALLERGENS" ||
        this.patientInfo.sub_category === "SENSISURE ANY 30 ALLERGENS" ||
        this.patientInfo.sub_category === "SENSISURE ANY 5 ALLERGENS" ||
        this.patientInfo.sub_category === "SENSISURE COMPREHENSIVE PANEL 48 PARAMETERS" ||
        this.patientInfo.sub_category === "SENSISURE ECZEMA MAXI 27 PARAMETERS" ||
        this.patientInfo.sub_category === "SENSISURE ECZEMA MINI 20 PARAMETERS" ||
        this.patientInfo.sub_category === "SENSISURE FRUIT 6 PARAMETERS" ||
        this.patientInfo.sub_category === "SENSISURE NONVEG 8 PARAMETERS" ||
        this.patientInfo.sub_category === "SENSISURE RHINITIS MAXI 22 PARAMETERS" ||
        this.patientInfo.sub_category === "SENSISURE RHINITIS MINI 11 PARAMETERS" ||
        this.patientInfo.sub_category === "SENSISURE VEG 22 PARAMETERS" ||
        this.patientInfo.sub_category === "SENSISURE VEG AND NONVEG 26 PARAMETERS" ||
        this.patientInfo.sub_category === "PHADIA TOP ALLERGY ECZEMA PANEL" ||
        this.patientInfo.sub_category === "PHADIA TOP ALLERGY FOOD PANEL VEG AND NONVEG" ||
        this.patientInfo.sub_category === "PHADIA TOP ALLERGY INHALATION PANEL 20 PARAMETERS" ||
        this.patientInfo.sub_category === "PHADIA TOP ALLERGY NON VEG" ||
        this.patientInfo.sub_category === "PHADIA TOP ALLERGY PANEL VEGETARIAN" ||
        this.patientInfo.sub_category === "PHADIA TOP ALLERGY PEANUT" ||
        this.patientInfo.sub_category === "PHADIA TOP ALLERGY RHINITIS PANEL" ||
        this.patientInfo.sub_category === "PHADIA TOP ALLERGY SCREENING DUST PANEL" ||
        this.patientInfo.sub_category === "PHADIATOP ALLERGY SCREENING" ||
        this.patientInfo.sub_category === "INHALATIONAL SPECIFIC PANEL BY PHADIA TOP" ||
        this.patientInfo.sub_category === "PHADIA TOP ALLERGY MILK PRODUCTS PANEL" ||
        this.patientInfo.sub_category === "PHADIA TOP ALLERGY ASTHMA PANEL" ||
        this.patientInfo.sub_category === "MSI BY SANGER SEQUENCING" ||
        this.patientInfo.sub_category === "IHC HER 2 NEU")
      );
    }
  },
 async mounted() {
    this.loadInitData();
    await this.$store.dispatch("reportTemplates/fetchAllIhcDetails");
    if(this.getAllIhcMarkerDetails && this.getAllIhcMarkerDetails.data){
      this.ihcMarkerDetails = this.getAllIhcMarkerDetails.data;
      this.ihcMarkerDetails = this.ihcMarkerDetails.sort((a, b) => a.ihcMarker.localeCompare(b.ihcMarker));
    }
    // this.fetchReportTemplates();
  },

  setup() {
    const cropper = ref(null);
    const image = ref({ src: null, file: null, type: null }); // Define image with initial src and type as null
    const file = ref(null);
    const imageFormateError = ref(false);
    const instance = getCurrentInstance();
    const allowedTypes = ["image/jpeg", "image/png"];
    const uploadImage = (event) => {
      const { files } = event.target;
      if (allowedTypes.includes(files[0].type)) {
        if (files && files[0]) {
          imageFormateError.value = false;
          if (image.value.src) {
            URL.revokeObjectURL(image.value.src); // Accessing image's value
          }
          const blob = URL.createObjectURL(files[0]);
          image.value.src = blob; // Accessing image's value
          image.value.type = files[0].type; // Accessing image's value
          image.value.file = files[0];
        }
      } else {
        imageFormateError.value = true
      }
    };
    onUnmounted(() => {
      if (image.value.src) {
        URL.revokeObjectURL(image.value.src); // Accessing image's value
      }
    });

    const cropper1 = ref(null);
    const image1 = ref({ src: null, type: null });
    const file1 = ref(null);
    const allowedTypes1 = ["image/jpeg", "image/png",];
    const uploadDoctorSignatureImage = (event) => {
      const { files } = event.target;
      if (allowedTypes1.includes(files[0].type)) {
        if (files && files[0]) {
          if (image1.value.src) {
            URL.revokeObjectURL(image1.value.src);
          }
          const blob = URL.createObjectURL(files[0]);
          image1.value.src = blob;
          image1.value.type = files[0].type;
          image1.value.file = files[0];
        }
      } else {
        instance.appContext.config.globalProperties.$notify.error({
          title: "Error",
          message: "Please upload an image with JPEG, PNG, or JPG format.",
        });
        return;
      }
    };

    onUnmounted(() => {
      if (image1.value.src) {
        URL.revokeObjectURL(image1.value.src);
      }
    });
    return {
      imageFormateError,
      image1,
      cropper1,
      file1,
      uploadDoctorSignatureImage,
      image,
      cropper,
      file,
      uploadImage,
    };


  },




  methods: {
    onChangeIHCMarker(Marker){
      const newIhcCloneLower = this.newIhcClone      
      const duplicateIHC = this.ihcMarkerDetails.filter(data => 
        data.ihcMarker === Marker.ihcMarker && 
        data.ihcClone.map(val => val).includes(newIhcCloneLower)
    );
    if (duplicateIHC.length > 0) {
        this.isDuplicateMarker = true;
    } else {
        this.isDuplicateMarker = false;
    }    
    this.IHCchangevalue = false;
    },
    handleFixationTimeChange(value) {
      if (value) {
        this.showCustomInput = true;
        this.customFixationTime = value;       
      } else {
        this.ihcMarkersList[0].fixationtime=value
        this.customFixationTime = value;
        this.showCustomInput = false;
      }
    },
    removeEditor(index) {
      if (this.editorsData.length > 1) {
        this.editorsData.splice(index, 1);
      }
    },
    addNewEditor() {
      let editor = {
        title: "",
        template_body: ""
      };
      this.editorsData.push(editor);
    },
    editIHCDetails(row){     
     this.isEditIHC=true 
     this.isDuplicateMarker=false
     this.IHCchangevalue=true
     this.multpleIhcCloneDetails = this.ihcMarkerDetails.filter(data => data.ihcMarker === row.ihc_marker).map( data1 => data1.ihcClone)
     const filterIHCMarkers = this.ihcMarkerDetails.filter( data => data.ihcMarker === row.ihc_marker)
     this.IHCMarkerLength=row.sno  
     this.newIhcClone=row.ihc_clone    
     this.ihcMarkerDetailsList = {
        ihcMarker: row.ihc_marker,
        ihcClone: this.newIhcClone,
        ihc_id:filterIHCMarkers[0]._id
      }    
      let valueToMatch = this.multpleIhcCloneDetails[0];
      this.multpleIhcCloneLength = valueToMatch.findIndex(data => data === this.newIhcClone);
    },
   addIHCDetails(){   
     this.isEditIHC=false
     this.newIhcClone='' 
     this.IHCMarkerLength=-1
     this.multpleIhcCloneLength=-1
     this.isDuplicateMarker=false
     this.ihcMarkerDetailsList = {
        ihcMarker:'',
        ihcClone:[],
      }      
    },

    async saveIHCDetails() {
      const duplicate = this.ihcMarkerDetails.filter( data => data.ihcMarker === this.ihcMarkerDetailsList.ihcMarker)
      if(duplicate.length > 0){
       return this.updateIHCDetails('marker')
      }
      this.ihcMarkerDetailsList.ihcClone.push(this.newIhcClone);
      let params = {
        ...this.ihcMarkerDetailsList,
        created_By: this.getProfileDetails.first_name + +'' + this.getProfileDetails.last_name
      }
      await this.$store.dispatch("reportTemplates/saveIhcDetails", {
        ...params
      });
      if (this.getIhcMarkersDetailsResponce.data) {
        await this.$store.dispatch("reportTemplates/fetchAllIhcDetails", params);
        this.ihcMarkerDetails = this.getAllIhcMarkerDetails.data;
        this.ihcMarkerDetails = this.ihcMarkerDetails.sort((a, b) => a.ihcMarker.localeCompare(b.ihcMarker));       
      } 
      this.ihcDetails =this.ihcDetails.filter(data => data.ihc_marker!='')
      this.ihcDetails.push({
        sno: this.ihcDetails?.length +1,
        ihc_marker: this.ihcMarkerDetailsList.ihcMarker,
        ihc_clone: this.newIhcClone,
        ihc_result: '',
        ihc_percentage:''
      });     
      this.ihcMarkerDetailsList = {
        ihcMarker:'',
        ihcClone:[],
       }
    },

    async updateIHCDetails(marker) {
      this.ihcMarkerDetailsList.ihcClone=[]
      // this.ihcMarkerDetails.filter(data => data.ihcMarker === this.ihcMarkerDetailsList.ihcMarker && data.ihcMarker)
      if(this.multpleIhcCloneLength >= 0 && this.multpleIhcCloneLength < this.multpleIhcCloneDetails[0]?.length) {
        const filterData = this.ihcMarkerDetails.filter( data => data.ihcMarker === this.ihcMarkerDetailsList.ihcMarker)
        this.multpleIhcCloneDetails[0][this.multpleIhcCloneLength] = this.newIhcClone;
        this.ihcMarkerDetailsList.ihc_id=filterData[0]._id
        this.ihcMarkerDetailsList.ihcClone = this.multpleIhcCloneDetails[0]
      }
       const duplicate = this.ihcMarkerDetails.filter( data => data.ihcMarker === this.ihcMarkerDetailsList.ihcMarker && marker)
      if(duplicate.length > 0 && marker!=''){
        duplicate[0].ihcClone.push(this.newIhcClone)
        this.ihcMarkerDetailsList.ihcClone=duplicate[0].ihcClone
        this.ihcMarkerDetailsList.ihc_id=duplicate[0]._id
      }
      let params = {
        ...this.ihcMarkerDetailsList,
        updated_By: this.getProfileDetails.first_name + +'' + this.getProfileDetails.last_name
      }
      await this.$store.dispatch("reportTemplates/updateIHCDetails", {
        ...params
      }); 
      if (this.getUpdatedIHCDetailsResponce) {
        await this.$store.dispatch("reportTemplates/fetchAllIhcDetails", params);
        this.ihcMarkerDetails = this.getAllIhcMarkerDetails.data;  
        this.ihcMarkerDetails = this.ihcMarkerDetails.sort((a, b) => a.ihcMarker.localeCompare(b.ihcMarker));      
      }      
      if(this.IHCMarkerLength!=-1){
        this.ihcDetails = this.ihcDetails.map((item) => {
        if (item.sno === this.IHCMarkerLength) {
          return {
            sno: item.sno,
            ihc_marker: this.ihcMarkerDetailsList.ihcMarker,
            ihc_clone: this.newIhcClone,
            ihc_result: item.ihc_result,
            ihc_percentage: item.ihc_percentage
          };
        } else {
          return item; // retain unchanged item
        }
      });
      }         
    },
     
    handleBGMLPlama(result) {
      this.patientInfo.Mucolipidosis_Plasma = result;
    },

    handleAcidLILeucocytes(result) {
      this.patientInfo.Acid_Lipase_Leukocytes = result;
    },

    handlesendTaysachSandhoffLeukocytes(result) {
      this.patientInfo.Taysach_Sandhoff_Leukocytes = result;
    },
    handlesendSchindlerLeukocytes(result) {
      this.patientInfo.Schindler_Leukocytes = result;
    },

    handlePompeLeukocytes(result) {
      this.patientInfo.Pompe_Leukocytes = result;
    },
    handleNiemannPickABLeukocytes(result) {
      this.patientInfo.Niemann_Pick_AB_Leukocytes = result;
    },
    handleNCL1Leukocytes(result) {
      this.patientInfo.NCL1_Leukocytes = result;
    },
    handleNeuraminidaseFibroblasts(result) {
      this.patientInfo.Neuraminidase_Fibroblasts = result;
    },
    handlempsLeukocytes(result) {
      this.patientInfo.BiochemicalGenitics = result;
    },

    SavefabricData(result) {
      this.patientInfo.Fabry_Leukocytes = result
    },
    SaveGM1Data(result) {
      this.patientInfo.GM1_Leukocytes = result
    },
    SaveHLASABData(result) {
      this.patientInfo.HLA_SAB = result;
    },
    SaveCytopathologyReports(result) {
      this.patientInfo.cytopathology_details = result
    },

    handleDropdownChange(rows) {
          const selectedMarker = rows.ihc_marker;
          rows.ihc_clone=''
          const filteredItems = this.ihcMarkerDetails.filter(item => item.ihcMarker === selectedMarker);
          let clones = [];
        // If there are multiple ihcClone values in filteredItems, iterate through them
        if (filteredItems.some(item => Array.isArray(item.ihcClone))) {
          filteredItems.forEach(item => {
            clones.push(...item.ihcClone);
          });
        } else {
          // Otherwise, directly push the single ihcClone
          filteredItems.forEach(item => {
            clones.push(item.ihcClone);
          });
        }
        const existingClones = this.ihcDetails.filter(detail => detail.ihc_marker === selectedMarker).map(detail => detail.ihc_clone);
        clones = clones.filter(clone => !existingClones.includes(clone));
          if(clones.length==1){
            rows.ihc_clone = clones[0];
          }                         
      },

      filteredClones(marker) {
        const filteredItems = this.ihcMarkerDetails.filter(item => item.ihcMarker === marker.ihc_marker);
        let clones = [];
        // If there are multiple ihcClone values in filteredItems, iterate through them
        if (filteredItems.some(item => Array.isArray(item.ihcClone))) {
          filteredItems.forEach(item => {
            clones.push(...item.ihcClone);
          });
        } else {
          // Otherwise, directly push the single ihcClone
          filteredItems.forEach(item => {
            clones.push(item.ihcClone);
          });
        }
        const existingClones = this.ihcDetails.filter(detail => detail.ihc_marker === marker.ihc_marker).map(detail => detail.ihc_clone);
        clones = clones.filter(clone => !existingClones.includes(clone));
          if (clones.length === 0 && filteredItems.length > 0) {
        // Set error message if no clones are available but filteredItems are present
          marker.errorMessage = 'No available clones';
          } else {
              // Clear error message if there are available clones
              marker.errorMessage = "";
          }     
        return clones;
      },     

    onAddIHC() {
      this.ihcDetails.push({
        sno: this.ihcDetails?.length + 1,
        ihc_marker: '',
        ihc_clone: '',
        ihc_result: '',
        ihc_percentage:''
      });
    },
    onDeleteIHC(index) {
      if (this.ihcDetails.length > 1) {
        this.ihcDetails.splice(index.sno - 1, 1)
        this.ihcDetails.forEach((data, index) => {
          data.sno = index + 1
        })
      }
    },
    cropImage() {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { canvas } = cropper.getResult();
        this.croppedImageData = canvas.toDataURL(this.image.type);
        this.croppedimage = this.croppedImageData
      }
    },
    cropDoctorSignatureImage() {
      const cropper1 = this.$refs.cropper1;
      if (cropper1) {
        const { canvas } = cropper1.getResult();
        this.croppedImageData1 = canvas.toDataURL(this.image1.type);
        this.croppedimage1 = this.croppedImageData1
      }
    },
    dataURLtoFile(dataUrl, filename, type) {
      const arr = dataUrl.split(',');
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().replace(/[-T:Z.]/g, "");
      //  const randomNumber = Math.floor(Math.random() * 1000); // Change 1000 to your desired range
      const extension = filename.split('.').pop();
      const nameWithoutExtension = filename.substring(0, filename.lastIndexOf('.'));
      const newFilename = `${nameWithoutExtension}_${formattedDate}.${extension}`
      return new File([u8arr], newFilename, { type: type });
    },
    onAddItem() {
      this.resultTableData.push({
        sno: this.resultTableData?.length + 1,
        result: '',
        chromosomeloci: '',
        noofcells: '',
        finalresult: '',
      })

    },
    onDeleteItem(index) {
      this.resultTableData.splice(index.sno - 1, 1)
      this.resultTableData.forEach((data, index) => {
        data.sno = index + 1
      })
    },
    deleteImage(index) {
      this.previewImage.splice(index, 1)
    },
    // onChangeImage(image) {
    //   try {
    //     if (image.size / Math.pow(1024, 2) > 5) {
    //       ElMessage.error("Digital Signature size cannot exceed 5MB!");
    //       return false;
    //     }

    //     const reader = new FileReader();

    //     reader.onloadend = () => {
    //       this.previewImage.push(reader.result);
    //     };
    //     reader.readAsDataURL(image.raw);

    //   } catch (err) {
    //     console.log(err);
    //   }
    // },
    async onChangeImage(file) {
      const isValidFile = this.validateFile(file);
      if (!isValidFile) {
        this.$message.error('Invalid file format or size. Please upload a jpg/png file with a size less than 500KB.');
        return;
      }
      try {
        const selectedFile = file.raw;
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await axios.post("patient-reports/upload-pdf", formData, file.raw);
        const pdf = response.data.data.imageUrl;
        this.previewImage.push({ imageUrl: pdf, imageContent: '' });
      } catch (error) {
        console.error(error);
      }
    },
    validateFile(file) {
      const isValidType = ['image/jpeg', 'image/png'].includes(file.raw.type);
      const isValidSize = file.raw.size / 1024 < 500;
      return isValidType && isValidSize;
    },
    async loadInitData() {
      await this.$store.dispatch("errors/clear", {}, { root: true });

      if (this.$route.params.template_id) {
        await this.fetchSinglePatientInfo();
      }

      setTimeout(() => {
        this.show = true;
      });
    },
    async fetchReportTemplates() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data..";
        let params = {
          get_all: true,
          category: "CYTOGENETICS",

        };
        if (this.reportType) {
          const matchingType = this.cytogeneticsTypesMap.find(type => type.value === this.reportType);

          if (matchingType) {
            params.sub_category = matchingType.value;
            this.showKaryotypingUI = matchingType.value === 'KARYOTYPING';
            // this.showSolidfishUI = matchingType.value === 'SOILDFISH';
            //this.showHematfishUI = matchingType.value === 'HEMATFISH';
          }
        }

        await this.$store.dispatch("reportTemplates/fetchAllReportTemplates", params);

        // this.templates = this.getAllReportTemplates.data;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },

    async fetchSinglePatientInfo() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data...";
        await this.$store.dispatch(
          "reportTemplates/fetchSingleReportTemplate",
          { template_id: this.$route.params.template_id }
        );
        this.setPatientData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    PusForCytologyData(result) {
      this.patientInfo.PusFor_Cytology = result;
    },

    CytopathologySubCategoery(result) {
      this.patientInfo.Cytopathology_SubCategoery = result;
    },

    handleSmearForMalignantCells(result) {
      this.patientInfo.SmearFor_MalignantCells = result;
    },

    setPatientData() {
      this.editorsData = [];
      this.patientInfo = { ...this.getSingleReportTemplate };
        if(this.patientInfo.category=="IHC_HISTOPATHOLOGY"){
        this.ihcDetails = this.patientInfo.ihcMarkersList[0].IHCResultTable.length > 0 ? this.patientInfo.ihcMarkersList[0].IHCResultTable : this.ihcDetails
        this.ihcNote = this.patientInfo.ihcMarkersList.length > 0  ? this.patientInfo.ihcMarkersList[0].note : this.ihcNote
        this.ihcMarkersList =this.patientInfo.ihcMarkersList.length > 0 ? this.patientInfo.ihcMarkersList : this.ihcMarkersList
        this.ihcHer2NewDetails =this.patientInfo.ihcMarkersList[0].IHCHer2New.length > 0 ? this.patientInfo.ihcMarkersList[0].IHCHer2New : this.ihcHer2NewDetails
        this.customFixationTime=this.patientInfo.ihcMarkersList.length > 0 ? this.patientInfo.ihcMarkersList[0].fixationtime : this.customFixationTime    
       }
       if (this.patientInfo.category == 'CORPORATE') {
        this.image.src = this.patientInfo.corporate_logo
        this.image.file = this.patientInfo.corporate_logo
        const urlParts = this.patientInfo.corporate_logo.split('/');
        const fileNameWithExtension = urlParts[urlParts.length - 1];
        const fileNameParts = fileNameWithExtension.split('.');
        this.image.file = {
          name: fileNameWithExtension,
          type: fileNameParts[fileNameParts.length - 1]
        }
      }

      if (this.patientInfo.sub_category === 'FISH') {
        this.resultTableData = this.getSingleReportTemplate.HematFishResultTable
        this.fluorTableData = this.getSingleReportTemplate.fluorTableData
        this.previewImage = this.getSingleReportTemplate.HematFish_patient_image
      }
      this.reportType = this.patientInfo.report_type;
      this.editorData = this.getSingleReportTemplate.template_body;
      if (
        this.getSingleReportTemplate &&
        this.getSingleReportTemplate.template_body_sections &&
        this.getSingleReportTemplate.template_body_sections.length
      ) {
        this.editorsData = [
          ...this.editorsData,
          ...this.getSingleReportTemplate.template_body_sections
        ];
      }
      if (!this.patientInfo.sub_category) {
        this.patientInfo.sub_category = this.patientInfo.report_type;
      }
      if (
        this.patientInfo.sub_category &&
        ( this.patientInfo.sub_category ==
          "COMPREHENSIVE_RESPIRATORY_PANEL_1_BACTERIA_VIRUSES" ||
           this.patientInfo.sub_category ==
          "COMPREHENSIVE_RESPIRATORY_PANEL_2_BACTERIA_VIRUSES_FUNGI" ||
          this.patientInfo.sub_category ==
          "COMPREHENSIVE_RESPIRATORY_PANEL_3_BACTERIA_VIRUSES_FUNGI" || 
          this.patientInfo.sub_category ==
          "CLOSTRIDIUM_DIFFICILE_DETECTION_PCR" || 
          this.patientInfo.sub_category ==
          "VRSA_DETECTION_QUALITATIVE_BY_REAL_TIME_PCR" ||
          this.patientInfo.sub_category ==
          "CBNAAT_GENEXPERT_MTB_XDR" ||
          this.patientInfo.sub_category ==
          "CBNAAT_GENEXPERT_MTB_WITH_RIFAMPICIN_QUALITATIVE" ||
          this.patientInfo.sub_category ==
          "VITAMIN_C_TOTAL_ASCORBIC" ||
          this.patientInfo.sub_category ==
          "VITAMIN_E_ALPHATOCOPHEROL"  ||
          this.patientInfo.sub_category ==
          "VITAMIN_A_ALL_TRANSRETINOL" ||
          this.patientInfo.sub_category ==
          "FLU_PANEL_BY_REAL_TIME_PCR" ||
          this.patientInfo.sub_category ==
          "HEPATITIS_B_VIRUS_HBV_GENOTYPE" ||
          this.patientInfo.sub_category ==
          "LEPTOSPIRA_DETECTION_PCR" ||
          this.patientInfo.sub_category ==
          "MEASLES_PCR" ||
          this.patientInfo.sub_category ==
          "MDR_MTB_WITH_RIFAMPICIN_QUALITATIVE" ||
          this.patientInfo.sub_category ==
          "HIV_1_DRUG_RESISTANCE_GENOTYPING" ||
          this.patientInfo.sub_category ==
          "MRSA_SCREENING_BY_QUALITATIVE_PCR" ||
          this.patientInfo.sub_category ==
          "MYCOBACTERIUM_TUBERCULOSIS_MTB_AMP_NON_TUBERCULOUS_MYCOBACTERIUM_NTM_DIFFERENTIATION_BY_QUALITATIVE_PCR" ||
          this.patientInfo.sub_category ==
          "NON_TUBERCULOUS_MYCOBACTERIUM_NTM_QUALITATIVE_PCR" ||
          this.patientInfo.sub_category ==
          "SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR_V2" ||
          this.patientInfo.sub_category ==
          "VITAMIN_B1" ||
          this.patientInfo.sub_category ==
          "VITAMIN_K1" ||
          this.patientInfo.sub_category ==
          "VITAMIN_B2_RIBOFLAVIN" ||
          this.patientInfo.sub_category ==
          "VITAMIN_B3" ||
          this.patientInfo.sub_category ==
          "VITAMIN_B5" ||
          this.patientInfo.sub_category ==
          "VITAMIN_B6" ||
          this.patientInfo.sub_category ==
          "VITAMIN_B7" ||
          this.patientInfo.sub_category ==
          "VITAMIN_B9" ||
          this.patientInfo.sub_category ==
          "METHYLMALONIC_ACID_QUANTITATIVE_SERUM" ||
          this.patientInfo.sub_category ==
          "CELL_BLOCK" ||
          this.patientInfo.sub_category ==
          "SYNOVIAL_FLUID_JOINT_INFECTION_MULTIPLEX_PCR"  ||
          this.patientInfo.sub_category ==
          "TORCH_PANEL_REALTIME_PCR"  ||
          this.patientInfo.sub_category ==
          "VRE_DETECTION_BY_QUALITATIVE_PCR"  ||
          this.patientInfo.sub_category ==
          "CRYPTOCOCCUS_QUALITATIVE_PCR"  ||
          this.patientInfo.sub_category ==
          "CHIKUNGUNYA_RNA_DETECTION_BY_PCR"  ||
          this.patientInfo.sub_category ==
          "CANDIDA_AURIS_QUALITATIVE_PCR"  ||
          this.patientInfo.sub_category ==
          "ANTI_MICROBIAL_RESISTANCE_GENE_DETECTION_BY_MULTIPLEX_PCR" ||
          this.patientInfo.sub_category ==
          "SENSISURE_SINGLE_ALLERGEN" ||
          this.patientInfo.sub_category ==
          "SENSISURE_AEROALLERGENS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_ALLERGY_SCREENING" ||
          this.patientInfo.sub_category ==
          "MSI_BY_SANGER_SEQUENCING" ||
          this.patientInfo.sub_category ==
          "RED_CELL_ANTIBODY_SCREENING" ||
          this.patientInfo.sub_category ==
          "BLOOD_GROUP" ||
          this.patientInfo.sub_category ==
          "DIRECT_ANTIGLOBULIN_TEST" ||
          this.patientInfo.sub_category ==
          "INDIRECT_ANTIGLOBULIN_TEST" ||
          this.patientInfo.sub_category ==
          "METHYLMALONIC_ACID_QUALITATIVE_URINE" ||
          this.patientInfo.sub_category ==
          "METHYLMALONIC_ACID_QUANTITATIVE_URINE" ||
          this.patientInfo.sub_category ==
          "EXTENDED_SPECTRUM_BETA_LACTAMS_ESBL_RESISTANCE_GENE_DETECTION_BY_MULTIPLEX_PCR" ||
          this.patientInfo.sub_category ==
          "TOXOPLASMA_QUALITATIVE_PCR" ||
          this.patientInfo.sub_category ==
          "ASPERGILLUS_QUALITATIVE_PCR" ||
          this.patientInfo.sub_category ==
          "MUCORALES_QUALITATIVE_PCR" ||
          this.patientInfo.sub_category ==
          "FUNGAL_MULTIPLEX_PCR_PANEL" ||
          this.patientInfo.sub_category ==
          "PAN_FUNGAL_DNA_PCR" ||
          this.patientInfo.sub_category ==
          "CAREBAPENEM_PCR" ||
          this.patientInfo.sub_category ==
          "SCRUB_TYPHUS_PCR" ||
          this.patientInfo.sub_category ==
          "RICKETSSIA_PCR" ||
          this.patientInfo.sub_category ==
          "EGFR" ||
          this.patientInfo.sub_category ==
          "KRAS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_ANY_10_ALLERGENS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_ANY_20_ALLERGENS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_ANY_30_ALLERGENS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_ANY_5_ALLERGENS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_COMPREHENSIVE_PANEL_48_PARAMETERS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_ECZEMA_MAXI_27_PARAMETERS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_ECZEMA_MINI_20_PARAMETERS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_FRUIT_6_PARAMETERS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_NONVEG_8_PARAMETERS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_RHINITIS_MAXI_22_PARAMETERS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_RHINITIS_MINI_11_PARAMETERS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_VEG_22_PARAMETERS" ||
          this.patientInfo.sub_category ==
          "SENSISURE_VEG_AND_NONVEG_26_PARAMETERS" ||
          this.patientInfo.sub_category ==
          "PHADIA_TOP_ALLERGY_ECZEMA_PANEL" ||
          this.patientInfo.sub_category ==
          "PHADIA_TOP_ALLERGY_FOOD_PANEL_VEG_AND_NONVEG" ||
          this.patientInfo.sub_category ==
          "PHADIA_TOP_ALLERGY_INHALATION_PANEL_20_PARAMETERS" ||
          this.patientInfo.sub_category ==
          "PHADIA_TOP_ALLERGY_NON_VEG" ||
          this.patientInfo.sub_category ==
          "PHADIA_TOP_ALLERGY_PANEL_VEGETARIAN" ||
          this.patientInfo.sub_category ==
          "PHADIA_TOP_ALLERGY_PEANUT" ||
          this.patientInfo.sub_category ==
          "PHADIA_TOP_ALLERGY_RHINITIS_PANEL" ||
          this.patientInfo.sub_category ==
          "PHADIA_TOP_ALLERGY_SCREENING_DUST_PANEL" ||
          this.patientInfo.sub_category ==
          "PHADIATOP_ALLERGY_SCREENING" ||
          this.patientInfo.sub_category ==
          "INHALATIONAL_SPECIFIC_PANEL_BY_PHADIA_TOP" ||
          this.patientInfo.sub_category ==
          "PHADIA_TOP_ALLERGY_MILK_PRODUCTS_PANEL" ||
          this.patientInfo.sub_category ==
          "PHADIA_TOP_ALLERGY_ASTHMA_PANEL"
        ) 
      ) {
        this.patientInfo.sub_category = this.patientInfo.report_type;
      }
      if (this.patientInfo.sub_category === 'HISTOPATHOLOGY(BIOPSY)') {
        this.Description = this.getSingleReportTemplate.template_body
        this.Impression = this.getSingleReportTemplate.Impression
        this.Microscopy = this.getSingleReportTemplate.Microscopy
      }
      if (this.patientInfo.report_type === 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR') {
        this.GramPostiveOrganisms = this.getSingleReportTemplate.GramPostiveOrganisms,
          this.GramNegativeOrganisms = this.getSingleReportTemplate.GramNegativeOrganisms
        this.Yeast = this.getSingleReportTemplate.Yeast
        this.Anti_microbial_resistance_gene = this.getSingleReportTemplate.Anti_microbial_resistance_gene
        this.Drugs_Interpretation = this.getSingleReportTemplate.Drugs_Interpretation
      }
    },
    async clearForm() {
      await ElMessageBox.confirm(
        "Are you sure to clear the form. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          await this.clearPatientForm();
        })
        .catch(() => { });
    },
    async addTemplate() {
      try {
        let params = this.prepareTemplateData();
        if (!params) {
          return;
        }
        this.loading = true;
        this.loadingText = "Loading...";
        if (this.patientInfo.department == "Department of CORPORATE" && !this.image.file && this.patientInfo.sub_category != "") {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: "Please upload company logo.",
          });
          return
        }
        if (this.patientInfo.department == "Department of CORPORATE" && this.image.file && this.patientInfo.template_title) {
          const croppedFile = await this.dataURLtoFile(this.croppedImageData, this.image.file.name, this.image.file.type);
          this.image.file = croppedFile
          let formData = new FormData();
          formData.append('file', this.image.file);
          const logoresponse = await axios.post("patient-reports/upload-pdf", formData, this.image.file);
          if (logoresponse.data.success == true) {
            const logopdf = logoresponse.data.data.imageUrl;
            params.corporate_logo = logopdf;
          }
        }
        let dd = window.document.getElementsByClassName("ck-content");
        if (
          this.isMolecularGenetics &&
          this.editorsData &&
          this.editorsData.length > 0
        ) {
          let multipleTitleData = []
          let multititle = {
            title:"",
            template_body:""
          }
          Array.from(dd).forEach((ckeditorElement, index) => {
            if (ckeditorElement && this.editorsData[index]) {
              multititle.template_body = ckeditorElement.innerHTML || "";
              multititle.title = this.editorsData[index].title || "";
              multipleTitleData.push({ ...multititle }); // Push a copy to the array
            }
          });
          params.template_body_sections = multipleTitleData;
        }
        await this.$store.dispatch("reportTemplates/addReportTemplate", params);
        if (this.getReportTemplateAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Template Added Successfully",
          });

          this.$router.push("/all-report-templates");
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Adding Template",
          });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },


    async updateTemplateData() {
      try {
        let params = this.prepareTemplateData();
        if (!params) {
          return;
        }
        this.loading = true;
        this.loadingText = "Loading...";
        if (this.patientInfo.department == "Department of CORPORATE" && !this.image.file) {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: "Please upload logo and signature image files.",
          });
          return;
        }
        if (this.patientInfo.department == "Department of CORPORATE" && this.image.file) {
          const croppedFile = await this.dataURLtoFile(this.croppedImageData, this.image.file.name, this.image.file.type);
          this.image.file = croppedFile
          let formData = new FormData();
          formData.append('file', this.image.file);
          const logoresponse = await axios.post("patient-reports/upload-pdf", formData, this.image.file);
          if (logoresponse.data.success == true) {
            const logopdf = logoresponse.data.data.imageUrl;
            params.corporate_logo = logopdf;
          }         
        }
        if (this.patientInfo.department == "Department of Molecular Genetics" && this.patientInfo.sub_category == 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR') {
          params.GramPostiveOrganisms = []
          params.GramNegativeOrganisms = []
          params.Yeast = []
          params.Anti_microbial_resistance_gene = []
          params.Drugs_Interpretation = []
          params.GramPostiveOrganisms = this.GramPostiveOrganisms,
            params.GramNegativeOrganisms = this.GramNegativeOrganisms,
            params.Yeast = this.Yeast,
            params.Anti_microbial_resistance_gene = this.Anti_microbial_resistance_gene,
            params.Drugs_Interpretation = this.Drugs_Interpretation
        }
        let dd = window.document.getElementsByClassName("ck-content");
        if (
          this.isMolecularGenetics &&
          this.editorsData &&
          this.editorsData.length > 0
        ) {
          let multipleTitleData = []
          let multititle = {
            title:"",
            template_body:""
          }
          Array.from(dd).forEach((ckeditorElement, index) => {
            if (ckeditorElement && this.editorsData[index]) {
              multititle.template_body = ckeditorElement.innerHTML || "";
              multititle.title = this.editorsData[index].title || "";
              multipleTitleData.push({ ...multititle }); // Push a copy to the array
            }
          });
          params.template_body_sections = multipleTitleData;
        }
        await this.$store.dispatch("reportTemplates/updateReportTemplate", {
          template_id: this.patientInfo._id,
          ...params,
        });

        if (this.getReportTemplateUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Template Updated Successfully",
          });

          this.$router.push({
            path: "/all-report-templates",
            query: this.$route.query,
          });
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error at Updating Template",
          });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    prepareTemplateData() {

      if (this.onCheckSubCategory()) {
        if(this.patientInfo.report_type =='Alpha-Fucosidosis_Leukocytes'){
          this.patientInfo.sub_category='Alpha_Fucosidosis_Leukocytes'
        }else if(this.patientInfo.report_type =='Alpha-Mannosidosis_Leukocytes'){
          this.patientInfo.sub_category='Alpha_Mannosidosis_Leukocytes'
        }else if(this.patientInfo.report_type =='Beta-Mannosidosis_Leukocytes'){
          this.patientInfo.sub_category='Beta_Mannosidosis_Leukocytes'
        }
        this.$route.params.template_id ? this.$refs.childComponentRef.updateTemplate() : this.$refs.childComponentRef.childMethod();
        return;
      }
      if (this.patientInfo.category == 'BIOCHEMICAL_GENETICS' && this.onCheckbioSubCategory()) {
        this.$refs.childComponentRef.childMethod();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.category == 'BIOCHEMICAL_GENETICS' && this.patientInfo.sub_category == "GM1_Leukocytes") {
        this.$refs.childComponentRef.childMethod();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == "Mucolipidosis II and III_Plasma") {
        this.$refs.childBGMLPlamaRef.sendBGMLPlama();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == "AcidLipaseLeukocytes" || this.patientInfo.sub_category == "Acid Lipase_Leukocytes") {
        this.$refs.childAcidLILeucocytesRef.sendAcidLILeucocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == "TaysachSandhoffLeukocytes" || this.patientInfo.sub_category == "Taysach,Sandhoff_Leukocytes") {
        this.$refs.childTaysachSandhoffLeukocytesRef.sendTaysachSandhoffLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == "Schindler_Leukocytes") {
        this.$refs.childSchindlerLeukocytesRef.sendSchindlerLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Department of CYTOPATHOLOGY" && this.onCheckCytopathologySubCategory()) {
        this.$refs.childComponentRef.childMethod();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Department of CYTOPATHOLOGY" && this.patientInfo.sub_category == "PAP SMEAR - CONVENTIONAL") {
        this.$refs.childComponentRef.childMethod();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Department of CYTOPATHOLOGY" && this.patientInfo.sub_category == "PAP SMEAR ABOVE 40") {
        this.$refs.childComponentRef.childMethod();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.onCheckCytologySubCategory()) {
        this.$refs.childComponentRef.childMethod();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == "Pompe_Leukocytes") {
        this.$refs.childPompeLeukocytesRef.sendPompeLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.sub_category == "Niemann Pick AB_Leukocytes") {
        this.$refs.childNiemannPickABLeukocytesRef.sendNiemannPickABLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == "NCL1_Leukocytes") {
        this.$refs.childNCL1Leukocytes.sendNCL1Leukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == "Neuraminidase_Fibroblasts") {
        this.$refs.childNeuraminidaseFibroblasts.sendNeuraminidaseFibroblasts();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Department of CYTOPATHOLOGY" && this.patientInfo.sub_category == "Smear For Malignant Cells") {
        this.$refs.childSmearForMalignantCells.handleSmearForMalignantCells();
        let params = { ...this.patientInfo };
        return params;
      }
      if ((this.patientInfo.department === 'DEPARTMENT OF TRANSPLANT IMMUNOGENETICS') && this.patientInfo.sub_category == 'HLA SAB CLASS I II TYPING') {
        this.$refs.childComponentRef.childMethod();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Department of CORPORATE") {
        let params = { ...this.patientInfo };
        if ((params.height != null && params.height != "" && params.weight != null && params.weight != "")) {
          const height = parseFloat(params.height);
          const weight = parseFloat(params.weight);
          const heightInMeters = height / 100;
          const bmi = weight / (heightInMeters * heightInMeters);
          const Calculationbmi = bmi.toFixed(2);
          params.calculationbmi = Calculationbmi
        } else {
          const value = "NaN";
          params.calculationbmi = parseFloat(value);
        }
        return params;
      }
      if (this.histopathologyIHCTypes.includes(this.patientInfo.sub_category)) {
        this.ihcMarkersList[0].IHCResultTable=this.ihcDetails.filter(detail => detail.ihc_marker !== '' && detail.ihc_clone !== '');
        if(this.patientInfo.sub_category=='IHC - ER PR HER2 NEU & KI67' || this.patientInfo.sub_category=='IHC - ER, PR , HER2NEU' || this.patientInfo.sub_category=='IHC HER 2 NEU'){
          this.ihcMarkersList[0].IHCHer2New=this.ihcHer2NewDetails
        }
        if(this.patientInfo.sub_category=='IHC - ER PR HER2 NEU & KI67'){
          this.ihcMarkersList[0].fixationtime=this.customFixationTime==""?this.ihcMarkersList[0].fixationtime:this.customFixationTime
          this.patientInfo.ihcMarkersList= this.ihcMarkersList 
        } else if (this.patientInfo.sub_category=='IHC - ER, PR , HER2NEU'){
          this.ihcMarkersList[0].fixationtime=this.customFixationTime==""?this.ihcMarkersList[0].fixationtime:this.customFixationTime
          this.ihcMarkersList[0].references= ''
          this.ihcMarkersList[0].note= this.ihcNote
          this.patientInfo.ihcMarkersList= this.ihcMarkersList 
        }else{
          this.ihcMarkersList[0].fixationtime=this.customFixationTime==""?this.ihcMarkersList[0].fixationtime:this.customFixationTime
          this.ihcMarkersList[0].references= ''
          this.ihcMarkersList[0].note= this.ihcNote
          this.ihcMarkersList[0].her2neuscoring= ''
          this.patientInfo.ihcMarkersList= this.ihcMarkersList 
        }
      //  this.patientInfo.ihcMarkersList= this.ihcMarkersList      
        const params = { ...this.patientInfo };
        return params
      }
      let dd = window.document.getElementsByClassName("ck-content");      
      const data = dd[0]?.innerHTML;
      const Microscopy = dd[1] ? dd[1].innerHTML : "";
      const Impression = dd[2] ? dd[2].innerHTML : "";
      // let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
      // stringContent = stringContent.trim();

      // if (!stringContent) {
      //   this.$message.warning("No Content to Save Template");
      //   return;
      // }
      let params = { ...this.patientInfo };

      if (params.department) {
        let category = departments.find(
          (department) => department.label == params.department
        );
        if (category && category.value) {
          params.category = category.value;
        }
      }

      if (!this.isMolecularGenetics) {
        params.template_body = data;
      }
      if (this.isMolecularGenetics) {
        params.template_type_version = 2;
      }
      if (this.patientInfo.sub_category === 'FISH') {
        params.HematFishResultTable = this.resultTableData
        params.fluorTableData = this.fluorTableData
        params.HematFish_patient_image = this.previewImage
      }
      if (this.patientInfo.department == "Department of Molecular Genetics" && this.patientInfo.sub_category == 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR') {
        params.GramPostiveOrganisms = []
        params.GramNegativeOrganisms = []
        params.Yeast = []
        params.Anti_microbial_resistance_gene = []
        params.Drugs_Interpretation = []
        params.GramPostiveOrganisms = this.GramPostiveOrganisms,
          params.GramNegativeOrganisms = this.GramNegativeOrganisms,
          params.Yeast = this.Yeast,
          params.Anti_microbial_resistance_gene = this.Anti_microbial_resistance_gene,
          params.Drugs_Interpretation = this.Drugs_Interpretation
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == 'MPS II_Leukocytes') {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == 'MPS IIIA_Leukocytes') {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == 'MPS IIIB_Leukocytes') {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == 'MPS IIIC_Leukocytes') {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == 'MPS IIID_Leukocytes') {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == 'MPS IVA_Leukocytes') {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == "MPS VI_Leukocytes") {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == "MPS VII_Leukocytes") {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      params.grossdescriptiontype = this.grossdescriptiontype;
      params.isaddendumChecked = this.isaddendumChecked;
      params.Microscopy = Microscopy ? Microscopy : undefined;
      params.Impression = Impression ? Impression : undefined;
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "COMPREHENSIVE RESPIRATORY PANEL 1 BACTERIA/VIRUS"
      ) {
        params.sub_category =
          "COMPREHENSIVE_RESPIRATORY_PANEL_1_BACTERIA_VIRUSES";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "COMPREHENSIVE RESPIRATORY PANEL 2 BACTERIA/VIRUS/FUNGI"
      ) {
        params.sub_category =
          "COMPREHENSIVE_RESPIRATORY_PANEL_2_BACTERIA_VIRUSES_FUNGI";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "COMPREHENSIVE RESPIRATORY PANEL 3 BACTERIA/VIRUS/FUNGI"
      ) {
        params.sub_category =
          "COMPREHENSIVE_RESPIRATORY_PANEL_3_BACTERIA_VIRUSES_FUNGI";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category == "CLOSTRIDIUM DIFFICILE DETECTION PCR"
      ) {
        params.sub_category = "CLOSTRIDIUM_DIFFICILE_DETECTION_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "VRSA DETECTION QUALITATIVE BY REAL TIME PCR"
      ) {
        params.sub_category = "VRSA_DETECTION_QUALITATIVE_BY_REAL_TIME_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "CBNAAT MTB/XDR"
      ) {
        params.sub_category = "CBNAAT_GENEXPERT_MTB_XDR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "CBNAAT GENEXPERT MTB WITH RIFAMPICIN QUALITATIVE"
      ) {
        params.sub_category = "CBNAAT_GENEXPERT_MTB_WITH_RIFAMPICIN_QUALITATIVE";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "VITAMIN - A  ALL TRANSRETINOL"
      ) {
        params.sub_category = "VITAMIN_A_ALL_TRANSRETINOL";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "VITAMIN - C TOTAL ASCORBIC"
      ) {
        params.sub_category = "VITAMIN_C_TOTAL_ASCORBIC";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "VITAMIN - E ALPHATOCOPHEROL"
      ) {
        params.sub_category = "VITAMIN_E_ALPHATOCOPHEROL";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "FLU PANEL - BY REAL TIME PCR"
      ) {
        params.sub_category = "FLU_PANEL_BY_REAL_TIME_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "HEPATITIS B VIRUS (HBV) GENOTYPE"
      ) {
        params.sub_category = "HEPATITIS_B_VIRUS_HBV_GENOTYPE";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "LEPTOSPIRA DETECTION PCR"
      ) {
        params.sub_category = "LEPTOSPIRA_DETECTION_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "MEASLES PCR"
      ) {
        params.sub_category = "MEASLES_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "MDR MTB WITH RIFAMPICIN QUALITATIVE"
      ) {
        params.sub_category = "MDR_MTB_WITH_RIFAMPICIN_QUALITATIVE";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "HIV 1 DRUG RESISTANCE - GENOTYPING"
      ) {
        params.sub_category = "HIV_1_DRUG_RESISTANCE_GENOTYPING";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "MRSA SCREENING BY QUALITATIVE PCR"
      ) {
        params.sub_category = "MRSA_SCREENING_BY_QUALITATIVE_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "MYCOBACTERIUM TUBERCULOSIS (MTB) &amp; NON-TUBERCULOUS MYCOBACTERIUM (NTM) DIFFERENTIATION BY QUALITATIVE PCR"
      ) {
        params.sub_category = "MYCOBACTERIUM_TUBERCULOSIS_MTB_AMP_NON_TUBERCULOUS_MYCOBACTERIUM_NTM_DIFFERENTIATION_BY_QUALITATIVE_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "NON-TUBERCULOUS MYCOBACTERIUM (NTM) QUALITATIVE PCR"
      ) {
        params.sub_category = "NON_TUBERCULOUS_MYCOBACTERIUM_NTM_QUALITATIVE_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SEPSIS PANELWITH AMR GENES - BY MULTIPLEX PCR V2"
      ) {
        params.sub_category = "SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR_V2";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "VITAMIN - B1"
      ) {
        params.sub_category = "VITAMIN_B1";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "VITAMIN - K1"
      ) {
        params.sub_category = "VITAMIN_K1";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "VITAMIN B2; RIBOFLAVIN"
      ) {
        params.sub_category = "VITAMIN_B2_RIBOFLAVIN";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "VITAMIN - B3"
      ) {
        params.sub_category = "VITAMIN_B3";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "VITAMIN - B5"
      ) {
        params.sub_category = "VITAMIN_B5";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "VITAMIN - B6"
      ) {
        params.sub_category = "VITAMIN_B6";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "VITAMIN - B7"
      ) {
        params.sub_category = "VITAMIN_B7";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "VITAMIN - B9"
      ) {
        params.sub_category = "VITAMIN_B9";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "METHYLMALONIC ACID QUANTITATIVE - SERUM"
      ) {
        params.sub_category = "METHYLMALONIC_ACID_QUANTITATIVE_SERUM";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "CELL BLOCK"
      ) {
        params.sub_category = "CELL_BLOCK";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SYNOVIAL FLUID/JOINT INFECTION - MULTIPLEX PCR"
      ) {
        params.sub_category = "SYNOVIAL_FLUID_JOINT_INFECTION_MULTIPLEX_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "TORCH PANEL REALTIME PCR"
      ) {
        params.sub_category = "TORCH_PANEL_REALTIME_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "VRE DETECTION BY QUALITATIVE PCR"
      ) {
        params.sub_category = "VRE_DETECTION_BY_QUALITATIVE_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "CRYPTOCOCCUS QUALITATIVE PCR"
      ) {
        params.sub_category = "CRYPTOCOCCUS_QUALITATIVE_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "CHIKUNGUNYA RNA DETECTION BY PCR"
      ) {
        params.sub_category = "CHIKUNGUNYA_RNA_DETECTION_BY_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "CANDIDA AURIS QUALITATIVE PCR"
      ) {
        params.sub_category = "CANDIDA_AURIS_QUALITATIVE_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "ANTI-MICROBIAL RESISTANCE GENE DETECTION - BY MULTIPLEX PCR"
      ) {
        params.sub_category = "ANTI_MICROBIAL_RESISTANCE_GENE_DETECTION_BY_MULTIPLEX_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE SINGLE ALLERGEN"
      ) {
        params.sub_category = "SENSISURE_SINGLE_ALLERGEN";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE AERO ALLERGENS"
      ) {
        params.sub_category = "SENSISURE_AEROALLERGENS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE ALLERGY SCREENING"
      ) {
        params.sub_category = "SENSISURE_ALLERGY_SCREENING";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "MSI BY SANGER SEQUENCING"
      ) {
        params.sub_category = "MSI_BY_SANGER_SEQUENCING";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "RED CELL ANTIBODY SCREENING"
      ) {
        params.sub_category = "RED_CELL_ANTIBODY_SCREENING";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "BLOOD GROUP"
      ) {
        params.sub_category = "BLOOD_GROUP";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "DIRECT ANTIGLOBULIN TEST"
      ) {
        params.sub_category = "DIRECT_ANTIGLOBULIN_TEST";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "INDIRECT ANTIGLOBULIN TEST"
      ) {
        params.sub_category = "INDIRECT_ANTIGLOBULIN_TEST";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "METHYLMALONIC ACID QUALITATIVE - URINE"
      ) {
        params.sub_category = "METHYLMALONIC_ACID_QUALITATIVE_URINE";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "METHYLMALONIC ACID QUANTITATIVE - URINE"
      ) {
        params.sub_category = "METHYLMALONIC_ACID_QUANTITATIVE_URINE";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "EXTENDED SPECTRUM BETA-LACTAMS (ESBL) RESISTANCE GENE DETECTION BY MULTIPLEX PCR"
      ) {
        params.sub_category = "EXTENDED_SPECTRUM_BETA_LACTAMS_ESBL_RESISTANCE_GENE_DETECTION_BY_MULTIPLEX_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "TOXOPLASMA QUALITATIVE PCR"
      ) {
        params.sub_category = "TOXOPLASMA_QUALITATIVE_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "ASPERGILLUS QUALITATIVE PCR"
      ) {
        params.sub_category = "ASPERGILLUS_QUALITATIVE_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "MUCORALES QUALITATIVE PCR"
      ) {
        params.sub_category = "MUCORALES_QUALITATIVE_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "FUNGAL MULTIPLEX PCR PANEL"
      ) {
        params.sub_category = "FUNGAL_MULTIPLEX_PCR_PANEL";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "PAN FUNGAL DNA PCR"
      ) {
        params.sub_category = "PAN_FUNGAL_DNA_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "CAREBAPENEM PCR"
      ) {
        params.sub_category = "CAREBAPENEM_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SCRUB TYPHUS PCR"
      ) {
        params.sub_category = "SCRUB_TYPHUS_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "RICKETSSIA PCR"
      ) {
        params.sub_category = "RICKETSSIA_PCR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "EGFR"
      ) {
        params.sub_category = "EGFR";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "KRAS"
      ) {
        params.sub_category = "KRAS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE ANY 10 ALLERGENS"
      ) {
        params.sub_category = "SENSISURE_ANY_10_ALLERGENS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE ANY 20 ALLERGENS"
      ) {
        params.sub_category = "SENSISURE_ANY_20_ALLERGENS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE ANY 30 ALLERGENS"
      ) {
        params.sub_category = "SENSISURE_ANY_30_ALLERGENS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE ANY 5 ALLERGENS"
      ) {
        params.sub_category = "SENSISURE_ANY_5_ALLERGENS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE COMPREHENSIVE PANEL 48 PARAMETERS"
      ) {
        params.sub_category = "SENSISURE_COMPREHENSIVE_PANEL_48_PARAMETERS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE ECZEMA MAXI 27 PARAMETERS"
      ) {
        params.sub_category = "SENSISURE_ECZEMA_MAXI_27_PARAMETERS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE ECZEMA MINI 20 PARAMETERS"
      ) {
        params.sub_category = "SENSISURE_ECZEMA_MINI_20_PARAMETERS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE FRUIT 6 PARAMETERS"
      ) {
        params.sub_category = "SENSISURE_FRUIT_6_PARAMETERS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE NONVEG 8 PARAMETERS"
      ) {
        params.sub_category = "SENSISURE_NONVEG_8_PARAMETERS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE RHINITIS MAXI 22 PARAMETERS"
      ) {
        params.sub_category = "SENSISURE_RHINITIS_MAXI_22_PARAMETERS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE RHINITIS MINI 11 PARAMETERS"
      ) {
        params.sub_category = "SENSISURE_RHINITIS_MINI_11_PARAMETERS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE VEG 22 PARAMETERS"
      ) {
        params.sub_category = "SENSISURE_VEG_22_PARAMETERS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "SENSISURE VEG AND NONVEG 26 PARAMETERS"
      ) {
        params.sub_category = "SENSISURE_VEG_AND_NONVEG_26_PARAMETERS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "PHADIA TOP ALLERGY ECZEMA PANEL"
      ) {
        params.sub_category = "PHADIA_TOP_ALLERGY_ECZEMA_PANEL";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "PHADIA TOP ALLERGY FOOD PANEL VEG AND NONVEG"
      ) {
        params.sub_category = "PHADIA_TOP_ALLERGY_FOOD_PANEL_VEG_AND_NONVEG";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "PHADIA TOP ALLERGY INHALATION PANEL 20 PARAMETERS"
      ) {
        params.sub_category = "PHADIA_TOP_ALLERGY_INHALATION_PANEL_20_PARAMETERS";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "PHADIA TOP ALLERGY NON VEG"
      ) {
        params.sub_category = "PHADIA_TOP_ALLERGY_NON_VEG";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "PHADIA TOP ALLERGY PANEL VEGETARIAN"
      ) {
        params.sub_category = "PHADIA_TOP_ALLERGY_PANEL_VEGETARIAN";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "PHADIA TOP ALLERGY PEANUT"
      ) {
        params.sub_category = "PHADIA_TOP_ALLERGY_PEANUT";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "PHADIA TOP ALLERGY RHINITIS PANEL"
      ) {
        params.sub_category = "PHADIA_TOP_ALLERGY_RHINITIS_PANEL";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "PHADIA TOP ALLERGY SCREENING DUST PANEL"
      ) {
        params.sub_category = "PHADIA_TOP_ALLERGY_SCREENING_DUST_PANEL";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "PHADIATOP ALLERGY SCREENING"
      ) {
        params.sub_category = "PHADIATOP_ALLERGY_SCREENING";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "INHALATIONAL SPECIFIC PANEL BY PHADIA TOP"
      ) {
        params.sub_category = "INHALATIONAL_SPECIFIC_PANEL_BY_PHADIA_TOP";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "PHADIA TOP ALLERGY MILK PRODUCTS PANEL"
      ) {
        params.sub_category = "PHADIA_TOP_ALLERGY_MILK_PRODUCTS_PANEL";
      }
      if (
        this.patientInfo &&
        this.patientInfo.sub_category ==
          "PHADIA TOP ALLERGY ASTHMA PANEL"
      ) {
        params.sub_category = "PHADIA_TOP_ALLERGY_ASTHMA_PANEL";
      }
      return params;
    },

    async saveAsTemplate() {
      await this.updateTemplateData();
    },
    clearPatientForm(clearPatientData = true) {
      if (clearPatientData) {
        this.patientInfo = {
          visit_id: "",
          patient_name: "",
          ref_doctor: "",
          uhid: "",
          mr_no: "",
          gender: "",
          age: "",
          hospital_name: "",
          registration_date: "",
          reported_date: "",
          received_date: "",
        };
      }

      window.document.getElementsByClassName("ck-content")[0].innerText = "";
      window.document.getElementsByClassName("ck-content").innerHTML = "";
      // this.editorData = "";
    },
    async previewReport() {
      try {
        this.loading = true;
        this.loadingText = "Loading..";
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;

        let params = { ...this.patientInfo };

        params.test_details = data;

        this.$store.dispatch("patientReports/addPatientData", params);

        let routerData = this.$router.resolve({
          name: "PatientReportPreview",
        });

        window.open(routerData.href, "_blank");

        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async goToReportTemplates() {
      this.$router.push({ name: "ReportTemplates", query: this.$route.query });
    },

    async updatePatientInfo() {
      this.loading = true;
      this.loadingText = "Loading...";
      try {
        let params = { ...this.patientInfo };
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;
        params.test_details = data;
        let patientId = this.$route.params.patient_id;
        await this.$store.dispatch("reportTemplates/updateReportTemplate", {
          params,
          patientId,
        });
        if (this.getPatientUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Updated Successfully",
          });
          await this.goToReportTemplates();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async askRemoveConfirmation() {
      await ElMessageBox.confirm(
        "Are you want to clear template data. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          await this.clearPatientForm(false);
        })
        .catch(() => { });
    },
    scrollIntoFirstError() {
      var el = this.$el.getElementsByClassName("error")[0];
      if (el) {
        el.scrollIntoView({ block: "center" });
      }
    },
    onEditReportType() {
      this.reportTypeNew = this.patientInfo.report_type;
      this.editReportType = true;
      this.showReportType = false;
    },
    updateReportType() {
      this.patientInfo.report_type = this.reportTypeNew;
      this.editReportType = false;
      this.showReportType = true;
    },
    cancelUpdateReportType() {
      this.reportTypeNew = this.patientInfo.report_type;
      this.editReportType = false;
      this.showReportType = true;
    },
    onChangeCategory() {
      this.patientInfo.report_type = this.patientInfo.sub_category;
      if (this.patientInfo.department === "Histopathology") {
        this.patientInfo.category = "HISTO_PATHOLOGY";
      } else if (
        this.patientInfo.department ===
        "Department of Genomics and Molecular Diagnostics"
      ) {
        this.patientInfo.category = "GENOMICS";
      } else if (
        this.patientInfo.department === "Department of Molecular Genetics"
      ) {
        this.patientInfo.category = "MOLECULAR_GENETICS";
      } else if (
        this.patientInfo.department === "Department of Flow Cytometry"
      ) {
        this.patientInfo.category = "FLOW_CYTOMETRY";
      } else if (
        this.patientInfo.department ===
        "Department of Transplant Immunogenetics"
      ) {
        this.patientInfo.category = "TRANSPLANT_IMMUNOGENETICS";
      } else if (
        this.patientInfo.department == "Department of Serology / Immunology"
      ) {
        this.patientInfo.category = "SEROLOGY_IMMUNOLOGY";
      } else if (
        this.patientInfo.department == "Department of Pharmacogenomics"
      ) {
        this.patientInfo.category = "PHARMACO_GENOMICS";
      } else if (this.patientInfo.department == "Department of Bio-Chemistry") {
        this.patientInfo.category = "BIO_CHEMISTRY";
      } else if (this.patientInfo.department == "Department of CYTOGENETICS") {
        this.patientInfo.category = "CYTOGENETICS"
      }
      else if (this.patientInfo.department == "Department of CORPORATE") {
        this.patientInfo.category = "CORPORATE"
      }
      else if (this.patientInfo.department == "YODA SISHU SAMRAKSHA PANEL") {
        this.patientInfo.category = "YODA_SISHU_SAMRAKSHA"
      }
      else if (this.patientInfo.department == "Biochemical Genetics") {
        this.patientInfo.category = "BIOCHEMICAL_GENETICS"
      } else if (this.patientInfo.department == "Department of CYTOPATHOLOGY") {
        this.patientInfo.category = "CYTOPATHOLOGY"
      }
      else if (this.patientInfo.department == "IHC - HISTOPATHOLOGY") {
        this.patientInfo.category = "IHC_HISTOPATHOLOGY"
      } else if (this.patientInfo.department == "Department of ImmunoHematology") {
        this.patientInfo.category = "IMMUNOHEMATOLOGY";
      } else {
        this.patientInfo.category = "RADIOLOGY";
      }
    },
    checkCKEditorContent() {
      const excludedSubcategories = [
        'KARYOTYPING',
        'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR',
        'HISTOPATHOLOGY(IHC - 4 MARKERS)',
        'HISTOPATHOLOGY(BIOPSY)',
        'FISH',
        'Newborn Screening (TMS – 49 Disorders)',
        'Gaucher_Leukocytes',
        'MPS II_Leukocytes',
        'Fabry_Leukocytes',
        'HEMOGLOBINOPATHIES SAMRAKSHA',
        'URINARY GLYCOSAMINOGLYCANS (GAG) QUANTIFICATION(SAMRAKSHA)',
        'Newborn Screening (Bio-7)',
        'Urine Organic Acid Analysis (GC-MS)',
        'HLA SAB CLASS I II TYPING',
        'MPS IIIA_Leukocytes',
        'MPS IIIB_Leukocytes',
        'MPS IIIC_Leukocytes',
        'MPS IIID_Leukocytes',
        'MPS IVA_Leukocytes',
        "MPS VI_Leukocytes",
        "MPS VII_Leukocytes",
        "PAP SMEAR ABOVE 40",
        'PAP SMEAR - CONVENTIONAL',
        'LYSOSOMAL STORAGE DISORDERS',
        'Mucolipidosis II and III_Plasma',
        "Honda Hyundai Motors",
        "Bharathi Cement",
        "Reliance Industries",
        "Bajaj Finserv Ltd",
        "Indian Oil Corporation Ltd",
        "AcidLipaseLeukocytes",
        "Acid Lipase_Leukocytes",
        'Leukocytes_NCL2',
        "Alpha-Fucosidosis_Leukocytes",
        "Alpha-Mannosidosis_Leukocytes",
        "Beta-Mannosidosis_Leukocytes",
        "Biotinidase_Plasma",
        "Chitotriosidase_Plasma",
        "TaysachSandhoffLeukocytes",
        "Taysach,Sandhoff_Leukocytes",
        "Krabbe_Leukocytes",
        "Glycoasparginase_Leukocytes",
        "SchindlerLeukocytes",
        "Schindler_Leukocytes",
        "MPS I_Leukocytes",
        "Pus For Cytology",
        "MLD_Leukocytes",
        "Pompe_Leukocytes",
        "GM1_Leukocytes",
        "Liquid Based Cytology - Pap Smear",
        "Fnac Slides Reporting",
        "Cytology Slide Reporting",
        "FNAC (Sample In Clinic)",
        "Fluid For Cytology",
        "Niemann Pick AB_Leukocytes",
        "NCL1_Leukocytes",
        "Urine For Cytology",
        "Sputum For Cytology",
        "Neuraminidase_Fibroblasts",
        "YODA SHISHU SAMRAKSHA PANEL",
        "Smear For Malignant Cells",
        'Liquid_Based_Cytology',
        "Cytology_Slide_Reporting",
        "FNAC",
        "Fluid_For_Cytology",
        "Urine_For_Cytology",
        "Sputum_For_Cytology",
        'Pus_For_Cytology',
        "Fnac_Slides_Reporting",
        "Alpha_Fucosidosis_Leukocytes",
        "Alpha_Mannosidosis_Leukocytes",
        "Beta_Mannosidosis_Leukocytes",
      ];
      excludedSubcategories.push(...this.histopathologyIHCTypes)
      return !excludedSubcategories.includes(this.patientInfo.sub_category);
    },
    onCheckSubCategory() {
      const subCategorys = [
        "Alpha-Fucosidosis_Leukocytes",
        "Alpha-Mannosidosis_Leukocytes",
        "Beta-Mannosidosis_Leukocytes",
        "Biotinidase_Plasma",
        "Chitotriosidase_Plasma"
      ];
      return subCategorys.includes(this.patientInfo.report_type)
    },
    onCheckbioSubCategory() {
      const subCategorys = [
        "Fabry_Leukocytes",
        "Gaucher_Leukocytes",
        "Leukocytes_NCL2",
        "Krabbe_Leukocytes",
        "MPS I_Leukocytes",
        "Glycoasparginase_Leukocytes",
        "MLD_Leukocytes"
      ];
      return subCategorys.includes(this.patientInfo.sub_category)
    },
    onCheckCytologySubCategory() {
      const subCategorys = [
        "Cytology Slide Reporting",
        "FNAC (Sample In Clinic)",
        "Fluid For Cytology",
        "Urine For Cytology",
        "Sputum For Cytology"
      ];
      return subCategorys.includes(this.patientInfo.report_type)
    },
    onCheckCytopathologySubCategory() {
      const subCategorys = [
        "Liquid Based Cytology - Pap Smear",
        "Fnac Slides Reporting",
        "Pus For Cytology",
      ];
      return subCategorys.includes(this.patientInfo.report_type)
    }

  },
  // beforeRouteLeave(to, from, next) {

  //     const answer = window.confirm(
  //       "Do you really want to leave? you have unsaved changes!"
  //     );
  //     if (answer) {
  //       next();
  //     } else {
  //       next(false);
  //     }

  // },
};
</script>

<style lang="scss">
.ck-editor__editable {
  min-height: 320px;
}

.report-header {
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  background: #2eaf9f;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.veEyeLabel {
  text-align: right;
  font-weight: 500;
  margin-top: 45px;
}

.image-flex {
  display: grid;
  grid-template-columns: repeat(3, 265px);
  gap: 30px;
  padding: 0 10px;
  margin: 20px 0;
  align-items: center;
  justify-items: center;

  img {
    width: 240px;
    max-width: 240px;
    height: 200px;
    object-fit: contain;
  }

  h4 {
    text-align: center;
    border: 2px solid #cccccc;
    border-radius: 4px;
    padding: 10px;
  }

  .upload-btn {
    padding: 10px;
  }

  .img-block {
    position: relative;
    background: #f3f3f3;
    padding: 10px;
    border-radius: 6px;

    .remove-btn {
      display: none;
    }

    &:hover {
      .remove-btn {
        display: block;
        cursor: pointer;
      }
    }

    .remove-btn {
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }
}

.fix-cropper-width {
  width: 600px;
  max-width: 600px;
}

#fileUploadArea {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.fileUploadArea {
  .section-2 {
    margin-top: 2rem;
  }
}

.cropper {
  height: 280px;
  max-height: 100%;
  width: 280px;
  max-width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #ddd;
}

// .uploadBlock{
//   background-color: #ddd;
//   width: 280px;
//   height: 280px;
//   padding: 10px;
//   box-sizing: border-box;
//   border-radius: 10px;
// }
.croppedBlock {
  background: #ddd;
  width: 280px;
  height: 280px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;

  .croppedImage {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: fill;

  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin: 25px auto;
  margin-left: 370px;
}

.centeredButton {
  display: flex;
  justify-content: center;
}

.button {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  width: 100%;
  background: #151515;
  cursor: pointer;
  transition: background 0.5s;
  border: none;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &:hover {
    background: #2F2F2F;
  }

  input {
    display: none;
  }
}

.hidden {
  display: none;
}

.fileUploadBlock {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.fileUploadBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.editor {
  margin-top: 0.5em;
  padding: 10px;

  .ck-editor__editable {
    min-height: 320px;
  }
}

.add-patient-report-data .addReportPatientDemographics .title {
  text-align: left !important;
  text-transform: initial !important;
  padding: 6px !important;
}
#molecular-genetics-report-editor {
  .add-editor-button {
    position: fixed;
    top: 40%;
    left: -10px;
    background-color: #18546d;
    border: none;
    color: white;
    padding: 15px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    font-size: 15px;
    cursor: pointer;
    transition-duration: 0.4s;
    transform: rotate(-90deg);
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 600;
    span {
      padding-left: 5px;
    }
  }

  .input-title {
    margin-bottom: 15px;
    display:grid;
    grid-template-columns:1fr 40px;
    grid-column-gap:0.5rem;
    .el-input__wrapper {
      background: #f5f5f5;
      border-radius: 7px;
      text-transform: uppercase;
      text-align: center;
      line-height: 2px;
      letter-spacing: 0.5px;
      font-weight: 500;
    }
    input {
      height: 35px;
      font-size: 16px;
      &::placeholder {
        font-size: 14px;
      }
    }
  
    .removeBtn {
      background-color:#18546d;
      color:#fff;
      min-width:inherit;
      border-radius:50%;
      padding:0px 8px;
      width:fit-content;

    }
  
  }
}
</style>
