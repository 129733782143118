<template>
  <section v-loading.fullscreen.lock="loading" :element-loading-text="loadingText">
    <div class="double-marker">
      <div class="addReportPatientDemographics">
        <div>
          <div class="edit-box mb-20">
            <span class="title">Clinical History</span>
            <el-input type="textarea" autosize rows="3" v-model="patientInfo.clinical_details"></el-input>
          </div>
          <div class="edit-box mb-20">
            <span class="title">Indication</span>
            <el-input type="textarea" autosize rows="3" v-model="patientInfo.indication"></el-input>
          </div>
          <div class=" edit-box mb-20">
            <span class="title">{{(reportDetails.sub_category=='Chitotriosidase_Plasma' || reportDetails.sub_category=='Biotinidase_Plasma')?'Plasma Enzyme Activity:':'Leukocyte Enzyme Activity'}}</span>
            <el-table border style="width: 100%" :data="Leukocyte_Enzyme_Activity">
              <el-table-column prop="Test_Units" label="Test (units)" width="235" v-slot="scope">
                <el-form>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item class="mb-0">
                        <el-input type="textarea" placeholder="Test (units)" v-model="scope.row.Test_Units" autosize></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-table-column>
              <el-table-column prop="chromosomeloci" label="Disorder" width="200" v-slot="scope" v-if="reportDetails.sub_category!=='Chitotriosidase_Plasma' && reportDetails.sub_category!=='Biotinidase_Plasma'">
                <el-form>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item class="mb-0">
                        <el-input type="textarea" placeholder="Disorder" autosize v-model="scope.row.Disorder"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-table-column>
              <el-table-column prop="noofcells" label="Results" width="160" v-slot="scope">
                <el-form>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item class="mb-0">
                        <el-input type="textarea" placeholder="Results" v-model="scope.row.Results" autosize></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-table-column>
              <el-table-column prop="finalresult" label="Biological Reference Interval (Mean ± SD)" :width="reportDetails?.from==undefined?250:340" v-slot="scope" v-if="reportDetails.sub_category=='Chitotriosidase_Plasma' || reportDetails.sub_category=='Biotinidase_Plasma'">
                <el-form>
                  <el-row>
                    <el-col :span="24">
                      <el-input type="textarea" placeholder="Biological Reference Interval (Mean ± SD)" v-model="scope.row.Reference_Interval_Mean_SD" autosize></el-input>
                    </el-col>
                  </el-row>
                </el-form>
              </el-table-column>
              <el-table-column prop="finalresult" label="Reference Interval (Mean ± SD)" width="140" v-slot="scope" v-else>
                <el-form>
                  <el-row>
                    <el-col :span="24">
                      <el-input type="textarea" placeholder="Reference Interval (Mean ± SD)" v-model="scope.row.Reference_Interval_Mean_SD" autosize></el-input>
                    </el-col>
                  </el-row>
                </el-form>
              </el-table-column>
              <el-table-column prop="finalresult" label="Status" width="140" v-slot="scope">
                <el-form>
                  <el-row>
                    <el-col :span="24">
                      <el-select placeholder="Status" :clearable="true" :filterable="true" v-model="scope.row.Status">
                        <el-option label="Normal" value="Normal"></el-option>
                        <el-option label="Deficient" value="Deficient"></el-option>
                        <el-option label="Low" value="Low"></el-option>
                        <el-option label="Elevated" value="Elevated"></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </el-form>
              </el-table-column>
              <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope" v-if="this.reportDetails?.from==undefined">
                <el-row :gutter="12">
                  <el-col :span="6" v-if="scope.row.sno === Leukocyte_Enzyme_Activity?.length">
                    <el-buttton @click="onAddItem" class="c-pointer">
                      <el-icon :size="20">
                        <CirclePlus />
                      </el-icon>
                    </el-buttton>
                  </el-col>
                  <el-col :span="6" v-if="Leukocyte_Enzyme_Activity?.length > 1">
                    <el-buttton @click="onDeleteItem(scope.row)">
                      <el-icon :size="19">
                        <Delete style="color: red;" />
                      </el-icon>
                    </el-buttton>
                  </el-col>
                </el-row>
              </el-table-column>
            </el-table>
          </div>
          <div class="edit-box mb-20">
            <span class="title">Method</span>
            <el-input type="textarea" autosize v-model="patientInfo.method" rows="3"></el-input>
          </div>
          <div class="edit-box mb-20">
            <span class="title">Result</span>
            <el-input type="textarea" autosize v-model="patientInfo.result" rows="3"></el-input>
          </div>
          <div class="edit-box mb-20  mt-20" v-if="reportDetails.sub_category=='Chitotriosidase_Plasma'">
            <span class="title">Remarks</span>
            <el-input type="textarea" autosize v-model="patientInfo.Remarks" rows="3"></el-input>
          </div>
          <div class="edit-box mb-20">
            <span class="title">Interpretation</span>
            <el-input type="textarea" autosize v-model="patientInfo.Interpretation" rows="3"></el-input>
          </div>
          <div class="edit-box mb-20">
            <span class="title">Recommendations</span>
            <el-input type="textarea" autosize v-model="patientInfo.recommendations" rows="3"></el-input>
          </div>
          <div class="edit-box mb-20">
            <span class="title">Comment</span>
            <el-input type="textarea" autosize v-model="patientInfo.comments" rows="3"></el-input>
          </div>
          <div class="edit-box mb-20">
            <span class="title">Note</span>
            <el-input type="textarea" autosize v-model="patientInfo.note" rows="3"></el-input>
          </div>
          <div class="edit-box mb-20  mt-20" v-if="reportDetails.sub_category=='Chitotriosidase_Plasma'">
            <span class="title">Reference</span>
            <el-input type="textarea" autosize v-model="patientInfo.References" rows="3"></el-input>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  // import { ElMessage } from "element-plus";
  import {
    genderTypes,
    radiologyReportTypes,
    histopathologyTypesMap,
    tmsTypes,
    departments,
    genomicsReportTypes,
    molecularGeneticsReportTypes,
    transplantImmunoGenetics,
    cytometryReportTypes,
    serologyImmunologyTypes,
    pharmacoGenomicsTypes,
    biochemistryTypes,
    cytogeneticsTypes,
    corporateTypes,
    immunoHematologyReportTypes
} from "@/constants/formConstants";
import { ElMessageBox } from "element-plus";
import { mapGetters } from "vuex";
import 'vue-advanced-cropper/dist/style.css';
// import corporateTemplate from "@/components/reportsTemplates/corporateTemplate"

export default {
    props: {
        reportDetails: {
            type: String,
            default:null
        },
        Leukocyte_Enzyme_ActivityArray:{
            type:Array
        }
    },
    components: {

    },

    data() {
      return {
        genderTypes: genderTypes,
        patientInfo: {
          Interpretation: this.reportDetails.sub_category === 'Beta-Mannosidosis_Leukocytes'?'Normal activity of β-Mannosidase in leukocytes may rule out the possibility of β-Mannosidosis disease.':
        this.reportDetails.sub_category === 'Biotinidase_Plasma'?'Normal biotinidase enzyme activity obtained in plasma may rule out the possibility of Biotinidase deficiency.':
        this.reportDetails.sub_category === 'Chitotriosidase_Plasma'?'':
        this.reportDetails.sub_category === 'Alpha-Mannosidosis_Leukocytes'?'Normal activity of α- Mannosidase in leukocytes may rule out the possibility of α- Mannosidosis disease.':'Normal activity of α-Fucosidase in leukocytes may rule out the possibility of α-Fucosidosis disease.',
          
        result: this.reportDetails.sub_category === 'Beta-Mannosidosis_Leukocytes'?'A normal activity of β-Mannosidase is obtained in peripheral blood leukocytes.':
        this.reportDetails.sub_category === 'Biotinidase_Plasma'?'A normal enzyme activity of biotinidase is obtained in plasma.':
        this.reportDetails.sub_category === 'Chitotriosidase_Plasma'?'A normal activity of chitotriosidase is obtained in plasma.':
        this.reportDetails.sub_category === 'Alpha-Mannosidosis_Leukocytes'?'A normal activity of α- Mannosidase is obtained in peripheral blood leukocytes.':'A normal activity of α-Fucosidase is obtained in peripheral blood leukocytes.',
          department: "",
          report_type: "",
          template_title: "",
          recommendations: "",
          comments: "",
          References: "1) Yufeng, Guo. et al., J.Inher. Meatb. Dis. 18 (1995) 717-722. 2) Kadali, S. et al., Clinica Chimica Acta 457 (2016) 8–11. 3) Begona Aguilera et al, volume.278, No.42, 40911-40916, 2003.",
          Microscopy: "",
          Impression: "",
          category: "",
          sub_category: "",
          Clinical: "",
          clinical_details: "",
          indication: "",
          note: "It is presumed that the specimen used to perform the test belongs to the patient specified above, such verification having been carried out at the collection level of sample. Please correlate with clinical findings and other test findings.",
          method: this.reportDetails.sub_category === 'Biotinidase_Plasma' ? 'Artificial chromogenic substrates' : "Artificial Fluorogenic substrates.",
          Remarks: ""
        },
        isaddendumChecked: false,
        grossdescriptiontype: "Others",
        unSavedChanges: false,
        loading: false,
        loadingText: "",
        show: false,
        editorData: "",
        searchVisitId: "",
        reportType: "",
        Impression: "",
        Microscopy: "",
        Description: "",
        showReportType: true,
        editReportType: false,
        disabledDate: time => {
          return time.getTime() > Date.now();
        },
        showAddTemplate: false,
        reportTypeNew: "",
        Leukocyte_Enzyme_Activity: [{
          sno: 1,
          Test_Units: this.reportDetails.report_type == "Alpha-Fucosidosis_Leukocytes" ? "α-Fucosidase (nm/h/mg)" : this.reportDetails.report_type == "Alpha-Mannosidosis_Leukocytes" ? "α-Mannosidase (nm/h/mg)" : this.reportDetails.report_type == "Beta-Mannosidosis_Leukocytes" ? "β-Mannosidase (nm/h/mg)" : this.reportDetails.report_type == "Biotinidase_Plasma" ? "Biotinidase (nmol/min/ml)" : "Chitotriosidase (nmol/h/ml)",
          Disorder: this.reportDetails.report_type == "Alpha-Fucosidosis_Leukocytes" ? "α-Fucosidosis" : this.reportDetails.report_type == "Alpha-Mannosidosis_Leukocytes" ? "α-Mannosidosis" : this.reportDetails.report_type == "Beta-Mannosidosis_Leukocytes" ? "β-Mannosidosis" : "",
          Results: "",
          Reference_Interval_Mean_SD: this.reportDetails.report_type == "Alpha-Fucosidosis_Leukocytes" ? "31 – 108 (52 ± 19.5)" : this.reportDetails.report_type == "Alpha-Mannosidosis_Leukocytes" ? "100 - 340 (221 ± 65.2)" : this.reportDetails.report_type == "Beta-Mannosidosis_Leukocytes" ? "36 - 179 (105 ± 35.3)" : this.reportDetails.report_type == "Biotinidase_Plasma" ? "3.7 – 9.4 (6.6 ± 1.5)" : "5.1 – 78 (25.5 ± 19.7)",
          Status: "Normal"
        }, {
          sno: 2,
          Test_Units: this.reportDetails.report_type == "Alpha-Fucosidosis_Leukocytes" ? "* β-Galactosidase (nmol/h/mg)" : this.reportDetails.report_type == "Alpha-Mannosidosis_Leukocytes" ? "* β-Galactosidase (nmol/h/mg)" : this.reportDetails.report_type == "Beta-Mannosidosis_Leukocytes" ? "* β-Galactosidase (nmol/h/mg)" : this.reportDetails.report_type == "Biotinidase_Plasma" ? "* β-Hexosaminidase total (nmol/h/ml)" : "* β-Hexosaminidase total (nmol/h/ml)",
          Disorder: this.reportDetails.report_type == "Alpha-Fucosidosis_Leukocytes" ? "GM1 gangliosidosis" : this.reportDetails.report_type == "Alpha-Mannosidosis_Leukocytes" ? "GM1 gangliosidosis" : this.reportDetails.report_type == "Beta-Mannosidosis_Leukocytes" ? "GM1 gangliosidosis" : "",
          Results: "",
          Reference_Interval_Mean_SD: this.reportDetails.report_type == "Alpha-Fucosidosis_Leukocytes" ? "85 – 300 (142 ± 59)" : this.reportDetails.report_type == "Alpha-Mannosidosis_Leukocytes" ? "85 – 300 (142 ± 59)" : this.reportDetails.report_type == "Beta-Mannosidosis_Leukocytes" ? "85 – 300 (142 ± 59)" : this.reportDetails.report_type == "Biotinidase_Plasma" ? "635 – 3600 (2184 ± 435)" : "635 – 3600 (2184 ± 435)",
          Status: "Normal"
        }]
      };
    },
    computed: {
      shouldRenderElement() {
        // Check if patientInfo.report_type is not empty and then check if it includes sub_category
        if (this.patientInfo.department == "Department of CORPORATE" && this.patientInfo.category == "CORPORATE") {
          return (this.patientInfo.report_type && this.patientInfo.report_type.includes(this.patientInfo.sub_category));
        } else {
          return false;
        }
      },
      ...mapGetters("errors", ["getErrors", "getError"]),
      ...mapGetters("patientReports", ["getPatientAddStatus", "getSinglePatientReports", "getPatientUpdateStatus", "getPatientDetails"]),
      ...mapGetters("reportTemplates", ["getSingleReportTemplate", "getReportTemplateUpdateStatus", "getReportTemplateAddStatus"]),
      ...mapGetters("auth", ["getAuthenticatedUser"]),
      getSubCategories() {
        if (!this.patientInfo.department) {
          return [];
        } else if (this.patientInfo.department == "Histopathology") {
          return histopathologyTypesMap;
        } else if (this.patientInfo.department == "Department of CYTOGENETICS") {
          return cytogeneticsTypes;
        } else if (this.patientInfo.department == "Department of CORPORATE") {
          return corporateTypes;
        } else if (this.patientInfo.department == "Department of Radiology") {
          return radiologyReportTypes;
        } else if (this.patientInfo.department == "Biochemical Genetics") {
          return tmsTypes;
        } else if (this.patientInfo.department == "Department of Genomics and Molecular Diagnostics") {
          return genomicsReportTypes;
        } else if (this.patientInfo.department == "Department of Molecular Genetics") {
          return molecularGeneticsReportTypes;
        } else if (this.patientInfo.department == "Department of Flow Cytometry") {
          return cytometryReportTypes;
        } else if (this.patientInfo.department == "Department of Transplant Immunogenetics") {
          return transplantImmunoGenetics;
        } else if (this.patientInfo.department == "Department of Serology / Immunology") {
          return serologyImmunologyTypes;
        } else if (this.patientInfo.department == "Department of Pharmacogenomics") {
          return pharmacoGenomicsTypes;
        }  else if (this.patientInfo.department == "Department of Bio-Chemistry") {
          return biochemistryTypes;
        } else if (this.patientInfo.department == "Department of ImmunoHematology") {
          return immunoHematologyReportTypes;
        } else {
          return [];
        }
      },
      isReportTypeUpdateDisable() {
        return this.reportTypeNew && this.reportTypeNew.trim() && this.reportTypeNew.trim().length ? false : true;
      },
      isEditForm() {
        return this.$route.params && this.$route.params.template_id ? true : false;
      },
      isDraft() {
        if (this.isEditForm && this.patientInfo.report_status && this.patientInfo.report_status != "DRAFT") {
          return false;
        }
        return true;
      },
      noContent() {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;
        return data.length ? false : true;
      }
    },
    mounted() {
      if (this.$route.params.template_id) {
        this.Leukocyte_Enzyme_Activity = [];
      }
      if (this.reportDetails.from == "AddPatientReport") {
        this.Leukocyte_Enzyme_Activity = [];
        this.getPatientDetails1();
      }
      this.loadInitData();
      // this.fetchReportTemplates();
    },
    methods: {
      savePatientDetails() {
        let result = {
          method: this.patientInfo.method,
          References: this.patientInfo.References,
          result: this.patientInfo.result,
          Interpretation: this.patientInfo.Interpretation,
          recommendations: this.patientInfo.recommendations,
          comments: this.patientInfo.comments,
          note: this.patientInfo.note,
          Leukocyte_Enzyme_Activity: this.Leukocyte_Enzyme_Activity,
          clinical_details: this.patientInfo.clinical_details,
          indication: this.patientInfo.indication,
          Remarks: this.patientInfo.Remarks
        };
        this.$emit("savePatientDetails", result);
      },
      async getPatientDetails1() {
        this.patientInfo = {
          ...this.reportDetails
        };
        this.Leukocyte_Enzyme_Activity = this.reportDetails.Leukocyte_Enzyme_Activity;
      },
      onAddItem() {
        this.Leukocyte_Enzyme_Activity?.push({
          sno: this.Leukocyte_Enzyme_Activity?.length + 1,
          result: "",
          chromosomeloci: "",
          noofcells: "",
          finalresult: ""
        });
      },
      onDeleteItem(index) {
        this.Leukocyte_Enzyme_Activity?.splice(index.sno - 1, 1);
        this.Leukocyte_Enzyme_Activity?.forEach((data, index) => {
          data.sno = index + 1;
        });
      },
      async loadInitData() {
        await this.$store.dispatch("errors/clear", {}, {
          root: true
        });
        if (this.$route.params.template_id) {
          await this.fetchSinglePatientInfo();
        }
        setTimeout(() => {
          this.show = true;
        });
      },
      async fetchReportTemplates() {
        try {
          this.loading = true;
          this.loadingText = "Fetching data..";
          let params = {
            get_all: true,
            category: "CYTOGENETICS"
          };
          if (this.reportType) {
            const matchingType = this.cytogeneticsTypesMap.find(type => type.value === this.reportType);
            if (matchingType) {
              params.sub_category = matchingType.value;
              this.showKaryotypingUI = matchingType.value === "KARYOTYPING";
              // this.showSolidfishUI = matchingType.value === 'SOILDFISH';
              //this.showHematfishUI = matchingType.value === 'HEMATFISH';
            }
          }
          await this.$store.dispatch("reportTemplates/fetchAllReportTemplates", params);
          // this.templates = this.getAllReportTemplates.data;
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.error(err);
        }
      },
      async fetchSinglePatientInfo() {
        try {
          this.loading = true;
          this.loadingText = "Fetching data...";
          await this.$store.dispatch("reportTemplates/fetchSingleReportTemplate", {
            template_id: this.$route.params.template_id
          });
          await this.setPatientData();
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        }
      },
      setPatientData() {
        this.Leukocyte_Enzyme_Activity = this.getSingleReportTemplate.Leukocyte_Enzyme_Activity;
        this.patientInfo = {
          ...this.getSingleReportTemplate
        };
        this.reportType = this.patientInfo.report_type;
        this.editorData = this.getSingleReportTemplate.template_body;
        if (!this.patientInfo.sub_category) {
          this.patientInfo.sub_category = this.patientInfo.report_type;
        }
      },
      async clearForm() {
        await ElMessageBox.confirm("Are you sure to clear the form. Continue?", "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }).then(async () => {
          await this.clearPatientForm();
        }).catch(() => {});
      },
      async childMethod() {
        try {
          let params = this.prepareTemplateData();
          if (!params) {
            return;
          }
          this.loading = true;
          this.loadingText = "Loading...";
          await this.$store.dispatch("reportTemplates/addReportTemplate", params);
          if (this.getReportTemplateAddStatus) {
            this.$notify.success({
              title: "Success",
              message: "Report Template Added Successfully"
            });
            this.$router.push("/all-report-templates");
          } else if (this.getErrors) {
            this.scrollIntoFirstError();
          } else if (!this.getErrors) {
            this.$notify.error({
              title: "Error",
              message: "Error while Adding Template"
            });
          }
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        }
      },
      async updateTemplate() {
        try {
          let params = this.prepareTemplateData();
          if (!params) {
            return;
          }
          this.loading = true;
          this.loadingText = "Loading...";
          await this.$store.dispatch("reportTemplates/updateReportTemplate", {
            template_id: this.patientInfo._id,
            ...params
          });
          if (this.getReportTemplateUpdateStatus) {
            this.$notify.success({
              title: "Success",
              message: "Report Template Updated Successfully"
            });
            this.$router.push({
              path: "/all-report-templates",
              query: this.$route.query
            });
          } else if (!this.getErrors) {
            this.$notify.error({
              title: "Error",
              message: "Error at Updating Template"
            });
          }
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        }
      },
      prepareTemplateData() {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        const Microscopy = dd[1] ? dd[1].innerHTML : "";
        const Impression = dd[2] ? dd[2].innerHTML : "";
        let params = {
          ...this.patientInfo
        };
        params.template_title = this.reportDetails.template_title;
        params.department = this.reportDetails.department;
        params.sub_category = this.reportDetails.sub_category;
        params.report_type = this.reportDetails.report_type;
        if (params.department) {
          let category = departments.find(department => department.label == params.department);
          if (category && category.value) {
            params.category = category.value;
          }
        }
        params.template_body = data;
        params.Leukocyte_Enzyme_Activity = this.Leukocyte_Enzyme_Activity;
        params.grossdescriptiontype = this.grossdescriptiontype;
        params.isaddendumChecked = this.isaddendumChecked;
        params.Microscopy = Microscopy ? Microscopy : undefined;
        params.Impression = Impression ? Impression : undefined;
        return params;
      },
      async saveAsTemplate() {
        await this.updateTemplateData();
      },
      clearPatientForm(clearPatientData = true) {
        if (clearPatientData) {
          this.patientInfo = {
            visit_id: "",
            patient_name: "",
            ref_doctor: "",
            uhid: "",
            mr_no: "",
            gender: "",
            age: "",
            hospital_name: "",
            registration_date: "",
            reported_date: "",
            received_date: ""
          };
        }
        window.document.getElementsByClassName("ck-content")[0].innerText = "";
        window.document.getElementsByClassName("ck-content").innerHTML = "";
        // this.editorData = "";
      },
      async previewReport() {
        try {
          this.loading = true;
          this.loadingText = "Loading..";
          let dd = window.document.getElementsByClassName("ck-content");
          const data = dd[0].innerHTML;
          let params = {
            ...this.patientInfo
          };
          params.test_details = data;
          this.$store.dispatch("patientReports/addPatientData", params);
          let routerData = this.$router.resolve({
            name: "PatientReportPreview"
          });
          window.open(routerData.href, "_blank");
          this.loading = false;
        } catch (err) {
          console.log(err);
        }
      },
      async goToReportTemplates() {
        this.$router.push({
          name: "ReportTemplates",
          query: this.$route.query
        });
      },
      async updatePatientInfo() {
        this.loading = true;
        this.loadingText = "Loading...";
        try {
          let params = {
            ...this.patientInfo
          };
          let dd = window.document.getElementsByClassName("ck-content");
          const data = dd[0].innerHTML;
          params.test_details = data;
          let patientId = this.$route.params.patient_id;
          await this.$store.dispatch("reportTemplates/updateReportTemplate", {
            params,
            patientId
          });
          if (this.getPatientUpdateStatus) {
            this.$notify.success({
              title: "Success",
              message: "Patient Report Updated Successfully"
            });
            await this.goToReportTemplates();
          } else if (this.getErrors) {
            this.scrollIntoFirstError();
          } else if (!this.getErrors) {
            this.$notify.error({
              title: "Error",
              message: "Error while Updating Report"
            });
          }
          this.loading = false;
        } catch (err) {
          console.log(err);
        }
      },
      async askRemoveConfirmation() {
        await ElMessageBox.confirm("Are you want to clear template data. Continue?", "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "No",
          type: "warning"
        }).then(async () => {
          await this.clearPatientForm(false);
        }).catch(() => {});
      },
      scrollIntoFirstError() {
        var el = this.$el.getElementsByClassName("error")[0];
        if (el) {
          el.scrollIntoView({
            block: "center"
          });
        }
      },
      onEditReportType() {
        this.reportTypeNew = this.patientInfo.report_type;
        this.editReportType = true;
        this.showReportType = false;
      },
      updateReportType() {
        this.patientInfo.report_type = this.reportTypeNew;
        this.editReportType = false;
        this.showReportType = true;
      },
      cancelUpdateReportType() {
        this.reportTypeNew = this.patientInfo.report_type;
        this.editReportType = false;
        this.showReportType = true;
      },
      onChangeCategory() {
        this.patientInfo.report_type = this.patientInfo.sub_category;
        if (this.patientInfo.department === "Histopathology") {
          this.patientInfo.category = "HISTO_PATHOLOGY";
        } else if (this.patientInfo.department === "Department of Genomics and Molecular Diagnostics") {
          this.patientInfo.category = "GENOMICS";
        } else if (this.patientInfo.department === "Department of Molecular Genetics") {
          this.patientInfo.category = "MOLECULAR_GENETICS";
        } else if (this.patientInfo.department === "Department of Flow Cytometry") {
          this.patientInfo.category = "FLOW_CYTOMETRY";
        } else if (this.patientInfo.department === "Department of Transplant Immunogenetics") {
          this.patientInfo.category = "TRANSPLANT_IMMUNOGENETICS";
        } else if (this.patientInfo.department == "Department of Serology / Immunology") {
          this.patientInfo.category = "SEROLOGY_IMMUNOLOGY";
        } else if (this.patientInfo.department == "Department of Pharmacogenomics") {
          this.patientInfo.category = "PHARMACO_GENOMICS";
        } else if (this.patientInfo.department == "Department of Bio-Chemistry") {
          this.patientInfo.category = "BIO_CHEMISTRY";
        } else if (this.patientInfo.department == "Department of CYTOGENETICS") {
          this.patientInfo.category = "CYTOGENETICS";
        } else if (this.patientInfo.department == "Department of CORPORATE") {
          this.patientInfo.category = "CORPORATE";
        }  else if (this.patientInfo.department == "Biochemical Genetics") {
          this.patientInfo.category = "BIOCHEMICAL_GENETICS";
        } else if (this.patientInfo.department == "Department of ImmunoHematology") {
          this.patientInfo.category = "IMMUNOHEMATOLOGY";
        } else {
          this.patientInfo.category = "RADIOLOGY";
        }
      },
      checkCKEditorContent() {
        const excludedSubcategories = ["KARYOTYPING", "SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR", "HISTOPATHOLOGY(IHC - 4 MARKERS)", "HISTOPATHOLOGY(BIOPSY)", "FISH", "Newborn Screening TMS 49 Disorders", "GaucherLeukocytes", "MPSIILeukocytes", "FabryLeukocytes", "Hemoglobinopathies (LC-MS/MS)", "Urinary Glycosaminoglycans (GAG) Quantification", "Newborn Screening (Bio-7)", "Urine Organic Acid Analysis (GC-MS)", "Alpha_Fucosidosis_Leukocytes", "Alpha-Fucosidosis_Leukocytes", "Alpha_Mannosidosis_Leukocytes", "Alpha-Mannosidosis_Leukocytes"];
        return !excludedSubcategories.includes(this.patientInfo.sub_category);
      }
    }
    // beforeRouteLeave(to, from, next) {
    //     const answer = window.confirm(
    //       "Do you really want to leave? you have unsaved changes!"
    //     );
    //     if (answer) {
    //       next();
    //     } else {
    //       next(false);
    //     }
    // },
  };
</script>
<style lang="scss">
  .ck-editor__editable {
    min-height: 320px;
  }

  .report-header {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    background: #2eaf9f;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .veEyeLabel {
    text-align: right;
    font-weight: 500;
    margin-top: 45px;
  }

  .image-flex {
    display: grid;
    grid-template-columns: repeat(3, 265px);
    gap: 30px;
    padding: 0 10px;
    margin: 20px 0;
    align-items: center;
    justify-items: center;

    img {
      width: 240px;
      max-width: 240px;
      height: 200px;
      object-fit: contain;
    }

    h4 {
      text-align: center;
      border: 2px solid #cccccc;
      border-radius: 4px;
      padding: 10px;
    }

    .upload-btn {
      padding: 10px;
    }

    .img-block {
      position: relative;
      background: #f3f3f3;
      padding: 10px;
      border-radius: 6px;

      .remove-btn {
        display: none;
      }

      &:hover {
        .remove-btn {
          display: block;
          cursor: pointer;
        }
      }

      .remove-btn {
        position: absolute;
        top: 6px;
        right: 6px;
      }
    }
  }

  .fix-cropper-width {
    width: 600px;
    max-width: 600px;
  }

  #fileUploadArea {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  .fileUploadArea {
    .section-2 {
      margin-top: 2rem;
    }
  }

  .cropper {
    height: 280px;
    max-height: 100%;
    width: 280px;
    max-width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #ddd;
  }

  // .uploadBlock{
  //   background-color: #ddd;
  //   width: 280px;
  //   height: 280px;
  //   padding: 10px;
  //   box-sizing: border-box;
  //   border-radius: 10px;
  // }
  .croppedBlock {
    background: #ddd;
    width: 280px;
    height: 280px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;

    .croppedImage {
      height: 100%;
      max-height: 100%;
      width: 100%;
      max-width: 100%;
      object-fit: fill;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    margin: 25px auto;
    margin-left: 370px;
  }

  .centeredButton {
    display: flex;
    justify-content: center;
  }

  .button {
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    width: 100%;
    background: #151515;
    cursor: pointer;
    transition: background 0.5s;
    border: none;

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    &:hover {
      background: #2f2f2f;
    }

    input {
      display: none;
    }
  }

  .hidden {
    display: none;
  }

  .fileUploadBlock {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .fileUploadBlock {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .editor {
    margin-top: 0.5em;
    padding: 10px;

    .ck-editor__editable {
      min-height: 320px;
    }
  }

  .add-patient-report-data .addReportPatientDemographics .title {
    text-align: left !important;
    text-transform: initial !important;
    padding: 6px !important;
    display: block;
  }

  .add-patient-report-data .addReportPatientDemographics {
    border-radius: 7px;
    margin-bottom: 10px;
    overflow: hidden;
    box-shadow: 0px 24px 80px 0px rgba(180, 184, 193, 0.16);
    background: #fff;
  }
</style>