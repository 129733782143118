<template>
  <section id="reportEditorView">
    <section
      class="report-editor-component"
      id="tms-add-report-view"
      v-loading.fullscreen.lock="loading"
      :element-loading-text="loadingText"
    >
      <div class="reportTitleNavbar">
        <h3 class="reportTitle" v-if="getPatientResults">
          Patient Results Edit({{ getPatientResults.barcode }})
        </h3>
        <div class="backButton">
          <el-button @click="goToReports">
            <el-icon class="el-icon-back"><Back /></el-icon>
            Back</el-button
          >
        </div>
      </div>

      <div class="inner">
        <span class="report-header">DEPARTMENT OF BIOCHEMICAL GENETICS</span>
        <div class="patient-information">
          <el-form :model="patientInfo">
            <el-row>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Visit Id">
                  <el-input
                    v-model="patientInfo.visit_id"
                    placeholder="Enter Visit Id"
                    disabled
                  ></el-input>
                  <FormError errorName="visit_id"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item
                  label="Patient Name"
                  class="mb-05 form-mandatory-field"
                >
                  <el-input
                    v-model="patientInfo.patient_name"
                    placeholder="Enter Patient Name"
                  ></el-input>
                </el-form-item>
                <FormError
                  errorName="patient_demographics.patient_name"
                ></FormError>
              </el-col>

              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item
                  label="Hospital Name"
                  class="mb-05 mandatory-field"
                >
                  <el-input
                    v-model="patientInfo.hospital_name"
                    placeholder="Enter Hospital Name"
                  ></el-input>
                  <FormError errorName="ref_doctor"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Ref. Doctor" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.ref_doctor"
                    placeholder="Enter Reference Doctor"
                  ></el-input>
                  <FormError errorName="ref_doctor"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item
                  label="Client Address"
                  class="mb-05 mandatory-field"
                >
                  <el-input
                    v-model="patientInfo.client_add"
                    placeholder="Enter Client Address"
                  ></el-input>
                  <FormError errorName="client_add"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Client Code" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.client_code"
                    placeholder="Enter Client Code"
                  ></el-input>
                  <FormError errorName="client_code"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="UHID/MR No" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.uhid"
                    placeholder="Enter UHID No"
                  ></el-input>
                  <FormError errorName="uhid"></FormError>
                </el-form-item>
              </el-col>

              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Gender" class="mb-05 mandatory-field">
                  <div class="gender-select">
                    <el-select
                      v-model="patientInfo.gender"
                      placeholder="Select Gender"
                    >
                      <el-option
                        v-for="(gender, index) in genderTypes"
                        :key="index"
                        :label="gender"
                        :value="gender"
                      ></el-option>
                    </el-select>
                    <FormError errorName="gender"></FormError>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Age" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.age"
                    placeholder="Enter Age"
                  ></el-input>
                  <FormError errorName="age"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Mobile" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.mobile"
                    placeholder="Enter Mobile Number"
                  ></el-input>
                  <FormError errorName="mobile"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Phone" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.alternate_mobile"
                    placeholder="Enter Phone Number"
                  ></el-input>
                  <FormError errorName="alternate_mobile"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Email" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.email"
                    placeholder="Enter Email"
                  ></el-input>
                  <FormError errorName="email"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Locality" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.locality"
                    placeholder="Enter Locality"
                  ></el-input>
                  <FormError errorName="locality"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Client Name" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.client_name"
                    placeholder="Enter Client Name"
                  ></el-input>
                  <FormError errorName="client_name"></FormError>
                </el-form-item>
              </el-col>

              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Sample Type" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.sample_type"
                    placeholder="Enter Sample Type"
                  ></el-input>
                  <FormError errorName="sample_type"></FormError>
                </el-form-item>
              </el-col>

              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Barcode No" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.barcode_no"
                    placeholder="Enter Barcode No"
                  ></el-input>
                  <FormError errorName="barcode_no"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item
                  label="Registration Date"
                  class="mb-05 mandatory-field"
                >
                  <el-date-picker
                    v-model="patientInfo.registration_date"
                    type="date"
                    placeholder="Pick a Date"
                    format="DD-MM-YYYY"
                    value-format="YYYY-MM-DD"
                    :disabled-date="disabledDate"
                  >
                  </el-date-picker>
                  <FormError errorName="registration_date"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item
                  label="Collected Date"
                  class="mb-05 mandatory-field"
                >
                  <el-date-picker
                    v-model="patientInfo.collected_date"
                    type="date"
                    placeholder="Pick a Date"
                    format="DD-MM-YYYY"
                    value-format="YYYY-MM-DD"
                    :disabled-date="disabledDate"
                  >
                  </el-date-picker>
                  <FormError errorName="collected_date"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item
                  label="Reported Date"
                  class="mb-05 mandatory-field"
                >
                  <el-date-picker
                    v-model="patientInfo.reported_date"
                    type="date"
                    placeholder="Pick a Date"
                    format="DD-MM-YYYY"
                    value-format="YYYY-MM-DD"
                    :disabled-date="disabledDate"
                  >
                  </el-date-picker>
                  <FormError errorName="reported_date"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item
                  label="Received Date"
                  class="mb-05 mandatory-field"
                >
                  <el-date-picker
                    v-model="patientInfo.received_date"
                    type="date"
                    placeholder="Pick a Date"
                    format="DD-MM-YYYY"
                    value-format="YYYY-MM-DD"
                    :disabled-date="disabledDate"
                  >
                  </el-date-picker>
                  <FormError errorName="received_date"></FormError>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="card-body">
          <div class="each-card editor-view">
            <span class="title">Clinical History</span>
            <div class="clinical-editor" v-if="show" v-loading="loadingEditor">
              <ckeditor
                :editor="editor"
                tag-name="div"
                ref="myCommentEditor"
                v-model="clinicalData"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
          <div class="each-card editor-view">
            <span class="title">Methodology</span>
            <div
              class="methodology-editor"
              v-if="show"
              v-loading="loadingEditor"
            >
              <ckeditor
                :editor="editor"
                tag-name="div"
                ref="myCommentEditor"
                v-model="methodologyData"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
          <div class="each-card editor-view">
            <span class="title">Result</span>
            <div class="result-editor" v-if="show" v-loading="loadingEditor">
              <ckeditor
                :editor="editor"
                tag-name="div"
                ref="myCommentEditor"
                v-model="resultData"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>

          <div class="each-card">
            <div class="table-view">
              <!-- <h4 class="table-heading">Result Values</h4> -->
              <el-table :data="testResults">
                <el-table-column prop="test" label="Name">
                  <template #default="scope">
                    <b> {{ scope.row.test }} ({{ scope.row.units }})</b>
                  </template>
                </el-table-column>
                <el-table-column prop="value" label="Results(nmol/ml)">
                  <template #default="scope">
                    <el-input
                      v-model="testResults[scope.$index].value"
                    ></el-input>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="cutOff"
                  label="Biological Reference Range(nmol/ml)"
                ></el-table-column>
              </el-table>
            </div>
          </div>
          <div class="each-card editor-view">
            <span class="title">Interpretation</span>
            <div
              class="interpretation-editor"
              v-if="show"
              v-loading="loadingEditor"
            >
              <ckeditor
                :editor="editor"
                tag-name="div"
                ref="myCommentEditor"
                v-model="interpretationData"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
          <div class="each-card editor-view">
            <span class="title">Impression</span>
            <div
              class="impression-editor"
              v-if="show"
              v-loading="loadingEditor"
            >
              <ckeditor
                :editor="editor"
                tag-name="div"
                ref="myCommentEditor"
                v-model="impressionData"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
          <div class="each-card editor-view">
            <span class="title">Comments</span>
            <div class="comment-editor" v-if="show" v-loading="loadingEditor">
              <ckeditor
                :editor="editor"
                tag-name="div"
                ref="myCommentEditor"
                v-model="commentData"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
          <div class="each-card editor-view">
            <span class="title"><Ri:d></Ri:d>Recommendations</span>
            <div
              class="recommendation-editor"
              v-if="show"
              v-loading="loadingEditor"
            >
              <ckeditor
                :editor="editor"
                tag-name="div"
                ref="myCommentEditor"
                v-model="recommendationsData"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>

          <div class="each-card editor-view">
            <span class="title">Remarks</span>
            <div class="remarks-editor" v-if="show" v-loading="loadingEditor">
              <ckeditor
                :editor="editor"
                tag-name="div"
                ref="myCommentEditor"
                v-model="remarksData"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
          <div class="each-card editor-view background-editor">
            <span class="title">Background</span>
            <div
              class="background-editor"
              v-if="show"
              v-loading="loadingEditor"
            >
              <ckeditor
                :editor="editor"
                tag-name="div"
                ref="myCommentEditor"
                v-model="backgroundData"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
          <div class="each-card editor-view">
            <span class="title">References</span>
            <div class="reference-editor" v-if="show" v-loading="loadingEditor">
              <ckeditor
                :editor="editor"
                tag-name="div"
                ref="myCommentEditor"
                v-model="referencesData"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
          <div class="each-card editor-view">
            <span class="title">Note</span>
            <div class="note-editor" v-if="show" v-loading="loadingEditor">
              <ckeditor
                :editor="editor"
                tag-name="div"
                ref="myCommentEditor"
                v-model="noteData"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
        </div>

        <ul class="action-buttons">
          <li>
            <el-button @click="savePatientInfo" class="submit-button"
              >Submit</el-button
            >
          </li>
        </ul>
        <FormError errorName="critical_error"></FormError>
      </div>

      <div v-if="templateTitleDialogVisible" class="template-title-dialog">
        <el-dialog
          width="30%"
          v-model="templateTitleDialogVisible"
          title="New Template"
          :before-close="handleClose"
          class="template-title-dialog"
        >
          <el-input
            placeholder="Please add a titile for Template "
            v-model="templateTitle"
          ></el-input>

          <template #footer>
            <span class="dialog-footer">
              <el-button
                size="medium"
                class="el-white"
                type="info"
                plain
                @click="handleClose"
                >Cancel</el-button
              >
              <el-button
                size="medium"
                type="primary"
                @click="addNewTemplate"
                :loading="loading"
                >Submit</el-button
              >
            </span>
          </template>

          <FormError errorName="template_title"></FormError>
        </el-dialog>
      </div>
    </section>
  </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import CKEditor from "@ckeditor/ckeditor5-vue";
import { genderTypes } from "@/constants/formConstants";
import FormError from "@/components/FormError";
import { mapGetters } from "vuex";

import { ElMessage } from "element-plus";

import EditorConfig from "@/config/editorConfig";
export default {
  components: {
    ckeditor: CKEditor.component,
    FormError,
  },

  data() {
    return {
      templateTitle: "",
      value: "",
      genderTypes: genderTypes,

      patientInfo: {
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        client_add: "",
        client_code: "",
        uhid: "",
        mr_no: "",
        gender: "",
        age: "",
        client_name: "",
        barcode_no: "",
        registration_date: "",
        collected_date: "",
        reported_date: "",
        received_date: "",
        sample_type: "",
      },
      report_template_id: "",
      test_results: "",
      loading: false,
      loadingText: "",
      editor: ClassicEditor,
      show: false,
      editorData: "",
      comments: "",
      recommendations: "",
      impression: "",
      resultData: "",
      clinicalData: "",
      methodologyData: "",
      interpretationData: "",
      recommendationsData: "",
      referencesData: "",
      noteData: "",
      remarks: "",
      testResults: [],

      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      editorConfig: EditorConfig,
      remarksData: "",
      patientLoading: false,

      templateTitleDialogVisible: false,
      testCategory: "",
      templates: [],
      commentData: "",
      impressionData: "",
      backgroundData: "",
    };
  },
  computed: {
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("resultFiles", [
      "getPatientResults",
      "getPatientResultUpdateStatus",
    ]),
    ...mapGetters("reportTemplates", [
      "getAllReportTemplates",
      "getReportTemplateAddStatus",
    ]),
  },
  async mounted() {
    this.loadInitData();
  },

  methods: {
    async loadInitData() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data...";
        await this.$store.dispatch("errors/clear", {}, { root: true });

        await Promise.all([
          this.fetchSinglePatientInfo(),
          this.fetchReportTemplates(),
        ]);
        // await this.department();

        setTimeout(() => {
          this.show = true;
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    openResultEdit() {
      this.openResults = true;
    },

    async fetchReportTemplates() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data..";
        let params = {
          get_all: true,
          category: "BIOCHEMICAL_GENETICS",
          status: "ACTIVE",
        };
        await this.$store.dispatch(
          "reportTemplates/fetchAllReportTemplates",
          params
        );
        if (this.getAllReportTemplates) {
          this.templates = this.getAllReportTemplates.data;
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async fetchSinglePatientInfo() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data...";
        let params = {
          result_file_id: this.$route.params.result_file_id,
          patient_id: this.$route.params.patient_id,
        };
        await this.$store.dispatch(
          "resultFiles/fetchSinglePatientResults",
          params
        );

        await this.setPatientData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async setPatientData() {
      if (
        this.getPatientResults &&
        this.getPatientResults.patient_demographics
      ) {
        this.patientInfo = {
          ...this.patientInfo,
          ...this.getPatientResults.patient_demographics,
        };
      }
      if (this.getPatientResults && this.getPatientResults.sample_type) {
        this.patientInfo.sample_type = this.getPatientResults.sample_type;
      }
      this.report_template_id = this.getPatientResults.report_template_id || "";
      if (this.getPatientResults && this.getPatientResults.interpretation) {
        this.remarksData = this.getPatientResults.remarks;
        this.updateEditorData(this.remarksData);
      } else {
        this.remarksData = "";
        this.updateEditorData(this.remarksData);
      }
      if (this.getPatientResults && this.getPatientResults.background) {
        this.backgroundData = this.getPatientResults.background;
        this.updateResultsBackgroundData(this.backgroundData);
      } else {
        this.backgroundData = "";
        this.updateResultsBackgroundData(this.backgroundData);
      }
      if (this.getPatientResults && this.getPatientResults.result) {
        this.resultData = this.getPatientResults.result;
        this.updateResultEditorData(this.resultData);
      } else {
        this.resultData = "";
        this.updateResultEditorData(this.resultData);
      }
      if (this.getPatientResults && this.getPatientResults.clinical_history) {
        this.clinicalData = this.getPatientResults.clinical_history;
        this.updateClinicalEditorData(this.clinicalData);
      } else {
        this.clinicalData = "";
        this.updateClinicalEditorData(this.clinicalData);
      }
      if (this.getPatientResults && this.getPatientResults.methodology) {
        this.methodologyData = this.getPatientResults.methodology;
        this.updateMethodologyEditorData(this.methodologyData);
      } else {
        this.methodologyData = "";
        this.updateMethodologyEditorData(this.methodologyData);
      }
      if (this.getPatientResults && this.getPatientResults.interpretation) {
        this.interpretationData = this.getPatientResults.interpretation;
        this.updateInterpretationEditorData(this.interpretationData);
      } else {
        this.interpretationData = "";
        this.updateInterpretationEditorData(this.interpretationData);
      }
      if (this.getPatientResults && this.getPatientResults.recommendations) {
        this.recommendationsData = this.getPatientResults.recommendations;
        this.updateRecommendationsEditorData(this.recommendationsData);
      } else {
        this.recommendationsData = "";
        this.updateRecommendationsEditorData(this.recommendationsData);
      }
      if (this.getPatientResults && this.getPatientResults.references) {
        this.referencesData = this.getPatientResults.references;
        this.updateReferenceEditorData(this.referencesData);
      } else {
        this.referencesData = "";
        this.updateReferenceEditorData(this.referencesData);
      }
      if (this.getPatientResults && this.getPatientResults.note) {
        this.noteData = this.getPatientResults.note;
        this.updateNoteEditorData(this.noteData);
      } else {
        this.noteData = "";
        this.updateNoteEditorData(this.noteData);
      }
      if (this.getPatientResults && this.getPatientResults.comments) {
        this.commentData = this.getPatientResults.comments;
        this.updateCommentEditorData(this.commentData);
      } else {
        this.commentData = "";
        this.updateCommentEditorData(this.commentData);
      }
      if (this.getPatientResults && this.getPatientResults.impression) {
        this.impressionData = this.getPatientResults.impression;
        this.updateImpressionEditorData(this.impressionData);
      } else {
        this.impressionData = "";
        this.updateImpressionEditorData(this.impressionData);
      }
      this.testCategory = this.getPatientResults.test_category;
      this.testResults = JSON.parse(
        JSON.stringify(this.getPatientResults.test_results_summary)
      );

      // this.testResultsEditSummary = editTestResults;

      // removed duplicates
    },
    updateResultsBackgroundData(backgroundData) {
      this.show = false;
      setTimeout(() => {
        this.show = true;
        this.backgroundData = backgroundData;
      }, 100);
    },
    updateEditorData(templateData) {
      this.show = false;
      setTimeout(() => {
        this.show = true;
        this.remarksData = templateData || "";
      }, 100);
    },
    updateResultEditorData(templateResultData) {
      this.show = false;
      setTimeout(() => {
        this.show = true;
        this.resultData = templateResultData;
      }, 100);
    },
    updateClinicalEditorData(templateClinicalData) {
      this.show = false;
      setTimeout(() => {
        this.show = true;
        this.clinicalData = templateClinicalData;
      }, 100);
    },
    updateMethodologyEditorData(templateMethodologyData) {
      this.show = false;
      setTimeout(() => {
        this.show = true;
        this.methodologyData = templateMethodologyData;
      }, 100);
    },
    updateInterpretationEditorData(templateInterpretationData) {
      this.show = false;
      setTimeout(() => {
        this.show = true;
        this.interpretationData = templateInterpretationData;
      }, 100);
    },
    updateRecommendationsEditorData(templateRecommendationsData) {
      this.show = false;
      setTimeout(() => {
        this.show = true;
        this.recommendationsData = templateRecommendationsData;
      }, 100);
    },
    updateReferenceEditorData(templateReferenceData) {
      this.show = false;
      setTimeout(() => {
        this.show = true;
        this.referencesData = templateReferenceData;
      }, 100);
    },
    updateNoteEditorData(templateNoteData) {
      this.show = false;
      setTimeout(() => {
        this.show = true;
        this.noteData = templateNoteData;
      }, 100);
    },
    updateCommentEditorData(templateCommentData) {
      this.show = false;
      setTimeout(() => {
        this.show = true;
        this.commentData = templateCommentData;
      }, 100);
    },
    updateImpressionEditorData(templateImpressionData) {
      this.show = false;
      setTimeout(() => {
        this.show = true;
        this.impressionData = templateImpressionData;
      }, 100);
    },

    goToReports() {
      if (this.$route.query && this.$route.query.fromRoute) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: "PatientResultFiles",
          query: this.$route.query,
        });
      }
    },
    async previewReport() {
      try {
        this.loading = true;
        this.loadingText = "Loading..";
        let patient_demographics = { ...this.patientInfo };

        if (patient_demographics.client_code != "") {
          const code = patient_demographics.client_code.toString();
          patient_demographics.client_code = code;
        }

        let params = { patient_demographics };
        params.test_category = this.testCategory || "TMS";
        let innerResultData = window.document.querySelector(
          ".result-editor .ck-content"
        );
        params.result = innerResultData.innerHTML || "";
        let clinicData = window.document.querySelector(
          ".clinical-editor .ck-content"
        );
        params.clinical_history = clinicData.innerHTML || "";
        let methodsData = window.document.querySelector(
          ".methodology-editor .ck-content"
        );
        params.methodology = methodsData.innerHTML || "";
        let interpretationData = window.document.querySelector(
          ".interpretation-editor .ck-content"
        );
        params.interpretation = interpretationData.innerHTML || "";
        let recommendData = window.document.querySelector(
          ".recommendation-editor .ck-content"
        );
        params.recommendations = recommendData.innerHTML || "";
        let remarkData = window.document.querySelector(
          ".remarks-editor .ck-content"
        );
        params.remarks = remarkData.innerHTML || "";
        let referenceData = window.document.querySelector(
          ".reference-editor .ck-content"
        );
        params.references = referenceData.innerHTML || "";
        let noteData = window.document.querySelector(
          ".note-editor .ck-content"
        );
        let backgroundData = window.document.querySelector(
          ".background-editor .ck-content"
        );
        params.background = backgroundData.innerHTML || "";
        params.note = noteData.innerHTML || "";
        params.test_results_summary = this.testResults;

        this.$store.dispatch("resultFiles/addResultsData", params);

        let routerData = this.$router.resolve({
          name: "PatientResultsPreview",
        });

        window.open(routerData.href, "_blank");

        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    savePatientInfo() {
      this.addPatientInfo();
    },

    async handleEditClose(response) {
      this.openResults = response;
      console.log(response);
      // this.loading = true;
      // this.setPatientData();
      this.openClose();
      // this.loading = false;
    },
    openClose() {
      window.location.reload();
    },
    async updatePatientResults(results) {
      let data = [];
      results.map((category) => {
        data = [...data, ...category.results];
      });

      this.openResults = false;
    },

    async addPatientInfo() {
      try {
        this.loading = true;
        this.loadingText = "Loading...";
        let patient_demographics = { ...this.patientInfo };

        if (patient_demographics.client_code != "") {
          const code = patient_demographics.client_code.toString();
          patient_demographics.client_code = code;
        }

        let params = { patient_demographics };
        let innerResultData = window.document.querySelector(
          ".result-editor .ck-content"
        );
        if (innerResultData.innerHTML == '<p><br data-cke-filler="true"></p>') {
          params.result = "";
        } else {
          params.result = innerResultData.innerHTML || "";
        }
        let clinicData = window.document.querySelector(
          ".clinical-editor .ck-content"
        );
        if (clinicData.innerHTML == '<p><br data-cke-filler="true"></p>') {
          params.clinical_history = "";
        } else {
          params.clinical_history = clinicData.innerHTML || "";
        }
        let methodologyData = window.document.querySelector(
          ".methodology-editor .ck-content"
        );
        if (methodologyData.innerHTML == '<p><br data-cke-filler="true"></p>') {
          params.methodology = "";
        } else {
          params.methodology = methodologyData.innerHTML || "";
        }
        let interpretationData = window.document.querySelector(
          ".interpretation-editor .ck-content"
        );
        if (
          interpretationData.innerHTML == '<p><br data-cke-filler="true"></p>'
        ) {
          params.interpretation = "";
        } else {
          params.interpretation = interpretationData.innerHTML || "";
        }
        let recommendData = window.document.querySelector(
          ".recommendation-editor .ck-content"
        );
        if (recommendData.innerHTML == '<p><br data-cke-filler="true"></p>') {
          params.recommendations = "";
        } else {
          params.recommendations = recommendData.innerHTML || "";
        }
        let remarkData = window.document.querySelector(
          ".remarks-editor .ck-content"
        );
        if (remarkData.innerHTML == '<p><br data-cke-filler="true"></p>') {
          params.remarks = "";
        } else {
          params.remarks = remarkData.innerHTML || "";
        }
        let referenceData = window.document.querySelector(
          ".reference-editor .ck-content"
        );
        if (referenceData.innerHTML == '<p><br data-cke-filler="true"></p>') {
          params.references = "";
        } else {
          params.references = referenceData.innerHTML || "";
        }
        let noteData = window.document.querySelector(
          ".note-editor .ck-content"
        );
        if (noteData.innerHTML == '<p><br data-cke-filler="true"></p>') {
          params.note = "";
        } else {
          params.note = noteData.innerHTML || "";
        }
        let commentData = window.document.querySelector(
          ".comment-editor .ck-content"
        );
        if (commentData.innerHTML == '<p><br data-cke-filler="true"></p>') {
          params.comments = "";
        } else {
          params.comments = commentData.innerHTML || "";
        }
        let impressionData = window.document.querySelector(
          ".impression-editor .ck-content"
        );
        if (impressionData.innerHTML == '<p><br data-cke-filler="true"></p>') {
          params.impression = "";
        } else {
          params.impression = impressionData.innerHTML || "";
        }
        let backgroundData = window.document.querySelector(
          ".background-editor .ck-content"
        );
        if (backgroundData.innerHTML == '<p><br data-cke-filler="true"></p>') {
          params.background = "";
        } else {
          params.background = backgroundData.innerHTML || "";
        }
        if (this.report_template_id) {
          params.report_template_id = this.report_template_id || "";
        }

        params.test_results_summary = this.testResults;
        // params.recommendations = this.recommendations;
        // params.impression = this.impression;
        // params.remarks = this.remarks;
        let patientId = this.$route.params.patient_id;
        let resultFileId = this.$route.params.result_file_id;
        await this.$store.dispatch("resultFiles/updatePatientResults", {
          params,
          patientId,
          resultFileId,
        });
        if (this.getPatientResultUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Updated Successfully",
          });
          await this.goToReports();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while updating Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    saveAsTemplate() {
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0].innerHTML;
      let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
      stringContent = stringContent.trim();
      if (!stringContent) {
        ElMessage.warning("Report content is not given");
        return;
      }
      this.templateTitleDialogVisible = true;
    },
    async handleClose() {
      this.templateTitle = "";
      this.templateTitleDialogVisible = false;
      await this.$store.dispatch("errors/clear", {}, { root: true });
    },
    async addNewTemplate() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;

        let params = { ...this.patientInfo };
        if (params.client_code) {
          params.client_code = params.client_code.toString();
        }
        params.template_title = this.templateTitle;

        if (!data.length) {
          this.$message("No Content to Save Template");
          return;
        }
        params.template_body = data;

        this.loading = true;
        params.sub_category = "BIO";
        params.department = "Biochemical Genetics";
        params.category = "BIOCHEMICAL_GENETICS";

        await this.$store.dispatch("reportTemplates/addReportTemplate", params);

        if (this.getReportTemplateAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Template Added Successfully",
          });

          this.templateTitleDialogVisible = false;
          this.templateTitle = "";

          await this.fetchReportTemplates();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error at Creating new Template",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lower_abnormal {
  font-weight: bold;
}

.upper_abnormal {
  font-weight: bold;
  background-color: #b7d2ec;
}
</style>
<style lang="scss">
#tms-add-report-view {
  max-width: 1000px;
  margin: 0 auto;
  .reports-title-header {
    display: grid;
    grid-template-columns: 88% auto;
    margin-bottom: 15px;
    .display-end {
      display: flex;
      text-align: end;
    }
    .report-title {
      text-align: center;
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }

    .back-btn {
      padding: 9px 15px;
      min-height: auto;
      line-height: 1;
      color: #fff;
      border: 1px solid #2f80ed;
      background-color: #2f80ed;
      font-weight: 500;
      .el-icon-back {
        margin-right: 4px;
      }
      &:active,
      &:hover,
      &:focus {
        border: 1px solid #47525d42;
      }
    }
  }

  .inner-navbar {
    margin-bottom: 10px;
    .mt-10 {
      margin-top: 10px;
    }
    .filter-navigation {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }
  .tms-report-divider {
    width: 100%;
    height: 3px;
    background: #b7d2ec;
    margin: 15px 0;
  }
  @media print {
    .tms-report-divider {
      display: none;
    }
  }
  .tms-reports-edit {
    text-align: right;
    // padding: 10px;
    .el-button {
      color: #000;
      border: none;
      background-color: transparent;
      &:hover {
        color: #000;
        border: none;
        background-color: transparent;
      }
    }
    img {
      width: 22px;
      position: relative;
      top: 5px;
    }
  }

  .inner {
    margin-bottom: 50px;
    .report-header {
      margin: 0;
      color: #fff;
      font-weight: 500;
      font-size: 20px;
      background: #2eaf9f;
      text-align: center;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    border: 1px solid rgba(7, 162, 149, 0.6);
    border-radius: 10px;
    padding: 0;
    background: #fff;
    overflow: hidden;
    .patient-information {
      .el-form-item {
        margin-bottom: 0;
      }
      .form-mandatory-field {
        .el-form-item__label:after {
          content: "*";
          color: red !important;
          font-size: 15px;
          line-height: 1;
          padding-left: 3px;
        }
      }

      .el-form-item__label {
        min-width: 40%;
        text-align: left;
        background: #e0f3f1;
        padding-left: 10px;
        border-bottom: 1px solid #e0f3f1;
        font-weight: 500;
        font-size: 11px;
        color: #000;
      }

      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }
      .el-input__inner {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-left: none;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        border-radius: 0;
        color: #000;
        font-weight: 500;
        // text-transform: uppercase;
        &::placeholder {
          color: #5a5a5a;
          font-size: 13px;
          text-transform: capitalize;
        }
      }
    }
    .report-sub-title {
      color: #43454b;
      font-size: 18px;
      text-align: center;
      margin-top: -1px;
      padding-top: 1rem;
      border-top: 1.2px solid rgba(0, 0, 0, 0.2);

      font-weight: 500;
    }
    .report-type-edit-info {
      display: flex;
      margin-top: 0.5em;
      padding: 0 10px;
      gap: 5px;
    }
    .editor,
    .comments-editor {
      margin-top: 0.5em;
      padding: 10px;
      .ck-editor__editable {
        min-height: 320px;
      }
    }
    .image__caption_highlighted {
      min-height: auto !important;
    }
    .ck-dropdown__panel {
      max-height: 230px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .ck-placeholder {
      min-height: auto !important;
    }

    .ck-dropdown__panel::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2eaf9f;
    }
    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      margin: 0;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      li {
        &:not(:last-child) {
          margin-right: 20px;
        }
        .el-button {
          padding: 9px 15px;
          min-height: auto;
          line-height: 1;
          &.preview-button {
            background: #28b4a6;
            color: #fff;
            font-weight: 500;
          }
          &.print-button {
            background: #f56c6c !important;
          }
          &.submit-button {
            background: #0061ec;
            color: #fff;
            font-weight: 500;
          }
        }
      }
    }
  }
  figure {
    &.table {
      br {
        display: none;
      }
      br[data-cke-filler="true"] {
        display: block !important;
      }
    }
  }
  .ck {
    ul {
      list-style: disc;
      padding-left: 30px;
      li {
        padding-left: 5px;
        list-style-type: disc !important;
      }
    }
  }
  .table-data {
    padding-top: 1em;
  }
  .el-table {
    .el-table thead {
      background-color: #28b4a6;
      font-weight: 500;
    }
  }
  // .input-error {
  //   .el-input__inner {
  //     border: 1.5px solid red !important;
  //   }
  //   ::placeholder {
  //     color: red !important;
  //     font-weight: 500;
  //   }
  // }
  .result-table {
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
  }
}
.double-marker {
  padding: 10px 0 20px;

  .text-area-sub-header {
    padding: 5px 20px;
  }

  .el-textarea__inner {
    width: 95%;
    margin: auto;
  }
}
</style>

<style lang="scss">
.bio-chemistry-report-title {
  text-align: center;
  background: #e0f3f1;
  border-radius: 5px;
  margin-top: 10px !important;
  padding: 6px 0;
  font-weight: 500;
  font-size: 15px;
  color: #333;
}
.bio-chemistry-data-table {
  border: 1px solid rgba(7, 162, 149, 0.6);
  margin: 20px 10px 10px 10px;
  padding: 0 !important;
  table {
    th {
      background: #2eaf9f;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      padding: 4px 10px;
      letter-spacing: 0;
      position: relative;
      text-align: left;
      &:not(:last-child) {
        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 2px;
          bottom: 2px;
          background: rgba(255, 255, 255, 0.6);
          width: 1px;
        }
      }
      .cell {
        padding: 0;
      }
    }

    td {
      padding: 5px 10px;
      font-size: 14px;
      border-bottom: 1px solid #2eaf9f;
      font-weight: 500;
      color: #000;
      &:not(:last-child) {
        border-right: 1px solid #2eaf9f;
      }
      .cell {
        padding: 0;
      }
    }
  }
}

.tms-report-editor-table {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  .data-table {
    margin-bottom: 20px;
    table {
      border-collapse: collapse;
      width: 100%;

      th,
      td {
        padding: 5px 10px !important;
        font-size: 15px;
      }
      th {
        background: #f5f5f5;
        font-weight: 500;
      }
      .table-head {
        background: #28b4a6;
        color: #fff;
        text-align: center !important;
        font-size: 15px !important;
        .cell {
          line-height: 20px !important;
        }
      }
    }
  }
}
.mb-20 {
  margin-bottom: 20px;
}
</style>
