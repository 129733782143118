<template>
  <section id="all-patient-reports">
    <div class="container">
      <div class="page-navigation">
        <el-row>
          <el-col :span="12">
            <ul class="filter-navigation">
              <li>
                <div class="category-filter">
                  <el-select
                    placeholder="Select Category"
                    v-model="searchCategory"
                    @change="loadData(1)"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="category in reportCategory"
                      :key="category"
                      :label="category.label"
                      :value="category.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </li>
              <!-- <li>
                <div class="category-filter">
                  <el-select
                    placeholder="Select Status"
                    v-model="status"
                    @change="loadData(1)"
                  >
                    <el-option
                      v-for="status in statusList"
                      :key="status"
                      :label="status.label"
                      :value="status.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </li> -->
            </ul>
          </el-col>
          <el-col :span="12">
            <ul class="action-navigation">
              <li v-if="isAbleToAccess('ADD_PATIENT_REPORT')">
                <el-button
                  @click="goToAddForm"
                  class="default-button add-button"
                  >Add Template
                </el-button>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <div v-loading="loading" :element-loading-text="loadingText">
        <div class="vue-table">
          <el-table
            :data="reportTemplates"
            style="width: 100%"
            height="calc(100vh - 250px)"
            class="table-list display-web"
            @sort-change="changeSort"
          >
            <el-table-column
              prop="created_at"
              label="Date"
              width="200"
              sortable="custom"
              fixed
            >
              <template #default="scope">
                <span class="date" v-if="scope.row.created_at">
                  {{ date(scope.row.created_at) }}
                  {{ time(scope.row.created_at) }}
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="department"
              label="Department"
              min-width="200"
              sortable="custom"
            >
              <template #default="scope">
                {{ scope.row.department || "--" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="category"
              label="Category"
              min-width="200"
              sortable="custom"
            >
              <template #default="scope">
                {{ scope.row.category || "--" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="sub_category"
              label="Sub-Category"
              min-width="400"
            >
              <template #default="scope">
                {{ scope.row.sub_category ? scope.row.sub_category : "--" }}
              </template>
            </el-table-column>
            <!-- <el-table-column prop="status" label="Status" min-width="120">
              <template #default="{ row }">
                <div class="column-status">
                  <el-tag
                    :type="row.status == 'ARCHIVED' ? 'danger' : 'success'"
                    >{{ row.status }}</el-tag
                  >
                </div>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="remarks"
              label="Actions"
              fixed="right"
              :width="140"
            >
              <template #default="{ row }">
                <ul class="table-action-buttons">
                  <li>
                    <el-button class="view-icon" @click="previewReport(row)">
                      <img
                        src="@/assets/images/icons/eye.svg"
                        alt=""
                        class="img-fluid"
                      />
                    </el-button>
                  </li>
                  <li
                    v-if="
                      row.status != 'ARCHIVED' &&
                      isAbleToAccess('EDIT_TEMPLATE')
                    "
                  >
                    <el-button class="edit-icon" @click="editReport(row)">
                      <img
                        src="@/assets/images/icons/edit.svg"
                        alt=""
                        class="img-fluid"
                      />
                    </el-button>
                  </li>
                  <li
                    v-if="
                      row.status != 'ARCHIVED' &&
                      isAbleToAccess('DELETE_TEMPLATE')
                    "
                  >
                    <el-button class="edit-icon" @click="archiveReport(row)">
                      <el-icon>
                        <delete style="color: red" />
                      </el-icon>
                    </el-button>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div
          class="vue-pagination"
          v-if="reportTemplates && reportTemplates.length"
        >
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="loadData"
            :page-sizes="[10, 25, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { ElMessageBox } from "element-plus";

import userRolesPermissionsMixin from "@/mixins/userRolesPermissionsMixin";
import {
  reportCategories,
  subCategories,
  statusTypes,
} from "@/constants/formConstants";
export default {
  middleware: "auth",
  mixins: [userRolesPermissionsMixin],
  computed: {
    ...mapGetters("biochemistryTemplates", [
      "getAllBiochemistryTemplates",
      "getTemplateDeleteStatus",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),
  },
  data() {
    return {
      reportCategory: reportCategories,
      statusList: statusTypes,
      subCategories: subCategories,
      dialogVisible: false,
      headerFooter: "",
      reportTemplates: [],
      page: 1,
      pageSize: 10,
      total: 0,
      orderBy: "",
      orderType: "",
      sortCodeMap: {
        ascending: "asc",
        descending: "desc",
      },
      searchString: "",
      tagByStatus: {
        APPROVED: "success",
        VERIFIED: "warning",
        INITIATE: "primary",
        DRAFT: "info",
        PENDING: "warning",
      },

      loading: false,
      loadingText: "",
      fromDate: "",
      toDate: "",
      subCategoryTypes: [],
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      searchCategory: "",
      status: "ACTIVE",
    };
  },
  async mounted() {
    await this.setQueryToData();

    await this.fetchAllReports();

    await this.$store.dispatch("errors/clear");
  },
  methods: {
    date(value) {
      if (!value) return;
      return moment(value).format("DD-MM-yyyy");
    },
    time(value) {
      if (!value) return;
      return moment(value).format("hh:mm a");
    },
    async fetchDateFilterData() {
      if (this.fromDate && this.toDate) {
        await this.loadData(1);
      } else if (!this.fromDate && !this.toDate) {
        this.loadData(1);
      }
    },
    getSubCategory(category) {
      const subcategories = this.subCategories.filter((e) =>
        e.category.includes(category)
      );
      subcategories.forEach((x) => (this.subCategoryTypes = x.subcategory));
    },
    goToAddForm() {
      this.$router.push({ name: "AddBiochemistryTemplates" });
    },
    converToUpperCase(str) {
      if (!str) return;
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    },
    async fetchAllReports() {
      try {
        this.loading = true;
        this.loadingText = "Fetching Biochemistry Templates...";
        let params = this.prepareParams();

        await this.$store.dispatch(
          "biochemistryTemplates/fetchAllBiochemistryTemplates",
          params
        );
        this.setTableData();
        this.setQueryData(params);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async onSearch() {
      setTimeout(async () => {
        this.page = 1;
        await this.fetchAllReports();
      }, 600);
    },

    setTableData() {
      const tableData = JSON.parse(
        JSON.stringify(this.getAllBiochemistryTemplates)
      );
      this.reportTemplates =
        tableData && tableData.data && tableData.data.length
          ? tableData.data
          : [];
      this.page = tableData.page || 1;
      this.total = tableData.total || 0;
      this.pageSize = tableData.limit || 10;
    },
    prepareParams() {
      let params = { page: this.page, limit: this.pageSize };

      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }

      if (this.searchCategory) {
        params.category = this.searchCategory;
      }
      if (this.status) {
        params.status = this.status;
      }

      return params;
    },

    setQueryData(data) {
      let params = {
        selectedPage: data.page || 1,
        selectedPageSize: data.limit || 10,
        selectedString: data.search_string || undefined,
        selectedTemplateFromDate: this.fromDate || undefined,
        selectedTemplateToDate: this.toDate || undefined,
        selectedCategory: data.category || undefined,
        selectedStatus: data.status || undefined,
      };

      this.$router
        .replace({
          name: this.$route.name,
          query: { ...params },
        })
        .catch(() => {});
    },
    setQueryToData() {
      if (this.$route.query) {
        this.page = this.$route.query.selectedPage
          ? parseInt(this.$route.query.selectedPage)
          : 1;
        this.pageSize = this.$route.query.selectedPageSize
          ? parseInt(this.$route.query.selectedPageSize)
          : 10;
        this.searchString = this.$route.query.selectedString || "";
        this.fromDate = this.$route.query.selectedTemplateFromDate || "";
        this.toDate = this.$route.query.selectedTEmplateToDate || "";
        this.searchCategory = this.$route.query.selectedCategory || "";
        this.status = this.$route.query.selectedStatus || "ACTIVE";
      }
    },
    changeSort({ prop, order }) {
      this.orderBy = prop;
      this.orderType = this.sortCodeMap[order];
      this.page = 1;
      this.fetchAllReports();
    },
    async handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      await this.fetchAllReports();
    },
    async loadData(page) {
      this.page = page;

      await this.fetchAllReports();
    },

    async previewReport(template) {
      this.$router.push({
        name: "TemplateView",
        params: {
          template_id: template._id,
        },
        query: this.$route.query,
      });
    },
    async editReport(template) {
      try {
        this.$router.push({
          name: "UpdateBiochemistryTemplate",
          params: {
            template_id: template._id,
          },
          query: this.$route.query,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async archiveReport(template) {
      await ElMessageBox.confirm(
        "Are you sure to delete the template. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          await this.archive(template);
        })
        .catch(() => {});
    },
    async archive(template) {
      try {
        this.loading = true;
        this.loadingText = "Deleting Template...";
        await this.$store.dispatch(
          "biochemistryTemplates/archiveTemplateData",
          template._id
        );
        if (this.getTemplateDeleteStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Template Deleted Successfully",
          });

          this.fetchAllReports(this.page);
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while deleting template",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
#all-patient-reports {
  tbody {
    td {
      &:last-child {
        background: #f5f5f5;
      }
    }
  }
  .view-icon {
    padding-top: 3px;
    padding-bottom: 1px;
  }
}
</style>
